import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Spin } from 'antd';
import cn from 'classnames';

import DiscoveryFirstViewIcon from 'assets/DiscoveryFirstViewIcon.svg';

import { CollaboratorInviteModal } from 'Components/CollaboratorInviteModal';
import { FirstViewBodyUI } from 'Components/FirstViewBodyUI';
import { Milestone } from 'Components/Milestone';

import {
  addFileToProjectDiscovery,
  addLinkToProjectDiscovery,
  loadProjectDiscovery,
  removeFileFromProjectDiscovery,
  updateProjectDiscovery,
} from 'Redux/Actions/projectActions';

import { MILESTONE_STATUSES, NOTIFICATION_TYPES } from 'utlis/constants';
import formScrollToField from 'utlis/form_ scroll_to_field';
import { onSocketMessage } from 'utlis/socket';
import { errorReactToastify } from 'utlis/toasts';

import QuestionsGroup from './components/questions_group';

export default function DesignBriefForm({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [timerForSaveStart, setTimerForSaveStart] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isSavingFiles, setIsSavingFiles] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [openCollaboratorModal, setOpenCollaboratorModal] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.userProfile);
  const selectedProject = useSelector(state => state.selectedProject);
  const briefMilestone = useSelector(state => state.milestonesStore?.dictionaryId?.[milestoneId]);

  const [form] = Form.useForm();

  const { isDesignerSubmitted, isCompleted } = useMemo(
    () => ({
      isDesignerSubmitted: currentUser.permissions?.isDesigner
        && [MILESTONE_STATUSES.submitted, MILESTONE_STATUSES.completed].includes(briefMilestone?.milestone_status),
      isCompleted: [MILESTONE_STATUSES.completed].includes(briefMilestone?.milestone_status),
    }),
    [briefMilestone?.milestone_status]
  );

  useEffect(() => {
    if (selectedProject.uuid) {
      dispatch(loadProjectDiscovery(selectedProject.uuid))
        .then(() => setIsLoaded(true));
    }
  }, [selectedProject.uuid]);

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          dispatch(loadProjectDiscovery(selectedProject.uuid))
            .then(() => form.resetFields());
        }
      },
      [
        NOTIFICATION_TYPES.FORM_EDIT,
        NOTIFICATION_TYPES.FORM_SUBMIT,
        NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT,
        NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
      ]
    ),
    [selectedProject.uuid]
  );

  if (!isLoaded) {
    return (
      <div className="flex flex-component-center px-lg py-lg mx-xs">
        <Spin size="large" />
      </div>
    );
  }

  if (!selectedProject.discovery?.uuid && isLoaded) {
    return (
      <Milestone
        selectedMilestone={briefMilestone}
        title={briefMilestone?.name}

        withShareButton={!isReadonly}
      >
        <FirstViewBodyUI
          className="mx-xxxs"
          image={DiscoveryFirstViewIcon}
          text={
            <>
              Your designer is preparing
              {' '}
              {briefMilestone.name}
              {' '}
              questions for you!
              <br />
              We will update your view as soon as they are ready!
            </>
          }
        />
      </Milestone>
    );
  }

  return (
    <Milestone
      title={briefMilestone?.name}
      selectedMilestone={briefMilestone}

      withShareButton={!isReadonly}
      additionalRightElement={
        <>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !isDesignerSubmitted || isCompleted,
              }
            )}
          >
            SUBMITTED FOR INPUT
          </p>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !isCompleted,
              }
            )}
          >
            ANSWERS SUBMITTED
          </p>
          <div
            className={cn(
              'flex align-center',
              {
                hide: !selectedProject.discovery?.uuid || isDesignerSubmitted || isCompleted || isReadonly,
              }
            )}
          >
            <p className="text-sm text-silver m-zero">
              {
                timerForSaveStart
                  ? (
                    isSaved && !isSavingFiles
                      ? 'Saved'
                      : (
                        isSavingFiles
                          ? 'Files Saving ...'
                          : 'Saving ...'
                      )
                  )
                  : 'All changes are saved automatically'
              }
            </p>
            <p
              className={cn(
                'text-xs my-zero ml-sm',
                {
                  hide: !hasError,
                  'text-sunsetOrange': hasError,
                }
              )}
            >
              Please fill in all required fields
              <br />
              and correct any mistakes
            </p>
            <CollaboratorInviteModal
              visible={openCollaboratorModal}
              onCancel={onInviteModalClose}
            />
          </div>
        </>
      }
    >
      <Form
        initialValues={selectedProject.discovery.answersList}
        form={form}
        preserve
        onFieldsChange={(changedFields, allFields) => {
          if (changedFields.length && !isCompleted) {
            setHasError(!allFields.every(({ errors }) => !errors.length));
          }

          if (
            changedFields.length
              && !isCompleted
              && !isDesignerSubmitted
              && changedFields[0]?.name?.indexOf('design_images') == -1
          ) {
            if (timerForSaveStart) {
              clearTimeout(timerForSaveStart);
            }

            setTimerForSaveStart(setTimeout(
              onSave,
              1000
            ));
          }
        }}
      >
        {
          selectedProject.discovery?.questionGroupList
            .map(({ uuid, ...props }, idx) => (
              <QuestionsGroup
                key={uuid}
                idx={idx}
                uuid={uuid}
                className={cn('pt-4 px-16', { 'mt-52': idx != 0 })}
                {...props}
                selectedProject={selectedProject}
                currentUser={currentUser}
                isCompleted={isCompleted}
                isDesignerSubmitted={isDesignerSubmitted}
                form={form}
                onAddDesignFile={onAddDesignFile}
                onAddLink={onAddLink}
                onRemoveDesignFile={onRemoveDesignFile}
                onSaveQuestions={async () => {
                  await dispatch(loadProjectDiscovery(selectedProject.uuid));

                  form.resetFields();
                }}
                validateFields={validateFields}
                isReadonly={isReadonly}
              />
            ))
        }
      </Form>
    </Milestone>
  );

  async function onSave() {
    const formData = form.getFieldValue();
    const dataForSaving = Object.keys(formData)
      .filter(
        questionUUID => !['multi_file_field', 'file_field'].includes(
          selectedProject.discovery.questionsList[questionUUID]?.question_format
        )
      )
      .reduce(
        (currentObj, key) => selectedProject.discovery.answersList[key] == formData[key]
          ? currentObj
          : {
            ...currentObj,
            // TODO: This is a hack. We need to update the component for future use
            [key]: selectedProject.discovery.questionsList[key]?.question_format === 'colors'
              ? formData[key].split(',').filter(Boolean)
              : formData[key],
          },
        {}
      );

    if (dataForSaving.design_image) {
      dataForSaving.design_image = dataForSaving.design_image?.file?.thumbUrl || dataForSaving.design_image;
    }

    if (Object.keys(dataForSaving).length) {
      setIsSaved(false);

      await dispatch(
        updateProjectDiscovery(selectedProject.uuid, dataForSaving)
      );

      setIsSaved(true);
    }
  }

  async function validateFields(fieldName) {
    try {
      const res = await form.validateFields(fieldName);

      setHasError(false);

      return res;
    }
    catch (error) {
      setHasError(true);

      error.errorFields.some(({ errors, name: [name] }) => {
        if (errors.length) {
          formScrollToField(form, name);
        }

        return !!errors.length;
      });

      return Promise.reject(error);
    }
  }

  async function onAddDesignFile(questionUUID, { file, onSuccess, onProgress, onError }) {
    const maxFileSizeMB = 25;

    try {
      setTimerForSaveStart(1);
      setIsSaved(false);
      setIsSavingFiles(true);

      if (file.size > maxFileSizeMB * Math.pow(10, 6)) {
        const errorMessage = `The maximum file size is ${maxFileSizeMB} MB`;

        errorReactToastify(errorMessage);
        onError({ message: errorMessage });
      }
      else {
        const answer = await dispatch(addFileToProjectDiscovery(
          selectedProject.uuid,
          questionUUID,
          new File([file], file.name, { type: file.type }),
          {
            onUploadProgress: ({ progress }) => onProgress({ percent: progress * 100 }),
          }
        ));

        form.setFieldValue(
          questionUUID,
          answer
        );

        await validateFields([questionUUID]);

        onSuccess();
      }
    }
    catch (error) {
      onError(error);

      if (!error?.errorFields) {
        if (error?.response?.data?.detail) {
          errorReactToastify(error.response.data.detail);
        }
        else {
          throw new Error(error);
        }
      }
    }
    finally {
      setIsSaved(true);
      setIsSavingFiles(false);
    }
  }

  async function onAddLink(questionUUID, link) {
    try {
      setTimerForSaveStart(1);
      setIsSaved(false);
      setIsSavingFiles(true);

      const answer = await dispatch(addLinkToProjectDiscovery(
        selectedProject.uuid,
        questionUUID,
        link
      ));

      form.setFieldValue(
        questionUUID,
        answer
      );

      await validateFields([questionUUID]);
    }
    catch (error) {
      if (!error?.errorFields) {
        if (error?.response?.data?.detail) {
          errorReactToastify(error.response.data.detail);
        }
        else {
          throw new Error(error);
        }
      }
    }
    finally {
      setIsSaved(true);
      setIsSavingFiles(false);
    }
  }

  async function onRemoveDesignFile(questionUUID, fileUrl) {
    try {
      setTimerForSaveStart(1);
      setIsSaved(false);
      setIsSavingFiles(true);

      await dispatch(removeFileFromProjectDiscovery(
        selectedProject.uuid,
        questionUUID,
        selectedProject.discovery.answersList[questionUUID].indexOf(fileUrl)
      ));

      form.setFieldValue(
        questionUUID,
        selectedProject.discovery.answersList[questionUUID].filter(url => url != fileUrl)
      );

      await validateFields([questionUUID]);
    }
    catch (error) {
      if (!error?.errorFields) {
        if (error?.response?.data?.detail) {
          errorReactToastify(error.response.data.detail);
        }
        else {
          throw new Error(error);
        }
      }
    }
    finally {
      setIsSaved(true);
      setIsSavingFiles(false);
    }
  }

  function onInviteModalClose() {
    setOpenCollaboratorModal(false);
  }
}

DesignBriefForm.propTypes = {
  isReadonly: PropTypes.bool,
};
