import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Spin } from 'antd';
import cn from 'classnames';

import clock from 'assets/clock_icon.svg';
import deleteIcon from 'assets/delete_icon.svg';
import doorOpen from 'assets/door_open.svg';
import invitedIcon from 'assets/invited_icon.svg';
import resentInvite from 'assets/resent_invite.svg';

import ConfirmationModal from 'Components/CollaboratorInviteModal/components/ConfirmationModal';

import { INVITE_STATUSES } from 'utlis/constants';

import styles from './styles.module.scss';

export const CollaboratorItem = ({
  className,
  email,
  profilePhoto,
  fullName,
  isYou,
  isExpert,
  inviteStatus,
  resendInvite,
  removeCollaborator,
}) => {
  const selectedProject = useSelector(state => state.selectedProject);

  const userProfile = useSelector(state => state.userStore);

  const [isRemoving, setIsRemoving] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const collaboratorName = useMemo(
    () => {
      if (isYou && !isExpert) {
        return `${fullName} (You)`;
      }
      if (isExpert) {
        return `${fullName} (Expert)`;
      }

      return fullName;
    },
    [isYou, isExpert, fullName]
  );

  const popoverContent = (
    <div className="flex flex-column">
      {
        inviteStatus === INVITE_STATUSES.pending
          && (
            <div
              className="flex mb-8 pointer"
              onClick={handleResendInvite}
            >
              <div className={cn('flex flex-component-center', styles['image-container'])}>
                {isResending
                  ? (
                    <Spin />
                  )
                  : (
                    <img src={resentInvite} alt="resend invite icon" />
                  )}
              </div>
              <p className="mb-zero ml-4">
                Resend invite
              </p>
            </div>
          )
      }
      <div
        className="flex pointer"
        onClick={() => {
          setShowConfirmationModal(true);
          setShowUserOptions(false);
        }}
      >
        <div className={cn('flex flex-component-center', styles['image-container'])}>
          {isRemoving
            ? (
              <Spin />
            )
            : (
              <img
                src={isYou && userProfile.uuid !== selectedProject.owner.uuid ? doorOpen : deleteIcon}
                alt="delete icon"
              />)}
        </div>
        <p className="mb-zero ml-4">
          {isYou && userProfile.uuid !== selectedProject.owner.uuid ? 'Leave Project' : 'Remove'}
        </p>
      </div>
    </div>
  );

  return (
    <div
      className={cn('mt-16', styles['gridContainer'], className)}
    >
      <ConfirmationModal
        openConfirmModal={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        onAccept={handleRemoveCollaborator}
        buttonOneText="Cancel"
        buttonTwoText={isYou && userProfile.uuid !== selectedProject.owner.uuid ? 'Leave project' : 'Remove teammate'}
        text={(
          <>
            Are you sure you want to
            {' '}
            {isYou && userProfile.uuid !== selectedProject.owner.uuid ? 'leave' : 'remove'}
            {' '}
            <b>
              {isYou && userProfile.uuid !== selectedProject.owner.uuid ? selectedProject.name : fullName || email}
            </b>
            ?
            {' '}
            {isYou && userProfile.uuid !== selectedProject.owner.uuid ? 'You' : 'They'}
            {' '}
            will not be able to access this project anymore.
          </>
        )}
      />
      <div className={styles['collaboratorDetail']}>
        <img
          className={styles['collaboratorAvatar']}
          src={inviteStatus === INVITE_STATUSES.pending ? invitedIcon : profilePhoto}
          alt="img"
        />
        <div className="flex flex-column ml-16">
          {
            collaboratorName
              && (
                <span className="text-weight-400 text-black-opacity-0-90 text-xs letter-sm">
                  {collaboratorName}
                </span>
              )
          }
          <span className="text-10 text-black-opacity-0-40 break-all">
            {email}
          </span>
        </div>
      </div>
      <span className="text-11 text-black-opacity-0-60 letter-sm">
        {selectedProject.owner.email === email
          ? 'owner'
          : inviteStatus === INVITE_STATUSES.pending
            ? (
              <>
                <img src={clock} width={11} height={11} />
                {' '}
                pending invite
              </>
            )
            : (
              <>
                <FontAwesomeIcon icon={faCheck} />
                {' '}
                accepted
              </>
            )}
        {(selectedProject.owner.email !== email && userProfile.uuid === selectedProject.owner.uuid
          || (isYou && userProfile.uuid !== selectedProject.owner.uuid))
            && (
              <Popover
                content={popoverContent}
                placement="topRight"
                trigger="click"
                visible={showUserOptions}
                onVisibleChange={setShowUserOptions}
                showArrow={false}
              >
                <FontAwesomeIcon icon={faAngleDown} size="lg" className="ml-8 pointer" />
              </Popover>
            )}
      </span>
    </div>
  );

  async function handleResendInvite() {
    try {
      setIsResending(true);
      await resendInvite(email);
    }
    finally {
      setIsResending(false);
      setShowUserOptions(false);
    }
  }

  async function handleRemoveCollaborator() {
    try {
      setIsRemoving(true);
      await removeCollaborator(email);
    }
    finally {
      setIsRemoving(false);
      setShowUserOptions(false);
    }
  }
};

CollaboratorItem.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string.isRequired,
  profilePhoto: PropTypes.string,
  fullName: PropTypes.string,
  isYou: PropTypes.bool,
  isExpert: PropTypes.bool,
  inviteStatus: PropTypes.string,
  resendInvite: PropTypes.func,
  removeCollaborator: PropTypes.func,
};
