import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { ReactComponent as PencilIcon } from 'assets/pencil.svg';

import { Button } from 'Components/Button';
import { DeleteItemModal } from 'Components/DeleteItemModal';
import Question from 'Components/DesignBriefForm/components/question';
import TemplateString from 'Components/template_string';

import {
  addQuestionToBriefForm,
  changeSequenceQuestionsInBriefForm,
  deleteQuestionFromBriefForm,
  editQuestionInBriefForm,
} from 'Redux/Actions/projectActions';

import { PROJECT_TYPES } from 'utlis/constants';

import { AddQuestionButton } from './components/AddQuestionButton';
import { NoQuestions } from './components/NoQuestions';

import styles from './styles.module.scss';

export default function QuestionsGroup({
  uuid,
  name,
  description,
  selectedProject,
  currentUser,
  isCompleted,
  isDesignerSubmitted,
  idx,
  questions,
  form,
  onAddDesignFile,
  onAddLink,
  onRemoveDesignFile,
  onSaveQuestions,
  validateFields,

  isReadonly,
  className,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [questionsList, setQuestionsList] = useState(getQuestionsListFromProps());
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionIdxForRemove, setSelectedQuestionIdxForRemove] = useState(null);
  const [isProgress, setIsProgress] = useState(false);

  const questionGroupEditButtonsWrapperRef = useRef({});

  const groupHasQuestions = useMemo(
    () => !!questionsList
      .flat()
      .flat()
      .find(({ isRemoved }) => !isRemoved),
    [questionsList]
  );

  useEffect(
    () => {
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    },
    []
  );

  useEffect(
    () => {
      setQuestionsList(getQuestionsListFromProps());
    },
    [questions]
  );

  useEffect(
    () => {
      if (isDesignerSubmitted || isCompleted) {
        cancelEditMode();
      }
    },
    [isDesignerSubmitted, isCompleted]
  );

  return (
    <DragDropContext onDragEnd={isEditMode && onDragEnd}>
      <div
        className={cn(styles['discovery-alignment-form'], className)}
        disabled={isProgress}
      >
        <div className="flex space-between align-center">
          <p className="text-black-opacity-0-60 text-md m-zero letter-xxs">
            {
              isEditMode
                ? (
                  <span className="text-xl text-weight-500 text-black">
                    Add, remove or shuffle the questions.
                  </span>
                )
                : (
                  <>
                    <span className="text-xl text-weight-500 text-black">
                      <TemplateString text={name} />
                    </span>
                    {
                      selectedProject.type.toLowerCase() == 'logo design' && !isDesignerSubmitted && idx == 0
                        ? (
                          <>
                            <br />
                            To get a better idea of your project goals kindly gather
                            answers to the following questions from your client.
                          </>
                        )
                        : (
                          !!description && (
                            <>
                              <br />
                              <TemplateString text={description} />
                            </>
                          )
                        )
                    }
                  </>
                )
            }
          </p>
          {
            isEditMode
              ? (
                <div className="flex-shrink-0 ml-sm">
                  <Button
                    isText
                    className="text-13 text-pacificBlue text-button"
                    onClick={cancelEditMode}
                    disabled={isProgress}
                  >
                    CANCEL
                  </Button>
                  <Button
                    className={cn(styles['edit-button'], 'ml-sm')}
                    onClick={onSave}
                    disabled={isProgress}
                  >
                    {
                      !isProgress
                        ? 'SAVE'
                        : 'SAVING ...'
                    }
                  </Button>
                </div>
              )
              : (
                <Button
                  onClick={() => setIsEditMode(true)}
                  className={cn(
                    styles['edit-button'],
                    styles['shape-edit-button'],
                    'flex-shrink-0',
                    {
                      hide: (
                        currentUser.permissions.isClient
                          || isCompleted
                          || isDesignerSubmitted
                          || (!questionsList.length && selectedProject.type == PROJECT_TYPES.blankProject)
                          || isReadonly
                      ),
                    }
                  )}
                  icon={<PencilIcon />}
                />
              )
          }
        </div>
        <Droppable droppableId={uuid}>
          {droppableProvided => (
            <div
              ref={droppableProvided.innerRef}
              className="text-md text-black letter-xs mt-24"
              {...droppableProvided.droppableProps}
              {...droppableProvided.dragHandleProps}
            >
              {
                questionsList
                  .map((questionGroup, questionGroupIdx) => (
                    <Draggable
                      key={questionGroup.flat()[0].uuid}
                      draggableId={questionGroup.flat()[0].uuid}
                      index={questionGroupIdx}
                      isDragDisabled={!isEditMode}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className={cn(
                            styles['reset-numeric-list'],
                            styles['question-group-wrapper'],
                            {
                              [`${[styles['question-group']]} mb-24`]: !(
                                questionGroup.isBulletedQuestionList || questionGroup.isNumberedQuestionList
                              ),
                              'flex align-center space-between': isEditMode,
                              'hide': questionGroup.flat()[0].isRemoved,
                            }
                          )}
                        >
                          <div
                            className={cn(
                              {
                                [styles['question-max-width']]: (
                                  isEditMode
                                    && (!questionGroup[0].isNew || questionGroup[0].isDone)
                                ),
                                'flex-grow': questionGroupIdx != selectedQuestion,
                                [`${styles['border']} ${styles['selected-question']}`]: questionGroupIdx == selectedQuestion,
                              }
                            )}
                            onClick={
                              () => isEditMode
                              && (!questionGroup[0].isNew || questionGroup[0].isDone || questionGroup[0].created_by)
                                && setSelectedQuestion(questionGroupIdx)
                            }
                          >
                            {
                              questionGroup
                                .map(question => {
                                  if (Array.isArray(question)) {
                                    return (
                                      <div
                                        key={question[0].uuid}
                                        className={cn({
                                          [styles['question-group']]: !(
                                            question.isBulletedQuestionList || question.isNumberedQuestionList
                                          ),
                                          [styles['market-question-list-item']]: question.isBulletedQuestionList,
                                          [styles['numeric-question-list-item']]: question.isNumberedQuestionList,
                                        })}
                                      >
                                        {
                                          question.map(
                                            currentQuestion => renderQuestion(questionGroupIdx, currentQuestion)
                                          )
                                        }
                                      </div>
                                    );
                                  }

                                  return renderQuestion(questionGroupIdx, question);
                                })
                            }
                          </div>
                          <div
                            ref={el => questionGroupEditButtonsWrapperRef.current[questionGroupIdx] = el}
                            className={cn(
                              styles['question-group-edit-buttons-wrapper'],
                              'ml-md flex-shrink-0',
                              {
                                hide: (
                                  draggableSnapshot.isDragging
                                    || !isEditMode
                                    || (questionGroup[0].isNew && !questionGroup[0].isDone)
                                ),
                                [styles['show-question-group-edit-buttons-wrapper']]: (
                                  questionGroupIdx === selectedQuestion
                                ),
                              }
                            )}
                          >
                            <Button.FuzzyWuzzyBrown
                              isInvert
                              className={cn(
                                'text-13 text-notesText'
                              )}
                              icon={<FontAwesomeIcon icon={faTrashCan} className="mr-sm" />}
                              onClick={() => setSelectedQuestionIdxForRemove(questionGroupIdx)}
                            >
                              DELETE
                            </Button.FuzzyWuzzyBrown>
                            <AddQuestionButton
                              className="ml-sm"
                              onAddQuestion={
                                isProgress
                                  ? null
                                  : newQuestionObj => {
                                    setQuestionsList([].concat(
                                      questionsList.slice(0, questionGroupIdx + 1),
                                      [[newQuestionObj]],
                                      questionsList.slice(questionGroupIdx + 1)
                                    ));
                                    setSelectedQuestion(null);
                                  }
                              }
                              destroyPopupOnHide
                              getPopupContainer={() => questionGroupEditButtonsWrapperRef.current[questionGroupIdx]}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
              }
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
        {
          !groupHasQuestions && selectedProject.type == PROJECT_TYPES.blankProject && (
            <NoQuestions className="mt-24 mb-32" />
          )
        }
        <div
          className={cn(
            'flex',
            {
              'just-center': !groupHasQuestions,
              hide: (selectedProject.type != PROJECT_TYPES.blankProject && groupHasQuestions)
                || currentUser.permissions.isClient
                || isDesignerSubmitted
                || isCompleted,
            }
          )}
        >
          {
            !isReadonly && (
              <AddQuestionButton
                onAddQuestion={
                  isProgress
                    ? null
                    : newQuestionObj => {
                      if (!isEditMode) {
                        setIsEditMode(true);
                      }

                      setQuestionsList(questionsList.concat([[newQuestionObj]]));
                    }
                }
              />
            )
          }
        </div>
      </div>
      <DeleteItemModal
        questionText="Are you sure you want to delete this question?"
        visible={selectedQuestionIdxForRemove !== null}
        onDelete={deleteQuestion}
        onCancel={() => setSelectedQuestionIdxForRemove(null)}
      />
    </DragDropContext>
  );

  function getQuestionsListFromProps() {
    return questions
      .reduce(
        (current, next) => {
          current.at(-1).push(next);

          if (!next.is_connected_with_next) {
            current.push([]);
          }

          return current;
        },
        [[]]
      )
      .filter(questionGroup => questionGroup.length)
      .map(questionGroup => {
        if (questionGroup.find(({ is_bulleted }) => is_bulleted)) {
          questionGroup.isBulletedQuestionList = true;
        }
        else if (questionGroup.find(({ is_numbered }) => is_numbered)) {
          questionGroup.isNumberedQuestionList = true;
        }
        if (questionGroup.find(({ is_child }) => is_child)) {
          questionGroup.isChildQuestionList = true;
        }

        return questionGroup;
      })
      .reduce(
        (current, next) => {
          if (!next.isChildQuestionList) {
            current.push([]);
          }

          current.at(-1).push(next);

          return current;
        },
        []
      )
      .filter(Boolean)
      .filter(questionGroup => questionGroup.length);
  }

  function clickOutside(event) {
    const isClickOutside = !event.composedPath().find(
      el => el.className?.indexOf && el.className.indexOf(styles['question-group']) != -1
    );

    if (isClickOutside) {
      setSelectedQuestion(null);
    }
  }

  function onDragEnd(result) {
    if (result.destination) {
      const newQuestionsList = Array.from(questionsList);
      const [removed] = newQuestionsList.splice(result.source.index, 1);

      newQuestionsList.splice(result.destination.index, 0, removed);

      setQuestionsList(newQuestionsList);
    }
  }

  async function onSave() {
    try {
      setIsProgress(true);

      let currentQuestionsList = questionsList.flat().flat();

      const changedQuestions = currentQuestionsList
        .filter(({ isChanged }) => isChanged);
      const promiseChangeQuestion = changedQuestions.length
        ? Promise.all(changedQuestions.map(question => editQuestionInBriefForm(
          selectedProject.uuid,
          question
        )))
        : Promise.resolve();

      const removeQuestionsUUIDs = currentQuestionsList
        .filter(({ isRemoved }) => isRemoved)
        .map(({ uuid: questionUUID }) => questionUUID);

      if (removeQuestionsUUIDs.length) {
        await deleteQuestionFromBriefForm(
          selectedProject.uuid,
          removeQuestionsUUIDs
        );
      }

      currentQuestionsList = currentQuestionsList.filter(({ isRemoved }) => !isRemoved);

      const newSequenceQuestionsUUID = currentQuestionsList
        .filter(({ isNew }) => !isNew)
        .map(({ uuid: questionUUID }) => questionUUID);

      if (newSequenceQuestionsUUID.length) {
        await changeSequenceQuestionsInBriefForm(selectedProject.uuid, newSequenceQuestionsUUID);
      }

      const newQuestions = currentQuestionsList
        .reduce(
          (current, question) => [].concat(
            current,
            {
              ...question,
              prev_question: current.at(-1)?.isNew
                ? current.at(-1)?.prev_question || null
                : current.at(-1)?.uuid || null,
              category: uuid,
            }
          ),
          []
        )
        .filter(({ isDone, isNew }) => isDone && isNew);

      let promiseSaveNewQuestions = Promise.resolve();

      newQuestions.reverse().forEach(question => {
        promiseSaveNewQuestions = promiseSaveNewQuestions.then(() => addQuestionToBriefForm(
          selectedProject.uuid,
          question
        ));
      });

      await Promise.all([promiseSaveNewQuestions, promiseChangeQuestion]);

      await onSaveQuestions();

      setIsEditMode(false);
      setSelectedQuestion(null);
    }
    finally {
      setIsProgress(false);
    }
  }

  function renderQuestion(questionGroupIdx, question) {
    const { question_format, question_type } = question;
    let otherProps = {
      required: currentUser.permissions.isClient
        ? question.is_required
        : false,
      disabled: isReadonly || (
        isEditMode
          ? true
          : isCompleted || isDesignerSubmitted
      ),
    };

    if (
      (question_format == 'radio_button' && question_type == 'product_visualize_as')
        || question_format == 'multi_file_field'
        || question_format == 'file_field'
    ) {
      otherProps.disabled = isReadonly || (
        currentUser.permissions.isClient
          ? isCompleted
          : isDesignerSubmitted
      );
    }

    if (question_format == 'list') {
      otherProps.onAddItem = validateFields;
      otherProps.onRemoveItem = validateFields;
    }

    if (['multi_file_field', 'file_field'].includes(question_format)) {
      if (!form.getFieldValue(question.uuid)) {
        return null;
      }

      const maxCount = (question_format == 'multi_file_field' ? 5 : 1) - (question?.answer?.answer?.length || 0);

      otherProps = {
        ...otherProps,
        accept: question_format == 'multi_file_field'
          ? 'image/*'
          : null,
        hideUploadButton: isCompleted || isDesignerSubmitted,
        disabledUploadButton: (
          maxCount <= 0
            || isCompleted
            || isDesignerSubmitted
        ) || isReadonly,
        hideRemoveButton: isCompleted || isDesignerSubmitted,
        maxCount: maxCount,
        multiple: question_format == 'multi_file_field',
        onAddFile: (...args) => onAddDesignFile(question.uuid, ...args),
        onAddLink: (...args) => onAddLink(question.uuid, ...args),
        onRemoveFile: (...args) => onRemoveDesignFile(question.uuid, ...args),
      };
    }

    return !(question.uuid || question.isNew)
      ? question
      : (
        <Question
          key={question.uuid}
          onCancel={() => setQuestionsList([].concat(
            questionsList.slice(0, questionGroupIdx),
            questionsList.slice(questionGroupIdx + 1)
          ))}
          onAddOrEdit={({
            questionText,
            questionAnswerPlaceholder,
            questionAnswerChoices,
          }) => {
            questionAnswerChoices = questionAnswerChoices?.split(',')?.filter(choice => choice.trim() !== '');

            setQuestionsList(
              [].concat(
                questionsList.slice(0, questionGroupIdx),
                [[{
                  ...question,
                  isDone: true,
                  isChanged: !question.isNew,
                  question_text: questionText,
                  place_holder: questionAnswerPlaceholder || questionAnswerChoices[0],
                  question_choices: questionAnswerChoices,
                }]],
                questionsList.slice(questionGroupIdx + 1)
              )
            );
            setSelectedQuestion(null);
          }}
          isSelected={questionGroupIdx == selectedQuestion}
          {...question}
          {...otherProps}
        />
      );
  }

  function deleteQuestion() {
    setQuestionsList(currentQuestionsList => [].concat(
      currentQuestionsList.slice(0, selectedQuestionIdxForRemove),
      [[...currentQuestionsList[selectedQuestionIdxForRemove].flat().map(question => ({
        ...question,
        isRemoved: true,
      }))]],
      currentQuestionsList.slice(selectedQuestionIdxForRemove + 1)
    ));
    setSelectedQuestion(null);
    setSelectedQuestionIdxForRemove(null);
  }

  function cancelEditMode() {
    setIsEditMode(false);
    setSelectedQuestion(null);
    setQuestionsList(getQuestionsListFromProps());
  }
}

QuestionsGroup.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selectedProject: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isDesignerSubmitted: PropTypes.bool.isRequired,
  idx: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  onAddDesignFile: PropTypes.func.isRequired,
  onAddLink: PropTypes.func.isRequired,
  onRemoveDesignFile: PropTypes.func.isRequired,
  onSaveQuestions: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,

  isReadonly: PropTypes.bool,
  className: PropTypes.string,
};
