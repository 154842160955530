import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faCheck, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Menu } from 'antd';
import cn from 'classnames';

import projectThumbnailImage from 'assets/project_card_thumbnail.svg';
import threeDots from 'assets/three_dots.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import DateFromNow from 'Components/DateFromNow/index';
import { DropdownMenu } from 'Components/DropdownMenu';

import {
  deleteProject,
  getProjectStatusCountAction,
  initializeProjects,
  markProjectAsCompleted,
  renameProject,
} from 'Redux/Actions/projectActions';

import { ROUTES } from 'routes/route.constants';

import constants from 'utlis/constants';
import { onLoadImageError } from 'utlis/globalFunctions';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export const ProjectCard = ({ project, screenStatus, setSelectedProjectOption }) => {
  const dispatch = useDispatch();

  const authUser = useSelector(state => state.userStore);

  const [updateName, setUpdateName] = useState(false);
  const [updatingName, setUpdatingName] = useState(false);
  const [projectName, setProjectName] = useState(project?.name);

  const { collaborators, collaboratorMarginRight } = useMemo(
    () => ({
      collaborators: [...project?.collaborators]?.reverse(),
      collaboratorMarginRight: (
        parseInt(styles.avatarSize)
          - (parseInt(styles.maxCollaboratorsListWidth) - 15) / project?.collaborators?.length
      ),
    }),
    project?.collaborators
  );

  useEffect(() => {
    document.addEventListener('click', outsideProjectThumbnailClick, false);
    document.addEventListener('click', handleClickOutsideInput, false);

    return () => {
      document.removeEventListener('click', outsideProjectThumbnailClick);
      document.removeEventListener('click', handleClickOutsideInput);
    };
  });

  return (
    <div className={styles['thumbnail']}>
      <div className={styles['thumbnail__button']}>
        <Link
          className={cn(styles['project-thumbnail-container'])}
          to={ROUTES.BOARD(project?.slug)}
        >
          <div className={styles['letters-icon']}>
            <img
              className={styles['thumbnail__image']}
              src={project?.thumbnail || projectThumbnailImage}
              alt={
                project?.thumbnail
                  ? project.name
                  : 'Default Project Icon'
              }
            />
          </div>
        </Link>
        {/* Project Text Detail */}
        <div className={styles['thumbnail__projectContainer']}>
          {updateName
            ? (
              <div onClick={e => e.stopPropagation()} className={styles['project-name-container']}>
                <Input
                  className={cn(styles.input, 'text-md letter-sm text-black')}
                  placeholder="Enter ProjectName"
                  value={projectName}
                  onChange={onChangeName}
                  onPressEnter={updateProjectName}
                  onClick={e => e.stopPropagation()}
                  disabled={updatingName}
                />
              </div>
            )
            : (
              <Link
                className={cn(styles['project-name-container'])}
                to={ROUTES.BOARD(project?.slug)}
              >
                <div
                  className={cn('ellipsis', styles['project-name'])}
                >
                  <span>
                    {projectName}
                  </span>
                </div>
                <div className={styles['last-edited']}>
                  Edited
                  {' '}
                  <DateFromNow date={project?.updated_at} />
                </div>
              </Link>
            )}

          {/* Avatar Logic on Project Thumbnail */}
          <div className="flex space-between px-16">
            <div className={styles['thumbnail__avatars']}>
              <img
                src={project?.owner?.profile_photo || defaultUserAvatar}
                onError={e => onLoadImageError(e, defaultUserAvatar)}
                alt={`Owner - ${project?.owner?.full_name}`}
                className={styles['avatar']}
              />
              {
                collaborators
                  ?.slice(0, 2)
                  ?.map(({ uuid, user: { profile_photo, full_name } }) => (
                    <img
                      key={uuid}
                      src={profile_photo || defaultUserAvatar}
                      onError={e => onLoadImageError(e, defaultUserAvatar)}
                      alt={`Collaborator - ${full_name}`}
                      className={styles['avatar']}
                      style={{
                        marginRight: collaborators?.length > 2
                          ? -collaboratorMarginRight
                          : undefined,
                      }}
                    />
                  )
                )
              }
              {
                collaborators?.length > 2
                  && (
                    <DropdownMenu
                      overlay={
                        <Menu
                          items={
                            collaborators
                              ?.slice(2, collaborators.length)
                              ?.map(({ uuid, user: { profile_photo, full_name } }, index) => ({
                                key: index,
                                label: (
                                  (
                                    <div className="flex">
                                      <img
                                        key={uuid}
                                        src={profile_photo}
                                        onError={e => onLoadImageError(e, defaultUserAvatar)}
                                        alt={`Collaborator - ${full_name}`}
                                        className={styles['avatar']}
                                        style={{
                                          marginRight: collaborators?.length > 2
                                            ? -collaboratorMarginRight
                                            : undefined,
                                        }}
                                      />
                                      <span className="ml-16">
                                        {full_name}
                                      </span>
                                    </div>
                                  )),
                              }))
                          }
                        />
                      }
                      placement="bottom"
                    >
                      <button
                        className={styles['avatar-button']}
                      >
                        +
                        {collaborators.length - 1}
                      </button>
                    </DropdownMenu>
                  )
              }
            </div>
            {
              authUser?.uuid === project?.owner?.uuid && (
                <DropdownMenu
                  overlayClassName={styles['project-card-menu']}
                  overlay={
                    <Menu
                      items={[
                        {
                          key: 1,
                          label: (
                            <button
                              onClick={() => handleMarkAsCompleted()}
                              className={styles['thumbnail__menuButton']}
                            >
                              <FontAwesomeIcon className={styles['thumbnail__menuIcon']} icon={faCheck} />
                              <span className={styles['thumbnail__menuText']}>
                                Mark as completed
                              </span>
                            </button>
                          ),
                          className: cn({ hide: project?.is_complete }),
                        },
                        {
                          key: 2,
                          label: (
                            <button
                              onClick={handleProjectRename}
                              id="rename"
                              className={styles['thumbnail__menuButton']}
                            >
                              <FontAwesomeIcon className={styles['thumbnail__menuIcon']} icon={faPencil} />
                              <span className={styles['thumbnail__menuText']}>
                                Rename Project
                              </span>
                            </button>
                          ),
                        },
                        {
                          key: 3,
                          label: (
                            <button
                              onClick={() => handleProjectDelete()}
                              className={cn(styles['thumbnail__menuButton'], styles['danger-color'])}
                            >
                              <FontAwesomeIcon className={styles['thumbnail__menuIcon']} icon={faTrashCan} />
                              <span className={styles['thumbnail__menuText']}>
                                Delete
                              </span>
                            </button>
                          ),
                        },
                      ]}
                    />
                  }
                  placement="bottomRight"
                >
                  <button
                    className={styles['thumbnail__threeDots']}
                    onClick={() => handleProjectOption()}
                  >
                    <img src={threeDots} alt="ThreeDots" />
                  </button>
                </DropdownMenu>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );

  // Function to remove the Delete Menu when click outside of the Project Thumbnail
  function outsideProjectThumbnailClick(event) {
    event.stopPropagation();
    const isClickInsideThumbnailThreeDots = !!event
      .composedPath()
      .find(el => el.id == constants.ThumbnailThreeDotsId);

    if (isClickInsideThumbnailThreeDots) {
      return;
    }
  }

  function handleProjectDelete() {
    if (project?.uuid) {
      dispatch(deleteProject(project, screenStatus))
        .then(() => dispatch(getProjectStatusCountAction()));
    }
  }

  function handleClickOutsideInput(event) {
    if (!event.target.closest(`.${styles['input']}`) && !event.composedPath().find(el => el.id == 'rename')) {
      setUpdatingName(false);
      setUpdateName(false);
    }
  }

  function onChangeName(e) {
    setProjectName(e.target.value);
  }

  function handleProjectRename() {
    setUpdateName(true);
  }

  async function updateProjectName() {
    if (project?.uuid) {
      setUpdatingName(true);

      try {
        const response = await dispatch(renameProject(project.uuid, projectName));

        await dispatch(initializeProjects(screenStatus));
        await dispatch(getProjectStatusCountAction());
        successReactToastify(response?.data?.message || 'Project name updated successfully!');
      }
      catch (error) {
        errorReactToastify(error.response.data.detail || 'Something went wrong, please try again later.');
        setProjectName(project?.name);
      }

      setUpdatingName(false);
      setUpdateName(false);
    }
  }

  async function handleMarkAsCompleted() {
    if (project?.uuid) {
      try {
        const response = await dispatch(markProjectAsCompleted(project));

        await dispatch(initializeProjects(screenStatus));
        await dispatch(getProjectStatusCountAction());
        successReactToastify(response?.data?.message);
      }
      catch (error) {
        errorReactToastify(error.response.data.detail);
      }
    }
  }

  function handleProjectOption() {
    setSelectedProjectOption(project);
  }
};

ProjectCard.propTypes = {
  project: PropTypes.object,
  screenStatus: PropTypes.number,
  setSelectedProjectOption: PropTypes.func,
};
