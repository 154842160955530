import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { MILESTONE_METADATA } from 'utlis/workspace.constants';

import styles from './styles.module.scss';

export const MilestoneIcon = ({ type, size, isCompleted, className }) => {
  const Icon = useMemo(
    () => MILESTONE_METADATA[type]?.icon || (() => null),
    [type]
  );

  return (
    <div
      className={cn(styles['milestone-icon'], className)}
      style={{
        backgroundColor: MILESTONE_METADATA[type]?.backgroundColor,
        width: size,
        height: size,
      }}
    >
      {
        isCompleted
          ? <FontAwesomeIcon icon={faCircleCheck} size="lg" />
          : <Icon width={size / 2} height={size / 2} />
      }
    </div>
  );
};

MilestoneIcon.propTypes = {
  type: PropTypes.string.isRequired,

  className: PropTypes.string,
  isCompleted: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MilestoneIcon.defaultProps = {
  size: 36,
};
