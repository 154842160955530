import { Form, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export default function CustomCheckbox({ name, className, rules = [], required, ...props }) {
  return (
    <Form.Item
      name={name}
      {...props}
      rules={[
        {
          required: required,
          message: 'This field is required',
        },
        ...rules,
      ]}
    >
      <Checkbox
        className={cn(styles.checkbox, className)}

        {...props}
      />
    </Form.Item>
  );
}

CustomCheckbox.Group = function CheckboxGroup({ name, className, rules = [], required, ...props }) {
  return (
    <Form.Item
      name={name}
      {...props}
      rules={[
        {
          required: required,
          message: 'This field is required',
        },
        ...rules,
      ]}
    >
      <Checkbox.Group
        className={cn(styles.checkbox, className)}

        {...props}
      />
    </Form.Item>
  );
};

CustomCheckbox.propTypes = CustomCheckbox.Group.propTypes = {
  name: PropTypes.string.isRequired,

  className: PropTypes.string,
  rules: PropTypes.array,
  required: PropTypes.bool,
};
