import { ApiManager } from 'utlis/api_manager';
import { VOTE_CONTENT_TYPE_NAMES } from 'utlis/constants';

const voteApi = new ApiManager(process.env.REACT_APP_BASE_URL, 1, 'project/votes');

export class VoteService {
  static VOTE_CONTENT_TYPE_NAMES = VOTE_CONTENT_TYPE_NAMES

  static async getFileVoteValue(objectUUID, contentTypeName) {
    return (
      await voteApi.get({
        object_id: objectUUID,
        content_type_name: contentTypeName,
      })
    ).data.data;
  }

  static async changeFileVoteValue(objectUUID, contentTypeName) {
    return voteApi.post({
      content_type_name: contentTypeName,
      object_uuid: objectUUID,
    });
  }
}
