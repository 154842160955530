import http from 'utlis/http';

export const getProjectStatusCountService = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/project_status_count/`;

  try {
    const { data } = await http.get(url);

    return data;
  }
  catch (e) {
    console.error(e);
  }
};

export const getAllProjectTypes = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/project_types/`;
  const { data } = await http.get(url);

  return data;
};

export const getCollaboratorsOfProject = async (projectId, FigmaFileKeys) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/fetch_figma_users/`;

  const { data } = await http.post(url, {
    figma_file_keys: FigmaFileKeys,
    project_uuid: projectId,
  });

  return data;
};

export const getReadWriteKey = async () => {
  const resp = await http.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/plugin/keys/`);

  return resp.data;
};

export const getUserDetails = async read_key => {
  const resp = await http.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/plugin/access-token?read_key=${read_key}`);

  return resp.data;
};

export const connectGoogleDoc = async (access, authCompleteURL) => {
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/gdocs_connect/?return_url=${authCompleteURL}`,
    headers: {
      Authorization: access ? `Bearer ${access}` : undefined,
    },
  };

  await http(config).then(
    response => {
      const { data } = response;

      const connectGoogleDocURL = data.url;

      window.open(connectGoogleDocURL, '_self');
    }
  );
};
