import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export const FirstViewBodyUI = ({
  image,
  text,
  heading,
  className,
}) => (
  <div
    className={cn(
      styles['first-view-body-wrapper'],
      'flex flex-column text-center flex-component-center',
      className
    )}
  >
    {heading && (
      <p className="text-xl text-weight-500">
        {heading}
      </p>
    )}
    <img
      src={image}
      alt="FirstViewIcon"
      className="mb-24"
    />
    <p className={cn(styles['first-view-body-text'], 'm-zero')}>
      {text}
    </p>
  </div>
);

FirstViewBodyUI.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string,
  className: PropTypes.string,
};
