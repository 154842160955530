import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import cn from 'classnames';
import { SketchPicker } from 'react-color';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export const ColorPaletteCard = ({
  index,
  onDelete,
  onEdit,
  form,
  isReadonly,
}) => {
  const { uuid, color, caption } = form.getFieldValue(['colorPalette', index]);
  const [selectedColor, setSelectedColor] = useState(color);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      document.addEventListener('click', clickOutside);
      return () => document.removeEventListener('click', clickOutside);
    },
    []
  );

  return (
    <Form.Item
      shouldUpdate
      name={index}
      rules={[{
        // eslint-disable-next-line no-unused-vars
        validator: (rule, value) =>
          value.caption ? Promise.resolve() : Promise.reject(new Error('*This field is required')),
      }]}
    >
      <div
        className={cn(
          styles.color__paletteCardWrapper,
          {
            [styles.readonly_color__paletteCardWrapper]: isReadonly,
          }
        )}
      >
        <div
          className={cn(
            styles.color__container,
            'flex just-center align-center'
          )}
          style={{ background: selectedColor }}
        >
          <div className={styles.color__codeText}>
            {selectedColor}
          </div>
          <div
            className={cn(
              'flex-column',
              styles.action__buttonWrapper
            )}
          >
            <Button
              data-id={`add-color-${index}`}
              className={styles.color__actionButton}
              onClick={() => setShowColorPicker(true)}
            >
              Add Color
            </Button>
            <Button
              className={cn(styles.color__actionButton, 'mt-8')}
              loading={isLoading}
              onClick={onDeletePaletteCard}
            >
              Delete
            </Button>
          </div>
        </div>
        <div className={styles.color__paletteCardFooter}>
          <input
            placeholder="Color name"
            defaultValue={caption}
            onChange={e =>
              onEdit(uuid, e.target.value, color, index)
            }
            className={styles.color__paletteInput}
            readOnly={isReadonly}
          />
        </div>
      </div>
      {
        showColorPicker
          && (
            <div data-id={`sketch-picker-${index}`}>
              <SketchPicker
                className={styles.sketch__pickerInput}
                color={selectedColor}
                onChange={({ hex }) => setSelectedColor(hex)}
                onChangeComplete={({ hex }) => onEdit(uuid, caption, hex, index)}
                presetColors={[]}
                disableAlpha
              />
            </div>
          )
      }
    </Form.Item>
  );

  function clickOutside(event) {
    const colorPickerWrapperEl = event.composedPath().find(
      el => el.getAttribute
        && (
          el.getAttribute('data-id') === `sketch-picker-${index}`
          || el.getAttribute('data-id') === `add-color-${index}`
        )
    );

    if (!colorPickerWrapperEl) {
      setShowColorPicker(false);
    }
  }
  async function onDeletePaletteCard() {
    setIsLoading(true);
    try {
      await onDelete(uuid);
    }
    finally {
      setIsLoading(false);
    }
  }
};

ColorPaletteCard.propTypes = {
  index: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  form: PropTypes.object,
  isReadonly: PropTypes.bool,
};
