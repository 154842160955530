import { useSearchParams } from 'react-router-dom';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import styles from './styles.module.scss';

export const PaymentComplete = () => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get('state') || 'success';

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title={state === 'success' ? 'You\'re all set!' : 'Oops something went wrong'}
      description={
        state === 'success'
          ? 'Now you can return to Figma and get more feedback on your designs.'
          : 'Something went wrong with your payment, if you have any issues please reach out to contact@designpro.com'
      }
      hideNav
    />
  );
};
