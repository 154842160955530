import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { fullPriceFormat } from 'utlis/string_format';

import { ReactComponent as DollarIcon } from 'assets/DollarIcon.svg';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function MakePayment({
  paymentItem,

  onPaymentDeny,
  onPayPayment,

  ...props
}) {
  const [isPaymentDenyInProgress, setIsPaymentDenyInProgress] = useState(false);
  const [isPayPaymentInProgress, setIsPayPaymentInProgress] = useState(false);

  return (
    <div {...props}>
      <p className="m-zero text-weight-500 text-20 letter-xs text-black-opacity-0-60">
        Make Payment
      </p>
      <div className={cn(styles.payment__makePaymentBox, 'flex align-center space-between mt-sm-md')}>
        <div className="flex align-center">
          <DollarIcon />
          <span className="text-md text-black-opacity-0-90 letter-sm ml-12">
            {
              paymentItem
                ? (`
                  Designer requested payment of ${fullPriceFormat(
                    paymentItem.amount,
                    {
                      currency: paymentItem.currency,
                      minimumFractionDigits: 2,
                    }
                  )}
                `)
                : 'No payment requests yet'
            }
          </span>
        </div>
        <div className={cn({ hide: !paymentItem })}>
          {
            onPaymentDeny && (
              <Button
                loading={isPaymentDenyInProgress}
                disabled={isPaymentDenyInProgress}
                className="text-fuzzyWuzzyBrown"
                isText
                onClick={paymentDeny}
              >
                DENY
              </Button>
            )
          }
          {
            onPayPayment && (
              <Button
                className="ml-32"
                loading={isPayPaymentInProgress}
                disabled={isPayPaymentInProgress}
                onClick={payPayment}
              >
                PAY
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );

  async function paymentDeny() {
    try {
      setIsPaymentDenyInProgress(true);

      await onPaymentDeny(paymentItem);
    }
    finally {
      setIsPaymentDenyInProgress(false);
    }
  }

  async function payPayment() {
    try {
      setIsPayPaymentInProgress(true);

      await onPayPayment(paymentItem);
    }
    finally {
      setIsPayPaymentInProgress(false);
    }
  }
}

MakePayment.propTypes = {
  paymentItem: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  onPaymentDeny: PropTypes.func,
  onPayPayment: PropTypes.func,
};
