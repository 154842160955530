import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faExternalLink, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Image as ImageAntd, Select, Space, Spin } from 'antd';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import cn from 'classnames';

import aiIcon from 'assets/ai_icon.svg';
import commentIcon from 'assets/comment_icon.svg';
import dropdownArrow from 'assets/dropdown_arrow.svg';
import figJamIcon from 'assets/FigJam_logo.svg';
import figmaIcon from 'assets/figma_logo.svg';
import jiraIcon from 'assets/jira_icon.svg';
import notesIcon from 'assets/notes_icon.svg';
import priorityIcon from 'assets/priority_icon.svg';
import slackIcon from 'assets/slack_dark.svg';
import sourceIcon from 'assets/source_icon.svg';
import statusIcon from 'assets/status_icon.svg';
import labelsIcon from 'assets/tag_icon.svg';
import transcriptionIcon from 'assets/transcription_icon.svg';
import userIcon from 'assets/user_icon.svg';

import { Button } from 'Components/Button';

import { CommentContainer } from 'Pages/ProjectDetails/sub-routes/Board/components/CommentFileContainer';
import styles from 'Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/styles.module.scss';

import { setProjectFigmaCollaborators } from 'Redux/Actions/kanbanBoardActions';
import { selectProject } from 'Redux/Actions/projectActions';

import { getCollaboratorsOfProject } from 'services/projectServices';
import { getFigmaCommentImage } from 'services/tasks';

import {
  KANBAN_BOARD_FILE_TYPE_VALUES,
  KANBAN_BOARD_ID_TO_STRING,
  KANBAN_BOARD_PRIORITY_OPTIONS,
  KANBAN_BOARD_TAG_COLORS,
} from 'utlis/constants';
import { getUserIcon } from 'utlis/globalFunctions';

import { AssigneeDropdown } from './components/AssigneeDropdown';
import { LabelsDropdown } from './components/LabelsDropdown';

export function TaskDetails({
  label,
  assignees,
  setAssignees,
  taskPriority,
  taskDetails,
  allFilesComments,
  selectedTask,
  fileDetails,
  handleChangeTag,
  handleRemoveTage,
  isCreatingNewTask,
  handleChangePriority,
  handleTranscriptFileModal,
  handleChangeStatus,
  taskStatusValue,
  taskStatusInfo,
}) {
  const dispatch = useDispatch();

  const [imageUrl, setImageUrl] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [mappedAiAssignees, setMappedAiAssignees] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);
  const currentProjectColumns = useSelector(state => state.kanbanBoard?.currentProject?.details?.columns);
  const selectedProject = useSelector(state => state.selectedProject);
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);
  const userDetails = useSelector(state => state.userStore);
  const projectFigmaCollaborators = useSelector(state => state.kanbanBoard.projectFigmaCollaborators);
  const userUUID = useSelector(state => state.userProfile?.uuid);

  const collaboratorsOfProject = useMemo(() => selectedProject?.collaborators.map(
    val => val.user
  ), [selectedProject]);

  const membersOfCurrentFile = useMemo(() => projectFigmaCollaborators?.non_project_members?.filter(
    person => person?.figma_file_key === taskDetails?.figma_file_key
  ), [projectFigmaCollaborators?.non_project_members, taskDetails?.figma_file_key]);

  const isFigmaTask = useMemo(
    () => taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA,
    [taskDetails?.file_type]
  );

  const aiAssignees = useMemo(() => taskDetails?.ai_assignees || [], [taskDetails?.ai_assignees]);

  const { comment } = useMemo(
    () => {
      if (!isCreatingNewTask && taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA) {
        return {
          comment: getFigmaThreadById(taskDetails?.comment_id),
        };
      }

      return {
        comment: null,
      };
    },
    [
      allFilesComments,
      taskDetails,
      taskDetails?.comment_id,
    ]
  );

  const containerTitle = useMemo(
    () => {
      switch (!isCreatingNewTask && taskDetails?.file_type) {
        case KANBAN_BOARD_FILE_TYPE_VALUES.NOTES:
          return 'Note';
        default:
          return 'Qoute';
      }
    },
    [taskDetails?.file_type]
  );

  const isCustomFile = useMemo(
    () => fileDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM || isCreatingNewTask,
    [selectedTask]
  );

  const aiAssigneesIndicator = useMemo(
    () => (
      <div
        className={styles['ai-assignees']}
        onClick={() => setShowDropdown(prevState => !prevState)}
      >
        <img src={aiIcon} alt="ai icon" />
        <div className={styles['assignees']}>
          {
            mappedAiAssignees.map(
              assignee => (
                <img
                  key={assignee.email || assignee.full_name || assignee}
                  src={getUserIcon(assignee, false)}
                  alt="Magic Wand Icon"
                  className={styles['user-icon']}
                />
              )
            )
          }
        </div>
        <div className={styles['help-popup']}>
          <div className={styles['arrow-top']} />
          <p className={styles['help-text']}>
            Assignee candidates recommended by AI
          </p>
        </div>
      </div>
    ),
    [mappedAiAssignees]
  );

  useEffect(
    () => {
      if (userDetails.email && selectedProject?.owner?.email) {
        const data = allMappedAiUsers();

        setMappedAiAssignees(data);
      }
    },
    [
      userDetails,
      selectedProject?.owner,
    ]
  );

  useEffect(
    () => {
      if (taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA) {
        getImageUrl();

        return () => {
          setImageUrl('');
        };
      }
    },
    [
      taskDetails?.comment_id,
      taskDetails,
    ]
  );

  const slackComments = useMemo(() => {
    if (selectedTask?.fileDetails.slack_thread) {
      return selectedTask?.fileDetails.slack_thread.map(val => ({
        user: {
          img_url: val.user?.image,
          handle: val.user?.name,
        },
        created_at: new Date(val.ts * 1000),
        message: val.text,
      }));
    }

    return [];
  }, [selectedTask?.fileDetails.slack_thread]);

  const showCommentContainer = useMemo(
    () => {
      if (taskDetails?.file_type) {
        return taskDetails.file_type !== KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM
          && taskDetails.file_type !== KANBAN_BOARD_FILE_TYPE_VALUES.NOTES
          && taskDetails.file_type !== KANBAN_BOARD_FILE_TYPE_VALUES.JIRA;
      }

      return !isCreatingNewTask;
    },
    [taskDetails, isCreatingNewTask]
  );

  return (
    <>
      <div className={styles['task-meta-wrapper']}>
        {
          !isCustomFile
            && (
              <div className={styles['indicator']}>
                <img src={aiIcon} alt="recommendation icon" />
                AI recommendation
              </div>
            )
        }
        <div className={styles['task-detail']}>
          <div className={styles['task-detail-title-container']}>
            <img src={statusIcon} width={12} height={12} alt="status icon" />
            <div className={styles['title']}>
              Status
            </div>
          </div>
          <Space wrap>
            <Select
              className={cn(styles['status-select'], styles[`${KANBAN_BOARD_ID_TO_STRING[taskStatusValue % 4 || 4]}-status`])}
              defaultValue={taskStatusInfo[taskStatusValue]}
              style={{ width: 120 }}
              suffixIcon={<img src={dropdownArrow} alt="Menu Icon" />}
              onChange={handleChangeStatus}
              options={
                currentProjectColumns.map(
                  (col, ind) => ({
                    value: ind + 1,
                    label: (
                      <span>
                        {
                          col.name.charAt(0).toUpperCase() + col.name.slice(1)
                        }
                      </span>
                    ),
                  })
                )
              }
              bordered={false}
            />
          </Space>
        </div>
        <div className={styles['task-detail']}>
          <div className={styles['task-detail-title-container']}>
            <img src={priorityIcon} width={12} height={12} alt="priority icon" />
            <div className={styles['title']}>
              Priority
            </div>
          </div>
          <Space wrap>
            <Select
              className={cn(styles['priority-select'], styles[`${taskPriority || taskPriority}-priority`])}
              defaultValue={taskPriority}
              style={{ width: 120 }}
              suffixIcon={<img src={dropdownArrow} alt="Menu Icon" />}
              onChange={handleChangePriority}
              options={
                Object.values(KANBAN_BOARD_PRIORITY_OPTIONS).map(
                  priority => ({
                    value: priority,
                    label: (
                      <>
                        <span className={styles[`${priority}-priority-circle`]} />
                        <span>
                          {
                            priority.charAt(0).toUpperCase() + priority.slice(1)
                          }
                        </span>
                      </>
                    ),
                  })
                )
              }
              bordered={false}
            />
          </Space>
        </div>
        <div className={styles['task-detail']}>
          <div className={styles['task-detail-title-container']}>
            <img src={labelsIcon} width={12} height={12} alt="labels icon" />
            <div className={styles['title']}>
              Labels
            </div>
          </div>
          <div className="flex align-center flex-wrap gap-8">
            {
              label.map(
                val => (
                  <span
                    style={{
                      backgroundColor: KANBAN_BOARD_TAG_COLORS.AI_GENERATED.backgroundColor,
                    }}
                    className={styles['tags']}
                    key={val}
                  >
                    {!isCustomFile && <img src={aiIcon} alt="Magic Wand Icon" />}
                    <span className={cn({ [styles['manual-tag']]: isCustomFile })}>
                      {val}
                    </span>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className={styles['close-icon']}
                      onClick={() => handleRemoveTage(val)}
                    />
                  </span>
                )
              )
            }
            <Dropdown
              autoAdjustOverflow
              overlay={
                <LabelsDropdown
                  taskLabels={label}
                  handleChangeTag={handleChangeTag}
                />
              }
              trigger={['click']}
            >
              <Button
                isText
                className={styles['add-button']}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add label
              </Button>
            </Dropdown>
          </div>
        </div>
        <div
          className={cn(
            styles['task-detail'],
            styles['assignee-detail']
          )}
        >
          <div className={styles['task-detail-title-container']}>
            <img src={userIcon} alt="user icon" className={styles['icon-styles']} />
            Assignee
          </div>
          <div className="flex align-center gap-8 h-fit">
            {
              assignees.length !== 0
                && (
                  <div className={styles['assignees-container']}>
                    {
                      assignees.map(
                        val => (
                          <span
                            className={styles['assignee']}
                            key={val.email || val.full_name || val}
                          >
                            <img
                              src={getUserIcon(val)}
                              alt="Magic Wand Icon"
                              className={styles['user-icon']}
                            />
                            {val.full_name || val.email || val}
                          </span>
                        )
                      )
                    }
                  </div>
                )
            }
            <div className={styles['assignee-button-container']}>
              <Dropdown
                autoAdjustOverflow
                visible={showDropdown}
                onVisibleChange={handleOnVisibleChange}
                overlay={
                  <AssigneeDropdown
                    assignees={assignees}
                    setAssignees={setAssignees}
                    mappedAiAssignees={mappedAiAssignees}
                    setMappedAiAssignees={setMappedAiAssignees}
                    allMappedAiUsers={allMappedAiUsers}
                    projectFigmaCollaborators={projectFigmaCollaborators}
                    collaboratorsOfProject={collaboratorsOfProject}
                    membersOfCurrentFile={membersOfCurrentFile}
                    isFigmaTask={isFigmaTask}
                  />
                }
                trigger={['click']}
              >
                <Button
                  isText
                  className={styles['add-button']}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {
                    assignees.length === 0 && aiAssignees.length !== 0
                      ? 'Select assignee'
                      : 'Add assignee'
                  }
                </Button>
              </Dropdown>
              {/* AI Recommended Assignee */}
              {assignees.length === 0 && aiAssignees.length !== 0 && aiAssigneesIndicator}
            </div>
          </div>
        </div>
        {
          !isCustomFile
            && (
              <div className={cn('flex flex-column align-start', styles['task-detail'])}>
                <div className={styles['task-detail']}>
                  <div className={styles['task-detail-title-container']}>
                    <img src={sourceIcon} width={12} height={12} alt="labels icon" />
                    <div className={styles['title']}>
                      {taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA ? 'Source' : 'File'}
                    </div>
                  </div>
                  {
                    taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
                      ? (
                        <Link
                          className={styles['figma-link']}
                          to={getCommentUrl(comment?.uri)}
                          target="_blank"
                        >
                          <img className="mr-8" src={figmaIcon} width={16} height={16} alt="figma icon" />
                          <span className={styles['link-text']}>
                            {taskDetails?.figma_file_name}
                          </span>
                          <FontAwesomeIcon className={styles['external-link-icon']} icon={faExternalLink} />
                        </Link>
                      )
                      : (
                        <Button
                          isText
                          className={cn('flex align-center', styles['transcription-button'])}
                          onClick={() => handleTranscriptFileModal(fileDetails)}
                        >
                          <img
                            className={styles['icon']}
                            src={fileTypeIcon(fileDetails?.file_type)}
                            alt="Transcription Icon"
                          />
                          <span className={styles['transcription-title']}>
                            {fileDetails?.figma_file_name}
                          </span>
                        </Button>
                      )
                  }
                </div>
                {
                  taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
                    && (
                      <div
                        className="text-center width-full"
                      >
                        {
                          isImageLoading
                            ? <Spin />
                            : (
                              imageUrl
                                ? (
                                  <ImageAntd
                                    wrapperClassName={styles['comment-image']}
                                    src={imageUrl}
                                  />
                                )
                                : (
                                  <div className="flex">
                                    A screenshot for this comment cannot be generated
                                  </div>
                                )
                            )
                        }
                      </div>
                    )
                }
              </div>
            )
        }
      </div>
      {
        showCommentContainer
          && (
            <div className={styles['comment-container-wrapper']}>
              {
                taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
                  && (
                    <>
                      <div>
                        <span className="flex align-center">
                          <img className="mr-4" src={commentIcon} alt="comment icon" />
                          {
                            `Comments (${comment?.replies?.length || 0})`
                          }
                        </span>
                      </div>
                      {
                        !!comment?.replies?.length
                          && sortReplies(comment?.replies)?.map(replyComment => (
                            <CommentContainer
                              key={replyComment.id}
                              commentDetails={replyComment}
                            />
                          ))
                      }
                    </>
                  )
              }
              {
                taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FigJam
                  && (
                    <>
                      <span className="flex align-center">
                        <img className="mr-4" src={commentIcon} alt="comment icon" />
                        Sticky Note
                      </span>
                      <span className={styles['sticky-note-container']}>
                        {taskDetails?.sticky}
                      </span>
                    </>
                  )
              }
              {
                taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.TRANSCRIPTION
                  && (
                    <CommentContainer
                      containerTitle={containerTitle}
                      transcriptionDetail={selectedTask?.taskDetails}
                    />
                  )
              }
              {
                taskDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.SLACK
                  && (
                    <>
                      <span className="flex align-center">
                        <img className="mr-4" src={commentIcon} alt="comment icon" />
                        {
                          `Slack messages (${slackComments.length || 0})`
                        }
                      </span>
                      {slackComments.map(
                        message => (
                          <CommentContainer
                            key={message.created_at}
                            commentDetails={message}
                          />
                        )
                      )}
                    </>
                  )
              }
            </div>
          )
      }
    </>
  );

  function fileTypeIcon(fileType) {
    switch (fileType) {
      case KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA:
        return figmaIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.NOTES:
        return notesIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.SLACK:
        return slackIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.JIRA:
        return jiraIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.FigJam:
        return figJamIcon;
      default:
        return transcriptionIcon;
    }
  }

  function sortReplies(replies) {
    return replies?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }

  function getCommentUrl(uri) {
    return uri ? `https://www.figma.com/file/${taskDetails?.figma_file_key}?${uri}` : '';
  }

  function getFigmaThreadById(id) {
    const commentObject = allFilesComments[id];

    if (commentObject !== undefined) {
      return commentObject.parent_id !== undefined ? allFilesComments[commentObject.parent_id] : commentObject;
    }
  }

  async function getImageUrl() {
    if (comment?.comment_details?.client_meta) {
      setIsImageLoading(true);

      try {
        const { data } = await getFigmaCommentImage(
          taskDetails?.figma_file_key,
          figmaAppToken?.access_token,
          comment,
          userUUID
        );
        const storage = getStorage();

        const url = await getDownloadURL(ref(storage, data.highlighted_image_path));

        setImageUrl(url);
      }
      finally {
        setIsImageLoading(false);
      }
    }
  }

  async function handleOnVisibleChange(visible) {
    setShowDropdown(visible);

    if (visible) {
      await dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
      await handleFigmaCollaborators();
    }
  }

  async function handleFigmaCollaborators() {
    const allFigmaFileKeys = savedFigmaFiles.filter(
      file => file?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
    ).map(
      file => file?.figma_file_key
    );

    const data = await getCollaboratorsOfProject(selectedProject.uuid, allFigmaFileKeys);

    dispatch(setProjectFigmaCollaborators(data));
  }

  function allUsers() {
    const currentUser = {
      full_name: userDetails.full_name,
      email: userDetails.email,
      image: userDetails.profile_photo,
      isOwner: selectedProject?.owner?.email === userDetails.email,
    };

    const projectOwner = selectedProject?.owner?.email !== userDetails.email
      ? [
        {
          full_name: selectedProject?.owner?.full_name,
          email: selectedProject?.owner?.email,
          image: selectedProject?.owner?.profile_photo,
          isOwner: true,
        },
      ]
      : [];

    const allCollaborators = projectFigmaCollaborators?.project_members
      || collaboratorsOfProject
      || [];

    const projCollaborators = allCollaborators.filter(
      ({ email }) => email !== userDetails.email
    ).map(
      ({ full_name, profile_photo, email }) => ({
        full_name,
        email,
        image: profile_photo,
      })
    );

    const FigmaUsersOfFile = membersOfCurrentFile || [];

    const figmaFileUsers = isFigmaTask
      ? FigmaUsersOfFile.map(({ full_name, img_url, handle }) => (
        {
          full_name: full_name || handle,
          email: '',
          image: img_url,
        }
      ))
      : [];

    return [currentUser, ...projectOwner, ...projCollaborators, ...figmaFileUsers];
  }

  function allMappedAiUsers() {
    const users = allUsers();
    const aiDataToAssignees = aiAssignees.map(assignee => {
      const foundUser = users.find(user => user.full_name.includes(assignee));

      if (foundUser) {
        return foundUser;
      }

      return {
        full_name: assignee,
        email: '',
        image: '',
      };
    });

    return aiDataToAssignees;
  }
}

TaskDetails.propTypes = {
  isCreatingNewTask: PropTypes.bool,
  taskPriority: PropTypes.string,
  assignees: PropTypes.object,
  setAssignees: PropTypes.func,
  handleChangePriority: PropTypes.func,
  label: PropTypes.array,
  handleChangeTag: PropTypes.func,
  handleRemoveTage: PropTypes.func,
  taskDetails: PropTypes.object,
  allFilesComments: PropTypes.object,
  selectedTask: PropTypes.object,
  fileDetails: PropTypes.object,
  handleTranscriptFileModal: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  taskStatusValue: PropTypes.string,
  taskStatusInfo: PropTypes.object,
};
