import { useEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Menu } from 'antd';
import cn from 'classnames';

import sortCheckIcon from 'assets/check_icon.svg';
import copyIcon from 'assets/copy_icon.svg';
import sortArrowIcon from 'assets/sort_arrow_icon.svg';
import sortIcon from 'assets/sort_icon.svg';
import threeDotsIcon from 'assets/ThreeDotsIcon.svg';

import { DropdownMenu } from 'Components/DropdownMenu';

import { Task } from 'Pages/ProjectDetails/sub-routes/Board/components/Task';

import KanbanBoardServices from 'services/kanban_board';

import { KANBAN_BOARD_ID_TO_STRING, KANBAN_BOARD_SORT_OPTIONS } from 'utlis/constants';
import copyTaskDetails from 'utlis/copy_task_details';
import copyValue from 'utlis/copy_value';
import { successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export function KanbanBoardCols({
  draggableIndex,
  columnId,
  column,
  sortOption,
  editTask,
  deleteTask,
  openTaskDetailsPopup,
  allFilesComments,
  colIdToEdit,
  setColIdToEdit,
  newColName,
  setNewColName,
  openCustomTaskModal,
  inProgressStatus,
}) {
  const selectedProject = useSelector(state => state.selectedProject);
  const currentProjectColumns = useSelector(state => state.kanbanBoard?.currentProject?.details?.columns);

  const [sortCurrentOption, setSortCurrentOption] = useState(KANBAN_BOARD_SORT_OPTIONS.NEWEST_FIRST);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [isSortOptionMenu, setIsSortOptionMenu] = useState(false);
  const [updatedCol, setUpdatedCol] = useState(column);
  const [destroyPopup, setDestroyPopup] = useState(false);

  const columnIdString = useMemo(
    () => KANBAN_BOARD_ID_TO_STRING[column?.status % 4 || 4],
    [column]
  );

  useEffect(
    () => {
      sortTasks();
    },
    [sortCurrentOption, column]
  );

  useEffect(
    () => {
      setSortCurrentOption(sortOption);
    },
    [sortOption]
  );

  useEffect(
    () => {
      const handleClick = () => {
        isSortOptionMenu ? setDestroyPopup(true) : setDestroyPopup(false);
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    },
    [isSortOptionMenu]
  );

  return (
    <Draggable draggableId={columnId} index={draggableIndex}>
      {
        draggableProvided => (
          <div
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            ref={draggableProvided.innerRef}
          >
            <Droppable
              key={columnId}
              droppableId={columnId}
              type="task"
            >
              {
                (provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={cn(
                      styles['droppable-col'],
                      {
                        [styles[`${columnIdString}-draggingOver`]]: snapshot.isDraggingOver,
                        [styles[columnIdString]]: !snapshot.isDraggingOver,
                      }
                    )}
                    onMouseEnter={() => setShowMenuIcon(true)}
                    onMouseLeave={() => setShowMenuIcon(false)}
                  >
                    <div className={cn('flex align-center space-between', styles['col-name-container'])}>
                      {
                        colIdToEdit === columnId
                          ? (
                            <Input
                              id={columnId}
                              value={newColName}
                              onChange={e => handleChange(e)}
                              className={styles['col-title-input']}
                              onPressEnter={() => handleEnterPress()}
                              onClick={e => e.stopPropagation()}
                            />
                          )
                          : (
                            <div
                              className={cn(styles['col-title'], styles[`${columnIdString}-title`])}
                              onClick={e => startEditingColumnName(e, columnId)}
                            >
                              <span className={styles['col-name']}>
                                {updatedCol.name || column.name}
                              </span>
                              <span className={styles['col-items']}>
                                {column.items?.length}
                              </span>
                            </div>
                          )
                      }
                      <div className="flex align-center">
                        <button
                          disabled={inProgressStatus}
                          className={cn(
                            styles['add-button'],
                            { [styles['add-button-disabled']]: inProgressStatus }
                          )}
                          onClick={() => openCustomTaskModal(columnId)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <DropdownMenu
                          destroyPopupOnHide={!isSortOptionMenu || destroyPopup}
                          overlayClassName={cn(styles['col-menu'], { [styles['sort-option-menu']]: isSortOptionMenu })}
                          overlay={
                            <Menu
                              items={isSortOptionMenu ? sortOptionItems() : colOptionItems()}
                              onClick={
                                isSortOptionMenu
                                  ? () => setIsSortOptionMenu(false)
                                  : null
                              }
                            />
                          }
                          placement="right"
                        >
                          <button
                            className={cn(
                              styles['option-button'],
                              { [styles['show-option-button']]: showMenuIcon }
                            )}
                            onClick={() => setIsSortOptionMenu(false)}
                          >
                            <img src={threeDotsIcon} width={16} height={11} alt="Option Icon" />
                          </button>
                        </DropdownMenu>
                      </div>
                    </div>
                    <div className={styles['task-container']}>
                      {
                        updatedCol.items.map(({ taskID, taskDetails }, index) => (
                          <Task
                            key={taskID}
                            index={index}
                            taskID={taskID}
                            taskDetails={taskDetails}
                            editTask={editTask}
                            deleteTask={deleteTask}
                            allFilesComments={allFilesComments}
                            onClick={() => openTaskDetailsPopup(taskID, taskDetails)}
                          />
                        ))
                      }
                    </div>
                    {/* We dont require until its functional */}
                    {/* <div className={styles['add-new-task']}>
                      <FontAwesomeIcon className={styles['plus-icon']} icon={faPlus} />
                      <span className={styles['new-button']}>
                        New
                      </span>
                    </div> */}
                    {provided.placeholder}
                  </div>
                )
              }
            </Droppable>
          </div>
        )
      }

    </Draggable>
  );

  function handleEnterPress() {
    if (colIdToEdit) {
      KanbanBoardServices.updateColumnData(selectedProject?.uuid, colIdToEdit, newColName || column.name);
    }
    setColIdToEdit('');
    setNewColName('');
  }

  function handleChange(e) {
    setNewColName(e.target.value);
  }

  function startEditingColumnName(e, id) {
    /** if we were already editing a column and directly jumped to another then we need to make sure changes to the old one are saved */
    if (colIdToEdit && newColName !== currentProjectColumns[Number(colIdToEdit) - 1]['name']) {
      KanbanBoardServices.updateColumnData(selectedProject?.uuid, colIdToEdit, newColName);
    }

    setColIdToEdit(id);
    setNewColName(column.name);
    e.stopPropagation();
  }

  function sortTasks() {
    let sortedItems;

    switch (sortCurrentOption) {
      case KANBAN_BOARD_SORT_OPTIONS.NEWEST_FIRST:
        sortedItems = sortByCreatedAt(true);
        break;
      case KANBAN_BOARD_SORT_OPTIONS.OLDEST_FIRST:
        sortedItems = sortByCreatedAt(false);
        break;
      default:
        sortedItems = column.items;
        break;
    }

    const updatedColumn = { ...column, items: sortedItems };

    setUpdatedCol(updatedColumn);
  }

  function sortByCreatedAt(descending) {
    const sortedItems = column.items.sort((a, b) => {
      const dateA = new Date(a.taskDetails?.source_created_at?.toDate());
      const dateB = new Date(b.taskDetails?.source_created_at?.toDate());

      return descending
        ? dateB - dateA // sort by date descending
        : dateA - dateB; // sort by date ascending
    });

    return sortedItems;
  }

  function colOptionItems() {
    return [
      {
        key: 1,
        label: (
          <button
            className={cn(styles['menu-button'], 'sort-button')}
            onClick={() => setIsSortOptionMenu(true)}
          >
            <img className="mr-8" src={sortIcon} alt="Sort Icon" />
            <span>
              Sort by:
            </span>
            <span className={styles['sort-option']}>
              {sortCurrentOption}
            </span>
            <img className="ml-12" src={sortArrowIcon} alt="Sort Arrow Icon" />
          </button>
        ),
      },
      {
        key: 2,
        label: (
          <button
            className={cn(styles['menu-button'])}
            onClick={copyAllTasks}
          >
            <img className="mr-8" src={copyIcon} alt="Copy Icon" />
            <span>
              Copy all tasks
            </span>
          </button>
        ),
      },
    ];
  }

  async function copyAllTasks() {
    let textToCopy = '';

    updatedCol.items.forEach(({ taskDetails }) => {
      textToCopy = textToCopy + copyTaskDetails(taskDetails, allFilesComments) + '\n\n\n';
    });

    await copyValue(textToCopy);
    successReactToastify(
      'Tasks text copied to clipboard'
    );
  }

  function sortOptionItems() {
    return Object.values(KANBAN_BOARD_SORT_OPTIONS)
      .map(
        option => (
          {
            key: option,
            label: (
              <button
                className={styles['sort-option-button']}
                onClick={() => setSortCurrentOption(option)}
              >
                <span>
                  {option}
                </span>
                {
                  sortCurrentOption === option
                    && (
                      <img className="ml-12" src={sortCheckIcon} alt="Sort Check Icon" />
                    )
                }
              </button>
            ),
          }
        )
      );
  }
}

KanbanBoardCols.propTypes = {
  draggableIndex: PropTypes.number.isRequired,
  columnId: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  sortOption: PropTypes.string.isRequired,
  editTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  openTaskDetailsPopup: PropTypes.func.isRequired,
  allFilesComments: PropTypes.object.isRequired,
  colIdToEdit: PropTypes.string.isRequired,
  setColIdToEdit: PropTypes.func.isRequired,
  newColName: PropTypes.string.isRequired,
  setNewColName: PropTypes.func.isRequired,
  openCustomTaskModal: PropTypes.func.isRequired,
  inProgressStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
