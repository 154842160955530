import { Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function AddQuestionButton({
  addButtonClassName,
  onAddQuestion,

  ...props
}) {
  return (
    <Dropdown
      overlay={
        <Menu
          items={
            [
              {
                questionType: 'text_field',
                text: 'Text Question',
              },
              {
                questionType: 'singleselect_dropdown',
                text: 'Menu Options',
              },
            ].map(({ questionType, text }, questionTypeIdx) => ({
              key: questionTypeIdx,
              label: (
                <Button
                  onClick={() => onAddQuestion({
                    uuid: `${questionType}-${Math.trunc(Math.random() * Math.pow(10, 5))}`,
                    question_format: questionType,
                    isNew: true,
                    isDone: false,
                  })}
                  isText
                  className="text-button"
                >
                  {text}
                </Button>
              ),
            }))
          }
        />
      }
      trigger={['click']}
      disabled={!onAddQuestion}

      {...props}
    >
      <Button
        className={cn(styles['add-question-button'], addButtonClassName)}
        shape="round"
        icon={<FontAwesomeIcon icon={faPlus} className="mr-sm" />}
        disabled={!onAddQuestion}
        isInvert
      >
        ADD QUESTION
      </Button>
    </Dropdown>
  );
}

AddQuestionButton.propTypes = {
  onAddQuestion: PropTypes.func,
  addButtonClassName: PropTypes.string,
};
