import { ApiManager } from 'utlis/api_manager';
import { REACTIONS_CONTENT_TYPE_NAMES } from 'utlis/constants';

const reactionsApi = new ApiManager(process.env.REACT_APP_BASE_URL, 1, 'reactions');

export default class ReactionsService {
  static REACTIONS_CONTENT_TYPE_NAMES = REACTIONS_CONTENT_TYPE_NAMES

  static async getReactions(objectsUUIDs) {
    return (await reactionsApi.get({ uuids: objectsUUIDs })).data;
  }

  static leaveReaction(reactionUnicode, objectsUUID, contentTypeName, milestoneId, projectUUID) {
    return reactionsApi.patch(null, {
      unique_code: reactionUnicode,
      content_type_name: contentTypeName,
      object_uuid: objectsUUID,
      is_increment: true,
      milestone_id: milestoneId,
      project_uuid: projectUUID,
    });
  }

  static deleteReaction(reactionUnicode, objectsUUID, contentTypeName, milestoneId, projectUUID) {
    return reactionsApi.patch(null, {
      unique_code: reactionUnicode,
      content_type_name: contentTypeName,
      object_uuid: objectsUUID,
      is_increment: false,
      milestone_id: milestoneId,
      project_uuid: projectUUID,
    });
  }
}
