import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

const { TextArea } = Input;

import styles from './styles.module.scss';

export function MessageForm({
  onSubmit,

  className,
  messageSuggestion,
  messagePlaceholder,
  autoFocusOnMessage,
  autoFocusAfterSubmit,
  resetFormAfterSubmit,
  textareaBackgroundColor,
  messageRules,
  allowMultiLineMessage,

  ...props
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const [form] = Form.useForm();

  const textareaRef = useRef();

  const InputComponent = useMemo(
    () => allowMultiLineMessage ? TextArea : Input,
    [allowMultiLineMessage]
  );

  useEffect(
    () => {
      if (textareaRef.current && autoFocusOnMessage) {
        textareaRef.current.focus();
      }
      if (textareaRef.current && autoFocusAfterSubmit && isSubmited) {
        textareaRef.current.focus();
      }
    },
    [textareaRef.current, isSubmited]
  );

  useEffect(
    () => {
      form.setFieldsValue({
        message: messageSuggestion,
      });
    },
    [messageSuggestion]
  );

  return (
    <Form
      className={cn(styles['message-form'], className)}
      initialValues={{ message: messageSuggestion }}
      form={form}
      onFinish={onSendMessage}

      {...props}
    >
      <Form.Item
        name="message"
        className={cn(styles['textarea-wrapper'], 'flex-grow m-zero')}
        rules={[{ required: true, message: '' }, ...messageRules]}
      >
        <InputComponent
          ref={textareaRef}
          autoSize={{ minRows: 1, maxRows: 5 }}
          bordered={false}
          placeholder={messagePlaceholder}
          className={cn(styles['textarea'], 'text-black text-md')}
          disabled={isSubmiting}
          style={{
            backgroundColor: textareaBackgroundColor,
          }}
          onBlur={() => {
            if (isSubmited) {
              setIsSubmited(false);
            }
          }}
          onChange={() => {
            if (isSubmited) {
              setIsSubmited(false);
            }
          }}
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() => (
          <Button
            className={styles['send-message-button']}
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            type="primary"
            shape="round"
            htmlType="submit"
            disabled={
              isSubmiting
                || !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          />
        )}
      </Form.Item>
    </Form>
  );

  async function onSendMessage(args) {
    try {
      setIsSubmiting(true);

      await onSubmit(args);

      if (resetFormAfterSubmit) {
        form.resetFields();
      }

      setIsSubmited(true);
    }
    finally {
      setIsSubmiting(false);
    }
  }
}

MessageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  className: PropTypes.string,
  messageSuggestion: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  autoFocusOnMessage: PropTypes.bool,
  autoFocusAfterSubmit: PropTypes.bool,
  resetFormAfterSubmit: PropTypes.bool,
  textareaBackgroundColor: PropTypes.string,
  messageRules: PropTypes.array,
  allowMultiLineMessage: PropTypes.bool,
};

MessageForm.defaultProps = {
  autoFocusOnMessage: true,
  textareaBackgroundColor: styles.defaultTextareaBackgroundColor,
  messageRules: [],
  allowMultiLineMessage: true,
  resetFormAfterSubmit: true,
};
