import { ReactComponent as NavbarLogo } from 'assets/images/landing/logo.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export const OautNav = () => {
  const userObject = JSON.parse(localStorage.getItem('userObject'));

  return (
    <div className={styles['nav']}>
      <div className={styles['nav-inner']}>
        <NavbarLogo className={styles['logo']} />
        <div className={styles['user-details']}>
          <img
            className={styles['user-avatar']}
            src={userObject.profile_photo || defaultUserAvatar}
            onError={e => onLoadImageError(e, defaultUserAvatar)}
            alt="Owner avatar"
          />
          <p className={styles['email']}>
            {userObject.email}
          </p>
        </div>
      </div>
    </div>
  );
};
