import { serialize as objectToFormData } from 'object-to-formdata';

import { ApiManager } from 'utlis/api_manager';
import { COMMENT_TYPES } from 'utlis/constants';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class CommentService {
  static COMMENT_TYPES = COMMENT_TYPES

  static async getComments(projectUUID, { fileUUID, commentsType, milestoneId, limit = 10 } = {}) {
    return (await api.setUrl(`project/${projectUUID}/comment`).get({
      files: fileUUID,
      comment_type: commentsType,
      milestone_id: milestoneId,
      limit,
    })).data;
  }

  static async sendComment(projectUUID, params, config) {
    return (await api.setUrl(`project/${projectUUID}/comment`).post(params, config)).data;
  }

  static async deleteComment(projectUUID, commentUUID) {
    return (await api.setUrl(`project/${projectUUID}/comment/${commentUUID}`).delete()).data;
  }

  static async editComment(projectUUID, commentUUID, text, otherData) {
    return (await api.setUrl(`project/${projectUUID}/comment`).patch(commentUUID, {
      text,
      ...otherData,
    })).data;
  }

  static sendCommentOnMilestone(
    projectUUID,
    {
      text,
      content,
      milestone_id,
      parent_comment,
      ...otherData
    }
  ) {
    return CommentService.sendComment(
      projectUUID,
      {
        comment_type: parent_comment ? undefined : CommentService.COMMENT_TYPES.COMMENT_ON_ANY_OTHER_MILESTONE,
        text,
        content,
        milestone_id,
        parent_comment,
        ...otherData,
      }
    );
  }

  static sendCommentOnFigmaFile(
    projectUUID,
    {
      text,
      fileUUID,
      coordinates,
      content,
      annotation_file,
      node_id,
      parent_comment,
      ...otherData
    }
  ) {
    return CommentService.sendComment(
      projectUUID,
      {
        comment_type: parent_comment ? undefined : CommentService.COMMENT_TYPES.COMMENT_INSIDE_FIGMA_FILE,
        text,
        file: fileUUID,
        node_offset: coordinates,
        content,
        annotation_file,
        node_id,
        parent_comment,
        ...otherData,
      }
    );
  }

  static sendCommentOnGDriveFile(projectUUID, {
    text,
    fileUUID,
    coordinates,
    content,
    annotation_file,
    parent_comment,
  }) {
    return CommentService.sendComment(
      projectUUID,
      objectToFormData({
        comment_type: parent_comment ? undefined : CommentService.COMMENT_TYPES.COMMENT_INSIDE_GOOGLE_DRIVE_FILE,
        text,
        file: fileUUID,
        content: JSON.stringify(content),
        annotation_file,
        coordinates: JSON.stringify(coordinates),
        parent_comment,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  static async getCommentsSuggestions(projectType, milestoneType, userType) {
    return (await api.setUrl('project/comment_suggestions').get({
      project_type__name: projectType,
      milestone_type: milestoneType,
      for_which_user: userType,
    })).data.results;
  }
}
