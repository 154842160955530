import { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export function PaymentStatusCell({ userProfile, text: paymentStatus }) {
  const clientStatuses = {
    initiated: 'Incomplete',
    captured: 'Paid',
    requested: 'Requested by Designer',
    denied: 'Denied',
  };
  const designerStatuses = {
    initiated: 'Started',
    captured: 'Received',
    requested: 'Request submitted',
    denied: 'Denied',
  };

  const paymentStatusText = useMemo(
    () => userProfile.permissions?.isDesigner
      ? designerStatuses[paymentStatus]
      : clientStatuses[paymentStatus],
    [paymentStatus]
  );

  return (
    <span className={styles[`payment__status_${paymentStatus}`]}>
      {paymentStatusText || paymentStatus}
    </span>
  );
}

PaymentStatusCell.propTypes = {
  userProfile: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
};
