import { useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Input, Menu, Tooltip } from 'antd';
import cn from 'classnames';

import aiIcon from 'assets/ai_icon.svg';
import copyIcon from 'assets/copy_icon.svg';
import customTaskIcon from 'assets/custom_task_icon.svg';
import deleteIcon from 'assets/delete_icon.svg';
import figJamIcon from 'assets/FigJam_logo.svg';
import figmaFileIcon from 'assets/figma_file_icon.svg';
import invitedIcon from 'assets/invited_icon.svg';
import jiraIcon from 'assets/jira_icon.svg';
import magicWandIcon from 'assets/magic_wand_icon.svg';
import notesIcon from 'assets/notes_icon.svg';
import pencilIcon from 'assets/pencil_icon.svg';
import slackIconUrl from 'assets/slack_dark.svg';
import tagIcon from 'assets/tag_icon.svg';
import threeDotsIcon from 'assets/ThreeDotsIcon.svg';
import transcriptionIcon from 'assets/transcription_icon.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { Button } from 'Components/Button';
import { DropdownMenu } from 'Components/DropdownMenu';

import { INTERACTION_STATUS, KANBAN_BOARD_FILE_TYPE_VALUES, TASK_DETAILS } from 'utlis/constants';
import copyTaskDetails from 'utlis/copy_task_details';
import copyValue from 'utlis/copy_value';
import { capitalizeFirstLetter } from 'utlis/string_format';
import { successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export function Task({
  index,
  taskID,
  taskDetails,
  editTask,
  deleteTask,
  allFilesComments,
  onClick,
}) {
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);

  const [isEditMode, setIsEditMode] = useState(false);

  const [form] = Form.useForm();

  const fileDetails = useMemo(
    () => savedFigmaFiles.find(file => file.figma_file_key === taskDetails.figma_file_key),
    [savedFigmaFiles, taskDetails]
  );

  const assignees = (taskDetails?.assignees
    && typeof taskDetails.assignees !== 'string'
    && taskDetails.assignees.filter(val => val.email))
    || []
  ;

  const fileTypeToIcon = {
    [KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA]: figmaFileIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.TRANSCRIPTION]: transcriptionIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM]: customTaskIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.NOTES]: notesIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.SLACK]: slackIconUrl,
    [KANBAN_BOARD_FILE_TYPE_VALUES.JIRA]: jiraIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.FigJam]: figJamIcon,
  };

  const showBorderColor = useMemo(
    () => taskDetails[TASK_DETAILS.INTERACTION_STATUS]
          && taskDetails[TASK_DETAILS.INTERACTION_STATUS] === INTERACTION_STATUS.UNREAD,
    [taskDetails]
  );

  const labels = useMemo(
    () => {
      if (typeof taskDetails.label === 'string') {
        return capitalizeFirstLetter(taskDetails.label);
      }

      const labelsCount = taskDetails.label.length;

      if (labelsCount <= 3) {
        return taskDetails.label.map(tag => capitalizeFirstLetter(tag)).join(', ');
      }

      const firstTwoLabels = taskDetails.label
        .slice(0, 2)
        .map(tag => capitalizeFirstLetter(tag))
        .join(', ');

      const remainingLabelsCount = labelsCount - 2;

      return (
        <div className={styles['labels-container']}>
          {firstTwoLabels}
          &#160;
          and
          {' '}
          {remainingLabelsCount}
          {' '}
          more
        </div>
      );
    },
    [taskDetails.label]
  );

  return (
    <Draggable
      key={taskID}
      index={index}
      draggableId={taskID}
    >
      {
        draggableProvided => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className={cn(
              styles['draggable-item'],
              {
                [styles['add-border']]: showBorderColor,
              }
            )}
            style={{ ...draggableProvided.draggableProps.style }}
            onClick={handleItemClick}
          >
            {
              !isEditMode && (
                <div className={styles['task-buttons']}>
                  <button
                    type="button"
                    className={styles['edit-button']}
                    onClick={() => setIsEditMode(true)}
                  >
                    <img src={pencilIcon} alt="Edit Icon" />
                  </button>
                  <DropdownMenu
                    destroyPopupOnHide
                    overlayClassName={styles['task-card-menu']}
                    overlay={
                      <Menu
                        items={menuItems()}
                      />
                    }
                    placement="right"
                  >
                    <button
                      type="button"
                      className={styles['option-button']}
                    >
                      <img src={threeDotsIcon} width={16} height={13} alt="Option Icon" />
                    </button>
                  </DropdownMenu>
                </div>
              )
            }
            {
              isEditMode
                ? (
                  <Form
                    form={form}
                    initialValues={{ title: taskDetails.title }}
                    className="edit-description"
                    onFinish={handleEditTask}
                  >
                    <Form.Item
                      name="description"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea
                        placeholder="Enter Title"
                        maxLength={400}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        onPressEnter={handleEditTask}
                      />
                    </Form.Item>
                    <div className="flex flex-col just-end">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </Form.Item>
                      <Button.White
                        className="ml-8"
                        onClick={cancelEditTask}
                      >
                        Cancel
                      </Button.White>
                    </div>
                  </Form>
                )
                : (
                  <>
                    <div className="flex">
                      <div className={styles['magic-wand-icon']}>
                        <Tooltip
                          placement="bottom"
                          title="Created by AI"
                          overlayInnerStyle={{ 'borderRadius': '8px' }}
                        >
                          <img src={magicWandIcon} alt="Magic Wand Icon" />
                        </Tooltip>
                      </div>
                      <div className={styles['description']}>
                        {taskDetails.title}
                      </div>
                    </div>
                    <div className={styles['tag']}>
                      <img width={12} height={12} src={tagIcon} alt="Tag Icon" />
                      {labels}
                    </div>
                    <div className={styles['tags']}>
                      <div className={styles['file-name-container']}>
                        <img
                          src={fileTypeToIcon[fileDetails?.file_type]}
                          alt="File Icon"
                        />
                        {fileDetails?.figma_file_name}
                      </div>
                      <div className="flex align-center gap-6">
                        <div className={cn('flex align-center', styles[`${taskDetails?.priority.toLowerCase()}-priority`])}>
                          <span className={styles[`${taskDetails?.priority.toLowerCase()}-priority-circle`]} />
                          {taskDetails?.priority}
                        </div>
                        <div className={styles['assignees']}>
                          {assignees.length !== 0 && (
                            assignees.map(
                              (assignee, assigneeNo) => (
                                <img
                                  key={assignee.email || assignee.name || assignee}
                                  src={getUserIcon(assignee)}
                                  alt="Magic Wand Icon"
                                  className={cn(
                                    styles['user-icon'],
                                    {
                                      [styles['move-icon-right']]: assigneeNo !== assignees.length - 1,
                                    }
                                  )}
                                />
                              )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
            }
          </div>
        )
      }
    </Draggable>
  );

  function menuItems() {
    return [
      {
        key: 2,
        label: (
          <button
            className={styles['menu-button']}
            onClick={() => handleCopyText(taskDetails)}
          >
            <img src={copyIcon} alt="Copy Icon" />
            <span>
              Copy
            </span>
          </button>
        ),
      },
      {
        key: 3,
        label: (
          <button
            className={cn(styles['menu-button'], styles['delete-button'])}
            onClick={handleDeleteTask}
          >
            <img src={deleteIcon} alt="Delete Icon" />
            <span>
              Delete
            </span>
          </button>
        ),
      },
    ];
  }

  function handleEditTask() {
    const { title } = form.getFieldsValue();

    editTask(taskID, title);
    setIsEditMode(false);
  }

  function cancelEditTask() {
    setIsEditMode(false);
  }

  function handleDeleteTask() {
    deleteTask(taskID);
  }

  async function handleCopyText(details) {
    const textToCopy = copyTaskDetails(details, allFilesComments) + '\n';

    await copyValue(textToCopy);

    successReactToastify('Task copied to clipboard');
  }

  function handleItemClick(event) {
    const clickedElement = event.target;

    // Check if the clicked element or any of its ancestors have the "task-buttons" class
    const isTaskButtonClicked = clickedElement.closest(`.${styles['task-buttons']}`);
    const isMenuButtonClicked = clickedElement.closest('.ant-dropdown-menu');
    const isEditFormClick = clickedElement.closest('.edit-description');

    if (!isTaskButtonClicked && !isMenuButtonClicked && !isEditFormClick) {
      onClick(); // Handle the click event as usual
    }
  }

  function getUserIcon(val) {
    if (val.profile_photo || val.img_url) {
      return val.profile_photo || val.img_url;
    }
    if (val.email && !val.full_name) {
      return invitedIcon;
    }
    if (typeof val === 'string') {
      return aiIcon;
    }

    return defaultUserAvatar;
  }
}

Task.propTypes = {
  index: PropTypes.number.isRequired,
  taskID: PropTypes.string.isRequired,
  taskDetails: PropTypes.object.isRequired,
  editTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  allFilesComments: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
