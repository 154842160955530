import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class MoodboardService {
  static async getMoodboardImagesList(projectUUID, milestoneId) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/third_party_image`).get({ limit: 1000 }))
      ?.data;
  }

  static async addImagesToMoodboard(projectUUID, milestoneId, images) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/third_party_image`).post({
      images,
    }))?.data;
  }
  static async addGDriveImagesToMoodboard(projectUUID, milestoneId, images) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/drive_image`).post({
      images: images.map(driveFile => ({
        file_id: driveFile.id,
        file_url: driveFile.url,
        file_name: driveFile.name,
        thumbnail_url: driveFile.embedUrl || driveFile.url,
        icon_link: driveFile.iconUrl,
        mime_type: driveFile.mimeType,
        width: driveFile.width,
        height: driveFile.height,
        image_caption: '',
      })),
    }))?.data;
  }

  static async addTextToMoodboard(projectUUID, milestoneId, text) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/third_party_image/text_panel`).post({
      text,
    }))?.data;
  }

  static async updateMoodboardItem(projectUUID, milestoneId, itemUUID, { imageCaption, text }) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/third_party_image`).patch(
      itemUUID,
      {
        image_caption: imageCaption,
        text,
      }
    ))?.data;
  }
  static async updateGDriveMoodboardItem(projectUUID, milestoneId, itemUUID, { imageCaption, text }) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/drive_image`).patch(
      itemUUID,
      {
        image_caption: imageCaption,
        text,
      }
    ))?.data;
  }

  static deleteMoodboardImage(projectUUID, milestoneId, imageUUID) {
    return api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/third_party_image`)
      .delete(imageUUID);
  }
  static deleteGDriveMoodboardImage(projectUUID, milestoneId, imageUUID) {
    return api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/drive_image`)
      .delete(imageUUID);
  }
}
