import http from 'utlis/http';
import actionTypes from '../constants';
import { successReactToastify, errorReactToastify } from '../../utlis/toasts';

export const getAllContainers = milestoneId => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${milestoneId}/container/`,
    };

    return await http(config)
      .then(response => {
        dispatch({
          type: actionTypes.GET_ALL_CONTAINERS,
          payload: response.data,
        });

        return response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const updateContainer = (
  fileContainers,
  setPaginationValue,
) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: fileContainers?.next,
    };

    http(config)
      .then(response => {
        dispatch({
          type: actionTypes.UPDATE_CONTAINERS,
          payload: response.data,
        });
        if (setPaginationValue) {
          setPaginationValue(prevState => prevState + 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const deleteContainer = (
  currentMilestone,
  container,
) => async dispatch => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${currentMilestone?.id}/container/${container?.uuid}/`,
    };

    return await http(config)
      .then(() => {
        successReactToastify(
          'Design Concepts Container is deleted successfully',
        );
        dispatch(getAllContainers(currentMilestone?.id));
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);

        return Promise.reject(error);
      });
  }
  catch (error) {
    console.log('Error is ', error);

    throw new Error(error);
  }
};

export const removeAllContainers = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_ALL_CONTAINERS, payload: [] });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};
