import PropTypes from 'prop-types';
import { Menu, Spin } from 'antd/es';
import cn from 'classnames';

import { DropdownMenu } from 'Components/DropdownMenu';

import styles from './styles.module.scss';

export const NewMilestonesDropdown = ({
  milestonesList,
  children,
  isClientView,
  onAddNewMilestone,

  ...props
}) => {
  return (
    <DropdownMenu
      destroyPopupOnHide
      overlay={
        milestonesList && milestonesList.length
          ? (
            <Menu
              items={menuItems(milestonesList)}
            />
          )
          : (
            <div className="flex flex-component-center px-lg py-lg">
              <Spin size="large" />
            </div>
          )
      }
      placement="right"

      {...props}
    >
      {children}
    </DropdownMenu>
  );

  function menuItems(milestoneList) {
    if (!milestoneList || !milestoneList.length) {
      return [];
    }

    const childrens = milestonesList.map(({ name, type, can_client_add, can_user_create_more }) => ({
      key: name,
      label: (
        <div
          className={cn(styles['dropdown-item'], 'no-wrap')}
        >
          {name}
        </div>
      ),
      className: cn({ hide: isClientView && !can_client_add }),
      disabled: !can_user_create_more,
      onClick: () => {
        handleCreateMilestoneModal(name, type);
      },
    }));

    return childrens;
  }

  function handleCreateMilestoneModal(name, type) {
    {/* To set name and type milestone and make modal visible */ }
    onAddNewMilestone(name, type);
  }
};

NewMilestonesDropdown.propTypes = {
  milestonesList: PropTypes.array,
  children: PropTypes.node,
  onAddNewMilestone: PropTypes.func,
  isClientView: PropTypes.bool,
};
