import { Link, useLocation } from 'react-router-dom';

import { SettingsTabItem } from 'Pages/Settings/components/SettingsTabs/components/SettingsTabItem';

import { ROUTES } from 'routes/route.constants';

const tabItems = [
  {
    title: 'Profile',
    description: 'Add your name, role and address',
    url: ROUTES.PROFILE_SETTINGS,
  },
  {
    title: 'Connected apps',
    description: 'Review the apps integrated in your account',
    url: ROUTES.CONNECTED_APPS_SETTINGS,
  },
  {
    title: 'Language and timezone',
    description: 'Add your preferred language and timezone',
    url: ROUTES.LANGUAGE_AND_TIMEZONE_SETTINGS,
  },
];

export const SettingsTabs = () => {
  const routerLocation = useLocation();

  return (
    <div className="flex align-center just-start mt-32">
      {
        tabItems.map(item => (
          <Link to={item.url} key={item.url} className="flex mr-48">
            <SettingsTabItem
              key={item.url}
              isActive={routerLocation.pathname.includes(item.url)}
            >
              {item.title}
            </SettingsTabItem>
          </Link>
        ))
      }
    </div>
  );
};
