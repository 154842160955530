import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'Components/Button';

export function AnnotationTools({
  canvasRef,
  isAnnotationMode,
}) {
  const previousCanvasState = useRef([]); // to save the canvas state before undo

  const canvasLines = useMemo(
    () => {
      if (canvasRef?.current) {
        return JSON.parse(canvasRef?.current.getSaveData()).lines;
      }

      return [];
    },
    [canvasRef?.current?.getSaveData()]
  );

  return (
    <div className="mr-md">
      <Button
        className="ml-sm"
        onClick={undo}
        disabled={!isAnnotationMode || canvasLines?.length === 0}
        isInvert
      >
        <FontAwesomeIcon icon={faRotateLeft} />
      </Button>
      <Button
        className="ml-sm"
        onClick={redo}
        disabled={!isAnnotationMode || previousCanvasState.current.length === 0}
        isInvert
      >
        <FontAwesomeIcon icon={faRotateRight} />
      </Button>
    </div>
  );

  function undo() {
    previousCanvasState.current.push(canvasRef.current.getSaveData());

    canvasRef.current.undo();
  }

  function redo() {
    if (previousCanvasState.current.length > 0) {
      canvasRef.current.loadSaveData(previousCanvasState.current.pop(), true); // pop the last state and load it
    }
  }
}

AnnotationTools.propTypes = {
  canvasRef: PropTypes.object.isRequired,
  isAnnotationMode: PropTypes.bool.isRequired,
};
