import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu as DropdownMenuOptions } from 'antd';
import cn from 'classnames';

import designProLogo from 'assets/images/landing/logo.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { DropdownMenu } from 'Components/DropdownMenu';

import { ROUTES } from 'routes/route.constants';

import { logoutUser, onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export const ProjectListingHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userProfile = useSelector(state => state.userProfile);

  return (
    <div className={styles['listing-header-wrapper']}>
      <div className={cn('flex space-between align-center', styles['listing-header-container'])}>
        <Link to={ROUTES.LANDING}>
          <img className={styles.logoImage} src={designProLogo} alt="Logo" />
        </Link>
        <div className={cn('flex space-between', styles['nav-items-wrapper'])}>
          <a className={styles['nav-item']} href="mailto:help@designpro.ai">
            Help
          </a>
          <a className={styles['nav-item']} href="mailto:feedback@designpro.ai">
            Share feedback
          </a>
          <DropdownMenu
            overlayClassName={styles['dropdown-wrapper']}
            trigger={['click']}
            overlay={
              <DropdownMenuOptions
                items={
                  [
                    {
                      key: 'settings',
                      label: (
                        <Link className={styles['dropdown-option']} to={ROUTES.PROFILE_SETTINGS}>
                          Settings
                        </Link>
                      ),
                    },
                    {
                      key: 'log_out',
                      label: (
                        <button
                          className={cn(styles['logout-option'], styles['dropdown-option'])}
                          onClick={() => logoutUser(dispatch, navigate)}
                        >
                          Log Out
                        </button>
                      ),
                    },
                  ]
                }
              />
            }
          >
            <div className={cn('flex align-center pointer', styles['account-dropdown-trigger'])}>
              <img
                className={styles['user-avatar']}
                src={userProfile.profile_photo || defaultUserAvatar}
                onError={e => onLoadImageError(e, defaultUserAvatar)}
                alt="Owner avatar"
              />
              <span className={cn('mx-8', styles['nav-item'])}>
                Account
              </span>
              <FontAwesomeIcon icon={faAngleDown} className={cn('ml-xs', styles['angle-down-icon'])} />
            </div>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};
