import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class ReadonlyService {
  static async getReadonlyLinkForProject(projectUUID, config) {
    const readonlyUUID = (
      await api.setUrl(`project/${projectUUID}/read_only`).post(undefined, config)
    )?.data?.readonly_uuid;

    return `${location.origin}/view-project/${readonlyUUID}`;
  }

  static async getReadonlyProjectObj(readonlyUUID, config) {
    return (
      await api.setUrl('project/read_only/project_detail').get(
        {
          UID: readonlyUUID,
        },
        config
      )
    )?.data;
  }
}

export const readonlyUserProfile = {
  firstName: 'Guest',
  lastName: '',
  get full_name() {
    return [this.firstName || '', this.lastName || ''].join(' ').trim();
  },
  email: 'demo-user@designpro.ai',
  username: 'demo-user',
  permissions: {
    isDesigner: false,
    isClient: false,
    isReadonly: true,
  },
  appTokens: {
    googleDrive: null,
  },
  uuid: null,
  user_type: 'readonly',
  profile_photo: null,
  timezone: null,
  refresh: null,
  access: null,
  user_tokens: [],
  designer_profile: {
    is_stripe_acc: false,
    is_charges_enabled: false,
    pending_balance: '0.00',
    available_balance: '0.00',
    currency: 'USD',
    currencySymbol: '$',
  },
  address: null,
  logo_image: null,
  updated_timestamp: Date.now(),
};
