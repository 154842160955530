import { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { infoReactToastify } from 'utlis/toasts';

import StripePaymentService from 'services/stripe_payment';

import styles from './styles.module.scss';

export function UserWithoutStripe({ className, ...props }) {
  const [createLinkToStripeInProgress, setCreateLinkToStripeInProgress] = useState(false);

  return (
    <div
      className={cn(
        styles['designer-without-stripe-block'],
        'px-16 py-16 text-white flex space-between align-center',
        className
      )}

      {...props}
    >
      <p className="text-md text-weight-600 letter-xs m-zero">
        ACTION REQUIRED - Please set up a Stripe account to continue
      </p>
      <div className="flex align-center flex-shrink-0 ml-md">
        <Button
          className={cn(
            styles['add-stripe-account-button'],
            'ml-sm-md text-sm letter-xl text-weight-500 py-sm px-small-md-lg'
          )}
          ghost
          shape="round"
          disabled={createLinkToStripeInProgress}
          loading={createLinkToStripeInProgress}
          onClick={onAddStripeAccountButtonClick}
        >
          {
            createLinkToStripeInProgress
              ? 'GET LINK TO STRIPE ...'
              : 'ADD STRIPE ACCOUNT'
          }
        </Button>
      </div>
    </div>
  );

  async function onAddStripeAccountButtonClick() {
    setCreateLinkToStripeInProgress(true);

    try {
      const link = await StripePaymentService.getLinkToStripeAccount();

      infoReactToastify(`
        After a few seconds, you will be redirected to the Stripe account creation page.
        When you finish the survey, you will return to this page automatically.
      `);

      setTimeout(
        () => {
          location.href = link;
        },
        3000
      );
    }
    finally {
      setCreateLinkToStripeInProgress(false);
    }
  }
}

UserWithoutStripe.propTypes = {
  className: PropTypes.string,
};
