import PropTypes from 'prop-types';

import { fullPriceFormat } from 'utlis/string_format';

export function PriceCell({ text: price, record }) {
  return record.currency
    ? fullPriceFormat(price, { currency: record.currency, minimumFractionDigits: 2 })
    : price;
}

PriceCell.propTypes = {
  record: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
};
