import { useEffect, useRef } from 'react';
import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import globalQuestionnaireComponentsStyles from 'Components/questionnaireComponents/styles.module.scss';

import styles from './styles.module.scss';

export default function CustomDatePicker({ className, required, ...props }) {
  const datePickerRef = useRef();

  useEffect(
    () => {
      const datePickerInput = datePickerRef.current.querySelector('.ant-picker-input > input');
      const observer = new MutationObserver(onChange);

      observer.observe(datePickerInput, {
        attributes: true,
        childList: false,
        subtree: false,
      });

      onChange();

      return () => observer.disconnect();
    },
    []
  );

  return (
    <div className={styles['date-picker-wrapper']} ref={datePickerRef}>
      <Form.Item
        className={cn(
          globalQuestionnaireComponentsStyles['form-item'],
          'm-zero',
          className
        )}
        {...props}
        rules={[{ required: required }]}
      >
        <DatePicker
          className={cn(styles['date-picker'], globalQuestionnaireComponentsStyles['input-text'])}
          format="MMMM DD YYYY"
          getPopupContainer={() => datePickerRef.current}
          {...props}
        />
      </Form.Item>
      <span
        className={cn(
          styles['date-picker-size-checker'],
          globalQuestionnaireComponentsStyles['input-text'],
          className
        )}
      />
    </div>
  );

  function onChange() {
    const datePickerInput = datePickerRef.current.querySelector('.ant-picker-input > input');
    const datePickerSizeChecker = datePickerRef.current.querySelector(`.${styles['date-picker-size-checker']}`);

    datePickerSizeChecker.innerText = datePickerInput.value || datePickerInput.placeholder;

    const currentSize = datePickerSizeChecker.offsetWidth;

    if (Number.parseFloat(datePickerInput.getAttribute('size')) != currentSize) {
      datePickerInput.style.width = `${currentSize}px`;
    }
  }
}

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
};
