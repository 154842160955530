import { ApiManager } from 'utlis/api_manager';

const searchImageApi = new ApiManager(process.env.REACT_APP_BASE_URL, 1, 'project/search_image');
const dallEImageApi = new ApiManager(process.env.REACT_APP_BASE_URL, 1, 'openai/images');

export class ImageSearchService {
  static imageSearchApiSources = {
    google: 'google',
    pixabay: 'pixabay',
    usearch: 'usearch',
    unsplash: 'unsplash',
    dallE: 'dalle',
    local: 'local',
    gDrive: 'gDrive',
  }

  static async googleSearchImages(search, { limit = 10, page = 1 } = {}) {
    const data = (await searchImageApi.get({
      source: ImageSearchService.imageSearchApiSources.google,
      q: search,
      searchType: 'image',
      safe: 'active',
      imgSize: 'large',
      filter: 1,
      num: limit,
      start: (page - 1) * limit + 1,
    }))?.data;

    return {
      count: data.queries.nextPage[0].totalResults,
      results: data.items.map(({
        link,
        image: {
          height,
          width,
          thumbnailLink,
          thumbnailWidth,
          thumbnailHeight,
        },
      }) => ({
        uuid: link,
        thumbnailUrl: (
          thumbnailWidth < 640 && thumbnailHeight < 640
            ? null
            : thumbnailLink
        ),
        width,
        height,
        imageUrl: link,
      })),
    };
  }

  static async pixabaySearchImage(search, { limit = 10, page = 1 } = {}) {
    const data = (await searchImageApi.get({
      source: ImageSearchService.imageSearchApiSources.pixabay,
      q: search,
      safesearch: true,
      page,
      per_page: limit,
    }))?.data;

    return {
      count: data.totalHits,
      results: data.hits.map(({
        id,
        largeImageURL,
        // fullHDUrl,
        // imageURL,
        imageWidth,
        imageHeight,
        webformatURL,
      }) => ({
        uuid: id,
        thumbnailUrl: webformatURL,
        width: imageWidth,
        height: imageHeight,
        imageUrl: largeImageURL,
      })),
    };
  }

  static async usearchSearchImages(search, { limit = 50, page = 1 } = {}) {
    const data = (await searchImageApi.get({
      source: ImageSearchService.imageSearchApiSources.usearch,
      q: search,
      pageNumber: page,
      pageSize: limit,
      autoCorrect: true,
      safeSearch: true,
    }))?.data;

    return {
      count: data.totalCount,
      results: data.value.map(({
        url,
        width,
        height,
        thumbnail,
        thumbnailWidth,
        thumbnailHeight,
      }) => ({
        uuid: url,
        thumbnailUrl: (
          thumbnailWidth < 640 && thumbnailHeight < 640
            ? null
            : thumbnail
        ),
        width,
        height,
        imageUrl: url,
      })),
    };
  }

  static async unsplashSearchImages(search, { limit = 30, page = 1 } = {}) {
    const data = (await searchImageApi.get({
      source: ImageSearchService.imageSearchApiSources.unsplash,
      query: search,
      page: page,
      per_page: limit,
      content_filter: 'high',
    }))?.data;

    return {
      count: data.total,
      results: data.results.map(({
        id,
        width,
        height,
        urls: {
          full,
          small,
        },
      }) => ({
        uuid: id,
        thumbnailUrl: small,
        width,
        height,
        imageUrl: full,
      })),
    };
  }

  static async dallESearchImages(search, { limit = 4, size = '512x512', page = 1 } = {}) {
    const data = (await dallEImageApi.get({
      text: search,
      count: limit,
      size: size,
      offset: limit * (page - 1),
    }))?.data;

    return {
      count: limit,
      results: (data?.data || []).map(({ url }) => ({
        uuid: url,
        width: Number(size.split('x')[0]),
        height: Number(size.split('x')[1]),
        imageUrl: url,
      })),
    };
  }
}

export const INITIAL_IMAGE_LIST = {
  count: 0,
  results: [],
};
