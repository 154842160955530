import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function AddCaptionForm({ onSubmit, placeholder, className, onCancel, ...props }) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [form] = Form.useForm();

  return (
    <Form
      className={className}
      form={form}
      onFinish={onFinish}
      initialValues={{ caption: '' }}

      {...props}
    >
      <Form.Item
        name="caption"
        className="mb-12"
      >
        <Input
          className={styles['caption-input']}
          placeholder={placeholder}
        />
      </Form.Item>
      <div className="flex align-center just-end">
        {
          onCancel && (
            <Button
              isText
              onClick={onCancel}
              className="mr-24-important text-sm letter-xl text-lochinvar-opacity-0-80"
            >
              CANCEL
            </Button>
          )
        }
        <Form.Item
          noStyle
          shouldUpdate
        >
          {() => (
            <Button
              disabled={isSubmiting || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              type="primary"
              htmlType="submit"
            >
              {
                isSubmiting
                  ? 'SAVING ...'
                  : 'DONE'
              }
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );

  async function onFinish(values) {
    try {
      setIsSubmiting(true);

      await onSubmit(values);
    }
    finally {
      setIsSubmiting(false);
    }
  }
}

AddCaptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  placeholder: PropTypes.string,
  className: PropTypes.string,
  onCancel: PropTypes.func,
};
