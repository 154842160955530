import PropTypes from 'prop-types';

import { OautNav } from 'Pages/FigmaPluginAuth/components/OauthNav';

import styles from './styles.module.scss';

export const PopupParent = ({ children, hideNav }) => (
  <div className={styles['parent-container']}>
    {!hideNav && <OautNav />}
    <div className={styles['page-content']}>
      {children}
    </div>
  </div>
);

PopupParent.propTypes = {
  children: PropTypes.node.isRequired,
  hideNav: PropTypes.bool,
};
