import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import designProLogo from 'assets/designProLogo.svg';
import figmaLogo from 'assets/figma_logo.svg';
import plusIcon from 'assets/plus_icon.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { initializeProjects } from 'Redux/Actions/projectActions';

import { trackEvent } from 'services/eventTracking';
import FigmaServices from 'services/figma_file';

import { PROJECT_STATUS } from 'utlis/constants';

import styles from './styles.module.scss';

export const ConnectToFigma = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const projects = useSelector(state => state.projectStore);

  useEffect(
    () => {
      setIsLoading(true);
      trackEvent('[page] connect to figma', { 'page name': 'connect to figma' });

      dispatch(initializeProjects(PROJECT_STATUS.active))
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const projectUUID = useMemo(
    () => projects?.results?.length ? projects.results[0]?.uuid : '',
    [projects]
  );

  localStorage.setItem('lastPage', 'connectToFigma');

  const icons = (
    <>
      <img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />
      <img src={plusIcon} alt="plus icon" className={styles['small-icon']} />
      <img src={figmaLogo} alt="Figma Logo" className={styles['icon']} />
    </>
  );

  return (
    <AuthModel
      icons={icons}
      title="Connect to Figma"
      description="Connect DesignPro with your Figma account."
      button={{
        onClick: connectToFigma,
        text: 'CONNECT TO FIGMA',
        loading: isLoading,
        disabled: isLoading,
      }}
    />
  );

  function connectToFigma() {
    FigmaServices.openFigmaOauth(projectUUID, window.location.origin);
    trackEvent('[click] connect to figma', { 'page name': 'connect to figma' });
  }
};
