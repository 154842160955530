import { addDoc, collection, deleteDoc, doc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';

import { KANBAN_BOARD_DEFAULT_COLS } from 'utlis/constants';
import { Firestore } from 'utlis/firebase';
import http from 'utlis/http';

export default class KanbanBoardServices {
  static async getTasks(projectUUID) {
    const collectionRef = collection(Firestore, 'tasks');
    const q = query(collectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);

    const tasksData = querySnapshot.docs.map(
      currentDoc => (
        {
          taskID: currentDoc.id,
          taskDetails: currentDoc.data(),
        }
      )
    );

    return tasksData;
  }

  static async getTaskComments(projectUUID) {
    const fileCollectionRef = collection(Firestore, 'files');
    const q = query(fileCollectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);
    const comments = {};

    querySnapshot.docs.flatMap(currentDoc => {
      const file = currentDoc.data();

      // only storing comments of file type 1
      if (file.comments) {
        file.comments.flatMap(fileCommentParent => fileCommentParent.comments.map(childComment => {
          comments[childComment.comment_id] = childComment;
          childComment.replies?.forEach(replyComment => {
            comments[replyComment.id] = replyComment;
          });
        }));
      }

      return comments;
    });

    return comments;
  }

  static async getFigmaFiles(projectUUID) {
    const collectionRef = collection(Firestore, 'files');
    const q = query(collectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);

    const filesData = [];

    querySnapshot.forEach(
      currentDoc => {
        const data = currentDoc.data();

        if (data.figma_file_key && filesData.findIndex(file => file.figma_file_key === data.figma_file_key) === -1) {
          const newFileObject = {
            figma_file_key: data.figma_file_key,
            figma_file_name: data.figma_file_name,
            file_type: data.file_type,
            group_labels: data.group_labels?.map(groupLabel => groupLabel.label) || [],
          };

          // Add transcript property if data.transcript exists
          if (data.transcript) {
            newFileObject.transcript = data.transcript;
          }

          // add slack messages if we have those
          if (data.condensed_replies) {
            newFileObject.slack_thread = data.condensed_replies;
          }

          // add notes property if data.notes exists
          if (data.notes) {
            newFileObject.notes = data.notes;
          }

          filesData.push(newFileObject);
        }
      }
    );

    return filesData;
  }

  static async deleteTask(taskID) {
    const taskRef = doc(Firestore, 'tasks', taskID);

    await deleteDoc(taskRef);
  }

  static async deleteFigmaFile(projectUUID, fileID) {
    const tasksRef = collection(Firestore, 'tasks');
    const filesRef = collection(Firestore, 'files');

    const taskQuery = query(
      tasksRef,
      where('figma_file_key', '==', fileID),
      where('project_id', '==', projectUUID)
    );

    const filesQuery = query(
      filesRef,
      where('figma_file_key', '==', fileID),
      where('project_id', '==', projectUUID)
    );

    const taskDocs = await getDocs(taskQuery);
    const filesDocs = await getDocs(filesQuery);

    // Delete all tasks associated with this file
    taskDocs.docs.forEach(taskDoc => {
      KanbanBoardServices.deleteTask(taskDoc.id);
    });

    // Delete all files associated with this file
    filesDocs.docs.forEach(fileDoc => {
      deleteDoc(doc(filesRef, fileDoc.id));
    });
  }

  /**
   * Updates a task with the given field values.
   * @param {string} taskID - The ID of the task to update.
   * @param {{
   *  cloumnID: string,
   *  label: string,
   *  priority: string,
   *  description: string
   * }} fieldValues - An object containing the field values {cloumnID, label, priority, description}.
   * @returns None
   */
  static async updateTask(taskID, fieldValues) {
    const taskRef = doc(Firestore, 'tasks', taskID);

    await updateDoc(taskRef, fieldValues);
  }

  static async getCurrentProject(projectUUID) {
    const collectionRef = collection(Firestore, 'projects');
    const q = query(collectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);

    // If document is found
    if (!querySnapshot.empty) {
      const projectData = querySnapshot.docs.map(
        currentDoc => ({
          docID: currentDoc.id,
          details: currentDoc.data(),
        })
      );

      return projectData[0];
    }

    // If no document is found, create one with default columns
    const defaultColumns = KANBAN_BOARD_DEFAULT_COLS;

    const newDocData = {
      project_id: projectUUID,
      columns: defaultColumns,
      created_at: Timestamp.now(), // Current timestamp
    };

    // Add the new document to the collection
    const newDocRef = await addDoc(collectionRef, newDocData);

    return {
      docID: newDocRef.id,
      details: newDocData,
    };
  }

  static async getColumnData(projectUUID) {
    const collectionRef = collection(Firestore, 'projects');
    const q = query(collectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);

    // If document is found
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data().columns;
    }

    // If no document is found, create one with default columns
    const defaultColumns = KANBAN_BOARD_DEFAULT_COLS;

    const newDocData = {
      project_id: projectUUID,
      columns: defaultColumns,
      created_at: Timestamp.now(), // Current timestamp
    };

    // Add the new document to the collection
    await addDoc(collectionRef, newDocData);

    // Return the default columns
    return defaultColumns;
  }

  static async updateColumnData(projectUUID, columnID, columnName) {
    const collectionRef = collection(Firestore, 'projects');
    const q = query(collectionRef, where('project_id', '==', projectUUID));

    const querySnapshot = await getDocs(q);

    const currentDoc = querySnapshot.docs[0];
    const docId = currentDoc.id;
    const columnData = currentDoc.data().columns;

    columnData[Number(columnID - 1)]['name'] = columnName;

    const taskRef = doc(Firestore, 'projects', docId);

    await updateDoc(taskRef, { columns: columnData });
  }

  static async getGoogleDocData(docURL) {
    const googleDocData = await http
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/read_gdoc?document_url=${docURL}`)
      .then(response => response.data);

    return googleDocData;
  }
}
