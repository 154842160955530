import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { match } from 'path-to-regexp';

import actionTypes from 'Redux/constants';

import ReadonlyService, { readonlyUserProfile } from 'services/readonly_service';

import { setReadonlyAuthorizationToken } from 'utlis/http';

export default function ReadonlyRoute({ component }) {
  const readonlyUrlParser = match(
    '/readonly/:readonlyUUID',
    {
      decode: decodeURIComponent,
      end: false,
    }
  );
  const readonlyUUID = readonlyUrlParser(location.pathname)?.params?.readonlyUUID;

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (readonlyUUID) {
        ReadonlyService.getReadonlyProjectObj(
          readonlyUUID,
          {
            cache: {
              maxAge: Number.MAX_VALUE,
              exclude: { query: false },
            },
          }
        )
          .then(({ encrypted }) => {
            setReadonlyAuthorizationToken(encrypted);

            dispatch({ type: actionTypes.SIGN_IN_USER, payload: readonlyUserProfile });
            dispatch({ type: actionTypes.SET_USER_PROFILE, payload: readonlyUserProfile });

            setIsLoading(false);
          });
      }
    },
    []
  );

  if (isLoading) {
    return (
      <div className="flex flex-component-center px-lg py-huge mx-xs">
        <Spin size="large" className="mr-md" />
      </div>
    );
  }

  return component;
}

ReadonlyRoute.propTypes = {
  component: PropTypes.element.isRequired,
};
