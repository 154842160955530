import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { setUserProfile, setWriteKey } from 'Redux/Actions/userActions';

import styles from './styles.module.scss';

export const ReviewerAuthComplete = () => {
  const dispatch = useDispatch();

  const writeKey = localStorage.getItem('writeKey');
  const userObject = JSON.parse(localStorage.getItem('userObject'));

  useEffect(() => {
    setWriteKey(writeKey);
  }, []);

  useEffect(() => {
    if (userObject?.uuid) {
      dispatch(setUserProfile(userObject));
    }
  }, []);

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="You're all set!"
      description="Return to Figma and start generating reviews to enhance your designs and get unstuck."
    />
  );
};
