import * as Sentry from '@sentry/react';
import axios from 'axios';
import { serialize as objectToFormData } from 'object-to-formdata';

import actionTypes from 'Redux/constants';

import { ROUTES } from 'routes/route.constants';

import { PLUGIN_SOURCE } from 'utlis/constants';
import { catchExceptions } from 'utlis/crashlytics';
import { openGoogleDriveUI } from 'utlis/globalFunctions';
import http, { setAuthorizationToken } from 'utlis/http';
import { errorReactToastify } from 'utlis/toasts';

export function initializeSignInUser(auth_code) {
  return async dispatch => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/register/google/`;

    try {
      const payload = {
        auth_code,
      };

      return await axios
        .post(url, payload)
        .then(res => {
          dispatch({
            type: actionTypes.SIGN_IN_USER,
            payload: res.data,
          });

          window.localStorage.setItem('userObject', JSON.stringify(res?.data));
          window.localStorage.setItem('access_token', res?.data?.access);
          window.localStorage.setItem('refresh_token', res?.data?.refresh);
          window.localStorage.setItem('authenticated', true);

          setAuthorizationToken(res?.data?.access);

          return res;
        });
    }
    catch (error) {
      Sentry.captureException('Error is : ', error);
      catchExceptions(error);
      errorReactToastify(error);
    }
  };
}

export async function initializeSettingUser(auth_code, writeKey, returnURL, userID, pluginSource) {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/plugin/redirect_from_google/`;

  try {
    const payload = {
      auth_code,
      write_key: writeKey,
      return_url: returnURL,
      user_id: userID,
      request_plugin_source: pluginSource ? PLUGIN_SOURCE[pluginSource] : PLUGIN_SOURCE.taskGen,
    };

    return await axios
      .post(url, payload)
      .then(
        res => {
          const userData = res.data;

          window.localStorage.setItem('userObject', JSON.stringify(userData));
          window.localStorage.setItem('access_token', userData?.access);
          window.localStorage.setItem('refresh_token', userData?.refresh);
          window.localStorage.setItem('authenticated', true);

          setAuthorizationToken(userData?.access);

          return userData;
        }
      );
  }
  catch (error) {
    Sentry.captureException('Error is : ', error);
    catchExceptions(error);
    errorReactToastify(error);
  }
}

export async function initializeSettingExtensionUser(auth_code, writeKey, returnURL, pluginSource) {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/extension/redirect_from_google/`;

  try {
    const payload = {
      auth_code,
      write_key: writeKey,
      return_url: returnURL,
      request_plugin_source: pluginSource ? PLUGIN_SOURCE[pluginSource] : PLUGIN_SOURCE.taskGen,
    };

    return await axios
      .post(url, payload)
      .then(
        res => {
          const userData = { ...res.data };

          delete userData.return_url;

          window.localStorage.setItem('userObject', JSON.stringify(userData));
          window.localStorage.setItem('access_token', userData?.access);
          window.localStorage.setItem('refresh_token', userData?.refresh);
          window.localStorage.setItem('authenticated', true);

          setAuthorizationToken(userData?.access);

          return userData;
        }
      );
  }
  catch (error) {
    Sentry.captureException('Error is : ', error);
    catchExceptions(error);
    errorReactToastify(error);
  }
}

export const setWriteKey = writekey => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/plugin/keys/`;

  return http.put(url, { write_key: writekey });
};

export const refreshAuthToken = refreshToken => async dispatch => http
  .post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/token/refresh/`, {
    refresh: refreshToken,
  })
  .then(res => {
    dispatch({
      type: actionTypes.REFRESH_AUTH_TOKEN,
      payload: {
        access: res?.data?.access,
      },
    });
    setAuthorizationToken(res?.data?.access);
    localStorage.setItem('access_token', res?.data?.access);
    localStorage.setItem(
      'userObject',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('userObject')),
        access: res?.data?.access,
      })
    );
    localStorage.setItem('authenticated', true);
  });

export const setSignedInUser = userObj => async dispatch => {
  try {
    dispatch({ type: actionTypes.SIGN_IN_USER, payload: userObj });
  }
  catch (error) {
    Sentry.captureException('Error is ', error);
  }
};

export const signOutUser = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.LOGOUT_USER, payload: {} });
  }
  catch (error) {
    Sentry.captureException('Error is ', error);
  }
};

export const setUserProfile = (
  authUser,
  openPicker,
  refresh
) => async dispatch => {
  try {
    const conditionalUrl = refresh
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/user/user_profile/${authUser?.uuid}/?refresh_token=true`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/user/user_profile/${authUser?.uuid}/`;
    const config = {
      method: 'get',
      url: conditionalUrl,
    };

    return http(config)
      .then(response => {
        dispatch({
          type: actionTypes.SET_USER_PROFILE,
          payload: response.data,
        });
        const driveObject = response.data.user_tokens.filter(
          object => object.provider === 'Google drive'
        );
        const figmaObject = response.data.user_tokens.filter(
          object => object.provider === 'Figma'
        );

        window.localStorage.setItem(
          'drive_accessToken',
          driveObject[0]?.access_token
        );
        window.localStorage.setItem(
          'figma_accessToken',
          figmaObject[0]?.access_token
        );

        const userObject = JSON.parse(localStorage.getItem('userObject'));
        const newObj = { ...userObject, ...response?.data };

        window.localStorage.setItem('userObject', JSON.stringify(newObj));

        if (refresh && openPicker) {
          openGoogleDriveUI(openPicker);
        }
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    Sentry.captureException('Error is ', error);
  }
};

export const removeUserProfile = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_USER_PROFILE, payload: {} });
  }
  catch (error) {
    Sentry.captureException('Error is ', error);
  }
};

export const updateUserProfile = (
  authUser,
  userProfile
) => async dispatch => {
  const payload = {
    full_name: `${userProfile.firstName} ${userProfile.lastName}`,
    address: userProfile.address,
    profile_photo: userProfile.profile_photo,
    logo_image: userProfile.logo_image,
  };
  const formData = objectToFormData(payload);

  const config = {
    method: 'patch',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/user_profile/${authUser?.uuid}/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };

  return http(config)
    .then(response => {
      dispatch({
        type: actionTypes.SET_USER_PROFILE,
        payload: response.data,
      });
    });
};

export const editUserProfile = (
  authUser,
  userType,
  navigate
) => async dispatch => {
  try {
    const data = new FormData();

    data.append('user_type', userType);

    const config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/user_profile/${authUser?.uuid}/`,
      data: data,
    };

    http(config).then(() => {
      navigate(ROUTES.DASHBOARD_PROJECT_TYPE('active'));
    });
    dispatch({ type: actionTypes.REMOVE_USER_PROFILE, payload: {} });
  }
  catch (error) {
    Sentry.captureException('Error is ', error);
  }
};
