import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { LoaderCard } from 'Components/LoaderCard';

import styles from './styles.module.scss';

export const LoaderColumns = ({ numColumns, numCardsPerColumn }) => {
  const thumbnailCards = useMemo(() => [...Array(numCardsPerColumn).keys()].map(i => (
    <LoaderCard
      key={i}
      className={styles['thumbnail-wrapper']}
      showContentItems={false}
    />
  )), [numCardsPerColumn]);

  const columns = useMemo(() => [...Array(numColumns).keys()].map(i => (
    <div key={i} className={styles['column']}>
      <div className={styles['header-loader']} />
      {thumbnailCards}
    </div>
  )), [numColumns, thumbnailCards]);

  return (
    <div className={styles['kanban-board']}>
      {columns}
    </div>
  );
};

LoaderColumns.propTypes = {
  numColumns: PropTypes.number.isRequired,
  numCardsPerColumn: PropTypes.number.isRequired,
};
