import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class CollaboratorService {
  static async inviteCollaborator(
    collaboratorsList,
    selectedProjectUUID
  ) {
    return (await api.setUrl(`project/projects/${selectedProjectUUID}/invite_users`)
      .patch('', { invites: collaboratorsList })).data;
  }

  static async reInviteCollaborator(
    selectedProjectUUID,
    email
  ) {
    return (await api.setUrl(`project/${selectedProjectUUID}/resend_invite/${email}`)
      .get()).data;
  }

  static async removeCollaborator(
    selectedProjectUUID,
    email
  ) {
    return (await api.setUrl(`project/${selectedProjectUUID}/remove_invite/${email}`)
      .get()).data;
  }
}
