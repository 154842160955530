import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export default function CustomRadio({ name, className, rules = [], required, ...props }) {
  return (
    <Form.Item
      name={name}
      {...props}
      rules={[
        {
          required: required,
          message: 'This field is required',
        },
        ...rules,
      ]}
    >
      <Radio
        className={cn(styles.radio, className)}

        {...props}
      />
    </Form.Item>
  );
}

CustomRadio.Group = function RadioGroup({ name, className, rules = [], required, options, disabled, ...props }) {
  return (
    <Form.Item
      name={name}
      disabled={disabled}
      {...props}
      rules={[
        {
          required: required,
          message: 'This field is required',
        },
        ...rules,
      ]}
    >
      <Radio.Group className={cn(styles.radio, 'flex flex-wrap')}>
        {
          options.map(({ value, label, imgUrl }) => {
            const radio = (
              <Radio
                key={value}
                className={cn(
                  styles.radio,
                  {
                    [className]: !imgUrl,
                  },
                )}
                value={value}
                disabled={disabled}

                {...props}
              >
                {label}
              </Radio>
            );

            return imgUrl
              ? (
                <label
                  className={cn(
                    styles['radio-with-bg'],
                    'mr-24',
                    {
                      pointer: !disabled,
                    },
                    className
                  )}
                >
                  <span
                    className={styles['radio-bg']}
                    style={{
                      backgroundImage: `url(${imgUrl})`,
                    }}
                  />
                  <span className="py-12 pl-24">
                    {radio}
                  </span>
                </label>
              )
              : radio;
          })
        }
      </Radio.Group>
    </Form.Item>
  );
};

CustomRadio.propTypes = CustomRadio.Group.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,

  className: PropTypes.string,
  rules: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
