import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import cn from 'classnames';

import figmaIconUrl from 'assets/figma_logo.svg';
import googleDocsIconUrl from 'assets/GoogleDocsIcon.svg';
import miroIconUrl from 'assets/miro_icon.svg';
import slackIconUrl from 'assets/slack_logo.svg';
import stripeIconUrl from 'assets/stripe_logo.svg';

import { USER_TYPES } from 'utlis/constants';

import { ConnectApp } from './components/ConnectApp';
import { SlackActions } from './components/SlackActions';
import { StripeActions } from './components/StripeActions';
import { ToggleSwitch } from './components/ToggleSwitch';

import styles from './styles.module.scss';

const apps = [
  {
    title: 'Figma',
    key: 'Figma',
    description: 'Add your Figma designs to your workflow.',
    icon: figmaIconUrl,
    visibleFor: [USER_TYPES.designer, USER_TYPES.client],
  },
  {
    title: 'Google Docs',
    key: 'Google docs',
    description: 'Import your documents directly from Google Docs into your workflow.',
    icon: googleDocsIconUrl,
    visibleFor: [USER_TYPES.designer, USER_TYPES.client],
  },
  {
    title: 'Miro',
    key: 'Miro',
    description: 'Add your Miro designs to your workflow.',
    icon: miroIconUrl,
    visibleFor: [USER_TYPES.designer, USER_TYPES.client],
  },
  {
    title: 'Stripe',
    key: 'stripe',
    description: 'Add payments to your projects.',
    icon: stripeIconUrl,
    visibleFor: [USER_TYPES.designer],
    ActionButton: StripeActions,
  },
  {
    title: 'Slack',
    key: 'Slack',
    description: 'Receive notifications on slack.',
    icon: slackIconUrl,
    visibleFor: [USER_TYPES.designer, USER_TYPES.client],
    ActionButton: SlackActions,
  },
];

export const ConnectedApps = () => {
  const userProfile = useSelector(state => state.userProfile);

  const [isSaving, setIsSaving] = useState(false);

  return (
    <div className={cn('flex flex-column', styles.container)}>
      <div className="mt-48">
        <div className="mb-20 flex space-between">
          <div className="flex flex-column">
            <span className={styles['section__heading']}>
              Apps
            </span>
            <span className={styles['section__description']}>
              Review the apps integrated in your account.
            </span>
          </div>
          <div className="flex align-center">
            {
              isSaving
                ? (
                  <Spin className="mr-40" size="medium" />
                )
                : (
                  <span className={styles['progress']}>
                    Changes saved
                  </span>
                )
            }
          </div>
        </div>
        <div className={cn('flex flex-column mt-32')}>
          {
            apps.map(
              app => app.visibleFor.includes(userProfile.user_type)
                && (
                  <div
                    key={app.key}
                    className={cn(styles.section__container)}
                  >
                    <div className="flex my-20">
                      <div className={styles['app__icon']}>
                        <img
                          width={32}
                          height={32}
                          src={app.icon}
                          alt="connected app icon"
                        />
                      </div>
                      <div className={cn('ml-16 flex flex-column', styles['title__container'])}>
                        <span className={styles['app__title']}>
                          {app.title}
                        </span>
                        <span className={cn('mt-2', styles['app__description'])}>
                          {app.description}
                        </span>
                      </div>
                      <div className={styles.status}>
                        {
                          app.ActionButton
                            ? <app.ActionButton onChangeStart={onChangeStart} onChangeStop={onChangeStop} />
                            : userProfile?.user_tokens?.find(token => token.provider === app.key)
                              ? <ToggleSwitch isDisabled />
                              : <ConnectApp title={app.title} isDisabled />
                        }
                      </div>
                    </div>
                  </div>
                )
            )
          }
        </div>
      </div>
    </div>
  );

  function onChangeStart() {
    setIsSaving(true);
  }

  function onChangeStop() {
    setIsSaving(false);
  }
};
