import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import cn from 'classnames';

import { setCommentSuggestion } from 'Redux/Actions/commentActions';

import CommentService from 'services/comments';

import styles from './styles.module.scss';

export default function Topics({ selectedMilestone }) {
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const currentProject = useSelector(state => state.selectedProject);
  const currentUser = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  useEffect(
    async () => {
      if (selectedMilestone?.id) {
        try {
          setIsLoading(true);
          const list = await CommentService.getCommentsSuggestions(
            currentProject.type,
            selectedMilestone.type,
            `${currentUser.user_type.toLowerCase()},both`
          );

          setSuggestionsList(list);
        }
        finally {
          setIsLoading(false);
        }
      }
    },
    [selectedMilestone?.id, currentProject?.type]
  );

  return (
    <div id="suggestions-list" className={cn(styles['suggestions-list-wrapper'])}>
      {
        !isLoading
          ? (
            <div className="my-16 mx-md">
              <p
                className={cn(
                  'text-md text-weight-600 letter-xs text-black mt-zero mb-16',
                  {
                    hide: !suggestionsList.length,
                  }
                )}
              >
                Suggested topics
              </p>
              {
                suggestionsList.map(({ uuid, comment_text }) => (
                  <p
                    key={uuid}
                    className={cn(
                      'pointer',
                      styles['suggestion']
                    )}
                    onClick={() => dispatch(setCommentSuggestion(comment_text))}
                  >
                    {comment_text}
                  </p>
                ))
              }
            </div>
          )
          : (
            <div className="flex align-center just-center pt-lg">
              <Spin size="large" />
            </div>
          )
      }
    </div>
  );
}

Topics.propTypes = {
  selectedMilestone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
