import { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import cn from 'classnames';

import { ReactComponent as ArrowDropDownIcon } from 'assets/arrow_drop_down_icon.svg';
import sortCheckIcon from 'assets/check_icon.svg';

import { Button } from 'Components/Button';

import { KANBAN_BOARD_SORT_OPTIONS } from 'utlis/constants';

import styles from './styles.module.scss';

export function SortMenu({ selectedOption, onChangeOption }) {
  const [showSortOptions, setShowSortOptions] = useState(false);

  const sortOptions = (
    <div className={styles['sort-options']}>
      <div className={styles['sort-options-title']}>
        Sort by
      </div>
      {
        Object.entries(KANBAN_BOARD_SORT_OPTIONS).map(([key, value]) => (
          <button
            key={key}
            className={styles['sort-option-container']}
            onClick={() => handleSortOptionClick(KANBAN_BOARD_SORT_OPTIONS[key])}
          >
            <div
              className={cn(
                'flex, space-between',
                styles['options'],
                { [styles['option-selected']]: selectedOption.includes(value) }
              )}
            >
              <span className={styles['text']}>
                {value}
              </span>
              {
                selectedOption.includes(value)
                  && (
                    <img src={sortCheckIcon} alt="check-icon" />
                  )
              }
            </div>
          </button>
        ))
      }
    </div>
  );

  return (
    <div className={styles['sort-button-container']}>
      <Popover
        content={sortOptions}
        placement="bottom"
        trigger="click"
        onVisibleChange={setShowSortOptions}
        showArrow={false}
        overlayStyle={{ 'paddingTop': 0 }}
        overlayClassName={styles['sort-button-container-popover']}
      >
        <Button.White
          className={cn('flex align-center mr-8', styles['sort-button'])}
          onClick={() => setShowSortOptions(!showSortOptions)}
        >
          <div className={styles['sort-button-context']}>
            <div>
              <span className="mr-8">
                Sort by:
              </span>
              <span className={styles['sort-option']}>
                {selectedOption || 'None'}
              </span>
            </div>
            <ArrowDropDownIcon
              className={cn(
                styles['arrow-drop-down-icon'],
                {
                  [styles['active-arrow-drop-down-icon']]: showSortOptions,
                }
              )}
            />
          </div>
        </Button.White>
      </Popover>
    </div>
  );

  function handleSortOptionClick(value) {
    onChangeOption(value);
  }
}

SortMenu.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onChangeOption: PropTypes.func.isRequired,
};
