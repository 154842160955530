import * as Sentry from '@sentry/react';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { refreshAuthToken } from 'Redux/Actions/userActions';

let projectStoreDispatch;
let projectNavigate;

const instances = [];
const cache = setupCache({
  maxAge: 0,
});

export const globalInterceptors = {
  _interceptors: [],
  addRequestInterceptor(res, rej) {
    const id = createInterceptorId();

    this._interceptors.push({
      id, res, rej,
      type: 'request',
      axiosInstances: [],
    });
    this.addInterceptorsToAllAxiosInstances();

    return () => {
      this.removeRequestInterceptor(this._interceptors.find(interceptor => interceptor.id == id));
    };
  },
  addResponseInterceptor(res, rej) {
    const id = createInterceptorId();

    this._interceptors.push({
      id, res, rej,
      type: 'response',
      axiosInstances: [],
    });
    this.addInterceptorsToAllAxiosInstances();

    return () => {
      this.removeResponseInterceptor(this._interceptors.find(interceptor => interceptor.id == id));
    };
  },
  addInterceptorsToAllAxiosInstances() {
    instances.forEach(instance => {
      this._interceptors.forEach(interceptor => {
        const instanceHasInterceptor = interceptor.axiosInstances.find(
          currentInstance => currentInstance.instance == instance
        );

        if (!instanceHasInterceptor) {
          const newInterceptor = instance.interceptors[interceptor.type].use(interceptor.res, interceptor.rej);

          interceptor.axiosInstances.push({
            instance,
            interceptor: newInterceptor,
          });
        }
      });
    });
  },
  removeRequestInterceptor(interceptor) {
    this.removeInterceptorsFromAllAxiosInstances(interceptor);
  },
  removeResponseInterceptor(interceptor) {
    this.removeInterceptorsFromAllAxiosInstances(interceptor);
  },
  removeInterceptorsFromAllAxiosInstances(interceptor) {
    interceptor.axiosInstances.forEach(axiosInstance => {
      axiosInstance.instance.interceptors[interceptor.type].eject(axiosInstance.interceptor);
    });
    this._interceptors.splice(this._interceptors.indexOf(interceptor), 1);
  },
};

const globalAxiosInstance = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('access_token')
      ? `Bearer ${localStorage.getItem('access_token')}`
      : undefined,
  },
  adapter: cache.adapter,
});

export default globalAxiosInstance;

let authenticateConfig;
let authenticatePromice = [];
let isWaitAuthenticate = false;
const listRepeatRequest = [];

globalInterceptors.addRequestInterceptor(
  config => {
    if (config.url.indexOf(process.env.REACT_APP_FIGMA_BASE_URL) != -1) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('figma_accessToken')}`;
    }

    if (!isWaitAuthenticate && config.method == 'post' && config.url.indexOf('token/refresh') != -1) {
      isWaitAuthenticate = true;
      authenticateConfig = config;

      return config;
    }

    if (authenticateConfig != config && isWaitAuthenticate && authenticatePromice) {
      return new Promise((res, rej) => {
        authenticatePromice.push({
          resolve: res,
          reject: rej,
          config: config,
        });
      }).then(() => config);
    }

    return config;
  },
  error => Promise.reject(error)
);

globalInterceptors.addResponseInterceptor(
  response => {
    if (authenticateConfig == response.config) {
      authenticatePromice.forEach(config => config.resolve());
      isWaitAuthenticate = false;
      authenticatePromice = [];
      authenticateConfig = null;
    }

    return response;
  },
  error => {
    if (
      !axios.isCancel(error)
        && error.response
        && error.request.status != 0
        && error.response.config.url.indexOf('token/refresh') == -1
    ) {
      const stringConfig = JSON.stringify(error.config);

      if (error.response.status == 401 && listRepeatRequest.indexOf(stringConfig) == -1) {
        listRepeatRequest.push(stringConfig);

        return refreshAuthToken(localStorage.getItem('refresh_token'))(projectStoreDispatch)
          .then(() => {
            error.config.headers['Authorization'] = globalAxiosInstance.defaults.headers['Authorization'];

            return axios.request(error.config);
          })
          .then(res => {
            listRepeatRequest.splice(listRepeatRequest.indexOf(stringConfig), 1);

            return res;
          })
          .catch(refreshAuthError => {
            if (
              refreshAuthError?.response?.config?.url.indexOf('token/refresh') != -1
                && refreshAuthError?.response?.status == 401
            ) {
              projectNavigate('/session-has-expired');
            }
            else {
              Sentry.captureException(refreshAuthError);
            }
          });
      }
    }

    return Promise.reject(error);
  }
);

export function setAuthorizationToken(token) {
  globalAxiosInstance.defaults.headers['Authorization'] = (
    token
      ? `Bearer ${token}`
      : (
        localStorage.getItem('access_token')
          ? `Bearer ${localStorage.getItem('access_token')}`
          : undefined
      )
  );

  return token;
}

export function setReadonlyAuthorizationToken(token) {
  globalAxiosInstance.defaults.headers['X-Secondary-Authorization'] = token;

  return token;
}

export function createAxiosInstance(config) {
  const newInstance = axios.create(config);

  instances.push(newInstance);
  globalInterceptors.addInterceptorsToAllAxiosInstances();

  return newInstance;
}

export function setProjectStoreDispatch(dispatch) {
  projectStoreDispatch = dispatch;
}

export function setProjectHistory(navigate) {
  projectNavigate = navigate;
}

export function createAbortController() {
  // eslint-disable-next-line compat/compat
  return new AbortController();
}

function createInterceptorId() {
  return Math.trunc(Math.random() * 1e9);
}
