export default {
  // User Constants
  SIGN_IN_USER: 'SIGN_IN_USER',
  SET_SIGNED_IN_USER: 'SET_SIGNED_IN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  REMOVE_USER_PROFILE: 'REMOVE_USER_PROFILE',
  SET_ALL_USERS: 'SET_ALL_USERS',
  REMOVE_ALL_USERS: 'REMOVE_ALL_USERS',
  REFRESH_AUTH_TOKEN: 'REFRESH_AUTH_TOKEN',
  UNLINK_STRIPE_ACCOUNT: 'UNLINK_STRIPE_ACCOUNT',
  UNLINK_SLACK_ACCOUNT: 'UNLINK_SLACK_ACCOUNT',

  //   Project Constants
  INITIALIZE_PROJECTS: 'INITIALIZE_PROJECTS',
  UPDATE_PROJECTS: 'UPDATE_PROJECTS',
  REMOVE_PROJECTS: 'REMOVE_PROJECTS',
  ADD_NEW_PROJECT: 'ADD_NEW_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SELECTED_PROJECT: 'SELECTED_PROJECT',
  REMOVE_SELECTED_PROJECT: 'REMOVE_SELECTED_PROJECT',
  SELECTED_PROJECT_DISCOVERY: 'SELECTED_PROJECT_DISCOVERY',
  UPDATE_SELECTED_PROJECT_DISCOVERY: 'UPDATE_SELECTED_PROJECT_DISCOVERY',
  SELECTED_PROJECT_PROPOSAL: 'SELECTED_PROJECT_PROPOSAL',
  UPDATE_SELECTED_PROJECT_PROPOSAL: 'UPDATE_SELECTED_PROJECT_PROPOSAL',
  INVITE_COLLABORATOR: 'INVITE_COLLABORATOR',
  SET_PROJECT_TYPE_MILESTONES: 'SET_PROJECT_TYPE_MILESTONES',
  REMOVE_PROJECT_TYPE_MILESTONES: 'REMOVE_PROJECT_TYPE_MILESTONES',
  GET_PROJECT_STATUS_COUNT: 'GET_PROJECT_STATUS_COUNT',

  //   Figma Token Constants
  GET_FIGMA_TOKENS: 'GET_FIGMA_TOKENS',
  REMOVE_FIGMA_TOKENS: 'REMOVE_FIGMA_TOKENS',

  //   Figma Constants
  SET_FIGMA_FILES: 'SET_FIGMA_FILES',
  REMOVE_FIGMA_FILES: 'REMOVE_FIGMA_FILES',
  FETCH_SVG_ID: 'FETCH_SVG_ID',
  REMOVE_SVG_ID: 'REMOVE_SVG_ID',
  SET_SELECTED_FIGMA_FILE: 'SET_SELECTED_FIGMA_FILE',
  REMOVE_SELECTED_FIGMA_FILE: 'REMOVE_SELECTED_FIGMA_FILE',
  SET_NODE_INDEX: 'SET_NODE_INDEX',
  REMOVE_NODE_INDEX: 'REMOVE_NODE_INDEX',

  //   Next Batch Comments Constants
  FETCH_NEXT_POSTS_BATCH: 'FETCH_NEXT_POSTS_BATCH',
  SET_POSTS_NEXT_BATCH_URL: 'SET_POSTS_NEXT_BATCH_URL',
  REMOVE_POSTS_NEXT_BATCH_URL: 'REMOVE_POSTS_NEXT_BATCH_URL',

  //   Figma Constants
  SET_FIGMA_LINKS: 'SET_FIGMA_LINKS',
  REMOVE_FIGMA_LINKS: 'REMOVE_FIGMA_LINKS',
  ADD_FIGMA_LINKS: 'ADD_FIGMA_LINKS',
  DELETE_FEED_FILE: 'DELETE_FEED_FILE',
  EDIT_FIGMA_FILE: 'EDIT_FIGMA_FILE',

  //   Brief Constants
  GET_DESIGN_BRIEF: 'GET_DESIGN_BRIEF',
  POST_DESIGN_BRIEF: 'POST_DESIGN_BRIEF',
  REMOVE_DESIGN_BRIEF: 'REMOVE_DESIGN_BRIEF',

  //   Contract Document Constants
  SET_CONTRACT_DOCUMENT: 'SET_CONTRACT_DOCUMENT',
  POST_CONTRACT_DOCUMENT: 'POST_CONTRACT_DOCUMENT',
  REMOVE_CONTRACT_DOCUMENT: 'REMOVE_CONTRACT_DOCUMENT',
  SIGN_CONTRACT_DOCUMENT: 'SIGN_CONTRACT_DOCUMENT',

  //   Drive Constants
  POST_DRIVE_FILE: 'POST_DRIVE_FILE',
  DELETE_DRIVE_FILE: 'DELETE_DRIVE_FILE',

  //   FCM Constants
  CREATE_FCM_TOKEN: 'CREATE_FCM_TOKEN',
  DELETE_FCM_TOKEN: 'DELETE_FCM_TOKEN',

  //   Stripe Constants
  CREATE_STRIPE_ACCOUNT: 'CREATE_STRIPE_ACCOUNT',
  WITHDRAW_AMOUNT: 'WITHDRAW_AMOUNT',

  //   Workflow Steps Constants
  SET_WORKFLOW_STEPS: 'SET_WORKFLOW_STEPS',
  REMOVE_WORKFLOW_STEPS: 'REMOVE_WORKFLOW_STEPS',

  //   Notes Constants
  CREATE_NOTE: 'CREATE_NOTE',
  EDIT_NOTE: 'EDIT_NOTE',
  GET_NOTES: 'GET_NOTES',
  DELETE_NOTE: 'DELETE_NOTE',

  // Feed Constants
  SET_FEED_FILES: 'SET_FEED_FILES',
  UPDATE_FEED_FILES: 'UPDATE_FEED_FILES',
  REMOVE_FEED_FILES: 'REMOVE_FEED_FILES',
  GET_MILESTONES_STORE: 'GET_MILESTONES_STORE',
  REMOVE_MILESTONE_STORE: 'REMOVE_MILESTONE_STORE',
  REMOVE_MILESTONE_OBJECT: 'REMOVE_MILESTONE_OBJECT',
  STOP_PROMPT_COPY_DESIGN_BRIEF: 'STOP_PROMPT_COPY_DESIGN_BRIEF',

  //   Payment Constants
  GET_PAYMENT_DETAILS: 'GET_PAYMENT_DETAILS',
  REMOVE_PAYMENT_DETAILS: 'REMOVE_PAYMENT_DETAILS',

  //   Workspace constants
  GET_WORKSPACE_FILES: 'GET_WORKSPACE_FILES',
  UPDATE_WORKSPACE_FILES: 'UPDATE_WORKSPACE_FILES',
  REMOVE_WORKSPACE_FILES: 'REMOVE_WORKSPACE_FILES',
  DELETE_UPLOADED_FILE: 'DELETE_UPLOADED_FILE',

  //   Design Concepts Constants
  GET_ALL_CONTAINERS: 'GET_ALL_CONTAINERS',
  REMOVE_ALL_CONTAINERS: 'REMOVE_ALL_CONTAINERS',
  UPDATE_CONTAINER_NEXT_LINK: 'UPDATE_CONTAINER_NEXT_LINK',
  UPDATE_CONTAINERS: 'UPDATE_CONTAINERS',

  app: {
    setAppState: 'APP:SET_APP_STATE',
    setShowAllComments: 'APP:SET_SHOW_ALL_COMMENTS',
    setIsPinCommentMode: 'APP:SET_IS_PIN_COMMENT_MODE',
    setHidePinCommentMode: 'APP:SET_HIDE_PIN_COMMENT_MODE',
    setIsAnnotationMode: 'APP:SET_IS_ANNOTATION_MODE',
    setShowNotes: 'APP:SET_SHOW_NOTES',
    setShowExperts: 'APP:SET_SHOW_EXPERTS',
    setShowTopics: 'APP:SET_SHOW_TOPICS',
    setIsReadonly: 'APP:SET_IS_READONLY',
    showMilestoneProgressModal: 'APP:SHOW_MILESTONE_PROGRESS_MODAL',
    hideMilestoneProgressModal: 'APP:HIDE_MILESTONE_PROGRESS_MODAL',
  },
  comments: {
    loadComments: 'COMMENTS:LOAD_COMMENTS',
    loadCommentsAndKeepPrevComments: 'COMMENTS:LOAD_COMMENTS_AND_KEEP_PREV_COMMENTS',
    selectComment: 'COMMENTS:SELECT_COMMENT',
    setCommentSuggestion: 'COMMENTS:SET_COMMENT_SUGGESTION',
    sendComment: 'COMMENTS:SEND_COMMENT',
    removeComment: 'COMMENTS:REMOVE_COMMENT',
    removeAllCommentsFromReduxStore: 'COMMENTS:REMOVE_ALL_COMMENTS_FROM_REDUX_STORE',
    removeCommentsFromReduxStore: 'COMMENTS:REMOVE_COMMENTS_FROM_REDUX_STORE',
    changeCommentText: 'COMMENTS:CHANGE_COMMENT_TEXT',
    setNewCommentData: 'COMMENTS:SET_NEW_COMMENT_DATA',
  },
  kanbanBoard: {
    setKanbanBoardTasks: 'KANBAN_BOARD:SET_KANBAN_BOARD_TASKS',
    setFilteredTasks: 'KANBAN_BOARD:SET_FILTERED_TASKS',
    removeKanbanBoard: 'KANBAN_BOARD:REMOVE_KANBAN_BOARD',
    setSavedFigmaFiles: 'KANBAN_BOARD:SET_SAVED_FIGMA_FILES',
    setCurrentProject: 'KANBAN_BOARD:SET_CURRENT_PROJECT',
    setProjectFigmaCollaborators: 'KANBAN_BOARD:SET_PROJECT_FIGMA_COLLABORATORS',
  },
};
