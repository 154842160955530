import { ApiManager } from 'utlis/api_manager';
import { MILESTONES_APPS, MILESTONES_TYPES } from 'utlis/constants';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class MilestoneService {
  static MILESTONES_TYPES = MILESTONES_TYPES
  static MILESTONES_APPS = MILESTONES_APPS

  static async getMilestonesListThatUserCanCreateInProject(projectUUID, projectType) {
    return (await api.setUrl('project/project_types').getByKey(projectType, { project_uuid: projectUUID }))
      .data.default_milestones_list.milestones;
  }
}
