import actionTypes from 'Redux/constants';

import CommentService from 'services/comments';

export function loadComments(projectUUID, { fileUUID, commentsType, milestoneId, limit, keepPrevComments } = {}) {
  return async dispatch => {
    const data = await CommentService.getComments(projectUUID, { fileUUID, commentsType, milestoneId, limit });

    dispatch({
      type: keepPrevComments
        ? actionTypes.comments.loadCommentsAndKeepPrevComments
        : actionTypes.comments.loadComments,
      payload: data,
    });

    return data;
  };
}

export function removeAllCommentsFromReduxStore() {
  return dispatch => dispatch({
    type: actionTypes.comments.removeAllCommentsFromReduxStore,
  });
}

export function removeCommentsFromReduxStore(commentsListUUID) {
  return dispatch => dispatch({
    type: actionTypes.comments.removeCommentsFromReduxStore,
    payload: commentsListUUID,
  });
}

export function selectComment(comment) {
  return dispatch => dispatch({
    type: actionTypes.comments.selectComment,
    payload: comment,
  });
}

export function setCommentSuggestion(commentSuggestion) {
  return dispatch => dispatch({
    type: actionTypes.comments.setCommentSuggestion,
    payload: commentSuggestion,
  });
}

export function sendCommentOnMilestone(
  projectUUID,
  {
    text,
    content,
    milestone_id,
    parent_comment,
    file_links,
  }
) {
  return async dispatch => {
    const data = await CommentService.sendCommentOnMilestone(
      projectUUID,
      {
        text,
        content,
        milestone_id,
        parent_comment,
        file_links,
      }
    );

    dispatch({
      type: actionTypes.comments.sendComment,
      payload: data,
    });

    return data;
  };
}

export function sendCommentOnFigmaFile(
  projectUUID,
  {
    text,
    fileUUID,
    coordinates,
    content,
    annotation_file,
    node_id,
    parent_comment,
    ...otherData
  }
) {
  return async dispatch => {
    const data = await CommentService.sendCommentOnFigmaFile(
      projectUUID,
      {
        text,
        fileUUID,
        coordinates,
        content,
        annotation_file,
        node_id,
        parent_comment,
        ...otherData,
      }
    );

    dispatch({
      type: actionTypes.comments.sendComment,
      payload: data,
    });

    return data;
  };
}

export function sendCommentOnGDriveFile(
  projectUUID,
  {
    text,
    fileUUID,
    content,
    annotation_file,
    coordinates,
    parent_comment,
  }
) {
  return async dispatch => {
    const data = await CommentService.sendCommentOnGDriveFile(
      projectUUID,
      {
        text,
        fileUUID,
        content,
        annotation_file,
        coordinates,
        parent_comment,
      }
    );

    dispatch({
      type: actionTypes.comments.sendComment,
      payload: data,
    });

    return data;
  };
}

export function deleteComment(projectUUID, commentUUID) {
  return async dispatch => {
    const data = await CommentService.deleteComment(projectUUID, commentUUID);

    dispatch({
      type: actionTypes.comments.removeComment,
      payload: {
        ...data,
        commentUUID,
      },
    });

    return data;
  };
}

export function editComment(projectUUID, commentUUID, newCommentText, otherData) {
  return async dispatch => {
    const data = await CommentService.editComment(projectUUID, commentUUID, newCommentText, otherData);

    dispatch({
      type: actionTypes.comments.changeCommentText,
      payload: {
        ...data,
        commentUUID,
        text: newCommentText,
        ...otherData,
      },
    });

    return data;
  };
}

export function setNewCommentData({ coordinates, annotationData, annotationImage, pointPosition, file } = {}) {
  return dispatch => {
    dispatch({
      type: actionTypes.comments.setNewCommentData,
      payload: {
        coordinates,
        annotationData,
        annotationImage,
        pointPosition,
        file,
      },
    });
  };
}
