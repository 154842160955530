import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Radio } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';
import { NameFormItem } from 'Components/NameFormItem';

import { addNewProject, removeProjectTypeMilestones } from 'Redux/Actions/projectActions';

import { ROUTES } from 'routes/route.constants';

import { getAllProjectTypes } from 'services/projectServices';

import { PROJECT_TYPES } from 'utlis/constants';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export default function AddNewProjectModal({ onCancel, ...props }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [screenNumber, setScreenNumber] = useState(2);
  const [projectTypes, setProjectTypes] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const projectsCount = useSelector(state => state.projectStore?.count);
  const projectStatusCount = useSelector(state => state.projectStatusCount);

  const [form] = Form.useForm();
  const projectNameField = useRef();

  useEffect(
    () => {
      getAllProjectTypes()
        .then(response => {
          setProjectTypes(response.results);
        })
        .catch(error => errorReactToastify(error?.response?.data?.detail));
    },
    []
  );

  useEffect(
    () => {
      if (projectNameField.current) {
        projectNameField.current.focus();
      }
    },
    [projectNameField.current]
  );

  return (
    <Modal
      destroyOnClose
      className={styles.newProject__modal}
      onCancel={closeModalWindow}
      width={750}
      footer={null}
      closeIconProps={{
        style: {
          marginTop: '28px',
        },
      }}

      {...props}
    >
      <Form
        initialValues={{
          name: '',
          type: getProjectType(PROJECT_TYPES.simpleUx),
        }}
        form={form}
        disabled={isSubmiting}
      >
        <h4 className={styles.newProject__modalHeading}>
          {screenNumber === 1 ? 'Create new project' : 'Name your project'}
        </h4>
        {/* Project Milestone Screen (Second Screen of Modal) */}
        {screenNumber === 1 && (
          <div className={styles.projectTypes__wrapper}>
            <h4 className={styles.template__heading}>
              Choose template
            </h4>
            <Form.Item
              shouldUpdate
              name="type"
              rules={[
                {
                  required: true,
                  message: '*Please select at least one project type',
                },
              ]}
            >
              <Radio.Group
                className={cn(styles.projectCard__wrapper, 'flex flex-wrap')}
                value={form.getFieldValue('type')}
                size="large"
              >
                {/* Iterating over all of the Milestone object */}
                {projectTypes.map(currentProjectType => (
                  <div
                    key={currentProjectType.name}
                    className={cn(
                      styles.projectCard,
                      'flex flex-column'
                    )}
                  >
                    <img
                      className={styles.projectCard__image}
                      src={currentProjectType.image}
                      alt={`${currentProjectType} image`}
                    />
                    <span className={styles.projectCard__radioWrapper}>
                      <span className={styles.projectCard__label}>
                        {currentProjectType.name}
                      </span>
                    </span>
                    <div
                      className={styles.projectCard__info}
                    >
                      <span
                        className={styles.projectCard__description}
                      >
                        Kick start your next
                        {' '}
                        <b>
                          {currentProjectType.name}
                          {' '}
                          project
                          {' '}
                        </b>
                        with
                        {' '}
                        {currentProjectType.default_milestones_list.length}
                        {' '}
                        milestones pre-configured.
                      </span>
                      <label>
                        <Button
                          className={styles.projectCard__selectButton}
                          onClick={() => {
                            form.setFieldsValue({ type: currentProjectType });
                            setScreenNumber(prevState => prevState + 1);
                          }}
                        >
                          SELECT
                        </Button>
                      </label>
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {/* Project Name Screen (First Screen of Modal) */}
        {screenNumber === 2 && (
          <div
            className={cn(
              'flex flex-column',
              styles.projectName
            )}
          >
            <span className={styles.projectName__title}>
              Project name
            </span>
            <NameFormItem
              name="name"
              autofocus
              placeholder="Add Project Name"
              maxLength={254}
              onPressEnter={handleSubmit}
            />
          </div>
        )}
        {
          screenNumber === 2 && (
            <div className={styles.modalFooter}>
              <div className={styles.modalFooter__buttons}>
                <Button
                  isText
                  className={styles.addProject__transparentButton}
                  onClick={() => closeModalWindow()}
                  disabled={isSubmiting}
                >
                  CANCEL
                </Button>
                <Button
                  type="primary"
                  className={styles.addProject__primaryModalButton}
                  onClick={handleSubmit}
                  loading={isSubmiting}
                  disabled={isSubmiting}
                >
                  CREATE PROJECT
                </Button>
              </div>
            </div>
          )
        }
      </Form>
    </Modal>
  );

  function handleSubmit() {
    form.validateFields()
      .then(() => {
        handleCreateProject();
      });
  }

  function closeModalWindow() {
    form.resetFields();

    setScreenNumber(2);
    dispatch(removeProjectTypeMilestones());

    if (onCancel) {
      onCancel();
    }
  }

  async function handleCreateProject() {
    try {
      const { name, type } = form.getFieldValue();

      setIsSubmiting(true);

      const newProject = await dispatch(addNewProject(
        name.trim(),
        type?.uuid,
        projectsCount
      ));

      successReactToastify(`${name.trim()} is successfully created.`);

      if (newProject.slug) {
        const totalProjects = projectStatusCount?.active + projectStatusCount?.completed;

        /** when the first project is created we want to fire the first project creation action along with the project creation action */
        if (totalProjects === 0) {
          ReactGA.event({
            category: 'project_creation',
            action: 'first_project_created',
          });
        }
        ReactGA.event({
          category: 'project_creation',
          action: 'new_project_created',
        });

        navigate(ROUTES.BOARD(newProject.slug));
        closeModalWindow();
      }
    }
    catch (error) {
      errorReactToastify(error?.response?.data?.detail);
    }
    finally {
      setIsSubmiting(false);
    }
  }

  function getProjectType(name) {
    return projectTypes.find(project => project.name === name);
  }
}

AddNewProjectModal.propTypes = {
  onCancel: PropTypes.func,
};
