import actionTypes from '../constants';

export const fileContainersReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONTAINERS:
      return action.payload;
    case actionTypes.REMOVE_ALL_CONTAINERS:
      return [];
    case actionTypes.UPDATE_CONTAINER_NEXT_LINK:
      state.next = action.payload;
      return state;
    case actionTypes.UPDATE_CONTAINERS:
      if (
        state.results[state.results.length - 1]?.uuid !==
        action.payload.results[action.payload.results.length - 1]?.uuid
      ) {
        state.previous = action.payload.previous;
        state.next = action.payload.next;
        state.results = [...state.results, ...action.payload.results];
      }
      return state;
    default:
      return state;
  }
};
