import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal, Checkbox } from 'antd';
import {
  autofillMilestoneFromDesignBrief,
  stopPromptAutofillMilestoneFromDesignBrief,
} from '../../Redux/Actions/projectActions';
import { actionStopPromptAutofillFromDesignBrief } from '../../Redux/Actions/FeedActions';
import styles from './AutofillDataFromDesignBrief.module.scss';
import './AutofillDataFromDesignBriefGlobalStyles.scss';
import cn from 'classnames';
import { MILESTONES_STATUS } from 'utlis/constants';

function AutofillDataFromDesignBrief({ isProposal, reloadCallback }) {
  const { milestoneId } = useParams();
  const userProfile = useSelector(state => state.userProfile);
  const selectedProject = useSelector(state => state.selectedProject);
  /*Same logic is used on backend to select design brief: ProjectMileStone.objects.filter(type=MilestoneType.BRIEF)*/
  const designBrief = useSelector(state => state.milestonesStore.list.filter(ms => ms.type === 'brief')[0]);
  const milestone = useSelector(state => state.milestonesStore?.dictionaryId?.[milestoneId]);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      designBrief.is_completed
      && milestone.copy_information_prompt
      && userProfile.permissions?.isDesigner
      && (milestone.milestone_status === MILESTONES_STATUS.pending
        || milestone.milestone_status === MILESTONES_STATUS.started)
    ) {
      setShowDialog(true);
    }
  }, []);
  return (
    <Modal
      closable={false}
      wrapClassName={styles.autofill__modalWrap + ' auto-fill-modal-wrap'}
      className={styles.autofill__modal}
      visible={showDialog}
      onOk={handleAutofill}
      onCancel={handleHideDialog}
      footer={[
        <Button
          key="back"
          loading={cancelling}
          onClick={handleCancel}
          className={cn([styles.autofill__button, styles.autofill__cancelButton])}
        >
          DON'T USE
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleAutofill}
          className={cn([styles.autofill__button, styles.autofill__okButton])}
        >
          USE INFORMATION
        </Button>,
      ]}
    >
      <div className={styles.autofill__title}>
        Carry Information from Design Brief
      </div>
      <div className={styles.autofill__modalText}>
        You can use the information from the design brief to auto-fill the
        {' '}{ milestone.name }.
      </div>
      <div className={styles.autofill__checkWrap}>
        <Checkbox
          checked={checked}
          onChange={handleCheckChanged}
        >Do not show this again</Checkbox>
      </div>
    </Modal>
  );

  async function handleAutofill() {
    setLoading(true);
    await autofillMilestoneFromDesignBrief(isProposal, selectedProject.uuid, milestoneId);
    setLoading(false);
    dispatch(actionStopPromptAutofillFromDesignBrief(milestoneId));
    reloadCallback();
    setShowDialog(false);
  }

  function handleHideDialog() {
    setShowDialog(false);
  }

  async function handleCancel() {
    if (checked) {
      setCancelling(true);
      await stopPromptAutofillMilestoneFromDesignBrief(selectedProject.uuid, milestoneId);
      setCancelling(false);
      dispatch(actionStopPromptAutofillFromDesignBrief(parseInt(milestoneId)));
    }
    setShowDialog(false);
  }

  function handleCheckChanged(e) {
    setChecked(e.target.checked);
  }
}

AutofillDataFromDesignBrief.propTypes = {
  isProposal: PropTypes.bool,
  reloadCallback: PropTypes.func,
};
export default AutofillDataFromDesignBrief;
