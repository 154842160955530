import React from 'react';
import { Outlet } from 'react-router-dom';

import { ProjectListingHeader } from './components/ProjectListingHeader';

import styles from './styles.module.scss';

export const Home = () => (
  <div className={styles['home-wrapper']}>
    <ProjectListingHeader />
    <div className={styles.container}>
      <Outlet />
    </div>
  </div>
);
