import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import empty_dashboard_icon from 'assets/images/dashboard/empty_dashboard_icon.svg';

import styles from './styles.module.scss';

const EmptyDashboard = ({ className }) => {
  const { projectType } = useParams();

  const userProfile = useSelector(state => state.userProfile);
  const projectStatusCount = useSelector(state => state.projectStatusCount);

  const message = useMemo(
    () => getMessageForProjectType(),
    [projectType, projectStatusCount]
  );

  return (
    <div className={cn(styles.missingProjects, className)}>
      {/* SVG Container */}
      <div className={styles.missingProjects__icon}>
        <img src={empty_dashboard_icon} alt="girl with empty box" />
      </div>

      {/* Bottom Text Container */}
      <div className={styles.missingProjects__textContainer}>
        <div className={styles.missingProjects__firstHeading}>
          {message}
        </div>
      </div>
    </div>
  );

  function getMessageForProjectType() {
    const messages = {
      'first_project': 'Let’s get started and create your first project!',
      'first_project_client': 'Get started by collaborating on your first project!',
      'completed': 'Excited to see your first project get completed!',
      'noRecent': 'You don\'t have any recent projects at the moment',
      'noActive': 'You don\'t have any active projects at the moment',
    };

    if (projectType === 'recent') {
      if (projectStatusCount.active || projectStatusCount.completed) {
        return messages.noRecent;
      }
      return userProfile?.permissions?.isDesigner
        ? messages.first_project
        : messages.first_project_client;
    }
    if (projectType === 'active') {
      if (projectStatusCount.recent || projectStatusCount.completed) {
        return messages.noActive;
      }
      return userProfile?.permissions?.isDesigner
        ? messages.first_project
        : messages.first_project_client;
    }
    return messages.completed;
  }
};

EmptyDashboard.propTypes = {
  className: PropTypes.string,
};

export default EmptyDashboard;
