export function fullNumberFormat(
  value,
  {
    minimumFractionDigits = 0,
    maximumFractionDigits,
  } = {}
) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}

export function fullPriceFormat(
  value,
  {
    currency = 'USD',
    minimumFractionDigits = 0,
    hideFractionDigitsIfItIsZero = true,
    maximumFractionDigits,
  } = {}
) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: hideFractionDigitsIfItIsZero
      ? value % 1 ? minimumFractionDigits : 0
      : minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}

export function extractLoomURL(str) {
  const regex = /https:\/\/www\.loom\.com\/share\/[a-zA-Z0-9]+/g;
  const match = str.match(regex);

  return match ? match[0] : null;
}

export function extractFigmaFileName(name) {
  // If a match is found, return the file name with dashes swapped with spaces
  if (name) {
    let fileName = name.replace(/-/g, ' '); // Swap dashes with spaces

    fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);

    return fileName;
  }

  // Return null if no name is found
  return 'Untitled';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
