import PropTypes from 'prop-types';
import { Spin } from 'antd';

import { LoaderCard } from 'Components/LoaderCard';

export const Loader = ({ statusCount }) => {
  if (statusCount > 0) {
    return ([
      ...Array(statusCount + 1).keys(),
    ].map(idx => <LoaderCard key={idx} showContentItems />));
  }

  return (
    <div className="flex align-center just-center width-full">
      <Spin className="projects__spinner" size="large" />
    </div>
  );
};

Loader.propTypes = {
  statusCount: PropTypes.number.isRequired,
};
