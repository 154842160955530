import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { CollaboratorInput } from 'Components/CollaboratorInput';

import { setProjectFigmaCollaborators } from 'Redux/Actions/kanbanBoardActions';
import { selectProject } from 'Redux/Actions/projectActions';

import CollaboratorService from 'services/collaborator';
import { getCollaboratorsOfProject } from 'services/projectServices';

import { KANBAN_BOARD_FILE_TYPE_VALUES } from 'utlis/constants';

import styles from './styles.module.scss';

export const CollaboratorEmail = ({ fetchFigmaCollaborators, reducedMargin, actionAfterInvite }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const selectedProjectStore = useSelector(state => state.selectedProject);
  const savedFigmaFiles = useSelector(state => state.kanbanBoard.savedFigmaFiles);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{ invite: null }}
      form={form}
      disabled={isSubmitting}
      onFinish={handleEmailInvite}
      className={styles['invite-collaborators']}
    >
      <div className={cn(styles['collaborator-invite'], { [styles['reduced-margin']]: reducedMargin })}>
        <CollaboratorInput
          name="invite"
          className={styles['input-item']}
          autoFocus
          isDropdown
        />
        <Form.Item
          noStyle
          shouldUpdate
        >
          {() => (
            <Button
              className={
                cn(
                  styles['invite-button'],
                  {
                    [styles['disabled']]: isSubmitting
                      || !form.getFieldValue('invite')?.email
                      || !!form.getFieldsError().filter(({ errors }) => errors.length).length,
                  }
                )
              }
              disabled={isSubmitting || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              type="primary"
              htmlType="submit"
            >
              {
                isSubmitting
                  ? 'Sending'
                  : 'Invite'
              }
            </Button>
          )}
        </Form.Item>
      </div>
      {errorMessage
        && (
          <div className={styles['error-message']}>
            {errorMessage}
          </div>
        )}
    </Form>
  );

  async function handleFigmaCollaborators() {
    const allFigmaFileKeys = savedFigmaFiles.filter(
      file => file.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
    ).map(
      file => file.figma_file_key
    );

    if (selectedProjectStore?.uuid && allFigmaFileKeys.length) {
      const data = await getCollaboratorsOfProject(selectedProjectStore?.uuid, allFigmaFileKeys);

      dispatch(setProjectFigmaCollaborators(data));
    }
  }

  async function handleEmailInvite({ invite }) {
    setErrorMessage('');

    try {
      setIsSubmitting(true);
      await CollaboratorService.inviteCollaborator(
        [invite],
        selectedProjectStore?.uuid
      );
      await dispatch(selectProject(selectedProjectStore?.uuid, selectedProjectStore?.slug));
      fetchFigmaCollaborators && await handleFigmaCollaborators();
    }
    catch (error) {
      setErrorMessage(error?.response?.data?.detail);
      setIsSubmitting(false);
    }
    finally {
      setIsSubmitting(false);
      actionAfterInvite && actionAfterInvite();
      form.resetFields();
    }
  }
};

CollaboratorEmail.propTypes = {
  fetchFigmaCollaborators: PropTypes.bool,
  reducedMargin: PropTypes.bool,
  actionAfterInvite: PropTypes.func,
};
