import { combineReducers } from 'redux';

import { appReducer } from './appReducer';
import { commentsReducer } from './commentReducer';
import { contractReducer } from './contractReducer';
import { fileContainersReducer } from './designConceptsReducer';
import {
  feedReducer,
  milestonesReducer,
} from './FeedReducer';
import {
  figmaFilesReducer,
  figmaReducer,
  figmaWorkflowSteps,
  selectedFigmaFileReducer,
} from './figmaReducer';
import { kanbanBoardReducer } from './kanbanBoardReducer';
import { notesReducer } from './notesReducer';
import { paymentReducer } from './paymentReducer';
import {
  projectReducer,
  projectStatusCountReducer,
  projectTypeMilestonesReducer,
  selectedProjectReducer,
} from './projectReducer';
import { userProfileReducer, userReducer, usersReducer } from './userReducer';
import { workspaceFilesReducer } from './workspaceReducer';

const allReducers = combineReducers({
  app: appReducer,
  userStore: userReducer,
  userProfile: userProfileReducer,
  projectStore: projectReducer,
  projectStatusCount: projectStatusCountReducer,
  allUsersStore: usersReducer,
  projectTypeMilestones: projectTypeMilestonesReducer,
  selectedProject: selectedProjectReducer,
  kanbanBoard: kanbanBoardReducer,
  figmaTokens: figmaReducer,
  figmaFiles: figmaFilesReducer,
  feedFiles: feedReducer,
  fileContainers: fileContainersReducer,
  workspaceFiles: workspaceFilesReducer,
  milestonesStore: milestonesReducer,
  paymentMethods: paymentReducer,
  selectedFigmaFile: selectedFigmaFileReducer,
  commentsStore: commentsReducer,
  contractDocument: contractReducer,
  workflowSteps: figmaWorkflowSteps,
  notesStore: notesReducer,
});

export default allReducers;
