import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Row, Col, Checkbox, Divider, Input } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

const SigningModal = ({ isOpen, currentUser, onSign, onClose }) => {
  const [signatureText, setSignatureText] = useState('');
  const [isSigning, setIsSigning] = useState(false);

  const [form] = Form.useForm();

  const closeModal = () => {
    onClose(false);
    form.resetFields();
    setSignatureText('');
  };

  return (
    <Modal
      title={
        <span className="text-20 text-black text-weight-500">
          Sign Contract
        </span>
      }
      className={styles['modal']}
      centered
      visible={isOpen}
      onCancel={closeModal}
      footer={null}
      width={700}
    >
      <Form
        form={form}
        preserve
      >
        <Row gutter={16}>
          <Col span={12}>
            <div className={cn(styles['input-area'], 'flex flex-component-center text-center')}>
              <div>
                <Form.Item
                  className={cn(styles['signature-input-wrapper'], 'm-zero')}
                  name="signatureText"
                  rules={[{ required: true }]}
                >
                  <Input
                    className={cn(styles['signature-input'], 'text-center text-28-important text-weight-600')}
                    placeholder="Jane Doe"
                    onChange={({ target }) => setSignatureText(target.value)}
                  />
                </Form.Item>
                <Divider className="my-zero" />
                <p className="text-weight-500 text-xs text-monsoonColor mt-xs mb-zero">
                  Type your legal name to complete signature
                </p>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={cn(styles['signature-preview'], 'py-sm px-md-lg')}>
              <p className="text-weight-500 text-monsoonColor text-xs mt-zero mb-xxs">
                Signature preview
              </p>
              <Divider className="my-zero" />
              <p
                className="text-weight-500 text-monsoonColor text-32 mt-md-lg mb-sm text-whisper"
              >
                {signatureText || 'Jane Doe'}
              </p>
              <Divider className="my-zero" />
              <p className="text-weight-500 text-monsoonColor text-xs mt-xs mb-zero">
                {signatureText || 'Jane Doe'}
              </p>
            </div>
          </Col>
        </Row>
        <Form.Item
          className="mt-md"
          name="iAgree"
          valuePropName="checked"
          rules={[{
            validator: (rule, value) => // eslint-disable-line
              value ? Promise.resolve() : Promise.reject(new Error('You must agree')),
          }]}
        >
          <Checkbox>
            I, {currentUser.full_name}, certify that I have read the contract,
            and understand that clicking 'CONFIRM SIGNATURE' constitutes a legally binding signature.
          </Checkbox>
        </Form.Item>
        <div className="flex just-end">
          <Button
            type="primary"
            htmlType="button"
            onClick={e => onSubmit(e)}
            disabled={isSigning}
          >
            CONFIRM SIGNATURE
          </Button>
        </div>
      </Form>
    </Modal>
  );

  async function onSubmit(e) {
    await form.validateFields();

    setIsSigning(true);

    await onSign(signatureText, e);

    setIsSigning(false);
  }
};

SigningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
  }).isRequired,
  onSign: PropTypes.func.isRequired,
};

export default SigningModal;
