import PropTypes from 'prop-types';
import { Spin, Table } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

export function InfiniteTable({ loadMore, hasMore, initialLoad, pageStart, useWindow, ...props }) {
  return (
    <InfiniteScroll
      pageStart={pageStart}
      initialLoad={initialLoad}
      // We need to pass the key parameter because the library implementation requires it
      // https://github.com/danbovey/react-infinite-scroller/blob/master/src/InfiniteScroll.js#L277
      loader={<Spin key={0} className="mt-lg flex just-center" size="large" />}
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={useWindow}
    >
      <Table
        pagination={false}

        {...props}
      />
    </InfiniteScroll>
  );
}

InfiniteTable.propTypes = {
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  initialLoad: PropTypes.bool,
  pageStart: PropTypes.number,
  useWindow: PropTypes.bool,
};

InfiniteTable.defaultProps = {
  useWindow: true,
};
