import { forwardRef, Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Dropdown, Form, Menu, Spin } from 'antd';
import htmlReactParser from 'html-react-parser';
import moment from 'moment';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Milestone } from 'Components/Milestone';
import DatePicker from 'Components/questionnaireComponents/DatePicker';
import Field from 'Components/questionnaireComponents/Field';
import Navigation from 'Components/questionnaireComponents/Navigation';
import Select from 'Components/questionnaireComponents/Select';

import formScrollToField from 'utlis/form_ scroll_to_field';

function ContractText(
  {
    contractDocument,
    currentUser,
    onSave,
    onSubmit,
    contractMilestone,

    className,
    onFieldsChange,
    isSaved,
    hasError,
    isDesignerSubmitted,
    setIsOpenSigningModal,
    isReadonly,
  },
  ref
) {
  const [contractTemplateText, setContractTemplateText] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [timerForSaveStart, setTimerForSaveStart] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [form] = Form.useForm();

  const contractText = useMemo(
    () => contractTemplateText[currentStepIndex - 1]
      ? parseContractTemplateText(contractTemplateText[currentStepIndex - 1])
      : null,
    [contractTemplateText, currentStepIndex, contractDocument]
  );

  const isCompleted = !!contractDocument?.client_signature;

  useEffect(
    () => {
      fetch(contractDocument.template)
        .then(res => res.text())
        .then(res => {
          setIsLoading(false);
          setContractTemplateText(res.split('<p>{PAGE_BREAK}</p>'));
        });
    },
    []
  );

  if (currentUser.permissions.isClient) {
    useEffect(
      () => {
        form.setFieldsValue({
          ...contractDocument,
          contract_start_date: contractDocument.contract_start_date
            ? moment(contractDocument.contract_start_date)
            : null,
        });
      },
      [contractDocument]
    );
  }

  return (
    <Milestone
      title={contractMilestone?.name}
      selectedMilestone={contractMilestone}

      withShareButton={!isReadonly}
      additionalRightElement={
        <div
          className={cn(
            'flex align-center',
            {
              hide: !contractDocument?.uuid,
            }
          )}
        >
          <p
            className={cn(
              'text-xs text-silver my-zero',
              {
                hide: currentUser.permissions.isClient
                  || contractDocument.client_signature,
              }
            )}
          >
            {
              isSaved !== null
                ? (
                  isSaved
                    ? 'Saved'
                    : 'Saving ...'
                )
                : 'All changes are saved automatically'
            }
          </p>
          <p
            className={cn(
              'text-xs my-zero ml-sm',
              {
                hide: !hasError,
                'text-sunsetOrange': hasError,
              }
            )}
          >
            Please fill in all required fields
            <br />
            and correct any mistakes
          </p>
          <Button
            className={cn(
              'ml-sm',
              {
                hide: currentUser.permissions.isClient
                  || !contractDocument.is_questionnaire_completed
                  || isDesignerSubmitted,
              }
            )}
            onClick={async () => {
              await validateFields();

              setIsOpenSigningModal(true);
            }}
            disabled={isSubmiting || hasError}
          >
            {
              isSubmiting
                ? 'SUBMITING ...'
                : 'SIGN & SUBMIT FOR COUNTER-SIGN'
            }
          </Button>
          <Button
            className={cn(
              'ml-sm',
              {
                hide: !contractDocument.is_questionnaire_completed || (
                  currentUser.permissions.isClient
                    ? contractDocument.client_signature
                    : true
                ),
              }
            )}
            onClick={() => setIsOpenSigningModal(true)}
            disabled={isSubmiting || (currentUser.permissions.isClient ? false : !contractDocument.client_signature)}
          >
            {
              isSubmiting
                ? 'SIGNING ...'
                : 'ADD COUNTER SIGNATURE'
            }
          </Button>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey my-zero ml-sm',
              {
                hide: !contractDocument.designer_signature || currentUser.permissions.isClient || isCompleted,
              }
            )}
          >
            SUBMITTED FOR COUNTER-SIGN
          </p>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey my-zero ml-sm',
              {
                hide: !(contractDocument.designer_signature && contractDocument.client_signature),
              }
            )}
          >
            SIGNED BY BOTH PARTIES
          </p>
        </div>
      }
    >
      <Form
        ref={ref}
        className={className}
        initialValues={{
          ...contractDocument,
          contract_start_date: contractDocument.contract_start_date
            ? moment(contractDocument.contract_start_date)
            : null,
        }}
        values
        form={form}
        preserve
        onFieldsChange={!isReadonly && ((changedFields, allFields) => {
          if (onFieldsChange) {
            onFieldsChange(changedFields, allFields);
          }

          if (changedFields.length && !contractDocument.client_signature) {
            if (timerForSaveStart) {
              clearTimeout(timerForSaveStart);
            }

            setTimerForSaveStart(setTimeout(
              () => {
                onSave(form.getFieldValue());
              },
              1000
            ));
          }
        })}
        disabled={isReadonly}
      >
        {
          !isLoading
            ? (
              <Fragment>
                <p className="text-black text-weight-bold text-28 m-zero">
                  {contractDocument.project_type}
                  {' '}
                  Contract
                </p>
                <br />
                {contractText}
                <Divider className="mt-md-lg mb-sm-md" />
                <Navigation
                  justify="space-between"
                  currentStepIndex={currentStepIndex}
                  lastStepIndex={contractTemplateText.length}
                  goToNextStep={() => {
                    validateFields().then(() => setCurrentStepIndex(currentStepIndex + 1));
                  }}
                  goToPrevStep={() => {
                    validateFields().then(() => setCurrentStepIndex(currentStepIndex - 1));
                  }}
                  hideSubmitButton={isCompleted || !onSubmit || !!(
                    currentUser.permissions.isClient
                      ? contractDocument.client_signature
                      : contractDocument.designer_signature
                  )}
                  onSubmit={!isSubmiting && onSubmitContract}
                  submitText={
                    contractDocument.isDesignerSubmitted
                      ? (
                        isSubmiting
                          ? 'SIGNING ...'
                          : 'ADD COUNTER SIGNATURE'
                      )
                      : (
                        isSubmiting
                          ? 'SUBMITING ...'
                          : 'SIGN & SUBMIT FOR COUNTER-SIGN'
                      )
                  }
                />
              </Fragment>
            )
            : (
              <div className="flex flex-component-center px-lg py-lg">
                <Spin size="large" />
              </div>
            )
        }
      </Form>
    </Milestone>
  );

  function parseContractTemplateText(templateText) {
    return htmlReactParser(
      templateText,
      {
        replace: ({ type, data }) => {
          if (type == 'text' && data) {
            const textList = data.split(/{[\w_]+}/g);
            const keysList = data.match(/{[\w_]+}/g);

            if (textList?.length && keysList?.length) {
              return (
                <Fragment>
                  {
                    textList
                      .map((el, idx) => [el, keysList[idx]])
                      .flat()
                      .filter(Boolean)
                      .map(text => {
                        switch (text) {
                          case '{DESIGNER_NAME}':
                            return contractDocument.designer;
                          case '{CLIENT_NAME}':
                            return contractDocument.client;
                          case '{CONTRACT_START_DATE}':
                            return (
                              <DatePicker
                                name="contract_start_date"
                                required
                                showToday={false}
                                allowClear={false}
                                suffixIcon={null}
                                disabled={currentUser.permissions.isClient}
                                disabledDate={current => current && current < moment().subtract(1, 'd')}
                              />
                            );
                          case '{FEE_TYPE}':
                            return (
                              <Select
                                name="fee_type"
                                placeholder="flat fee, hourly"
                                options={[
                                  { value: 'flat fee', label: 'flat fee' },
                                  { value: 'hourly', label: 'hourly' },
                                ]}
                                required
                                disabled={currentUser.permissions.isClient}
                              />
                            );
                          case '{TOTAL_AMOUNT}':
                            return (
                              <Field
                                name="total_amount"
                                placeholder="$1000"
                                prefix="$"
                                type="number"
                                required
                                disabled={currentUser.permissions.isClient}
                              />
                            );
                          case '{DEPOSIT_AMOUNT}':
                            return (
                              <Field
                                name="deposit_amount"
                                placeholder="$500"
                                prefix="$"
                                type="number"
                                required
                                disabled={currentUser.permissions.isClient}
                              />
                            );
                          case '{REMAINING_AMOUNT_PAYMENT_TIME}':
                            return (
                              <Select
                                name="remaining_amount_payment_time"
                                placeholder="at the end of the project"
                                options={[
                                  {
                                    value: 'at the end of the project',
                                    label: 'at the end of the project',
                                  },
                                  {
                                    value: 'at the beginning of the project',
                                    label: 'at the beginning of the project',
                                  },
                                ]}
                                required
                                disabled={currentUser.permissions.isClient}
                              />
                            );
                          case '{CONTRACT_END_CONDITION}':
                            return (
                              <Dropdown
                                className="pointer"
                                overlay={(
                                  <Menu
                                    items={[
                                      {
                                        label: 'pro-rated amount',
                                        key: 'pro-rated',
                                      },
                                      {
                                        label: 'flat fee',
                                        key: 'flat fee',
                                      },
                                    ]}
                                    onClick={({ key }) => {
                                      form.setFieldsValue({ contract_end_condition: key });
                                      onSave(form.getFieldValue());
                                    }}
                                  />
                                )}
                                trigger={['click']}
                                disabled={currentUser.permissions.isClient}
                              >
                                <span className="text-pacificBlue text-weight-bold">
                                  {
                                    form.getFieldValue('contract_end_condition') == 'pro-rated'
                                      && `
                                        The Client will pay the Designer for the work done up until when the Contract
                                        ends and will reimburse the Designer for any agreed-upon,
                                        non-cancellable expenses.
                                      `
                                  }
                                  {
                                    form.getFieldValue('contract_end_condition') == 'flat fee'
                                      && (
                                        <Fragment>
                                          If either party ends this Contract before the Contract automatically ends
                                          as explained in the first sentence of this paragraph, the Client will
                                          pay the Designer a guaranteed payment of
                                          {' '}
                                          <Field
                                            name="flat_fee"
                                            placeholder="$100"
                                            prefix="$"
                                            type="number"
                                            required
                                            onClick={event => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                            }}
                                            disabled={
                                              currentUser.permissions.isClient || contractDocument.client_signature
                                            }
                                          />
                                          {' '}
                                          (USD) and the Client will reimburse the Designer for any agreed-upon,
                                          non-cancellable expenses.
                                        </Fragment>
                                      )
                                  }
                                </span>
                              </Dropdown>
                            );
                          case '{DESIGNER_SIGNATURE}':
                            return (
                              <span>
                                {contractDocument.designer_signature}
                                {' '}
                                <span className="text-whisper text-32 text-underline">
                                  {contractDocument.designer_signature}
                                </span>
                              </span>
                            );
                          case '{CLIENT_SIGNATURE}':
                            return (
                              <span>
                                {contractDocument.client_signature}
                                {' '}
                                <span className="text-whisper text-32 text-underline">
                                  {contractDocument.client_signature}
                                </span>
                              </span>
                            );
                          case '{BUSINESS_TYPE}':
                          case '{BUSINESS_NAME}':
                          case '{COMPLETE_IN}':
                            return (
                              <span className="text-pacificBlue text-weight-bold">
                                {contractDocument[text.toLowerCase()]}
                              </span>
                            );
                          case '{DISPLAY}':
                            return (
                              <span className="text-pacificBlue text-weight-bold">
                                {
                                  [
                                    contractDocument.display.slice(0, -1).join(', '),
                                    contractDocument.display[contractDocument.display.length - 1],
                                  ]
                                    .filter(Boolean)
                                    .join(' and ')
                                }
                              </span>
                            );
                          default:
                            return text;
                        }
                      })
                  }
                </Fragment>
              );
            }
          }
        },
      }
    );
  }

  async function onSubmitContract() {
    setIsSubmiting(true);

    if (timerForSaveStart) {
      clearTimeout(timerForSaveStart);
    }

    if (currentUser.permissions.isDesigner) {
      await onSave(form.getFieldValue());
    }
    await onSubmit();

    setIsSubmiting(false);
  }

  async function validateFields() {
    try {
      const res = await form.validateFields();

      return res;
    }
    catch (error) {
      error.errorFields.some(({ errors, name: [name] }) => {
        if (errors.length) {
          formScrollToField(form, name);
        }

        return !!errors.length;
      });

      return Promise.reject(error);
    }
  }
}

ContractText.propTypes = {
  contractDocument: PropTypes.shape({
    uuid: PropTypes.string,
    fee_type: PropTypes.string.isRequired,
    deposit_amount: PropTypes.string.isRequired,
    total_amount: PropTypes.string.isRequired,
    remaining_amount_payment_time: PropTypes.string.isRequired,
    contract_start_date: PropTypes.string.isRequired,
    contract_end_condition: PropTypes.string.isRequired,
    flat_fee: PropTypes.string.isRequired,
    designer_signature: PropTypes.bool.isRequired,
    client_signature: PropTypes.bool.isRequired,
    template: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    designer: PropTypes.string.isRequired,
    project_type: PropTypes.string.isRequired,
    business_name: PropTypes.string.isRequired,
    business_type: PropTypes.string.isRequired,
    complete_in: PropTypes.string.isRequired,
    display: PropTypes.array.isRequired,
    isDesignerSubmitted: PropTypes.bool.isRequired,
    is_questionnaire_completed: PropTypes.bool.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.object.isRequired,
  }).isRequired,
  setIsOpenSigningModal: PropTypes.func.isRequired,
  contractMilestone: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,

  className: PropTypes.string,
  onFieldsChange: PropTypes.func,
  isSaved: PropTypes.bool,
  hasError: PropTypes.bool,
  isDesignerSubmitted: PropTypes.bool,
  isReadonly: PropTypes.bool,
};

export default forwardRef(ContractText);
