import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { collection, doc, onSnapshot } from 'firebase/firestore';

import { TaskCard } from 'Pages/ProjectDetails/sub-routes/Feed/components/TaskCard';

import { Firestore } from 'utlis/firebase';

import styles from './styles.module.scss';

export const TaskList = ({ figmaFileKey, comments }) => {
  const [tasks, setTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(Firestore, 'files');

    if (figmaFileKey) {
      onSnapshot(doc(collectionRef, figmaFileKey), tasksSnapshot => {
        const taskListData = tasksSnapshot.data();

        if (taskListData?.tasks) {
          const newTasks = taskListData.tasks.map(task => {
            const matchingComment = comments
              .flatMap(parentComment => parentComment.comments)
              .find(childComment => childComment.comment_id === task.comment_id);

            const figmaCommentUrl = matchingComment
              ? `https://www.figma.com/file/${figmaFileKey}?${matchingComment.uri}`
              : '';

            return {
              ...task,
              commentCroppedImage: task.comment_cropped_image,
              figmaCommentUrl,
            };
          });

          setTasks(newTasks);
        }

        if (taskListData?.tasks.length >= comments?.length) {
          setIsLoading(false);
        }
      });
    }
  }, [figmaFileKey]);

  return (
    <>
      <h1>
        AI  Task List
      </h1>
      <div className={styles['task-list-wrapper']}>
        {
          tasks?.map((task, index) => (
            <TaskCard key={index} {...task} />
          ))
        }
        {
          isLoading
            && (
              <>
                <Spin className="mt-16" size="lg" />
                <p>
                  Our AI engine is generating tasks against feedback on file. Hold tight!
                </p>
              </>
            )
        }
      </div>
    </>
  );
};

TaskList.propTypes = {
  figmaFileKey: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
};
