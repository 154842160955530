import PropTypes from 'prop-types';
import cn from 'classnames';

import primaryStyles from './primary.module.scss';
import secondaryStyles from './secondary.module.scss';

export function NavigationHeaderItem({ children, className, onClick, isActive, variant, ...props }) {
  const styles = variant === 'primary' ? primaryStyles : secondaryStyles;

  return (
    <button
      className={cn(
        styles.navigation__button,
        styles.tab__inactive,
        {
          [styles.tab__active]: isActive,
        },
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

NavigationHeaderItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};
