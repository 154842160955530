import actionTypes from 'Redux/constants';

import http from 'utlis/http';

const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v2/project`;

export const loadContractDocument = selectedProjectUUID => async dispatch => (
  await http.get(`${baseURL}/${selectedProjectUUID}/contract_document/`)
    .then(({ data }) => {
      dispatch({
        type: actionTypes.SET_CONTRACT_DOCUMENT,
        payload: data,
      });

      return data;
    })
);

export const updateContractDocument = (selectedProjectUUID, newData) => async dispatch => (
  await http.put(
    `${baseURL}/${selectedProjectUUID}/contract_document/update_contract/`,
    newData
  )
    .then(({ data }) => {
      dispatch({
        type: actionTypes.SET_CONTRACT_DOCUMENT,
        payload: data,
      });

      return data;
    })
);

export const submitContractDocument = (selectedProjectUUID, newData) => async dispatch => (
  await http.post(
    `${baseURL}/${selectedProjectUUID}/contract_document/`,
    newData
  )
    .then(() => dispatch(loadContractDocument(selectedProjectUUID)))
);

export const removeContractDocument = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_CONTRACT_DOCUMENT, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const signContractDocument = (selectedProjectUUID, signature) => async dispatch => (
  await http.patch(
    `${baseURL}/${selectedProjectUUID}/contract_document/sign_contract/`,
    {
      signature,
    }
  )
    .then(() => dispatch(loadContractDocument(selectedProjectUUID)))
);
