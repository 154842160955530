import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import 'antd/dist/antd.css';

import './index.css';

import ScrollToTop from 'Components/ScrollToTop';

import store from 'Redux/store';

import 'utlis/socket';
import 'utlis/firebase';
import { ENV_CONSTANTS } from 'utlis/constants';
import { readonlyRegExp } from 'utlis/validators';

import App from './App';

const readonlyUrlPrefix = location.pathname.match(readonlyRegExp)?.[1];

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: ENV_CONSTANTS.REACT_APP_SENTRY_TRACE_RATE,
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <BrowserRouter basename={readonlyUrlPrefix || ''}>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
