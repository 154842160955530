import actionTypes from 'Redux/constants';

const commentsInitialState = {
  allComments: [],
  selectedComment: null,
  commentSuggestion: null,
  newCommentData: null,
};

export function commentsReducer(state = commentsInitialState, action) {
  switch (action.type) {
    case actionTypes.comments.loadComments:
      return {
        ...state,
        allComments: removeDuplicateAndSort(action.payload.results),
      };
    case actionTypes.comments.loadCommentsAndKeepPrevComments:
      return {
        ...state,
        allComments: removeDuplicateAndSort([...action.payload.results, ...state.allComments]),
      };
    case actionTypes.comments.selectComment:
      return {
        ...state,
        selectedComment: action.payload,
      };
    case actionTypes.comments.setCommentSuggestion:
      return {
        ...state,
        commentSuggestion: action.payload,
      };
    case actionTypes.comments.removeAllCommentsFromReduxStore:
      return commentsInitialState;
    case actionTypes.comments.removeCommentsFromReduxStore:
      return {
        ...state,
        allComments: state.allComments.filter(({ uuid }) => !action.payload.includes(uuid)),
      };
    case actionTypes.comments.removeComment:
      return {
        ...state,
        allComments: state.allComments
          .map(comment => {
            comment.replies = comment.replies.filter(({ uuid }) => uuid != action.payload.commentUUID);

            return comment;
          })
          .filter(({ uuid }) => uuid != action.payload.commentUUID),
      };
    case actionTypes.comments.changeCommentText:
      return {
        ...state,
        allComments: state.allComments.map(
          comment => comment.uuid == action.payload.commentUUID
            ? {
              ...comment,
              text: action.payload.text,
            }
            : comment
        ),
      };
    case actionTypes.comments.setNewCommentData:
      return {
        ...state,
        newCommentData: action.payload,
      };
    default:
      return state;
  }
}

function removeDuplicateAndSort(comments) {
  return comments
    .reduce(
      (currentObj, next) => currentObj.findIndex(({ uuid }) => uuid == next.uuid) == -1
        ? currentObj.concat(next)
        : currentObj,
      []
    )
    .sort(({ created_at: a }, { created_at: b }) => Date.parse(a) - Date.parse(b));
}
