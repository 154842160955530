import PropTypes from 'prop-types';
import CanvasDraw from '@win11react/react-canvas-draw';

export function Annotations({
  annotationList,
  editableAnnotation,

  hideCanvas,
  disabled,
  canvasRef,

  ...props
}) {
  return (
    <>
      {
        annotationList.map((saveData, idx) => (
          <CanvasDraw
            key={idx}
            immediateLoading
            hideGrid
            disabled
            hideInterface
            clampLinesToDocument
            saveData={saveData}

            {...props}
          />
        ))
      }
      {
        canvasRef && !hideCanvas && (
          <CanvasDraw
            ref={canvasRef}
            immediateLoading
            hideGrid
            disabled={disabled}
            lazyRadius={0}
            brushRadius={3}
            hideInterface
            clampLinesToDocument
            saveData={editableAnnotation || Annotations.defaultCanvasData}

            {...props}
          />
        )
      }
    </>
  );
}

Annotations.propTypes = {
  annotationList: PropTypes.array,
  editableAnnotation: PropTypes.string,
  hideCanvas: PropTypes.bool,
  disabled: PropTypes.bool,
  canvasRef: PropTypes.any,
};

Annotations.defaultCanvasData = '{"lines": []}';
