import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export function DropdownButton({
  icon,
  text,
  className,
  ...props
}) {
  return (
    <button
      className={cn(
        styles['dropdown-button'],
        'flex align-center px-8 py-12 text-xs text-weight-500 letter-xs pointer',
        className
      )}
      {...props}
    >
      {icon}
      <span className={cn({ 'ml-sm': !!icon })}>
        {text}
      </span>
    </button>
  );
}

DropdownButton.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
  className: PropTypes.string,
};
