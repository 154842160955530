import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';

import designProLogo from 'assets/designProLogo.svg';

import { Button } from 'Components/Button';
import { NameFormItem } from 'Components/NameFormItem';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { addNewProject } from 'Redux/Actions/projectActions';

import { ROUTES } from 'routes/route.constants';

import { trackEvent } from 'services/eventTracking';
import { getAllProjectTypes } from 'services/projectServices';

import { PROJECT_TYPES } from 'utlis/constants';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export const FirstProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [projectTypes, setProjectTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userObjectFromLocal = JSON.parse(localStorage.getItem('userObject'));

  const userFirstName = userObjectFromLocal.full_name.split(' ')[0];

  useEffect(
    () => {
      trackEvent('[page] create first project', { 'page name': 'create first project plugin' });

      getAllProjectTypes()
        .then(response => {
          setProjectTypes(response.results);
        })
        .catch(error => errorReactToastify(error?.response?.data?.detail));
    },
    []
  );

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="Welcome to DesignPro!"
      description="Create a project for your actionable tasks generated from your Figma comments."
      formData={(
        <Form
          className={styles['form']}
          initialValues={{
            name: `${userFirstName}'s first project`,
          }}
          form={form}
          disabled={isSubmitting}
        >
          <NameFormItem
            name="name"
            autofocus
            placeholder="Add Project Name"
            maxLength={254}
            onPressEnter={handleSubmit}
          />
          <Button
            className={styles['modal-button']}
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            CREATE PROJECT
          </Button>
        </Form>
      )}
    />
  );

  function handleSubmit() {
    trackEvent('[click] create first project', { 'page name': 'create first project plugin' });

    form.validateFields()
      .then(() => {
        handleCreateProject();
      });
  }

  async function handleCreateProject() {
    try {
      const { name } = form.getFieldValue();

      setIsSubmitting(true);

      const newProject = await dispatch(addNewProject(
        name.trim(),
        getProjectType(PROJECT_TYPES.simpleUx).uuid,
        1,
        true
      ));

      successReactToastify(`${name.trim()} is successfully created.`);

      if (newProject.slug) {
        navigate(ROUTES.CONNECT_TO_FIGMA);
      }
    }
    catch (error) {
      errorReactToastify(error?.response?.data?.detail);
    }
    finally {
      setIsSubmitting(false);
    }
  }

  function getProjectType(name) {
    return projectTypes.find(project => project.name === name);
  }
};
