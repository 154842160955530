import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Carousel } from 'antd';
import cn from 'classnames';

import FunctionNotAvailable from 'Components/FunctionNotAvailable';
import ImageWithComments from 'Components/ImageWithComments';

import { isAdobeIllustratorFile } from 'utlis/validators';

import { GDriveFile } from './components/GDriveFile';
import { MiroFile } from './components/MiroFile';

import styles from './styles.module.scss';

export default function FilesContainer({
  files,
  isGDriveContainer,
  isMiroContainer,
  isCommentMod,
  setCanvasRef,

  ...props
}) {
  const { isAnnotationMode } = useSelector(state => state.app);

  const canvasRefs = useRef([]);

  const [indexRef, setIndexRef] = useState(0);

  useEffect(
    () => {
      setCanvasRef(canvasRefs.current[indexRef]);
    },
    [canvasRefs?.current[indexRef]?.current]
  );

  const filesForImageWithComments = useMemo(
    () => isGDriveContainer
      ? files.map(({ uuid, file_id, s3_link, thumbnail_url, mime_type, file_name }) => ({
        uuid: uuid,
        gDriveId: file_id,
        url: s3_link,
        thumbnail_url,
        mime_type,
        file_name,
      }))
      : files,
    [files, isGDriveContainer]
  );

  const filesComponentsForRender = isGDriveContainer || isMiroContainer
    ? (
      filesForImageWithComments.map((file, index) => !isMiroContainer && (
        file.mime_type.indexOf('image') != -1
            && !isAdobeIllustratorFile(file.file_name)
      )
        ? (
          <ImageWithComments
            index={index}
            key={file.uuid}
            canvasRefs={canvasRefs}
            file={file}
            isCommentMod={isCommentMod}
            isAnnotationMode={isAnnotationMode}
            {...props}
          />
        )
        : (
          <FunctionNotAvailable
            key={file.uuid}
            className={cn({
              'px-sm pt-sm': filesForImageWithComments.length > 1,
              [styles['many-iframes']]: filesForImageWithComments.length > 1,
            })}
            isNotAvailable
            isDisabledPinComment
          >
            {
              isMiroContainer && (
                <MiroFile
                  className={cn({ [styles['no-comments']]: isCommentMod })}
                  key={file.uuid}
                  file={file}
                  isCommentMod={isCommentMod}
                  {...props}
                />
              )
            }
            {
              isGDriveContainer && (
                <GDriveFile
                  className={cn({ [styles['no-comments']]: isCommentMod })}
                  key={file.uuid}
                  file={file}
                  isCommentMod={isCommentMod}
                  {...props}
                />
              )
            }
          </FunctionNotAvailable>
        ))
    )
    : (
      filesForImageWithComments.map((file, index) => (
        <ImageWithComments
          index={index}
          key={file.uuid}
          canvasRefs={canvasRefs}
          figmaFile={file}
          isCommentMod={isCommentMod}
          className={cn({
            'my-sm': filesForImageWithComments.length > 1,
          })}
          isAnnotationMode={isAnnotationMode}
          {...props}
        />
      ))
    );

  return (
    <div className={styles['file-container-wrapper']}>
      <Carousel
        dots={{ className: styles['carousel-dots'] }}
        easing="ease-in-out"
        afterChange={updateRef}
        adaptiveHeight
      >
        {filesComponentsForRender}
      </Carousel>
    </div>
  );

  function updateRef(index) {
    const updatedRef = canvasRefs.current[index];

    setIndexRef(index);

    setCanvasRef(updatedRef);
  }
}

FilesContainer.propTypes = {
  files: PropTypes.array.isRequired,

  isGDriveContainer: PropTypes.bool,
  isMiroContainer: PropTypes.bool,
  isCommentMod: PropTypes.bool,
  setCanvasRef: PropTypes.func.isRequired,
};
