import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { collection, doc, getDocs, onSnapshot, query, where, writeBatch } from 'firebase/firestore';
import cn from 'classnames';

import noAssignee from 'assets/no_assignee.svg';

import { DeleteItemModal } from 'Components/DeleteItemModal';

import {
  setCurrentProject,
  setFilteredTasks,
  setKanbanBoardTasks,
  setProjectFigmaCollaborators,
  setSavedFigmaFiles,
} from 'Redux/Actions/kanbanBoardActions';

import FigmaFile from 'services/figma_file';
import KanbanBoardServices from 'services/kanban_board';
import { getCollaboratorsOfProject } from 'services/projectServices';
import { addSlackLink, updateProjectAPI } from 'services/tasks';
import {
  createCustomTask,
  getFigmaCommentTasksV2,
  getLatestTasks,
  getNotesTasks,
  getTranscriptionCommentTasks,
  updateTaskAPI,
} from 'services/tasks';

import {
  INTERACTION_STATUS,
  KANBAN_BOARD_DATE_RANGE_OPTIONS,
  KANBAN_BOARD_FILE_PROGRESS_STATUS,
  KANBAN_BOARD_FILE_TYPE_VALUES,
  KANBAN_BOARD_LATEST_TASK_RESPONSE,
  KANBAN_BOARD_NOTIFICATION_DESCRIPTION,
  KANBAN_BOARD_SORT_OPTIONS,
  KANBAN_BOARD_SYNC_MESSAGES,
  KANBAN_BOARD_SYNC_OPTIONS,
  TASK_DETAILS,
} from 'utlis/constants';
import { Firestore } from 'utlis/firebase';
import { extractFigmaFileName } from 'utlis/string_format';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import { AddNewColumn } from './components/AddNewColumn.jsx';
import { KanbanBoardCols } from './components/KanbanBoardCols';
import { KanbanBoardHeader } from './components/KanbanBoardHeader';
import { LoaderColumns } from './components/LoaderColumn';
import { ManageFilesModal } from './components/ManageFilesModal';
import { StarterScreen } from './components/StarterScreen';
import { TaskDetailsPopup } from './components/TaskDetailsPopup';

import styles from './styles.module.scss';

export function Board() {
  const selectedProject = useSelector(state => state.selectedProject);
  const projectStatusCount = useSelector(state => state.projectStatusCount);
  const slackToken = useSelector(state => state.userStore?.appTokens?.slack?.user_access_token);
  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);
  const currentProject = useSelector(state => state.kanbanBoard.currentProject);
  const userUUID = useSelector(state => state.userProfile?.uuid);

  const [showManageFilesModal, setShowManageFilesModal] = useState(false);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [columns, setColumns] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskDetailsPopup, setShowTaskDetailsPopup] = useState(false);
  const [allFilesComments, setAllFilesComments] = useState({});
  const [showRemoveFileConfirm, setShowRemoveFileConfirm] = useState(false);
  const [fileID, setFileID] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const [showTasksOfFiles, setShowTasksOfFiles] = useState([]);
  const [sortOption, setSortOption] = useState(KANBAN_BOARD_SORT_OPTIONS.NEWEST_FIRST);
  const [searchedValue, setSearchedValue] = useState('');
  const [priorities, setPriorities] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [selectedFileKeys, setSelectedFileKeys] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [inProgressStatus, setInProgressStatus] = useState();
  const [newTaskIDs, setNewTaskIDs] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [transcriptFileDetails, setTranscriptFileDetails] = useState(null);
  const [notesFileDetails, setNotesFileDetails] = useState(null);
  const [prevPopup, setPrevPopup] = useState(null);
  const [boardColumns, setBoardColumns] = useState([]);
  const [colIdToEdit, setColIdToEdit] = useState('');
  const [newColumnName, setNewColumnName] = useState('');
  const [saveColName, setSaveColName] = useState(false);
  const [processedComments, setProcessedComments] = useState([]);
  const [isCreatingNewTask, setIsCreatingNewTask] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);

  const dispatch = useDispatch();

  const callHandleResync = useRef(true);

  const [api, contextHolder] = notification.useNotification();

  useEffect(
    () => {
      // extract filtered tasks from columns
      const filteredTasks = Object.values(columns).flatMap(column => column.items);

      // set filtered tasks in redux store
      dispatch(setFilteredTasks(filteredTasks));
      dispatch(setKanbanBoardTasks(tasks));
    },
    [columns]
  );

  useEffect(
    () => {
      const condition = where('project_id', '==', selectedProject.uuid);
      const firestoreTasksUnsubscribe = onSnapshot(
        query(collection(Firestore, 'tasks'), condition),
        async () => {
          await getAllTaskComments();
          await getBoardTasks();
        }
      );
      const firestoreProjectsUnsubscribe = onSnapshot(
        query(collection(Firestore, 'projects'), condition),
        async () => {
          await handleFetchProjectData();
        }
      );
      const firestoreFilesUnsubscribe = onSnapshot(
        query(collection(Firestore, 'files'), condition),
        () => {
          handleSyncTasks();
          getSavedFigmaFiles();
        }
      );

      return () => {
        firestoreTasksUnsubscribe();
        firestoreProjectsUnsubscribe();
        firestoreFilesUnsubscribe();
      };
    },
    []
  );

  useEffect(() => {
    if (boardColumns) {
      const searchedValueLower = searchedValue.toLowerCase();

      const columnsFromBackend = boardColumns.map(
        (colData, currentStatus) => (
          {
            [currentStatus + 1]: {
              name: colData['name'],
              items: tasks.filter(
                task => {
                  const statusExist = Number(task.taskDetails.status) === colData?.status;
                  const assigneeEmails = task.taskDetails.assignees?.map(val => val.email || '') || [];
                  const assigneeNames = task.taskDetails.assignees?.map(val => val.full_name || '') || [];

                  const emailSelectedAssignees = selectedAssignees.length === 0
                    || selectedAssignees?.find(assignee => assigneeEmails.includes(assignee?.email));

                  const noAssignees = selectedAssignees.find(assignee => assignee.email === 'none')
                    ? !task.taskDetails.assignees
                      || !task.taskDetails.assignees?.length
                      || task.taskDetails.assignees?.filter(val => typeof val === 'string').length
                    : false;

                  const isAssigneeSelected = emailSelectedAssignees || !!noAssignees;

                  //to make sure only tasks of files that are active are shown
                  const fileExist = showTasksOfFiles?.find(
                    file => file.figma_file_key === task.taskDetails.figma_file_key && file.is_active
                  );

                  // search based on assignee
                  const assigneesContainsSearchedValue = assigneeNames.find(
                    name => name?.toLowerCase().includes(searchedValueLower)
                  )
                    || assigneeEmails.find(email => email?.toLowerCase().includes(searchedValueLower));

                  // search based on label
                  const labelContainsSearchedValue = typeof task.taskDetails.label === 'string'
                    ? task.taskDetails.label.toLowerCase().includes(searchedValueLower)
                    : Object.values(
                      task.taskDetails.label
                    ).some(
                      labelValue => labelValue.toLowerCase().includes(searchedValueLower)
                    );

                  //search based on description, priority and label
                  const searchedValueExist
                    = task.taskDetails.description.toLowerCase().includes(searchedValueLower)
                      || task.taskDetails.priority.toLowerCase().includes(searchedValueLower)
                      || labelContainsSearchedValue || assigneesContainsSearchedValue;

                  //filter based on priority
                  const priorityExist
                    = selectedPriorities.length === 0 || selectedPriorities.includes(task.taskDetails.priority);

                  //filter based on file key
                  const filteredFileExist
                    = selectedFileKeys.length === 0 || selectedFileKeys.includes(task.taskDetails.figma_file_key);

                  //filter based on label
                  const labelExist
                    = selectedLabels.length === 0
                    || selectedLabels.some(label => {
                      if (typeof task.taskDetails.label === 'string') {
                        return task.taskDetails.label === label;
                      }

                      return Object.values(task.taskDetails.label).some(labelValue => labelValue === label);
                    });

                  //filter based on date range
                  const dateRangeExist = !selectedDateRange || handleDateRangeFilter(task.taskDetails);

                  return statusExist
                    && fileExist
                    && searchedValueExist
                    && priorityExist
                    && filteredFileExist
                    && labelExist
                    && dateRangeExist
                    && isAssigneeSelected;
                }
              ),
              status: colData?.status,
            },
          }
        )
      )
        .reduce((cols, column) => Object.assign(cols, column), {});

      setColumns(columnsFromBackend);
      setIsFiltered(false);
    }
  }, [tasks, showTasksOfFiles, searchedValue, isFiltered, boardColumns]);

  useEffect(
    () => {
      if (savedFigmaFiles.length && callHandleResync.current && !isLoadingTasks && !inProgressStatus) {
        handleReSyncTasks();
        callHandleResync.current = false; //To avoid calling handleReSyncTasks() on every savedFigmaFiles change
      }
    },
    [savedFigmaFiles.length, isLoadingTasks]
  );

  useEffect(
    () => {
      if (!inProgressStatus) {
        extractNewTasks(tasks);
        extractUniqueProperties();
      }
    },
    [tasks, inProgressStatus]
  );

  useEffect(
    () => {
      if (!inProgressStatus) {
        getSavedFigmaFiles();

        // set last updated message
        handleLastUpdated();
      }
    },
    [tasks.length, inProgressStatus]
  );

  useEffect(
    () => {
      if ((Object.entries(newTaskIDs).length > 0 || processedComments.length > 0) && !showNotification) {
        const notificationTitle = processedComments.length > 0
          ? (
            <div>
              {`${processedComments.length} comments processed and`}
              <br />
              {Object.entries(newTaskIDs).length || 'no'}
              {' '}
              new tasks generated
            </div>
          )
          : (
            <div>
              {Object.entries(newTaskIDs).length || 'No'}
              {' '}
              new tasks generated
            </div>
          );

        const notificationDescription = Object.entries(newTaskIDs).length > 0
          ? KANBAN_BOARD_NOTIFICATION_DESCRIPTION[Object.values(newTaskIDs)[0]]
          : KANBAN_BOARD_NOTIFICATION_DESCRIPTION.NO_TASKS;

        setShowNotification(true);
        openNotification(notificationTitle, notificationDescription);
      }
    },
    [newTaskIDs]
  );

  useEffect(
    () => {
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    },
    []
  );

  useEffect(() => {
    if (saveColName) {
      if (colIdToEdit) {
        KanbanBoardServices.updateColumnData(selectedProject?.uuid, colIdToEdit, newColumnName);
        setColIdToEdit('');
        setNewColumnName('');
      }

      setSaveColName(false);
    }
  }, [saveColName]);

  useEffect(
    () => {
      if (selectedProject.uuid && savedFigmaFiles.length) {
        handleFigmaCollaborators();
      }
    },
    [selectedProject, savedFigmaFiles]
  );

  return (
    <div className={styles['kanban-board-container']}>
      {contextHolder}
      <KanbanBoardHeader
        {...{
          lastUpdated,
          isLoadingTasks,
          inProgressStatus,
          sortOption,
          onChangeSortOption,
          selectedFileKeys,
          onChangeSelectedFileNames,
          selectedAssignees,
          onChangeSelectedAssignees,
          priorities,
          selectedPriorities,
          onChangeSelectedPriorities,
          labels,
          selectedLabels,
          onChangeSelectedLabels,
          selectedDateRange,
          onChangeDateRange,
          setIsFiltered,
          handleShowManageFileModal,
          handleReSyncTasks,
          onChangeSearchValue,
          handleClearFilterOptions,
          taskAssignees,
        }}
      />
      <div className={styles['columns-container']}>
        {
          !isLoadingTasks
            && (
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="all-columns" direction="horizontal" type="column">
                  {
                    (provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className={cn(
                          styles['kanban-board'],
                          {
                            [styles['kanban-board-with-blur']]: tasks.length === 0,
                          }
                        )}
                      >
                        {
                          Object.entries(columns).map(([columnId, column], index) => (
                            <KanbanBoardCols
                              key={columnId}
                              draggableIndex={index}
                              columnId={columnId}
                              column={column}
                              sortOption={sortOption}
                              openTaskDetailsPopup={openTaskDetailsPopup}
                              editTask={handleEditTask}
                              deleteTask={deleteTask}
                              allFilesComments={allFilesComments}
                              colIdToEdit={colIdToEdit}
                              setColIdToEdit={setColIdToEdit}
                              newColName={newColumnName}
                              setNewColName={setNewColumnName}
                              openCustomTaskModal={openCustomTaskModal}
                              inProgressStatus={inProgressStatus}
                            />
                          ))
                        }
                        {
                          !snapshot.isDraggingOver && (
                            <AddNewColumn />
                          )
                        }
                        {provided.placeholder}
                      </div>
                    )
                  }
                </Droppable>
              </DragDropContext>
            )
        }
        {
          isLoadingTasks
            ? (
              <LoaderColumns numCardsPerColumn={4} numColumns={4} />
            )
            : tasks.length === 0 && !inProgressStatus
              ? (
                <StarterScreen
                  openCustomTaskModal={openCustomTaskModal}
                  onClickAddFigmaFileButton={handleShowManageFileModal}
                />
              )
              : null
        }
      </div>
      {
        showTaskDetailsPopup && (
          <TaskDetailsPopup
            selectedTask={selectedTask}
            visible={showTaskDetailsPopup}
            handleUpdateTaskDetails={handleUpdateTaskDetails}
            onCancel={closeTaskDetailModal}
            allFilesComments={allFilesComments}
            deleteTask={deleteTask}
            handleTranscriptFileModal={handleTranscriptFileModal}
            isCreatingNewTask={isCreatingNewTask}
            selectedColumn={selectedColumn}
            handleCreateCustomTask={handleCreateCustomTask}
          />
        )
      }
      <ManageFilesModal
        closeModal={closeManageFilesModal}
        showManageFilesModal={
          showManageFilesModal
            || localStorage.getItem('connectingToSlack') === 'true'
            || localStorage.getItem('connectingToGoogleDocs') === 'true'
            || (savedFigmaFiles.length === 0 && !isLoadingTasks)
        }
        prevPopup={prevPopup}
        handleTaskDetailsPopup={handleTaskDetailsPopup}
        transcriptFileDetails={transcriptFileDetails}
        notesFileDetails={notesFileDetails}
        handleAddFigmaFile={handleAddFigmaFile}
        handleAddTranscription={handleAddTranscription}
        handleAddNotes={handleAddNotes}
        handleAddSlackThread={handleAddSlackThread}
        savedFigmaFiles={savedFigmaFiles}
        showTasksOfFiles={showTasksOfFiles}
        switchOnChange={switchOnChange}
        showRemoveFileModal={showRemoveFileModal}
        openCustomTaskModal={openCustomTaskModal}
      />
      <DeleteItemModal
        questionText="Are you sure you want to delete the tasks associated with this file?"
        visible={showRemoveFileConfirm}
        onDelete={handleRemoveFile}
        onCancel={() => setShowRemoveFileConfirm(false)}
        zIndex={2000}
      />
    </div>
  );

  function closeTaskDetailModal() {
    setShowTaskDetailsPopup(false);
    setIsCreatingNewTask(false);
  }

  async function handleCreateCustomTask(taskDetails) {
    setInProgressStatus(KANBAN_BOARD_SYNC_MESSAGES.CREATING);
    setShowTaskDetailsPopup(true);
    setIsCreatingNewTask(true);
    await createCustomTask(selectedProject.uuid, taskDetails, userUUID);
  }

  function openCustomTaskModal(columnID) {
    setSelectedTask(null);
    setSelectedColumn(columnID);
    setIsCreatingNewTask(true);
    setShowTaskDetailsPopup(true);
  }

  function clickOutside(event) {
    const inputForColNameClicked = event.target.closest(`.${styles['col-title-input']}`);

    if (!inputForColNameClicked) {
      setSaveColName(true);
    }
  }

  function handleDestroyNotification() {
    if (showNotification) {
      notification.destroy();
      setShowNotification(false);
      setProcessedComments([]);
      setNewTaskIDs({});
    }
  }

  function openNotification(title, description) {
    api.open({
      className: styles['notification'],
      message: title,
      description: description,
      duration: 0,
      placement: 'bottomRight',
      maxCount: 1,
      onClose: handleDestroyNotification,
    });
  }

  function closeManageFilesModal() {
    setShowManageFilesModal(false);
    localStorage.setItem('connectingToSlack', false);
    localStorage.setItem('connectingToGoogleDocs', false);
    setPrevPopup(null);
    setTranscriptFileDetails({});
    setNotesFileDetails({});
  }

  function handleShowManageFileModal() {
    setTranscriptFileDetails({});
    handleDestroyNotification();
    getSavedFigmaFiles();
    setShowManageFilesModal(true);
  }

  function switchOnChange(checked, figmaFile) {
    // update the is_active property of the file
    const updateShowTasksOfFiles = showTasksOfFiles?.map(
      file => file.figma_file_key === figmaFile.figma_file_key
        ? { figma_file_key: figmaFile.figma_file_key, is_active: checked }
        : file
    );

    setShowTasksOfFiles(updateShowTasksOfFiles);
  }

  function handleShowSyncMessage(foundDoc) {
    let progressStatus = '';

    if (foundDoc) {
      const fileType = foundDoc.data().file_type;
      const fileStatus = foundDoc.data().status;

      if (fileStatus === KANBAN_BOARD_SYNC_OPTIONS.PROCESSING) {
        progressStatus = KANBAN_BOARD_FILE_PROGRESS_STATUS[fileType] || KANBAN_BOARD_FILE_PROGRESS_STATUS.OTHER;
      }
      else {
        progressStatus = KANBAN_BOARD_SYNC_MESSAGES.SYNCING;
      }
    }
    setInProgressStatus(progressStatus);
  }

  async function handleSyncTasks() {
    const filesRef = collection(Firestore, 'files');
    const filesQuery = query(filesRef, where('project_id', '==', selectedProject.uuid));

    const querySnapshot = await getDocs(filesQuery);

    const foundDoc = querySnapshot.docs.find(
      currentDoc => currentDoc.data().status === KANBAN_BOARD_SYNC_OPTIONS.PROCESSING
        || currentDoc.data().status === KANBAN_BOARD_SYNC_OPTIONS.SYNCING
    );

    handleShowSyncMessage(foundDoc);
  }

  async function handleReSyncTasks() {
    const figmaFileKeys = savedFigmaFiles
      .filter(file => file.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA)
      .map(file => file.figma_file_key);

    if (figmaFileKeys.length > 0) {
      setInProgressStatus(KANBAN_BOARD_SYNC_MESSAGES.SYNCING);

      const getLatestTaskResponse = await getLatestTasks(
        figmaFileKeys,
        figmaAppToken?.access_token,
        selectedProject.uuid,
        userUUID
      );

      if (getLatestTaskResponse?.message === KANBAN_BOARD_LATEST_TASK_RESPONSE.ALREADY_UPDATED) {
        setInProgressStatus('');
        successReactToastify(KANBAN_BOARD_LATEST_TASK_RESPONSE.ALREADY_UPDATED);
      }
    }
  }

  async function handleRemoveFile() {
    try {
      const filesRef = collection(Firestore, 'files');
      const tasksRef = collection(Firestore, 'tasks');

      const filesQuery = query(
        filesRef,
        where('figma_file_key', '==', fileID),
        where('project_id', '==', selectedProject.uuid)
      );

      const filesDocs = await getDocs(filesQuery);

      const taskQuery = query(
        tasksRef,
        where('figma_file_key', '==', fileID),
        where('project_id', '==', selectedProject.uuid)
      );

      const taskDocs = await getDocs(taskQuery);

      const batch = writeBatch(Firestore);

      // Delete all tasks associated with this file
      taskDocs.docs.forEach(currentDoc => {
        batch.delete(doc(tasksRef, currentDoc.id));
      });

      // Delete all files associated with this file
      filesDocs.docs.forEach(currentDoc => {
        batch.delete(doc(filesRef, currentDoc.id));
      });
      // Commit the batch to perform all the deletions at once
      await batch.commit();

      successReactToastify('File along with its tasks removed successfully');
    }
    catch (e) {
      errorReactToastify(e?.message);
    }
    finally {
      setFileID(null);
      setShowRemoveFileConfirm(false);
    }
  }

  function checkIfTasksPreviouslyGenerated() {
    const totalProjects = projectStatusCount?.active + projectStatusCount?.completed;

    return totalProjects === 1 && tasks.length === 0 && !isLoadingTasks && !inProgressStatus;
  }

  async function handleAddFigmaFile(figmaFileUrl) {
    /** showing the inprogress message as soon as the user clicks on the import button so that the user does not experiences any delays */
    setInProgressStatus(KANBAN_BOARD_SYNC_MESSAGES.PROCESSING_FIGMA_FILE);
    const figmaFileInfo = await FigmaFile.getFigmaFileInfoFromFigmaUrl(figmaFileUrl);
    const figmaFileName = extractFigmaFileName(figmaFileInfo.fileName);

    closeManageFilesModal(); // close the modal before adding the file
    showBoardSpinner();

    if (checkIfTasksPreviouslyGenerated()) {
      ReactGA.event({
        category: 'figma_file',
        action: 'first_figma_file_added',
      });
    }
    ReactGA.event({
      category: 'figma_file',
      action: 'figma_file_added',
    });

    try {
      const response = await getFigmaCommentTasksV2(
        figmaFileInfo.fileKey,
        figmaAppToken?.access_token,
        selectedProject.uuid,
        figmaFileName,
        userUUID
      );

      setProcessedComments(response?.data?.comments[0]?.comments);

      await getAllTaskComments();
      handleFigmaCollaborators();
    }
    catch (error) {
      errorReactToastify(error?.response?.data, { autoClose: 5000 });
      setInProgressStatus('');
    }
  }

  async function handleAddTranscription(values) {
    const fileName = values.title;
    const transcriptionTitle = values.title;
    const transcriptionContent = values.content;

    await getTranscriptionCommentTasks(
      selectedProject.uuid,
      fileName,
      transcriptionTitle,
      transcriptionContent,
      userUUID
    );
  }

  async function handleAddNotes(values) {
    const fileName = values.title;
    const notesTitle = values.title;
    const notesContent = values.content;

    await getNotesTasks(
      selectedProject.uuid,
      fileName,
      notesTitle,
      notesContent,
      userUUID
    );
  }

  async function handleAddSlackThread(threadUrl, fileType) {
    setInProgressStatus(KANBAN_BOARD_SYNC_MESSAGES.PROCESSING_SLACK_THREAD);

    closeManageFilesModal();
    showBoardSpinner();

    try {
      await addSlackLink(selectedProject?.uuid, fileType, threadUrl, slackToken, userUUID);
    }
    catch (error) {
      errorReactToastify(error?.response?.data.message, { autoClose: 5000 });
      setInProgressStatus('');
    }
  }

  function handleLastUpdated() {
    // get last created task
    const lastUpdatedTask = tasks?.length > 0
      ? tasks?.sort(
          (a, b) => b.taskDetails.created_at - a.taskDetails.created_at
        )[0].taskDetails.created_at.toDate().toString()
      : null;

    setLastUpdated(lastUpdatedTask);
  }

  async function getSavedFigmaFiles() {
    const filesData = await KanbanBoardServices.getFigmaFiles(selectedProject.uuid);

    dispatch(setSavedFigmaFiles(filesData));

    // update showTasksOfFiles with new files
    const updatedShowTasksOfFiles = filesData.map(
      fileData => {
        const fileExist = showTasksOfFiles?.find(file => file.figma_file_key === fileData.figma_file_key);

        return fileExist || { figma_file_key: fileData.figma_file_key, is_active: true };
      }
    );

    setShowTasksOfFiles(updatedShowTasksOfFiles);
  }

  async function getAllTaskComments() {
    const comments = await KanbanBoardServices.getTaskComments(selectedProject.uuid);

    setAllFilesComments(comments);
  }

  function extractNewTasks() {
    const date1 = new Date();
    const newTaskIDsToUpdate = {};

    tasks.forEach(
      task => {
        const date2 = new Date(task.taskDetails.created_at.toDate());
        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(date1 - date2);

        // Check if the time difference is 30 seconds or less
        if (timeDifference <= 30000) {
          newTaskIDsToUpdate[task.taskID] = task.taskDetails.file_type;
        }
      }
    );

    setNewTaskIDs(newTaskIDsToUpdate);
  }

  async function getBoardTasks() {
    try {
      const collectionRef = collection(Firestore, 'tasks');
      const q = query(collectionRef, where('project_id', '==', selectedProject.uuid));

      const querySnapshot = await getDocs(q);

      const tasksData = querySnapshot.docs.map(
        currentDoc => ({
          taskID: currentDoc.id,
          taskDetails: currentDoc.data(),
        })
      );

      setTasks(tasksData);
    }
    finally {
      setIsLoadingTasks(false);
    }
  }

  async function deleteTask(taskID) {
    try {
      await KanbanBoardServices.deleteTask(taskID);
      successReactToastify('Task deleted successfully');
    }
    catch (e) {
      errorReactToastify(e?.message);
    }
  }

  async function handleEditTask(taskID, description) {
    try {
      await KanbanBoardServices.updateTask(taskID, { description });
      successReactToastify('Task updated successfully');
    }
    catch (e) {
      errorReactToastify(e?.message);
    }
  }

  async function handleUpdateTaskDetails(taskID, detailsToUpdate) {
    try {
      await KanbanBoardServices.updateTask(taskID, detailsToUpdate);
      successReactToastify('Task updated successfully');
    }
    catch (e) {
      errorReactToastify(e?.message);
    }
  }

  function onDragStart() {
    document.documentElement.style.scrollBehavior = 'auto';
  }

  async function onDragEnd(result) {
    document.documentElement.style.scrollBehavior = null;

    if (result.destination) {
      const { source, destination, type } = result;

      if (type === 'task') {
        if (source.droppableId !== destination.droppableId) {
          const destinationColumn = columns[destination.droppableId];

          dragInDiffCol(source, destination);
          const columnID = destinationColumn.status;

          await KanbanBoardServices.updateTask(result.draggableId, { status: columnID });
        }
        else {
          dragInSameCol(source, destination);
        }
      }
      else {
        handleUpdateColumns(source.index, destination.index);
      }
    }
  }

  function handleUpdateColumns(source, destination) {
    const updatedColumns = [...boardColumns];
    const [removedCol] = updatedColumns.splice(source, 1);

    updatedColumns.splice(destination, 0, removedCol);

    setBoardColumns(updatedColumns);
    updateProjectColumns(currentProject?.docID, updatedColumns);
  }

  async function updateProjectColumns(docID, updatedColumns) {
    await updateProjectAPI(docID, { columns: updatedColumns }, userUUID);
  }

  function dragInDiffCol(source, destination) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);

    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  }

  function dragInSameCol(source, destination) {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);

    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }

  function extractUniqueProperties() {
    const allPriorities = [];
    const allLabels = [];
    let allAssignees = [];
    let allAssigneesEmails = [];

    Object.values(tasks).forEach(
      task => {
        const { priority, label, assignees } = task.taskDetails;

        const formattedAssignees = assignees
          ? typeof assignees === 'string'
            ? [assignees]
            : assignees
          : [];

        const newAssignees = formattedAssignees.filter(
          assignee => typeof assignee !== 'string' && !allAssigneesEmails.includes(assignee.email)
        );

        allAssignees = [...allAssignees, ...newAssignees];
        allAssigneesEmails = [...allAssigneesEmails, ...(newAssignees.map(val => val.email))];

        if (!allPriorities.includes(priority)) {
          allPriorities.push(priority);
        }

        if (typeof label !== 'string') {
          Object.values(label).forEach(
            value => {
              if (!allLabels.includes(value)) {
                allLabels.push(value);
              }
            }
          );
        }
        else if (!allLabels.includes(label)) {
          allLabels.push(label);
        }
      }
    );

    setPriorities(allPriorities);
    setLabels(allLabels);
    setTaskAssignees([{ full_name: 'No assignee', email: 'none', profile_photo: noAssignee }, ...allAssignees]);
  }

  function handleDateRangeFilter(taskDetails) {
    const secondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const endDate = new Date();
    const taskCreatedAt = new Date(taskDetails?.source_created_at?.toDate());
    let startDate;

    switch (selectedDateRange) {
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_WEEK:
        startDate = new Date(endDate.getTime() - secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_TWO_WEEKS:
        startDate = new Date(endDate.getTime() - 2 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_THREE_WEEKS:
        startDate = new Date(endDate.getTime() - 3 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 1);
        break;
    }

    return taskCreatedAt >= startDate && taskCreatedAt <= endDate;
  }

  function showBoardSpinner() {
    setShowManageFilesModal(false);
    localStorage.setItem('connectingToSlack', false);
    localStorage.setItem('connectingToGoogleDocs', false);
  }

  function openTaskDetailsPopup(taskID, taskDetails) {
    const fileDetails = savedFigmaFiles.find(file => file.figma_file_key === taskDetails.figma_file_key);

    setSelectedTask({
      taskID,
      taskDetails,
      fileDetails,
    });

    setShowTaskDetailsPopup(true);
    handleRemoveNewTaskID(taskID);

    // update interaction status of task to read
    if (
      taskDetails[TASK_DETAILS.INTERACTION_STATUS]
        && taskDetails[TASK_DETAILS.INTERACTION_STATUS] === INTERACTION_STATUS.UNREAD
    ) {
      updateTaskDetails(taskID, { [TASK_DETAILS.INTERACTION_STATUS]: INTERACTION_STATUS.READ });
    }
  }

  function updateTaskDetails(taskID, detailsToUpdate) {
    try {
      updateTaskAPI(taskID, detailsToUpdate, userUUID);
    }
    catch (error) {
      Sentry.captureException('Error is ', error);
    }
  }

  // to remove the new task id from the list of new tasks
  function handleRemoveNewTaskID(taskID) {
    if (Object.keys(newTaskIDs).includes(taskID)) {
      const updatedNewTaskIDs = { ...newTaskIDs };

      delete updatedNewTaskIDs[taskID];
      setNewTaskIDs(updatedNewTaskIDs);
    }
  }

  function showRemoveFileModal(figmaFileKey) {
    setFileID(figmaFileKey);
    setShowRemoveFileConfirm(true);
  }

  function onChangeSearchValue(value) {
    setSearchedValue(value);
  }

  function onChangeSortOption(value) {
    setSortOption(value);
  }

  function onChangeFilterOption(value, items, setItems) {
    if (items.includes(value)) {
      setItems(items.filter(item => item !== value));
    }
    else {
      setItems([...items, value]);
    }
  }

  function onChangeSelectedAssignees(value) {
    onChangeFilterOption(value, selectedAssignees, setSelectedAssignees);
  }

  function onChangeDateRange(value) {
    if (selectedDateRange.includes(value)) {
      setSelectedDateRange('');
    }
    else {
      setSelectedDateRange(value);
    }
  }

  function onChangeSelectedFileNames(value) {
    onChangeFilterOption(value, selectedFileKeys, setSelectedFileKeys);
  }

  function onChangeSelectedLabels(value) {
    onChangeFilterOption(value, selectedLabels, setSelectedLabels);
  }

  function onChangeSelectedPriorities(value) {
    onChangeFilterOption(value, selectedPriorities, setSelectedPriorities);
  }

  function handleClearFilterOptions() {
    setSelectedPriorities([]);
    setSelectedFileKeys([]);
    setSelectedLabels([]);
    setSelectedAssignees([]);
    setSelectedDateRange('');
    setIsFiltered(true);
  }

  function showTranscriptModal() {
    setShowTaskDetailsPopup(false);
    setShowManageFilesModal(true);
  }

  function handleTranscriptFileModal(details) {
    if (details?.transcript || details?.notes) {
      if (details?.transcript) {
        setTranscriptFileDetails(details?.transcript);
      }
      else {
        setNotesFileDetails(details?.notes);
      }

      setPrevPopup('taskDetailsPopup');
      showTranscriptModal();
    }
  }

  function handleTaskDetailsPopup() {
    setPrevPopup(null);
    setShowManageFilesModal(false);
    setShowTaskDetailsPopup(true);
    localStorage.setItem('connectingToSlack', false);
    localStorage.setItem('connectingToGoogleDocs', false);
    setTranscriptFileDetails({});
    setNotesFileDetails({});
  }

  async function handleFetchProjectData() {
    const project = await KanbanBoardServices.getCurrentProject(selectedProject.uuid);

    dispatch(setCurrentProject(project));

    setBoardColumns(project?.details?.columns);

    // if status of the columns don't exist
    if (!project?.details?.columns[0]?.status) {
      const updatedColumns = project.details.columns.map(
        (column, index) => {
          if (!column.status) {
            return {
              ...column,
              status: index + 1,
            };
          }

          return column;
        }
      );

      const updatedProject = {
        ...project,
        details: {
          ...project.details,
          columns: updatedColumns,
        },
      };

      dispatch(setCurrentProject(updatedProject));
      updateProjectColumns(project?.docID, updatedColumns);
    }
  }

  async function handleFigmaCollaborators() {
    const allFigmaFileKeys = savedFigmaFiles.filter(
      file => file.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
    ).map(
      file => file.figma_file_key
    );

    const data = await getCollaboratorsOfProject(selectedProject.uuid, allFigmaFileKeys);

    dispatch(setProjectFigmaCollaborators(data));
  }
}
