import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import AddNewMilestoneModal from 'Components/AddNewMilestoneModal';

import { addMilestone } from 'Redux/Actions/FeedActions';
import {
  deleteMilestone,
  moveMilestoneOrder,
} from 'Redux/Actions/FeedActions';

import MilestoneService from 'services/milestones';

import { MILESTONES_TYPES } from 'utlis/constants';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';
import { MILESTONE_METADATA } from 'utlis/workspace.constants';

import { MilestoneCard } from './components/MilestoneCard';
import { NewMilestonesDropdown } from './components/NewMilestonesDropdown';

import styles from './styles.module.scss';

export const MilestonesList = ({ isReadonly }) => {
  const { milestoneId } = useParams();

  const [milestoneList, setMilestoneList] = useState([]);
  const [milestonesListThatUserCanCreateInProject, setMilestonesListThatUserCanCreateInProject] = useState([]);
  const [showAddNewMilestoneModal, setShowAddNewMilestoneModal] = useState(false);
  const [milestoneName, setMilestoneName] = useState('');
  const [milestoneType, setMilestoneType] = useState('');

  const currentProject = useSelector(state => state.selectedProject);
  const milestonesFromStore = useSelector(state => state.milestonesStore.list);
  const currentUser = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef();

  useEffect(
    () => {
      if (milestonesFromStore?.length > 0) {
        setMilestoneList(milestonesFromStore);
      }
    },
    [milestonesFromStore]
  );

  useEffect(
    () => {
      MilestoneService.getMilestonesListThatUserCanCreateInProject(currentProject.uuid, currentProject.type)
        .then(setMilestonesListThatUserCanCreateInProject);
    },
    [currentProject, milestonesFromStore]
  );

  return (
    <>
      <div className={cn(styles.workspace__sidebarWrapper, 'py-md')}>
        <div className="flex space-between align-center mx-md mb-16">
          <p className="text-weight-600 text-black text-20 letter-xs m-zero">
            Milestones
          </p>
          <NewMilestonesDropdown
            milestonesList={milestonesListThatUserCanCreateInProject}
            onAddNewMilestone={handleAddNewMilestone}
            getPopupContainer={() => ref?.current}
            isClientView={currentUser?.permissions?.isClient}
            className={cn({ hide: isReadonly })}
          >
            <div className={styles.new__templateButton} ref={ref}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </NewMilestonesDropdown>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className={styles.workspace_sidebarCardWrapper}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {milestoneList.map((milestone, index) => (
                  <Draggable
                    key={milestone.id}
                    draggableId={milestone.id.toString()}
                    index={index}
                    isDragDisabled={
                    currentUser?.permissions?.isClient
                      || [MILESTONES_TYPES.brief, MILESTONES_TYPES.uxBrief].includes(milestone.type)
                    }
                  >
                    {draggableProvided => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <MilestoneCard
                          {...milestone}
                          onDeleteMilestone={currentUser.uuid == milestone.created_by && onDeleteMilestone}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <AddNewMilestoneModal
        visible={showAddNewMilestoneModal}
        milestoneName={milestoneName}
        milestoneType={milestoneType}
        handleCreateMilestone={handleCreateMilestone}
        onCancel={setShowAddNewMilestoneModal}
      />
    </>
  );

  async function onDeleteMilestone(milestone) {
    try {
      const {
        type: previousMilestoneType,
        id: previousMilestoneId,
      } = milestonesFromStore[milestone.sequence_no - 2];

      await dispatch(deleteMilestone(currentProject, milestone));

      successReactToastify(`${milestone.name} Milestone is deleted successfully.`);

      if (milestoneId == milestone.id) {
        navigate(MILESTONE_METADATA[previousMilestoneType].route(currentProject.slug, previousMilestoneId));
      }
    }
    catch (error) {
      if (error?.response?.data?.detail) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }

  function onDragEnd(result) {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    const items = reorder(milestoneList, result.source.index, result.destination.index);

    dispatch(moveMilestoneOrder(currentProject, items, setMilestoneList));

    if (result.source.index > 0 && result.destination.index > 0) {
      setMilestoneList(items);
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }

  function handleAddNewMilestone(name, type) {
    setMilestoneName(name);
    setMilestoneType(type);
    setShowAddNewMilestoneModal(true);
  }

  async function handleCreateMilestone(name, closeModalWindow) {
    try {
      await dispatch(addMilestone(
        currentProject,
        milestoneId,
        name,
        milestoneType
      ));

      closeModalWindow();
      successReactToastify(`${milestoneName} Milestone is created successfully.`);
    }
    catch (error) {
      if (error?.response?.data?.detail) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }
};

MilestonesList.propTypes = {
  isReadonly: PropTypes.bool,
};
