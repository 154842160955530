import thumbnail_image_1 from 'assets/images/project_thumbnail_images/thumbnail_image_1.png';
import thumbnail_image_2 from 'assets/images/project_thumbnail_images/thumbnail_image_2.png';
import thumbnail_image_3 from 'assets/images/project_thumbnail_images/thumbnail_image_3.png';
import thumbnail_image_4 from 'assets/images/project_thumbnail_images/thumbnail_image_4.png';
import thumbnail_image_5 from 'assets/images/project_thumbnail_images/thumbnail_image_5.png';
import thumbnail_image_6 from 'assets/images/project_thumbnail_images/thumbnail_image_6.png';
import thumbnail_image_7 from 'assets/images/project_thumbnail_images/thumbnail_image_7.png';
import thumbnail_image_8 from 'assets/images/project_thumbnail_images/thumbnail_image_8.png';
import thumbnail_image_9 from 'assets/images/project_thumbnail_images/thumbnail_image_9.png';
import thumbnail_image_10 from 'assets/images/project_thumbnail_images/thumbnail_image_10.png';

import { convertToBase64 } from './globalFunctions';

const getRandomThumbnailImage = projectsCount => [
  thumbnail_image_1,
  thumbnail_image_2,
  thumbnail_image_3,
  thumbnail_image_4,
  thumbnail_image_5,
  thumbnail_image_6,
  thumbnail_image_7,
  thumbnail_image_8,
  thumbnail_image_9,
  thumbnail_image_10,
][projectsCount % 10 || 0];

export const getProjectThumbnail = async projectsCount => {
  const thumbnailImage = await (await fetch(getRandomThumbnailImage(projectsCount))).blob();
  const base64Image = await convertToBase64(thumbnailImage);

  return base64Image;
};
