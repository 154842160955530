import { saveAs } from 'file-saver';
import JSzip from 'jszip';

export default async (filesUrls, downloadFileName) => {
  if (Array.isArray(filesUrls)) {
    const imagesBlobs = await Promise.all(filesUrls.map(getImageBlob));
    const zip = new JSzip();

    imagesBlobs.forEach((blob, idx) => zip.file(getFileName(filesUrls[idx]), blob));

    zip.generateAsync({ type: 'blob' })
      .then(content => saveAs(content, downloadFileName));
  }
  else {
    const imageBlob = await getImageBlob(filesUrls);
    const imageURL = URL.createObjectURL(imageBlob);
    const link = document.createElement('a');

    link.href = imageURL;
    link.download = downloadFileName || getFileName(filesUrls);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

async function getImageBlob(imageUrl) {
  return (await fetch(`${imageUrl}?random=${Math.trunc(Math.random() * 10000)}`)).blob();
}

function getFileName(fileURL) {
  return fileURL.substring(fileURL.lastIndexOf('/') + 1);
}
