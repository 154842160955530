import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Col, Divider, Row, Spin } from 'antd';

import { UserEWallet } from 'Components/UserEWallet';

import { setUserProfile } from 'Redux/Actions/userActions';

import StripePaymentService from 'services/stripe_payment';

import { NOTIFICATION_TYPES } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import { MakePayment } from './components/MakePayment';
import PaymentFormModal from './components/PaymentFormModal';
import { PaymentHistoryTable } from './components/PaymentHistoryTable';
import { RequestPaymentForm } from './components/RequestPaymentForm';
import { UserWithoutStripe } from './components/UserWithoutStripe';

import styles from './styles.module.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export function ProjectPayments() {
  const [paymentListData, setPaymentListData] = useState({
    count: 0,
    list: [],
    currentPage: 1,
    totalPage: 0,
    pageSize: 10,
  });
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pendingPaymentItem, setPendingPaymentItem] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [showPaymentFormModal, setShowPaymentFormModal] = useState(false);

  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);
  const selectedProject = useSelector(state => state.selectedProject);

  // Returned from payment gateway
  const [searchParams, setSearchParams] = useSearchParams();
  const returnedSuccessful = searchParams.get('redirect_status') === 'succeeded';

  const dispatch = useDispatch();

  const isDesignerWithoutStripe = useMemo(
    () => userProfile?.permissions?.isDesigner
      && (
        !userProfile?.designer_profile?.is_stripe_acc
          || !userProfile?.designer_profile?.is_charges_enabled
      ),
    [userProfile]
  );
  const projectHasClient = useMemo(
    () => selectedProject.collaborators.find(
      ({ user: { permissions: { isClient } } }) => isClient
    ),
    [selectedProject]
  );

  const options = getStripeElementOptions(clientSecret);

  useEffect(
    () => {
      Promise.all([
        dispatch(setUserProfile(authUser)),
        loadPaymentsList(paymentListData.currentPage),
      ])
        .then(() => setIsPageLoading(false));
      handleSuccessOnReturn();
    },
    []
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { project_uuid } }) => {
        if (project_uuid == selectedProject.uuid) {
          loadPaymentsList(1, true);
        }
      },
      [
        NOTIFICATION_TYPES.REQUEST_PAYMENT,
        NOTIFICATION_TYPES.PAYMENT_REQUEST_DENIED,
        NOTIFICATION_TYPES.PAYMENT_AMOUNT_PAID,
        NOTIFICATION_TYPES.PAYMENT_REQUEST_DELETED,
      ]
    ),
    [selectedProject]
  );

  if (isPageLoading) {
    return (
      <div className="flex align-center just-center pt-lg">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="px-32 pt-32">
      <Row>
        <Col span="18" className={styles['projects__payment-column']}>
          {
            returnedSuccessful && (
              <h2 className="text-black-opacity-0-60 ml-sm-md">
                Please wait ...
              </h2>
            )
          }
          {
            userProfile?.permissions?.isDesigner && isDesignerWithoutStripe && (
              <UserWithoutStripe className="mb-48" />
            )
          }
          {
            userProfile?.permissions?.isDesigner
              && !isDesignerWithoutStripe
              && !pendingPaymentItem
              && (
                <RequestPaymentForm
                  currentUser={userProfile}
                  onSubmit={handleRequestPayment}
                />
              )
          }
          {
            userProfile?.permissions?.isClient
              && !clientSecret
              && !returnedSuccessful
              && (
                <MakePayment
                  className="mb-56"
                  paymentItem={pendingPaymentItem}
                  onPaymentDeny={handlePaymentRejection}
                  onPayPayment={openPaymentFormModal}
                />
              )
          }
          <p className="text-weight-500 text-20 letter-xs text-black-opacity-0-60 pl-sm-md">
            Fees Breakdown
          </p>
          <Divider className="mt-sm-md mb-small-md-lg" />
          <PaymentHistoryTable
            pageStart={paymentListData.currentPage}
            paymentsList={
              paymentListData.list.length
                ? paymentListData.list
                : [{
                  amount: '-',
                  created_at: '-',
                  currency: undefined,
                  payment_status: '-',
                  payment_type: '-',
                  platform_fee: '-',
                  receipt: '-',
                  stripe_fee: '-',
                  total: '-',
                  updated_at: '-',
                  uuid: '-',
                }]
            }
            loadMore={loadPaymentsList}
            useWindow={false}
            hasMore={paymentListData.currentPage < paymentListData.totalPage}
            columnsKeys={[
              PaymentHistoryTable.columnsKeys.amount,
              PaymentHistoryTable.columnsKeys.stripeFee,
              PaymentHistoryTable.columnsKeys.paymentStatus,
              PaymentHistoryTable.columnsKeys.total,
              PaymentHistoryTable.columnsKeys.remove,
            ]}
            onRemovePaymentRequest={handlePaymentCancelByDesigner}
          />
        </Col>
        <Col span="6">
          {
            userProfile?.uuid
              && userProfile?.permissions?.isDesigner
              && !isDesignerWithoutStripe
              && <UserEWallet className={styles.projects__withdrawContainer} />
          }
        </Col>
      </Row>

      {/**Client pays the payment
        * TODO: Find out how to find requesting designer name
      */}

      {
        clientSecret
          && pendingPaymentItem
          && userProfile?.permissions?.isClient
          && (
            <Elements stripe={stripePromise} options={options}>
              <PaymentFormModal
                projectUUID={selectedProject.uuid}
                setClientSecret={setClientSecret}
                onSuccessPayment={() => loadPaymentsList(1, true)}
                paymentItem={pendingPaymentItem}

                visible={showPaymentFormModal}
                onCancel={() => {
                  setClientSecret(null);
                  setShowPaymentFormModal(false);
                }}
              />
            </Elements>
          )
      }
    </div>
  );

  async function loadPaymentsList(newCurrentPage, isUpdateNewCurrentPage) {
    const newData = await StripePaymentService.getPaymentsList(
      selectedProject.uuid,
      newCurrentPage,
      paymentListData.pageSize
    );

    if (!pendingPaymentItem || isUpdateNewCurrentPage) {
      setPendingPaymentItem(newData.results.find(
        item => item.payment_status === StripePaymentService.PAYMENT_STATUSES.requested
          || item.payment_status === StripePaymentService.PAYMENT_STATUSES.initiated
      ));
    }

    setPaymentListData(currentPaymentListData => ({
      ...paymentListData,

      count: newData.count,
      list: isUpdateNewCurrentPage
        ? [
          ...newData.results,
          ...currentPaymentListData.list.slice(newCurrentPage * currentPaymentListData.pageSize, -1),
        ]
        : [...currentPaymentListData.list, ...newData.results],
      currentPage: isUpdateNewCurrentPage ? currentPaymentListData.currentPage : newCurrentPage,
      totalPage: newData.count / currentPaymentListData.pageSize,
    }));
  }

  async function handleSuccessOnReturn() {
    if (searchParams.get('redirect_status') === 'succeeded') {
      await StripePaymentService.postPaymentCallback(
        selectedProject.uuid,
        searchParams.get('payment_id'),
        searchParams.get('save_card') == 'true'
      );

      setTimeout(
        async () => {
          await loadPaymentsList(1, true);
          successReactToastify('Payment has been processed');
          setSearchParams('');
        },
        2000
      );
    }
  }

  async function handleRequestPayment(requestedAmount) {
    if (projectHasClient) {
      try {
        const newRequestedPayment = await StripePaymentService.createPaymentRequestByDesigner(
          selectedProject.uuid,
          requestedAmount,
          userProfile.designer_profile?.currency,
          'payment'
        );

        setPaymentListData(currentPaymentListData => ({
          ...currentPaymentListData,

          count: currentPaymentListData.count + 1,
          list: [newRequestedPayment, ...currentPaymentListData.list],
          totalPage: (currentPaymentListData.count + 1) / currentPaymentListData.pageSize,
        }));
        setPendingPaymentItem(newRequestedPayment);

        successReactToastify('The payment has been requested');
      }
      catch (error) {
        if (error?.response?.status == 400) {
          errorReactToastify(error?.response?.data?.detail);
        }
        else {
          throw new Error(error);
        }
      }
    }
    else {
      errorReactToastify(
        'The project does not have a client. Please invite the client or wait until he accepts your invitation.'
      );
    }
  }

  // Start paying
  async function openPaymentFormModal(paymentItem) {
    const { client_secret } = await StripePaymentService.createClientSecret(selectedProject.uuid, paymentItem.uuid);

    setClientSecret(client_secret);
    setShowPaymentFormModal(true);
  }

  // Reject payment (By Client)
  async function handlePaymentRejection(paymentItem) {
    try {
      await StripePaymentService.clientRejectsPayment(selectedProject.uuid, paymentItem.uuid);
      await loadPaymentsList(1, true);
    }
    catch (error) {
      if (error?.response?.status == 400) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }

  async function handlePaymentCancelByDesigner(paymentUUID) {
    await StripePaymentService.deletesPendingPayment(selectedProject.uuid, paymentUUID);

    setPaymentListData(currentPaymentListData => ({
      ...currentPaymentListData,

      count: currentPaymentListData.count - 1,
      list: currentPaymentListData.list.filter(({ uuid }) => uuid != paymentUUID),
      totalPage: (currentPaymentListData.count - 1) / currentPaymentListData.pageSize,
    }));

    if (pendingPaymentItem.uuid == paymentUUID) {
      setPendingPaymentItem(null);
    }
  }
}

function getStripeElementOptions(clientSecret) {
  return {
    clientSecret,
    appearance: {
      labels: 'floating',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '3px',
        borderRadius: '4px',
      },
    },
  };
}
