// This is a fork from https://github.com/SmallImprovements/quill-auto-links

import { Quill } from 'react-quill';

import LinkPreviewService from 'services/link_preview';

import { urlRegExp } from './validators';

import styles from 'Components/ReactQuill/styles.module.scss';

const DEFAULT_OPTIONS = {
  paste: true,
  type: true,
};

export const AUTO_LINK_EVENTS_NAMES = {
  autoLinkStart: 'auto-link-start',
  autoLinkFinish: 'auto-link-finish',
};

const sliceFromLastWhitespace = str => {
  const whitespaceI = str.lastIndexOf(' ');
  const sliceI = whitespaceI === -1 ? 0 : whitespaceI + 1;

  return str.slice(sliceI);
};

function registerTypeListener(quill) {
  quill.keyboard.addBinding({
    collapsed: true,
    key: ' ',
    prefix: urlRegExp,
    handler: (range, context) => {
      const url = sliceFromLastWhitespace(context.prefix);
      const link = getCorrectUrl(url);
      const retain = range.index - url.length;
      const ops = retain ? [{ retain }] : [];

      ops.push(
        { delete: url.length },
        {
          insert: url,
          attributes: {
            link: {
              link: link,
              className: styles['auto-link'],
            },
          },
        }
      );

      getLinkPreview(quill, link)
        .then(({ title }) => {
          quill.updateContents({
            ops: [
              quill.getText().indexOf(url) == 0
                ? null
                : {
                  retain: quill.getText().indexOf(url),
                },
              {
                delete: url.length,
              },
              {
                insert: title,
                attributes: {
                  link: {
                    link,
                    className: null,
                  },
                },
              },
            ].filter(Boolean),
          });
        })
        .catch(() => {
          quill.updateContents({
            ops: [
              quill.getText().indexOf(url) == 0
                ? null
                : {
                  retain: quill.getText().indexOf(url),
                },
              {
                delete: url.length,
              },
              {
                insert: link,
                attributes: {
                  link: {
                    link,
                    className: null,
                  },
                },
              },
            ].filter(Boolean),
          });
        });

      quill.updateContents({ ops });

      return true;
    },
  });
}

function registerPasteListener(quill) {
  quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
    if (typeof node.data === 'string') {
      const matches = node.data.match(urlRegExp);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;

        matches.forEach(match => {
          const split = str.split(match);
          const beforeLink = split.shift();
          const link = getCorrectUrl(match);

          ops.push({ insert: beforeLink });
          ops.push({
            insert: match,
            attributes: {
              link: {
                link,
                className: styles['auto-link'],
              },
            },
          });

          getLinkPreview(quill, link)
            .then(({ title }) => {
              quill.updateContents({
                ops: [
                  quill.getText().indexOf(link) == 0
                    ? null
                    : {
                      retain: quill.getText().indexOf(link),
                    },
                  {
                    delete: link.length,
                  },
                  {
                    insert: title,
                    attributes: {
                      link: {
                        link,
                        className: null,
                      },
                    },
                  },
                ].filter(Boolean),
              });
            })
            .catch(() => {
              quill.updateContents({
                ops: [
                  quill.getText().indexOf(link) == 0
                    ? null
                    : {
                      retain: quill.getText().indexOf(link),
                    },
                  {
                    delete: link.length,
                  },
                  {
                    insert: link,
                    attributes: {
                      link: {
                        link,
                        className: null,
                      },
                    },
                  },
                ].filter(Boolean),
              });
            });

          str = split.join(match);
        });

        ops.push({ insert: str });

        delta.ops = ops;
      }
    }
    else {
      return;
    }

    return delta;
  });
}

export default class AutoLinks {
  constructor(quill, options = {}) {
    const opts = { ...DEFAULT_OPTIONS, ...options };

    if (opts.type) {
      registerTypeListener(quill);
    }
    if (opts.paste) {
      registerPasteListener(quill);
    }
  }
}

const Inline = Quill.import('blots/inline');

class LinkBlot extends Inline {
  static create({ link, className }) {
    const node = super.create();

    node.setAttribute('href', link);
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer');
    node.setAttribute('class', className || '');

    return node;
  }

  static formats(node) {
    return {
      link: node.getAttribute('href'),
    };
  }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

Quill.register(LinkBlot);

function getCorrectUrl(url) {
  return url.indexOf('http') == 0
    ? url
    : `http://${url}`;
}

function getLinkPreview(quillEditor, link) {
  quillEditor.emitter.emit(AUTO_LINK_EVENTS_NAMES.autoLinkStart);

  return LinkPreviewService.getLinkPreview(link)
    .finally(() => quillEditor.emitter.emit(AUTO_LINK_EVENTS_NAMES.autoLinkFinish));
}
