import { Form } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';

import Field from '../Field';

import styles from '../styles.module.scss';

export default function FieldsList({ name, placeholder, disabled, onAddItem, onRemoveItem, ...props }) {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        fields.map((field, idx) => (
          <li
            key={field.key}
            className={cn(styles['field-li'], 'mt-sm')}
          >
            <Field
              className="text-weight-500"
              name={field.name}
              placeholder={idx == 0 ? placeholder : 'list another point'}
              disabled={disabled}

              {...props}
            />
            <Button
              isText
              className={cn(
                'ml-md-lg-important text-pacificBlue',
                {
                  hide: disabled,
                }
              )}
              onClick={() => {
                if (idx == 0) {
                  add('');

                  if (onAddItem) {
                    onAddItem();
                  }
                }
                else {
                  remove(field.name);

                  if (onRemoveItem) {
                    onRemoveItem();
                  }
                }
              }
              }
            >
              {
                idx == 0
                  ? '+ ADD MORE'
                  : 'DELETE'
              }
            </Button>
          </li>
        ))
      )}
    </Form.List>
  );
}

FieldsList.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};
