import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import cn from 'classnames';

import { initializeProjects, updateProjects } from 'Redux/Actions/projectActions';

import { PROJECT_STATUS } from 'utlis/constants';

import EmptyDashboard from './components/EmptyDashboard';
import { Loader } from './components/Loader';
import { ProjectCard } from './components/ProjectCard';

import styles from './DashboardProjects.module.scss';

const DashboardProjects = () => {
  const scrollParentRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedProjectOption, setSelectedProjectOption] = useState(null);

  const projects = useSelector(state => state.projectStore);
  const projectStatusCount = useSelector(state => state.projectStatusCount);

  const dispatch = useDispatch();

  const { projectType } = useParams();

  useEffect(
    () => {
      setIsLoading(true);
      dispatch(initializeProjects(PROJECT_STATUS[projectType]))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [projectType]
  );

  if (isLoading) {
    return (
      <div className="mt-xlg width-full flex flex-wrap mx-auto ">
        <Loader statusCount={projectStatusCount[projectType]} />
      </div>
    );
  }

  return (
    <>
      {
        projects?.results?.length > 0
          ? (
            <InfiniteScroll
              loadMore={fetchNextBatchOfProjects}
              hasMore={!!projects.next}
              loader={
                <div key={0} className="mt-16 width-full text-center mx-auto">
                  <Spin size="large" />
                </div>
              }
              getScrollParent={() => scrollParentRef.current}
              useWindow={false}
            >
              <div className={cn('mt-48', styles['project-listing-wrapper'])}>
                {
                  projects.results.map((project, index) => (
                    <ProjectCard
                      key={project.uuid}
                      project={project}
                      index={index}
                      screenStatus={PROJECT_STATUS[projectType]}
                      selectedProjectOption={selectedProjectOption}
                      setSelectedProjectOption={setSelectedProjectOption}
                    />
                  ))
                }
              </div>
            </InfiniteScroll>
          )
          : (
            projectStatusCount[projectType] === 0
            && (
              <EmptyDashboard className="mt-xlg" />
            )
          )
      }
    </>
  );

  function fetchNextBatchOfProjects() {
    dispatch(updateProjects(projects));
  }
};

export default DashboardProjects;
