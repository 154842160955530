import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';

export function DeleteItemModal({
  questionText,
  onDelete,
  onCancel,

  deleteButtonText,
  cancelButtonText,

  ...props
}) {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <Modal
      footer={null}
      closable={false}

      {...props}
    >
      <p className="text-weight-600 text-xl letter-xs text-black-opacity-0-90 text-center mb-zero">
        {questionText}
      </p>
      <Button.RossoCorsa
        className="mt-40"
        onClick={async () => {
          try {
            setIsRemoving(true);

            await onDelete();
          }
          finally {
            setIsRemoving(false);
          }
        }}
        loading={isRemoving}
        disabled={isRemoving}
        block
      >
        {deleteButtonText}
      </Button.RossoCorsa>
      <Button.White
        className="mt-12"
        onClick={onCancel}
        disabled={isRemoving}
        block
      >
        {cancelButtonText}
      </Button.White>
    </Modal>
  );
}

DeleteItemModal.propTypes = {
  questionText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  deleteButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

DeleteItemModal.defaultProps = {
  deleteButtonText: 'DELETE',
  cancelButtonText: 'CANCEL',
};
