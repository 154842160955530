import PropTypes from 'prop-types';
import { Form } from 'antd';
import cn from 'classnames';

import { ReactComponent as LogoInvision1 } from 'assets/logo_invision_1.svg';
import { ReactComponent as LogoInvision2 } from 'assets/logo_invision_2.svg';
import { ReactComponent as LogoInvision3 } from 'assets/logo_invision_3.svg';

import styles from './styles.module.scss';

export default function Field({ className, required, ...props }) {
  return (
    <Form.Item
      className={className}
      {...props}
      rules={[{
        required: required,
        message: 'This field is required',
      }]}
    >
      <LogoInvision
        {...props}
      />
    </Form.Item>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
};

function LogoInvision({ optionClassName, value, onChange, disabled }) {
  const options = [
    {
      value: 'type only',
      text: 'Only type',
      icon: <LogoInvision1 className="my-8" />,
    },
    {
      value: 'a symbol',
      text: 'Only symbol',
      icon: <LogoInvision2 />,
    },
    {
      value: 'symbol and type',
      text: 'Symbol and type',
      icon: <LogoInvision3 />,
    },
  ];

  return (
    <div className={cn(styles['logo-invision-list'], 'flex align-start flex-wrap')}>
      {
        options.map(({ value: optionValue, text, icon }) => (
          <button
            key={optionValue}
            className={cn(
              styles['logo-invision'],
              'flex flex-column align-center space-between',
              'text-md  letter-xxs',
              'py-16',
              {
                [styles['logo-invision-active']]: value == optionValue,
              },
              optionClassName
            )}
            onClick={() => onChange(optionValue)}
            disabled={disabled}
          >
            {text}
            {icon}
          </button>
        ))
      }
    </div>
  );
}

LogoInvision.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  optionClassName: PropTypes.string,
};
