import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Form, Row, Spin } from 'antd';
import moment from 'moment';
import cn from 'classnames';

import ProposalFirstViewIcon from 'assets/ProposalFirstViewIcon.svg';

import AutofillDataFromDesignBrief from 'Components/AutofillDataFromDesignBrief/AutofillDataFromDesignBrief';
import { Button } from 'Components/Button';
import { FirstViewBodyUI } from 'Components/FirstViewBodyUI';
import { Milestone } from 'Components/Milestone';
import MilestoneProgressModal from 'Components/MilestoneCompleteModal';
import { ProposalMilestones } from 'Components/ProposalForm/components/ProposalMilestones';
import Field from 'Components/questionnaireComponents/Field';
import Select from 'Components/questionnaireComponents/Select';
import StepWrapper from 'Components/StepWrapper';

import { showMilestoneProgressModal } from 'Redux/Actions/appActions';
import { submitMilestone } from 'Redux/Actions/FeedActions';
import {
  getProjectProposal,
  submitProjectProposal,
  updateProjectProposal,
} from 'Redux/Actions/projectActions';

import { MILESTONE_STATUSES, NOTIFICATION_TYPES, PROJECT_TYPES } from 'utlis/constants';
import formScrollToField from 'utlis/form_ scroll_to_field';
import { onSocketMessage } from 'utlis/socket';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import DesignerAvatar from './components/DesignerAvatar';

import styles from './styles.module.scss';

const staticMilestoneList = [
  {
    name: 'Moodboard',
    description: 'Gather inspirational imagery, research brands and put together a color palette with fonts.',
  },
  {
    name: 'Design Feedback',
    description: 'Present initial concepts, gather feedback and make revisions.',
  },
  {
    name: 'Presentation',
    description: 'Present edited concepts, discuss final changes.',
  },
  {
    name: 'File Delivery',
    description: 'Present final concepts, hand-off files. Deliverables include a moodboard, design concepts, presentation and delivery of files in the requested file formats.',
  },
];

export default function ProposalForm({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [timerForSaveStart, setTimerForSaveStart] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.userProfile);
  const currentProject = useSelector(state => state.selectedProject);
  const proposalMilestone = useSelector(state => state.milestonesStore?.dictionaryId?.[milestoneId]);

  const [form] = Form.useForm();

  const isDesignerSubmitted = [
    MILESTONE_STATUSES.submitted,
    MILESTONE_STATUSES.completed,
  ].includes(proposalMilestone?.milestone_status);
  const isCompleted = [MILESTONE_STATUSES.completed].includes(proposalMilestone?.milestone_status);

  useEffect(() => {
    loadMilestone();
  }, []);

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          dispatch(getProjectProposal(currentProject.uuid))
            .then(() => form.resetFields());
        }
      },
      [
        NOTIFICATION_TYPES.FORM_SUBMIT,
        NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT,
        NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
        NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
      ]
    ),
    [currentProject.uuid]
  );

  if (!isLoaded) {
    return (
      <div className="flex flex-component-center px-lg py-lg">
        <Spin size="large" />
      </div>
    );
  }
  if (!currentProject.proposal?.uuid) {
    return (
      <Milestone
        title={proposalMilestone?.name}
        selectedMilestone={proposalMilestone}

        withShareButton={!isReadonly}
      >
        <FirstViewBodyUI
          className="mx-xxxs"
          image={ProposalFirstViewIcon}
          text={
            <>
              Your designer is working on your Proposal!
              <br />
              We will update your view as soon as it’s ready!
            </>
          }
        />
      </Milestone>
    );
  }

  return (
    <Milestone
      title={proposalMilestone?.name}
      subTitle="DESIGN PRO"
      selectedMilestone={proposalMilestone}

      withShareButton={!isReadonly}
      additionalRightElement={
        <div className="flex align-center just-end">
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !(currentUser.permissions.isDesigner && isDesignerSubmitted) || isCompleted,
              }
            )}
          >
            SUBMITTED TO CLIENT
          </p>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !isCompleted,
              }
            )}
          >
            APPROVED
          </p>
          <div
            className={cn(
              'flex align-center',
              {
                hide: !currentProject.proposal
                  || (currentUser.permissions.isClient && !isDesignerSubmitted)
                  || isCompleted
                  || isReadonly,
              }
            )}
          >
            {!isDesignerSubmitted && (
              <p className="text-xs text-silver m-zero">
                {
                  timerForSaveStart
                    ? (
                      isSaved
                        ? 'Saved'
                        : 'Saving ...'
                    )
                    : 'All changes are saved automatically'
                }
              </p>
            )}
            <p
              className={cn(
                'text-xs my-zero ml-sm',
                {
                  hide: !hasError,
                  'text-sunsetOrange': hasError,
                }
              )}
            >
              Please fill in all required fields
              <br />
              and correct any mistakes
            </p>
            <Button
              className={cn(
                'ml-sm',
                {
                  hide: currentUser.permissions.isDesigner && isDesignerSubmitted,
                }
              )}
              onClick={e => onSubmit(e)}
              disabled={isSubmiting || hasError}
            >
              {
                isSubmiting
                  ? (
                    currentUser.permissions.isClient
                      ? 'APPROVING ...'
                      : 'SUBMITING ...'
                  )
                  : (
                    currentUser.permissions.isClient
                      ? 'APPROVE'
                      : 'SUBMIT FOR REVIEW'
                  )
              }
            </Button>
          </div>
        </div>
      }
    >
      {
        !isReadonly && (
          <>
            {
              isModalOpen && (
                <MilestoneProgressModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  currentMilestone={proposalMilestone}
                  notificationType={notificationType}
                />
              )
            }
            <AutofillDataFromDesignBrief isProposal reloadCallback={loadMilestone} />
          </>
        )
      }

      <StepWrapper className="pt-lg px-56 pb-44">
        <Form
          className="pt-lg px-56 pb-44"
          initialValues={{
            ...currentProject.proposal.proposal_json,
            milestones: currentProject.type === PROJECT_TYPES.blankProject
              ? currentProject.proposal.proposal_json.milestones
              : staticMilestoneList,
          }}
          form={form}
          preserve
          onFieldsChange={(changedFields, allFields) => {
            if (changedFields.length && !isCompleted) {
              setHasError(!allFields.every(({ errors }) => !errors.length));
            }

            if (
              changedFields.length
                && !isCompleted
                && !isDesignerSubmitted
            ) {
              if (timerForSaveStart) {
                clearTimeout(timerForSaveStart);
              }

              setTimerForSaveStart(setTimeout(
                onSave,
                1000
              ));
            }
          }}
        >
          <div className="flex">
            <DesignerAvatar
              designer={currentProject.proposal.prepared_by}
              className="mr-huge"
            />
            <Row className="letter-xs ml-md-lg flex-grow" gutter={100} wrap={false}>
              <Col flex="50%">
                <p className={cn(styles['prepared-title'], 'text-sm text-weight-300 m-zero')}>
                  Prepared by
                </p>
                <p className="text-lg m-zero">
                  {currentProject.proposal.prepared_by.full_name}
                </p>
                {
                  !(isDesignerSubmitted && !currentProject.proposal.proposal_json.designer_address) && (
                    <div className={cn(styles['prepared-address'], 'text-sm')}>
                      <Field
                        name="designer_address"
                        placeholder="1111 Manhattan Ave. Brooklyn, New York, 11207 United States"
                        disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                      />
                    </div>
                  )
                }
              </Col>
              <Col flex="50%">
                <p className={cn(styles['prepared-title'], 'text-sm text-weight-300 m-zero')}>
                  Prepared for
                </p>
                <p className="text-lg m-zero">
                  {currentProject.proposal.prepared_for.full_name}
                </p>
                {
                  !!currentProject.proposal.prepared_for.address && (
                    <p className={cn(styles['prepared-address'], 'text-sm')}>
                      {currentProject.proposal.prepared_for.address}
                    </p>
                  )
                }
              </Col>
            </Row>
          </div>
          <p className="text-44 letter-xxs mt-56 mb-zero">
            Deliverable-Based Proposal
          </p>
          <p className="text-20 text-weight-500 mt-20 mb-zero letter-xs">
            Issued
            {' '}
            {moment(currentProject.proposal.created_at).format('MMMM Do YYYY')}
          </p>
          <p className="text-20 letter-xs mt-20 mb-zero">
            {
              currentProject.type === PROJECT_TYPES.blankProject
                ? (
                  <Field
                    name="description"
                    className="text-weight-600"
                    placeholder="Add a blurb here that talks about the project and what value will you be adding to the product by working as a designer on it..."
                    required
                    disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                  />
                )
                : (
                  <>
                    <Field
                      name="company_name"
                      className="text-weight-600"
                      placeholder="DesignPro"
                      required
                      disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                    />
                    {' '}
                    is a
                    {' '}
                    <Field
                      name="company_type"
                      className="text-weight-600"
                      placeholder="startup"
                      required
                      disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                    />
                    {' '}
                    looking for a
                    {' '}
                    <span className="text-weight-600">
                      {currentProject.type.toLowerCase()}
                    </span>
                    .
                    <Field
                      name="company_name"
                      className="text-weight-600"
                      placeholder="DesignPro"
                      required
                      disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                    />
                    {' '}
                    aims to be a first class product suite that helps bridge the client / designer
                    relationship removing common pain points in collaborative projects.
                    As the designer I will work with you to build a
                    {' '}
                    <span className="text-weight-600">
                      {currentProject.designName}
                    </span>
                    {' '}
                    that proudly conveys DesignPro’s values and services.
                  </>
                )
            }
          </p>
          <p className="text-20 letter-xs text-weight-300 mt-40 mb-zero">
            To ensure a successful outcome we will refer to the following goals and requirements.
            <ul className="mx-40 mt-xxs mb-zero">
              <li>
                The
                {' '}
                {currentProject.designName}
                {' '}
                will be designed for
                {' '}
                <Select
                  name="product_available_in"
                  className="text-weight-600"
                  placeholder="web, print, and mobile"
                  options={[
                    { value: 'web', label: 'web' },
                    { value: 'print', label: 'print' },
                    { value: 'mobile', label: 'mobile' },
                  ]}
                  mode="multiple"
                  required
                  disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                />
              </li>
              <li>
                The
                {' '}
                {currentProject.designName}
                {' '}
                will be described as
                {' '}
                <Field
                  name="product_describe_as"
                  className="text-weight-600"
                  placeholder="timeless, recognizable, and simple"
                  required
                  disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                />
              </li>
              <li>
                Final design to be delivered in the
                following format
                {currentProject.proposal.proposal_json.product_available_in.length > 1 ? 's' : ''}
                {' '}
                <Select
                  name="deliverables"
                  className="text-weight-600"
                  options={[
                    { value: 'PNG', label: 'PNG' },
                    { value: 'PDF', label: 'PDF' },
                    { value: 'AI', label: 'AI' },
                    { value: 'FIG', label: 'FIG' },
                    { value: 'Other', label: 'Other' },
                  ]}
                  required
                  disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                  mode="multiple"
                  placeholder="PNG, PDF, AI"
                />
              </li>
              <li>
                The
                {' '}
                {currentProject.designName}
                {' '}
                will be displayed as
                {' '}
                <Select
                  name="product_visualize_as"
                  className="text-weight-600"
                  options={[
                    { value: 'type only', label: 'type only' },
                    { value: 'a symbol', label: 'a symbol' },
                    { value: 'symbol and type', label: 'symbol and type' },
                  ]}
                  required
                  disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                  placeholder="an icon and type mark"
                />
              </li>
            </ul>
          </p>
          <ProposalMilestones
            disabled={isDesignerSubmitted || currentProject.type !== PROJECT_TYPES.blankProject}
            form={form}
            onSave={onSave}
          />
          <p className="text-34 letter-xxs mt-60 mb-32">
            Timeline and Budget
          </p>
          {
            [
              {
                title: 'Project Budget',
                field: (
                  <Field
                    name="budget"
                    className="text-weight-600"
                    placeholder="$1000"
                    prefix="$"
                    type="number"
                    disabled={
                      currentUser.permissions.isClient
                        || isDesignerSubmitted
                    }
                  />
                ),
              },
              {
                title: 'Project Timeline',
                field: (
                  <Select
                    name="deliverables_duration"
                    className="text-weight-600"
                    options={[
                      { value: '3 days', label: '3 days' },
                      { value: '1 week', label: '1 week' },
                      { value: '1 month', label: '1 month' },
                      { value: '2 months', label: '2 months' },
                    ]}
                    required
                    disabled={currentUser.permissions.isClient || isDesignerSubmitted || isReadonly}
                    placeholder="1 month"
                  />
                ),
              },
            ].map(({ title, field }) => (
              <Row key={title} className="text-20 letter-xs mt-16">
                <Col span={16} className="text-weight-500">
                  {title}
                </Col>
                <Col
                  span={4}
                  className="text-right"
                >
                  {field}
                </Col>
              </Row>
            ))
          }
        </Form>
      </StepWrapper>
    </Milestone>
  );

  async function onSave() {
    const formData = form.getFieldValue();
    const dataForSaving = Object.keys(formData).reduce(
      (currentObj, key) => currentProject.proposal[key] == formData[key]
        ? currentObj
        : {
          ...currentObj,
          [key]: formData[key],
        },
      {}
    );

    if (dataForSaving.design_image) {
      dataForSaving.design_image = dataForSaving.design_image?.file?.thumbUrl || dataForSaving.design_image;
    }

    if (Object.keys(dataForSaving).length) {
      setIsSaved(false);

      await dispatch(updateProjectProposal(currentProject.uuid, dataForSaving));

      setIsSaved(true);
    }
  }

  async function onSubmit(e) {
    const projectHasClient = currentProject.collaborators.find(
      ({ user: { user_type } }) => user_type == 'Client'
    );

    if (projectHasClient) {
      try {
        await validateFields();
        setNotificationType(e.target.innerText);

        setIsSubmiting(true);

        if (timerForSaveStart) {
          clearTimeout(timerForSaveStart);
        }

        if (currentUser.permissions.isClient) {
          await dispatch(submitMilestone(
            currentUser,
            currentProject,
            proposalMilestone?.id,
            proposalMilestone?.name
          ));

          dispatch(showMilestoneProgressModal({
            milestone: proposalMilestone,
            notificationType: currentUser.permissions?.isDesigner
              ? NOTIFICATION_TYPES.FORM_SUBMIT
              : NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
          }));
        }
        else {
          await onSave();

          await dispatch(submitProjectProposal(currentProject.uuid));

          dispatch(showMilestoneProgressModal({
            milestone: proposalMilestone,
            notificationType: currentUser.permissions?.isDesigner
              ? NOTIFICATION_TYPES.FORM_SUBMIT
              : NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
          }));
        }

        if (currentUser.permissions.isDesigner) {
          successReactToastify('Successfully sent to client!');
        }
      }
      finally {
        setIsSubmiting(false);
      }
    }
    else {
      errorReactToastify(
        'The project does not have a client. Please invite the client or wait until he accepts your invitation.'
      );
    }
  }

  async function validateFields() {
    try {
      const res = await form.validateFields();

      setHasError(false);

      return res;
    }
    catch (error) {
      setHasError(true);

      error.errorFields.some(({ errors, name: [name] }) => {
        if (errors.length) {
          formScrollToField(form, name);
        }

        return !!errors.length;
      });

      return Promise.reject(error);
    }
  }

  function loadMilestone() {
    dispatch(getProjectProposal(currentProject.uuid))
      .then(() => {
        setIsLoaded(true);
        form.resetFields();
      });
  }
}

ProposalForm.propTypes = {
  isReadonly: PropTypes.bool,
};
