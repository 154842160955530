import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class BrandPersonalityService {
  static async getBrandPersonalityList(projectUUID, milestoneId) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/brand_personality`).get({ limit: 1000 }))
      ?.data;
  }

  static async addBrandPersonalityItem(projectUUID, milestoneId, word) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/brand_personality`).post({
      word,
    }))?.data;
  }

  static updateBrandPersonalityItem(projectUUID, milestoneId, brandPersonalityUUID, newData) {
    return api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/brand_personality`)
      .patch(brandPersonalityUUID, {
        is_select_by_client: newData.is_select_by_client,
      });
  }

  static deleteBrandPersonalityItem(projectUUID, milestoneId, brandPersonalityUUID) {
    return api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/brand_personality`)
      .delete(brandPersonalityUUID);
  }
}
