import { animateScroll } from 'react-scroll';

export default (formInstance, fieldName) => {
  formInstance.scrollToField(
    fieldName,
    {
      behavior: actions => {
        actions.forEach(({ top: elTop }) => {
          animateScroll.scrollTo(
            elTop > document.documentElement.scrollTop
              ? elTop + 25 /* padding */
              : elTop - 124 /* navBar's height */ - 25 /* padding */,
            { containerId: '.app' },
          );
        });
      },
    }
  );
};
