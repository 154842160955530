import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import cn from 'classnames';

import styles from './styles.module.scss';

import { setIsPinCommentMode, setHidePinCommentMode } from 'Redux/Actions/appActions';

function FunctionNotAvailable({
  className,
  cursorClassName,
  message,
  children,
  isNotAvailable,
  isNotAvailableCursor,
  isDisabledClick,
  isDisabledPinComment,

  ...props
}) {
  const [isHover, setIsHover] = useState(false);

  const { isPinCommentMode } = useSelector(state => state.app);

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (isNotAvailable && isDisabledPinComment) {
        dispatch(setIsPinCommentMode(false));
        dispatch(setHidePinCommentMode(true));
      }

      return () => {
        dispatch(setIsPinCommentMode(isPinCommentMode));
        dispatch(setHidePinCommentMode(false));
      };
    },
    [isNotAvailable, isDisabledPinComment]
  );

  return (
    <div
      className={cn(
        styles['function-not-available'],
        {
          [cursorClassName || styles['function-not-available-cursor']]: isNotAvailable && isNotAvailableCursor,
          [styles['disabled-click']]: isNotAvailable && isDisabledClick,
        },
        className
      )}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      {...props}
    >
      {
        message && (
          <MouseTooltip
            visible={isNotAvailable && isHover}
            offsetX={15}
            offsetY={10}
            style={{ zIndex: 1000 }}
          >
            <div className={styles.tooltip}>
              {message}
            </div>
          </MouseTooltip>
        )
      }
      {children}
    </div>
  );
}

FunctionNotAvailable.propTypes = {
  className: PropTypes.string,
  cursorClassName: PropTypes.string,
  message: PropTypes.node,
  isNotAvailable: PropTypes.bool,
  isNotAvailableCursor: PropTypes.bool,
  children: PropTypes.node,
  isDisabledClick: PropTypes.bool,
  isDisabledPinComment: PropTypes.bool,
};

export default FunctionNotAvailable;
