import { stripIndent } from 'common-tags';

export default function copyTaskDetails(taskDetails, allFilesComments) {
  const labels = Array.isArray(taskDetails.label) ? taskDetails.label.join(', ') : taskDetails.label;
  const assignees = taskDetails?.assignees?.map(assignee => assignee.full_name || assignee.email).join(', ') || '';

  const copiedTask = stripIndent`
    Title: ${taskDetails.title}
    Label: ${labels}
    Priority: ${taskDetails.priority}
    ${assignees ? `Assignee: ${assignees}` : ''}
    ${taskDetails?.comment_id ? `Figma frame: https://www.figma.com/file/${taskDetails?.figma_file_key}?${allFilesComments[taskDetails?.comment_id]?.uri}` : ''}
  `.replace(/(\n){2,}/gm, '\n');

  return copiedTask;
}
