import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faExternalLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Switch } from 'antd';
import cn from 'classnames';

import customTaskIcon from 'assets/custom_task_icon.svg';
import figmaIcon from 'assets/figma_logo.svg';
import googleDocsIcon from 'assets/GoogleDocsIcon.svg';
import notesIcon from 'assets/notes_icon.svg';
import otherAppsIcon from 'assets/other_apps_icon.svg';
import slackIconUrl from 'assets/slack_logo.svg';
import transcriptionIcon from 'assets/transcription_icon.svg';
import trashLightIcon from 'assets/trash_light_icon.svg';

import { Button } from 'Components/Button';

import { setUserProfile } from 'Redux/Actions/userActions';

import { KANBAN_BOARD_FILE_TYPE_NAMES, KANBAN_BOARD_FILE_TYPE_VALUES } from 'utlis/constants';

import styles from './styles.module.scss';

export function ImportFileContent({
  showTasksOfFiles,
  switchOnChange,
  showRemoveFileModal,
  handleImportOption,
  showReadOnlyTranscriptModal,
}) {
  const dispatch = useDispatch();

  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);

  const [showNotesOptions, setShowNotesOptions] = useState(false);

  useEffect(() => {
    if (authUser && authUser.uuid && !userProfile?.uuid) {
      dispatch(setUserProfile(authUser));
    }
  }, [authUser]);

  const popoverContent = useMemo(
    () => {
      const fileOptions = [
        {
          title: 'Figma',
          icon: figmaIcon,
          onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.FIGMA),
        },
        {
          title: 'Video call transcript',
          icon: transcriptionIcon,
          onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION),
        },
        {
          title: 'Meeting Notes',
          icon: notesIcon,
          onClick: () => setShowNotesOptions(!showNotesOptions),
          showOptions: showNotesOptions,
          options: [
            {
              title: 'Google Docs',
              icon: googleDocsIcon,
              onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS),
            },
            {
              title: 'Other Apps',
              icon: otherAppsIcon,
              onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.OTHER_APPS),
            },
          ],
        },
        {
          title: 'Slack',
          icon: slackIconUrl,
          onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.SLACK),
        },
      ];

      return (
        <div className={styles['select-file-container']}>
          {
            fileOptions.map(
              fileOption => (
                <div key={fileOption.title} className={styles['file-option-container']}>
                  <div
                    className={styles['file-option']}
                    onClick={fileOption.onClick}
                  >
                    <img src={fileOption.icon} width={16} height={16} alt={fileOption.title} />
                    <span className={styles['file-option-title']}>
                      {fileOption.title}
                    </span>
                  </div>
                  {
                    fileOption?.showOptions && (
                      <div className={styles['sub-options-container']}>
                        {
                          fileOption.options.map(
                            option => (
                              <div
                                key={option.title}
                                className={styles['file-option']}
                                onClick={option.onClick}
                              >
                                <img src={option.icon} width={16} height={16} alt={option.title} />
                                <span className={styles['file-option-title']}>
                                  {option.title}
                                </span>
                              </div>
                            )
                          )
                        }
                      </div>
                    )
                  }
                </div>
              )
            )
          }
        </div>
      );
    },
    [handleImportOption]
  );

  return (
    <>
      <div className="flex flex-column">
        <span className={styles['add-file-title']}>
          Add new file
        </span>
        <span className={styles['add-file-description']}>
          To convert your design feedback into actionable tasks, please select the file you want to import.
        </span>
      </div>
      <Popover
        content={popoverContent}
        placement="bottom"
        trigger="click"
        showArrow={false}
        overlayStyle={{ 'paddingTop': 0 }}
        overlayClassName={styles['select-file-popover']}
      >
        <Button
          className={styles['select-file-button']}
        >
          <FontAwesomeIcon className={styles['button-icon']} icon={faPlus} />
          <span className={styles['button-text']}>
            Select File
          </span>
        </Button>
      </Popover>
      {
        savedFigmaFiles.length > 0 && (
          <>
            <p className={cn('mt-20', styles['imported-files-title'])}>
              Imported files
            </p>
            <p className={cn(styles['imported-files-description'])}>
              Choose which file’s tasks you want to display on the board.
            </p>
            <div className={cn(styles['all-imported-files-container'])}>
              {
                savedFigmaFiles.map(
                  figmaFile => (
                    <div key={figmaFile.figma_file_key} className={styles['added-figma-file-wrapper']}>
                      {
                        figmaFile.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA
                          ? (
                            <Link
                              className={styles['figma-link']}
                              to={`https://www.figma.com/file/${figmaFile.figma_file_key}`}
                              target="_blank"
                            >
                              <img
                                className="mr-8"
                                src={fileTypeIcon(figmaFile.file_type)}
                                width={16}
                                height={16}
                                alt="figma icon"
                              />
                              <span className={styles['link-text']}>
                                {figmaFile.figma_file_name}
                              </span>
                              <FontAwesomeIcon className={styles['external-link-icon']} icon={faExternalLink} />
                            </Link>
                          )
                          : (
                            <Button
                              isText
                              onClick={() => handleReadOnlyTranscriptFilePopup(figmaFile.transcript)}
                              className="flex align-center"
                            >
                              <img
                                className="mr-8"
                                src={fileTypeIcon(figmaFile.file_type)}
                                width={16}
                                height={16}
                                alt="figma icon"
                              />
                              <span className={styles['link-text']}>
                                {figmaFile.figma_file_name}
                              </span>
                            </Button>
                          )
                      }
                      <div>
                        <Switch
                          className={styles['switch']}
                          defaultChecked
                          checked={isChecked(figmaFile)}
                          onChange={checked => switchOnChange(checked, figmaFile)}
                          size="medium"
                        />
                        <img
                          src={trashLightIcon}
                          alt="Trash Light Icon"
                          className={styles['trash-icon']}
                          onClick={() => showRemoveFileModal(figmaFile.figma_file_key)}
                        />
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </>
        )
      }
    </>
  );

  function fileTypeIcon(fileType) {
    switch (fileType) {
      case KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA:
        return figmaIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.TRANSCRIPTION:
        return transcriptionIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.NOTES:
        return notesIcon;
      case KANBAN_BOARD_FILE_TYPE_VALUES.SLACK:
        return slackIconUrl;
      default:
        return customTaskIcon;
    }
  }

  function isChecked(figmaFile) {
    return showTasksOfFiles?.find(file => file.figma_file_key === figmaFile.figma_file_key)?.is_active;
  }

  function handleReadOnlyTranscriptFilePopup(transcriptDetails) {
    showReadOnlyTranscriptModal(transcriptDetails);
  }
}

ImportFileContent.propTypes = {
  showTasksOfFiles: PropTypes.array.isRequired,
  switchOnChange: PropTypes.func.isRequired,
  showRemoveFileModal: PropTypes.func.isRequired,
  handleImportOption: PropTypes.func.isRequired,
  showReadOnlyTranscriptModal: PropTypes.func.isRequired,
};
