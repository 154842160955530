import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  sendEmailVerification,
  signInWithEmailAndPassword } from 'firebase/auth';
import cn from 'classnames';

import designProLogo from 'assets/designProLogo.svg';
import designProTextIcon from 'assets/designProTextIcon.svg';

import { ROUTES } from 'routes/route.constants';

import { extensionSignUpUser, loginUser, signUpUser, verifyUserEmail } from 'services/customLogin';
import { connectGoogleDoc } from 'services/projectServices';

import { PLUGIN_SOURCE_IDENTIFIERS } from 'utlis/constants';
import { isEmail, isPasswordValid } from 'utlis/validators';

import styles from './styles.module.scss';

export const PluginSignin = ({ loginButton, errorMessage, setErrorMessage }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const userID = searchParams.get('userID');
  const writeKey = searchParams.get('writeKey');
  const returnURL = searchParams.get('returnURL');
  const pluginSource = searchParams.get('source');

  const USER_TYPES = {
    FIRST_TIME: 'firstTime',
    CUSTOM_AUTH: 'customAuth',
  };

  const [signInStart, setSignInStart] = useState(false);
  const [userType, setUserType] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [signingIn, setSigningIn] = useState(false);

  const buttonText = useMemo(() => {
    if (!signInStart) {
      return verifyingEmail ? 'VERIFYING...' : 'CONTINUE WITH EMAIL';
    }

    if (userType === USER_TYPES.CUSTOM_AUTH) {
      return signingIn ? 'SIGNING IN...' : 'SIGN IN';
    }

    return signingIn ? 'SIGNING UP...' : 'SIGN UP';
  }, [signInStart, verifyingEmail, userType, signingIn]);

  return (
    <div className={styles['signin-page']}>
      <div className={styles['auth-modal']}>
        <div className={styles['title-container']}>
          <img width={26} height={26} src={designProLogo} alt="design pro logo" />
          <img className={styles['text-icon']} src={designProTextIcon} alt="design pro text icon" />
        </div>
        {signInStart
          ? (
            <>
              <button className={styles['back-button']} onClick={handleGoBack}>
                Go back
              </button>
              <div className={styles['line']} />
            </>
          )
          : (
            <span className={styles['description']}>
              Get started in minutes with a free account
            </span>
          )}

        {!signInStart && (
          <>
            {loginButton}
            <div className={styles['partition']}>
              <div className={cn(styles['line'], styles['half'])} />
              {' '}
              <p className={styles['or']}>
                OR
              </p>
              {' '}
              <div className={cn(styles['line'], styles['half'])} />
            </div>
          </>
        )}
        <div>
          {signInStart && (
            <h2 className={styles['welcome-text']}>
              {userType === USER_TYPES.FIRST_TIME ? 'Create a new account' : 'Welcome back'}
            </h2>
          )}
          <form onSubmit={handleSubmit} className={styles['email-input-container']}>
            <p className={styles['label']}>
              Email
            </p>
            <input
              type="email"
              value={email}
              className={styles['input']}
              placeholder="Enter your email"
              onChange={onEmailChange}
              onKeyPress={handleKeyPress}
            />
            {signInStart && (
              <>
                <p className={styles['label']}>
                  Password
                </p>
                <input
                  type="password"
                  value={password}
                  className={styles['input']}
                  placeholder="Enter your password"
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </>
            )}
            {userType === USER_TYPES.FIRST_TIME && (
              <ul className={styles['list']}>
                <li>
                  8 characters
                </li>
                <li>
                  Include lowercase and uppercase
                </li>
                <li>
                  Include a number and a special character
                </li>
              </ul>
            )}
            <button
              className={cn(styles['action-button'], {
                [styles['disabled']]: verifyingEmail || signingIn,
              })}
              type="submit"
              disabled={verifyingEmail || signingIn}
            >
              {buttonText}
            </button>

            {userType === USER_TYPES.CUSTOM_AUTH && (
              <button type="button" className={styles['forgot-password']} onClick={handleForgetPassword}>
                {' '}
                Forgot password?
              </button>
            )}
          </form>

          {errorMessage && (
            <p className={styles['error-message']}>
              {errorMessage}
            </p>
          )}

          {!userType && (
            <div className={styles['terms']}>
              <a href="/terms-and-conditions" target="_blank">
                Terms and conditions
              </a>
              {' '}
              and
              {' '}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function isValid() {
    if (!email) {
      setErrorMessage('Email is required.');

      return false;
    }
    if (email && !isEmail(email)) {
      setErrorMessage('Please enter a valid Email.');

      return false;
    }

    if (signInStart && !password) {
      setErrorMessage('Password is required.');

      return false ;
    }

    if (signInStart && userType === USER_TYPES.FIRST_TIME && password && !isPasswordValid(password)) {
      setErrorMessage('Password must be 8 characters and include lowercase, uppercase, number and special character');

      return false ;
    }

    setErrorMessage('');

    return true;
  }

  function handleForgetPassword() {
    navigate(ROUTES.FORGOT_PASSWORD);
  }

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  async function checkUserEmail() {
    setVerifyingEmail(true);
    const res = await verifyUserEmail(email);

    if (!res) {
      setErrorMessage('Ops, something went wrong, please try again later');

      return;
    }

    const data = res.data;

    if (data.is_user_exists && data.provider === 'Google') {
      setErrorMessage('Oh! Looks like you created Google account with this email already. Try signing in with that!');
    }
    if (!data.is_user_exists) {
      setSignInStart(true);
      setUserType(USER_TYPES.FIRST_TIME);
    }
    else if (data.is_user_exists && data.provider === 'Firebase') {
      setSignInStart(true);
      setUserType(USER_TYPES.CUSTOM_AUTH);
    }
    setVerifyingEmail(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    if (!signInStart) {
      checkUserEmail();
    }
    else if (userType === USER_TYPES.FIRST_TIME) {
      signUp();
    }
    else if (userType === USER_TYPES.CUSTOM_AUTH) {
      signIn();
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && isValid()) {
      handleSubmit(e);
    }
  }

  function handleGoBack() {
    setSignInStart(false);
    setErrorMessage('');
    setUserType('');
  }

  async function signUp() {
    setSigningIn(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      const user = userCredential.user;

      window.localStorage.setItem('user', JSON.stringify(user));
      window.localStorage.setItem('url', window.location.href);

      await sendEmailVerification(user, {
        url: window.location.href,
        handleCodeInApp: true,
      });

      const idToken = await getIdToken(user);

      if (pluginSource === PLUGIN_SOURCE_IDENTIFIERS.CHROME_EXTENSION) {
        await extensionSignUpUser(pluginSource, writeKey, idToken);
      }
      else {
        await signUpUser(userID, pluginSource, writeKey, idToken);
      }

      navigate(ROUTES.VERIFY_EMAIL);
    }
    catch (error) {
      if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
        setErrorMessage('This email is already in use');
      }
      else if (error.message && error.message !== 'Request failed with status code 400') {
        setErrorMessage(error.message);
      }
      else {
        setErrorMessage('Something went wrong, please try again later');
      }
    }
    setSigningIn(false);
  }

  async function signIn() {
    setSigningIn(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const user = userCredential.user;

      const idToken = await getIdToken(user);

      if (!user.emailVerified) {
        setErrorMessage('Please verify your account by following instructions in the email you received');
        setSigningIn(false);

        return;
      }

      const userData = await loginUser(pluginSource, writeKey, idToken, dispatch);

      const docsObject = userData.user_tokens.find(
        object => object.provider === 'Google docs'
      );

      if (returnURL === ROUTES.GDOC_AUTH_COMPLETE && !docsObject) {
        const authCompleteURL = `${window.location.origin}${ROUTES.GDOC_AUTH_COMPLETE}`;

        connectGoogleDoc(userData.access, authCompleteURL);
      }
      else if (returnURL) {
        navigate(returnURL);
      }
      else if (pluginSource === PLUGIN_SOURCE_IDENTIFIERS.DESIGN_REVIEWER) {
        navigate(ROUTES.REVIEWER_AUTH_COMPLETE);
      }
      else {
        navigate(ROUTES.AUTH_COMPLETE);
      }
    }

    catch (error) {
      if (error.message === 'Firebase: Error (auth/invalid-login-credentials).') {
        setErrorMessage('Either the email or password don\'t match. Make sure they\'re correct and try again.');
      }
      else {
        setErrorMessage(error.message);
      }
    }
    setSigningIn(false);
  }
}
;

PluginSignin.propTypes = {
  loginButton: PropTypes.node,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
};
