import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export default function DesignerAvatar({ designer, className }) {
  const [firstName, lastName] = designer.firstName
    ? [designer.firstName, designer.lastName]
    : designer.full_name.split(' ');

  return (
    designer.profile_photo
      ? (
        <img
          className={cn(styles['profile-photo'], className)}
          src={designer.profile_photo}
        />
      )
      : (
        <div className={cn(styles['auto-profile-photo'], className)}>
          {firstName[0]}
          <div className={styles['separator']} />
          {lastName[0]}
        </div>
      )
  );
}

DesignerAvatar.propTypes = {
  designer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    full_name: PropTypes.string,
    profile_photo: PropTypes.string,
  }).isRequired,

  className: PropTypes.string,
};
