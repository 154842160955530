import designConceptsFirstViewIconUrl from 'assets/first_view_icons/design_concepts.svg';
import designReviewFirstViewIconUrl from 'assets/first_view_icons/design_review.svg';

import { MILESTONES_TYPES } from 'utlis/constants';

export const firstViewBodyTexts = {
  [MILESTONES_TYPES.designConcepts]: {
    image: designConceptsFirstViewIconUrl,
    text: (
      <>
        No files uploaded yet.
        <br />
        We will update your view as soon as they are available!
      </>
    ),
  },
  [MILESTONES_TYPES.designReview]: {
    image: designReviewFirstViewIconUrl,
    text: (
      <>
        No files uploaded yet.
        <br />
        We will update your view as soon as they are available!
      </>
    ),
  },
};
