import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function ReadMoreReadLess({
  lineLimit,
  className,
  readMoreText,
  readLessText,
  readMoreLessClassName,
  readMoreClassName,
  readLessClassName,

  ...props
}) {
  const [showAllData, setShowAllData] = useState(false);
  const [showReadMoreLessButton, setShowReadMoreLessButton] = useState(false);

  const paragraphRef = useRef(null);

  useEffect(
    () => {
      const paragraphEl = paragraphRef?.current;

      setShowReadMoreLessButton(
        paragraphEl
          && paragraphEl?.clientHeight != paragraphEl?.scrollHeight
      );
    },
    [paragraphRef?.current]
  );

  return (
    <>
      <p
        ref={paragraphRef}
        className={cn(styles['read-more-read-less-wrapper'], className)}
        style={{
          WebkitLineClamp: showAllData ? undefined : lineLimit,
        }}

        {...props}
      />
      <Button
        className={cn(
          readMoreLessClassName,
          {
            [readLessClassName]: showAllData,
            [readMoreClassName]: !showAllData,
            hide: !showReadMoreLessButton,
          }
        )}
        isText
        onClick={toggleShowAllData}
      >
        {
          showAllData
            ? readLessText
            : readMoreText
        }
      </Button>
    </>
  );

  function toggleShowAllData() {
    setShowAllData(currentValue => !currentValue);
  }
}

ReadMoreReadLess.propTypes = {
  lineLimit: PropTypes.number,
  className: PropTypes.string,

  readMoreText: PropTypes.string,
  readLessText: PropTypes.string,
  readMoreLessClassName: PropTypes.string,
  readMoreClassName: PropTypes.string,
  readLessClassName: PropTypes.string,
};

ReadMoreReadLess.defaultProps = {
  lineLimit: 15,
  readMoreText: 'read more',
  readLessText: 'read less',
};
