import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import cn from 'classnames';

import { infoReactToastify } from 'utlis/toasts';
import copyValue from 'utlis/copy_value';

import { ReactComponent as ShareIcon } from 'assets/ShareIcon.svg';

import styles from './styles.module.scss';

const StepWrapper = (
  {
    title,
    subTitle,
    icon,
    children,
    className,
    additionalRightElement,
    withShareButton,
  },
  ref
) => (
  <div ref={ref} className={cn(styles['step-wrapper'], className)}>
    {
      !!title && (
        <div className={cn(styles['header'], 'flex align-center space-between pl-28 pr-24 pt-24 pb-16')}>
          <div className="flex align-center">
            <img
              src={icon}
              width={29}
            />
            <div className="text-black text-weight-600 ml-sm my-zero">
              <p className="text-10 letter-xxl my-zero">
                {subTitle}
              </p>
              <p className="text-md letter-xxs invert-mt-xs mb-zero">
                {title}
              </p>
            </div>
          </div>
          <div className="flex align-center space-between">
            {additionalRightElement}
            {
              withShareButton && (
                <Button
                  shape="circle"
                  className={cn(styles['share-button'], 'ml-sm-md flex flex-component-center')}
                  icon={<ShareIcon width={12} height={13} />}
                  onClick={() => {
                    copyValue(location.href)
                      .then(() => {
                        infoReactToastify('Link to this page has been copied');
                      });
                  }}
                />
              )
            }
          </div>
        </div>
      )
    }
    {children}
  </div>
);

StepWrapper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  additionalRightElement: PropTypes.node,
  withShareButton: PropTypes.bool,
};

export default forwardRef(StepWrapper);
