import cn from 'classnames';

import { Button } from 'Components/Button';

import { LanguageAndTimezoneForm } from './components/LanguageAndTimezoneForm';

import styles from './styles.module.scss';

export const LanguageAndTimezone = () => (
  <div className={cn(styles.container)}>
    <div className={cn('mt-48', styles['input__container'])}>
      <div className="mb-20 flex space-between">
        <div className="flex flex-column">
          <span className={styles['section__heading']}>
            Language and timezone
          </span>
          <span className={styles['section__description']}>
            Add your preferred language and timezone
          </span>
        </div>
        <div className="flex align-center">
          <span className={styles['progress']}>
            Changes saved
          </span>
        </div>
      </div>
      <LanguageAndTimezoneForm />
      <div className={styles['buttons__section']}>
        <div className="mt-24 flex just-end buttons">
          <Button.White
            className={cn('mr-16', styles['cancel__button'])}
          >
            Cancel
          </Button.White>
          <Button
            className={cn(styles['save__button'])}
            disabled
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  </div>
);
