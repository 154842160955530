import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faEllipsis, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image as ImageAntd, Menu } from 'antd';
import cn from 'classnames';

import { ReactComponent as BinIcon } from 'assets/bin_icon.svg';
import { ReactComponent as CanvasPencilIcon } from 'assets/canvas_pencil.svg';
import { ReactComponent as DownloadIcon } from 'assets/download_icon.svg';
import threeDots from 'assets/three_dots.svg';
import { ReactComponent as ThumbtackIcon } from 'assets/thumbtack.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { Button } from 'Components/Button';
import DateFromNow from 'Components/DateFromNow';
import { DeleteItemModal } from 'Components/DeleteItemModal';
import { DropdownButton, DropdownMenu } from 'Components/DropdownMenu';
import { CommentForm } from 'Components/ImageWithComments/components/CommentForm';
import { ReadMoreReadLess } from 'Components/ReadMoreReadLess';

import downloadFiles from 'utlis/download_files';
import { onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export default function CommentComponent({
  comment: {
    uuid,
    text,
    created_at,
    commenter,
    figma_comment_detail,
    replies = [],
    coordinates,
    content,
    file_links,
  },

  className,
  hideRepliesCount,
  onClick,
  onRemoveComment,
  isScrollToComment,
  onEditComment,
  showPinIfHasCoordinates,
  showPenIfHasAnnotation,
  showActiveButtonInRow,
  onReply,
  editorProps,
  isReadOnly,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showRemoveCommentConfirm, setShowRemoveCommentConfirm] = useState(false);
  const [commentImages, setCommentImages] = useState(file_links);

  const { showPinIcon, showPenIcon } = useMemo(
    () => ({
      showPinIcon: (
        showPinIfHasCoordinates
          && (coordinates || figma_comment_detail?.node_offset)
          && !content?.annotationData
      ),
      showPenIcon: showPenIfHasAnnotation && content?.annotationData,
    }),
    [showPinIfHasCoordinates, showPenIfHasAnnotation, coordinates, figma_comment_detail, content]
  );

  const commentEl = useRef();

  useEffect(() => {
    if (isScrollToComment) {
      commentEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  return (
    <div
      ref={commentEl}
      data-comment-uuid={uuid}
      className={cn(
        styles['comment'],
        {
          pointer: onClick,
          [styles['is-removing']]: isRemoving,
          [styles['is-saving']]: isSaving,
        },
        'flex space-between',
        className
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'flex',
          {
            [styles['comment-content-block']]: isEditing,
          }
        )}
      >
        {
          (commenter?.profile_photo || figma_comment_detail?.figma_commenter_avatar || defaultUserAvatar) && (
            <img
              className={cn(styles['avatar'], 'mr-20')}
              src={commenter?.profile_photo || figma_comment_detail?.figma_commenter_avatar || defaultUserAvatar}
              onError={e => onLoadImageError(e, defaultUserAvatar)}
              alt="user avatar"
            />
          )
        }
        <div
          className={cn(
            {
              [styles['comment-content-block']]: isEditing,
            }
          )}
        >
          <p className="m-zero">
            <span className={cn(styles['comment-user-name'], 'text-Outfit')}>
              {commenter?.full_name || figma_comment_detail?.figma_commenter_name}
            </span>
            <span className={cn(styles['comment-time'], 'text-Outfit')}>
              <DateFromNow
                date={created_at}
              />
            </span>
          </p>
          {
            isEditing
              && !isReadOnly
              ? (
                <CommentForm
                  className="flex-grow mt-12"
                  commentSuggestion={text}
                  onSubmit={async ({ message, file_links: newImages }) => {
                    const finalImagesList = commentImages ? commentImages.concat(newImages) : newImages;

                    setCommentImages(finalImagesList);

                    try {
                      setIsSaving(true);

                      await onEditComment(uuid, message, { file_links: finalImagesList });

                      setIsEditing(false);
                    }
                    finally {
                      setIsSaving(false);
                    }
                  }}
                  messagePlaceholder="Edit comment"
                  withTools={false}
                  onCancel={() => {
                    setCommentImages(file_links);
                    setIsEditing(false);
                  }}
                  submitButtonText="Save"
                  {...editorProps}
                />
              )
              : (
                <div className={cn(styles['comment-text-wrapper'], 'mt-12')}>
                  {
                    showPinIcon
                      && (
                        <ThumbtackIcon className={styles['pin-marker']} />
                      )
                  }
                  {
                    showPenIcon
                      && (
                        <CanvasPencilIcon className={styles['pin-marker']} />
                      )
                  }
                  <ReadMoreReadLess
                    lineLimit={5}
                    className={cn(
                      styles['comment-text'],
                      'text-Outfit text-sm text-carbonGrey letter-sm m-zero pre-wrap',
                      'break-word m-zero text-weight-normal',
                      {
                        [styles['with-icon']]: showPinIcon || showPenIcon,
                      }
                    )}
                    readMoreLessClassName="text-md letter-sm m-zero text-blueIvy"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              )
          }
          {
            commentImages
              && (
                <div className="flex flex-wrap">
                  {
                    commentImages.map(imageUrl => (
                      <div key={imageUrl} className={cn('mt-8 mb-8', styles['image-container'])}>
                        <ImageAntd className={styles['image']} src={imageUrl} alt="comment image" />
                        <DropdownMenu
                          overlayClassName={styles['image-menu']}
                          overlay={
                            <Menu
                              items={[
                                {
                                  key: 1,
                                  label: (
                                    <button
                                      onClick={() => downloadImage(imageUrl)}
                                      className={styles['menu-button']}
                                      disabled={isDownloading}
                                    >
                                      <DownloadIcon className={styles['menu-icon']} />
                                      <span className={styles['menu-text']}>
                                        Export image
                                      </span>
                                    </button>
                                  ),
                                },
                                {
                                  key: 2,
                                  label: (
                                    <button
                                      onClick={() => deleteCommentImage(imageUrl)}
                                      className={cn(styles['menu-button'], styles['danger-color'])}
                                    >
                                      <BinIcon className={styles['menu-icon']} />
                                      <span className={styles['menu-text']}>
                                        Delete
                                      </span>
                                    </button>
                                  ),
                                  className: cn({ hide: !isEditing }),
                                },
                              ]}
                            />
                          }
                          placement="bottomLeft"
                        >
                          <button
                            className={styles['dots-icon']}
                          >
                            <img src={threeDots} alt="ThreeDots" />
                          </button>
                        </DropdownMenu>
                      </div>
                    ))
                  }
                </div>
              )
          }
          <div className={cn('flex mt-12', { hide: !showActiveButtonInRow || isEditing })}>
            {
              onReply && !hideRepliesCount && (
                <Button
                  className={cn('text-sm text-carbonGrey mr-12-important', styles['action-button'])}
                  isText
                  onClick={onReply}
                >
                  Reply
                  {replies.length ? ` (${replies.length})` : ''}
                </Button>
              )
            }
            {
              onEditComment && (
                <Button
                  className={cn('text-sm text-carbonGrey mr-12-important', styles['action-button'])}
                  isText
                  onClick={(() => setIsEditing(true))}
                >
                  Edit
                </Button>
              )
            }
            {
              onRemoveComment && (
                <Button
                  className={cn('text-sm text-carbonGrey mr-12-important', styles['action-button'])}
                  isText
                  onClick={() => setShowRemoveCommentConfirm(true)}
                >
                  Delete
                </Button>
              )
            }
          </div>
        </div>
      </div>
      <div
        onClick={preventDefault}
        style={{ position: 'relative' }}
        className={cn({ hide: !(onRemoveComment || onEditComment) || showActiveButtonInRow || isEditing })}
      >
        <DropdownMenu
          destroyPopupOnHide
          getPopupContainer={() => commentEl.current}
          overlay={
            <Menu
              className="p-zero"
              items={
                [
                  {
                    className: 'p-zero',
                    label: (
                      <DropdownButton
                        icon={<FontAwesomeIcon icon={faPen} />}
                        text="Edit"
                      />
                    ),
                    key: 'Edit',
                    onClick: !!onEditComment && (() => setIsEditing(true)),
                  },
                  {
                    className: 'p-zero',
                    label: (
                      <DropdownButton
                        icon={<FontAwesomeIcon icon={faTrashCan} />}
                        text={replies.length ? 'Delete thread' : 'Delete comment'}
                      />
                    ),
                    key: 'Delete',
                    onClick: onRemoveComment && (() => setShowRemoveCommentConfirm(true)),
                  },
                ]
                  .filter(({ onClick: clickFunc }) => !!clickFunc)
              }
            />
          }
          className="mt-xs"
          placement="bottomRight"
        >
          <FontAwesomeIcon icon={faEllipsis} className="text-md pointer" />
        </DropdownMenu>
      </div>
      <DeleteItemModal
        questionText="Would you like to delete this comment?"
        visible={showRemoveCommentConfirm}
        onDelete={deleteComment}
        onCancel={() => setShowRemoveCommentConfirm(false)}
      />
    </div>
  );

  function preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async function deleteComment() {
    try {
      setIsRemoving(true);

      return await onRemoveComment({ uuid });
    }
    finally {
      setIsRemoving(false);
    }
  }

  async function downloadImage(imageUrl) {
    try {
      setIsDownloading(true);

      await downloadFiles(imageUrl);
    }
    finally {
      setIsDownloading(false);
    }
  }

  async function deleteCommentImage(imageUrl) {
    const filteredFileLinks = commentImages.filter(currentImageUrl => currentImageUrl !== imageUrl);

    setCommentImages(filteredFileLinks);
  }
}

CommentComponent.propTypes = {
  comment: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    commenter: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      profile_photo: PropTypes.string.isRequired,
    }),
    figma_comment_detail: PropTypes.shape({
      figma_commenter_name: PropTypes.string.isRequired,
      figma_commenter_avatar: PropTypes.string.isRequired,
      node_offset: PropTypes.object.isRequired,
    }),

    replies: PropTypes.array,
    coordinates: PropTypes.object,
    content: PropTypes.object,
    file_links: PropTypes.array,
  }).isRequired,

  className: PropTypes.string,
  hideRepliesCount: PropTypes.bool,
  onClick: PropTypes.func,
  onRemoveComment: PropTypes.func,
  isScrollToComment: PropTypes.bool,
  onEditComment: PropTypes.func,
  showPinIfHasCoordinates: PropTypes.bool,
  showPenIfHasAnnotation: PropTypes.bool,
  showActiveButtonInRow: PropTypes.bool,
  onReply: PropTypes.func,
  editorProps: PropTypes.object,
  isReadOnly: PropTypes.bool,
};
