import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Field from 'Components/questionnaireComponents/Field';
import { Button } from 'Components/Button';

import {
  NewMilestonesDropdown,
} from 'Pages/ProjectDetails/sub-routes/Workspace/components/MilestonesList/components/NewMilestonesDropdown';

import MilestoneService from 'services/milestones';

import { MILESTONES_TYPES } from 'utlis/constants';

import { ReactComponent as PencilIcon } from 'assets/pencil.svg';

import styles from './styles.module.scss';

export const ProposalMilestones = ({
  form,
  disabled,
  onSave,
}) => {
  const currentProject = useSelector(state => state.selectedProject);
  const currentUser = useSelector(state => state.userProfile);

  const [milestonesListThatUserCanCreateInProject, setMilestonesListThatUserCanCreateInProject] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const ref = useRef();

  useEffect(
    () => {
      if (currentProject.uuid) {
        MilestoneService.getMilestonesListThatUserCanCreateInProject(currentProject.uuid, currentProject.type)
          .then(initializeMilestoneList);
      }
    },
    [currentProject]
  );

  return (
    <>
      <div className="flex align-center space-between mt-60 mb-32">
        <span className="text-34 letter-xxs">
          Milestones
        </span>
        {
          isEditMode
            ? (
              <div className="flex gap-12">
                <Button
                  type="text"
                  isInvert
                  className="text-13"
                  onClick={cancelEditModeChanges}
                >
                  CANCEL
                </Button>
                <Button
                  className="ml-sm"
                  onClick={saveEditModeChanges}
                >
                  Save
                </Button>
              </div>
            )
            : (
              <Button
                onClick={() => setIsEditMode(true)}
                shape="circle"
                className={cn(
                  styles.edit__button,
                  {
                    hide: (
                      currentUser.permissions.isClient
                        || disabled
                        || (!form.getFieldValue('milestones')?.length)
                    ),
                  }
                )}
                icon={<PencilIcon />}
              />
            )
        }
      </div>

      <DragDropContext onDragEnd={isEditMode && onDragEnd}>
        <Droppable droppableId={'proposal-milestone-list'}>
          {droppableProvided => (
            <div
              ref={droppableProvided.innerRef}
              className="text-25 text-black letter-xs mt-24"
              {...droppableProvided.droppableProps}
              {...droppableProvided.dragHandleProps}
            >
              <Form.List name="milestones">
                {
                  fields => (
                    <>
                      {
                        fields.map((field, index) => (
                          <Draggable
                            key={field.key}
                            draggableId={`${field.name}-${field.key}`}
                            index={index}
                            isDragDisabled={!isEditMode || disabled}
                          >
                            {draggableProvided => (
                              <Row
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="letter-xs"
                                wrap={false}
                              >
                                <Col
                                  flex="50px"
                                  className={cn('mr-8', {
                                    'pt-md': index !== 0,
                                  })}
                                >
                                  <span className="text-weight-600">
                                    {index + 1}.
                                  </span>
                                </Col>
                                <Col
                                  flex="235px"
                                  className={cn({
                                    'pt-md': index !== 0,
                                  })}
                                >
                                  <div className={styles.milestone__name}>
                                    <span className="text-weight-500">
                                      {form.getFieldValue(['milestones', field.key]).name}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  flex="auto"
                                  className={cn(
                                    styles['left-vertiacl-divider'],
                                    'pl-32',
                                    {
                                      'pt-md': index !== 0,
                                    }
                                  )}
                                >
                                  <div className="flex align-center space-between">
                                    <p className="text-20 text-weight-300 m-zero">
                                      <Field
                                        name={[field.key, 'description']}
                                        className="text-weight-300"
                                        placeholder="Add description of the milestone. It can include the challenges
                                                    you’ll tachle along with the deliverables"
                                        required
                                        disabled={
                                          currentUser.permissions.isClient
                                            || disabled
                                            || isEditMode
                                        }
                                      />
                                    </p>
                                    <Button
                                      className={
                                        cn(
                                          'text-13 ml-16 text-notesText text-button',
                                          styles.remove__button,
                                          { hide: !isEditMode }
                                        )}
                                      onClick={() => deleteMilestone(index)}
                                      icon={<FontAwesomeIcon icon={faTimes} className="mr-sm" />}
                                    >
                                      REMOVE
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Draggable>
                        ))}
                    </>
                  )}
              </Form.List>
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        className={cn(styles.dropdown__wrapper, {
          'mt-64': isEditMode,
          hide: disabled,
        })}
      >
        <NewMilestonesDropdown
          milestonesList={milestonesListThatUserCanCreateInProject}
          onAddNewMilestone={addNewMilestone}
          getPopupContainer={() => ref?.current}
          disabledItemMode={false}
        >
          <span ref={ref}>
            <Button disabled={currentUser.permissions.isClient || disabled}>
              <FontAwesomeIcon className="mr-12" icon={faPlus} size="lg" />{' '}
              Add Milestone
            </Button>
          </span>
        </NewMilestonesDropdown>
      </div>
    </>
  );

  function cancelEditModeChanges() {
    form.resetFields();
    setIsEditMode(false);
  }

  function saveEditModeChanges() {
    onSave();
    setIsEditMode(false);
  }

  function onDragEnd(result) {
    if (result.destination) {
      const previousMilestoneList = form.getFieldValue('milestones');
      const [removed] = previousMilestoneList.splice(result.source.index, 1);
      previousMilestoneList.splice(result.destination.index, 0, removed);
      form.setFieldValue(
        'milestones',
        previousMilestoneList
      );
    }
  }

  function addNewMilestone(key) {
    const previousMilestoneList = form.getFieldValue('milestones') || [];
    form.setFieldValue(
      'milestones',
      [
        ...previousMilestoneList,
        {
          name: key,
          description: '',
        },
      ]);
    updateMilestoneList(key);
    onSave();
  }

  function deleteMilestone(index) {
    const previousMilestoneList = form.getFieldValue('milestones');
    const deletedMilestone = previousMilestoneList.splice(index, 1);
    updateMilestoneList(deletedMilestone.name);
    form.setFieldValue(
      'milestones',
      [...previousMilestoneList]
    );
  }

  function initializeMilestoneList(milestoneList) {
    // INFO: Removing design brief in every case because it is already been created for every type of project
    const excludedMilestoneList = milestoneList
      .filter(
        milestone => milestone.type !== MILESTONES_TYPES.designBrief
      );
    const validMilestoneList = excludedMilestoneList
      .map(milestone => {
        const isInList = form.getFieldValue('milestones')
          ?.find(
              currentAddedMilestone => currentAddedMilestone.name === milestone.name
          );
        return {
          ...milestone,
          can_user_create_more: !(milestone.is_unique && isInList),
        };
      });
    setMilestonesListThatUserCanCreateInProject(validMilestoneList);
  }

  function updateMilestoneList(milestoneName) {
    const availableMilestones = milestonesListThatUserCanCreateInProject
      .filter(({
        name,
        is_unique,
      }) =>
        !(name === milestoneName && is_unique)
      );
    setMilestonesListThatUserCanCreateInProject(availableMilestones);
  }
};

ProposalMilestones.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};
