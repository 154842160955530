import PropTypes from 'prop-types';
import cn from 'classnames';

import defaultUserAvatar from 'assets/user_avatar.svg';

import { onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export function ExportProfileCard({
  name,
  title,
  country,
  profile_picture,
  onExpertSelect,
}) {
  return (
    <button className={cn('flex mt-md', styles.expert__cardWrapper)} onClick={onExpertSelect}>
      <img
        className={styles.expert__avatar}
        src={profile_picture || defaultUserAvatar}
        onError={e => onLoadImageError(e, defaultUserAvatar)}
        alt="expert profile image"
      />
      <div className="flex flex-column ml-16">
        <span className="text-14 text-weight-500">
          {name}
        </span>
        <span className={styles.expert__subtitle}>
          {title}
        </span>
        <span className={styles.expert__subtitle}>
          {country}
        </span>
      </div>
    </button>
  );
}

ExportProfileCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  profile_picture: PropTypes.string.isRequired,
  onExpertSelect: PropTypes.func,
};
