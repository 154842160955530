import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { editUserProfile } from 'Redux/Actions/userActions';

import { USER_TYPES } from 'utlis/constants';

import styles from './styles.module.scss';

const Onboarding = () => {
  const authUser = useSelector(state => state.userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser?.uuid) {
      dispatch(editUserProfile(authUser, USER_TYPES.designerNumber, navigate));
    }
  }, [authUser]);

  return (
    <div className={styles.onboarding}>
      <div className={styles['onboarding-message']}>
        Signing in...
      </div>
    </div>
  );
};

export default Onboarding;
