import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class MiroFileService {
  static async connectMiro(return_url) {
    const response = await api.setUrl('user/miro_connect').post({ return_url });
    const newWindow = window.open(
      response.data.url,
      '_self',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null;
    }
  }

  static saveMiroFile(projectUUID, miroFileUrl, milestoneId, containerUUID, prevFileUUID) {
    return api.setUrl(`project/${projectUUID}/miro_file`).post({
      container: containerUUID || null,
      milestone_id: milestoneId,
      prev_file: prevFileUUID || null,
      board_url: miroFileUrl,
      board_id: MiroFileService.getBoardIdFromMiroUrl(miroFileUrl),
    });
  }

  static updateMiroFile(projectUUID, oldMiroFileUUID, newMiroFileUrl) {
    return api.setUrl(`project/${projectUUID}/miro_file`).patch(
      oldMiroFileUUID,
      {
        board_url: newMiroFileUrl,
        board_id: MiroFileService.getBoardIdFromMiroUrl(newMiroFileUrl),
      }
    );
  }

  static deleteMiroFile(projectUUID, fileId) {
    return api.setUrl(`project/${projectUUID}/miro_file`).delete(fileId);
  }

  static getBoardIdFromMiroUrl(url) {
    return url.split('/').at(-2);
  }
}
