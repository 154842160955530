import actionTypes from '../constants';

export const contractReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTRACT_DOCUMENT:
      return getContractInfo(action.payload);
    case actionTypes.REMOVE_CONTRACT_DOCUMENT:
      return {};

    default:
      return state;
  }
};

function getContractInfo(contractInfo) {
  return {
    ...contractInfo,
    contract_json: {
      ...contractInfo.contract_json,
      product_available_in: contractInfo?.contract_json?.product_available_in || [],
    },
  };
}
