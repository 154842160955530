import { setup } from '@loomhq/record-sdk';

export class Loom {
  static configureLoomButton = null
  static isLoomInitialized = false

  static async init() {
    const { configureButton, status: LoomStatus } = await setup({
      publicAppId: process.env.REACT_APP_LOOM_PUBLIC_ID,
      config: {
        insertButtonText: 'Copy link',
      },
    });

    if (LoomStatus().success) {
      Loom.configureLoomButton = configureButton;
      Loom.isLoomInitialized = true;
    }

    return { configureButton, status: LoomStatus };
  }
}
