import { ApiManager } from 'utlis/api_manager';

import { PAYMENT_STATUSES } from 'utlis/constants';

const api = new ApiManager(process.env.REACT_APP_BASE_URL);
const apiV2 = new ApiManager(process.env.REACT_APP_BASE_URL, 2);

export default class StripePaymentService {
  static PAYMENT_STATUSES = PAYMENT_STATUSES;

  static async getLinkToStripeAccount() {
    return (await api.setUrl('user/stripe_onboarding').post({
      return_url: location.href,
    })).data.account_link;
  }

  static async withdrawMoney() {
    return (await api.setUrl('user/withdraw').post())?.data;
  }

  static async createPaymentRequestByDesigner(projectUUID, amount, currency, paymentType) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment`).post({
      amount: amount,
      currency,
      payment_type: paymentType,
    }))?.data;
  }

  static async clientRejectsPayment(projectUUID, paymentRequestUUID) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment/${paymentRequestUUID}/deny_request`).patch())?.data;
  }

  static async createClientSecret(projectUUID, paymentRequestUUID) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment/${paymentRequestUUID}/pay`).patch())?.data;
  }

  static async getPaymentsList(projectUUID, page = 1, pageSize = 10) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment`).get({
      limit: pageSize,
      offset: pageSize * (page - 1),
    }))?.data;
  }

  static deletesPendingPayment(projectUUID, paymentUUID) {
    return apiV2.setUrl(`project/${projectUUID}/payment/${paymentUUID}`).delete();
  }

  static async capturePaymentWithExistingPaymentMethod(projectUUID, paymentRequestUUID, payMethodId) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment/${paymentRequestUUID}/pay`).patchWithQuery(
      null,
      {
        pm_id: payMethodId,
      }
    ))?.data;
  }

  static async getUserPaymentMethods() {
    return (await api.setUrl('user/payment_method').get())?.data;
  }

  static async postPaymentCallback(projectUUID, paymentRequestUUID, saveCard) {
    return (await apiV2.setUrl(`project/${projectUUID}/payment/${paymentRequestUUID}/post_payment`).patchWithQuery(
      null,
      {
        is_save: saveCard,
      }
    ))?.data;
  }

  static async unlinkStripeAccount() {
    return api.setUrl('user/stripe_onboarding').delete();
  }
}
