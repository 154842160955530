import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Form } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';
import { ReactQuill } from 'Components/ReactQuill';

import styles from './styles.module.scss';

export function MoodboardTextModal({ onSubmit, initialValues, onCancel, title, readOnly, ...props }) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [form] = Form.useForm();

  useEffect(
    () => {
      form.setFieldsValue(initialValues);
    },
    [initialValues]
  );

  return (
    <Modal
      title={
        <p
          className={cn(
            'text-34 text-weight-600 letter-xs text-black pt-32 px-32',
            {
              hide: !title && readOnly,
            }
          )}
        >
          {
            title || (
              initialValues?.text
                ? 'Edit text'
                : 'Add text'
            )
          }
        </p>
      }
      className={styles.modal}
      destroyOnClose
      footer={null}
      width={600}
      onCancel={onClose}
      closable={false}

      {...props}
    >
      <Form
        initialValues={initialValues}
        form={form}
        preserve
        onFinish={submitText}
      >
        {
          readOnly && initialValues?.text && (
            <p
              className={cn(styles['react-quill-text'], 'px-32 py-32 m-zero')}
              dangerouslySetInnerHTML={{ __html: initialValues?.text }}
            />
          )
        }
        <Form.Item
          name="text"
          className={cn(
            'm-zero',
            {
              hide: readOnly,
            }
          )}
          rules={[{
            validator: textValidator,
          }]}
        >
          <ReactQuill
            className={cn(
              styles['moodboard-text-react-quill'],
              'mx-32 mb-32'
            )}
            placeholder="Enter notes here..."
          />
        </Form.Item>
        <Divider className="m-zero" />
        <div
          className={cn('flex align-center just-end px-32 py-16')}
        >
          <Button
            isText
            className="text-sm text-weight-500 letter-xl text-lochinvar-opacity-0-80"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className={cn(
                  'ml-32',
                  {
                    hide: readOnly,
                  }
                )}
                loading={isSubmiting}
                disabled={isSubmiting || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              >
                DONE
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  async function submitText({ text }) {
    try {
      setIsSubmiting(true);

      await onSubmit(text);
    }
    finally {
      setIsSubmiting(false);
    }
  }

  function onClose() {
    form.resetFields();

    if (onCancel) {
      onCancel();
    }
  }

  function textValidator(rule, value) { // eslint-disable-line no-unused-vars
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = (div.textContent || div.innerText || '').trim();

    return text ? Promise.resolve() : Promise.reject();
  }
}

MoodboardTextModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  onCancel: PropTypes.func,
  initialValues: PropTypes.any,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
};
