import { toast } from 'react-toastify';

const commonReactToastifyProps = {
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const errorReactToastify = (message, props) => toast.error(message, {
  ...commonReactToastifyProps,
  ...props,
});

export const successReactToastify = (message, props) => toast.success(message, {
  ...commonReactToastifyProps,
  ...props,
});

export const infoReactToastify = (message, props) => toast.info(message, {
  ...commonReactToastifyProps,
  autoClose: 3000,
  ...props,
});
