import { useEffect, useState } from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import styles from './styles.module.scss';

const EmailVerification = () => {
  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(currentCountdown => currentCountdown - 1);
      }, 1000);
    }
    else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="Please verify your email."
      description="If you can't find the email, please check your spam or click the button to resend it."
      button={{
        onClick: resendEmail,
        text: countdown > 0 ? `Please wait ${countdown} seconds` : 'RESEND VERIFICATION EMAIL',
        loading: isLoading,
        disabled: isLoading || countdown > 0,
      }}
      hideNav
    />
  );

  function resendEmail() {
    setIsLoading(true);

    const url = window.localStorage.getItem('url');

    sendEmailVerification(auth.currentUser, {
      url: url,
      handleCodeInApp: true,
    })
      .then(() => {
        setCountdown(30); // Start countdown after successful resend
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Failed to resend verification email:', error);
        setIsLoading(false);
      });
  }
};

export default EmailVerification;
