import { match } from 'path-to-regexp';
import qs from 'qs';

import { ApiManager } from 'utlis/api_manager';

const figmaImagesApi = new ApiManager(`${process.env.REACT_APP_FIGMA_BASE_URL}/v1/images`, null);
const figmaFilesApi = new ApiManager(`${process.env.REACT_APP_FIGMA_BASE_URL}/v1/files`, null);
const oldApi = new ApiManager(process.env.REACT_APP_BASE_URL, 1);
const api = new ApiManager(process.env.REACT_APP_BASE_URL, 2);

export default class FigmaFile {
  static async uploadFigmaFile(
    projectUUID,
    figmaFileUrl,
    milestoneId,
    containerUUID,
    prevFileUUID,
    imgFormat = 'png'
  ) {
    const { fileKey, fileName, nodeId } = await FigmaFile.getFigmaFileInfoFromFigmaUrl(figmaFileUrl);

    const figmaImage = await FigmaFile.getFigmaImage(figmaFileUrl, imgFormat);

    if (!figmaImage) {
      return Promise.reject({
        response: {
          status: 406,
          message: 'An empty Figma file can not be uploaded.',
        },
      });
    }

    return api.setUrl(`project/${projectUUID}/figma_file`).post({
      container: containerUUID || null,
      milestone_id: milestoneId,
      prev_file: prevFileUUID || null,
      files: [
        {
          file_key: fileKey,
          node_ids: [nodeId],
          svg_links: [figmaImage],
          file_name: fileName,
        },
      ],
    });
  }

  static deleteFigmaFile(projectUUID, figmaFileUUID) {
    return oldApi.setUrl(`project/${projectUUID}/figma_file/${figmaFileUUID}`).delete();
  }

  static async updateFigmaFile(
    projectUUID,
    figmaFileUUID,
    newFigmaFileUrl,
    imgFormat = 'png'
  ) {
    const { fileKey, fileName, nodeId } = await FigmaFile.getFigmaFileInfoFromFigmaUrl(newFigmaFileUrl);
    const figmaImage = await FigmaFile.getFigmaImage(newFigmaFileUrl, imgFormat);

    if (!figmaImage) {
      return Promise.reject({ message: 'An empty Figma file can not be uploaded.' });
    }

    return oldApi.setUrl(`project/${projectUUID}/figma_file`).patch(
      figmaFileUUID,
      {
        file_key: fileKey,
        node_ids: [nodeId],
        svg_links: [figmaImage],
        file_name: fileName,
      }
    );
  }

  static async getFigmaImage(figmaFileUrl, imgFormat = 'png') {
    const { fileKey, nodeId } = await FigmaFile.getFigmaFileInfoFromFigmaUrl(figmaFileUrl);

    return figmaImagesApi.getByKey(
      fileKey,
      {
        ids: nodeId,
        scale: 1,
        format: imgFormat,
      }
    )
      .then(({ data: { images } }) => images[nodeId]);
  }

  static openFigmaOauth(projectUUID, currentLocation) {
    return oldApi.setUrl(`user/${projectUUID}/figma_oauth`)
      .get(
        {},
        {
          headers: {
            'X-PLUGIN-REDIRECT': currentLocation || '',
          },
        }
      )
      .then(response => {
        const newWindow = window.open(
          response.data.url,
          '_self',
          'noopener,noreferrer'
        );

        if (newWindow) {
          newWindow.opener = null;
        }
      });
  }

  static async getFigmaFileInfoFromFigmaUrl(figmaUrl) {
    const urlObj = new URL(figmaUrl); // eslint-disable-line compat/compat
    const parser = match(
      '/:type(file|design|board)/:fileKey/:fileName',
      {
        decode: decodeURIComponent,
      }
    );
    const pathParams = parser(urlObj.pathname)?.params;
    const searchParams = qs.parse(urlObj.search, { ignoreQueryPrefix: true });
    let nodeId = searchParams['node-id'];

    if (!nodeId && pathParams?.fileKey) {
      const figmaFileInfo = await FigmaFile.getFigmaFileInfo(
        pathParams?.fileKey,
        {
          config: {
            cache: {
              maxAge: 1 * 60 * 1000, // 1 minute into milliseconds
              exclude: { query: false },
            },
          },
        }
      );

      nodeId = figmaFileInfo?.document?.children?.[0]?.id;
    }

    return {
      fileKey: pathParams?.fileKey,
      fileName: pathParams?.fileName,
      nodeId: nodeId.replace('-', ':'),
    };
  }

  static getFigmaFileInfo(figmaFileKey, { figmaNodeIds = '', depth = 1, config = {} } = {}) {
    return figmaFilesApi.setUrl(`${figmaFileKey}${figmaNodeIds ? '/nodes' : ''}`).get(
      {
        ids: figmaNodeIds,
        depth,
      },
      config
    )
      .then(({ data }) => data);
  }

  static getFigmaFileSize(figmaFileKey, figmaNodeIds) {
    return FigmaFile.getFigmaFileInfo(figmaFileKey, { figmaNodeIds })
      .then(({ nodes }) => {
        const figmaFileDocument = nodes[figmaNodeIds].document;

        if (figmaFileDocument?.absoluteBoundingBox) {
          return {
            width: figmaFileDocument?.absoluteBoundingBox.width,
            height: figmaFileDocument?.absoluteBoundingBox.height,
          };
        }

        const { x: figmaNodeRealX, y: figmaNodeRealY } = figmaFileDocument.children
          .map(({ absoluteBoundingBox }) => ({
            x: Math.max(0, absoluteBoundingBox.x),
            y: Math.max(0, absoluteBoundingBox.y),
          }))
          .reduce(
            (current, next) => ({
              x: Math.min(current.x, next.x),
              y: Math.min(current.y, next.y),
            }),
            {
              x: Infinity,
              y: Infinity,
            }
          );

        return figmaFileDocument.children
          .map(({ absoluteBoundingBox }) => ({
            width: Math.max(0, absoluteBoundingBox.x) + absoluteBoundingBox.width - figmaNodeRealX,
            height: Math.max(0, absoluteBoundingBox.y) + absoluteBoundingBox.height - figmaNodeRealY,
          }))
          .reduce(
            (current, next) => ({
              width: Math.max(current.width, next.width),
              height: Math.max(current.height, next.height),
            }),
            {
              width: 0,
              height: 0,
            }
          );
      });
  }
}
