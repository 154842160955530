import { useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faArrowUpFromBracket, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from 'antd';
import cn from 'classnames';

import { ReactComponent as AcrobatLogo } from 'assets/AcrobatLogo.svg';
import { ReactComponent as FigmaIcon } from 'assets/figma_logo.svg';
import { ReactComponent as GoogleDriveIcon } from 'assets/GoogleDriveIcon.svg';
import { ReactComponent as MiroIcon } from 'assets/miro_icon.svg';

import { DropdownButton, DropdownMenu } from 'Components/DropdownMenu';
import { Modal } from 'Components/Modal';

import FigmaServices from 'services/figma_file';
import MiroFileService from 'services/miro_file';

import { FILE_CONTAINER_TYPES, IMAGE_MIME_TYPES } from 'utlis/constants';

import { AddLinkToFileForm } from './components/AddLinkToFileForm';

import styles from './styles.module.scss';

export default function AddFile({
  onAddFigmaFile,
  onAddGDriveFile,
  onAddMiroFile,

  withFigma,
  withMiro,
  withPDF,
  withImage,
  withGDrive,
  customButton,
  onClick,
}) {
  const [addFileModalType, setAddFileModalType] = useState(null);

  const gDriveProvider = useSelector(state => state.userProfile?.appTokens?.googleDrive);
  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);
  const miroAppToken = useSelector(state => state.userProfile?.appTokens?.miro);
  const selectedProject = useSelector(state => state.selectedProject);

  const [openPicker] = useDrivePicker();

  return (
    <>
      <DropdownMenu
        destroyPopupOnHide
        overlay={
          <Menu
            className="p-zero"
            items={
              [
                {
                  isShow: withFigma,
                  title: 'Figma file',
                  icon: (
                    <FigmaIcon
                      className={cn(
                        styles['figma-icon'],
                        styles['dropdown-button-icon'],
                        'mr-sm flex flex-component-center'
                      )}
                    />
                  ),
                  onClick: () => setAddFileModalType(FILE_CONTAINER_TYPES.figma),
                },
                {
                  isShow: withMiro,
                  title: 'Miro',
                  icon: (
                    <MiroIcon
                      className={cn(
                        styles['dropdown-button-icon'],
                        'mr-sm flex flex-component-center'
                      )}
                    />
                  ),
                  onClick: () => setAddFileModalType(FILE_CONTAINER_TYPES.miro),
                },
                {
                  isShow: withPDF,
                  title: 'PDF',
                  icon: (
                    <AcrobatLogo
                      className={cn(
                        styles['dropdown-button-icon'],
                        'mr-sm'
                      )}
                    />
                  ),
                  onClick: () => handleOpenGDrivePicker({
                    onPicked: files => addGDriveFile(files, FILE_CONTAINER_TYPES.pdfNumber),
                    multiselect: false,
                    viewId: 'PDFS',
                    viewMimeTypes: 'application/pdf',
                  }),
                },
                {
                  isShow: withImage,
                  title: 'Image',
                  icon: (
                    <FontAwesomeIcon
                      icon={faArrowUpFromBracket}
                      className={cn(
                        styles['image-icon'],
                        styles['dropdown-button-icon'],
                        'mr-sm px-xs ml-xxxs'
                      )}
                    />
                  ),
                  onClick: () => handleOpenGDrivePicker({
                    onPicked: files => addGDriveFile(files, FILE_CONTAINER_TYPES.pngNumber),
                    viewId: 'DOCS_IMAGES',
                    viewMimeTypes: IMAGE_MIME_TYPES,
                  }),
                },
                {
                  isShow: withGDrive,
                  title: 'Gdrive',
                  icon: (
                    <GoogleDriveIcon
                      className={cn(
                        styles['dropdown-button-icon'],
                        'mr-sm'
                      )}
                    />
                  ),
                  onClick: () => handleOpenGDrivePicker({
                    onPicked: files => addGDriveFile(files, FILE_CONTAINER_TYPES.gDriveNumber),
                    multiselect: false,
                  }),
                },
              ]
                .filter(({ isShow }) => isShow)
                .map(({ title, icon, ...buttonProps }) => ({
                  className: 'p-zero',
                  label: (
                    <DropdownButton
                      icon={icon}
                      text={title}
                      {...buttonProps}
                    />
                  ),
                  key: title,
                }))
            }
          />
        }
        trigger={['click']}
        onClick={onClick}
      >
        {
          customButton || (
            <Button
              className={cn(styles['add-file-button'], 'px-24 py-12 text-sm text-weight-500 letter-xl')}
              icon={<FontAwesomeIcon icon={faPlus} className="mr-12" />}
            >
              Add file
            </Button>
          )
        }
      </DropdownMenu>
      <Modal
        title={
          <p className="text-20 text-weight-500 letter-xs pt-md ml-16">
            {`Paste ${addFileModalType} link below`}
          </p>
        }
        destroyOnClose
        className={styles['add-figma-file-modal']}
        visible={!!addFileModalType}
        onCancel={() => setAddFileModalType(null)}
        footer={null}
        centered
      >
        {
          addFileModalType === FILE_CONTAINER_TYPES.figma
            ? (
              <AddLinkToFileForm.Figma
                onAddFile={onAddFigmaFile}
                onHandleConnect={() => FigmaServices.openFigmaOauth(selectedProject.uuid)}
                appToken={figmaAppToken}
              />
            )
            : (
              <AddLinkToFileForm.Miro
                onAddFile={onAddMiroFile}
                onHandleConnect={() => MiroFileService.connectMiro(location.href)}
                appToken={miroAppToken}
              />
            )
        }
      </Modal>
    </>
  );

  function handleOpenGDrivePicker({ onPicked, ...options }) {
    openPicker({
      clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      developerKey: `${process.env.REACT_APP_PICKER_DEVELOPER_KEY}`,
      token: gDriveProvider.access_token,
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: ({ action, docs }) => {
        if (action == google.picker.Action.PICKED) {
          onPicked(docs);
        }
      },
      ...options,
    });
  }

  function addGDriveFile(files, containerType) {
    if (files.every(({ mimeType }) => IMAGE_MIME_TYPES.indexOf(mimeType) != -1)) {
      containerType = FILE_CONTAINER_TYPES.pngNumber;
    }
    if (files.every(({ mimeType }) => mimeType === 'application/pdf')) {
      containerType = FILE_CONTAINER_TYPES.pdfNumber;
    }

    return onAddGDriveFile(files, containerType);
  }
}

AddFile.propTypes = {
  onAddFigmaFile: PropTypes.func.isRequired,
  onAddGDriveFile: PropTypes.func.isRequired,
  onAddMiroFile: PropTypes.func.isRequired,

  withFigma: PropTypes.bool,
  withMiro: PropTypes.bool,
  withPDF: PropTypes.bool,
  withImage: PropTypes.bool,
  withGDrive: PropTypes.bool,
  customButton: PropTypes.node,
  onClick: PropTypes.func,
};
