export default {
  Owner: 'Owner',
  Client: 'Client',
  Collaborator: 'Collaborator',
  Designer: 'Designer',
  ACTIVITY: 'ACTIVITY',
  //   IDs and Classes for Rich Text Editor
  NotesEditingContainerId: 'note__editingContainer',
  NotesThreeDotsId: 'notes__threeDots',
  QuillEditorClass: 'ql-editor',
  QuillToolbarClass: 'ql-toolbar',
  TextEditorContainerId: 'text__editorContainer',
  // IDs and Classes for Dashboard Thumbnail
  ThumbnailThreeDotsId: 'thumbnail__threeDots',
  // IDs and Classes for Project Thumbnail
  WorkspaceMainContainerId: 'workspace__mainContainer',
  FeedPostCommentClass: 'feed__postComments',
  CommentFeedContainerId: 'comment_feed',
  CommentReplyPostButtonClass: 'comment__replyPostButton',
  WorkspaceVersionModalId: 'versionModal',
};

export const NOTIFICATION_TYPES = {
  CREATE_PROJECT: 'create_project',
  JOIN_PROJECT: 'join_project',
  FORM_SUBMIT: 'form_submit',
  FORM_ANSWERS_SUBMIT: 'form_answers_submit',
  MILESTONE_START: 'milestone_start',
  FILE_POST_IN_MILESTONE: 'file_post_in_milestone',
  MILESTONE_DONE_BY_DESIGNER: 'milestone_done_by_designer',
  MILESTONE_APPROVE_BY_CLIENT: 'milestone_approve_by_client',
  REQUEST_PAYMENT: 'request_payment',
  PAYMENT_REQUEST_DENIED: 'payment_request_denied',
  PAYMENT_AMOUNT_PAID: 'payment_amount_paid',
  PAYMENT_REQUEST_DELETED: 'payment_request_deleted',
  FORM_EDIT: 'form_edit',
  MILESTONE_UPDATED: 'milestone_updated',
  MILESTONE_SEQUENCE_UPDATED: 'milestone_sequence_updated',
  COMMENT_POST: 'comment_post',
  COMMENT_EDIT: 'comment_edit',
  COMMENT_DELETE: 'comment_delete',
  CREATE_NOTE: 'create_note',
  EDIT_NOTE: 'edit_note',
  DELETE_NOTE: 'delete_note',
  CREATE_PALETTE: 'create_palette',
  EDIT_PALETTE: 'edit_palette',
  DELETE_PALETTE: 'delete_palette',
  ADD_IMAGE: 'add_image',
  ADD_TEXT: 'add_text',
  DELETE_IMAGE: 'delete_image',
  POST_VOTE: 'post_vote',
  REACTION_ADDED: 'reaction_added',
  REACTION_MINUSED: 'reaction_minused',
};

export const ENV_CONSTANTS = {
  REACT_APP_SENTRY_TRACE_RATE: process.env.REACT_APP_SENTRY_TRACE_RATE || 0,
};

export const MILESTONES_TYPES = {
  designBrief: 'brief', // TODO: Delete one of it and merge the names when the notification works are finished
  uxBrief: 'brief',
  proposal: 'proposal',
  contract: 'contract',
  moodboard: 'moodboard',
  deposit: 'deposit',
  designConcepts: 'design-concepts',
  presentation: 'presentation',
  finalPayment: 'final-payment',
  deliverFiles: 'deliver-files',
  userInterview: 'user-interview',
  designReview: 'design-review',
  sketches: 'sketches',
  brandPersonality: 'brand-personality',
  colorPalette: 'color-palette',
};

export const MILESTONES_APPS = {
  googleForms: 'Google Forms',
  figma: 'Figma',
  miro: 'Miro',
  gdrive: 'Gdrive',
  googleDocs: 'Google Docs',
};

export const MILESTONES_STATUS = {
  pending: 'Pending state',
  started: 'Started state',
};

export const FILE_CONTAINER_TYPES = {
  figma: 'Figma',
  miro: 'Miro',
  gDrive: 'G-drive',
  pdf: 'PDF',
  png: 'PNG',

  2: 'G-drive',
  3: 'PNG',
  4: 'PDF',

  gDriveNumber: 2,
  pngNumber: 3,
  pdfNumber: 4,
};

export const VOTE_CONTENT_TYPE_NAMES = {
  miroFile: 'projectmirofile',
  gDriveFiles: 'projectgoogledrivefiles',
  figmaFile: 'projectfigmafile',
};

export const COMMENT_TYPES = {
  COMMENT_ON_PROJECT: 1,
  COMMENT_INSIDE_FIGMA_FILE: 3,
  COMMENT_ON_ANY_OTHER_MILESTONE: 4,
  COMMENT_INSIDE_GOOGLE_DRIVE_FILE: 5,
  COMMENT_ON_DESIGN_BRIEF: 6,
  COMMENT_ON_CONTRACT_DOCUMENT: 7,
};

export const USER_NUMBER_TYPES = {
  1: 'Designer',
  2: 'Client',
};

export const USER_TYPES = {
  designer: 'Designer',
  client: 'Client',

  designerNumber: 1,
  clientNumber: 2,
};

export const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/webp',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'image/bmp',
  'image/x-bmp',
].join(',');

export const PROJECT_TYPES = {
  simpleUx: 'Simple UX',
  blankProject: 'Blank Project',
  landingPage: 'Landing Page',
  pitchDeck: 'Pitch Deck',
  logoDesign: 'Logo Design',
};

export const PAYMENT_STATUSES = {
  initiated: 'initiated',
  captured: 'captured',
  requested: 'requested',
  denied: 'denied',
};

export const PROJECT_STATUS = {
  active: 2,
  completed: 3,
};

export const MILESTONE_STATUSES = {
  started: 'Started state',
  pending: 'Pending state',
  submitted: 'Submitted state',
  completed: 'Completed state',
};

export const INVITE_STATUSES = {
  accepted: 'Accepted',
  pending: 'Pending',
  denied: 'Denied',
};

export const REACTIONS_CONTENT_TYPE_NAMES = {
  thirdPartyImage: 'thirdpartyimage',
  projectGoogleDriveFiles: 'projectgoogledrivefiles',
};

export const KANBAN_BOARD_ID_TO_STRING = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
};

export const KANBAN_BOARD_SORT_OPTIONS = {
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first',
};

export const KANBAN_BOARD_PRIORITY_OPTIONS = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const KANBAN_BOARD_STATUS_OPTIONS = {
  BACKLOG: 'Backlog',
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  DONE: 'Done',
};

export const KANBAN_BOARD_STATUS_NUMBER = {
  1: 'Backlog',
  2: 'To Do',
  3: 'In Progress',
  4: 'Done',
};

export const KANBAN_BOARD_NAME_TO_STATUS = {
  'Backlog': 1,
  'To Do': 2,
  'In Progress': 3,
  'Done': 4,
};

export const KANBAN_BOARD_STATUS_NAMES = {
  1: 'backlog',
  2: 'to-do',
  3: 'in-progress',
  4: 'done',
};

export const KANBAN_BOARD_PRIORITY_NUMBER = {
  low: 1,
  medium: 2,
  high: 3,
};

export const KANBAN_BOARD_TAG_DEFAULT_OPTIONS = [
  'Research',
  'User Flow',
  'Information Architecture',
  'Navigation',
  'Usability',
  'Consistency',
  'Color',
  'Typography',
  'Iconography',
  'Layout',
  'Branding',
  'Animation',
  'Accessibility',
  'Responsive Design',
  'Copy',
  'Illustration',
  'Video',
  'Bug',
  'Quick fix',
  'Testing',
];

export const KANBAN_BOARD_TAG_COLORS = {
  AI_GENERATED: {
    backgroundColor: '#EEF1FF',
    borderColor: '#5C76EB',
  },
  CUSTOM: {
    backgroundColor: 'rgba(235, 229, 255, 0.80)',
    borderColor: '#9178F8',
  },
};

export const KANBAN_BOARD_DATE_RANGE_OPTIONS = {
  LAST_WEEK: 'Last week',
  LAST_TWO_WEEKS: 'Last 2 weeks',
  LAST_THREE_WEEKS: 'Last 3 weeks',
  LAST_MONTH: 'Last month',
};

export const KANBAN_BOARD_SYNC_OPTIONS = {
  PROCESSING: 1,
  FINISHED: 2,
  ERROR: 3,
  SYNCING: 4,
  CREATING: 5,
};

export const KANBAN_BOARD_SYNC_MESSAGES = {
  PROCESSING_FIGMA_FILE: 'Importing Figma file, this can take a few minutes.',
  PROCESSING_TRANSCRIPTION_FILE: 'Importing Transcription file, this can take a few minutes.',
  PROCESSING_SLACK_THREAD: 'Importing Slack Thread, this can take a few minutes.',
  SYNCING: 'Syncing files, this can take a few minutes.',
  CREATING: 'Creating custom task in progress',
};

export const SHORT_MONTH_NAMES = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export const KANBAN_BOARD_FILE_TYPE_VALUES = {
  FIGMA: 1,
  TRANSCRIPTION: 2,
  CUSTOM: 3,
  NOTES: 4,
  SLACK: 5,
  FigJam: 6,
  JIRA: 7,
};

export const KANBAN_BOARD_FILE_PROGRESS_STATUS = {
  [KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA]: 'Importing Figma file, this can take a few minutes.',
  [KANBAN_BOARD_FILE_TYPE_VALUES.TRANSCRIPTION]: 'Importing Transcription file, this can take a few minutes.',
  [KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM]: 'Creating custom task in progress',
  [KANBAN_BOARD_FILE_TYPE_VALUES.NOTES]: 'Importing meeting notes, this can take a few minutes.',
  [KANBAN_BOARD_FILE_TYPE_VALUES.SLACK]: 'Importing Slack Thread, this can take a few minutes.',
  OTHER: 'This can take a few minutes.',
};

export const KANBAN_BOARD_FILTER_CATEGORIES = {
  SOURCE: 'figma_file_key',
  PRIORITY: 'priority',
  ASSIGNEE: 'assignee',
  LABEL: 'label',
  DATE_RANGE: 'source_created_at',
};

export const KANBAN_BOARD_FILE_TYPE_NAMES = {
  FIGMA: 'figma',
  TRANSCRIPTION: 'Video Call Transcript',
  SLACK: 'Slack',
  NOTES: 'Meeting Notes',
  GOOGLE_DOCS: 'Google Docs',
  OTHER_APPS: 'Other Apps',
};

export const KANBAN_BOARD_IMPORT_DESCRIPTION = {
  [KANBAN_BOARD_FILE_TYPE_NAMES.FIGMA]:
    'To convert comments into actionable tasks, please paste your Figma file link here.',
  [KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION]:
    'To convert your feedback transcripts from Zoom, Loom, or notes into actionable tasks, simply paste them below.',
  [KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS]:
    'The file was successfully imported! Review the note.',
  [KANBAN_BOARD_FILE_TYPE_NAMES.OTHER_APPS]:
    'To convert your notes into actionable tasks, simply paste them below.',
};

export const KANBAN_BOARD_DEFAULT_COLS = [
  {
    name: 'Backlog',
    status: 1,
  },
  {
    name: 'To Do',
    status: 2,
  },
  {
    name: 'In Progress',
    status: 3,
  },
  {
    name: 'Done',
    status: 4,
  },
];

export const KANBAN_BOARD_NOTIFICATION_DESCRIPTION = {
  1: 'Tasks generated automatically from Figma comments',
  2: 'Tasks generated automatically from Transcript file',
  4: 'Tasks generated automatically from Meeting Notes',
  NO_TASKS: 'No new actionable comments found in the file.',
};

export const KANBAN_BOARD_LATEST_TASK_RESPONSE = {
  ALREADY_UPDATED: 'Already up to date!',
};

export const TASK_DETAILS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  LABEL: 'label',
  STATUS: 'status',
  PRIORITY: 'priority',
  INTERACTION_STATUS: 'interactionStatus',
};

export const INTERACTION_STATUS = {
  READ: 1,
  UNREAD: 2,
};

export const KANBAN_BOARD_TRANSCRIPT_LIMIT = 60000;

export const USER_TOKENS = {
  FIGMA: 'Figma',
  GOOGLE_DOCS: 'Google docs',
};

export const PLUGIN_SOURCE = {
  taskGen: 1,
  designReviewer: 2,
  chromeExtension: 3,
};

export const PLUGIN_SOURCE_IDENTIFIERS = {
  TASK_GEN: 'taskGen',
  DESIGN_REVIEWER: 'designReviewer',
  CHROME_EXTENSION: 'chromeExtension',
};
