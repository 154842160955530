import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faLink,
  faTrashCan,
  faEllipsisVertical,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faDownload,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import { DropdownMenu, DropdownButton } from 'Components/DropdownMenu';

import styles from './styles.module.scss';

export default function TopTools({
  file,
  figmaFile,

  className,
  onDeleteFile,
  onSwapFile,
  onChangeFullScreenState,
  fullScreenHandle,
  onDownloadFile,
  onAddFile,
  openUrlInNewTab,
}) {
  const [inProgress, setInProgress] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const topToolsRef = useRef();

  return (
    <div
      className={cn(
        styles['top-zoom-tools-wrapper'],
        'flex align-center',
        {
          hide: fullScreenHandle?.active,
        },
        className
      )}
      ref={topToolsRef}
    >
      {
        !!onDownloadFile && (
          <button
            className={cn(
              'text-white p-zero pointer',
              {
                [styles['download-in-progress']]: downloadInProgress,
              }
            )}
            disabled={inProgress}
            onClick={downloadFile}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        )
      }
      {
        (!!figmaFile || openUrlInNewTab) && (
          <a
            className="text-white p-zero pointer flex flex-component-center"
            href={openUrlInNewTab || `https://www.figma.com/file/${figmaFile.file_key}/${figmaFile.file_name}?node-id=${figmaFile.node_ids[0]}`}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        )
      }
      {
        !!onSwapFile && (
          <button
            className="text-white p-zero pointer"
            disabled={inProgress}
            onClick={() => onSwapFile((figmaFile || file).uuid)}
          >
            <FontAwesomeIcon icon={faLink} />
          </button>
        )
      }
      {
        !!(onDeleteFile || onChangeFullScreenState || onAddFile) && (
          <DropdownMenu
            destroyPopupOnHide
            getPopupContainer={() => topToolsRef?.current?.parentElement}
            placement="bottomRight"
            overlay={
              <Menu
                className="p-zero"
                items={
                  [
                    {
                      className: 'p-zero',
                      label: (
                        <DropdownButton
                          icon={<FontAwesomeIcon icon={faTrashCan} />}
                          text="Delete"
                        />
                      ),
                      key: 'Delete',
                      onClick: !!onDeleteFile && deleteFile,
                    },
                    {
                      className: 'p-zero',
                      label: (
                        <DropdownButton
                          icon={
                            fullScreenHandle?.active
                              ? <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                              : <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                          }
                          text={
                            fullScreenHandle?.active
                              ? 'Exit Full Screen'
                              : 'Full Screen'
                          }
                        />
                      ),
                      key: 'Full Screen',
                      onClick: onChangeFullScreenState,
                    },
                    {
                      className: 'p-zero',
                      label: (
                        <DropdownButton
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          text="Add Files"
                        />
                      ),
                      key: 'Add Files',
                      onClick: !!onAddFile && addFile,
                    },
                  ]
                    .filter(({ onClick }) => !!onClick)
                }
              />
            }
            trigger={['click']}
            disabled={inProgress}
          >
            <button
              className="text-white p-zero pointer"
              disabled={inProgress}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>
          </DropdownMenu>
        )
      }
    </div>
  );

  async function deleteFile() {
    try {
      setInProgress(true);

      await onDeleteFile((figmaFile || file).uuid);
    }
    finally {
      setInProgress(false);
    }
  }

  async function downloadFile() {
    try {
      setDownloadInProgress(true);

      await onDownloadFile((figmaFile || file).uuid);
    }
    finally {
      setDownloadInProgress(false);
    }
  }

  async function addFile() {
    try {
      setInProgress(true);

      await onAddFile((figmaFile || file).uuid);
    }
    finally {
      setInProgress(false);
    }
  }
}

TopTools.propTypes = {
  file: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
  }),
  figmaFile: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    node_ids: PropTypes.array.isRequired,
    file_key: PropTypes.string.isRequired,
    file_name: PropTypes.string.isRequired,
  }),

  className: PropTypes.string,
  onDeleteFile: PropTypes.func,
  onSwapFile: PropTypes.func,
  onChangeFullScreenState: PropTypes.func,
  fullScreenHandle: PropTypes.object,
  onDownloadFile: PropTypes.func,
  onAddFile: PropTypes.func,
  openUrlInNewTab: PropTypes.string,
};
