import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';
import Field from 'Components/questionnaireComponents/Field';

import { isURL } from 'utlis/validators';

export default function LinkForm({
  onSave,

  resetAfterSave,
  initialValue,
  placeholder,
  onCancel,
  wrapperClassName,

  ...props
}) {
  const [value, setValue] = useState(initialValue);

  return (
    <div className={wrapperClassName}>
      <Field
        className="text-weight-500"
        placeholder={placeholder}
        initialValue={initialValue}
        onKeyUp={({ target: { innerText } }) => setValue(innerText)}
        onChange={setValue}

        {...props}
      />
      <Button
        isText
        className={cn(
          'ml-md-lg-important',
          {
            'text-pacificBlue': isURL(value),
            'text-ironsideGrey': !isURL(value),
          }
        )}
        onClick={() => {
          onSave(value);

          if (resetAfterSave) {
            setValue('');
          }
        }}
        disabled={!isURL(value)}
      >
        {
          initialValue
            ? 'SAVE'
            : '+ ADD MORE'
        }
      </Button>
      {
        !!onCancel && (
          <Button
            isText
            className="ml-md-lg-important text-pacificBlue"
            onClick={onCancel}
          >
            CANCEL
          </Button>
        )
      }
    </div>
  );
}

LinkForm.propTypes = {
  onSave: PropTypes.func.isRequired,

  initialValue: PropTypes.string,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  resetAfterSave: PropTypes.bool,
  onCancel: PropTypes.func,
};
