import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { selectProject } from 'Redux/Actions/projectActions';

import ReadonlyService from 'services/readonly_service';

import { setReadonlyAuthorizationToken } from 'utlis/http';

export default function LandingReadonly() {
  const urlParams = useParams();

  const dispatch = useDispatch();

  useEffect(
    () => {
      getProjectReadOnlyDetail(urlParams.readonlyUUID);
    },
    []
  );

  return (
    <div className="flex flex-component-center px-lg py-huge mx-xs">
      <Spin size="large" className="mr-md" />
      <p className="text-20 m-zero">
        Please wait, redirecting...
      </p>
    </div>
  );

  async function getProjectReadOnlyDetail(readonlyUUID) {
    const readonlyProjectData = await ReadonlyService.getReadonlyProjectObj(readonlyUUID);

    setReadonlyAuthorizationToken(readonlyProjectData.encrypted);

    const projectData = await dispatch(selectProject(readonlyProjectData.plain.proj_uuid));

    window.location = `/readonly/${readonlyUUID}/project-details/${projectData.slug}/workspace`;
  }
}
