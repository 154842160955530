import { useState } from 'react';
import { useSelector } from 'react-redux';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';

import { ReactComponent as AddFilesThumbnail } from 'assets/AddFileIconBG.svg';

import { Button } from 'Components/Button';
import { AddLinkToFileForm } from 'Components/FilesList/components/AddFile/components/AddLinkToFileForm';
import { Modal } from 'Components/Modal';

import FigmaServices from 'services/figma_file';
import FigmaFile from 'services/figma_file';
import { getFigmaCommentTasks } from 'services/tasks';

import { TaskList } from './components/TaskList';

import styles from './styles.module.scss';

export function Feed() {
  const selectedProject = useSelector(state => state.selectedProject);
  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);
  const userUUID = useSelector(state => state.userProfile?.uuid);

  const [addFigmaFileModal, setAddFigmaFileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [figmaFileKey, setFigmaFileKey] = useState('');
  const [comments, setComments] = useState(null);

  if (isLoading) {
    return (
      <div className="flex align-center just-center pt-lg">
        <div className="text-center">
          <Spin size="large" />
          <p>
            Fetching all comments from file...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.workspace__allThumbnails}>
      {
        comments
          ? <TaskList figmaFileKey={figmaFileKey} comments={comments} />
          : (
            <div className={styles['empty-file-container']}>
              <AddFilesThumbnail width={118} height={118} />
              <div className={styles['main-heading']}>
                Import a Figma page to triage comments.
              </div>
              <div className={styles['description']}>
                No comments yet to triage
              </div>
              <Button className={styles['add-file-button']} onClick={() => setAddFigmaFileModal(true)}>
                <FontAwesomeIcon className="mr-8" icon={faGear} size="lg" />
                {' '}
                Manage files
              </Button>
            </div>
          )
      }
      <Modal
        title={
          <p className="text-20 text-weight-500 letter-xs pt-md ml-16">
            Paste Figma link below
          </p>
        }
        destroyOnClose
        className={styles['add-figma-file-modal']}
        visible={!!addFigmaFileModal}
        onCancel={() => setAddFigmaFileModal(null)}
        footer={null}
        centered
      >
        <AddLinkToFileForm.Figma
          onAddFile={handleAddFigmaFile}
          onHandleConnect={() => FigmaServices.openFigmaOauth(selectedProject.uuid)}
          appToken={figmaAppToken}
        />
      </Modal>
    </div>
  );

  async function handleAddFigmaFile(figmaFileUrl) {
    setIsLoading(true);
    const figmaFileInfo = FigmaFile.getFigmaFileInfoFromFigmaUrl(figmaFileUrl);

    try {
      const { data } = await getFigmaCommentTasks(figmaFileInfo.fileKey, figmaAppToken?.access_token, userUUID);

      if (data.comments) {
        setComments(data.comments);
      }
      setFigmaFileKey(figmaFileInfo.fileKey);
    }
    finally {
      setAddFigmaFileModal(false);
      setIsLoading(false);
    }
  }
}
