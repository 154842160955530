import axios from 'axios';

import http from 'utlis/http';
import actionTypes from '../constants';
import { infoReactToastify, errorReactToastify } from '../../utlis/toasts';

export const updateWorkspaceFiles = nextUrl => async dispatch => {
  try {
    let next = '';
    const config = {
      method: 'get',
      url: nextUrl,
    };

    axios(config)
      .then(response => {
        next = response?.data?.next;
        dispatch({
          type: actionTypes.UPDATE_WORKSPACE_FILES,
          payload: response?.data?.results,
        });
        if (next) {
          dispatch(updateWorkspaceFiles(next));
        }
      })
      .catch(error => {
        console.log(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getWorkspaceFiles = (
  authUser,
  selectedProject,
  milestone,
  setFilesCount,
  prevUrl,
  setPrevUrl,
  nextUrl,
  setNextUrl,
  urlIdentifier,
) => async dispatch => {
  try {
    const fetchUrl =
      urlIdentifier === 'previous' && prevUrl
        ? prevUrl
        : urlIdentifier === 'next' && nextUrl
        ? nextUrl
        : null;
    const url = fetchUrl
      ? fetchUrl
      : `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/files/?milestone_id=${milestone?.id}`;
    const config = {
      method: 'get',
      url: url,
    };

    http(config).then(response => {
      dispatch({
        type: actionTypes.GET_WORKSPACE_FILES,
        payload: response?.data?.results,
      });
      if (setFilesCount) {
        setFilesCount(response?.data?.count);
      }

      if (setPrevUrl || setNextUrl) {
        if (response?.data?.previous) {
          setPrevUrl(response?.data?.previous);
        }
        if (response?.data?.next) {
          setNextUrl(response?.data?.next);
        }
      }

      if (urlIdentifier === 'accumulateFiles') {
        dispatch(updateWorkspaceFiles(response?.data?.next));
      }
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeWorkspaceFiles = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_WORKSPACE_FILES, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const downloadWorkspaceFiles = selectedProject => async dispatch => {
  try {
    const config = {
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/download_files/`,
    };

    http(config)
      .then(function(response) {
        if (response.data.download_link) {
          const newWindow = window.open(
            response.data.download_link,
            '_blank',
            'noopener,noreferrer',
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        } else {
          infoReactToastify(response?.data?.message);
        }
      })
      .catch(function(error) {
        errorReactToastify(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getMilestoneTipStatus = (
  milestoneId,
  setIsTooltipClose,
  setIsCompleteTooltipOpen,
) => async dispatch => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/${milestoneId}/tooltip/`;
    http
      .get(url)
      .then(response => {
        if (setIsTooltipClose) {
          setIsTooltipClose(response?.data?.is_completed);
        }
        setIsTooltipClose(response?.data?.is_closed);
        if (setIsCompleteTooltipOpen) {
          setIsCompleteTooltipOpen(response?.data?.is_completed);
        }
      })
      .catch(error => {
        console.log(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const markMilestoneTipClosed = (
  milestoneId,
  setIsTooltipClose,
) => async dispatch => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/${milestoneId}/tooltip/`;
    http
      .post(url)
      .then(() => {
        dispatch(getMilestoneTipStatus(milestoneId, setIsTooltipClose));
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};
