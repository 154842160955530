import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import addCollaborator from 'assets/AddCollaborator.svg';
import { ReactComponent as CheckMark } from 'assets/check_icon.svg';
import clock from 'assets/clock_icon.svg';
import invitedIcon from 'assets/invited_icon.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

// eslint-disable-next-line max-len
import { CollaboratorEmail } from 'Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/components/TaskDetails/components/CollaboratorEmail';

import styles from './styles.module.scss';

export function AssigneeBar({ email, name, image, handleUserClick, isSelected, isAccepted, isOwner }) {
  const userDetails = useSelector(state => state.userStore);

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  return (
    <>
      <button
        className={cn(
          styles['assignee-bar'],
          { [styles['selected-assignee']]: isSelected && isSelected(name, email) }
        )}
        onClick={handleBarClick}
      >
        <img
          src={image || (name ? defaultUserAvatar : invitedIcon)}
          alt="user icon"
          className={styles['user-icon']}
        />
        <div className={styles['user-details']}>
          <p className={styles['name']}>
            {name}
            {' '}
            {userDetails.email === email && (
              <span className={styles['you']}>
                (you)
              </span>
            )}
          </p>
          <p className={cn(styles['email'], { [styles['bigger-font']]: !name })}>
            {email}
          </p>
        </div>
        {isOwner && (
          <p className={styles['pending']}>
            owner
          </p>
        )}
        {isAccepted && (
          <p className={styles['pending']}>
            <CheckMark alt="check icon" />
            accepted
          </p>
        )}
        {!isAccepted && !name && (
          <p className={styles['pending']}>
            <img width={10} height={10} src={clock} alt="clock icon" />
            pending invite
          </p>
        )}
        {!email && (
          showInviteUserModal
            ? (
              <FontAwesomeIcon
                className={cn(styles['close-icon'], styles['margin-left'])}
                icon={faXmark}
                onClick={() => setShowInviteUserModal(false)}
              />
            )
            : (
              <img
                src={addCollaborator}
                alt="invite person"
                className={styles['margin-left']}
                onClick={() => setShowInviteUserModal(true)}
              />
            )
        )}
        {!showInviteUserModal && (
          <div
            className={cn(
              styles['check-container'],
              {
                [styles['margin-left']]: name && email && !isAccepted && !isOwner,
              }
            )}
          >
            {isSelected && isSelected(name, email) && <CheckMark alt="check mark" className={styles['check-icon']} />}
          </div>
        )}
      </button>
      {showInviteUserModal && (
        <CollaboratorEmail
          fetchFigmaCollaborators
          reducedMargin
          actionAfterInvite={() => setShowInviteUserModal(false)}
        />
      )}
    </>
  );

  function handleBarClick() {
    if (email && handleUserClick) {
      handleUserClick({
        full_name: name,
        email: email,
        profile_photo: image,
      });
    }
    if (!email && !showInviteUserModal) {
      setShowInviteUserModal(true);
    }
  }
}

AssigneeBar.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isSelected: PropTypes.func,
  handleUserClick: PropTypes.func,
  isAccepted: PropTypes.bool,
  isOwner: PropTypes.bool,
};
