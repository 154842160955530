import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { AddLinkToFileForm } from 'Components/FilesList/components/AddFile/components/AddLinkToFileForm';
import { Modal } from 'Components/Modal';

import FigmaServices from 'services/figma_file';

import {
  KANBAN_BOARD_FILE_TYPE_NAMES,
  KANBAN_BOARD_IMPORT_DESCRIPTION,
  KANBAN_BOARD_TRANSCRIPT_LIMIT,
} from 'utlis/constants';

import { AddGoogleDocs } from './components/AddGoogleDocs';
import { AddSlackContent } from './components/AddSlackContent';
import { EmptyProjectModal } from './components/EmptyProjectModal';
import { ImportFileContent } from './components/ImportFileContent';

import styles from './styles.module.scss';

export function ManageFilesModal({
  closeModal,
  prevPopup,
  handleTaskDetailsPopup,
  showManageFilesModal,
  transcriptFileDetails,
  notesFileDetails,
  handleAddFigmaFile,
  handleAddSlackThread,
  handleAddTranscription,
  handleAddNotes,
  showTasksOfFiles,
  switchOnChange,
  showRemoveFileModal,
  openCustomTaskModal,
}) {
  const selectedProject = useSelector(state => state.selectedProject);
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);
  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);

  const [importOption, setImportOption] = useState('');
  const [transcriptDetails, setTranscriptDetails] = useState({});
  const [googleDocDetails, setGoogleDocDetails] = useState(null);
  const [readOnlyNotes, setReadOnlyNotes] = useState({});
  const [isFileFetched, setIsFileFetched] = useState(false);

  const [form] = Form.useForm();

  useEffect(
    () => {
      if (!importOption) {
        form.resetFields();
      }
    },
    [importOption]
  );

  useEffect(
    () => {
      if (transcriptFileDetails?.title) {
        setTranscriptDetails(transcriptFileDetails);
        setImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION);
      }
      else {
        setTranscriptDetails({});
        setImportOption('');
      }
    },
    [transcriptFileDetails]
  );

  useEffect(
    () => {
      if (notesFileDetails?.title) {
        setReadOnlyNotes(notesFileDetails);
        setImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.NOTES);
      }
      else {
        setReadOnlyNotes({});
        setImportOption('');
      }
    },
    [notesFileDetails]
  );

  useEffect(
    () => form.setFieldsValue({
      title: transcriptDetails.title || googleDocDetails?.title || readOnlyNotes?.title,
      content: transcriptDetails.content || googleDocDetails?.content || readOnlyNotes?.content,
    }),
    [transcriptDetails, googleDocDetails, readOnlyNotes]
  );

  useEffect(
    () => {
      setImportOption(
        localStorage.getItem('connectingToSlack') === 'true'
          ? KANBAN_BOARD_FILE_TYPE_NAMES.SLACK
          : localStorage.getItem('connectingToGoogleDocs') === 'true'
            ? KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS
            : ''
      );
    },
    []
  );

  const fileExistRule = [
    {
      /** if figma file was successfully fetched but still we have an error then it means file is already added
       * else it means that the user does not have permission for the file or it does not exist
        */
      message: isFileFetched
        ? 'To re-generate tasks for this file, please delete the file and add it again'
        : 'This file does not exist or you do not have access to it',

      // eslint-disable-next-line no-unused-vars
      validator: async (_, value) => {
        if (value) {
          const fileExist = await checkFileExist(value);

          if (fileExist) {
            return Promise.reject();
          }

          return Promise.resolve();
        }
      },
    },
  ];

  const manageFilesModalFooter = (
    <Button
      type="primary"
      htmlType="submit"
      className={styles['add-figma-file-button']}
      onClick={
        () => {
          form.validateFields().then(
            values => {
              handleCloseModal(); // close modal before adding file
              importOption === KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION
                ? handleAddTranscription(values)
                : handleAddNotes(values);
              setImportOption('');
            }
          );
        }
      }
    >
      IMPORT
    </Button>
  );

  const modalTitle = useMemo(
    () => {
      switch (importOption) {
        case KANBAN_BOARD_FILE_TYPE_NAMES.FIGMA:
          return 'Import Figma file';
        case KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION:
          return 'Import Video Call Transcript';
        case KANBAN_BOARD_FILE_TYPE_NAMES.NOTES:
          return 'Import Meeting Notes';
        case KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS:
          return 'Import Google Docs';
        case KANBAN_BOARD_FILE_TYPE_NAMES.OTHER_APPS:
          return 'Import Other Apps';
        case KANBAN_BOARD_FILE_TYPE_NAMES.SLACK:
          return 'Import Slack message thread';
        default:
          return 'Import file';
      }
    },
    [importOption]
  );

  const manageFilesModalTitle = useMemo(
    () => (
      <div className={styles['title-container']}>
        {
          importOption
            && (
              <Button
                isText
                className={styles['back-navigation-text']}
                onClick={
                  () => prevPopup ? handleTaskDetailsPopup() : handleBackNavigation()
                }
              >
                <FontAwesomeIcon className="mr-8" icon={faChevronLeft} />
                {prevPopup === 'taskDetailsPopup' ? 'Task Details' : 'Import file'}
              </Button>
            )
        }
        <span className={styles['modal-title']}>
          {modalTitle}
        </span>
      </div>
    ),
    [importOption, modalTitle, prevPopup]
  );

  const showImportFileDescription = useMemo(
    () => importOption === KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS
      ? !!googleDocDetails
      : !(
        transcriptDetails.title
          || readOnlyNotes.title
          || importOption === KANBAN_BOARD_FILE_TYPE_NAMES.SLACK
      ),
    [importOption, transcriptDetails.title, readOnlyNotes.title, googleDocDetails]
  );

  const showImportFields = useMemo(
    () => importOption === KANBAN_BOARD_FILE_TYPE_NAMES.OTHER_APPS
      || importOption === KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION
      || (importOption === KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS && googleDocDetails)
      || (importOption === KANBAN_BOARD_FILE_TYPE_NAMES.NOTES && readOnlyNotes),
    [importOption, googleDocDetails]
  );

  const isReadOnly = useMemo(
    () => !!transcriptDetails?.title || !!readOnlyNotes?.title,
    [transcriptDetails, readOnlyNotes]
  );

  const initialFormValues = useMemo(
    () => ({
      title: transcriptDetails.title || googleDocDetails?.title || readOnlyNotes?.title,
      content: transcriptDetails.content || googleDocDetails?.content || readOnlyNotes?.content,
    }),
    [transcriptDetails, googleDocDetails, readOnlyNotes]
  );

  return (
    <Modal
      title={savedFigmaFiles.length === 0 && !importOption ? '' : manageFilesModalTitle}
      width={importOption === KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION ? 800 : 600}
      destroyOnClose
      className={cn(styles['add-figma-file-modal'], {
        [styles['reduced-padding']]: savedFigmaFiles.length === 0 && !importOption,
      })}
      visible={!!showManageFilesModal}
      onCancel={handleCloseModal}
      closeIconProps={
        savedFigmaFiles.length === 0 && !importOption
          ? {
            style: {
              display: 'none',
            },
          }
          : ''
      }
      footer={showImportFields && !transcriptDetails?.title && !notesFileDetails?.title && manageFilesModalFooter}
      centered
    >
      {
        !importOption
          ? (
            savedFigmaFiles.length === 0
              ? (
                <EmptyProjectModal
                  openCustomTaskModal={openCustomTaskModal}
                  handleImportOption={handleImportOption}
                  closeModal={closeModal}
                />
              )
              : (
                <ImportFileContent
                  savedFigmaFiles={savedFigmaFiles}
                  showTasksOfFiles={showTasksOfFiles}
                  switchOnChange={switchOnChange}
                  showRemoveFileModal={showRemoveFileModal}
                  handleImportOption={handleImportOption}
                  showReadOnlyTranscriptModal={showReadOnlyTranscriptModal}
                />
              )
          )
          : (
            <div className={styles['import-file-container']}>
              {
                showImportFileDescription && (
                  <span className={styles['import-file-text']}>
                    {KANBAN_BOARD_IMPORT_DESCRIPTION[importOption]}
                  </span>
                )
              }
              {
                importOption === KANBAN_BOARD_FILE_TYPE_NAMES.FIGMA
                  && (
                    <AddLinkToFileForm.Figma
                      className={styles['figma-form-container']}
                      appToken={figmaAppToken?.access_token}
                      onAddFile={handleAddFigmaFile}
                      onHandleConnect={() => FigmaServices.openFigmaOauth(selectedProject.uuid)}
                      rules={fileExistRule}
                      submitButtonText="CONTINUE"
                    />
                  )
              }
              {
                importOption === KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS && !googleDocDetails
                  && (
                    <AddGoogleDocs setGoogleDocDetails={setGoogleDocDetails} />
                  )
              }
              {
                showImportFields
                  && (
                    <Form
                      form={form}
                      className={styles['transcription-form-container']}
                    >
                      <Form.Item
                        label="Title"
                        name="title"
                        initialValue={initialFormValues?.title}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Input
                          placeholder="Title"
                          readOnly={isReadOnly}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Content"
                        name="content"
                        initialValue={initialFormValues?.content}
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          {
                            max: KANBAN_BOARD_TRANSCRIPT_LIMIT,
                            message: `Content cannot exceed ${KANBAN_BOARD_TRANSCRIPT_LIMIT} characters`,
                          },
                        ]}
                      >
                        <Input.TextArea
                          showCount={!isReadOnly}
                          readOnly={isReadOnly}
                          placeholder="Paste any transcript or note here, and DesignPro will use AI to convert it into actionable tasks."
                        />
                      </Form.Item>
                    </Form>
                  )
              }
              {
                importOption === KANBAN_BOARD_FILE_TYPE_NAMES.SLACK
                  && <AddSlackContent handleAddSlackThread={handleAddSlackThread} />
              }
            </div>
          )
      }
    </Modal>
  );

  async function checkFileExist(figmaFileURL) {
    const figmaFileInfo = await FigmaServices.getFigmaFileInfoFromFigmaUrl(figmaFileURL);

    /** If a file is successfully fetched and there was no permission issue then we come here,
     * but if a user does not have access to figma file of file does not exist then this line of
     * code would not run. Based on this flag we show specific error message to the user
     */
    setIsFileFetched(true);

    return savedFigmaFiles.some(figmaFile => figmaFile.figma_file_key === figmaFileInfo.fileKey);
  }

  function handleImportOption(option) {
    if (option === KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION) {
      form.resetFields();
      setTranscriptDetails({});
    }
    setImportOption(option);
  }

  function showReadOnlyTranscriptModal(details) {
    setTranscriptDetails(details);
    setImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION);
  }

  function handleBackNavigation() {
    form.resetFields();
    setImportOption('');
    setGoogleDocDetails(null);
  }

  function handleCloseModal() {
    handleBackNavigation();
    closeModal();
  }
}

ManageFilesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prevPopup: PropTypes.string,
  handleTaskDetailsPopup: PropTypes.func.isRequired,
  showManageFilesModal: PropTypes.bool.isRequired,
  transcriptFileDetails: PropTypes.object,
  notesFileDetails: PropTypes.object,
  handleAddFigmaFile: PropTypes.func.isRequired,
  handleAddSlackThread: PropTypes.func.isRequired,
  handleAddTranscription: PropTypes.func.isRequired,
  handleAddNotes: PropTypes.func.isRequired,
  showTasksOfFiles: PropTypes.array.isRequired,
  switchOnChange: PropTypes.func.isRequired,
  showRemoveFileModal: PropTypes.func.isRequired,
  openCustomTaskModal: PropTypes.func.isRequired,
};
