import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import { IframeContainer } from 'Components/IframeContainer';
import TopTools from 'Components/ImageWithComments/components/TopTools';
import { VotingTools } from 'Components/ImageWithComments/components/VotingTools/index';
import { Button } from 'Components/Button';

import GDriveService from 'services/gdrive';
import FilesService from 'services/files';

import styles from './styles.module.scss';

export function GDriveFile({
  file,
  figmaFile,

  onDeleteFile,
  onSwapFile,
  onDownloadFile,
}) {
  const [isUserAuthorizedInGoogle, setIsUserAuthorizedInGoogle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);

  const selectedProject = useSelector(state => state.selectedProject);

  const fullScreenHandle = useFullScreenHandle();

  useEffect(
    () => {
      GDriveService.getGDriveAuthToken()
        .then(gDriveAuthToken => {
          setIsUserAuthorizedInGoogle(!!gDriveAuthToken);
          setIsLoading(false);
        })
        .catch(() => {
          setIsUserAuthorizedInGoogle(false);
          setIsLoading(false);
        });
    },
    [file?.gDriveId]
  );

  if (isLoading) {
    return (
      <div className={cn(styles['full-screen'], 'flex align-center just-center')}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <FullScreen
      className={styles['full-screen']}
      handle={fullScreenHandle}
    >
      {
        isUserAuthorizedInGoogle || pdfLink
          ? (
            <IframeContainer
              url={pdfLink || file.thumbnail_url}
              fullWidth
            />
          )
          : (
            <div
              className={cn(
                styles['full-screen'],
                'flex flex-column align-center just-center text-center text-20'
              )}
            >
              <p className="px-huge m-zero">
                Unfortunately, your browser blocks cookies for authorization in Google on third-party sites.
                Because of this, we cannot show you this content.
                <br />
                <br />
                To see this content, you can:
              </p>
              {
                [
                  'application/vnd.google-apps.document',
                  'application/pdf',
                  'application/vnd.google-apps.presentation',
                ].includes(file.mime_type)
                  && (
                    <Button
                      className="mt-md"
                      onClick={openPdf}
                      loading={isPdfLoading}
                      disabled={isPdfLoading}
                    >
                      Open as PDF
                    </Button>
                  )
              }
              <a
                href={file.thumbnail_url}
                target="_blank"
                rel="noreferrer"
                className="mt-md"
              >
                <Button.White className={styles.call__button}>
                  Open on Google Drive
                  <FontAwesomeIcon className="ml-8" icon={faArrowUpRightFromSquare} />
                </Button.White>
              </a>
              <div className="mt-md px-huge">
                Change your browser settings:
                <br />
                <b>Safari</b>: Turn off Settings {'>'} Privacy {'>'} Prevent cross-site tracking
                <br />
                <b>FireFox</b>: Use in Settings {'>'} Privacy & Security {'>'} Enhanced
                Tracking Protection “Custom” option
                <br />
                <b>Google Chrome</b>: Use in Settings {'>'} Privacy and security {'>'} Cookies
                and other site data “Allow all cookies” or “Block third-party cookies in Incognito” option
              </div>
            </div>
          )
      }
      <VotingTools
        file={file}
        className={styles['vote-tools']}
        fileType={VotingTools.fileTypes.gDriveFiles}
      />
      <TopTools
        file={file}
        figmaFile={figmaFile}
        onDeleteFile={onDeleteFile}
        onSwapFile={onSwapFile}
        onChangeFullScreenState={() => {
          if (fullScreenHandle.active) {
            fullScreenHandle.exit();
          }
          else {
            fullScreenHandle.enter();
          }
        }}
        fullScreenHandle={fullScreenHandle}
        onDownloadFile={onDownloadFile}
      />
    </FullScreen>
  );

  async function openPdf() {
    try {
      setIsPdfLoading(true);

      setPdfLink(
        file.url
          ? file.url
          : await FilesService.getDownloadLinkForGDriveFile(selectedProject.uuid, file.uuid)
      );
    }
    finally {
      setIsPdfLoading(false);
    }
  }
}

GDriveFile.propTypes = {
  file: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
    gDriveId: PropTypes.string.isRequired,
    mime_type: PropTypes.string.isRequired,
  }),
  figmaFile: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    node_ids: PropTypes.array.isRequired,
    file_key: PropTypes.string.isRequired,
    file_name: PropTypes.string.isRequired,
  }),

  onDeleteFile: PropTypes.func,
  onSwapFile: PropTypes.func,
  onDownloadFile: PropTypes.bool,
};
