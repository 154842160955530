import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import styles from './styles.module.scss';

export const SlackAuth = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'plugin-slack-connect',
      action: 'plugin_slack-connect-successful',
      label: 'user signed up for first time through plugin',
    });
  }, []);

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="You're all set!"
      description="Return to Figma and start turning Slack messages into actionable tasks."
    />
  );
};
