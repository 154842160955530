import { useMemo } from 'react';
import PropTypes from 'prop-types';
import reactEasyEmoji from 'react-easy-emoji';
import cn from 'classnames';

export function EmojiSymbol({ unicode, className, ...props }) {
  const emoji = useMemo(
    // eslint-disable-next-line compat/compat
    () => String.fromCodePoint(...unicode.split('-').map(el => Number(`0x${el}`))),
    [unicode]
  );

  return (
    <span
      className={cn(className)}

      {...props}
    >
      {
        isEmojiSupport(emoji)
          ? emoji
          : reactEasyEmoji(emoji)
      }
    </span>
  );

  function isEmojiSupport(currentEmoji) {
    const ctx = document.createElement('canvas').getContext('2d');

    ctx.canvas.width = ctx.canvas.height = 1;
    ctx.fillText(currentEmoji, -4, 4);

    return ctx.getImageData(0, 0, 1, 1).data[3] > 0; // Not a transparent pixel
  }
}

EmojiSymbol.propTypes = {
  unicode: PropTypes.string.isRequired,

  className: PropTypes.string,
};
