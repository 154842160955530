import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import AllCommentsList from 'Components/AllCommentsList';
import { Button } from 'Components/Button';
import { EmbeddedPanel } from 'Components/EmbeddedPanel';
import Experts from 'Components/Experts';
import Notes from 'Components/Notes';
import Topics from 'Components/Topics';

import {
  setAppState,
  setShowAllComments,
  setShowExperts,
  setShowNotes,
  setShowTopics,
} from 'Redux/Actions/appActions';
import { loadComments } from 'Redux/Actions/commentActions';

import { MILESTONES_TYPES } from 'utlis/constants';

export function WorkspaceToolbar({
  withComments,
  withNotes,
  withExperts,
  withTopics,
  canUserSendPinComments,

  ...props
}) {
  const { projectSlug, milestoneId } = useParams();

  const [openLastTab, setOpenLastTab] = useState(() => setShowAllComments);

  const { showAllComments, showNotes, showExperts, showTopics } = useSelector(state => state.app);
  const selectedMilestone = useSelector(state => state.milestonesStore.dictionaryId?.[milestoneId]);
  const currentProject = useSelector(state => state.selectedProject);
  const currentUser = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  const isCollapsed = useMemo(
    () => !(showAllComments || showNotes || showExperts || showTopics),
    [showAllComments, showNotes, showExperts, showTopics]
  );
  const projectUsersList = useMemo(
    () => currentProject.uuid
      ? [
        currentProject.owner,
        ...currentProject.collaborators.map(({ user }) => user),
      ].map(user => (
        user.uuid === currentUser.uuid
          ? {
            ...user,
            full_name: `${user.full_name} (you)`,
          }
          : user
      ))
      : [],
    [currentProject]
  );

  useEffect(
    () => {
      const initTab = async () => {
        let appState = null;

        if (selectedMilestone?.id) {
          const commentsList = await loadAllComment();

          if (commentsList?.results.length === 0 && withTopics) {
            appState = {
              showAllComments: false,
              showNotes: false,
              showExperts: false,
              showTopics: true,
            };
          }
        }
        if (projectSlug) {
          dispatch(setAppState({
            isPinCommentMode: false,
            showAllComments: true,
            showNotes: false,
            showExperts: false,
            showTopics: false,
            isAnnotationMode: false,
            ...appState,
          }));
        }
      };

      initTab();
    },
    [projectSlug, selectedMilestone?.id, currentProject?.type]
  );

  return (
    <EmbeddedPanel
      isCollapsed={isCollapsed}
      onOpen={() => {
        dispatch(openLastTab(true));

        if (openLastTab == setShowAllComments) {
          dispatch(setAppState({
            isPinCommentMode: false,
            isAnnotationMode: false,
          }));
        }
      }}
      onClose={() => dispatch(setAppState({
        isPinCommentMode: false,
        showAllComments: false,
        showNotes: false,
        showExperts: false,
        showTopics: false,
        isAnnotationMode: false,
      }))}
      customHeader={
        <div
          className={cn(
            'flex flex-nowrap ml-sm-md',
            {
              hide: isCollapsed,
            }
          )}
        >
          {
            [
              {
                title: 'Comments',
                onClick: () => {
                  setOpenLastTab(() => setShowAllComments);
                  dispatch(setAppState({
                    showAllComments: true,
                    isPinCommentMode: false,
                    isAnnotationMode: false,
                    showNotes: false,
                    showExperts: false,
                    showTopics: false,
                  }));
                },
                selected: showAllComments,
                hide: !withComments,
              },
              {
                title: 'Notes',
                onClick: () => {
                  setOpenLastTab(() => setShowNotes);
                  dispatch(setAppState({
                    showAllComments: false,
                    isPinCommentMode: false,
                    isAnnotationMode: false,
                    showNotes: true,
                    showExperts: false,
                    showTopics: false,
                  }));
                },
                selected: showNotes,
                hide: !withNotes,
              },
              {
                title: 'Experts',
                onClick: () => {
                  setOpenLastTab(() => setShowExperts);
                  dispatch(setAppState({
                    showAllComments: false,
                    isPinCommentMode: false,
                    showNotes: false,
                    showExperts: true,
                    showTopics: false,
                  }));
                },
                selected: showExperts,
                hide: !withExperts || selectedMilestone?.type !== MILESTONES_TYPES.designReview,
              },
              {
                title: 'Topics',
                onClick: () => {
                  setOpenLastTab(() => setShowTopics);
                  dispatch(setAppState({
                    showAllComments: false,
                    isPinCommentMode: false,
                    isAnnotationMode: false,
                    showNotes: false,
                    showExperts: false,
                    showTopics: true,
                  }));
                },
                selected: showTopics,
                hide: !withTopics,
              },
            ].map(({ title, onClick, selected, hide }) => (
              <Button
                key={title}
                isText
                onClick={onClick}
                className={cn(
                  'text-weight-500 text-sm mr-24-important',
                  {
                    'text-black': selected,
                    'text-black-opacity-0-40': !selected,
                    hide,
                  }
                )}
              >
                {title}
              </Button>
            ))
          }
        </div>
      }
      {...props}
    >
      {
        showAllComments && (
          <AllCommentsList
            selectedMilestone={selectedMilestone}
            keepPrevComments
            canUserSendPinComments={canUserSendPinComments}
          />
        )
      }
      {
        showNotes && (
          <Notes
            selectedMilestone={selectedMilestone}
            mentionsList={projectUsersList}
          />
        )
      }
      {
        showExperts && (
          <Experts />
        )
      }
      {
        showTopics && (
          <Topics selectedMilestone={selectedMilestone} />
        )
      }
    </EmbeddedPanel>
  );

  function loadAllComment() {
    return dispatch(loadComments(
      currentProject.uuid,
      {
        milestoneId: selectedMilestone?.id,
        keepPrevComments: true,
        limit: 1000,
      }
    ));
  }
}

WorkspaceToolbar.propTypes = {
  withComments: PropTypes.bool,
  withNotes: PropTypes.bool,
  withExperts: PropTypes.bool,
  withTopics: PropTypes.bool,
  canUserSendPinComments: PropTypes.bool,
};
