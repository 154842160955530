import qs from 'qs';

import { NOTIFICATION_TYPES } from './constants';
import { catchExceptions } from './crashlytics';

const isDevelopment = process.env.NODE_ENV == 'development';

const callbacks = {
  onMessageCallbacks: [],
};

if (isDevelopment) {
  localStorage.setItem('socketMessages', '[]');
  localStorage.setItem('onMessageCallbacks', '[]');
}

export function socketInit(token) {
  if (!token) {
    return;
  }
  const urlParams = {
    token,
  };
  const url = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/alerts/hub/connect/?${qs.stringify(urlParams)}`;

  if (!window.webSocket) {
    window.webSocket = {};

    setInterval(
      () => {
        // INFO: https://websockets.spec.whatwg.org/#dom-websocket-connecting
        if (window.webSocket.readyState >= WebSocket.CLOSING) {
          socketInit(token);
        }
      },
      20000
    );
  }

  try {
    window.webSocket = new WebSocket(url);
    window.webSocket.addEventListener('message', runAllSocketMessageCallbacks);
  }
  catch (e) {
    console.log(e);
  }
}

function runAllSocketMessageCallbacks({ data: socketPayload }) {
  try {
    const { message } = JSON.parse(socketPayload);

    if (message?.data?.notification_type) {
      const { data: { notification_type } } = message;

      if (isDevelopment) {
        localStorage.setItem(
          'socketMessages',
          JSON.stringify(
            []
              .concat(JSON.parse(localStorage.getItem('socketMessages') || '[]'))
              .concat(message)
          )
        );
      }

      callbacks.onMessageCallbacks.forEach(
        ({ cb, options: { triggeredNotificationTypes, excludeNotificationTypes } }) => {
          if (
            triggeredNotificationTypes.includes(notification_type)
              && !excludeNotificationTypes.includes(notification_type)
          ) {
            cb(message);
          }
        }
      );
    }
  }
  catch (error) {
    catchExceptions(error);
  }
}

export function onSocketMessage(cb, options = {}) {
  const onSocketMessageCbObj = {
    cb,
    options: {
      triggeredNotificationTypes: typeof options == 'string' || Array.isArray(options)
        ? [].concat(options)
        : (
          options.triggeredNotificationTypes
            ? [].concat(options.triggeredNotificationTypes)
            : Object.values(NOTIFICATION_TYPES)
        ),
      excludeNotificationTypes: options.excludeNotificationTypes ? [].concat(options.excludeNotificationTypes) : [],
    },
  };

  callbacks.onMessageCallbacks.push(onSocketMessageCbObj);

  if (isDevelopment) {
    localStorage.setItem('onMessageCallbacks', JSON.stringify(callbacks.onMessageCallbacks));
  }

  return () => {
    callbacks.onMessageCallbacks.splice(callbacks.onMessageCallbacks.indexOf(onSocketMessageCbObj), 1);

    if (isDevelopment) {
      localStorage.setItem('onMessageCallbacks', JSON.stringify(callbacks.onMessageCallbacks));
    }
  };
}
