import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';

import AddNewProjectModal from 'Components/AddNewProjectModal';
import { Button } from 'Components/Button';
import { NavigationHeaderItem } from 'Components/NavigationHeaderItem';

import {
  removeAllCommentsFromReduxStore,
} from 'Redux/Actions/commentActions';
import { removeContractDocument } from 'Redux/Actions/contractActions';
import { removeAllContainers } from 'Redux/Actions/designConceptsAction';
import {
  removeFeedFiles,
  removeMilestoneObject,
  removeMilestoneStore,
} from 'Redux/Actions/FeedActions';
import {
  removeFigmaFiles,
  removePostsNextBatchUrl,
  removeSelectedFigmaFile,
  removeWorkflowSteps,
} from 'Redux/Actions/figmaActions';
import { removeKanbanBoard } from 'Redux/Actions/kanbanBoardActions';
import { getProjectStatusCountAction, removeSelectedProjects } from 'Redux/Actions/projectActions';
import { setUserProfile } from 'Redux/Actions/userActions';
import { removeWorkspaceFiles } from 'Redux/Actions/workspaceActions';

import { PROJECT_STATUS } from 'utlis/constants';
import { checkingUserType } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export const ProjectListing = () => {
  const { projectType } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector(state => state.userStore);
  const selectedProject = useSelector(state => state.selectedProject);
  const userProfile = useSelector(state => state.userProfile);

  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  useEffect(() => {
    if (Object.keys(selectedProject).length > 0) {
      dispatch(removeWorkflowSteps());
      dispatch(removeSelectedProjects());
      dispatch(removeKanbanBoard());
      dispatch(removeSelectedFigmaFile());
      dispatch(removeFigmaFiles());
      dispatch(removeAllCommentsFromReduxStore());
      dispatch(removePostsNextBatchUrl());
      dispatch(removeContractDocument());
      dispatch(removeFeedFiles());
      dispatch(removeMilestoneObject());
      dispatch(removeWorkspaceFiles());
      dispatch(removeMilestoneStore());
      dispatch(removeAllContainers());
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    checkingUserType(userProfile, navigate);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, [userProfile, navigate]);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      dispatch(setUserProfile(authUser));
    }
  }, [authUser]);

  useEffect(() => {
    dispatch(getProjectStatusCountAction());
  }, []);

  const navigateToProjectType = screenName => navigate(screenName);

  return (
    <>
      <div className={cn('mt-52', styles['project-list-content'])}>
        <div className="flex space-between">
          <span className={styles['project-heading']}>
            Projects
          </span>
          {
            userProfile?.permissions?.isDesigner
              && (
                <Button
                  className={styles['create-project-btn']}
                  onClick={
                    () => setIsAddButtonClicked(true)
                  }
                >
                  CREATE PROJECT
                </Button>
              )
          }
        </div>
        <div className="flex align-center just-start mt-32">
          {Object.entries(PROJECT_STATUS).map(
            ([projectCategoryName, statusCode]) => (
              <NavigationHeaderItem
                key={'project-category-tabs-' + statusCode}
                isActive={(projectType || 'active') === projectCategoryName}
                onClick={() => navigateToProjectType(projectCategoryName)}
                variant="secondary"
              >
                {projectCategoryName}
              </NavigationHeaderItem>
            )
          )}
        </div>
      </div>
      <div className={styles['project-list-wrapper']}>
        <Outlet />
      </div>

      <AddNewProjectModal
        visible={isAddButtonClicked}
        onCancel={setIsAddButtonClicked}
      />
    </>
  );
};
