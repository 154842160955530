import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input } from 'antd';
import cn from 'classnames';

import clockIcon from 'assets/clock_icon.svg';
import searchIcon from 'assets/search_icon.svg';

import styles from './styles.module.scss';

export function TaskSearchBar({ onChangeSearchValue }) {
  const [inputValue, setInputValue] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [showRecentSearches, setShowRecentSearches] = useState(false);

  const autoCompleteRef = useRef();

  const searchOptions = useMemo(
    () => ([{
      label: 'Recent searches',
      options: recentSearches.map(title => ({
        value: title,
        label: (
          <div>
            <img className="mr-8" src={clockIcon} alt="clock-icon" />
            {title}
          </div>
        ),
      })),
    }]),
    [recentSearches]
  );

  return (
    <div ref={autoCompleteRef} className={styles['search-auto-complete']}>
      <AutoComplete
        open={showRecentSearches && !!recentSearches.length && !inputValue}
        onSelect={handleSearchSelect}
        options={searchOptions}
        getPopupContainer={() => autoCompleteRef?.current}
      >
        <Input
          className={cn('flex', styles['search-bar'])}
          prefix={<img src={searchIcon} alt="Search Icon" />}
          placeholder="Search task"
          onChange={({ target }) => handleInputChange(target.value)}
          onPressEnter={handleEnterPress}
          onFocus={() => setShowRecentSearches(true)}
          onBlur={() => setShowRecentSearches(false)}
          bordered={false}
          allowClear
        />
      </AutoComplete>
    </div>
  );

  function handleSearchSelect(value) {
    setInputValue(value);
    onChangeSearchValue(value);
    setShowRecentSearches(false);
  }

  function handleEnterPress() {
    if (inputValue) {
      setShowRecentSearches(false);
      onChangeSearchValue(inputValue);
      setRecentSearches([inputValue, ...recentSearches]);
    }
  }

  function handleInputChange(value) {
    if (!value) {
      setShowRecentSearches(true);
      onChangeSearchValue('');
    }
    setInputValue(value);
  }
}

TaskSearchBar.propTypes = {
  onChangeSearchValue: PropTypes.func.isRequired,
};
