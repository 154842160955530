import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import cn from 'classnames';

import DateFromNow from 'Components/DateFromNow';

import styles from './styles.module.scss';

export function CommentContainer({ containerTitle, commentDetails, transcriptionDetail, className }) {
  return (
    <div className={cn(styles['reply-container'], className)}>
      {
        commentDetails
          ? (
            <>
              <div className="mb-8 flex">
                <Avatar src={commentDetails.user?.img_url} size="small" />
                <span className={styles['user-name-text']}>
                  {commentDetails.user?.handle}
                </span>
                <span className={styles['posted-time-text']}>
                  <DateFromNow date={commentDetails.created_at} />
                </span>
              </div>
              <p className={styles['reply-text']}>
                {commentDetails.message}
              </p>
            </>
          )
          : (
            // Transcript Section
            <>
              <p className={styles['container-text']}>
                {containerTitle}
                <span className={cn(styles['posted-time-text'], styles['quote-timestamp'])}>
                  <DateFromNow date={transcriptionDetail?.created_at?.toDate()} />
                </span>
              </p>
              <p className={styles['reply-text']}>
                {transcriptionDetail?.transcript_section || transcriptionDetail?.notes_section}
              </p>
            </>
          )
      }
    </div>
  );
}

CommentContainer.propTypes = {
  containerTitle: PropTypes.string,
  commentDetails: PropTypes.shape({
    user: PropTypes.shape({
      img_url: PropTypes.string,
      handle: PropTypes.string,
    }),
    created_at: PropTypes.string,
    message: PropTypes.string,
  }),
  transcriptionDetail: PropTypes.object,
  className: PropTypes.string,
};
