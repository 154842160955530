import actionTypes from '../constants';

export const figmaReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FIGMA_TOKENS:
      return action.payload;
    case actionTypes.REMOVE_FIGMA_TOKENS:
      return {};
    default:
      return state;
  }
};

export const figmaFilesReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FIGMA_FILES:
      return action.payload;
    case actionTypes.REMOVE_FIGMA_FILES:
      return [];
    case actionTypes.DELETE_FEED_FILE:
      return state.filter(file => file.uuid !== action.payload.uuid);
    case actionTypes.FETCH_NEXT_POSTS_BATCH:
      return [...state, ...action.payload];
    case actionTypes.REMOVE_POSTS_NEXT_BATCH_URL:
      return '';
    default:
      return state;
  }
};

export const selectedFigmaFileReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_FIGMA_FILE:
      return action.payload;
    case actionTypes.REMOVE_SELECTED_FIGMA_FILE:
      return {};
    default:
      return state;
  }
};

export const figmaWorkflowSteps = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_WORKFLOW_STEPS:
      return action.payload;
    case actionTypes.REMOVE_WORKFLOW_STEPS:
      return [];
    default:
      return state;
  }
};
