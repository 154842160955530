import actionTypes from 'Redux/constants';

const appInitialState = {
  showAllComments: false,
  isPinCommentMode: false,
  hidePinCommentMode: false,
  isAnnotationMode: false,
  showNotes: false,
  showExperts: false,
  showTopics: false,
  isReadonly: false,
  milestoneProgressModalProps: {
    visible: false,
  },
};

export function appReducer(state = appInitialState, action) {
  switch (action.type) {
    case actionTypes.app.setAppState:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.app.setShowAllComments:
      return {
        ...state,
        showAllComments: action.payload,
      };
    case actionTypes.app.setIsPinCommentMode:
      return {
        ...state,
        isPinCommentMode: action.payload,
      };
    case actionTypes.app.setHidePinCommentMode:
      return {
        ...state,
        hidePinCommentMode: action.payload,
      };
    case actionTypes.app.setIsAnnotationMode:
      return {
        ...state,
        isAnnotationMode: action.payload,
      };
    case actionTypes.app.setShowNotes:
      return {
        ...state,
        showNotes: action.payload,
      };
    case actionTypes.app.setShowExperts:
      return {
        ...state,
        showExperts: action.payload,
      };
    case actionTypes.app.setShowTopics:
      return {
        ...state,
        showTopics: action.payload,
      };
    case actionTypes.app.setIsReadonly:
      return {
        ...state,
        isReadonly: action.payload,
      };
    case actionTypes.app.showMilestoneProgressModal:
    case actionTypes.app.hideMilestoneProgressModal:
      return {
        ...state,
        milestoneProgressModalProps: {
          ...state.milestoneProgressModalProps,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
