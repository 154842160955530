import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import designProLogo from 'assets/designProLogo.svg';
import googleDocsIcon from 'assets/GoogleDocsIcon.svg';

import { Button } from 'Components/Button';

import { setUserProfile } from 'Redux/Actions/userActions';

import GoogleDocsService from 'services/googleDocs';

import styles from './styles.module.scss';

export const ConnectGoogleDoc = () => {
  const dispatch = useDispatch();

  const [isConnecting, setIsConnecting] = useState(false);

  const authUser = useSelector(state => state.userStore);

  useEffect(
    () => {
      // function to call api to fetch user details
      if (isConnecting && authUser && authUser.uuid) {
        dispatch(setUserProfile(authUser));
      }
    },
    [isConnecting, authUser]
  );

  return (
    <>
      <div className={styles['icon-container']}>
        <img src={designProLogo} alt="slack icon" className={styles['icon']} />
        <FontAwesomeIcon icon={faPlus} />
        <img src={googleDocsIcon} alt="design pro logo" className={styles['icon']} />
      </div>
      <h2 className={styles['heading']}>
        Connect to Google Docs
      </h2>
      <p className={styles['text']}>
        To connect to your Google Docs, please grant DesignPro the read access in your Google account.
      </p>
      <Button className={styles['button']} onClick={handleConnectGoogleDocs}>
        {isConnecting ? 'Connecting to Google Docs...' : 'Connect to Google Docs'}
      </Button>
    </>
  );

  async function handleConnectGoogleDocs() {
    setIsConnecting(true);
    localStorage.setItem('connectingToGoogleDocs', true);
    const authCompleteURL = location.href;

    GoogleDocsService.getGoogleAuthLink(authCompleteURL)
      .then(link => {
        location.href = link;
      });
  }
};
