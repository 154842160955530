import chromeLogo from 'assets/chrome_logo.png';

import styles from './styles.module.scss';

export const InstallExtensionButton = () => (
  <a
    className={styles['button-container']}
    href="https://chromewebstore.google.com/detail/ai-website-reviewer/lhepaglfniknbbjllciljbooleolpdhe"
    target="_blank"
    rel="noreferrer"
  >
    <div className={styles['logo-container']}>
      <img
        className={styles['button-logo']}
        height={16}
        width={16}
        src={chromeLogo}
        alt="Install Button"
      />
    </div>
    <span
      className={styles['button-text']}
    >
      Add Chrome Extension
    </span>
  </a>
);
