import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import checkIcon from 'assets/check_icon.svg';
import searchIcon from 'assets/search_icon.svg';

import { KANBAN_BOARD_TAG_DEFAULT_OPTIONS } from 'utlis/constants';

import styles from './styles.module.scss';

export function LabelsDropdown({ taskLabels, handleChangeTag }) {
  const [inputValue, setInputValue] = useState('');

  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);

  // combine default options with unique group labels
  const options = useMemo(
    () => ([
      ...KANBAN_BOARD_TAG_DEFAULT_OPTIONS,
      ...extractUniqueGroupLabels(savedFigmaFiles),
    ]).sort((a, b) => a.localeCompare(b))
      .map(label => ({
        value: label,
      })),
    []
  );

  const filteredOptions = useMemo(
    () => options.filter(option => option.value.toLowerCase().includes(inputValue.toLowerCase())),
    [options, inputValue]
  );

  return (
    <div className={styles['labels-dropdown-container']}>
      <div className={styles['input-container']}>
        <div className={styles['search-bar-container']}>
          <img src={searchIcon} alt="Search Icon" />
          <input
            className="search-bar"
            placeholder="Search"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <div className={styles['options-container']}>
        {
          filteredOptions.length > 0
            ? (
              filteredOptions.map(
                option => (
                  <div
                    key={option.value}
                    className={cn(
                      styles['option-container'],
                      { [styles['option-selected']]: taskLabels.includes(option.value) }
                    )}
                    onClick={() => handleChangeTag([...taskLabels, option.value])}
                  >
                    <div className={styles['option']}>
                      {option.value}
                    </div>
                    {
                      taskLabels.includes(option.value) && (
                        <img src={checkIcon} alt="check-icon" />
                      )
                    }
                  </div>
                )
              )
            )
            : (
              <div className={styles['no-result']}>
                No labels found
              </div>
            )
        }
      </div>
    </div>
  );

  function extractUniqueGroupLabels(files) {
    const uniqueGroupLabels = Array.from(
      new Set(
        files
          .filter(file => file && file.group_labels)
          .flatMap(file => file.group_labels)
      )
    );

    return uniqueGroupLabels;
  }
}

LabelsDropdown.propTypes = {
  taskLabels: PropTypes.arrayOf(PropTypes.string),
  handleChangeTag: PropTypes.func.isRequired,
};
