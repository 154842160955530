import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import htmlReactParser from 'html-react-parser';

export default function TemplateString({ text = '', replaceFunc }) {
  const currentUser = useSelector(state => state.userProfile);
  const currentProject = useSelector(state => state.selectedProject);

  const rules = [
    {
      key: '{USER_NAME}',
      value: currentUser.firstName,
    },
    {
      key: '{LOWER_CASE_PROJECT_TYPE}',
      value: currentProject.type.toLowerCase(),
    },
    {
      key: '{PROJECT_TYPE}',
      value: currentProject.type,
    },
  ];

  return htmlReactParser(
    rules.reduce(
      (currentText, { key, value }) => currentText.replace(key, value),
      text
    ),
    {
      replace: replaceFunc,
    }
  );
}

TemplateString.propTypes = {
  text: PropTypes.string.isRequired,

  replaceFunc: PropTypes.func,
};
