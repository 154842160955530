import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export const DropdownMenu = ({ children, overlay, placement, trigger, disabled, overlayClassName, ...props }) => (
  <Dropdown
    overlayClassName={cn(styles.overlay_wrapper, overlayClassName)}
    overlay={overlay}
    placement={placement}
    trigger={trigger}
    disabled={disabled}
    {...props}
  >
    {children}
  </Dropdown>
);

DropdownMenu.propTypes = {
  overlayClassName: PropTypes.string,
  children: PropTypes.node,
  overlay: PropTypes.node,
  placement: PropTypes.string,
  trigger: PropTypes.array,
  disabled: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  trigger: ['click'],
};

export { DropdownButton } from './components/DropdownButton';
