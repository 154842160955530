import axios from 'axios';

import { ApiManager } from 'utlis/api_manager';

const googleDriveFilesApi = new ApiManager('https://www.googleapis.com/drive/v2/files', null);

export default class GDriveService {
  static getGDriveAuthToken() {
    return new Promise((res, rej) => {
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        })
          .then(googleAuth => res(googleAuth?.currentUser?.get()?.getAuthResponse(true)?.access_token))
          .catch(rej);
      });
    });
  }

  static async getGDriveFileInfo(gDriveFileId, params, config) {
    const gDriveAuthToken = await GDriveService.getGDriveAuthToken();

    return (await googleDriveFilesApi.getByKey(gDriveFileId, params, {
      headers: {
        Authorization: `Bearer ${gDriveAuthToken}`,
      },
      ...config,
    }))?.data;
  }

  static async downloadGDriveImage(downloadUrl, config) {
    const gDriveAuthToken = await GDriveService.getGDriveAuthToken();
    const blobData = (await axios.get(
      downloadUrl,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${gDriveAuthToken}`,
        },
        ...config,
      }
    ))?.data;

    return URL.createObjectURL(blobData); // eslint-disable-line compat/compat
  }
}
