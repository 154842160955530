import { forwardRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import moment from 'moment';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Milestone } from 'Components/Milestone';
import DatePicker from 'Components/questionnaireComponents/DatePicker';
import Field from 'Components/questionnaireComponents/Field';
import Select from 'Components/questionnaireComponents/Select';
import StepWrapper from 'Components/StepWrapper';

const Questionnaire = (
  {
    contractDocument,
    currentProject,
    currentUser,
    onSave,
    onSubmitQuestionnaire,
    isAnswerFromBrief,
    contractMilestone,

    className,
    onFieldsChange,
    isSaved,
    hasError,
    isSubmiting,
    isReadonly,
  },
  ref
) => {
  const [timerForSaveStart, setTimerForSaveStart] = useState(null);
  const [contractEndCondition, setContractEndCondition] = useState(contractDocument.contract_end_condition);

  const [form] = Form.useForm();

  const clients = [currentProject.owner, ...currentProject.collaborators.map(({ user }) => user)]
    .filter(({ user_type }) => user_type == 'Client');

  return (
    <Milestone
      title={contractMilestone?.name}
      selectedMilestone={contractMilestone}

      withShareButton={!isReadonly}
      additionalRightElement={
        <div
          className={cn(
            'flex align-center',
            {
              hide: !contractDocument?.uuid,
            }
          )}
        >
          <p
            className={cn(
              'text-xs text-silver my-zero',
              {
                hide: currentUser.permissions.isClient
                  || contractDocument.client_signature,
              }
            )}
          >
            {
              isSaved !== null
                ? (
                  isSaved
                    ? 'Saved'
                    : 'Saving ...'
                )
                : 'All changes are saved automatically'
            }
          </p>
          <p
            className={cn(
              'text-xs my-zero ml-sm',
              {
                hide: !hasError,
                'text-sunsetOrange': hasError,
              }
            )}
          >
            Please fill in all required fields
            <br />
            and correct any mistakes
          </p>
          <Button
            className={cn(
              'ml-sm',
              {
                hide: contractDocument.is_questionnaire_completed,
              }
            )}
            onClick={onSubmitQuestionnaire}
            disabled={isSubmiting || hasError}
          >
            {
              isSubmiting
                ? 'GENERATING ...'
                : 'GENERATE CONTRACT'
            }
          </Button>
        </div>
      }
    >
      <Form
        ref={ref}
        className={className}
        initialValues={{
          ...contractDocument,
          contract_start_date: contractDocument.contract_start_date
            ? moment(contractDocument.contract_start_date)
            : null,
        }}
        form={form}
        preserve
        onFieldsChange={!isReadonly && ((changedFields, allFields) => {
          if (onFieldsChange) {
            onFieldsChange(changedFields, allFields);
          }

          if (changedFields.length) {
            if (timerForSaveStart) {
              clearTimeout(timerForSaveStart);
            }

            setTimerForSaveStart(setTimeout(
              () => {
                onSave(form.getFieldValue());
              },
              1000
            ));
          }
        })}
        disabled={isReadonly}
      >
        <StepWrapper className="mb-lg mx-xs">
          <div className="pt-lg pb-xxxs px-xlg">
            <p className="text-black text-weight-300 text-20 letter-xxs">
              <span className="text-42 text-weight-500">
                Hi
                {' '}
                {currentUser.firstName}
                , let’s generate your contract!
              </span>
              <br />
              Please review the information below to generate your contract and start the project.
            </p>
            <div className="text-20 text-black letter-xs mt-24 px-lg">
              <p className="mt-zero mb-lg">
                I am a
                {' '}
                <span className="text-weight-500">
                  freelancer
                </span>
                {' '}
                based in the
                {' '}
                <span className="text-weight-500">
                  United States
                </span>
                .
              </p>
              <p className="mt-zero mb-lg">
                My Client
                {clients.length > 1 ? 's' : ''}
                {' '}
                is
                {' '}
                {clients.map(({ full_name }) => full_name).join(', ')}
                {' '}
                from the
                {' '}
                <Field
                  name={['contract_json', 'company_type']}
                  className="text-weight-600"
                  placeholder="startup"
                  required
                  disabled={isReadonly || (isAnswerFromBrief && isAnswerFromBrief('company_type'))}
                />
                {', '}
                <Field
                  name={['contract_json', 'company_name']}
                  className="text-weight-600"
                  placeholder="DesignPro"
                  required
                  disabled={isReadonly || (isAnswerFromBrief && isAnswerFromBrief('company_name'))}
                />
              </p>
              <p className="mt-zero mb-lg">
                I’m hired for a
                {' '}
                <span className="text-weight-500">
                  {contractDocument.project_type.toLowerCase()}
                </span>
                {' '}
                project.
              </p>
              <p className="mt-zero mb-lg">
                The services will include logo designs for
                {' '}
                <span className="text-weight-500">
                  <Select
                    name={['contract_json', 'product_available_in']}
                    className="text-weight-600"
                    placeholder="web, print, and mobile"
                    options={[
                      { value: 'web', label: 'web' },
                      { value: 'print', label: 'print' },
                      { value: 'mobile', label: 'mobile' },
                    ]}
                    mode="multiple"
                    required
                    disabled={isAnswerFromBrief && isAnswerFromBrief('product_available_in')}
                  />
                </span>
                .
              </p>
            </div>
          </div>
        </StepWrapper>
        <StepWrapper className="my-md mx-xs">
          <div className="pt-lg pb-xxxs px-xlg">
            <p className="text-black text-md text-42 text-weight-500">
              Payment and timeline
            </p>
            <div className="text-20 text-black letter-xs mt-24 px-lg">
              <p className="mt-zero mb-lg">
                The client will pay
                {' '}
                <Select
                  className="text-weight-600"
                  name="fee_type"
                  placeholder="flat fee, hourly"
                  options={[
                    { value: 'flat fee', label: 'a flat fee' },
                    { value: 'hourly', label: 'a hourly' },
                  ]}
                  required
                />
                {' '}
                of
                {' '}
                <Field
                  className="text-weight-600"
                  name="total_amount"
                  placeholder="$1000"
                  prefix="$"
                  type="number"
                  required
                  disabled={isReadonly}
                />
                {' '}
                with a desposit of
                {' '}
                <Field
                  className="text-weight-600"
                  name="deposit_amount"
                  placeholder="$500"
                  prefix="$"
                  type="number"
                  required
                  disabled={isReadonly}
                />
                .
              </p>
              <p className="mt-zero mb-lg">
                The remaining amount will be paid
                {' '}
                <Select
                  className="text-weight-600"
                  name="remaining_amount_payment_time"
                  placeholder="at the end of the project"
                  options={[
                    { value: 'at the end of the project', label: 'at the end of the project' },
                    { value: 'at the beginning of the project', label: 'at the beginning of the project' },
                  ]}
                  required
                />
                .
              </p>
              <p className="mt-zero mb-lg">
                The contract will start on
                {' '}
                <DatePicker
                  name="contract_start_date"
                  className="text-weight-600"
                  required
                  showToday={false}
                  allowClear={false}
                  suffixIcon={null}
                  placement="topLeft"
                  disabledDate={current => current && current < moment().subtract(1, 'd')}
                />
                {' '}
                and will end in
                {' '}
                <Select
                  name={['contract_json', 'deliverables_duration']}
                  className="text-weight-600"
                  options={[
                    { value: '3 days', label: '3 days' },
                    { value: '1 week', label: '1 week' },
                    { value: '1 month', label: '1 month' },
                    { value: '2 months', label: '2 months' },
                  ]}
                  required
                  placeholder="1 month"
                />
                .
              </p>
              <p className="mt-zero mb-lg">
                If either party ends the contract early, the client will pay
                {' '}
                <Select
                  className="text-weight-600"
                  name="contract_end_condition"
                  placeholder="a pro-rated amount"
                  options={[
                    { value: 'pro-rated', label: 'a pro-rated amount' },
                    { value: 'flat fee', label: 'a flat fee' },
                  ]}
                  required
                  onChange={setContractEndCondition}
                />
                {' '}
                {
                  contractEndCondition == 'flat fee'
                    ? (
                      <Fragment>
                        of
                        {' '}
                        <Field
                          className="text-weight-600"
                          name="flat_fee"
                          placeholder="$100"
                          prefix="$"
                          type="number"
                          required
                          disabled={isReadonly}
                        />
                      </Fragment>
                    )
                    : null
                }
                {' '}
                for the time worked.
              </p>
            </div>
          </div>
        </StepWrapper>
      </Form>
    </Milestone>
  );
};

export default forwardRef(Questionnaire);

Questionnaire.propTypes = {
  contractDocument: PropTypes.shape({
    uuid: PropTypes.string,
    fee_type: PropTypes.string.isRequired,
    deposit_amount: PropTypes.string.isRequired,
    total_amount: PropTypes.string.isRequired,
    remaining_amount_payment_time: PropTypes.string.isRequired,
    contract_start_date: PropTypes.string.isRequired,
    contract_end_condition: PropTypes.string.isRequired,
    flat_fee: PropTypes.string.isRequired,
    project_type: PropTypes.string.isRequired,
    complete_in: PropTypes.string.isRequired,
    display: PropTypes.array.isRequired,
    contract_json: PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      company_type: PropTypes.string.isRequired,
      product_available_in: PropTypes.string.isRequired,
      deliverables_duration: PropTypes.string.isRequired,
    }).isRequired,
    client_signature: PropTypes.string.isRequired,
    is_questionnaire_completed: PropTypes.bool.isRequired,
  }).isRequired,
  currentProject: PropTypes.shape({
    owner: PropTypes.object.isRequired,
    collaborators: PropTypes.object.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmitQuestionnaire: PropTypes.func.isRequired,
  contractMilestone: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,

  isAnswerFromBrief: PropTypes.func,
  className: PropTypes.string,
  onFieldsChange: PropTypes.func,
  isSaved: PropTypes.bool,
  hasError: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  isReadonly: PropTypes.bool,
};
