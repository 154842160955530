import PropTypes from 'prop-types';
import { Form } from 'antd';

import CollaboratorField from 'Components/CollaboratorField';

import { isEmail } from 'utlis/validators';

export function CollaboratorInput({ className, rules = [], ...props }) {
  return (
    <Form.Item
      className={className}
      {...props}
      rules={[
        {
          // eslint-disable-next-line no-unused-vars
          validator: (rule, { email }) => {
            if (!email) {
              return Promise.reject('*This field is required');
            }
            if (isEmail(email)) {
              return Promise.resolve();
            }

            return Promise.reject('*Invalid email');
          },
        },
        ...rules,
      ]}
    >
      <CollaboratorField
        {...props}
      />
    </Form.Item>
  );
}

CollaboratorInput.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.array,
  autoFocus: PropTypes.bool,
};
