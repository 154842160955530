import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Tabs, Upload } from 'antd';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';

import { isURL } from 'utlis/validators';

import { SelectFileOrLinkField } from './components/SelectFileOrLinkField';

import styles from './styles.module.scss';

export function SelectFileOrLinkModal({
  className,
  required,
  accept,
  multiple,
  maxCount,
  onAddFile,
  onAddLink,
  ...props
}) {
  const [showSelectFileOrLinkModal, setShowSelectFileOrLinkModal] = useState(false);
  const [countFilesToUpload, setCountFilesToUpload] = useState(0);
  const [countFinishedFiles, setCountFinishedFiles] = useState(0);
  const [activeTab, setActiveTab] = useState('upload');
  const [isModalDisabled, setIsModalDisabled] = useState(false);
  const [linkValue, setLinkValue] = useState('');
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <Form.Item
        className={className}
        {...props}
        rules={[{
          required: required,
          message: 'This field is required',
        }]}
      >
        <SelectFileOrLinkField
          openSelectFileOrLinkModal={() => setShowSelectFileOrLinkModal(true)}

          {...props}
        />
      </Form.Item>
      <Modal
        title={
          <span className="text-20 text-black-opacity-0-90 letter-xs">
            Add file below
          </span>
        }
        className={styles['select-file-or-link-modal']}
        destroyOnClose
        visible={showSelectFileOrLinkModal}
        onCancel={!isModalDisabled ? () => setShowSelectFileOrLinkModal(false) : null}
        footer={null}
        width={538}
        wrapClassName="mx-xsasdasdas"
        closable={!isModalDisabled}
      >
        <Tabs
          activeKey={activeTab}
          className={styles['upload-tabs']}
          onTabClick={!isModalDisabled ? setActiveTab : null}
          disabled={isModalDisabled}
        >
          <Tabs.TabPane tab="Upload" key="upload">
            <Upload
              accept={accept}
              multiple={multiple}
              maxCount={maxCount}
              className={styles['upload-button']}
              showUploadList={{
                showRemoveIcon: true,
              }}
              customRequest={customRequest}
              onChange={({ fileList }) => {
                const currentCountFilesToUpload = fileList.length;
                const currentCountFinishedFiles = fileList.reduce(
                  (current, file) => current + (file.status !== 'uploading' ? 1 : 0),
                  0
                );
                const fileListHasError = !!fileList.find((file => file.status === 'error'));

                setHasError(fileListHasError);
                setCountFilesToUpload(currentCountFilesToUpload);
                setCountFinishedFiles(currentCountFinishedFiles);
                setIsModalDisabled(true);

                if (currentCountFinishedFiles == currentCountFilesToUpload) {
                  setCountFilesToUpload(0);
                  setCountFinishedFiles(0);
                  setIsModalDisabled(false);

                  if (!(hasError || fileListHasError)) {
                    setShowSelectFileOrLinkModal(false);
                  }
                }
              }}
              progress={{
                strokeWidth: 3,
                format: percent => percent && `${Math.round(percent)}%`,
              }}
            >
              <Button.Smokey
                block
                disabled={isModalDisabled}
                loading={!!isModalDisabled}
              >
                {
                  isModalDisabled
                    ? `Uploading ${countFinishedFiles} of ${countFilesToUpload}`
                    : 'Upload file'
                }
              </Button.Smokey>
            </Upload>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Add Link" key="add_link">
            <Form.Item>
              <Input
                placeholder="paste link to image/pdf"
                value={linkValue}
                onChange={({ target: { value } }) => setLinkValue(value)}
                className={styles['link-input']}
              />
            </Form.Item>
            <div className="flex just-end mt-md">
              <Button
                disabled={!isURL(linkValue) || isModalDisabled}
                loading={!!isModalDisabled}
                onClick={async () => {
                  try {
                    setIsModalDisabled(true);

                    await onAddLink(linkValue);

                    setLinkValue('');
                    setShowSelectFileOrLinkModal(false);
                  }
                  finally {
                    setIsModalDisabled(false);
                  }
                }}
              >
                {
                  isModalDisabled
                    ? 'ADDING...'
                    : 'ADD LINK'
                }
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );

  function customRequest(...args) {
    setHasError(false);

    return onAddFile(...args);
  }
}

SelectFileOrLinkModal.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  isUploadSequentially: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  maxCount: PropTypes.number,
  onAddFile: PropTypes.func,
  onAddLink: PropTypes.func,
};
