import { useEffect, useMemo, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  faCompress,
  faExpand,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { ReactComponent as CenterIcon } from 'assets/center_focus_strong.svg';

import FunctionNotAvailable from 'Components/FunctionNotAvailable';

import {
  loadComments,
  removeCommentsFromReduxStore,
  selectComment,
  setNewCommentData,
} from 'Redux/Actions/commentActions';
import { getFigmaConnected } from 'Redux/Actions/figmaActions';

import FigmaFile from 'services/figma_file';

import { COMMENT_TYPES, NOTIFICATION_TYPES } from 'utlis/constants';
import { addChangeElementSizeListener } from 'utlis/resize_observer';
import { onSocketMessage } from 'utlis/socket';
import { infoReactToastify } from 'utlis/toasts';

import { Annotations } from './components/Annotations';
import CommentsBlock from './components/CommentsBlock';
import stylesCommentsBlock from './components/CommentsBlock/styles.module.scss';
import ImageComponent from './components/Image';
import TopTools from './components/TopTools';
import { VotingTools } from './components/VotingTools';

import stylesAllCommentsList from 'Components/AllCommentsList/styles.module.scss';

import styles from './styles.module.scss';

export default function ImageWithComments({
  file,
  figmaFile,
  selectedMilestone,

  className,
  isCommentMod,
  isAnnotationMode,
  index,
  canvasRefs,
  isThumbnail,
  onDownloadFile,
  getLinkForGDriveFile,
  onDeleteFile,
  onSwapFile,
  onAddFile,
  onChangeFiles,
  showToolsOnHover,
  checkLastFigmaFileVersion,
  isReadonly,

  ...props
}) {
  const { milestoneId } = useParams();

  const selectedProject = useSelector(state => state.selectedProject);
  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);
  const selectedComment = useSelector(state => state.commentsStore.selectedComment);
  const { commentsList, allCommentsList } = useSelector(state => {
    const allComments = state.commentsStore.allComments
      .filter(commentObj => (
        file
          ? commentObj.file == file.uuid
          : (
            figmaFile.node_ids.includes(commentObj?.figma_comment_detail?.node_id)
              && figmaFile.uuid === commentObj.file
          )
      ));

    return {
      commentsList: allComments.filter(commentObj => !commentObj?.content?.annotationData),
      allCommentsList: allComments,
    };
  });
  const newCommentData = useSelector(state => state.commentsStore.newCommentData);

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [zoomState, setZoomState] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [isFigmaFileOld, setIsFigmaFileOld] = useState(false);
  const [isCheckFigmaFileOld, setIsCheckFigmaFileOld] = useState(
    userProfile.permissions.isDesigner
      && !!figmaFile
      && checkLastFigmaFileVersion
  );
  const [isLoadLinkForGDriveFile, setIsLoadLinkForGDriveFile] = useState(false);

  const dispatch = useDispatch();

  const imageRef = useRef();
  const imageWrapperRef = useRef();
  const commentsListRefs = useRef({});
  const zoomRef = useRef();

  const annotationsCanvasRef = useRef();

  canvasRefs.current[index] = annotationsCanvasRef;

  const fullScreenHandle = useFullScreenHandle();

  const commentsType = file
    ? COMMENT_TYPES.COMMENT_INSIDE_GOOGLE_DRIVE_FILE
    : COMMENT_TYPES.COMMENT_INSIDE_FIGMA_FILE;
  const currentFile = {
    uuid: figmaFile?.uuid || file?.uuid,
    url: figmaFile?.svg_links?.[0] || file?.url,
    gDriveId: file?.gDriveId,
  };
  const hasFigmaToken = !!userProfile?.user_tokens?.find(({ provider }) => provider == 'Figma');

  const isUserAbleToComment = useMemo(
    () => isCommentMod && !(isImageLoading || isCheckFigmaFileOld || isFigmaFileOld || isReadonly),
    [isCommentMod, isImageLoading, isCheckFigmaFileOld, isFigmaFileOld, isReadonly]
  );
  const newCommentBlockPosition = useMemo(
    () => newCommentData?.file?.uuid == currentFile.uuid
      ? newCommentData?.coordinates
      : null,
    [newCommentData]
  );

  useEffect(
    () => {
      if (!isThumbnail) {
        document.addEventListener('click', clickOutside);

        return () => document.removeEventListener('click', clickOutside);
      }
    },
    []
  );

  useEffect(
    () => {
      document.addEventListener('keyup', itWasPressingEscape);

      return () => document.removeEventListener('keyup', itWasPressingEscape);
    },
    []
  );

  useEffect(
    () => {
      if (!isThumbnail && !isReadonly) {
        setIsCommentsLoading(true);
        setIsImageLoading(true);

        loadAllComment()
          .then(() => {
            setIsCommentsLoading(false);
          });
      }
      else {
        setIsCommentsLoading(false);
      }
    },
    [figmaFile?.uuid, file?.uuid]
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id, comment_file } }) => {
        if (milestoneId == milestone_id && currentFile.uuid == comment_file) {
          setIsCommentsLoading(true);

          dispatch(removeCommentsFromReduxStore(allCommentsList.map(({ uuid }) => uuid)));
          loadAllComment()
            .then(() => {
              setIsCommentsLoading(false);
            });
        }
      },
      [
        NOTIFICATION_TYPES.COMMENT_POST,
        NOTIFICATION_TYPES.COMMENT_EDIT,
        NOTIFICATION_TYPES.COMMENT_DELETE,
      ]
    ),
    [figmaFile?.uuid, file?.uuid, milestoneId, commentsList]
  );

  useEffect(
    () => {
      if (
        !isCommentsLoading
          && commentsList.length
          && commentsListRefs.current[selectedComment?.uuid]
      ) {
        commentsListRefs.current[selectedComment?.uuid].showCommentsList();
      }
    },
    [selectedComment?.uuid, Object.keys(commentsListRefs.current)]
  );

  useEffect(
    () => {
      if (!isImageLoading && !isCommentsLoading && zoomRef?.current) {
        centerZoomView();

        return addChangeElementSizeListener(
          zoomRef.current.instance.contentComponent.parentElement,
          () => requestAnimationFrame(centerZoomView)
        );
      }
    },
    [isImageLoading, isCommentsLoading, zoomRef]
  );

  useEffect(
    () => {
      if (figmaFile && userProfile.permissions.isDesigner && checkLastFigmaFileVersion && !isReadonly) {
        FigmaFile.getFigmaFileInfo(figmaFile.file_key, { figmaNodeIds: figmaFile.node_ids })
          .then(({ lastModified }) => {
            setIsCheckFigmaFileOld(false);

            if (lastModified > figmaFile.updated_at) {
              setIsFigmaFileOld(true);

              return FigmaFile.updateFigmaFile(
                selectedProject.uuid,
                figmaFile.uuid,
                `https://www.figma.com/file/${figmaFile.file_key}/${figmaFile.file_name}?node-id=${figmaFile.node_ids}`
              )
                .then(
                  () => onChangeFiles
                    ? onChangeFiles()
                    : null
                );
            }
          })
          .finally(() => {
            setIsFigmaFileOld(false);
          });
      }
    },
    [figmaFile?.uuid]
  );

  useEffect(
    () => {
      if (zoomRef?.current && (isAnnotationMode || selectedComment?.content?.annotationData)) {
        zoomRef.current?.resetTransform(0);
        zoomRef.current.centerView();
        changeZoomAndPosition(zoomRef?.current);
      }
    },
    [isAnnotationMode, selectedComment, zoomRef]
  );

  return (
    <div
      className={cn(
        styles['image-container'],
        'flex flex-component-center',
        {
          [styles['comments-loading']]: isCommentsLoading,
          [styles['check-figma-file-old']]: !isCommentsLoading && isCheckFigmaFileOld,
          [styles['new-figma-version-loading']]: !isCommentsLoading && isFigmaFileOld,
          [styles['is-load-image-from-g-drive']]: !isCommentsLoading && isLoadLinkForGDriveFile,
          [styles['is-thumbnail']]: isThumbnail,
        },
        className
      )}
      {...props}
    >
      <Droppable droppableId={currentFile.uuid}>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={current => {
              imageWrapperRef.current = current;
              droppableProvided.innerRef(current);
            }}
            {...droppableProvided.droppableProps}
            {...droppableProvided.dragHandleProps}
            className={cn(
              styles['image-wrapper'],
              {
                [styles['is-comment-mod']]: isUserAbleToComment,
              }
            )}
          >
            <FunctionNotAvailable
              className={cn({
                'flex flex-component-center': isThumbnail,
              })}
              cursorClassName={cn({
                [styles['comment-cursor']]: isUserAbleToComment,
              })}
              message="Login to Figma"
              isNotAvailable={!!(!hasFigmaToken && isUserAbleToComment && figmaFile?.uuid)}
              isNotAvailableCursor
            >
              <FullScreen handle={fullScreenHandle} className={styles['full-screen']}>
                <TransformWrapper
                  ref={zoomRef}
                  initialScale={1}
                  centerOnInit
                  wheel={{
                    disabled: false,
                    step: 0.1,
                  }}
                  panning={{
                    velocityDisabled: true,
                  }}
                  limitToBounds={false}
                  maxScale={50}
                  zoomAnimation={{
                    size: 0,
                  }}
                  alignmentAnimation={{
                    sizeX: 0,
                    sizeY: 0,
                  }}
                  doubleClick={{
                    disabled: true,
                  }}
                  onWheel={changeZoomAndPosition}
                  onPanning={changeZoomAndPosition}
                  onPinching={changeZoomAndPosition}
                  onZoom={changeZoomAndPosition}
                  disabled={inProgress || isAnnotationMode}
                >
                  {({ zoomIn, zoomOut }) => (
                    <div className={styles['file-image-wrapper']}>
                      <TransformComponent
                        wrapperClass={styles['transform-component-wrapper']}
                      >
                        <ImageComponent
                          ref={imageRef}
                          gDriveId={currentFile.gDriveId}
                          className={styles['image']}
                          isAnnotationMode={isAnnotationMode}
                          src={currentFile.url}
                          onClick={
                            isUserAbleToComment
                              ? event => {
                                if (hasFigmaToken) {
                                  imageOnClick(event);
                                }
                                else if (figmaFile?.uuid) {
                                  handleFigmaConnect();
                                }
                                else {
                                  imageOnClick(event);
                                }
                              }
                              : null
                          }
                          onMouseUp={
                            droppableSnapshot.isDraggingOver
                              ? event => {
                                imageOnClick(event);
                              }
                              : null
                          }
                          onLoad={() => setIsImageLoading(false)}
                          loadLinkForGDriveFile={loadLinkForGDriveFile}
                        />
                        {
                          !isImageLoading
                            && imageRef?.current.src
                            && (isAnnotationMode || selectedComment?.content?.annotationData)
                            && (
                              <Annotations
                                canvasRef={annotationsCanvasRef}
                                className={cn(
                                  styles['annotation'],
                                  {
                                    [styles['draw-cursor']]: isAnnotationMode,
                                  }
                                )}
                                disabled={!isAnnotationMode}
                                brushColor={isAnnotationMode ? styles.canvasBrushColor : 'transparent'}
                                catenaryColor={isAnnotationMode ? styles.canvasBrushColor : 'transparent'}
                                canvasWidth={imageRef?.current?.clientWidth}
                                canvasHeight={imageRef?.current?.clientHeight}
                                onChange={saveAnnotation}
                                annotationList={
                                  selectedComment?.file == currentFile.uuid && zoomState.scale == 1
                                    ? [selectedComment?.content?.annotationData || Annotations.defaultCanvasData]
                                    : []
                                }
                                editableAnnotation={
                                  newCommentData?.file?.uuid == currentFile.uuid
                                    ? (
                                      JSON.stringify(newCommentData?.annotationData)
                                        || Annotations.defaultCanvasData
                                    )
                                    : Annotations.defaultCanvasData
                                }
                              />
                            )
                        }
                      </TransformComponent>
                      <VotingTools
                        file={currentFile}
                        className={styles['vote-tools']}
                        fileType={
                          currentFile?.gDriveId
                            ? VotingTools.fileTypes.gDriveFiles
                            : VotingTools.fileTypes.figmaFile
                        }
                        disabled={isReadonly}
                      />
                      <TopTools
                        className={cn({
                          [styles['show-tools-on-hover']]: showToolsOnHover,
                        })}
                        file={file}
                        figmaFile={figmaFile}
                        onDeleteFile={!!onDeleteFile && deleteFile}
                        onSwapFile={onSwapFile}
                        onAddFile={onAddFile}
                        fullScreenHandle={fullScreenHandle}
                        onDownloadFile={onDownloadFile}
                      />
                      <div
                        className={cn(
                          styles['bottom-zoom-tools-wrapper'],
                          {
                            [styles['show-tools-on-hover']]: showToolsOnHover,
                            hide: isAnnotationMode || selectedComment?.content?.annotationData,
                          }
                        )}
                      >
                        <button
                          className="text-white p-zero pointer"
                          onClick={() => {
                            zoomRef.current.resetTransform(0);
                            zoomRef.current.centerView();
                            setTimeout(() => changeZoomAndPosition(zoomRef?.current), 200);
                          }}
                        >
                          <CenterIcon className={styles['center-icon']} />
                        </button>
                        <button
                          className="text-white p-zero pointer"
                          onClick={() => {
                            if (fullScreenHandle.active) {
                              fullScreenHandle.exit();
                            }
                            else {
                              fullScreenHandle.enter();
                            }
                            if (zoomState.scale <= 1) {
                              setTimeout(() => zoomRef?.current?.centerView(undefined, 0), 300);
                            }
                          }}
                          disabled={inProgress}
                        >
                          {
                            fullScreenHandle.active
                              ? <FontAwesomeIcon icon={faCompress} />
                              : <FontAwesomeIcon icon={faExpand} />
                          }
                        </button>
                        <button
                          className="text-white p-zero pointer"
                          onClick={() => {
                            zoomOut();
                            setTimeout(() => changeZoomAndPosition(zoomRef?.current), 300);
                          }}
                          disabled={inProgress}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <button
                          className="text-white p-zero pointer"
                          onClick={() => {
                            zoomIn();
                            setTimeout(() => changeZoomAndPosition(zoomRef?.current), 300);
                          }}
                          disabled={inProgress}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  )}
                </TransformWrapper>
              </FullScreen>
            </FunctionNotAvailable>
            {
              !(isImageLoading || isCommentsLoading) && !isThumbnail && (commentsList || [])
                .filter(commentObj => {
                  if (imageRef.current) {
                    const coordinates = commentObj.coordinates || commentObj.figma_comment_detail.node_offset;

                    return coordinates.x >= 0
                      && coordinates.y >= 0
                      && coordinates.x <= imageRef.current.naturalWidth
                      && coordinates.y <= imageRef.current.naturalHeight;
                  }

                  return true;
                })
                .map(commentObj => (
                  <CommentsBlock
                    key={commentObj.uuid}
                    ref={el => commentsListRefs.current[commentObj.uuid] = el}
                    position={getRelativePositionOfImageWrapper(
                      commentObj.coordinates || commentObj.figma_comment_detail.node_offset
                    )}
                    commentsList={[
                      commentObj,
                      ...commentObj.replies,
                    ]}
                    containerComponent={imageWrapperRef?.current}
                    hideCommentsList
                  />
                ))
            }
            {
              !(isImageLoading || isCommentsLoading) && !isThumbnail && (commentsList || [])
                .filter(commentObj => {
                  if (imageRef.current) {
                    const coordinates = commentObj.coordinates || commentObj.figma_comment_detail.node_offset;

                    return coordinates.x >= 0
                      && coordinates.y >= 0
                      && coordinates.x <= imageRef.current.naturalWidth
                      && coordinates.y <= imageRef.current.naturalHeight;
                  }

                  return true;
                })
                .map(commentObj => (
                  <div
                    key={commentObj.uuid}
                    className={styles['test-point']}
                    style={{
                      top: getRelativePositionOfImageWrapper(
                        commentObj.coordinates || commentObj.figma_comment_detail.node_offset
                      ).y,
                      left: getRelativePositionOfImageWrapper(
                        commentObj.coordinates || commentObj.figma_comment_detail.node_offset
                      ).x,
                    }}
                  />
                ))
            }
            {
              newCommentBlockPosition && !isThumbnail && (
                <CommentsBlock
                  position={getRelativePositionOfImageWrapper(newCommentBlockPosition)}
                  isNewComment
                  hideCommentsList
                  containerComponent={imageWrapperRef?.current}
                  onCancel={() => {
                    setNewCommentBlockPosition(null);
                  }}
                />
              )
            }
          </div>
        )}
      </Droppable>
    </div>
  );

  function imageOnClick(event) {
    setNewCommentBlockPosition({
      x: event.nativeEvent.offsetX * imageRef.current.naturalWidth / imageRef.current.clientWidth,
      y: event.nativeEvent.offsetY * imageRef.current.naturalHeight / imageRef.current.clientHeight,
    });
  }

  function getRelativePositionOfImageWrapper({ x, y }) {
    return !isImageLoading && imageRef.current
      ? {
        x: zoomState?.positionX + x * imageRef.current.clientWidth / imageRef.current.naturalWidth
          * (zoomState?.scale || 1),
        y: zoomState?.positionY + y * imageRef.current.clientHeight / imageRef.current.naturalHeight
          * (zoomState?.scale || 1),
      }
      : { x, y };
  }

  function clickOutside(event) {
    const isClickOutside = !event.composedPath().find(
      el => el.className?.indexOf && (
        el.className.indexOf(styles['image-wrapper']) != -1
          || el.className.indexOf(stylesAllCommentsList['comments-list-wrapper']) != -1
      )
    );

    const isClickOnCommentMarker = !!event.composedPath().find(
      el => el.className?.indexOf && el.className.indexOf(stylesCommentsBlock['comments-block-wrapper']) != -1
    )?.getAttribute('data-comment-uuid');

    if (isClickOutside) {
      dispatch(selectComment(null));
    }

    if (isClickOnCommentMarker) {
      setNewCommentBlockPosition(null);
      dispatch(selectComment(null));
    }
  }

  async function saveAnnotation() {
    const annotationData = JSON.parse(annotationsCanvasRef.current.getSaveData());

    if (annotationData?.lines?.length) {
      dispatch(setNewCommentData({
        annotationData: JSON.parse(annotationsCanvasRef.current.getSaveData()),
        file: {
          ...file,
          ...figmaFile,
        },
      }));
    }
    else {
      dispatch(setNewCommentData({}));
    }
  }

  async function setNewCommentBlockPosition(coordinates) {
    if (coordinates) {
      dispatch(setNewCommentData({
        coordinates,
        file: {
          ...file,
          ...figmaFile,
        },
      }));
    }
    else {
      dispatch(setNewCommentData({}));
    }
  }

  function loadAllComment() {
    return dispatch(loadComments(selectedProject.uuid, {
      fileUUID: currentFile?.uuid,
      milestoneId: selectedMilestone?.id,
      limit: 1000,
      commentsType,
      keepPrevComments: true,
    }));
  }

  function handleFigmaConnect() {
    infoReactToastify(
      'Please wait as we are connecting your account with Figma'
    );
    setTimeout(
      () => dispatch(getFigmaConnected(authUser, selectedProject, () => {})),
      2000
    );
  }

  function itWasPressingEscape(event) {
    if (event?.key === 'Escape') {
      setNewCommentBlockPosition(null);
    }
  }

  function changeZoomAndPosition(zoomPanPinchRef) {
    requestAnimationFrame(() => setZoomState({ ...zoomPanPinchRef?.state }));
  }

  async function deleteFile(fileUUID) {
    try {
      setInProgress(true);

      await onDeleteFile(fileUUID);

      dispatch(removeCommentsFromReduxStore(allCommentsList.map(({ uuid }) => uuid)));
    }
    finally {
      setInProgress(false);
    }
  }

  function centerZoomView() {
    zoomRef?.current?.centerView(undefined, 0);
    changeZoomAndPosition(zoomRef?.current);
  }

  async function loadLinkForGDriveFile() {
    try {
      setIsLoadLinkForGDriveFile(true);

      return getLinkForGDriveFile(currentFile?.uuid);
    }
    finally {
      setIsLoadLinkForGDriveFile(false);
    }
  }
}

ImageWithComments.propTypes = {
  file: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    gDriveId: PropTypes.string.isRequired,
  }),
  figmaFile: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    svg_links: PropTypes.array.isRequired,
    node_ids: PropTypes.array.isRequired,
    file_key: PropTypes.string.isRequired,
    file_name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),

  index: PropTypes.number.isRequired,
  canvasRefs: PropTypes.object.isRequired,
  selectedMilestone: PropTypes.object,
  className: PropTypes.string,
  isCommentMod: PropTypes.bool,
  isAnnotationMode: PropTypes.bool,
  isThumbnail: PropTypes.bool,
  onDownloadFile: PropTypes.func,
  getLinkForGDriveFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onSwapFile: PropTypes.func,
  onAddFile: PropTypes.func,
  onChangeFiles: PropTypes.func,
  showToolsOnHover: PropTypes.bool,
  checkLastFigmaFileVersion: PropTypes.bool,
  isReadonly: PropTypes.bool,
};
