import { useMemo, useRef, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, Progress, Spin, Upload } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';

import { ReactComponent as BinIcon } from 'assets/bin_icon.svg';
import { ReactComponent as DallEIcon } from 'assets/images_search_icons/dall_e.svg';
import { ReactComponent as GoogleIcon } from 'assets/images_search_icons/google.svg';
import { ReactComponent as PinterestIcon } from 'assets/images_search_icons/pinterest.svg';
import { ReactComponent as UnsplashIcon } from 'assets/images_search_icons/unsplash.svg';
import { ReactComponent as UploadCloudIcon } from 'assets/upload_cloud_icon.svg';
import { ReactComponent as UploadIcon } from 'assets/upload_icon.svg';

import { Button } from 'Components/Button';
import ImageFromGDrive from 'Components/ImageWithComments/components/Image';
import { Modal } from 'Components/Modal';

import FilesService from 'services/files';
import GDriveService from 'services/gdrive';
import { ImageSearchService, INITIAL_IMAGE_LIST } from 'services/image_search';

import { IMAGE_MIME_TYPES } from 'utlis/constants';

import { LocalImage } from './components/LocalImage';

import styles from './styles.module.scss';

const { Dragger } = Upload;

export function MoodboardModal({ onSubmit, onSubmitLocalFiles, onCancel, ...props }) {
  const maxFileSize = 5;
  const imageSearchServicesList = [
    {
      title: 'Upload',
      icon: UploadIcon,
      source: ImageSearchService.imageSearchApiSources.local,
    },
    {
      title: 'Unsplash',
      icon: UnsplashIcon,
      search: ImageSearchService.unsplashSearchImages,
      source: ImageSearchService.imageSearchApiSources.unsplash,
    },
    {
      title: 'Google images',
      icon: GoogleIcon,
      search: ImageSearchService.googleSearchImages,
      source: ImageSearchService.imageSearchApiSources.google,
    },
    {
      title: 'DALL·E',
      icon: DallEIcon,
      search: ImageSearchService.dallESearchImages,
      source: ImageSearchService.imageSearchApiSources.dallE,
    },
    {
      title: 'Pinterest (coming soon)',
      icon: PinterestIcon,
      disabled: true,
    },
  ];

  const [selectedImageSearchService, setSelectedImageSearchService] = useState(imageSearchServicesList[0]);
  const [imagesList, setImagesList] = useState(INITIAL_IMAGE_LIST);
  const [imagesListForUpload, setImagesListForUpload] = useState([]);
  const [selectedImagesUUIDs, setSelectedImagesUUIDs] = useState([]);
  const [currentSearch, setCurrentSearch] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const gDriveProvider = useSelector(state => state.userProfile?.appTokens?.googleDrive);

  const searchInputRef = useRef();
  const imagesListForUploadRefs = useRef({});

  const searchImagesDebouncedCallback = useDebouncedCallback(
    value => {
      searchImages({ message: value });
    },
    1000
  );

  const [openPicker] = useDrivePicker();

  const hasMoreImageToLoad = useMemo(
    () => !!currentSearch && imagesList.results.length < imagesList.count,
    [currentSearch, imagesList]
  );
  const countImagesForUpload = useMemo(
    () => imagesListForUpload.filter(({ size }) => (size || 0) < maxFileSize * Math.pow(10, 6)).length,
    [imagesListForUpload]
  );

  return (
    <Modal
      title={
        <p className="text-34 text-weight-600 letter-xs text-black pt-32 px-32">
          Add Images
        </p>
      }
      className={styles.modal}
      destroyOnClose
      footer={null}
      width={1100}
      onCancel={onClose}
      closable={false}

      {...props}
    >
      <div className={cn(styles['image-search-services-list'], 'flex aligh-center px-32')}>
        {
          imageSearchServicesList.map(imageSearchService => (
            <Button
              key={imageSearchService.title}
              isText
              className={cn(
                'flex align-center text-20 text-black-important letter-xs text-weight-600 pb-16-important',
                styles['image-search-service-button'],
                {
                  [styles['image-search-service-button-active']]: (
                    imageSearchService.title === selectedImageSearchService.title
                  ),
                }
              )}
              disabled={imageSearchService.disabled}
              onClick={() => setImageSearchService(imageSearchService)}
            >
              {
                !!imageSearchService.icon && (
                  <imageSearchService.icon className="mr-8" />
                )
              }
              {imageSearchService.title}
            </Button>
          ))
        }
      </div>
      <Divider className={cn(styles['divider'], 'm-zero')} />
      {
        selectedImageSearchService.search && (
          <>
            <Form.Item
              className="mt-28 mx-32 mb-zero"
              rules={[{
                validator: (rule, value) => (// eslint-disable-line no-unused-vars
                  value === currentSearch
                    ? Promise.reject()
                    : Promise.resolve()
                ),
              }]}
            >
              <Input
                ref={searchInputRef}
                className={styles['search-input']}
                placeholder="Search for images..."
                onKeyUp={event => {
                  if (event.key == 'Enter') {
                    searchImagesDebouncedCallback.cancel();
                    searchImages({ message: event.target.value });
                  }
                  else {
                    searchImagesDebouncedCallback(event.target.value);
                  }
                }}
              />
            </Form.Item>
            {
              isLoading && !imagesList?.results?.length && (
                <Spin key={0} className={cn(styles.loader, 'my-md flex just-center')} size="large" />
              )
            }
          </>
        )
      }
      {
        selectedImageSearchService.source === ImageSearchService.imageSearchApiSources.local && (
          <Dragger
            className={cn(styles['upload-file-wrapper'], 'mx-32 mt-32 mb-md')}
            name="local-file"
            accept="image/*"
            multiple
            onChange={({ file: { originFileObj } }) => {
              setImagesListForUpload(currentImagesListForUpload => (
                currentImagesListForUpload
                  .concat(originFileObj)
                  .reduce(
                    (current, next) => (
                      current.find(({ uid }) => next.uid && next.uid === uid)
                        ? current
                        : current.concat(next)
                    ),
                    []
                  )
              ));
            }}
            showUploadList={false}
          >
            <div className="flex flex-column align-center">
              <div className={styles['upload-cloud-icon-wrapper']}>
                <UploadCloudIcon />
              </div>
              <p className="text-sm text-carbonGrey mb-zero mt-16-important">
                <Button
                  isText
                  className="text-easternBlue text-weight-600"
                >
                  Click to upload
                </Button>
                {' '}
                or drag and drop or use
                {' '}
                <Button
                  isText
                  onClick={handleOpenGDrivePicker}
                  className="text-easternBlue text-weight-600"
                >
                  Google Drive
                </Button>
                <br />
              </p>
              <p className="text-sm text-carbonGrey mb-zero mt-4-important">
                SVG, PNG, JPG or GIF (max.
                {' '}
                {maxFileSize}
                {' '}
                MB)
              </p>
            </div>
          </Dragger>
        )
      }
      {
        !!imagesList.results.length && (
          <div className={cn(styles['image-list'], 'mt-24 px-32 pb-32')}>
            <InfiniteScroll
              initialLoad={false}
              className="flex flex-wrap just-center"
              pageStart={1}
              // We need to pass the key parameter because the library implementation requires it
              // https://github.com/danbovey/react-infinite-scroller/blob/master/src/InfiniteScroll.js#L277
              loader={<Spin key={0} className={cn(styles.loader, 'my-md flex just-center')} size="large" />}
              loadMore={page => searchImages({ message: currentSearch, page, loadMore: true })}
              hasMore={hasMoreImageToLoad}
              useWindow={false}
            >
              {
                imagesList.results.map(({ uuid, thumbnailUrl, imageUrl }) => (
                  <div
                    key={uuid}
                    className={cn(styles['image-wrapper'], 'mr-sm mb-sm flex flex-component-center')}
                  >
                    <img
                      src={thumbnailUrl || imageUrl}
                      className={cn(
                        styles['image'],
                        'pointer',
                        {
                          [styles['selected-image']]: selectedImagesUUIDs.includes(uuid),
                        }
                      )}
                      onClick={() => setSelectedImagesUUIDs(currentSelectedImages => (
                        currentSelectedImages.includes(uuid)
                          ? currentSelectedImages.filter(url => url !== uuid)
                          : [...currentSelectedImages, uuid]
                      ))}
                    />
                  </div>
                ))
              }
            </InfiniteScroll>
            {
              !hasMoreImageToLoad && currentSearch && (
                <p className="text-md text-center text-black-opacity-0-90 mt-12 mb-zero">
                  Type new search for more results
                </p>
              )
            }
          </div>
        )
      }
      {
        !!imagesListForUpload.length && (
          <>
            <p className="text-md text-black text-weight-600 mt-8 mb-zero px-32">
              Uploaded
            </p>
            <div className="flex flex-wrap px-32">
              {
                imagesListForUpload.map(file => (
                  <div
                    key={file.uid || file.id}
                    className="mr-md mt-12"
                  >
                    <div
                      className={cn(
                        styles['local-image'],
                        'flex flex-column flex-component-center mb-12',
                        {
                          [styles['loading-wrapper']]: file.isInfinityLoading || file.isProgressLoading,
                          [styles['big-image']]: file.size > maxFileSize * Math.pow(10, 6) && file.isLoaded,
                        }
                      )}
                    >
                      <div
                        className={cn(
                          styles['big-image-alert'],
                          {
                            hide: (file.size || 0) <= maxFileSize * Math.pow(10, 6) || !file.isLoaded,
                          }
                        )}
                      >
                        Max file size
                        {' '}
                        {maxFileSize}
                        {' '}
                        MB
                      </div>
                      {
                        !file?.id
                          ? (
                            <LocalImage
                              ref={el => imagesListForUploadRefs.current[file.uid || file.id] = el}
                              file={file}
                              className={styles['inner-local-image']}
                              onLoaded={() => updateImagesListForUpload(
                                file.uid,
                                {
                                  isLoaded: true,
                                }
                              )}
                            />
                          )
                          : (
                            <ImageFromGDrive
                              ref={el => imagesListForUploadRefs.current[file.uid || file.id] = el}
                              gDriveId={file.id}
                              useThumbnailIfNoOtherFile
                            />
                          )
                      }
                      <Spin
                        className={cn(
                          styles['infinity-loading'],
                          {
                            hide: !file.isInfinityLoading,
                          }
                        )}
                      />
                      <Progress
                        className={cn(
                          styles['progress-loading'],
                          {
                            hide: !file.isProgressLoading,
                          }
                        )}
                        type="circle"
                        percent={file.progress || 0}
                        width={50}
                      />
                    </div>
                    <Button
                      isText
                      icon={<BinIcon className={cn(styles['black-bin-icon'], 'mr-8')} />}
                      className={cn(
                        'flex align-center',
                        {
                          hide: isSubmitting || file.isInfinityLoading || file.isProgressLoading,
                        }
                      )}
                      onClick={() => removeLocalImage(file.uid || file.id)}
                    >
                      Delete
                    </Button>
                  </div>
                ))
              }
            </div>
          </>
        )
      }
      <Divider
        className={cn(
          styles['divider'],
          'mb-zero',
          {
            'mt-32': !imagesList.results.length,
            'mt-zero': imagesList.results.length,
          }
        )}
      />
      <div
        className={cn('flex align-center space-between px-32 py-16')}
      >
        <p className="m-zero text-sm text-weight-500 letter-xl text-lochinvar-opacity-0-80">
          {selectedImagesUUIDs.length || imagesListForUpload.length}
          {' '}
          images selected
        </p>
        <div>
          <Button
            isText
            className="text-sm text-weight-500 letter-xl text-lochinvar-opacity-0-80"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            className="ml-32"
            loading={isSubmitting}
            disabled={isSubmitting || !(selectedImagesUUIDs.length || countImagesForUpload)}
            onClick={submitImagesList}
          >
            DONE
          </Button>
        </div>
      </div>
    </Modal>
  );

  function setImageSearchService(imageSearchService) {
    setSelectedImageSearchService(imageSearchService);
    setImagesList(INITIAL_IMAGE_LIST);
    setSelectedImagesUUIDs([]);
    setImagesListForUpload([]);
    setCurrentSearch('');

    if (searchInputRef?.current) {
      searchInputRef.current.focus();
    }
  }

  async function searchImages({ message: search, page, loadMore = false }) {
    try {
      setCurrentSearch(search);
      setIsLoading(true);

      if (!loadMore) {
        setImagesList(INITIAL_IMAGE_LIST);
      }

      const newImagesList = await selectedImageSearchService.search(search, { page });

      setImagesList(
        currentImages => loadMore
          ? {
            ...newImagesList,
            results: [...(currentImages?.results || []), ...(newImagesList?.results || [])]
              .reduce(
                (current, next) => (
                  current.find(({ uuid, imageUrl }) => (
                    uuid == next.uuid
                    || imageUrl == next.imageUrl
                  ))
                    ? current
                    : [...current, next]
                ),
                []
              ),
          }
          : newImagesList
      );
    }
    finally {
      setIsLoading(false);
    }
  }

  async function submitImagesList() {
    try {
      setIsSubmitting(true);

      if (selectedImageSearchService.source === ImageSearchService.imageSearchApiSources.local) {
        await Promise.all(
          imagesListForUpload
            .filter(file => (file.size || 0) <= maxFileSize * Math.pow(10, 6))
            .map(file => {
              if (file.id) {
                return loadGDriveFileInfo(file.id);
              }
              if (file.uid) {
                return uploadFileToS3(file);
              }
            })
        );

        await onSubmitLocalFiles(
          imagesListForUpload
            .filter(file => (file.size || 0) <= maxFileSize * Math.pow(10, 6))
            .map(file => {
              if (file.uid) {
                file.width = imagesListForUploadRefs.current[file.uid]?.naturalWidth;
                file.height = imagesListForUploadRefs.current[file.uid]?.naturalHeight;
              }

              return file;
            })
        );
      }
      else {
        await onSubmit(
          imagesList.results
            .filter(image => selectedImagesUUIDs.includes(image.uuid))
            .map(({ imageUrl, height, width }) => ({
              image_url: imageUrl,
              image_caption: '',
              image_source: selectedImageSearchService.source,
              height,
              width,
            }))
        );
      }
    }
    finally {
      setIsSubmitting(false);
    }
  }

  function onClose() {
    setSelectedImageSearchService(imageSearchServicesList[0]);
    setImagesList(INITIAL_IMAGE_LIST);
    setImagesListForUpload([]);
    setSelectedImagesUUIDs([]);
    setCurrentSearch('');

    if (onCancel) {
      onCancel();
    }
  }

  function removeLocalImage(removedUID) {
    setImagesListForUpload(
      currentImagesListForUpload => currentImagesListForUpload
        .filter(({ uid, id }) => (uid !== removedUID && id != removedUID))
    );
  }

  function handleOpenGDrivePicker(event) {
    event.preventDefault();
    event.stopPropagation();

    openPicker({
      clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      developerKey: `${process.env.REACT_APP_PICKER_DEVELOPER_KEY}`,
      viewId: 'DOCS_IMAGES',
      viewMimeTypes: IMAGE_MIME_TYPES,
      token: gDriveProvider.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: ({ action, docs }) => {
        if (action == google.picker.Action.PICKED) {
          setImagesListForUpload(currentImagesListForUpload => currentImagesListForUpload.concat(docs));
        }
      },
    });
  }

  async function uploadFileToS3(file) {
    updateImagesListForUpload(
      file.uid,
      {
        isProgressLoading: true,
      }
    );

    const fileUrl = await FilesService.uploadFileFromLocal(
      file,
      {
        onUploadProgress: ({ progress }) => updateImagesListForUpload(
          file.uid,
          {
            progress: Math.round(progress * 100),
          }
        ),
      }
    );

    updateImagesListForUpload(
      file.uid,
      {
        url: fileUrl,
        isProgressLoading: false,
      }
    );
  }

  async function loadGDriveFileInfo(gDriveFileId) {
    updateImagesListForUpload(
      gDriveFileId,
      {
        isInfinityLoading: true,
      }
    );

    const { imageMediaMetadata: { width, height } } = await GDriveService.getGDriveFileInfo(gDriveFileId, null, {
      cache: {
        maxAge: 5 * 60 * 1000, // 5 minutes into milliseconds
      },
    });

    updateImagesListForUpload(
      gDriveFileId,
      {
        isInfinityLoading: false,
        width,
        height,
      }
    );
  }

  function updateImagesListForUpload(fileID, newData) {
    setImagesListForUpload(currentImagesListForUpload => currentImagesListForUpload.map(image => {
      if (image.id === fileID || image.uid === fileID) {
        for (const key in newData) {
          image[key] = newData[key];
        }
      }

      return image;
    }));
  }
}

MoodboardModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitLocalFiles: PropTypes.func.isRequired,

  onCancel: PropTypes.func,
};
