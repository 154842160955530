import { useEffect, useMemo, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { textReplace } from '@loomhq/loom-embed';
import { Spin } from 'antd';
import { saveAs } from 'file-saver';

import { ReactComponent as CanvasPencilIcon } from 'assets/canvas_pencil.svg';
import { ReactComponent as ThumbtackIcon } from 'assets/thumbtack.svg';

import { FirstViewBodyUI } from 'Components/FirstViewBodyUI';
import { CommentForm } from 'Components/ImageWithComments/components/CommentForm';
import { Milestone } from 'Components/Milestone';
import { Modal } from 'Components/Modal';

import { setIsAnnotationMode, setIsPinCommentMode } from 'Redux/Actions/appActions';
import {
  loadComments,
  removeCommentsFromReduxStore,
  selectComment, sendCommentOnFigmaFile, sendCommentOnGDriveFile, sendCommentOnMilestone,
  setCommentSuggestion,
  setNewCommentData,
} from 'Redux/Actions/commentActions';
import { loadProjectDiscovery } from 'Redux/Actions/projectActions';
import { setUserProfile } from 'Redux/Actions/userActions';

import FigmaFile from 'services/figma_file';
import FigmaServices from 'services/figma_file';
import FilesService from 'services/files';
import MiroFileService from 'services/miro_file';

import {
  FILE_CONTAINER_TYPES,
  IMAGE_MIME_TYPES,
  MILESTONES_TYPES,
  NOTIFICATION_TYPES,
} from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';
import { extractLoomURL } from 'utlis/string_format';
import { errorReactToastify } from 'utlis/toasts';

import { AddLinkToFileForm } from './components/AddFile/components/AddLinkToFileForm';
import AddFileWithWrapper from './components/AddFileWithWrapper';
import DownloadReferenceImages from './components/DownloadReferenceImages';
import FilesContainer from './components/FilesContainer';
import { firstViewBodyTexts } from './first_view_body_texts';

import styles from './styles.module.scss';

export default function FilesList({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [filesContainers, setFilesContainers] = useState([]);
  const [swapFileModalType, setSwapFileModalType] = useState(null);
  const [swapFileUUID, setSwapFileUUID] = useState(null);
  const [prevFileUUID, setPrevFileUUID] = useState(null);
  const [annotationsCanvasRef, setAnnotationsCanvasRef] = useState(null);

  const { isPinCommentMode: isCommentMod, isAnnotationMode, hidePinCommentMode } = useSelector(state => state.app);
  const selectedProject = useSelector(state => state.selectedProject);
  const userProfile = useSelector(state => state.userProfile);
  const currentMilestone = useSelector(state => state.milestonesStore.dictionaryId[milestoneId]);
  const gDriveProvider = useSelector(state => state.userProfile?.appTokens?.googleDrive);
  const figmaAppToken = useSelector(state => state.userProfile?.appTokens?.figma);
  const miroAppToken = useSelector(state => state.userProfile?.appTokens?.miro);
  const discoveryObject = useSelector(state => state.selectedProject?.discovery);
  const allComments = useSelector(state => state.commentsStore.allComments);
  const newCommentData = useSelector(state => state.commentsStore.newCommentData);
  const selectedCommentSuggestion = useSelector(state => state.commentsStore.commentSuggestion);

  const [openPicker] = useDrivePicker();

  const dispatch = useDispatch();

  const { hasFiles, filesContainersWithFiles } = useMemo(
    () => ({
      hasFiles: !!filesContainers.map(({ files }) => files).flat().length,
      filesContainersWithFiles: filesContainers.filter(({ files }) => files.length),
    }),
    [filesContainers]
  );
  const designImagesFromBrief = useMemo(
    () => discoveryObject?.verbalAnswersList?.designs,
    [discoveryObject]
  );
  const isFileListChangeable = useMemo(
    () => (
      (
        userProfile.permissions?.isDesigner
          || !firstViewBodyTexts[currentMilestone.type]
      )
        && !currentMilestone?.is_completed
        && !isReadonly
    ),
    [currentMilestone, userProfile, isReadonly]
  );

  const projectUsersList = useMemo(
    () => selectedProject.uuid
      ? [
        selectedProject.owner,
        ...selectedProject.collaborators.map(({ user }) => user),
      ].map(user => (
        user.uuid === userProfile.uuid
          ? {
            ...user,
            full_name: `${user.full_name} (you)`,
          }
          : user
      ))
      : [],
    [selectedProject]
  );

  const canUserSendPinComments = useMemo(
    () => [
      MILESTONES_TYPES.designConcepts,
      MILESTONES_TYPES.presentation,
      MILESTONES_TYPES.deliverFiles,
      MILESTONES_TYPES.userInterview,
      MILESTONES_TYPES.designReview,
      MILESTONES_TYPES.sketches,
    ]
      .includes(currentMilestone?.type),
    [currentMilestone?.type]
  );

  useEffect(
    () => {
      if (!isReadonly) {
        dispatch(setUserProfile(userProfile, null, true));
      }
    },
    [isReadonly]
  );

  useEffect(
    () => {
      loadContainersWithFiles();
    },
    [currentMilestone.id]
  );

  useEffect(
    () => {
      if (currentMilestone.milestone_type == MILESTONES_TYPES.moodboard) {
        dispatch(loadProjectDiscovery(selectedProject.uuid));
      }
    },
    [selectedProject.uuid]
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          loadContainersWithFiles();
        }
      },
      [
        NOTIFICATION_TYPES.MILESTONE_UPDATED,
      ]
    ),
    [selectedProject.uuid, currentMilestone.id, milestoneId, allComments]
  );

  if (isLoading) {
    return (
      <div className="flex flex-component-center px-lg py-lg mx-xs">
        <Spin size="large" />
      </div>
    );
  }

  if (isReadonly && firstViewBodyTexts[currentMilestone.type] && !hasFiles) {
    return (
      <Milestone
        selectedMilestone={currentMilestone}
        title={currentMilestone?.name}

        withShareButton={!isReadonly}
      >
        <FirstViewBodyUI
          className="mx-xxxs"
          {...firstViewBodyTexts[currentMilestone.type]}
        />
      </Milestone>
    );
  }

  return (
    <>
      {
        currentMilestone.milestone_type == MILESTONES_TYPES.moodboard && designImagesFromBrief.length > 0 && (
          <DownloadReferenceImages
            images={designImagesFromBrief}
          />
        )
      }

      <Milestone
        selectedMilestone={currentMilestone}
        title={currentMilestone.name}
        withShareButton={!isReadonly}

        withTopics
      >
        {
          hasFiles
            ? (
              filesContainersWithFiles.map(
                ({ uuid, container_type, files }) => container_type == FilesService.CONTAINER_TYPES.gDrive
                    || container_type == FilesService.CONTAINER_TYPES.pdf
                    || container_type == FilesService.CONTAINER_TYPES.png
                  ? (
                    <FilesContainer
                      key={uuid}
                      isGDriveContainer
                      files={files}
                      isAnnotationMode={isAnnotationMode}
                      setCanvasRef={setCanvasRef}
                      onSwapFile={isFileListChangeable && (fileUUID => {
                        let viewId = 'DOCS';
                        let viewMimeTypes = undefined;
                        let containerType = FilesService.CONTAINER_TYPES.gDriveNumber;
                        let multiselect = false;
                        let previousFileUUID = null;

                        switch (container_type) {
                          case FilesService.CONTAINER_TYPES.pdf:
                            viewId = 'PDFS';
                            viewMimeTypes = 'application/pdf';
                            containerType = FilesService.CONTAINER_TYPES.pdfNumber;
                            break;
                          case FilesService.CONTAINER_TYPES.png:
                            viewId = 'DOCS_IMAGES';
                            viewMimeTypes = IMAGE_MIME_TYPES;
                            containerType = FilesService.CONTAINER_TYPES.pngNumber;
                            multiselect = true;
                            previousFileUUID = fileUUID;
                            break;
                        }

                        handleOpenGDrivePicker({
                          onPicked: newFile => onSwapGDriveFile(
                            newFile,
                            containerType,
                            previousFileUUID,
                            fileUUID
                          ),
                          multiselect,
                          viewId,
                          viewMimeTypes,
                        });
                      })}
                      onDeleteFile={isFileListChangeable && onDeleteGDriveFile}
                      onDownloadFile={!isReadonly && onDownloadFile}
                      getLinkForGDriveFile={getDownloadLinkForGDriveFile}
                      onAddFile={
                        container_type == FilesService.CONTAINER_TYPES.png && isFileListChangeable
                          ? fileUUID => handleOpenGDrivePicker({
                            onPicked: newFiles => onSwapGDriveFile(
                              newFiles,
                              FilesService.CONTAINER_TYPES.pngNumber,
                              fileUUID
                            ),
                            viewId: 'DOCS_IMAGES',
                            viewMimeTypes: IMAGE_MIME_TYPES,
                          })
                          : null
                      }
                      isCommentMod={isCommentMod}
                      selectedMilestone={currentMilestone}
                      showToolsOnHover={container_type == FilesService.CONTAINER_TYPES.png}
                      isReadonly={isReadonly}
                    />
                  )
                  : (
                    container_type === FILE_CONTAINER_TYPES.miro
                      ? (
                        <FilesContainer
                          key={uuid}
                          files={files}
                          isMiroContainer
                          onAddFile={
                            isFileListChangeable
                              ? previousMiroFileUUID => {
                                setSwapFileModalType(FILE_CONTAINER_TYPES.miro);
                                setPrevFileUUID(previousMiroFileUUID);
                              }
                              : null
                          }
                          setCanvasRef={setCanvasRef}
                          onDeleteFile={isFileListChangeable && onDeleteMiroFile}
                          onSwapFile={
                            isFileListChangeable && (
                              previousMiroFileUUID => {
                                setSwapFileModalType(FILE_CONTAINER_TYPES.miro);
                                setSwapFileUUID(previousMiroFileUUID);
                              })
                          }
                          isReadonly={isReadonly}
                        />
                      )
                      : (
                        <FilesContainer
                          key={uuid}
                          files={files}
                          onAddFile={
                            isFileListChangeable
                              ? previousFigmaFileUUID => {
                                setSwapFileModalType(FILE_CONTAINER_TYPES.figma);
                                setPrevFileUUID(previousFigmaFileUUID);
                              }
                              : null
                          }
                          onSwapFile={
                            isFileListChangeable && (
                              previousFigmaFileUUID => {
                                setSwapFileModalType(FILE_CONTAINER_TYPES.figma);
                                setSwapFileUUID(previousFigmaFileUUID);
                              })
                          }
                          onChangeFiles={loadContainersWithFiles}
                          onDeleteFile={isFileListChangeable && onDeleteFigmaFile}
                          isCommentMod={isCommentMod}
                          isAnnotationMode={isAnnotationMode}
                          setCanvasRef={setCanvasRef}
                          selectedMilestone={currentMilestone}
                          checkLastFigmaFileVersion={!currentMilestone.is_completed}
                          isReadonly={isReadonly}
                        />
                      )
                  )
              )
            )
            : (
              <AddFileWithWrapper
                className="mx-xs"
                onAddFigmaFile={onAddFigmaFile}
                onAddMiroFile={onAddMiroFile}
                onAddGDriveFile={onAddGDriveFile}
                withFigma={
                  ![
                    MILESTONES_TYPES.presentation,
                    MILESTONES_TYPES.deliverFiles,
                  ].includes(currentMilestone?.milestone_type)
                }
                withMiro={
                  [
                    MILESTONES_TYPES.sketches,
                    MILESTONES_TYPES.designConcepts,
                    MILESTONES_TYPES.moodboard,
                    MILESTONES_TYPES.presentation,
                    MILESTONES_TYPES.deliverFiles,
                    MILESTONES_TYPES.designReview,
                  ].includes(currentMilestone?.type)
                }
                withPDF
                withImage
                withGDrive
              />
            )
        }
        {
          !isReadonly
            && (
              <CommentForm
                className={styles['comment-form-wrapper']}
                autoFocusOnMessage={false}
                annotationsCanvasRef={annotationsCanvasRef}
                onSubmit={onSaveComment}
                messagePlaceholder="Add a comment..."
                isPinCommentMode={isCommentMod}
                isAnnotationMode={isAnnotationMode}
                onClickPinButton={() => {
                  dispatch(setIsPinCommentMode(!isCommentMod));
                  dispatch(setIsAnnotationMode(false));
                  dispatch(setNewCommentData({}));
                  dispatch(selectComment(null));
                  dispatch(setCommentSuggestion(null));
                }}
                onClickAnnotationButton={() => {
                  dispatch(setIsAnnotationMode(!isAnnotationMode));
                  dispatch(setIsPinCommentMode(false));
                  dispatch(setNewCommentData({}));
                  dispatch(selectComment(null));
                  dispatch(setCommentSuggestion(null));
                }}
                inputIcon={
                  newCommentData?.annotationData?.lines?.length
                    ? <CanvasPencilIcon width={16} height={16} />
                    : (
                      newCommentData?.coordinates
                        ? <ThumbtackIcon className={styles['thumbtack-icon']} width={16} height={16} />
                        : null
                    )
                }
                commentSuggestion={selectedCommentSuggestion}
                hidePinButton={!canUserSendPinComments || hidePinCommentMode || !hasFiles}
                hideAnnotationButton={!canUserSendPinComments || hidePinCommentMode || !hasFiles}
                mentionsList={projectUsersList}
                fileType={newCommentData?.file}
              />
            )
        }
      </Milestone>
      <Modal
        title={
          <p className="text-20 text-weight-500 letter-xs pt-md ml-16">
            {
              prevFileUUID
                ? `Paste ${swapFileModalType} link below`
                : 'Swap link'
            }
          </p>
        }
        destroyOnClose
        className={styles['add-figma-file-modal']}
        visible={!!swapFileModalType}
        onCancel={closeSwapFileModal}
        footer={null}
        centered
      >
        {
          swapFileModalType === FILE_CONTAINER_TYPES.figma
            ? (
              <AddLinkToFileForm.Figma
                onHandleConnect={() => FigmaServices.openFigmaOauth(selectedProject.uuid)}
                appToken={figmaAppToken}
                onAddFile={
                  prevFileUUID
                    ? figmaFileUrl => onAddFigmaFile(figmaFileUrl, prevFileUUID)
                    : onSwapFigmaFile
                }
                submitButtonText={prevFileUUID ? null : 'POST NEW LINK'}
              />
            )
            : (
              <AddLinkToFileForm.Miro
                onHandleConnect={() => MiroFileService.connectMiro(location.href)}
                appToken={miroAppToken}
                onAddFile={
                  prevFileUUID
                    ? figmaFileUrl => onAddMiroFile(figmaFileUrl)
                    : onSwapMiroFile
                }
              />
            )
        }
      </Modal>
    </>
  );

  async function onSaveComment({
    message,
    file_links,
  }) {
    const currentFile = newCommentData?.file;
    let sendCommentFunc;

    if (currentFile?.gDriveId) {
      sendCommentFunc = sendCommentOnGDriveFile;
    }
    else if (currentFile) {
      sendCommentFunc = sendCommentOnFigmaFile;
    }
    else {
      sendCommentFunc = sendCommentOnMilestone;
    }
    if (message) {
      const loomURL = extractLoomURL(message);

      if (loomURL) {
        const loomIframe = await textReplace(loomURL);

        message = `${message}<br>${loomIframe}`;
      }
    }

    try {
      await dispatch(sendCommentFunc(
        selectedProject.uuid,
        {
          text: message || '<p><br></p>',
          fileUUID: currentFile?.uuid,
          coordinates: {
            x: Math.round(newCommentData?.coordinates?.x || 0),
            y: Math.round(newCommentData?.coordinates?.y || 0),
          },
          node_id: newCommentData?.file?.node_ids?.[0],
          content: {
            annotationData: JSON.stringify(newCommentData?.annotationData),
          },
          milestone_id: currentMilestone.id,
          file_links,
        }
      ));

      const [currentComment] = (await loadAllComment()).results;

      if (currentComment?.coordinates || currentComment?.figma_comment_detail?.node_offset) {
        dispatch(selectComment(currentComment));
      }
      dispatch(setNewCommentData({}));
      dispatch(setCommentSuggestion(null));
    }
    catch (error) {
      if (error?.response?.status == 400) {
        errorReactToastify(error?.response?.data?.detail);
      }

      throw new Error(error);
    }
  }

  function loadAllComment() {
    return dispatch(loadComments(
      selectedProject.uuid,
      {
        milestoneId: currentMilestone?.id,
        keepPrevComments: true,
        limit: 1000,
      }
    ));
  }

  async function loadContainersWithFiles() {
    try {
      setIsLoading(true);

      const newContainersWithFiles = await FilesService.loadContainersWithFiles(currentMilestone.id);
      const allFiles = newContainersWithFiles.map(({ files }) => files).flat();

      setFilesContainers(newContainersWithFiles);
      dispatch(removeCommentsFromReduxStore(
        allComments
          .filter(({ file }) => file && !allFiles.find(({ uuid }) => uuid == file))
          .map(({ uuid }) => uuid)
      ));
    }
    finally {
      setIsLoading(false);
    }
  }

  async function onAddMiroFile(miroFileUrl) {
    await MiroFileService.saveMiroFile(
      selectedProject.uuid,
      miroFileUrl,
      currentMilestone.id,
      getContainerByType(FILE_CONTAINER_TYPES.miro)?.uuid || null,
      prevFileUUID
    );
    setSwapFileModalType(null);
    setPrevFileUUID(null);
    await loadContainersWithFiles();
  }

  async function onAddFigmaFile(figmaFileUrl) {
    try {
      await FigmaFile.uploadFigmaFile(
        selectedProject.uuid,
        figmaFileUrl,
        currentMilestone.id,
        getContainerByType(FilesService.CONTAINER_TYPES.figma)?.uuid || null,
        prevFileUUID
      );

      setSwapFileModalType(null);
      setPrevFileUUID(null);
      await loadContainersWithFiles();
    }
    catch (error) {
      if (error?.response?.status == 404) {
        errorReactToastify('Please make sure you have access to this Figma file');
      }
      else if (error?.response?.status == 406) {
        errorReactToastify(error?.response?.message);
      }
      else {
        throw new Error(error);
      }
    }
  }

  async function onAddGDriveFile(
    files,
    containerType = FilesService.CONTAINER_TYPES.gDriveNumber,
    previousFileUUID = null
  ) {
    try {
      setIsLoading(true);

      await FilesService.uploadFileFromGDrive(
        selectedProject.uuid,
        getContainerByType(FilesService.CONTAINER_TYPES[containerType])?.uuid || null,
        currentMilestone.id,
        previousFileUUID,
        containerType,
        files
      );

      await loadContainersWithFiles();
    }
    finally {
      setIsLoading(false);
    }
  }

  async function onDeleteFigmaFile(fileUUID) {
    const selectedContainer = filesContainers.find(({ files }) => !!files.find(({ uuid }) => fileUUID = uuid));

    await FigmaFile.deleteFigmaFile(selectedProject.uuid, fileUUID);

    if (selectedContainer.files.length == 1) {
      await FilesService.deleteContainersWithFiles(currentMilestone.id, selectedContainer.uuid);
    }

    await loadContainersWithFiles();
  }

  async function onDeleteMiroFile(fileUUID) {
    const selectedContainer = filesContainers.find(({ files }) => !!files.find(({ uuid }) => fileUUID == uuid));

    await MiroFileService.deleteMiroFile(selectedProject.uuid, fileUUID);

    if (selectedContainer.files.length == 1) {
      await FilesService.deleteContainersWithFiles(currentMilestone.id, selectedContainer.uuid);
    }

    dispatch(selectComment(null));

    await loadContainersWithFiles();
  }

  async function onDeleteGDriveFile(fileUUID) {
    const selectedContainer = filesContainers.find(({ files }) => !!files.find(({ uuid }) => fileUUID == uuid));

    await FilesService.deleteGDriveFile(selectedProject.uuid, fileUUID);

    if (selectedContainer.files.length == 1) {
      await FilesService.deleteContainersWithFiles(currentMilestone.id, selectedContainer.uuid);
    }

    dispatch(selectComment(null));

    await loadContainersWithFiles();
  }

  async function onSwapMiroFile(newMiroFileUrl) {
    try {
      await MiroFileService.updateMiroFile(
        selectedProject.uuid,
        swapFileUUID,
        newMiroFileUrl
      );
      setSwapFileModalType(null);
      setSwapFileUUID(null);
      await loadContainersWithFiles();
    }
    catch (error) {
      if (error?.response?.data?.detail) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }

  async function onSwapFigmaFile(newFigmaFileUrl) {
    try {
      await FigmaFile.updateFigmaFile(
        selectedProject.uuid,
        swapFileUUID,
        newFigmaFileUrl
      );
      setSwapFileModalType(null);
      setSwapFileUUID(null);
      await loadContainersWithFiles();
    }
    catch (error) {
      if (error?.response?.data?.detail) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }

  async function onSwapGDriveFile(newGDriveFile, containerType, previousFileUUID, swapGDriveFileUUID) {
    try {
      if (containerType == FilesService.CONTAINER_TYPES.pngNumber) {
        await onAddGDriveFile(newGDriveFile, containerType, previousFileUUID);

        setIsLoading(true);

        await FilesService.deleteGDriveFile(selectedProject.uuid, swapGDriveFileUUID);
      }
      else {
        await FilesService.deleteGDriveFile(selectedProject.uuid, swapGDriveFileUUID);
        await onAddGDriveFile(newGDriveFile, containerType, prevFileUUID);
      }

      setSwapFileUUID(null);

      await loadContainersWithFiles();
    }
    finally {
      setIsLoading(false);
    }
  }

  function getContainerByType(type) {
    return filesContainers.find(({ container_type }) => container_type == type);
  }

  function handleOpenGDrivePicker({ onPicked, ...options }) {
    openPicker({
      clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      developerKey: `${process.env.REACT_APP_PICKER_DEVELOPER_KEY}`,
      viewId: 'DOCS',
      token: gDriveProvider.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: ({ action, docs }) => {
        if (action == google.picker.Action.PICKED) {
          onPicked(docs);
        }
      },
      ...options,
    });
  }

  async function onDownloadFile(fileUUID) {
    const file = filesContainersWithFiles
      .map(({ files }) => files)
      .flat()
      .find(({ uuid }) => uuid == fileUUID);
    const downloadLink = getDownloadLinkForGDriveFile(selectedProject.uuid, fileUUID);

    saveAs(await (await fetch(downloadLink)).blob(), file.file_name);
  }

  function getDownloadLinkForGDriveFile(fileUUID) {
    return FilesService.getDownloadLinkForGDriveFile(selectedProject.uuid, fileUUID);
  }

  function closeSwapFileModal() {
    setPrevFileUUID(null);
    setSwapFileModalType(null);
  }

  function setCanvasRef(ref) {
    setAnnotationsCanvasRef(ref);
  }
}

FilesList.propTypes = {
  isReadonly: PropTypes.bool,
};
