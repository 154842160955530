import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export function SettingsTabItem({ children, isActive, ...props }) {
  return (
    <div
      className={cn(
        styles.navigation__button,
        styles.tab__inactive,
        {
          [styles.tab__active]: isActive,
        }
      )}
      {...props}
    >
      {children}
    </div>
  );
}

SettingsTabItem.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
};
