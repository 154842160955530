import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowUpRightFromSquare,
  faEarthAmerica,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';

import { selectProject } from 'Redux/Actions/projectActions';

import { successReactToastify, errorReactToastify } from 'utlis/toasts';
import { onLoadImageError } from 'utlis/globalFunctions';

import { ExportProfileCard } from 'Components/ExpertProfileCard';
import { Button } from 'Components/Button';

import defaultUserAvatar from 'assets/user_avatar.svg';

import { Experts as ExpertsService } from 'services/experts';
import CollaboratorService from 'services/collaborator';

import styles from './styles.module.scss';

export default function Experts() {
  const dispatch = useDispatch();

  const [selectedExpert, setSelectedExpert] = useState(null);
  const [expertList, setExpertList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const selectedProject = useSelector(state => state.selectedProject);

  const isInvited = useMemo(
    () => selectedProject.invites.find(
      invite => (invite.invitee_email === selectedExpert?.email)
    ),
    [selectedExpert, selectedProject]
  );

  const inviteDetails = useMemo(
    () => (
      {
        email: selectedExpert?.email,
        user_type: 3,
      }
    ),
    [selectedExpert]
  );

  useEffect(() => {
    setIsLoading(true);
    ExpertsService.getExperts(
      undefined,
      {
        cache: {
          maxAge: 24 * 60 * 60 * 1000, // 1 Day into milliseconds
        },
      }
    )
      .then(resp => setExpertList(resp.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="flex align-center just-center pt-lg">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="mt-28">
      {
        selectedExpert
          ? (
            <div className="mx-32">
              <button className={styles.back__button} onClick={() => setSelectedExpert(null)}>
                <FontAwesomeIcon className="mr-12" icon={faArrowLeft} />
                <span className="text-14 text-weight-500">
                  All experts
                </span>
              </button>
              <div className="flex mt-32">
                <img
                  className={styles.expert__avatar}
                  src={selectedExpert.profile_picture}
                  onError={e => onLoadImageError(e, defaultUserAvatar)}
                  alt="expert profile image"
                />
                <div className="flex flex-column ml-20">
                  <span className="text-28 text-weight-600">
                    {selectedExpert.name}
                  </span>
                  <span className="text-14 text-weight-500 mt-4">
                    <FontAwesomeIcon className="mr-sm" icon={faEarthAmerica} />
                    {selectedExpert.country}
                  </span>
                </div>
              </div>
              <div className="mt-16 text-14 text-weight-500">
                Bio
              </div>
              <p className={styles.expert__subtitle}>
                {selectedExpert.bio}
              </p>
              {
                isConfirm
                  ? (
                    <>
                      <div className="mt-20 text-16 text-weight-600">
                        {isInvited ? 'Confirm removing' : 'Confirm adding to workspace'}
                      </div>
                      <Button className={styles.call__button} onClick={handleConfirm}>
                        CONFIRM
                      </Button>
                      <Button.White className={styles.call__button} onClick={() => setIsConfirm(false)}>
                        CANCEL
                      </Button.White>
                    </>
                  )
                  : (
                    <>
                      {
                        isInvited
                          ? (
                            <div className={styles.expert__added}>
                              <div className="text-14 text-weight-500">
                                <FontAwesomeIcon className="mr-8" icon={faCircleCheck} />
                                { isInvited.status === 'Pending' ? 'Invited' : 'Added' } to workspace
                              </div>
                              <Button isText className={styles.remove__user} onClick={() => setIsConfirm(true)}>
                                <p className={styles.remove}>
                                  Remove user
                                </p>
                              </Button>
                            </div>
                          )
                          : (
                            <Button
                              className={styles.call__button}
                              onClick={() => setIsConfirm(true)}
                            >
                              ADD TO WORKSPACE
                            </Button>
                          )
                      }
                      <a href={selectedExpert.calendly_link} rel="noreferrer" target="_blank">
                        <Button.White className={styles.call__button}>
                          SCHEDULE A CALL
                          <FontAwesomeIcon className="ml-8" icon={faArrowUpRightFromSquare} />
                        </Button.White>
                      </a>
                    </>
                  )
              }
            </div>
          )
          : (
            <>
              <h2 className="text-xl mx-32 text-weight-600">Hire an expert</h2>
              {
                expertList?.map(
                  expert => (
                    <ExportProfileCard
                      onExpertSelect={() => setSelectedExpert(expert)}
                      key={expert.uuid}
                      {...expert}
                    />
                  )
                )
              }
            </>
          )
      }
    </div>
  );

  async function handleConfirm() {
    if (isInvited) {
      await removeExpert();
    }
    else {
      await inviteExpert();
    }
    setIsConfirm(false);
    dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
  }

  async function inviteExpert() {
    try {
      await CollaboratorService.inviteCollaborator([inviteDetails], selectedProject?.uuid);
      successReactToastify(`${selectedExpert.email} is invited to this project.`);
    }
    catch (error) {
      errorReactToastify(error.response.data.detail);
    }
  }

  async function removeExpert() {
    try {
      await CollaboratorService.removeCollaborator(selectedProject?.uuid, selectedExpert?.email);
      successReactToastify(`${selectedExpert.email} is removed from this project.`);
    }
    catch (error) {
      errorReactToastify(error.response.data.detail);
    }
  }
}
