import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

import { ProjectListingHeader } from 'Pages/Home/components/ProjectListingHeader';

import { setUserProfile } from 'Redux/Actions/userActions';

import { SettingsTabs } from './components/SettingsTabs';

import styles from './styles.module.scss';

export const Settings = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);

  useEffect(() => {
    if (authUser && authUser.uuid && !userProfile?.uuid) {
      setIsLoading(true);
      dispatch(setUserProfile(authUser))
        .finally(() => setIsLoading(false));
    }
  }, [authUser]);

  return (
    <div className={styles['settings-wrapper']}>
      <ProjectListingHeader />
      <div className={styles.container}>
        <div className="mt-52">
          <div className="flex space-between">
            <span className={styles['settings-heading']}>
              Settings
            </span>
          </div>
        </div>
        <SettingsTabs />
        {
          isLoading
            ? (
              <div className="flex flex-component-center px-lg py-lg width-full">
                <Spin size="large" />
              </div>
            )
            : <Outlet />
        }
      </div>
    </div>
  );
};
