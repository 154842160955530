import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export const IframeContainer = ({ gDriveFileUrl, url, className, ...props }) => {
  const src = useMemo(
    () => url
      ? url
      : (
        gDriveFileUrl?.indexOf('preview') === -1 && gDriveFileUrl?.indexOf('docs') === -1
          ? gDriveFileUrl?.replace('view', 'preview')
          : gDriveFileUrl
      ),
    [gDriveFileUrl, url]
  );

  return (
    <div
      className={cn(styles['drive-thumbnail'], className)}
      {...props}
    >
      <iframe
        className={styles['drive-thumbnail-iframe']}
        src={src}
        allow="autoplay; fullscreen; clipboard-read; clipboard-write"
        allowFullScreen
      />
    </div>
  );
};

IframeContainer.propTypes = {
  gDriveFileUrl: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};
