import { getPreviewFromContent } from 'link-preview-js';

import { ApiManager } from 'utlis/api_manager';

const htmlPageApi = new ApiManager(process.env.REACT_APP_BASE_URL, 2, 'project/form/html_page');

export default class LinkPreviewService {
  static async getLinkPreview(link) {
    const { html, header } = (await htmlPageApi.post({
      url: link,
    }))?.data?.data;

    return getPreviewFromContent({
      data: html,
      headers: {
        'content-type': header['Content-Type'],
      },
      url: link,
    });
  }
}
