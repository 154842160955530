import PropTypes from 'prop-types';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

export const ConnectApp = ({ title, onClick, isDisabled }) => (
  <div className="flex align-center">
    <Button
      className={cn('mr-16', styles['connect__button'])}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span className={cn(styles['button__title'])}>
        Connect
        {' '}
        {title}
      </span>
      <FontAwesomeIcon className={styles['connect__icon']} icon={faArrowUpRightFromSquare} />
    </Button>
    <span className={styles['status']}>
      Not connected
    </span>
  </div>
);

ConnectApp.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
