import actionTypes from 'Redux/constants';

export function setKanbanBoardTasks(tasks) {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.setKanbanBoardTasks, payload: tasks });
  };
}

export function setFilteredTasks(filteredTasks) {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.setFilteredTasks, payload: filteredTasks });
  };
}

export function removeKanbanBoard() {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.removeKanbanBoard, payload: {} });
  };
}

export function setSavedFigmaFiles(data) {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.setSavedFigmaFiles, payload: data });
  };
}

export function setCurrentProject(project) {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.setCurrentProject, payload: project });
  };
}

export function setProjectFigmaCollaborators(data) {
  return dispatch => {
    dispatch({ type: actionTypes.kanbanBoard.setProjectFigmaCollaborators, payload: data });
  };
}
