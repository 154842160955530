import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as mixpanel from 'mixpanel-figma';

import 'react-toastify/dist/ReactToastify.css';

import './styles/App.scss';

import { initializeFeedFiles } from 'Redux/Actions/FeedActions';

import { readonlyRoutes, routes } from 'routes/routes';
import { RoutesWithSubRoutes } from 'routes/RoutesWithSubRoutes';

import { identifyUser } from 'services/eventTracking';

import { isUserInReadOnlyMode } from 'utlis/common';
import { NOTIFICATION_TYPES } from 'utlis/constants';
import { checkingUserType, logoutUser } from 'utlis/globalFunctions';
import {
  setAuthorizationToken,
  setProjectStoreDispatch,
} from 'utlis/http';
import { onSocketMessage } from 'utlis/socket';
import { infoReactToastify } from 'utlis/toasts';

import notificationStyles from './notification_styles.module.scss';

// disabling via config just in case
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
  disable_cookie: true,
  disable_persistence: true,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_GTAG_KEY);

function App() {
  const userProfile = useSelector(state => state.userProfile);
  const selectedProject = useSelector(state => state.selectedProject);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isReadonlyPage = isUserInReadOnlyMode();

  // URL params to convert dummy into design pro user
  const writeKey = searchParams.get('writeKey');
  const userID = searchParams.get('userID');
  const returnURL = searchParams.get('returnURL');

  const userObject = window.localStorage.getItem('userObject');

  // Logout user when user come from plugin to login design pro account
  useEffect(
    () => {
      if (writeKey && returnURL && userID && userObject) {
        const webURL = location.href;

        logoutUser(dispatch, navigate);

        window.location.assign(webURL);
      }
    },
    [returnURL, userObject]
  );

  useEffect(() => {
    setAuthorizationToken();
    setProjectStoreDispatch(dispatch);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: pathname,
    });
  }, [pathname]);

  useEffect(
    () => onSocketMessage(
      ({ title, body }) => {
        infoReactToastify(
          (
            <div className={notificationStyles['notification-wrapper']}>
              <span className={notificationStyles['notification-title']}>
                {title}
                :
              </span>
              <p dangerouslySetInnerHTML={{ __html: body }} />

            </div>
          ),
          {
            toastId: `${title}: ${body}`,
          }
        );
      },
      {
        excludeNotificationTypes: [
          NOTIFICATION_TYPES.FORM_EDIT,
          NOTIFICATION_TYPES.COMMENT_EDIT,
          NOTIFICATION_TYPES.COMMENT_DELETE,
          NOTIFICATION_TYPES.CREATE_NOTE,
          NOTIFICATION_TYPES.EDIT_NOTE,
          NOTIFICATION_TYPES.DELETE_NOTE,
          NOTIFICATION_TYPES.POST_VOTE,
          NOTIFICATION_TYPES.REACTION_ADDED,
          NOTIFICATION_TYPES.REACTION_MINUSED,
          NOTIFICATION_TYPES.MILESTONE_SEQUENCE_UPDATED,
        ],
      }
    ),
    [selectedProject]
  );
  useEffect(
    () => onSocketMessage(
      ({ data: { project_uuid } }) => {
        if (selectedProject?.uuid && project_uuid == selectedProject.uuid) {
          dispatch(initializeFeedFiles(selectedProject));
        }
      },
      {
        triggeredNotificationTypes: [
          NOTIFICATION_TYPES.MILESTONE_START,
          NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
          NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
          NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT,
          NOTIFICATION_TYPES.FORM_SUBMIT,
          NOTIFICATION_TYPES.JOIN_PROJECT,
        ],
      }
    ),
    [selectedProject]
  );

  useEffect(() => {
    checkingUserType(userProfile, navigate);
  }, [userProfile, navigate]);

  useEffect(() => {
    identifyUser(userProfile.uuid);
  }, [userProfile]);

  return (
    <>
      <RoutesWithSubRoutes
        routes={!isReadonlyPage ? routes : readonlyRoutes}
      />
      <ToastContainer
        bodyStyle={{ overflowWrap: 'anywhere' }}
        closeButton={false}
        style={{ width: '350px' }}
      />
    </>
  );
}

export default App;
