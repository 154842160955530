import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';
import { NameFormItem } from 'Components/NameFormItem';

import styles from './styles.module.scss';

export default function AddNewMilestoneModal({
  milestoneName,
  handleCreateMilestone,
  onCancel,
  ...props
}) {
  const [form] = Form.useForm();
  const projectNameField = useRef();

  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(
    () => {
      if (projectNameField.current) {
        projectNameField.current.focus();
      }
    },
    [projectNameField.current]
  );

  useEffect(
    () => {
      form.setFieldsValue({ name: milestoneName });
    },
    [milestoneName]
  );

  return (
    <Modal
      destroyOnClose
      className={styles.newMilestone__modal}
      onCancel={closeModalWindow}
      width={750}
      footer={null}
      closeIconProps={{
        style: {
          marginTop: '28px',
        },
      }}

      {...props}
    >
      <Form
        form={form}
        disabled={isSubmiting}
      >
        <h4 className={styles.newMilestone__modalHeading}>
          Name your milestone
        </h4>
        {/* Milestone name */}
        <div
          className={cn(
            'flex flex-column',
            styles.milestoneName
          )}
        >
          <span className={styles.milestoneName__title}>
            Milestone name
          </span>
          <NameFormItem
            name="name"
            autofocus
            placeholder="Add Milestone Name"
            maxLength={41}
            onPressEnter={handleSubmit}
          />
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.modalFooter__buttons}>
            <Button
              isText
              className={styles.addMilestone__transparentButton}
              onClick={() => closeModalWindow()}
              disabled={isSubmiting}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              className={styles.addMilestone__primaryModalButton}
              onClick={handleSubmit}
              loading={isSubmiting}
              disabled={isSubmiting}
            >
              CREATE MILESTONE
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );

  async function handleSubmit() {
    await form.validateFields();
    const { name } = form.getFieldValue();

    try {
      setIsSubmiting(true);

      await handleCreateMilestone(name, closeModalWindow);
    }
    finally {
      setIsSubmiting(false);
    }
  }

  function closeModalWindow() {
    form.resetFields();

    if (onCancel) {
      onCancel();
      form.setFieldValue('name', milestoneName);
    }
  }
}

AddNewMilestoneModal.propTypes = {
  milestoneName: PropTypes.string,
  handleCreateMilestone: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
