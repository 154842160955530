import { useState } from 'react';
import PropTypes from 'prop-types';
import { faFile, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image as Img, Spin } from 'antd';
import mimeTypes from 'mime-types';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { LinkPreviewData } from 'Components/LinkPreviewData';

import { IMAGE_MIME_TYPES } from 'utlis/constants';
import { getFileNameFromURL } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export function SelectFileOrLinkField({
  value,
  hideRemoveButton,
  disabledRemoveButton,
  hideUploadButton,
  disabledUploadButton,
  openSelectFileOrLinkModal,
  onRemoveFile,
}) {
  const [isRemoveFile, setIsRemoveFile] = useState();

  return (
    <div className="flex flex-wrap align-start">
      <Button
        className={cn({
          hide: hideUploadButton,
        })}
        isInvert
        disabled={disabledUploadButton}
        onClick={openSelectFileOrLinkModal}
      >
        Upload
      </Button>
      {
        (value || []).map(link => (
          <Spin
            key={link}
            wrapperClassName={cn(styles['file-icon-wrapper'], 'ml-24')}
            spinning={isRemoveFile == link}
          >
            {
              IMAGE_MIME_TYPES.indexOf(mimeTypes.lookup(link)) == -1
                ? (
                  <a
                    href={link}
                    className={cn(styles['file-icon'], 'flex flex-component-center text-25 text-black')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {mimeTypes.extension(mimeTypes.lookup(link)) || (
                      <LinkPreviewData.ImageOrIcon
                        className={styles['image']}
                        link={link}
                        preview={false}
                        placeholder={<FontAwesomeIcon icon={faFile} />}
                      />
                    )}
                  </a>
                )
                : (
                  <Img
                    src={link}
                    className={styles['image']}
                  />
                )
            }
            <p className="text-10 text-weight-300 letter-xs mb-zero mt-xs break-all">
              {
                mimeTypes.lookup(link)
                  ? getFileNameFromURL(link)
                  : <LinkPreviewData.Title link={link} />
              }
            </p>
            <Button
              isText
              className={cn(
                styles['remove-file-button'],
                'flex flex-component-center',
                {
                  hide: hideRemoveButton || isRemoveFile,
                }
              )}
              onClick={async () => {
                try {
                  setIsRemoveFile(link);

                  await onRemoveFile(link);
                }
                finally {
                  setIsRemoveFile(null);
                }
              }}
              disabled={disabledRemoveButton}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </Spin>
        ))
      }
    </div>
  );
}

SelectFileOrLinkField.propTypes = {
  value: PropTypes.any,
  hideRemoveButton: PropTypes.bool,
  disabledRemoveButton: PropTypes.bool,
  hideUploadButton: PropTypes.bool,
  disabledUploadButton: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  openSelectFileOrLinkModal: PropTypes.func,
};
