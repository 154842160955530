import qs from 'qs';

import http from './http';

export class ApiManager {
  constructor(domain, version = 1, url = '') {
    this.domain = domain;
    this.version = version;
    this.url = url;
    this.domainWithVersion = `${this.domain}${version ? `/api/v${version}` : ''}`;
    this.createUrl = createUrl.bind(null, this.domainWithVersion, this.url);
  }

  setUrl(url) {
    return new ApiManager(this.domain, this.version, url);
  }

  get(params, config = {}) {
    return http.get(this.createUrl(null, params), config);
  }

  getByKey(key, params = {}, config = {}) {
    return http.get(this.createUrl(key, params), config);
  }

  patch(key, data, config = {}) {
    return http.patch(this.createUrl(key), data, config);
  }
  patchWithQuery(key, params, data, config = {}) {
    return http.patch(this.createUrl(key, params), data, config);
  }

  post(data, config = {}) {
    return http.post(this.createUrl(), data, config);
  }

  put(key, data, config = {}) {
    return http.put(this.createUrl(key), data, config);
  }

  delete(key, config = {}) {
    return http.delete(this.createUrl(key), config);
  }

  http(config) {
    return http(config);
  }
}

export function createUrl(domain, url, key, params) {
  const paramsStr = qs.stringify(
    {
      ...params,
    },
    {
      indices: false,
    }
  );

  return `${domain}/${url ? `${url}/` : ''}${key ? `${key}/` : ''}${paramsStr ? `?${paramsStr}` : ''}`;
}
