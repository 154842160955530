import { serialize as objectToFormData } from 'object-to-formdata';

import actionTypes from 'Redux/constants';

import { getAllProjectTypes, getProjectStatusCountService } from 'services/projectServices';

import http from 'utlis/http';
import { getProjectThumbnail } from 'utlis/image';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

export const initializeProjects = screenStatus => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/?status=${screenStatus || 1}`,
    };

    await http(config).then(response => {
      dispatch({
        type: actionTypes.INITIALIZE_PROJECTS,
        payload: response.data,
      });
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const updateProjects = (projectObject, setIsLoading) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: projectObject?.next,
    };

    http(config)
      .then(response => {
        dispatch({
          type: actionTypes.UPDATE_PROJECTS,
          payload: response.data,
        });

        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log(error);

        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeProjects = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_PROJECTS, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const addNewProject = (
  projectName,
  projectTypeId,
  projectsCount,
  noDispatch
) => async dispatch => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: objectToFormData({
      type: projectTypeId,
      name: projectName,
      thumbnail: await getProjectThumbnail(projectsCount),
    }),
  };

  const response = await http(config);

  if (!noDispatch) {
    dispatch({
      type: actionTypes.ADD_NEW_PROJECT,
      payload: response.data,
    });
  }

  return response.data;
};

export const selectProject = (
  projectUUID,
  projectSlug,
  reqConfig = {}
) => async dispatch => {
  try {
    const conditionalUrl = projectUUID
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/${projectUUID}/`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/${projectSlug}/`;
    const config = {
      method: 'get',
      url: conditionalUrl,
      ...reqConfig,
    };

    return http(config).then(response => {
      dispatch({
        type: actionTypes.SELECTED_PROJECT,
        payload: response.data,
      });

      return response.data;
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeSelectedProjects = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_SELECTED_PROJECT, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const markProjectAsCompleted = selectedProject => () => http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/${selectedProject?.uuid}/mark_complete/`,
});

export const renameProject = (projectID, newName) => () => http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/${projectID}/`,
  data: { 'name': newName },
});

export const deleteProject = (project, screenStatus) => async dispatch => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/projects/${project?.uuid}`,
    };

    await http(config);
    dispatch({ type: actionTypes.DELETE_PROJECT, payload: project });
    successReactToastify(`${project?.name} is successfully deleted.`);

    return dispatch(initializeProjects(screenStatus));
  }
  catch (error) {
    errorReactToastify(error?.response?.data?.detail);
  }
};

export const getProjectType = (
  setProjectTypeObject,
  value,
  filterMilestonesByProjectType
) => async dispatch => {
  try {
    await getAllProjectTypes()
      .then(response => {
        if (filterMilestonesByProjectType) {
          response?.data?.results?.filter(projectType => {
            if (projectType?.name === value) {
              if (setProjectTypeObject) {
                setProjectTypeObject(projectType?.uuid);
              }
              dispatch({
                type: actionTypes.SET_PROJECT_TYPE_MILESTONES,
                payload: projectType,
              });
            }
          });
        }
        else {
          setProjectTypeObject([]);
          response?.data?.results?.map(projectObject => {
            setProjectTypeObject(prevState => [
              ...prevState,
              projectObject?.name,
            ]);
          });
        }
      })
      .catch(error => {
        errorReactToastify(error.response.data.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeProjectTypeMilestones = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_PROJECT_TYPE_MILESTONES, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getProjectDiscovery = async projectUUID => (
  ((await http.get(`${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/`)).data || [])
    .map(questionObj => {
      questionObj.questions = questionObj?.questions
        ?.sort(({ sequence_no: a }, { sequence_no: b }) => a - b)
        ?.map((question, idx) => ({
          ...question,
          is_connected_with_next: !!questionObj.questions?.[idx + 1]?.is_connected_with_previous,
        }));

      return questionObj;
    })
);

export const loadProjectDiscovery = (
  authToken,
  projectUUID
) => async dispatch => await getProjectDiscovery(authToken, projectUUID)
  .then(response => {
    dispatch({
      type: actionTypes.SELECTED_PROJECT_DISCOVERY,
      payload: response,
    });
  });

export const updateProjectDiscovery = (
  projectUUID,
  data
) => async dispatch => await http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/update_form/`,
  data: {
    answers: (Object.keys(data))
      .map(
        questionUUID => ({
          question_uuid: questionUUID,
          answer: data[questionUUID],
        })
      ),
  },
})
  .then(() => getProjectDiscovery(projectUUID))
  .then(response => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_PROJECT_DISCOVERY,
      payload: response,
    });
  });

export const addFileToProjectDiscovery = (
  projectUUID,
  questionUUID,
  file,
  config
) => async dispatch => await http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/add_file/`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data: objectToFormData({
    question_uuid: questionUUID,
    file,
  }),
  ...config,
})
  .then(() => processingResultOfAddingFilesOrLinks(projectUUID, questionUUID, dispatch));

export const addLinkToProjectDiscovery = (
  projectUUID,
  questionUUID,
  link
) => async dispatch => await http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/add_link/`,
  data: {
    question_uuid: questionUUID,
    link,
  },
})
  .then(() => processingResultOfAddingFilesOrLinks(projectUUID, questionUUID, dispatch));

function processingResultOfAddingFilesOrLinks(projectUUID, questionUUID, dispatch) {
  return getProjectDiscovery(projectUUID)
    .then(discoveryData => {
      dispatch({
        type: actionTypes.UPDATE_SELECTED_PROJECT_DISCOVERY,
        payload: discoveryData,
      });

      return discoveryData
        .map(({ questions }) => questions)
        .flat()
        .find(({ uuid }) => uuid === questionUUID)
        .answer.answer;
    });
}

export const removeFileFromProjectDiscovery = (
  projectUUID,
  questionUUID,
  fileIdx
) => async dispatch => await http({
  method: 'delete',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/remove_file/`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data: objectToFormData({
    question_uuid: questionUUID,
    file_index: fileIdx,
  }),
})
  .then(() => getProjectDiscovery(projectUUID))
  .then(discoveryData => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_PROJECT_DISCOVERY,
      payload: discoveryData,
    });
  });

export const addQuestionToBriefForm = (
  projectUUID,
  questionObj
) => http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/add_question/`,
  data: {
    prev_question: questionObj.prev_question,
    question_format: questionObj.question_format,
    question_text: questionObj.question_text,
    question_choices: questionObj.question_choices,
    place_holder: questionObj.place_holder || questionObj.question_choices?.[0],
    category: questionObj.category,

    is_connected_with_previous: false,
    is_required: true,
  },
});

export const editQuestionInBriefForm = (
  projectUUID,
  questionObj
) => http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/edit_question/`,
  data: {
    question_uuid: questionObj.uuid,
    question_text: questionObj.question_text,
    question_choices: questionObj.question_choices,
    place_holder: questionObj.place_holder || questionObj.question_choices?.[0],
  },
});

export const deleteQuestionFromBriefForm = (
  projectUUID,
  questionsUUIDs
) => http({
  method: 'delete',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/remove_question/`,
  data: {
    questions: questionsUUIDs,
  },
});

export const changeSequenceQuestionsInBriefForm = (
  projectUUID,
  sequenceQuestionsUUID
) => http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/move_question/`,
  data: {
    questions: sequenceQuestionsUUID,
  },
});

export const submitProjectDiscovery = projectUUID => async dispatch => await http({
  method: 'post',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/form/`,
})
  .then(() => getProjectDiscovery(projectUUID))
  .then(discoveryData => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_PROJECT_DISCOVERY,
      payload: discoveryData,
    });
  });

export const getProjectProposal = projectUUID => async dispatch => await http({
  method: 'get',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/proposal/`,
})
  .then(response => {
    dispatch({
      type: actionTypes.SELECTED_PROJECT_PROPOSAL,
      payload: response.data,
    });

    return response.data;
  });

export const updateProjectProposal = (
  projectUUID,
  data
) => async dispatch => await http({
  method: 'patch',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/proposal/update_proposal/`,
  data: {
    proposal_json: data,
  },
})
  .then(() => dispatch(getProjectProposal(projectUUID)));

export const submitProjectProposal = projectUUID => async dispatch => await http({
  method: 'post',
  url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUUID}/proposal/`,
})
  .then(() => dispatch(getProjectProposal(projectUUID)));

export const getProjectStatusCountAction = () => async dispatch => {
  const projectStatusCount = await getProjectStatusCountService();

  dispatch({
    type: actionTypes.GET_PROJECT_STATUS_COUNT,
    payload: projectStatusCount,
  });

  return projectStatusCount;
};

export const stopPromptAutofillMilestoneFromDesignBrief = async (projectUuid, milestoneId) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/${projectUuid}/milestone/disable_copy_information_prompt/?milestone_id=${milestoneId}`;
  const response = await http.patch(url);

  return response;
};

export const autofillMilestoneFromDesignBrief = async (isProposal, projectUuid, milestoneId) => {
  const mileStoneType = isProposal ? '/proposal/autofill_proposal/' : '/contract_document/autofill_contract/';
  const urlAutofill = `${process.env.REACT_APP_BASE_URL}/api/v2/project/${projectUuid}${mileStoneType}`;
  const method = isProposal ? 'patch' : 'put';
  const response = await http[method](urlAutofill, milestoneId);

  await stopPromptAutofillMilestoneFromDesignBrief(projectUuid, milestoneId);

  return response;
};
