import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const scrollOptions = {
    top: 0,
    left: 0,
    behavior: 'instant',
  };

  useEffect(() => {
    window.scrollTo(scrollOptions);
  }, [pathname]);

  return null;
}
