import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import ReadonlyRoute from './ReadonlyRoute';

export const RoutesWithSubRoutes = ({ routes }) => (
  <Routes>
    {SubRoutes(routes)}
  </Routes>
);

function SubRoutes(routes) {
  return routes.map(({ path, component, children, isPrivate, isReadonly, index }) => (
    <Route
      path={path}
      key={component.type.name + path}
      index={index}
      element={
        isPrivate
          ? <PrivateRoute component={component} />
          : (
            isReadonly
              ? <ReadonlyRoute component={component} />
              : component
          )
      }
    >
      {children && SubRoutes(children)}
    </Route>
  ));
}

RoutesWithSubRoutes.propTypes = {
  routes: PropTypes.array,
};
