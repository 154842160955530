import PropTypes from 'prop-types';

import { IframeContainer } from 'Components/IframeContainer';
import TopTools from 'Components/ImageWithComments/components/TopTools';
import { VotingTools } from 'Components/ImageWithComments/components/VotingTools/index';

import styles from './styles.module.scss';

export const MiroFile = ({
  file,

  onAddFile,
  onDeleteFile,
  onSwapFile,
}) => (
  <>
    <IframeContainer
      url={file.board_url}
      fullWidth
    />
    <VotingTools
      file={file}
      className={styles['vote-tools']}
      fileType={VotingTools.fileTypes.miroFile}
    />
    <TopTools
      openUrlInNewTab={file.board_url}
      file={file}
      onAddFile={onAddFile}
      onDeleteFile={onDeleteFile}
      onSwapFile={onSwapFile}
    />
  </>
);

MiroFile.propTypes = {
  file: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    board_url: PropTypes.string.isRequired,
  }),
  figmaFile: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    node_ids: PropTypes.array.isRequired,
    file_key: PropTypes.string.isRequired,
    file_name: PropTypes.string.isRequired,
  }),

  onAddFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onSwapFile: PropTypes.func,
};
