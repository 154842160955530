import { ROUTES } from 'routes/route.constants';

import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL);

export default class JiraService {
  static async connectJiraAccount() {
    return (await api.setUrl('user/jira_connect').post({
      return_url: `${process.env.REACT_APP_DESIGN_PRO_BASE_URL}${ROUTES.JIRA_AUTH_COMPLETE}`,
    })).data.url;
  }
}
