export const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const urlRegExp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*/i;

export const figmaUrlRegExp = /https:\/\/[\w.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

export const googleDocsUrlRegExp = /^https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9_-]+)(\/.*)?$/;

export const miroUrlRegExp = /^https:\/\/miro\.com\/app\/board\/[^/]+=\/$/;

export const adobeIllustratorRegExp = /\.ai$/;

export const colorHexRegExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const readonlyRegExp = /(\/readonly\/[\w\d-]+)\/?.*/;

export const userNameRegExp = /^[a-zA-Z\u00C0-\u02AB'´`\s-]+\.?$/i;

export const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

export function isPasswordValid(value) {
  return value && passwordRegexp.test(`${value}`.trim());
}

export function isEmail(value) {
  return value && emailRegexp.test(`${value}`.trim());
}

export function isURL(value) {
  return value && urlRegExp.test(`${value}`.trim());
}

export function isFigmaURL(value) {
  return value && figmaUrlRegExp.test(`${value}`.trim());
}

export function isAdobeIllustratorFile(value) {
  return value && adobeIllustratorRegExp.test(`${value}`.trim());
}

export function isColorHex(value) {
  return value && colorHexRegExp.test(`${value}`.trim());
}

export function isCorrectRange(value, minValue, maxValue) {
  return value >= minValue && value <= maxValue;
}

export function isUserName(value) {
  return value && userNameRegExp.test(`${value}`.trim());
}
