import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { setSignedInUser } from 'Redux/Actions/userActions';

import { parseJwt } from 'utlis/globalFunctions';
import { socketInit } from 'utlis/socket';

import { ROUTES } from './route.constants';

const PrivateRoute = ({ component }) => {
  const navigate = useNavigate();
  const reduxSignedInUser = useSelector(state => state.userStore);

  const dispatch = useDispatch();

  const userObject = JSON.parse(localStorage.getItem('userObject'));
  const jwtToken = parseJwt(userObject?.access || reduxSignedInUser?.access);

  useEffect(
    () => {
      dispatch(setSignedInUser(userObject));

      if (
        userObject?.access
          && (!window.webSocket || window.webSocket.readyState >= WebSocket.CLOSING)
      ) {
        socketInit(userObject.access);
      }
    },
    []
  );

  useEffect(() => {
    if (!window.localStorage.getItem('authenticated')) {
      window.localStorage.clear();
      navigate('/');
    }
  }, [userObject]);

  return (userObject?.access || reduxSignedInUser?.access) && jwtToken.exp * 1000 > Date.now()
    ? component
    : <Navigate to={ROUTES.LANDING} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
