import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { setUserProfile, setWriteKey } from 'Redux/Actions/userActions';

import { identifyUser, trackEvent } from 'services/eventTracking';

import styles from './styles.module.scss';

export const AuthComplete = () => {
  const dispatch = useDispatch();

  const writeKey = localStorage.getItem('writeKey');
  const userObject = JSON.parse(localStorage.getItem('userObject'));

  const lastPage = localStorage.getItem('lastPage');

  useEffect(() => {
    if (lastPage === 'connectToFigma') {
      identifyUser(userObject.uuid);
      ReactGA.event({
        category: 'plugin-sign_up',
        action: 'plugin_sign_up_completed',
        label: 'user signed up for first time through plugin',
      });

      trackEvent(
        '[page] first time figma auth complete',
        { 'writeKey': writeKey, 'page name': 'plugin auth completed', 'last page': lastPage }
      );

      localStorage.setItem('lastPage', '');
    }
    else {
      trackEvent(
        '[page] figma auth complete',
        { 'writeKey': writeKey, 'page name': 'plugin auth completed', 'last page': 'landing page' }
      );
    }
  }, []);

  useEffect(() => {
    setWriteKey(writeKey);
  }, []);

  useEffect(() => {
    if (userObject?.uuid) {
      dispatch(setUserProfile(userObject));
    }
  }, []);

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="You're all set!"
      description="Return to Figma and start turning your comments, Slack threads and requirements into actionable tasks."
    />
  );
};
