import actionTypes from 'Redux/constants';

const appInitialState = {
  allTasks: [],
  filteredTasks: [],
  savedFigmaFiles: [],
  projectFigmaCollaborators: {},
  currentProject: {},
};

export function kanbanBoardReducer(state = appInitialState, action) {
  switch (action.type) {
    case actionTypes.kanbanBoard.setKanbanBoardTasks:
      return {
        ...state,
        allTasks: action.payload,
      };
    case actionTypes.kanbanBoard.setFilteredTasks:
      return {
        ...state,
        filteredTasks: action.payload,
      };
    case actionTypes.kanbanBoard.setCurrentProject:
      return {
        ...state,
        currentProject: action.payload,
      };
    case actionTypes.kanbanBoard.setSavedFigmaFiles:
      return {
        ...state,
        savedFigmaFiles: action.payload,
      };
    case actionTypes.kanbanBoard.setProjectFigmaCollaborators:
      return {
        ...state,
        projectFigmaCollaborators: action.payload,
      };
    case actionTypes.kanbanBoard.removeKanbanBoard:
      return {
        ...appInitialState,
      };
    default:
      return state;
  }
}
