import { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import cn from 'classnames';

import GDriveService from 'services/gdrive';

import styles from './styles.module.scss';

const gDriveFileCache = {};

function ImageComponent(
  {
    src,
    gDriveId,
    className,
    wrapperClassName,
    onLoad,
    useThumbnailIfNoOtherFile,
    loadLinkForGDriveFile,

    ...props
  },
  ref
) {
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector(state => state.userProfile);

  const imageRef = useRef();

  useEffect(
    () => {
      if (!src && gDriveId) {
        if (gDriveFileCache[gDriveId]) {
          imageRef.current.src = gDriveFileCache[gDriveId];
        }
        else {
          return GDriveService.getGDriveFileInfo(gDriveId, null, {
            cache: {
              maxAge: 5 * 60 * 1000, // 5 minutes into milliseconds
            },
          })
            .then(({ downloadUrl, webContentLink, shared, owners, thumbnailLink }) => {
              if (
                webContentLink
                  && shared
                  && owners.find(({ emailAddress }) => emailAddress === currentUser.email)
              ) {
                imageRef.current.src = webContentLink;
              }
              else if (downloadUrl) {
                return GDriveService.downloadGDriveImage(downloadUrl)
                  .then(image => {
                    if (imageRef.current) {
                      setIsLoading(true);
                      imageRef.current.src = image;
                    }
                  })
                  .catch(error => {
                    if (loadLinkForGDriveFile) {
                      return loadLinkForGDriveFile().then(link => {
                        imageRef.current.src = link;
                      });
                    }
                    if (thumbnailLink && useThumbnailIfNoOtherFile) {
                      imageRef.current.src = thumbnailLink;
                    }
                    else {
                      throw new Error(error);
                    }
                  });
              }
              else if (thumbnailLink && useThumbnailIfNoOtherFile) {
                imageRef.current.src = thumbnailLink;
              }
            })
            .catch(error => {
              if (loadLinkForGDriveFile) {
                return loadLinkForGDriveFile().then(link => {
                  imageRef.current.src = link;
                });
              }

              throw new Error(error);
            });
        }
      }
      else if (imageRef.current) {
        setIsLoading(true);
        imageRef.current.src = src;
      }
    },
    [src, gDriveId]
  );

  return (
    <div className={cn(styles['image-wrapper'], 'flex flex-component-center', wrapperClassName)} {...props}>
      <Spin
        className={cn(
          styles['spin'],
          {
            hide: !isLoading,
          }
        )}
      />
      <img
        ref={el => {
          imageRef.current = el;

          if (typeof ref === 'function') {
            ref(el);
          }
          else if (ref) {
            ref.current = el;
          }
        }}
        className={cn(styles['image'], className)}
        onLoad={event => {
          setIsLoading(false);

          if (onLoad) {
            onLoad(event);
          }
        }}
      />
    </div>
  );
}

ImageComponent.propTypes = {
  src: PropTypes.string,
  gDriveId: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onLoad: PropTypes.func,
  useThumbnailIfNoOtherFile: PropTypes.bool,
  loadLinkForGDriveFile: PropTypes.func,
};

export default forwardRef(ImageComponent);
