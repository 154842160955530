import actionTypes from 'Redux/constants';

import { fullPriceFormat } from 'utlis/string_format';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN_USER:
      return getUserInfo(action.payload);
    case actionTypes.REFRESH_AUTH_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.LOGOUT_USER:
      return {};
    default:
      return state;
  }
};

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_PROFILE:
      return getUserInfo(action.payload);
    case actionTypes.REMOVE_USER_PROFILE:
      return {};
    case actionTypes.UNLINK_STRIPE_ACCOUNT:
      return {
        ...state,
        designer_profile: {
          is_stripe_acc: false,
          is_charges_enabled: false,
          pending_balance: '0.00',
          available_balance: '0.00',
        },
      };
    case actionTypes.UNLINK_SLACK_ACCOUNT:
      if (state.appTokens.slack) {
        delete state.appTokens.slack;
      }

      return {
        ...state,
        user_tokens: state.user_tokens?.filter(item => item.provider !== 'Slack'),
      };
    default:
      return state;
  }
};

export const usersReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_USERS:
      return action.payload;
    case actionTypes.REMOVE_ALL_USERS:
      return [];
    default:
      return state;
  }
};

export function getUserInfo(userInfo) {
  return {
    firstName: userInfo.full_name?.split(' ')[0],
    lastName: userInfo.full_name?.split(' ')[1],
    permissions: {
      isDesigner: userInfo.user_type == 'Designer' || userInfo.user_type == 1,
      isClient: userInfo.user_type == 'Client' || userInfo.user_type == 2,
    },
    ...(
      userInfo.user_tokens
        ? {
          appTokens: {
            figma: userInfo.user_tokens.find(({ provider }) => provider === 'Figma'),
            googleDrive: userInfo.user_tokens.find(({ provider }) => provider === 'Google drive'),
            googleDocs: userInfo.user_tokens.find(({ provider }) => provider === 'Google docs'),
            miro: userInfo.user_tokens.find(({ provider }) => provider === 'Miro'),
            slack: userInfo.user_tokens.find(({ provider }) => provider === 'Slack'),
          },
        }
        : null
    ),
    ...userInfo,
    designer_profile: {
      ...userInfo?.designer_profile,
      currencySymbol: userInfo?.designer_profile?.currency
        ? fullPriceFormat(0, { currency: userInfo?.designer_profile?.currency })
          .replace('0', '')
          .trim()
        : null,
    },
  };
}
