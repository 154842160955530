import QueryString from 'qs';

import actionTypes from 'Redux/constants';

import http from 'utlis/http';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import { getAllContainers } from './designConceptsAction';

export const updateFeedFiles = (
  nextNotificationsBatchLink,
  setIsLoading
) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: nextNotificationsBatchLink,
    };

    await http(config).then(response => {
      dispatch({
        type: actionTypes.UPDATE_FEED_FILES,
        payload: response?.data,
      });

      if (response?.data?.next) {
        dispatch(updateFeedFiles(response?.data?.next));
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const initializeFeedFiles = (
  selectedProject,
  setIsLoading,
  unreadCount,
  milestoneId,
  setMilestoneCurrentStatus
) => async dispatch => {
  try {
    const url = milestoneId
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/feed/?${QueryString.stringify({
        unread_only: unreadCount,
        milestone_id: milestoneId,
      })}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/feed/`;

    await http.get(url)
      .then(response => {
        if (milestoneId && unreadCount) {
          setMilestoneCurrentStatus(response?.data?.results[0]);
        }
        else {
          dispatch({
            type: actionTypes.SET_FEED_FILES,
            payload: response?.data,
          });

          if (response?.data?.next) {
            dispatch(updateFeedFiles(response?.data?.next, setIsLoading));
          }
          else if (setIsLoading) {
            setIsLoading(false);
          }
        }
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeFeedFiles = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_FEED_FILES, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getMilestones = (
  selectedProject,
  setMilestones
) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/`,
    };

    return http(config).then(response => {
      if (setMilestones) {
        setMilestones(response?.data);
      }

      dispatch({
        type: actionTypes.GET_MILESTONES_STORE,
        payload: response?.data,
      });
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeMilestoneStore = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_MILESTONE_STORE, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export function actionStopPromptAutofillFromDesignBrief(milestoneId) {
  return {
    type: actionTypes.STOP_PROMPT_COPY_DESIGN_BRIEF,
    payload: {
      id: milestoneId,
    },
  };
}

export const moveMilestoneOrder = (
  selectedProject,
  milestones,
  setMilestones
) => async dispatch => {
  try {
    const milestoneIds = milestones?.map(milestone => milestone?.id);
    const data = {
      milestones_ids: milestoneIds,
    };
    const config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/move_milestones/`,

      data: data,
    };

    http(config)
      .then(response => {
        dispatch(getMilestones(selectedProject, setMilestones));
        dispatch(initializeFeedFiles(selectedProject));
        successReactToastify(response?.data?.message);
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeMilestoneObject = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_MILESTONE_OBJECT, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const deleteMilestone = (
  selectedProject,
  milestone,
  setMilestones
) => async dispatch => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/${milestone?.id}/`,
    };

    return http(config)
      .then(() => {
        dispatch(initializeFeedFiles(selectedProject));

        dispatch(getMilestones(selectedProject, setMilestones));
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const submitMilestone = (
  userProfile,
  selectedProject,
  milestoneId,
  milestoneName
) => async dispatch => {
  try {
    const url = userProfile.permissions.isDesigner
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/submit_milestone/?milestone_id=${milestoneId}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/approve_milestone/?milestone_id=${milestoneId}`;
    const method = userProfile.permissions.isDesigner ? 'patch' : 'put';
    const config = {
      method: method,
      url: url,
    };

    return await http(config)
      .then(() => {
        if (
          !['Design Concepts', 'Moodboard', 'Proposal', 'Design Brief', 'UX Brief', 'Presentation']
            .includes(milestoneName)
        ) {
          dispatch(initializeFeedFiles(selectedProject));
        }
        dispatch(getAllContainers(milestoneId));
        dispatch(getMilestones(selectedProject));
        successReactToastify('Milestone is submitted successfully');
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const addMilestone = (
  selectedProject,
  prevMilestoneId,
  milestoneName,
  milestoneType,
  setMilestones
) => async dispatch => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/milestone/`,
      data: {
        prev_milestone: prevMilestoneId,
        name: milestoneName,
        milestone_type: milestoneType,
      },
    };

    return http(config)
      .then(() => dispatch(getMilestones(selectedProject, setMilestones)));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const markNotificationAsDone = selectedProject => async dispatch => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/feed/`;

    http
      .post(url)
      .then(() => {
        dispatch(initializeFeedFiles(selectedProject));
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};
