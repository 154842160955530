import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Spin } from 'antd';
import { AxiosError } from 'axios';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { ReactQuill } from 'Components/ReactQuill';

import NotesService from 'services/notes';

import { createAbortController } from 'utlis/http';

import styles from './styles.module.scss';

// We need a repository for note versions.
// React state is asynchronous so sometimes we have old note version value
const noteVersionList = {};
const noteSaveAbortControllerList = {};

export default function Notes({
  selectedMilestone,

  className,
  mentionsList,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [timerForSaveStart, setTimerForSaveStart] = useState(undefined);
  const [noteObj, setNoteObj] = useState(null);
  const [hasUserTriedUpdateOldNote, setHasUserTriedUpdateOldNote] = useState(true);

  const selectedProject = useSelector(state => state.selectedProject);

  const noteListKey = useMemo(
    () => `${selectedProject?.uuid} - ${selectedMilestone?.id}`,
    [selectedMilestone?.id, selectedProject?.uuid]
  );

  const [form] = Form.useForm();

  useEffect(
    () => {
      setNoteObj(null);
      noteVersionList[noteListKey] = 0;

      if (selectedMilestone?.id) {
        loadOrCreateNote();
      }
    },
    [selectedMilestone?.id, selectedProject?.uuid]
  );

  if (isLoading) {
    return (
      <div className="flex align-center just-center pt-lg">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={cn(styles['notes-wrapper'], className)}>
      <p
        className={cn(
          styles['progress-bar'],
          {
            hide: timerForSaveStart === undefined,
          }
        )}
      >
        {
          timerForSaveStart
            ? 'Saving ...'
            : 'Saved'
        }
      </p>
      <Form
        initialValues={{
          notes: noteObj?.body || '',
        }}
        form={form}
        preserve
        onFieldsChange={changedFields => {
          if (changedFields.length) {
            if (timerForSaveStart) {
              clearTimeout(timerForSaveStart);
            }

            if (noteSaveAbortControllerList[noteListKey]) {
              noteSaveAbortControllerList[noteListKey].abort();

              noteSaveAbortControllerList[noteListKey] = null;

              noteVersionList[noteListKey] = noteVersionList[noteListKey] + 1;
            }

            setTimerForSaveStart(setTimeout(
              () => {
                saveNotes(form.getFieldValue());
              },
              1000
            ));
          }
        }}
        className={styles['height-100percent']}
      >
        {
          hasUserTriedUpdateOldNote && (
            <p className={cn(styles['old-note-info'], 'text-sm letter-xs text-center')}>
              Your collaborators made some changes.
              <br />
              <Button
                isText
                className={styles['reload-note-button']}
                onClick={loadOrCreateNote}
              >
                Click
              </Button>
              {' '}
              to refresh.
            </p>
          )
        }
        <Form.Item name="notes" noStyle>
          <ReactQuill
            className={cn(
              styles['custom-react-quill'],
              {
                [styles['show-old-note-info']]: hasUserTriedUpdateOldNote,
              }
            )}
            placeholder="Enter notes here..."
            readOnly={hasUserTriedUpdateOldNote}
            mentionsList={mentionsList}
            mentionsButtonClassName={styles['mention-button']}
          />
        </Form.Item>
      </Form>
    </div>
  );

  async function loadOrCreateNote() {
    try {
      setIsLoading(true);

      const [note] = await NotesService.getNotes(selectedMilestone.id);

      if (!note?.uuid) {
        await saveNotes({ notes: '' });
      }
      else {
        setNoteObj(note);
        noteVersionList[noteListKey] = note?.version || 0;
      }

      setHasUserTriedUpdateOldNote(false);

      form.setFieldsValue({
        notes: note?.body || '',
      });

      return note;
    }
    finally {
      setIsLoading(false);
    }
  }

  function saveNotes({ notes }) {
    if (noteSaveAbortControllerList[noteListKey]) {
      noteSaveAbortControllerList[noteListKey].abort();
      noteVersionList[noteListKey] = noteVersionList[noteListKey] + 1;
    }

    noteSaveAbortControllerList[noteListKey] = createAbortController();

    return NotesService.saveNote(
      selectedMilestone.id,
      noteObj?.uuid,
      notes,
      noteVersionList[noteListKey] + 1,
      {
        signal: noteSaveAbortControllerList[noteListKey].signal,
      }
    )
      .then(({ done, ...res }) => {
        if (done === false) {
          setHasUserTriedUpdateOldNote(true);
          setTimerForSaveStart(undefined);
        }
        else {
          setNoteObj(note => ({
            ...note,
            ...(res?.note || res),
            version: noteVersionList[noteListKey] + 1,
          }));
          noteVersionList[noteListKey] = noteVersionList[noteListKey] + 1;

          setTimerForSaveStart(null);
        }
      })
      .catch(error => {
        if (error?.code != AxiosError.ERR_CANCELED) {
          throw new Error(error);
        }
      })
      .finally(() => {
        noteSaveAbortControllerList[noteListKey] = null;
      });
  }
}

Notes.propTypes = {
  selectedMilestone: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,

  className: PropTypes.string,
  mentionsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
};
