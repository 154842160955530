import { Button } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function CustomButton({
  className,
  isInvert,
  isText,
  type,

  ...props
}) {
  return (
    <Button
      className={cn(
        {
          [styles['button']]: !isInvert && !isText,
          [styles['invert-button']]: isInvert && !isText,
          'text-button': isText,
        },
        className
      )}
      type={isText ? 'text' : type}
      {...props}
    />
  );
}

CustomButton.propTypes = {
  className: PropTypes.string,
  isInvert: PropTypes.bool,
  isText: PropTypes.bool,
  type: PropTypes.string,
};

const colorList = {
  Smokey: styles['smokey-button'],
  RossoCorsa: styles['rossoCorsa-button'],
  FuzzyWuzzyBrown: styles['fuzzyWuzzyBrown-button'],
  White: styles['white-button'],
};

Object.keys(colorList).forEach(buttonColor => {
  // eslint-disable-next-line react/prop-types
  CustomButton[buttonColor] = ({ className, ...props }) => ( // eslint-disable-line react/display-name, react/prop-types
    <CustomButton className={cn(colorList[buttonColor], className)} {...props} />
  );
  // display-name for previos component
  CustomButton[buttonColor].displayName = `Button.${buttonColor}`;
  CustomButton[buttonColor].propTypes = CustomButton.propTypes;
});

export { CustomButton as Button };
