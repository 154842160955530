import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import cn from 'classnames';

import figmaIconUrl from 'assets/figma_logo.svg';
import googleDocsIcon from 'assets/GoogleDocsIcon.svg';
import miroIconUrl from 'assets/miro_icon.svg';

import { Button } from 'Components/Button';

import { infoReactToastify } from 'utlis/toasts';
import { figmaUrlRegExp, googleDocsUrlRegExp, miroUrlRegExp } from 'utlis/validators';

import styles from './styles.module.scss';

export const AddLinkToFileForm = ({
  appToken,
  icon,
  onHandleConnect,
  onAddFile,

  urlRegExp,
  submitButtonText,
  rules,

  ...props
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [form] = Form.useForm();

  return appToken
    ? (
      <Form
        form={form}
        preserve
        onFinish={onSubmit}
        {...props}
      >
        <Form.Item
          className="width-full"
          name="fileUrl"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            ...(
              urlRegExp
                ? [{
                  pattern: urlRegExp,
                  message: 'This is not a valid url',
                }]
                : []
            ),
            ...rules,
          ]}
        >
          <Input
            className={cn(styles.input, 'text-md letter-sm text-black mt-20')}
            placeholder="Enter file URL"
            onChange={e => setInputValue(e.target.value)}
            prefix={
              typeof icon === 'string'
                ? <img src={icon} />
                : icon
            }
            disabled={inProgress}
          />
        </Form.Item>
        <Form.Item className="flex just-end mb-zero">
          <Button
            htmlType="submit"
            className={styles['button']}
            disabled={inProgress || !inputValue}
          >
            {
              submitButtonText || (
                inProgress
                  ? 'POSTING FILE'
                  : 'POST FILE'
              )
            }
          </Button>
        </Form.Item>
      </Form>
    )
    : (
      <div className="flex just-center pb-md">
        <Button
          className={styles.button}
          onClick={() => handleConnect()}
        >
          <span>
            LOGIN TO CONNECT
          </span>
        </Button>
      </div>
    );

  async function onSubmit({ fileUrl }) {
    try {
      setInProgress(true);

      await onAddFile(fileUrl);
    }
    finally {
      setInProgress(false);
    }
  }

  function handleConnect() {
    infoReactToastify('Please wait as we are connecting your account...');
    setTimeout(onHandleConnect, 2000);
  }
};

AddLinkToFileForm.propTypes = {
  appToken: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onHandleConnect: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  urlRegExp: PropTypes.instanceOf(RegExp),

  submitButtonText: PropTypes.string,
  rules: PropTypes.array,
};

AddLinkToFileForm.defaultProps = {
  rules: [],
};

[
  {
    name: 'Figma',
    icon: figmaIconUrl,
    urlRegExp: figmaUrlRegExp,
  },
  {
    name: 'GoogleDocs',
    icon: googleDocsIcon,
    urlRegExp: googleDocsUrlRegExp,
  },
  {
    name: 'Miro',
    urlRegExp: miroUrlRegExp,
    icon: miroIconUrl,
  },
].forEach(({ name, urlRegExp, rules, icon }) => {
  AddLinkToFileForm[name] = props => ( // eslint-disable-line react/display-name
    <AddLinkToFileForm
      urlRegExp={urlRegExp}
      icon={icon}
      rules={rules}
      {...props}
    />
  );
  // display-name for previous component
  AddLinkToFileForm[name].displayName = `AddLinkToFileForm.${name}`;
  AddLinkToFileForm[name].propTypes = AddLinkToFileForm.propTypes;
});
