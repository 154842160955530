import { ROUTES } from 'routes/route.constants';

import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL);

export default class SlackService {
  static async getSlackOAuthLink() {
    return (await api.setUrl('user/slack_connect').post({
      return_url: location.href,
    })).data.url;
  }

  static async unlinkSlackAccount() {
    return api.setUrl('user/slack_connect').delete();
  }

  static async connectSlackAccount() {
    return (await api.setUrl('user/slack_connect').post({
      return_url: `${process.env.REACT_APP_DESIGN_PRO_BASE_URL}${ROUTES.SLACK_AUTH_COMPLETE}`,
    })).data.url;
  }
}
