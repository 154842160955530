import PropTypes from 'prop-types';
import { Modal } from 'antd';
import cn from 'classnames';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

const ConfirmationModal = ({
  openConfirmModal,
  onCancel,
  onAccept,
  buttonOneText,
  buttonTwoText,
  text,
}) => (
  <Modal
    visible={openConfirmModal}
    className={styles.modalClass}
    onCancel={onCancel}
    centered
    footer={null}
    width={560}
    closable={false}
  >
    <p className="text-center">
      {text}
    </p>
    <div className="flex gap-8">
      <Button onClick={onCancel} className={cn(styles['button'], styles['cancel'])}>
        {buttonOneText}
      </Button>
      <Button onClick={onAccept} className={cn(styles['button'], styles['delete'])}>
        {buttonTwoText}
      </Button>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  openConfirmModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  buttonOneText: PropTypes.string,
  buttonTwoText: PropTypes.string,
  text: PropTypes.node,
};

export default ConfirmationModal;
