import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import shareDocLinkScreenshot from 'assets/share_doc_link_screenshot.png';

import { AddLinkToFileForm } from 'Components/FilesList/components/AddFile/components/AddLinkToFileForm';

import KanbanBoardServices from 'services/kanban_board';

import pageStyles from './styles.module.scss';

export const AddGoogleDocContent = ({ setGoogleDocDetails }) => {
  const googleDocsToken = useSelector(state => state.userStore?.appTokens?.googleDocs);

  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <>
      <p className={pageStyles['description']}>
        Click the
        {' '}
        <b>
          Share
        </b>
        {' '}
        button at the top right of the toolbar. Then, click
        {' '}
        <b>
          Copy link
        </b>
        {' '}
        and paste the link below.
      </p>
      <div className={pageStyles['image-container']}>
        <img src={shareDocLinkScreenshot} alt="Google doc link info" className={pageStyles['image']} />
      </div>
      <span className={pageStyles['file-link-title']}>
        Google Docs link:
      </span>
      <AddLinkToFileForm.GoogleDocs
        className={pageStyles['doc-form-container']}
        appToken={googleDocsToken?.provider}
        onAddFile={handleAddDocURL}
        submitButtonText="CONTINUE"
      />
      {errorMessage && (
        <span className={pageStyles['error-message']}>
          {errorMessage}
        </span>
      )}
    </>
  );

  async function handleAddDocURL(docURL) {
    try {
      const googleDocData = await KanbanBoardServices.getGoogleDocData(docURL);

      setGoogleDocDetails(googleDocData);
    }
    catch (responseError) {
      setErrorMessage(responseError?.response?.data?.error);
    }
  }
};
AddGoogleDocContent.propTypes = {
  setGoogleDocDetails: PropTypes.func.isRequired,
};
