import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import cn from 'classnames';

import slackInfo from 'assets/slack_info.png';

import { Button } from 'Components/Button';

import { KANBAN_BOARD_FILE_TYPE_VALUES } from 'utlis/constants';

import pageStyles from './styles.module.scss';

export const AddThreadLink = ({ handleAddSlackThread }) => {
  const [inputValue, setInputValue] = useState('');
  const [submittingData, setSubmittingData] = useState(false);

  const [form] = Form.useForm();

  const inputRef = useRef(null);

  return (
    <>
      <p className={pageStyles['description']}>
        Hover over the first message in the thread and click the
        {' '}
        <b>
          More actions
        </b>
        {' '}
        icon at the top right of the message. Then, click
        {' '}
        <b>
          Copy link
        </b>
        {' '}
        and paste the link below.
      </p>
      <div className={pageStyles['image-container']}>
        <img src={slackInfo} alt="slack copy link info" className={pageStyles['image']} />
      </div>
      <div className={pageStyles['add-figma-file-wrapper']}>
        <span className={pageStyles['file-link-title']}>
          Slack Thread link:
        </span>
        <Form
          form={form}
          preserve
          onFinish={onSubmit}
          className={pageStyles['form']}
        >
          <Form.Item
            name="fileUrl"
            className={pageStyles['input-container']}
          >
            <Input
              ref={inputRef}
              className={cn(pageStyles['input'])}
              placeholder="https://workspace.slack.com/"
              onChange={e => setInputValue(e.target.value)}
              disabled={submittingData}
            />
          </Form.Item>
          <Form.Item className={pageStyles['submit-button-container']}>
            <Button
              htmlType="submit"
              className={cn(
                pageStyles['button'],
                {
                  [pageStyles['disabled-button']]: submittingData || inputValue.length === 0,
                }
              )}
              disabled={submittingData || !inputValue}
            >
              {
                submittingData
                  ? 'Importing...'
                  : 'Import thread'
              }
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );

  async function importThread() {
    await handleAddSlackThread(inputValue, KANBAN_BOARD_FILE_TYPE_VALUES.SLACK);
  }

  async function onSubmit() {
    try {
      setSubmittingData(true);

      await importThread();
    }
    finally {
      setSubmittingData(false);
    }
  }
};
AddThreadLink.propTypes = {
  handleAddSlackThread: PropTypes.func.isRequired,
};
