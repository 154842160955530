import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal as AntdModal } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

export function Modal({
  className,
  closeIconProps: {
    style: closeIconStyles,
    ...closeIconProps
  } = {},
  ...props
}) {
  return (
    <AntdModal
      className={cn(styles['modal'], className)}
      closeIcon={
        <FontAwesomeIcon
          style={{ width: '20px', height: '20px', ...closeIconStyles }}
          icon={faXmark}
          {...closeIconProps}
        />
      }
      {...props}
    />
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  closeIconProps: PropTypes.object,
  disabledRemoveButton: PropTypes.bool,
  disabledUploadButton: PropTypes.bool,
  hideUploadButton: PropTypes.bool,
  isUploadSequentially: PropTypes.bool,
};
