import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as AddFileIconBG } from 'assets/AddFileIconBG.svg';

import AddFile from 'Components/FilesList/components/AddFile';

import styles from './styles.module.scss';

export default function AddFileWithWrapper({ className, AddFileButton, ...props }) {
  return (
    <div className={cn(styles['add-file-wrapper'], 'px-32 pt-32 pb-56', className)}>
      <p className="text-28 letter-xs text-notesText text-weight-bold">
        Add File
      </p>
      <div className={cn('flex flex-column align-center just-center', styles['add-file-logo'])}>
        <AddFileIconBG className="mb-lg" />
        <AddFileButton {...props} />
      </div>
    </div>
  );
}

AddFileWithWrapper.propTypes = {
  className: PropTypes.string,
  AddFileButton: PropTypes.node,
};

AddFileWithWrapper.defaultProps = {
  AddFileButton: AddFile,
};
