import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as ButtonAntd, Form, Image as Img, Input, Select, Spin, Upload } from 'antd';
import cn from 'classnames';

import emptyImage from 'assets/empty_image.svg';

import { Button } from 'Components/Button';

import { updateUserProfile } from 'Redux/Actions/userActions';

import { getBase64 } from 'utlis/globalFunctions';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';
import { isUserName } from 'utlis/validators';

import styles from './styles.module.scss';

export const Profile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const firstNameLength = form.getFieldValue('firstName')?.length;
  const lastNameLength = form.getFieldValue('lastName')?.length;

  const [previewProfileImage, setPreviewProfileImage] = useState('');
  const [previewLogoImage, setPreviewLogoImage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [isProfilePhotoDeleted, setIsProfilePhotoDeleted] = useState(false);
  const [isLogoDeleted, setIsLogoDeleted] = useState(false);

  const userProfile = useSelector(state => state.userProfile);
  const authUser = useSelector(state => state.userStore);

  return (
    <div className={cn('flex flex-column', styles.container)}>
      {
        userProfile?.uuid
        && (
          <div className="mt-48">
            <div className="mb-20 flex space-between">
              <div className="flex flex-column">
                <span className={styles['section__heading']}>
                  Profile
                </span>
                <span className={styles['section__description']}>
                  Update your name, role and address here.
                </span>
              </div>
              <div className="flex align-center">
                {
                  isSaving
                    ? (
                      <Spin className="mr-40" size="medium" />
                    )
                    : (
                      <span className={styles['progress']}>
                        {
                          isChangesSaved ? 'Changes saved' : 'Changes not saved'
                        }
                      </span>
                    )
                }
              </div>
            </div>
            <Form
              initialValues={userProfile}
              form={form}
              onFieldsChange={() => setSaveButtonStatus()}
            >
              <div className={styles['name__section']}>
                <div className={styles['section__container']}>
                  <div className={styles['title__section']}>
                    <span className={styles['title']}>
                      Name
                    </span>
                  </div>
                  <Form.Item
                    name="firstName"
                    className={cn('mr-24', styles['name__input'])}
                    rules={[
                      () => ({
                        // eslint-disable-next-line no-unused-vars
                        validator(rule, value) {
                          return value === ''
                            ? Promise.reject('This field is required')
                            : (
                              value.length + lastNameLength === 100
                                ? Promise.reject('Maximum name length exceeded')
                                : (
                                  isUserName(value)
                                    ? Promise.resolve()
                                    : Promise.reject('* Invalid input')
                                )
                            );
                        },
                      }),
                    ]}
                  >
                    <Input
                      className={styles['input_field']}
                      placeholder="First Name"
                      maxLength={100 - lastNameLength}
                      rules
                    />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    className={styles['name__input']}
                    rules={[
                      () => ({
                        // eslint-disable-next-line no-unused-vars
                        validator(rule, value) {
                          return value === ''
                            ? Promise.reject('This field is required')
                            : (
                              value.length + firstNameLength === 100
                                ? Promise.reject('Maximum name length exceeded')
                                : (
                                  isUserName(value)
                                    ? Promise.resolve()
                                    : Promise.reject('* Invalid input')
                                )
                            );
                        },
                      }),
                    ]}
                  >
                    <Input
                      className={styles['input_field']}
                      placeholder="Last Name"
                      maxLength={100 - firstNameLength}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles['email__section']}>
                <div className={styles['section__container']}>
                  <div className={styles['title__section']}>
                    <span className={styles['title']}>
                      Google login
                    </span>
                    <span className={styles['description']}>
                      This is the Google Login associated with your account.
                      If you need to update this, please contact support.
                    </span>
                  </div>
                  <Form.Item name="email" className={styles['email__input']}>
                    <Input
                      placeholder="email"
                      prefix={<FontAwesomeIcon className="ml-12 mr-8 flex" icon={faEnvelope} />}
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles['role__section']}>
                <div className={styles['section__container']}>
                  <div className={styles['title__section']}>
                    <span className={styles['title']}>
                      Role
                    </span>
                    <span className={styles['description']}>
                      This defines how the platform works for you.
                      If you need to update this, please contact support.
                    </span>
                  </div>
                  <Form.Item name="user_type">
                    <Select
                      placeholder="Role"
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles['photo__section']}>
                <div className={styles['section__container']}>
                  <div className={styles['title__section']}>
                    <span className={styles['title']}>
                      Your photo
                    </span>
                    <span className={styles['description']}>
                      This will be displayed on your profile.
                    </span>
                  </div>
                  <div className={cn('flex', styles['photo__container'])}>
                    <Img
                      className={styles['photo__image']}
                      src={
                        previewProfileImage || (
                          userProfile.profile_photo
                            ? `${userProfile.profile_photo}?_=${userProfile.updated_timestamp}`
                            : emptyImage
                        )
                      }
                      alt="profile image"
                      preview={false}
                    />
                    <ButtonAntd
                      className={styles['delete__button']}
                      disabled={!userProfile.profile_photo || isProfilePhotoDeleted}
                      onClick={deletePhoto}
                    >
                      Delete
                    </ButtonAntd>
                    <Form.Item
                      name="profile_photo"
                    >
                      <Upload
                        beforeUpload={async file => {
                          setIsProfilePhotoDeleted(false);
                          setPreviewProfileImage(await getBase64(file));

                          return false;
                        }}
                        maxCount={1}
                        showUploadList={false}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                      >
                        <ButtonAntd className={styles['upload__button']}>
                          Update
                        </ButtonAntd>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className={styles['logo__section']}>
                <div className={styles['section__container']}>
                  <div className={styles['title__section']}>
                    <span className={styles['title']}>
                      Your organization’s logo
                    </span>
                    <span className={styles['description']}>
                      This will be displayed on design briefs and client invoices.
                    </span>
                  </div>
                  <div className={cn('flex', styles['logo__container'])}>
                    <Img
                      className={styles['logo__image']}
                      src={
                        previewLogoImage || (
                          userProfile.logo_image
                            ? `${userProfile.logo_image}?_=${userProfile.updated_timestamp}`
                            : emptyImage
                        )
                      }
                      alt="logo image"
                      preview={false}
                    />
                    <ButtonAntd
                      className={styles['delete__button']}
                      disabled={!userProfile.logo_image || isLogoDeleted}
                      onClick={deleteLogo}
                    >
                      Delete
                    </ButtonAntd>
                    <Form.Item
                      name="logo_image"
                    >
                      <Upload
                        showRemoveIcon
                        beforeUpload={async file => {
                          setIsLogoDeleted(false);
                          setPreviewLogoImage(await getBase64(file));

                          return false;
                        }}
                        maxCount={1}
                        showUploadList={false}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                      >
                        <ButtonAntd className={styles['upload__button']}>
                          Update
                        </ButtonAntd>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className={cn('flex just-end', styles['buttons__section'])}>
                <div className={styles['section__container']}>
                  <Button.White
                    className={cn('mr-12', styles['cancel__button'])}
                    onClick={cancelFormChanges}
                  >
                    Cancel
                  </Button.White>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSaving || isSaveDisabled}
                  >
                    {
                      isSaving ? 'Save...' : 'Save'
                    }
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )
      }
    </div>
  );

  function setSaveButtonStatus() {
    if (form.getFieldsError().filter(({ errors }) => errors.length).length) {
      setIsSaveDisabled(true);
    }
    else {
      setIsSaveDisabled(false);
    }
    setIsChangesSaved(false);
  }

  function cancelFormChanges() {
    setPreviewProfileImage('');
    setPreviewLogoImage('');
    setIsSaveDisabled(true);
    setIsChangesSaved(true);
    setIsProfilePhotoDeleted(false);
    setIsLogoDeleted(false);
    form.resetFields();
  }

  async function handleSubmit() {
    if (!form.getFieldsError().filter(({ errors }) => errors.length).length) {
      setIsSaving(true);
      const values = form.getFieldsValue();

      const payload = {
        ...values,
        profile_photo: values.profile_photo ? getImageData(values.profile_photo) : '',
        logo_image: values.logo_image ? getImageData(values.logo_image) : '',
      };

      delete payload.user_type;

      try {
        await dispatch(updateUserProfile(
          authUser,
          payload
        ));
        successReactToastify('Profile has been updated successfully');
        setIsChangesSaved(true);
      }
      catch (error) {
        errorReactToastify(error?.response?.data?.detail);
      }
      finally {
        setIsSaveDisabled(true);
        setIsSaving(false);
      }
    }
  }

  function getImageData(value) {
    return value?.fileList && value.fileList[0] ? value.fileList[0].originFileObj : undefined;
  }

  async function deletePhoto() {
    const profilePhoto = form.getFieldValue('profile_photo');

    if (profilePhoto) {
      setPreviewProfileImage(emptyImage);
      form.setFieldsValue({
        profile_photo: null,
      });
      setSaveButtonStatus();
      setIsProfilePhotoDeleted(true);
    }
  }

  async function deleteLogo() {
    const logoImage = form.getFieldValue('logo_image');

    if (logoImage) {
      setPreviewLogoImage(emptyImage);
      form.setFieldsValue({
        logo_image: null,
      });
      setSaveButtonStatus();
      setIsLogoDeleted(true);
    }
  }
};
