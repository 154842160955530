import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { textReplace } from '@loomhq/loom-embed';
import { Divider, Spin } from 'antd';
import qs from 'qs';
import cn from 'classnames';

import { ReactComponent as CanvasPencilIcon } from 'assets/canvas_pencil.svg';
import { ReactComponent as ThumbtackIcon } from 'assets/thumbtack.svg';

import CommentComponent from 'Components/ImageWithComments/components/Comment';
import { CommentForm } from 'Components/ImageWithComments/components/CommentForm';

import { setIsAnnotationMode, setIsPinCommentMode } from 'Redux/Actions/appActions';
import {
  deleteComment,
  editComment,
  loadComments,
  selectComment, sendCommentOnMilestone,
  setCommentSuggestion,
  setNewCommentData,
} from 'Redux/Actions/commentActions';

import { isUserInReadOnlyMode } from 'utlis/common';
import { MILESTONES_TYPES } from 'utlis/constants';
import { extractLoomURL } from 'utlis/string_format';
import { errorReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export default function AllCommentsList({
  selectedMilestone,

  className,
  keepPrevComments,
  canUserSendPinComments,
}) {
  const navigate = useNavigate();
  const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const isReadOnly = isUserInReadOnlyMode();

  const [commentParam, setCommentParam] = useState(urlParams.comment_uuid || null);
  const [isLoading, setIsLoading] = useState(true);
  const [showReplies, setShowReplies] = useState(false);

  const commentsList = useSelector(state => state.commentsStore.allComments);
  const selectedComment = useSelector(state => state.commentsStore.selectedComment);
  const newCommentData = useSelector(state => state.commentsStore.newCommentData);
  const selectedCommentSuggestion = useSelector(state => state.commentsStore.commentSuggestion);
  const currentProject = useSelector(state => state.selectedProject);
  const currentUser = useSelector(state => state.userProfile);
  const { isPinCommentMode, hidePinCommentMode, isAnnotationMode } = useSelector(state => state.app);

  const dispatch = useDispatch();

  const commentsRef = useRef();
  const prevCommentListsLength = useRef(commentsList.length);

  const projectUsersList = useMemo(
    () => currentProject.uuid
      ? [
        currentProject.owner,
        ...currentProject.collaborators.map(({ user }) => user),
      ].map(user => (
        user.uuid === currentUser.uuid
          ? {
            ...user,
            full_name: `${user.full_name} (you)`,
          }
          : user
      ))
      : [],
    [currentProject]
  );

  const showCommentForm = useMemo(
    () => ![
      MILESTONES_TYPES.designConcepts,
      MILESTONES_TYPES.designReview,
    ]
      .includes(selectedMilestone?.type),
    [selectedMilestone?.type]
  );

  useEffect(
    () => {
      if (selectedMilestone?.id) {
        try {
          setIsLoading(true);
          loadAllComment();
        }
        finally {
          setIsLoading(false);
        }
      }
    },
    [selectedMilestone?.id, currentProject?.type]
  );

  useEffect(
    () => {
      if (commentParam && commentsList) {
        const comment = commentsList.find(
          ({ uuid }) => uuid == commentParam
        );

        if (comment) {
          dispatch(selectComment(comment));
        }

        else {
          isReply(commentParam);
        }
      }
    },
    [commentsList]
  );

  useEffect(
    () => {
      if (commentsList.length > prevCommentListsLength.current) {
        scrollToBottom();
        prevCommentListsLength.current = commentsList.length;
      }
    },
    [commentsList.length]
  );

  return (
    <div id="all-comments-list" className={cn(styles['comments-list-wrapper'], className)}>
      {
        !isLoading
          ? (
            <>
              <div
                ref={commentsRef}
                className={cn(
                  styles['comments'],
                  {
                    [styles['comments-with-form']]: showCommentForm,
                  }
                )}
              >
                {
                  commentsList?.length
                    ? (
                      commentsList.map(comment => (
                        <>
                          <CommentComponent
                            key={comment.uuid}
                            comment={comment}
                            className="py-16 px-md"
                            onClick={e => {
                              if (e?.target?.localName != 'textarea') {
                                dispatch(selectComment(comment));
                                updateURL();
                              }
                            }}
                            onRemoveComment={
                              !comment.is_comment_from_figma && comment.commenter?.uuid == currentUser.uuid
                                ? onRemoveComment
                                : null
                            }
                            isScrollToComment={comment.uuid == commentParam}
                            showPinIfHasCoordinates
                            showPenIfHasAnnotation
                            showActiveButtonInRow
                            onEditComment={
                              !comment.is_comment_from_figma && comment.commenter?.uuid == currentUser.uuid
                                ? editSelectedComment
                                : null
                            }
                            onReply={() => setShowReplies(!showReplies)}
                            editorProps={{
                              mentionsList: projectUsersList,
                            }}
                          />
                          <Divider
                            className={cn(
                              styles['divider'],
                              'm-zero'
                            )}
                          />
                          {
                            ((selectedComment?.uuid == comment.uuid && showReplies)) && (
                              <div className="mb-md">
                                {
                                  comment.replies.map((replyComment, replyCommentIdx) => (
                                    <>
                                      <CommentComponent
                                        key={replyComment.uuid}
                                        comment={replyComment}
                                        className="pl-60 py-16 pr-md"
                                        onClick={e => {
                                          if (e?.target?.localName != 'textarea') {
                                            dispatch(selectComment(comment));
                                            updateURL();
                                          }
                                        }}
                                        isScrollToComment={replyComment.uuid == commentParam}
                                        onRemoveComment={
                                          !replyComment.is_comment_from_figma
                                          && replyComment.commenter?.uuid == currentUser.uuid
                                            ? onRemoveComment
                                            : null
                                        }
                                        showPinIfHasCoordinates
                                        showActiveButtonInRow
                                        onEditComment={
                                          !replyComment.is_comment_from_figma
                                          && replyComment.commenter?.uuid == currentUser.uuid
                                            ? editSelectedComment
                                            : null
                                        }
                                        editorProps={{
                                          mentionsList: projectUsersList,
                                        }}
                                      />
                                      <Divider
                                        className={cn(
                                          'my-zero invert-ml-md',
                                          {
                                            hide: replyCommentIdx === comment.replies.length - 1,
                                          }
                                        )}
                                      />
                                    </>
                                  ))
                                }
                                {
                                  !isReadOnly
                                    && (
                                      <CommentForm
                                        className="mx-md mt-sm"
                                        textAreaClassName={styles['reply-text-area']}
                                        autoFocusOnMessage={false}
                                        onSubmit={(
                                          {
                                            message,
                                            file_links,
                                          }
                                        ) => onSaveComment(
                                          {
                                            file_links,
                                            message,
                                            parent_comment: comment.uuid,
                                          }
                                        )}
                                        messagePlaceholder="Add a comment..."
                                        withTools={false}
                                        submitButtonText="Reply"
                                        onCancel={() => setShowReplies(false)}
                                        mentionsList={projectUsersList}
                                        fileType={newCommentData?.file}
                                      />
                                    )
                                }
                              </div>
                            )
                          }
                        </>
                      ))
                    )
                    : (
                      <div className="my-16 mx-md">
                        <p
                          className={cn(
                            styles['no-comments'],
                            'text-md text-weight-600 letter-xs text-black mt-zero mb-16'
                          )}
                        >
                          No comments yet
                        </p>
                      </div>
                    )
                }
              </div>
              {
                showCommentForm
                  && !isReadOnly
                  && (
                    <CommentForm
                      className={cn(
                        'mx-md',
                        {
                          'mt-40': commentsList.length,
                          'mt-md': !commentsList.length,
                        }
                      )}
                      autoFocusOnMessage={false}
                      onSubmit={onSaveComment}
                      messagePlaceholder="Add a comment..."
                      isPinCommentMode={isPinCommentMode}
                      isAnnotationMode={isAnnotationMode}
                      onClickPinButton={() => {
                        dispatch(setIsPinCommentMode(!isPinCommentMode));
                        dispatch(setIsAnnotationMode(false));
                        dispatch(setNewCommentData({}));
                        dispatch(selectComment(null));
                        dispatch(setCommentSuggestion(null));
                      }}
                      onClickAnnotationButton={() => {
                        dispatch(setIsAnnotationMode(!isAnnotationMode));
                        dispatch(setIsPinCommentMode(false));
                        dispatch(setNewCommentData({}));
                        dispatch(selectComment(null));
                        dispatch(setCommentSuggestion(null));
                      }}
                      inputIcon={
                        newCommentData?.annotationData?.lines?.length
                          ? <CanvasPencilIcon width={16} height={16} />
                          : (
                            newCommentData?.coordinates
                              ? <ThumbtackIcon className={styles['thumbtack-icon']} width={16} height={16} />
                              : null
                          )
                      }
                      hidePinButton={!canUserSendPinComments || hidePinCommentMode}
                      hideAnnotationButton={!canUserSendPinComments || hidePinCommentMode}
                      commentSuggestion={selectedCommentSuggestion}
                      mentionsList={projectUsersList}
                      fileType={newCommentData?.file}
                    />
                  )
              }
            </>
          )
          : (
            <div className="flex align-center just-center pt-lg">
              <Spin size="large" />
            </div>
          )
      }
    </div>
  );

  async function onSaveComment({
    message,
    parent_comment,
    file_links,
  }) {
    if (message) {
      const loomURL = extractLoomURL(message);

      if (loomURL) {
        const loomIframe = await textReplace(loomURL);

        message = `${message}<br>${loomIframe}`;
      }
    }

    try {
      if (parent_comment) {
        await dispatch(sendCommentOnMilestone(
          currentProject.uuid,
          {
            text: message || '<p><br></p>',
            parent_comment,
            file_links,
          }
        ));

        await loadAllComment();
      }
      else {
        await dispatch(sendCommentOnMilestone(
          currentProject.uuid,
          {
            text: message || '<p><br></p>',
            milestone_id: selectedMilestone?.id,
            file_links,
            content: {},
          }
        ));

        const [currentComment] = (await loadAllComment()).results;

        if (currentComment?.coordinates || currentComment?.figma_comment_detail?.node_offset) {
          dispatch(selectComment(currentComment));
        }
        dispatch(setNewCommentData({}));
        dispatch(setCommentSuggestion(null));
      }
    }
    catch (error) {
      if (error?.response?.status == 400) {
        errorReactToastify(error?.response?.data?.detail);
      }

      throw new Error(error);
    }
  }

  function onRemoveComment(comment) {
    return dispatch(deleteComment(currentProject.uuid, comment.uuid));
  }

  async function editSelectedComment(commentUUID, newCommentMessage, otherData) {
    await dispatch(editComment(
      currentProject.uuid,
      commentUUID,
      newCommentMessage,
      otherData
    ));
    await loadAllComment();
  }

  function loadAllComment() {
    return dispatch(loadComments(
      currentProject.uuid,
      {
        milestoneId: selectedMilestone?.id,
        keepPrevComments,
        limit: 1000,
      }
    ));
  }

  function isReply() {
    const reply = commentsList.find(({ replies }) => replies.some(({ uuid }) => uuid == commentParam));

    if (reply) {
      dispatch(selectComment(reply));
      setShowReplies(true);
    }
  }

  function updateURL() {
    if (commentParam) {
      const url = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      if (url?.comment_uuid) {
        setCommentParam(null);
        navigate(`${window.location.pathname}`);
      }
    }
  }

  function scrollToBottom() {
    commentsRef.current?.scroll({
      top: commentsRef.current?.scrollHeight,
    });
  }
}

AllCommentsList.propTypes = {
  selectedMilestone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,

  className: PropTypes.string,
  keepPrevComments: PropTypes.bool,
  canUserSendPinComments: PropTypes.bool,
};
