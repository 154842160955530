import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';

import ContractFirstViewIcon from 'assets/ContractFirstViewIcon.svg';

import AutofillDataFromDesignBrief from 'Components/AutofillDataFromDesignBrief/AutofillDataFromDesignBrief';
import { FirstViewBodyUI } from 'Components/FirstViewBodyUI';
import { Milestone } from 'Components/Milestone';

import { showMilestoneProgressModal } from 'Redux/Actions/appActions';
import {
  loadContractDocument,
  signContractDocument,
  submitContractDocument,
  updateContractDocument,
} from 'Redux/Actions/contractActions';
import { getMilestones } from 'Redux/Actions/FeedActions';

import { MILESTONE_STATUSES, NOTIFICATION_TYPES } from 'utlis/constants';
import formScrollToField from 'utlis/form_ scroll_to_field';
import { onSocketMessage } from 'utlis/socket';
import { errorReactToastify, infoReactToastify } from 'utlis/toasts';

import ContractText from './components/ContractText';
import Questionnaire from './components/Questionnaire';
import SigningModal from './components/SigningModal';

export default function ContractForm({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isSaved, setIsSaved] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSigningModal, setIsOpenSigningModal] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.userProfile);
  const currentProject = useSelector(state => state.selectedProject);
  const contractDocument = useSelector(state => state.contractDocument);
  const contractMilestone = useSelector(state => state.milestonesStore.dictionaryId[milestoneId]);

  const questionnaireRef = useRef();
  const contractTextRef = useRef();

  const isDesignerSubmitted = currentUser.permissions.isDesigner
    && [MILESTONE_STATUSES.submitted, MILESTONE_STATUSES.completed].includes(contractMilestone?.milestone_status);
  const isCompleted = [MILESTONE_STATUSES.completed].includes(contractMilestone?.milestone_status);

  useEffect(() => {
    loadMilestone();
  }, []);

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          dispatch(loadContractDocument(currentProject.uuid));
        }
      },
      [
        NOTIFICATION_TYPES.FORM_EDIT,
        NOTIFICATION_TYPES.FORM_SUBMIT,
        NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
        NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
      ]
    ),
    [currentProject]
  );

  return (
    <>
      {
        contractDocument?.uuid && isLoaded
          ? (
            !contractDocument.is_questionnaire_completed
              ? (
                <>
                  <AutofillDataFromDesignBrief isProposal={false} reloadCallback={loadMilestone} />
                  <Questionnaire
                    ref={questionnaireRef}
                    contractDocument={contractDocument}
                    currentProject={currentProject}
                    currentUser={currentUser}
                    onSave={onSave}
                    onSubmitQuestionnaire={onSubmitQuestionnaire}
                    contractMilestone={contractMilestone}

                    onFieldsChange={(changedFields, allFields) => {
                      if (changedFields.length && !isCompleted) {
                        setHasError(!allFields.every(({ errors }) => !errors.length));
                      }
                    }}
                    isSaved={isSaved}
                    hasError={hasError}
                    isSubmiting={isSubmiting}
                    isReadonly={isReadonly}
                  />
                </>

              )
              : (
                <ContractText
                  ref={contractTextRef}
                  contractDocument={contractDocument}
                  onSave={onSave}
                  currentUser={currentUser}
                  onSubmit={async () => {
                    await validateFields();

                    setIsOpenSigningModal(true);
                  }}
                  contractMilestone={contractMilestone}

                  onFieldsChange={(changedFields, allFields) => {
                    if (changedFields.length && !isCompleted) {
                      setHasError(!allFields.every(({ errors }) => !errors.length));
                    }
                  }}
                  isSaved={isSaved}
                  hasError={hasError}
                  setIsOpenSigningModal={setIsOpenSigningModal}
                  isDesignerSubmitted={isDesignerSubmitted}
                  isReadonly={isReadonly}
                />
              )
          )
          : (
            isLoaded
              ? (
                <Milestone
                  title={contractMilestone?.name}
                  selectedMilestone={contractMilestone}

                  withShareButton={!isReadonly}
                >
                  <FirstViewBodyUI
                    className="mx-xxxs"
                    heading={`${currentProject.type} Contract`}
                    image={ContractFirstViewIcon}
                    text={
                      <>
                        Your designer is working on the Contract and preparing it for you!
                        <br />
                        We will update your view as soon as it’s ready!
                      </>
                    }
                  />
                </Milestone>
              )
              : (
                <div className="flex flex-component-center px-lg py-lg">
                  <Spin size="large" />
                </div>
              )
          )
      }
      <SigningModal
        isOpen={isOpenSigningModal}
        onClose={setIsOpenSigningModal}
        currentUser={currentUser}
        onSign={currentUser.permissions.isClient ? onSign : onSubmit}
      />
    </>
  );

  async function onSave(formData) {
    const dataForSaving = Object.keys(formData).reduce(
      (currentObj, key) => contractDocument[key] == formData[key]
        ? currentObj
        : {
          ...currentObj,
          [key]: formData[key],
        },
      {}
    );

    if (dataForSaving.contract_start_date) {
      dataForSaving.contract_start_date = moment(
        dataForSaving.contract_start_date
      ).format('YYYY-MM-DD');
    }
    else {
      delete dataForSaving.contract_start_date;
    }

    if (Object.keys(dataForSaving).length) {
      setIsSaved(false);
      delete dataForSaving.updated_at;
      delete dataForSaving.isDesignerSubmitted;
      delete dataForSaving.milestone_status;
      await dispatch(
        updateContractDocument(currentProject.uuid, {
          ...dataForSaving,
          template: undefined,
        })
      );

      setIsSaved(true);
    }
  }

  async function onSubmitQuestionnaire() {
    setIsSubmiting(true);

    try {
      await validateFields();

      await onSave({
        ...questionnaireRef.current.getFieldValue(),
        is_questionnaire_completed: true,
      });
    }
    finally {
      setIsSubmiting(false);
    }
  }

  async function onSubmit(signature) {
    const projectHasClient = currentProject.collaborators.find(
      ({ user: { user_type } }) => user_type == 'Client'
    );

    if (projectHasClient) {
      setIsSubmiting(true);

      await dispatch(submitContractDocument(currentProject.uuid, {
        designer_signature: signature,
      }));
      await dispatch(getMilestones(currentProject));

      dispatch(showMilestoneProgressModal({
        milestone: contractMilestone,
        notificationType: currentUser.permissions?.isDesigner
          ? NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER
          : NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
      }));

      if (currentUser.permissions.isDesigner) {
        infoReactToastify('The contract was successfully sent to the client.');
      }

      setIsSubmiting(false);
      setIsOpenSigningModal(false);
    }
    else {
      errorReactToastify(
        'The project does not have a client. Please invite the client or wait until he accepts your invitation.'
      );
    }
  }

  async function onSign(signature) {
    setIsSubmiting(true);

    await dispatch(signContractDocument(currentProject.uuid, signature));

    dispatch(showMilestoneProgressModal({
      milestone: contractMilestone,
      notificationType: currentUser.permissions?.isDesigner
        ? NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER
        : NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
    }));

    setIsOpenSigningModal(false);
    infoReactToastify('The contract was successfully signed');

    setIsSubmiting(false);
  }

  async function validateFields() {
    const currentForm = questionnaireRef?.current || contractTextRef?.current;

    try {
      const res = await currentForm.validateFields();

      setHasError(false);

      return res;
    }
    catch (error) {
      setHasError(true);

      error.errorFields.some(({ errors, name: [name] }) => {
        if (errors.length) {
          formScrollToField(currentForm, name);
        }

        return !!errors.length;
      });

      return Promise.reject(error);
    }
  }

  function loadMilestone() {
    dispatch(loadContractDocument(currentProject.uuid))
      .then(() => {
        setIsLoaded(true);
      });
  }
}

ContractForm.propTypes = {
  isReadonly: PropTypes.bool,
};
