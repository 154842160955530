import { Form, Select } from 'antd';
import cn from 'classnames';

import { getCurrentTimezone } from 'utlis/date';

import styles from './styles.module.scss';

export const LanguageAndTimezoneForm = () => (
  <Form
    className={cn(styles['form__container'])}
    disabled
  >
    <div className={styles['section']}>
      <div className={styles['section__container']}>
        <span className={cn('mr-48', styles['title'])}>
          Language
        </span>
        <Form.Item>
          <Select
            placeholder="English - US"
            className={cn(styles['select__container'])}
          >
            <Select.Option value="english-us">
              English - US
            </Select.Option>
          </Select>
        </Form.Item>
      </div>
    </div>
    <div className={styles['section']}>
      <div className={styles['section__container']}>
        <span className={cn('mr-48', styles['title'])}>
          Timezone
        </span>
        <Form.Item>
          <Select
            placeholder={getCurrentTimezone()}
            className={cn(styles['select__container'])}
          >
            <Select.Option value={getCurrentTimezone()}>
              {getCurrentTimezone()}
            </Select.Option>
          </Select>
        </Form.Item>
      </div>
    </div>
  </Form>
);
