import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'routes/route.constants';

import { MILESTONES_TYPES, NOTIFICATION_TYPES } from 'utlis/constants';

import styles from './styles.module.scss';

function MilestoneProgressModal({
  milestone,
  notificationType,
  onCancel,

  ...props
}) {
  const { projectSlug } = useParams();

  const nextMilestone = useSelector(state =>
    state.milestonesStore.list
      .find(milestoneObj => milestone?.sequence_no + 1 === milestoneObj?.sequence_no)
  );
  const userProfile = useSelector(state => state.userProfile);

  const navigate = useNavigate();

  const headingText = useMemo(
    getHeadingText,
    [milestone, notificationType, userProfile]
  );
  const modalBodyText = useMemo(
    getModalBodyText,
    [milestone, notificationType, userProfile, nextMilestone]
  );

  return (
    <Modal
      title={
        <h4 className="text-20 mt-12 text-center">
          {headingText}
        </h4>
      }
      onCancel={onCancel}
      centered
      footer={null}
      width={520}
      {...props}
    >
      <div className={styles.progressModal__body}>
        {modalBodyText}
      </div>
      <div className={styles.progressModal__buttonsContainer}>
        <button
          onClick={onCancel}
          className={styles.progressModal__firstBtn}
        >
          Stay here
        </button>
        <button
          onClick={() => {
            onCancel();
            navigate(ROUTES.FEED(projectSlug));
          }}
          className={styles.progressModal__secondBtn}
        >
          Go to Feed
        </button>
      </div>
    </Modal>
  );

  function getHeadingText() {
    const filesListMilestoneHeadingText = {
      [NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER]: `${milestone?.name} submitted for approval!`,
      [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `${milestone?.name} approved!`,
    };
    const options = {
      [NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT]: `${milestone?.name} answers submitted!`, // SUBMIT ANSWERS
      [NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER]: userProfile?.permissions?.isDesigner // CONFIRM SIGNATURE
        ? `${milestone?.name} submitted for counter-sign`
        : `${milestone?.name} counter-signed!`,
      [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `The client has approved the ${milestone?.name}!`,

      [MILESTONES_TYPES.proposal]: {
        [NOTIFICATION_TYPES.FORM_SUBMIT]: `${milestone?.name} submitted for approval!`,
        [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `${milestone?.name} approved!`,
      },
      [MILESTONES_TYPES.contract]: {
        [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `${milestone?.name} counter-signed!`,
      },
      [MILESTONES_TYPES.moodboard]: filesListMilestoneHeadingText,
      [MILESTONES_TYPES.designConcepts]: filesListMilestoneHeadingText,
      [MILESTONES_TYPES.presentation]: filesListMilestoneHeadingText,
      [MILESTONES_TYPES.deliverFiles]: filesListMilestoneHeadingText,
    };

    options[MILESTONES_TYPES.designBrief] = options[MILESTONES_TYPES.uxBrief] = {
      [NOTIFICATION_TYPES.FORM_SUBMIT]: `${milestone?.name} questions submitted!`, // 'SUBMIT FOR APPROVAL'
    };

    return options[milestone?.type]?.[notificationType]
      || options[notificationType]
      || `${milestone?.name} approved!`;
  }

  function getModalBodyText() {
    const nextWorkForDesigner = nextMilestone
      ? `The Designer will work next on the ${nextMilestone?.name} and we will notify you in the Feed when it’s submitted for your review.`
      : '';
    const nextWorkForClient = nextMilestone
      ? `You can review it now and start on your next milestone: ${nextMilestone?.name}!`
      : '';

    const options = {
      [NOTIFICATION_TYPES.FORM_SUBMIT]: `You have submitted the ${milestone?.name} for the client’s approval. We will notify you in the Feed when it has been approved.`, // 'SUBMIT FOR APPROVAL'
      [NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER]: `You have submitted the ${milestone?.name} for the client’s approval. We will notify you in the Feed when it has been approved.`, // 'SUBMIT FOR APPROVAL'
      [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `You have approved the ${milestone?.name}! ${nextWorkForDesigner}`,

      [MILESTONES_TYPES.contract]: {
        [NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER]: userProfile?.permissions?.isDesigner // CONFIRM SIGNATURE
          ? `You have submitted the ${milestone?.name} for the client’s signature. We will notify you in the Feed when it has been signed.`
          : `
            The client has counter-signed the ${milestone?.name}!
            ${nextMilestone ? `You can review it now and start on your next milestone: ${nextMilestone?.name}!` : ''}
          `,
        [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `You have counter-signed the ${milestone?.name}! ${nextWorkForDesigner}`,
      },
      [MILESTONES_TYPES.proposal]: {
        [NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT]: `You have approved the ${milestone?.name}! ${nextWorkForDesigner}`,
      },
    };

    options[MILESTONES_TYPES.designBrief] = options[MILESTONES_TYPES.uxBrief] = {
      [NOTIFICATION_TYPES.FORM_SUBMIT]: `You have submitted the ${milestone?.name} questions for the client’s answers. We will notify you in the Feed when the answers have been submitted.`, // 'SUBMIT FOR APPROVAL'
      [NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT]: userProfile?.permissions?.isClient // SUBMIT ANSWERS
        ? `You have submitted the ${milestone?.name} answers! ${nextWorkForDesigner}`
        : `The client has submitted their answers to the ${milestone?.name}! ${nextWorkForClient}`,
    };

    return options[milestone?.type]?.[notificationType]
      || options[notificationType]
      || `You have approved the ${milestone?.name}! ${nextWorkForDesigner}`;
  }
}

MilestoneProgressModal.propTypes = {
  onCancel: PropTypes.func,
  milestone: PropTypes.shape({
    type: PropTypes.oneOfType(Object.values(MILESTONES_TYPES)).isRequired,
    name: PropTypes.string.isRequired,
  }),
  notificationType: PropTypes.oneOfType(Object.values(NOTIFICATION_TYPES)).isRequired,
};

export default MilestoneProgressModal;
