import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { MilestoneIcon } from 'Components/Milestone/components/MilestoneIcon';

import { ReactComponent as StarIcon } from 'assets/star.svg';

import { MILESTONES_TYPES } from 'utlis/constants';

import styles from './styles.module.scss';

export function AddFirstMoodboardItem({ className, onAddImageClick, ...props }) {
  return (
    <div
      className={cn(
        styles['add-first-moodboard-item-wrapper'],
        'flex flex-column align-center just-center',
        className
      )}
      {...props}
    >
      <div className={styles['milestone-icon-wrapper']}>
        <MilestoneIcon
          className={styles['milestone-icon']}
          type={MILESTONES_TYPES.moodboard}
          size={118}
        />
        <StarIcon className={styles['milestone-star-icon']} />
      </div>
      <p className="mt-32 mb-zero text-26 text-black text-weight-600 letter-xxs">
        Create a moodboard in seconds.
      </p>
      <p className="mt-8 mb-zero text-20 text-carbonGrey letter-xxs">
        Easily create a moodboard by clicking on “Add images”
      </p>
      {
        onAddImageClick && (
          <Button
            className="mt-24"
            onClick={onAddImageClick}
          >
            ADD IMAGE
          </Button>
        )
      }
    </div>
  );
}

AddFirstMoodboardItem.propTypes = {
  className: PropTypes.string,
  onAddImageClick: PropTypes.func,
};
