import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import cn from 'classnames';

// import { ReactComponent as LinkIcon } from 'assets/link_icon.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { Button } from 'Components/Button';
import { CollaboratorInput } from 'Components/CollaboratorInput';
import { Modal } from 'Components/Modal';

import { selectProject } from 'Redux/Actions/projectActions';

import CollaboratorService from 'services/collaborator';

// import ReadonlyService from 'services/readonly_service';
import { INVITE_STATUSES } from 'utlis/constants';
// import copyValue from 'utlis/copy_value';
import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import { CollaboratorItem } from './components/CollaboratorItem';

import styles from './styles.module.scss';

export const CollaboratorInviteModal = ({ onCancel, ...props }) => {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedProject = useSelector(state => state.selectedProject);
  const userProfile = useSelector(state => state.userProfile);

  const [form] = Form.useForm();

  useEffect(() => form.resetFields);

  const collaboratorList = useMemo(
    () => (
      [
        selectedProject?.owner,
        ...(selectedProject?.collaborators || [])
          .map(({ user, is_expert }) => ({ ...user, isExpert: is_expert, inviteStatus: INVITE_STATUSES.accepted })),
      ]
        .filter(Boolean)
        .sort(({ email }) => email === userProfile?.email ? -1 : 1)
    ),
    [selectProject?.collaborator, selectedProject?.owner, userProfile]
  );

  return (
    <Modal
      title={
        <div
          className={cn(
            styles['title-wrapper'],
            'text-xl text-center text-weight-600 flex align-center invert-mx-24 px-20 py-16'
          )}
        >
          Invite Teammates
        </div>
      }
      className={styles['inviteModal']}
      destroyOnClose
      onCancel={onCancel}
      centered
      width={578}
      footer={null}
      closeIconProps={{
        style: {
          marginTop: '16px',
        },
      }}

      {...props}
    >
      <Form
        initialValues={{ invite: null }}
        form={form}
        onFinish={handleEmailInvite}
      >
        <div className={cn(styles['collaboratorInvite'], 'flex align-start just-center')}>
          <CollaboratorInput
            name="invite"
            className="mr-4"
            autoFocus
          />
          <Form.Item
            noStyle
            shouldUpdate
          >
            {() => (
              <Button
                size="large"
                className={styles['invite-button']}
                disabled={isSubmitting || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                type="primary"
                htmlType="submit"
              >
                {
                  isSubmitting
                    ? 'Sending ...'
                    : 'Send invite'
                }
              </Button>
            )}
          </Form.Item>
        </div>
        <div className={cn('mt-4', styles['collaborator-list-wrapper'])}>
          {
            collaboratorList.map(collaborator => (
              <CollaboratorItem
                key={collaborator?.email}
                email={collaborator?.email}
                fullName={collaborator?.full_name}
                profilePhoto={collaborator?.profile_photo || defaultUserAvatar}
                isYou={collaborator?.email === userProfile?.email}
                removeCollaborator={removeCollaborator}
                {...collaborator}
              />
            ))
          }
          {
            selectedProject?.invites?.map((collaborator, idx) => (
              collaborator?.status === 'Pending'
                && (
                  <CollaboratorItem
                    key={`${collaborator?.invitee_email}-${idx}`}
                    email={collaborator?.invitee_email}
                    profilePhoto={defaultUserAvatar}
                    inviteStatus={collaborator?.status}
                    resendInvite={resendInvite}
                    removeCollaborator={removeCollaborator}
                    {...collaborator}
                  />
                ))
            )
          }
        </div>
      </Form>
      {/* Remove Copy link footer for now */}
      {/* <div
        className={cn(
          styles['readonly-wrapper'],
          'flex align-center just-end invert-mx-32 invert-mb-32 px-32 py-12'
        )}
      >
        <Button
          isText
          className=""
          icon={<LinkIcon className="mr-8" />}
          onClick={copyReadonlyLink}
          loading={isLoadingReadonlyLink}
        >
          Copy link
        </Button>
      </div> */}
    </Modal>
  );

  async function handleEmailInvite({ invite }) {
    try {
      setIsSubmitting(true);
      await CollaboratorService.inviteCollaborator(
        [invite],
        selectedProject?.uuid
      );
      await dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
      successReactToastify(`${invite.email} is invited to this project.`);
    }
    catch (error) {
      errorReactToastify(error?.response?.data?.detail);
    }
    finally {
      setIsSubmitting(false);
    }
  }

  // async function copyReadonlyLink() {
  //   try {
  //     if (readonlyLinkForProject) {
  //       copyValue(readonlyLinkForProject);
  //     }
  //     else {
  //       setIsLoadingReadonlyLink(true);

  //       const newReadonlyLinkForProject = await ReadonlyService.getReadonlyLinkForProject(selectedProject.uuid);

  //       copyValue(newReadonlyLinkForProject);
  //       setReadonlyLinkForProject(newReadonlyLinkForProject);
  //     }

  //     successReactToastify('The link has been copied');
  //   }
  //   finally {
  //     setIsLoadingReadonlyLink(false);
  //   }
  // }

  async function resendInvite(email) {
    try {
      await CollaboratorService.reInviteCollaborator(
        selectedProject?.uuid,
        email
      );
      successReactToastify(`${email} is reinvited to this project.`);
      await dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
    }
    catch (error) {
      errorReactToastify(error?.response?.data?.detail);
    }
  }

  async function removeCollaborator(email) {
    try {
      await CollaboratorService.removeCollaborator(selectedProject?.uuid, email);
      successReactToastify(`${email} is removed from this project.`);
      await dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
    }
    catch (error) {
      errorReactToastify(error.response.data.detail);
    }
  }
};

CollaboratorInviteModal.propTypes = {
  onCancel: PropTypes.func,
};
