import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import cn from 'classnames';

import copyIcon from 'assets/copy_icon.svg';
import customTaskIcon from 'assets/custom_task_icon.svg';
import deleteIcon from 'assets/delete_icon.svg';
import { ReactComponent as MagicWandIcon } from 'assets/magic_wand_icon.svg';

import { Button } from 'Components/Button';
import { Modal } from 'Components/Modal';

import {
  KANBAN_BOARD_FILE_TYPE_VALUES,
  KANBAN_BOARD_PRIORITY_OPTIONS,
} from 'utlis/constants';
import copyTaskDetails from 'utlis/copy_task_details';
import copyValue from 'utlis/copy_value';
import { successReactToastify } from 'utlis/toasts';

import { TaskDetails } from './components/TaskDetails';

import styles from './styles.module.scss';

export function TaskDetailsPopup({
  selectedTask,
  onCancel,
  handleUpdateTaskDetails,
  allFilesComments,
  handleTranscriptFileModal,
  deleteTask,
  isCreatingNewTask,
  selectedColumn,
  handleCreateCustomTask,
  ...props
}) {
  const taskID = selectedTask?.taskID;
  const taskDetails = selectedTask?.taskDetails;
  const fileDetails = selectedTask?.fileDetails;
  const selectedNode = taskDetails?.linked_node || null;

  const currentProjectColumns = useSelector(state => state.kanbanBoard?.currentProject?.details?.columns);

  const [taskPriority, setTaskPriority] = useState(
    taskDetails?.priority.toLowerCase()
    || KANBAN_BOARD_PRIORITY_OPTIONS.MEDIUM
  );
  const [taskStatus, setTaskStatus] = useState(taskDetails?.status);
  const [label, setLabel] = useState(
    taskDetails?.label
      ? typeof taskDetails?.label === 'string'
        ? [taskDetails?.label]
        : taskDetails?.label
      : []
  );
  const [isEditTitle, setIsEditTitle] = useState(isCreatingNewTask);
  const [taskTitle, setTaskTitle] = useState(taskDetails?.title || '');
  const [saveTitle, setSaveTitle] = useState(false);

  const [assignees, setAssignees] = useState(
    taskDetails?.assignees
      ? typeof taskDetails.assignees === 'string'
        ? [taskDetails.assignees]
        : taskDetails.assignees
      : []
  );

  const taskDetailsData = useMemo(
    () => ({
      updatedTaskDetails: {
        title: taskTitle,
        label,
        priority: taskPriority,
        status: taskStatus,
        linked_node: selectedNode,
        assignees,
      },
      isFieldsChanged: taskTitle !== taskDetails?.title
        || isLabelChanged()
        || taskPriority !== taskDetails?.priority
        || assignees !== taskDetails?.assignees
        || taskStatus !== taskDetails?.status,
    }),
    [
      taskTitle,
      label,
      taskPriority,
      taskStatus,
      taskDetails,
      selectedNode,
      assignees,
    ]
  );

  const taskStatusInfo = useMemo(
    () => currentProjectColumns?.reduce(
      (info, colData, currentIndex) => {
        const statusIndex = currentIndex + 1;

        return {
          ...info,
          [statusIndex]: colData.name,
          [colData.name]: statusIndex,
        };
      },
      {}
    ),
    [currentProjectColumns]
  );

  const isCustomFile = useMemo(
    () => fileDetails?.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM,
    [selectedTask]
  );

  const popUpHeader = useMemo(
    () => (
      <div className={styles['task-title-container']}>
        {
          isCreatingNewTask || isCustomFile
            ? (
              <img width={24} height={24} src={customTaskIcon} alt="custom task icon" />
            )
            : (
              <MagicWandIcon className={styles['task-title-icon']} />
            )
        }
        <span
          className={cn(
            styles['task-title'],
            {
              [styles['custom-task-title']]: isCreatingNewTask || isCustomFile,
            }
          )}
        >
          {isCreatingNewTask ? 'New task' : 'Task'}
        </span>
      </div>
    ),
    []
  );

  const taskStatusValue = useMemo(
    () => taskStatus || selectedColumn,
    [taskStatus, selectedColumn]
  );

  const modalFooter = useMemo(
    () => (
      <div className={styles['task-footer']}>
        {
          !isCreatingNewTask
            && (
              <div className={styles['action-buttons-container']}>
                <Button isText className={styles['footer-action-button']} onClick={handleCopyText}>
                  <img src={copyIcon} alt="Copy Icon" />
                  Copy task content
                </Button>
                <div className={styles['divider']} />
                <Button isText className={styles['footer-action-button']} onClick={handleDeleteTask}>
                  <img src={deleteIcon} alt="Delete Icon" />
                  Delete task
                </Button>
              </div>
            )
        }
        <Button
          className={styles['save-button']}
          onClick={isCreatingNewTask ? handleCreateTask : handleUpdateTask}
          disabled={!checkAllFilled() || !taskDetailsData.isFieldsChanged}
        >
          Save
        </Button>
      </div>
    ),
    [taskDetailsData.isFieldsChanged, label, taskTitle]
  );

  useEffect(
    () => {
      setIsEditTitle(isCreatingNewTask);
    },
    [isCreatingNewTask]
  );

  useEffect(
    () => {
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    },
    []
  );

  useEffect(
    () => {
      if (saveTitle) {
        if (isEditTitle && !isCreatingNewTask) {
          handleEnterPress();
        }
        setSaveTitle(false);
      }
    },
    [saveTitle]
  );

  return (
    <Modal
      destroyOnClose
      className={cn(
        styles['task-details-modal'],
        {
          [styles['custom-task-details-modal']]: isCreatingNewTask || isCustomFile,
        }
      )}
      onCancel={closeModal}
      width={800}
      footer={modalFooter}
      title={popUpHeader}

      {...props}
    >
      <div
        className={styles['task-description-container']}
        onClick={e => makeTitleEditable(e)}
      >
        {
          isEditTitle
            ? (
              <Input
                className={styles['editable-title']}
                onChange={e => setTaskTitle(e.target.value)}
                onPressEnter={() => handleEnterPress()}
                value={taskTitle}
                type="text"
                placeholder="Enter task title..."
              />
            )
            : (
              <p className={styles['task-description']}>
                {taskTitle}
              </p>
            )
        }
      </div>
      <div className={styles['details-modal-body']}>
        <TaskDetails
          assignees={assignees}
          setAssignees={setAssignees}
          label={label}
          taskStatusValue={taskStatusValue}
          taskStatusInfo={taskStatusInfo}
          taskPriority={taskPriority}
          taskDetails={taskDetails}
          allFilesComments={allFilesComments}
          isCreatingNewTask={isCreatingNewTask}
          selectedTask={selectedTask}
          fileDetails={fileDetails}
          handleChangeStatus={handleChangeStatus}
          handleChangeTag={handleChangeTag}
          handleRemoveTage={handleRemoveTage}
          handleChangePriority={handleChangePriority}
          handleTranscriptFileModal={handleTranscriptFileModal}
        />
      </div>
    </Modal>
  );

  function isLabelChanged() {
    const taskLabels = Array.isArray(taskDetails?.label) ? taskDetails?.label : [taskDetails?.label];
    const lowercaseTaskLabels = taskLabels.map(taskLabel => taskLabel.toLowerCase());

    return label.length !== lowercaseTaskLabels.length
      || label.some(val => !lowercaseTaskLabels.includes(val.toLowerCase()));
  }

  function handleDeleteTask() {
    deleteTask(taskID);
    closeModal();
  }

  async function handleCopyText() {
    const textToCopy = copyTaskDetails(taskDetails, allFilesComments) + '\n';

    await copyValue(textToCopy);
    successReactToastify('Task copied to clipboard');
  }

  function handleUpdateTask() {
    handleUpdateTaskDetails(taskID, taskDetailsData.updatedTaskDetails);
    closeModal();
  }

  function checkAllFilled() {
    return label.length !== 0 && taskTitle;
  }

  function handleCreateTask() {
    const customTaskDetails = {
      description: taskTitle,
      label: label,
      priority: taskPriority,
      status: Number(selectedColumn) || 1,
      title: taskTitle,
    };

    handleCreateCustomTask(customTaskDetails);
    closeModal();
  }

  function clickOutside(event) {
    const inputForTitleClicked = event.target.closest(`.${styles['editable-title']}`);

    if (!inputForTitleClicked) {
      setSaveTitle(true);
    }
  }

  function makeTitleEditable(e) {
    setIsEditTitle(true);
    e.stopPropagation();
  }

  function handleEnterPress() {
    if (!isCreatingNewTask) {
      setIsEditTitle(false);
    }
  }

  function closeModal() {
    setTaskPriority('');
    setLabel([]);
    setIsEditTitle(false);
    setTaskTitle('');
    onCancel();
  }

  function handleChangePriority(priority) {
    setTaskPriority(priority);
  }

  function handleChangeStatus(value) {
    setTaskStatus(value);
  }

  function handleChangeTag(tag) {
    setLabel(tag);
  }

  function handleRemoveTage(tagToRemove) {
    setLabel(label.filter(item => item !== tagToRemove));
  }
}

TaskDetailsPopup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  selectedTask: PropTypes.object.isRequired,
  editTask: PropTypes.func.isRequired,
  handleUpdateTaskDetails: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  handleTranscriptFileModal: PropTypes.func.isRequired,
  allFilesComments: PropTypes.object.isRequired,
  isCreatingNewTask: PropTypes.bool,
  selectedColumn: PropTypes.string,
  handleCreateCustomTask: PropTypes.func.isRequired,
};
