import actionTypes from '../constants';

export const workspaceFilesReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_WORKSPACE_FILES:
      return action.payload;
    case actionTypes.UPDATE_WORKSPACE_FILES:
      return [...state, ...action.payload];
    case actionTypes.REMOVE_WORKSPACE_FILES:
      return [];
    case actionTypes.DELETE_UPLOADED_FILE:
      return state.filter(file => file.uuid !== action.payload.uuid);
    default:
      return state;
  }
};
