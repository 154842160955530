import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ConnectApp } from 'Pages/Settings/sub-routes/ConnectedApps/components/ConnectApp';
import { ToggleSwitch } from 'Pages/Settings/sub-routes/ConnectedApps/components/ToggleSwitch';

import { unlinkStripeAccount } from 'Redux/Actions/PaymentActions';

import StripePaymentService from 'services/stripe_payment';

import { infoReactToastify, successReactToastify } from 'utlis/toasts';

export const StripeActions = ({ onChangeStart, onChangeStop }) => {
  const dispatch = useDispatch();

  const [
    createLinkToStripeInProgress,
    setCreateLinkToStripeInProgress,
  ] = useState(false);

  const userProfile = useSelector(state => state.userProfile);

  return (
    <div className="flex align-center">
      {
        userProfile?.designer_profile?.is_stripe_acc
          && userProfile?.designer_profile?.is_charges_enabled
          ? (
            <ToggleSwitch onChange={disconnectStripe} />
          )
          : (
            userProfile?.user_type === 'Designer'
              && (
                <ConnectApp
                  title="Stripe"
                  onClick={handleStripeAccount}
                  isDisabled={createLinkToStripeInProgress}
                />
              )
          )
      }
    </div>
  );

  function handleStripeAccount() {
    setCreateLinkToStripeInProgress(true);
    onChangeStart();

    StripePaymentService.getLinkToStripeAccount()
      .then(link => {
        if (
          userProfile?.designer_profile?.is_stripe_acc
          && userProfile?.designer_profile?.is_charges_enabled
        ) {
          window.open(link, '_blank', 'noopener,noreferrer');
          setCreateLinkToStripeInProgress(false);
          onChangeStop();
        }
        else {
          infoReactToastify(`
            After a few seconds, you will be redirected to the Stripe account creation page.
            When you finish the survey, you will return to this page automatically.
          `);
          setTimeout(() => {
            location.href = link;
          }, 3000);
        }
      })
      .finally(() => {
        setCreateLinkToStripeInProgress(false);
        onChangeStop();
      });
  }

  function disconnectStripe() {
    onChangeStart();
    dispatch(unlinkStripeAccount())
      .then(() => {
        successReactToastify('Successfully logged out of stripe account');
      })
      .finally(() => {
        onChangeStop();
      });
  }
};

StripeActions.propTypes = {
  onChangeStart: PropTypes.func.isRequired,
  onChangeStop: PropTypes.func.isRequired,
};
