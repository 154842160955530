import { useState, useEffect, useImperativeHandle, forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { ReactComponent as CommentMarkerSVG } from 'assets/comment_marker.svg';

import { selectComment } from 'Redux/Actions/commentActions';

import CommentComponent from '../Comment';
import { CommentForm } from '../CommentForm';

import styles from './styles.module.scss';

function CommentsBlock(
  {
    position,
    commentsList = [],
    className,
    isNewComment,
    isRelativePosition,
    showCommentsBlockLeft,
    onSubmit,
    onRemoveComment,
    onEditComment,
    commentSuggestion,
    isDragging,
    containerComponent,
    onCancel,
    hideCommentsList,
  },
  ref
) {
  const [showCommentsList, setShowCommentsList] = useState(isNewComment);

  const currentUser = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  const commentsListRef = useRef();

  const commentsBlockStyles = useMemo(
    () => {
      if (containerComponent && commentsListRef?.current) {
        const containerComponentRect = containerComponent.getBoundingClientRect();
        const commentsListRect = commentsListRef?.current.getBoundingClientRect();

        return {
          bottom: (
            (containerComponentRect.bottom - containerComponentRect.top) < (commentsListRect.height + position.y)
              ? `${-(containerComponentRect.bottom - containerComponentRect.top - position.y - 10)}px`
              : undefined
          ),
        };
      }
    },
    [containerComponent, commentsListRef?.current, position]
  );

  if (!isNewComment) {
    useEffect(
      () => {
        document.addEventListener('click', clickOutside);
        return () => document.removeEventListener('click', clickOutside);
      },
      []
    );
  }

  useEffect(
    () => {
      if (showCommentsList && commentsListRef.current) {
        dispatch(selectComment(commentsList[0]));
        commentsListRef.current.scrollTo(0, commentsListRef.current.scrollHeight);
      }
    },
    [showCommentsList, commentsListRef.current]
  );

  useImperativeHandle(
    ref,
    () => ({
      showCommentsList: () => {
        setShowCommentsList(true);
      },
    })
  );

  return (
    <div
      data-comment-uuid={commentsList?.[0]?.uuid}
      className={cn(
        styles['comments-block-wrapper'],
        'flex',
        {
          [styles['show-comments-list']]: showCommentsList,
          'flex-row-reverse': showCommentsBlockLeft,
          [styles['show-comments-list-left']]: showCommentsBlockLeft,
          [styles['is-dragging']]: isDragging,
        },
        className
      )}
      style={position && {
        left: `${position.x}${isRelativePosition ? '%' : 'px'}`,
        top: `${position.y}${isRelativePosition ? '%' : 'px'}`,
      }}
    >
      <div
        className={cn(
          'mt-sm flex',
          styles['comment-marker'],
          {
            pointer: commentsList.length,
            [styles['new-comment-marker']]: !commentsList.length,
          }
        )}
        onClick={() => setShowCommentsList(true)}
      >
        <CommentMarkerSVG />
        <img
          className={styles['marker-avatar']}
          src={
            commentsList.length
              ? (
                commentsList[0]?.commenter
                  ? commentsList[0].commenter.profile_photo
                  : commentsList[0]?.figma_comment_detail?.figma_commenter_avatar
              )
              : currentUser?.profile_photo
          }
        />
      </div>
      {
        !hideCommentsList && (
          <div
            ref={commentsListRef}
            className={cn(
              styles['comments-block'],
              {
                [styles['empty-comments-block']]: !commentsList.length,
              }
            )}
            style={commentsBlockStyles}
          >
            {
              commentsList.map(comment => (
                <CommentComponent
                  key={comment.uuid}
                  comment={comment}
                  hideRepliesCount
                  className="mb-md"
                  onRemoveComment={
                    !comment.is_comment_from_figma && comment.commenter?.uuid == currentUser.uuid
                      ? onRemoveComment
                      : null
                  }
                  onEditComment={
                    !comment.figma_comment_detail && comment.commenter?.uuid == currentUser.uuid
                      ? onEditComment
                      : null
                  }
                />
              ))
            }
            <div
              className={cn(
                styles['new-comment-form'],
                {
                  'px-sm py-sm': isNewComment,
                }
              )}
            >
              <div className="flex align-center mb-sm-md">
                <img
                  className={cn(styles['medium-avatar'], 'mr-12')}
                  src={currentUser?.profile_photo}
                />
                <p className="text-sm m-zero text-weight-500">
                  {currentUser?.full_name}
                </p>
              </div>
              <CommentForm
                className="flex-grow"
                commentSuggestion={commentSuggestion}
                onSubmit={onSubmit}
                messagePlaceholder={commentsList.length ? 'Reply' : 'Add a comment'}
                withTools={false}
                onCancel={isNewComment && onCancel}
              />
            </div>
          </div>
        )
      }
    </div>
  );

  function clickOutside(event) {
    const commentsBlockWrapperEl = event.composedPath().find(
      el => el.getAttribute && el.getAttribute('data-comment-uuid') == commentsList?.[0]?.uuid
    );

    if (!commentsBlockWrapperEl) {
      setShowCommentsList(false);
    }
  }
}

CommentsBlock.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  onSubmit: PropTypes.func,
  commentsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    figma_comment_detail: PropTypes.shape({
      figma_commenter_name: PropTypes.string.isRequired,
      figma_commenter_avatar: PropTypes.string.isRequired,
    }).isRequired,
  })),
  className: PropTypes.string,
  isNewComment: PropTypes.bool,
  isRelativePosition: PropTypes.bool,
  showCommentsBlockLeft: PropTypes.bool,
  onRemoveComment: PropTypes.func,
  onEditComment: PropTypes.func,
  commentSuggestion: PropTypes.string,
  isDragging: PropTypes.isDragging,
  containerComponent: PropTypes.node,
  onCancel: PropTypes.func,
  hideCommentsList: PropTypes.bool,
};

export default forwardRef(CommentsBlock);
