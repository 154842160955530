import { googleLogout } from '@react-oauth/google';

import AcceptedCollaboratorStatusIcon from 'assets/AcceptedCollaboratorStatusIcon.svg';
import aiIcon from 'assets/ai_icon.svg';
import DeniedCollaboratorStatusIcon from 'assets/DeniedCollaboratorStatusIcon.svg';
import figmaIcon from 'assets/figma_logo.svg';
import GoogleDocsIcon from 'assets/GoogleDocsIcon.svg';
import GoogleDriveIcon from 'assets/GoogleDriveIcon.svg';
import invitedIcon from 'assets/invited_icon.svg';
import miroIcon from 'assets/miro_icon.svg';
import PdfIcon from 'assets/pdf_icon.png';
import PendingCollaboratorStatusIcon from 'assets/PendingCollaboratorStatusIcon.svg';
import PresentationIcon from 'assets/PresentationIcon.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { removeAllCommentsFromReduxStore } from 'Redux/Actions/commentActions';
import { removeContractDocument } from 'Redux/Actions/contractActions';
import {
  removeFeedFiles,
  removeMilestoneStore,
} from 'Redux/Actions/FeedActions';
import {
  removeFigmaFiles,
  removeFigmaTokens,
  removePostsNextBatchUrl,
  removeSelectedFigmaFile,
  removeWorkflowSteps,
  saveFigmaThumbnail,
} from 'Redux/Actions/figmaActions';
import { removePaymentMethods } from 'Redux/Actions/PaymentActions';
import {
  removeProjects,
  removeSelectedProjects,
  selectProject,
} from 'Redux/Actions/projectActions';
import { removeUserProfile, signOutUser } from 'Redux/Actions/userActions';
import { removeWorkspaceFiles } from 'Redux/Actions/workspaceActions';

import { ROUTES } from 'routes/route.constants';

import { INVITE_STATUSES, MILESTONES_APPS, MILESTONES_TYPES } from './constants';

// Extracting Figma from the String
export const extractFigmaText = text => text.split(' ')[0];

// Convert String to Number
export const strToNum = str => Number(str).toFixed(2);

// Function to control the String that will be passed to this Function with the String Limit
export const handleStringLength = (string, limit) => {
  if (string?.length > limit) {
    return string.substring(0, limit) + '...';
  }

  return string;
};

// Getting the Status Icon from Assets folder based on the Status of the Collaborators invited to the Project
export const collaboratorsStatusIcon = collaboratorsStatus => {
  switch (collaboratorsStatus) {
    case INVITE_STATUSES.accepted:
      return AcceptedCollaboratorStatusIcon;
    case INVITE_STATUSES.pending:
      return PendingCollaboratorStatusIcon;
    case INVITE_STATUSES.denied:
      return DeniedCollaboratorStatusIcon;
    default:
      return null;
  }
};

// Getting all the Connected Application for the User
export const connectedApps = userProfile => {
  const connectedAccounts = [];

  userProfile?.user_tokens?.map(app => {
    if (app?.provider !== 'Google drive') {
      connectedAccounts.push(app?.provider);
    }
    else {
      app?.scopes?.map(scope => {
        const array = scope.split('/');

        if (array[array.length - 1] === 'drive') {
          connectedAccounts.push('Google Drive');
        }
      });
    }
  });

  return connectedAccounts;
};

export const hasGoogleDriveAccess = user_tokens => !!user_tokens.find(
  ({ provider, scopes }) => provider.toLowerCase() == 'google drive'
    && !!scopes.find(el => el.indexOf('drive') != -1)
);

// Checking if the User_Type in the userProfile is Null (meaning new user is logging in for the first time) or not.
export const checkingUserType = (userProfile, navigate) => {
  if (Object.keys(userProfile).length > 0) {
    if (userProfile?.user_type === null) {
      navigate('/onboarding');
    }
  }
};

// Function to identify the Workflow and pass the correct text to the Workflow Component
export const workflowStepTextRecognizer = buttonText => {
  if (
    buttonText
      ?.toLowerCase()
      ?.split(' ')
      ?.includes('brief')
  ) {
    return 'Brief';
  }
  if (
    buttonText
      ?.toLowerCase()
      ?.split(' ')
      ?.includes('contract')
  ) {
    return 'Contract';
  }
  if (
    buttonText
      ?.toLowerCase()
      ?.split(' ')
      ?.includes('files')
  ) {
    return 'Files';
  }
  if (
    buttonText
      ?.toLowerCase()
      ?.split(' ')
      ?.includes('request')
    || buttonText
      ?.toLowerCase()
      ?.split(' ')
      ?.includes('pay')
  ) {
    return 'Payment';
  }

  return 'Feed';
};

// Function to identify the Workflow and pass the correct icon to the Workflow Component
export const currentMilestoneIconRecognizer = milestoneTypeOrApp => {
  switch (milestoneTypeOrApp) {
    case MILESTONES_TYPES.designBrief:
    case MILESTONES_TYPES.uxBrief:
    case MILESTONES_TYPES.proposal:
    case MILESTONES_TYPES.brandPersonality:
    case MILESTONES_TYPES.colorPalette:
    case MILESTONES_APPS.googleForms:
      return PdfIcon;
    case MILESTONES_TYPES.contract:
    case MILESTONES_APPS.googleDocs:
      return GoogleDocsIcon;
    case MILESTONES_TYPES.designConcepts:
    case MILESTONES_TYPES.moodboard:
    case MILESTONES_APPS.figma:
      return figmaIcon;
    case MILESTONES_APPS.miro:
      return miroIcon;
    case MILESTONES_TYPES.deliverFiles:
    case MILESTONES_APPS.gdrive:
      return GoogleDriveIcon;
    case MILESTONES_TYPES.presentation:
      return PresentationIcon;
  }
};

// Function to create Object for a Post Comment API based on the number of arguments
export const postCommentAPIObject = (
  commentType,
  text,
  fileUUID,
  nodeId,
  svgElementID
) => {
  let data = {};

  if (commentType === 1 || commentType === 7 || commentType === 6) {
    data = JSON.stringify({
      comment_type: commentType,
      text: text,
    });
  }
  else if (commentType === 5) {
    data = JSON.stringify({
      comment_type: commentType,
      text: text,
      file: fileUUID,
    });
  }
  else if (commentType === 2) {
    data = JSON.stringify({
      comment_type: commentType,
      text: text,
      file: fileUUID,
      node_id: nodeId,
    });
  }
  else if (commentType === 3) {
    data = JSON.stringify({
      comment_type: commentType,
      text: text,
      file: fileUUID,
      node_id: nodeId,
      svg_element_ids: svgElementID,
    });
  }

  return data;
};

// Fetch Drive Files Tag Name
export const fetchDriveFileTag = name => {
  if (name) {
    const list = name?.split('.');

    return list[list?.length - 1].toUpperCase();
  }
};

// Figma URL building from individual components
export const figmaUrlBuilder = (id, key, name) => {
  const tempUrl = `https://www.figma.com/file/${key}/${name}?node-id=${id}`;
  const url = tempUrl.replaceAll(':', '%3A').replace('%3A', ':');

  return url;
};

// Save Figma File Function
export const saveFigmaFile = (
  containerId,
  milestone,
  figmaFiles,
  authUser,
  selectedProject,
  setIsLoading,
  dispatch
) => {
  const data = JSON.stringify({
    container: containerId,
    milestone_id: milestone?.id,
    files: figmaFiles,
  });

  if (data) {
    dispatch(
      saveFigmaThumbnail(
        authUser,
        selectedProject,
        milestone,
        data,
        setIsLoading
      )
    );
  }
};

// GET SVG LINKS API call
export const getSVGLinks = (
  containerId,
  milestone,
  figmaFiles,
  authUser,
  selectedProject,
  setIsLoading,
  dispatch
) => {
  saveFigmaFile(
    containerId,
    milestone,
    figmaFiles,
    authUser,
    selectedProject,
    setIsLoading,
    dispatch
  );
};

//  Function to select the Clicked project and redirect the user to that project
export const selectClickedProject = (
  project,
  selectedProject,
  dispatch,
  navigate
) => {
  if (project) {
    dispatch(selectProject(project?.uuid));
  }
  if (project || Object.keys(selectedProject).length === 0) {
    navigate(ROUTES.WORKSPACE(project.slug));
  }
};

// Google Picker API for uploading or selecting from the User's Google Drive
export const openGoogleDriveUI = openPicker => {
  const driveToken = window.localStorage.getItem('drive_accessToken');

  openPicker({
    clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
    developerKey: `${process.env.REACT_APP_PICKER_DEVELOPER_KEY}`,
    viewId: 'DOCS',
    token: driveToken,
    showUploadView: true,
    showUploadFolders: true,
    supportDrives: true,
    multiselect: true,
  });
};

// Tweaking the URL of the Google Docs and PDF in order to view it in Preview Mode
export const urlModifier = url => {
  if (url) {
    const array = url.split('/');

    array.pop();
    array.push('preview');

    return array.toString().replaceAll(',', '/');
  }
};

// handle Edit Contract Document
export const handleEditContractDocument = contract => {
  const newWindow = window.open(
    contract?.file_url,
    '_blank',
    'noopener,noreferrer'
  );

  if (newWindow) {
    newWindow.opener = null;
  }
};

export const appLogoutFunction = (dispatch, navigate, isFigmaLogin) => {
  window.localStorage.clear();
  dispatch(removeWorkflowSteps());
  dispatch(signOutUser());
  dispatch(removeProjects());
  dispatch(removeSelectedProjects());
  dispatch(removeFigmaTokens());
  dispatch(removeFigmaFiles());
  dispatch(removeSelectedFigmaFile());
  dispatch(removeAllCommentsFromReduxStore());
  dispatch(removePostsNextBatchUrl());
  dispatch(removeUserProfile());
  dispatch(removeContractDocument());
  dispatch(removeWorkspaceFiles());
  dispatch(removeFeedFiles());
  dispatch(removeMilestoneStore());
  dispatch(removeUserProfile());
  dispatch(removePaymentMethods());

  if (!isFigmaLogin) {
    navigate('/');
  }
};

export const urlParser = link => {
  const params = link.split('/');
  const fileKey = params[4];
  const id = params[5]?.split('?')[1]?.split('=')[1];
  const name = params[5]?.split('?')[0];

  return { id, fileKey, name };
};

export const convertToBase64 = file => new Promise((resolve, reject) => {
  const fileReader = new FileReader();

  fileReader.readAsDataURL(file);

  fileReader.onload = () => {
    resolve(fileReader.result);
  };

  fileReader.onerror = error => {
    reject(error);
  };
});

export const renderTimestamp = (date, isProject) => {
  if (date) {
    const expireTime = new Date(date).toISOString();
    const currentTime = new Date();
    const datum = Date.parse(expireTime);
    const diff = currentTime.valueOf() - datum;
    let minutes = Math.floor(diff / (1000 * 60));
    let hours = minutes / 60;
    let days = hours / (60 * 24);

    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);

      if (hours > 24) {
        days = Math.floor(hours / 24);

        if (days === 1) {
          return `${days} day ago`;
        }

        return `${days} days ago`;
      }

      return `${hours} hours ago`;
    }
    if (minutes < 1) {
      minutes = 1;

      if (isProject) {
        return 'Now';
      }

      return `${minutes} minute ago`;
    }

    return `${minutes} minutes ago`;
  }
};

export function parseJwt(jwtToken) {
  if (jwtToken) {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

  return {};
}

export function dataURLtoFile(dataURL, fileName, mimeType) {
  mimeType = mimeType || dataURL.split(',')?.[0]?.match(/:(.*?);/)[1];

  return fetch(dataURL)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], fileName, { type: mimeType }));
}

export const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export function getFileNameFromURL(url) {
  return url.substring(url.lastIndexOf('/') + 1);
}

export function getFileExtension(fileName) {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
}

export const onLoadImageError = (e, defaultImage) => {
  e.target.src = defaultImage;
};

export const logoutUser = (dispatch, navigate, isFigmaLogin) => {
  if (window?.webSocket?.close) {
    window.webSocket.close();
  }
  googleLogout();
  appLogoutFunction(dispatch, navigate, isFigmaLogin);
};

export const getUserIcon = (val, showAiIcon = true) => {
  if (val.profile_photo || val.img_url || val.image) {
    return val.profile_photo || val.img_url || val.image;
  }
  if (val.email && !val.full_name) {
    return invitedIcon;
  }
  if (showAiIcon && typeof val === 'string') {
    return aiIcon;
  }

  return defaultUserAvatar;
};

