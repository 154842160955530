import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import cn from 'classnames';

import { withdrawAmount } from 'Redux/Actions/PaymentActions';

import { errorReactToastify } from 'utlis/toasts';
import { fullPriceFormat } from 'utlis/string_format';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function UserEWallet({ className, ...props }) {
  const [isLoading, setIsLoading] = useState(false);

  const userProfile = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  return (
    <div className={cn(styles['projects__withdrawContainer'], className)} {...props}>
      <div className={styles['projects__leftSide']}>
        <span>E-WALLET</span>

        <h3>
          {
            userProfile?.uuid
              ? fullPriceFormat(
                Number(userProfile.designer_profile.available_balance),
                {
                  currency: userProfile.designer_profile.currency,
                  minimumFractionDigits: 2,
                  hideFractionDigitsIfItIsZero: false,
                }
              )
              : <Spin size="small" />
          }
        </h3>
      </div>
      <div className={styles['projects__rightSide']}>
        <Button
          onClick={handleWithdrawButtonClick}
          disabled={!Number(userProfile.designer_profile?.available_balance) || isLoading}
          loading={isLoading}
        >
          WITHDRAW
        </Button>
      </div>
    </div>
  );

  async function handleWithdrawButtonClick() {
    if (userProfile.designer_profile?.is_charges_enabled) {
      try {
        setIsLoading(true);

        await dispatch(withdrawAmount(userProfile));
      }
      finally {
        setIsLoading(false);
      }
    }
    else {
      errorReactToastify(
        'Your Stripe account is not fully onboarded, please complete the onboarding of your Stripe account',
      );
    }
  }
}

UserEWallet.propTypes = {
  className: PropTypes.string,
};
