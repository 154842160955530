import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import cn from 'classnames';

import UsersService from 'services/users';

import { USER_TYPES } from 'utlis/constants';

import styles from './styles.module.scss';

function CollaboratorField({ onChange, disabled, autoFocus, isDropdown }) {
  const inputRef = useRef(null);

  const [filteredUser, setFilteredUser] = useState([]);
  const [emailAccount, setEmailAccount] = useState('');
  const [autoFocusDone, setAutoFocusDone] = useState(false);

  useEffect(
    () => {
      if (autoFocus && inputRef?.current && !autoFocusDone) {
        setAutoFocusDone(true);
        inputRef.current.focus();
      }
    },
    [inputRef?.current]
  );

  return (
    <div
      className={cn(
        'flex align-center',
        styles['input-group-wrapper'],
        { [styles['dropdown-input-container']]: isDropdown }
      )}
    >
      <AutoComplete
        className={styles['email-input']}
        placeholder="Enter teammate's email"
        value={emailAccount}
        showSearch
        onSearch={onUserFiltering}
        options={filteredUser}
        disabled={disabled}
        onChange={changedValue => handleEmailAccount(changedValue)}
        ref={inputRef}
      />
    </div>
  );

  async function onUserFiltering(currentValue) {
    setEmailAccount(currentValue);

    if (currentValue.length > 3) {
      setFilteredUser(
        (await UsersService.getCollaborators({ search: currentValue })).results
          .map(({ email }) => ({
            value: email,
            label: email,
          }))
      );
    }
    else {
      setFilteredUser([]);
    }
  }

  function handleEmailAccount(email) {
    setEmailAccount(email);
    onChange({
      email,
      user_type: USER_TYPES.designerNumber,
    });
  }
}

CollaboratorField.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isDropdown: PropTypes.bool,
};

export default CollaboratorField;
