import { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import cn from 'classnames';

function LocalImage({ file, className, onLoaded, ...props }, ref) {
  const [isLoading, setIsLoading] = useState(true);

  const imageRef = useRef();

  useEffect(
    () => {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          imageRef.current.src = reader.result;

          setIsLoading(false);

          if (onLoaded) {
            onLoaded();
          }
        }
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    []
  );

  return (
    <div
      className={cn('flex flex-component-center', className)}

      {...props}
    >
      {
        isLoading && (
          <div className="my-16 mx-md">
            <Spin />
          </div>
        )
      }
      <img
        ref={el => {
          imageRef.current = el;

          if (ref) {
            ref(el);
          }
        }}
      />
    </div>
  );
}

LocalImage.propTypes = {
  file: PropTypes.object.isRequired,

  className: PropTypes.string,
  onLoaded: PropTypes.func,
};

const LocalImageWithRef = forwardRef(LocalImage);

export { LocalImageWithRef as LocalImage };
