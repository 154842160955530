import { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import downloadFiles from 'utlis/download_files.js';
import { errorReactToastify } from 'utlis/toasts';

import { ReactComponent as LeftArrowIcon } from 'assets/LeftArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/RightArrowIcon.svg';

import styles from './SelectedImageModal.module.scss';

const SelectedImageModal = ({
  isOpen,
  setIsOpen,
  images,
  imageCounter,
  setImageCounter,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const closeModalWindow = () => {
    setIsOpen(false);
  };

  const handleDownloadFiles = async () => {
    try {
      setIsDownloading(true);

      await downloadFiles(images[imageCounter]);
    }
    catch {
      errorReactToastify('There were some problems when trying to download this image');
    }
    finally {
      setIsDownloading(false);
    }
  };

  return (
    <Modal
      title={
        <span
          style={{
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: '400',
            letterSpacing: '0.25px',
            marginLeft: '-5px',
          }}
        >
          Download Reference Images
        </span>
      }
      destroyOnClose
      centered
      visible={isOpen}
      onCancel={closeModalWindow}
      footer={null}
      width={640}
    >
      <div className={styles.imageModal}>
        <div className={cn(styles.imagesContainer, 'flex align-center space-between')}>
          <button
            className={cn(styles['nav-button'], 'flex flex-component-center mr-sm')}
            onClick={() => setImageCounter(prevState =>
              prevState - 1 < 0 ? images?.length - 1 : prevState - 1,
            )}
          >
            <LeftArrowIcon />
          </button>
          <img src={images[imageCounter]} alt="Image" />
          <button
            className={cn(styles['nav-button'], 'flex flex-component-center ml-sm')}
            onClick={() => setImageCounter(prevState =>
              prevState + 1 >= images?.length ? 0 : prevState + 1,
            )}
          >
            <RightArrowIcon />
          </button>
        </div>
        <button
          className={styles['download-button']}
          onClick={handleDownloadFiles}
          disabled={isDownloading}
        >
          {
            isDownloading
              ? 'Downloading ...'
              : 'Download Image'
          }
        </button>
      </div>
    </Modal>
  );
};

SelectedImageModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  images: PropTypes.array,
  imageCounter: PropTypes.number,
  setImageCounter: PropTypes.func,
};

export default SelectedImageModal;
