import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import cn from 'classnames';

import { ReactComponent as BoardIcon } from 'assets/board.svg';
// import { ReactComponent as ExpertIcon } from 'assets/experts.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { Button } from 'Components/Button';
import { CollaboratorInviteModal } from 'Components/CollaboratorInviteModal';

import { selectProject } from 'Redux/Actions/projectActions';

import { ROUTES } from 'routes/route.constants';

import { onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export const ProjectDetailsHeader = ({ isProjectNotFound, isReadonly }) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const selectedProject = useSelector(state => state.selectedProject);
  const projectType = selectedProject?.is_complete ? 'completed' : 'active';

  const dispatch = useDispatch();

  const handleAddCollaborator = () => {
    setOpenInviteModal(true);
    dispatch(selectProject(selectedProject?.uuid, selectedProject?.slug));
  };

  return (
    <>
      <div className={styles.project__detailsHeader}>
        <div className={cn(styles.project__detailsLeft, 'flex align-center')}>
          <Link
            className={cn('ellipsis', styles.link, styles.project__navHeading)}
            to={ROUTES.DASHBOARD_PROJECT_TYPE(projectType)}
          >
            <FontAwesomeIcon
              className={cn(styles.back__arrow, { hide: isReadonly })}
              icon={faChevronLeft}
              size="lg"
            />
            {
              !isProjectNotFound && (
                selectedProject.name
                  || (
                    <Skeleton
                      className="invert-mb-md"
                      active
                      title={{ width: 200 }}
                      paragraph={false}
                    />
                  )
              )
            }
          </Link>
          <Link
            className={cn(
              styles.link,
              styles['project-tab'],
              {
                hide: isReadonly,
                [styles['project-tab-active']]: location.pathname.includes(ROUTES.BOARD(selectedProject?.slug)),
              }
            )}
            to={ROUTES.BOARD(selectedProject?.slug)}
          >
            <BoardIcon className="mr-8" width={20} height={20} />
            Tasks
          </Link>
          {/* <Link
            className={cn(
              'ml-40',
              styles.link,
              styles['project-tab'],
              {
                hide: isReadonly,
                [styles['project-tab-active']]: location.pathname.includes(ROUTES.WORKSPACE(selectedProject?.slug)),
              }
            )}
            to={ROUTES.WORKSPACE(selectedProject?.slug)}
          >
            <ExpertIcon className="mr-8" width={20} height={20} />
            Experts
          </Link> */}
        </div>
        {/* User Avatars */}
        <div
          className={cn(
            styles.project__detailsRight,
            {
              hide: isProjectNotFound || !selectedProject.uuid || isReadonly,
            }
          )}
        >
          <Button
            isInvert
            className="mr-24"
            onClick={() => handleAddCollaborator()}
          >
            Share
          </Button>
          <div className={styles.navbar__avatars}>
            <div className="flex flex-row-reverse">
              {
                selectedProject && selectedProject?.collaborators?.length > 0
                  ? (
                    [...selectedProject?.collaborators]
                      ?.reverse()
                      ?.filter(collaborator => collaborator?.user?.profile_photo)
                      ?.map(collaborator => (
                        <img
                          key={collaborator.uuid}
                          className={styles.navbar__avatar}
                          src={collaborator?.user?.profile_photo || defaultUserAvatar}
                          onError={e => onLoadImageError(e, defaultUserAvatar)}
                          alt={collaborator?.user?.full_name}
                        />
                      ))
                  )
                  : null
              }
              <img
                className={styles.navbar__avatar}
                src={selectedProject && selectedProject?.owner?.profile_photo || defaultUserAvatar}
                onError={e => onLoadImageError(e, defaultUserAvatar)}
                alt="OwnerAvatar"
              />
            </div>
          </div>
        </div>
      </div>
      <CollaboratorInviteModal
        visible={openInviteModal}
        onCancel={() => setOpenInviteModal(false)}
      />
    </>
  );
};

ProjectDetailsHeader.propTypes = {
  isProjectNotFound: PropTypes.bool,
  isReadonly: PropTypes.bool,
};
