import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

import { ElementNotFound } from 'Components/ElementNotFound';
import MilestoneProgressModal from 'Components/MilestoneCompleteModal';

import { hideMilestoneProgressModal, setAppState } from 'Redux/Actions/appActions';
import { getMilestones } from 'Redux/Actions/FeedActions';
import { initializeProjects, removeSelectedProjects, selectProject } from 'Redux/Actions/projectActions';
import { setUserProfile } from 'Redux/Actions/userActions';

import { NOTIFICATION_TYPES, PROJECT_STATUS } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';

import { ProjectDetailsHeader } from './components/ProjectDetailsHeader';

import styles from './styles.module.scss';

export const ProjectDetails = ({ isReadonly }) => {
  const { projectSlug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isProjectNotFound, setIsProjectNotFound] = useState(false);

  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);
  const projects = useSelector(state => state.projectStore);
  const milestoneProgressModalProps = useSelector(state => state.app.milestoneProgressModalProps);

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (authUser && authUser.uuid && !userProfile?.uuid) {
        dispatch(setUserProfile(authUser));
      }
    },
    [authUser]
  );

  useEffect(
    () => {
      if (!projects?.results?.length) {
        dispatch(initializeProjects(PROJECT_STATUS.active));
      }
      if (isReadonly) {
        dispatch(setAppState({
          showAllComments: false,
          isPinCommentMode: false,
          hidePinCommentMode: false,
          isAnnotationMode: false,
          showNotes: false,
          showExperts: false,
          isReadonly: true,
        }));
      }
      else {
        dispatch(setAppState({
          isReadonly: false,
        }));
      }
    },
    [isReadonly]
  );

  useEffect(
    () => {
      setIsLoading(true);
      dispatch(removeSelectedProjects());
      dispatch(selectProject(null, projectSlug))
        .then(project => dispatch(getMilestones(project)))
        .catch(error => {
          // TODO: this is a hack. The API should return 404
          if (error?.response?.status == 400) {
            setIsProjectNotFound(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [projectSlug]
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { project_slug } }) => {
        if (project_slug == projectSlug) {
          dispatch(selectProject(null, projectSlug));
        }
      },
      NOTIFICATION_TYPES.JOIN_PROJECT
    ),
    [projectSlug]
  );

  return (
    <>
      <div className={styles.workspace__body}>
        <div className={styles.project__details}>
          <ProjectDetailsHeader
            isProjectNotFound={isProjectNotFound}
            isReadonly={isReadonly}
          />
          {
            isLoading
              ? (
                <div className="flex align-center just-center pt-lg">
                  <Spin size="large" />
                </div>
              )
              : (
                !isProjectNotFound
                  ? <Outlet isReadonly={isReadonly} />
                  : (
                    <ElementNotFound
                      subTitle="We did not find this project. Maybe it was deleted"
                    />
                  )
              )
          }
        </div>
      </div>
      <MilestoneProgressModal
        {...milestoneProgressModalProps}
        onCancel={() => dispatch(hideMilestoneProgressModal())}
      />
    </>
  );
};

ProjectDetails.propTypes = {
  isReadonly: PropTypes.bool,
};
