import { ReactComponent as DesignBriefImageIcon } from 'assets/project_workspace_sidebar/design_brief.svg';
import { ReactComponent as ProposalImageIcon } from 'assets/project_workspace_sidebar/proposal.svg';
import { ReactComponent as ContractImageIcon } from 'assets/project_workspace_sidebar/contract.svg';
import { ReactComponent as MoodboardImageIcon } from 'assets/project_workspace_sidebar/moodboard.svg';
import { ReactComponent as PresentationImageIcon } from 'assets/project_workspace_sidebar/presentation.svg';
import { ReactComponent as DesignConceptsImageIcon } from 'assets/project_workspace_sidebar/design_concepts.svg';
import { ReactComponent as DeliveryImageIcon } from 'assets/project_workspace_sidebar/file_delivery.svg';
import { ReactComponent as UserInterviewImageIcon } from 'assets/project_workspace_sidebar/user_interview.svg';
import { ReactComponent as DesignReviewImageIcon } from 'assets/project_workspace_sidebar/design_review.svg';
import { ReactComponent as SketchesImageIcon } from 'assets/project_workspace_sidebar/sketches.svg';
import { ReactComponent as BrandPersonalityImageIcon } from 'assets/project_workspace_sidebar/brand_personality.svg';
import { ReactComponent as ColorPaletteImageIcon } from 'assets/project_workspace_sidebar/color_palette.svg';

import { ROUTES } from 'routes/route.constants';

import { MILESTONES_TYPES } from 'utlis/constants';

// TODO: Merge brief and uxBrief into one milestone type
export const MILESTONE_METADATA = {
  [MILESTONES_TYPES.designBrief]: {
    icon: DesignBriefImageIcon,
    backgroundColor: '#FFD1DD',
    route: ROUTES.BRIEF,
  },
  [MILESTONES_TYPES.uxBrief]: {
    icon: DesignBriefImageIcon,
    backgroundColor: '#FFD1DD',
    route: ROUTES.BRIEF,
  },
  [MILESTONES_TYPES.proposal]: {
    icon: ProposalImageIcon,
    backgroundColor: '#D1F8FF',
    route: ROUTES.PROPOSAL,
  },
  [MILESTONES_TYPES.contract]: {
    icon: ContractImageIcon,
    backgroundColor: '#FFE8D1',
    route: ROUTES.CONTRACT,
  },
  [MILESTONES_TYPES.moodboard]: {
    icon: MoodboardImageIcon,
    backgroundColor: '#D1E1FF',
    route: ROUTES.MOODBOARD,
  },
  [MILESTONES_TYPES.colorPalette]: {
    icon: ColorPaletteImageIcon,
    backgroundColor: '#F1D1FF',
    route: ROUTES.COLOR_PALETTE,
  },
  [MILESTONES_TYPES.designConcepts]: {
    icon: DesignConceptsImageIcon,
    backgroundColor: '#D1FFEB',
    route: ROUTES.DESIGN_CONCEPTS,
  },
  [MILESTONES_TYPES.presentation]: {
    icon: PresentationImageIcon,
    backgroundColor: '#E7FFD1',
    route: ROUTES.PRESENTATION,
  },
  [MILESTONES_TYPES.deliverFiles]: {
    icon: DeliveryImageIcon,
    backgroundColor: '#D1DDFF',
    route: ROUTES.DELIVERY,
  },
  [MILESTONES_TYPES.userInterview]: {
    icon: UserInterviewImageIcon,
    backgroundColor: '#FFF7D1',
    route: ROUTES.USER_INTERVIEW,
  },
  [MILESTONES_TYPES.designReview]: {
    icon: DesignReviewImageIcon,
    backgroundColor: '#FFD1E4',
    route: ROUTES.DESIGN_REVIEW,
  },
  [MILESTONES_TYPES.sketches]: {
    icon: SketchesImageIcon,
    backgroundColor: '#FFD1EE',
    route: ROUTES.SKETCHES,
  },
  [MILESTONES_TYPES.brandPersonality]: {
    icon: BrandPersonalityImageIcon,
    backgroundColor: '#FFD8D1',
    route: ROUTES.BRAND_PERSONALITY,
  },
};
