import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import sortCheckIcon from 'assets/check_icon.svg';

import { KANBAN_BOARD_DATE_RANGE_OPTIONS, SHORT_MONTH_NAMES } from 'utlis/constants';

import styles from './styles.module.scss';

export function FilterCategory({
  title,
  items,
  tasksCount,
  handleClickFunction,
  selectedItems,
  optionPrefix,
}) {
  const [categoryItems, setCategoryItems] = useState(items);
  const [showMore, setShowMore] = useState(false);

  useEffect(
    () => {
      setCategoryItems(
        items.length > 4 && !showMore
          ? items.slice(0, 3)
          : items
      );
    },
    [items, showMore]
  );

  return (
    <>
      <div className={styles['category-title']}>
        {title}
      </div>
      {
        categoryItems.map(
          item => (
            <div
              key={item.figma_file_key || item.email || item}
              className={styles['category-option-container']}
              onClick={() => handleClickFunction(item.figma_file_key || item)}
            >
              <div
                className={cn(
                  'flex, space-between',
                  styles['category-option'],
                  {
                    [styles['category-option-selected']]: selectedItems.includes(
                      item.figma_file_key || item
                    ),
                  }
                )}
              >
                <div className={styles['text-container']}>
                  {title === 'Priority' || title === 'Assignee'
                    ? optionPrefix(item)
                    : optionPrefix(item.file_type || '')}
                  <span className={styles['text']}>
                    {item.figma_file_name || item.full_name || item.email || item}
                  </span>
                  {
                    title === 'Date' && (
                      <p className={styles['date-range']}>
                        {
                          dateRange(item)
                        }
                      </p>
                    )
                  }
                </div>
                <div className={styles['check-icon-container']}>
                  {
                    tasksCount && (
                      <span>
                        {/* show tasks count */}
                        {`${tasksCount[item.figma_file_key || item.email || item]} ${tasksCount[item.figma_file_key || item.email || item] > 1 ? 'tasks' : 'task'}`}
                      </span>
                    )
                  }
                  <img
                    src={sortCheckIcon}
                    className={cn(
                      styles['check-icon'],
                      {
                        [styles['check-icon-selected']]: selectedItems.includes(
                          item.figma_file_key || item
                        ),
                      }
                    )}
                    alt="Check Icon"
                  />
                </div>
              </div>
            </div>
          )
        )
      }
      <div
        className={cn(
          styles['category-footer'],
          { [styles['remove-border']]: title === 'Date' }
        )}
      >
        {
          items.length > 4
            && (
              <button className={styles['show-more-btn']} onClick={() => setShowMore(categoryItems.length <= 4)}>
                {`${showMore ? 'Show less' : `Show ${items.slice(3).length} more`}`}
              </button>
            )
        }
      </div>
    </>
  );

  function startDateFormat(date) {
    const day = date.getDate();
    const month = SHORT_MONTH_NAMES[date.getMonth()];

    return `${day} ${month}`;
  }

  function endDateFormat(date) {
    const day = date.getDate();
    const month = SHORT_MONTH_NAMES[date.getMonth()];

    return `${day} ${month}, ${date.getFullYear()}`;
  }

  function dateRange(option) {
    const secondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const currentDate = new Date();

    let startDate;
    const endDate = endDateFormat(currentDate);

    switch (option) {
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_WEEK:
        startDate = new Date(currentDate.getTime() - secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_TWO_WEEKS:
        startDate = new Date(currentDate.getTime() - 2 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_THREE_WEEKS:
        startDate = new Date(currentDate.getTime() - 3 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_MONTH:
        startDate = new Date(currentDate);
        startDate.setMonth(startDate.getMonth() - 1);
        break;
    }

    return `${startDateFormat(startDate)} - ${endDate}`;
  }
}

FilterCategory.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      figma_file_key: PropTypes.string,
      figma_file_name: PropTypes.string,
      file_type: PropTypes.string,
    })),
  ]),
  tasksCount: PropTypes.object.isRequired,
  selectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  handleClickFunction: PropTypes.func.isRequired,
  optionPrefix: PropTypes.func,
};
