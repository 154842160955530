import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import cn from 'classnames';

import { ElementNotFound } from 'Components/ElementNotFound';

import { removeAllCommentsFromReduxStore, setCommentSuggestion }
  from 'Redux/Actions/commentActions';
import { getMilestones } from 'Redux/Actions/FeedActions';
import { setUserProfile } from 'Redux/Actions/userActions';

import { ROUTES } from 'routes/route.constants';

import { NOTIFICATION_TYPES } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';

import { MilestonesList } from './components/MilestonesList';

import styles from './styles.module.scss';

export const Workspace = ({ isReadonly }) => {
  const { milestoneId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const currentProject = useSelector(state => state.selectedProject);
  const authUser = useSelector(state => state.userStore);
  const userProfile = useSelector(state => state.userProfile);
  const selectedMilestone = useSelector(state => state.milestonesStore.dictionaryId?.[milestoneId]);
  const currentMilestone = useSelector(state => state.milestonesStore.currentMilestone);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(
    () => onSocketMessage(
      ({ data: { project_uuid } }) => {
        if (project_uuid == currentProject.uuid) {
          dispatch(getMilestones(currentProject));
        }
      },
      [
        NOTIFICATION_TYPES.MILESTONE_SEQUENCE_UPDATED,
        NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
        NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
      ]
    ),
    [currentProject]
  );

  useEffect(
    () => (() => dispatch(removeAllCommentsFromReduxStore())),
    [milestoneId]
  );

  useEffect(
    () => {
      if (!milestoneId && currentMilestone && !isLoading) {
        navigate(
          ROUTES.PROJECT_MILESTONES(
            currentProject.slug,
            currentMilestone.type,
            currentMilestone.id
          ),
          { replace: true }
        );
      }
    },
    [milestoneId, currentMilestone, isLoading]
  );

  useEffect(
    () => {
      if (currentProject) {
        dispatch(getMilestones(currentProject))
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [dispatch, currentProject]
  );

  useEffect(
    () => {
      if (authUser && authUser.uuid && Object.keys(userProfile).length === 0) {
        dispatch(setUserProfile(authUser));
      }
    },
    [dispatch, authUser]
  );

  return (
    <DragDropContext
      onDragStart={({ draggableId }) => {
        dispatch(setCommentSuggestion(draggableId));
      }}
      onDragEnd={() => {
        dispatch(setCommentSuggestion(null));
      }}
    >
      <div
        className={styles.project__workspaceWrapper}
      >
        <div className={styles.workspace__sidebar__wrapper}>
          <MilestonesList isReadonly={isReadonly} />
        </div>
        <div className={cn(styles.workspace__content__wrapper, 'flex flex-column')}>
          {
            isLoading || !milestoneId
              ? (
                <div className="flex flex-component-center py-lg">
                  <Spin size="large" />
                </div>
              )
              : (
                selectedMilestone
                  ? <Outlet />
                  : (
                    <ElementNotFound
                      subTitle="We did not find this milestone. Maybe it was deleted"
                    />
                  )
              )
          }
        </div>
      </div>
    </DragDropContext>
  );
};

Workspace.propTypes = {
  isReadonly: PropTypes.bool,
};
