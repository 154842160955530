import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Spin } from 'antd';

import { PluginSignin } from 'Pages/PluginSignIn';

import { getProjectStatusCountAction } from 'Redux/Actions/projectActions';
import {
  initializeSettingExtensionUser,
  initializeSettingUser,
  initializeSignInUser,
  refreshAuthToken,
  setSignedInUser,
} from 'Redux/Actions/userActions';

import { ROUTES } from 'routes/route.constants';

import { identifyUser, trackEvent } from 'services/eventTracking';

import { PLUGIN_SOURCE_IDENTIFIERS } from 'utlis/constants';
import { logoutUser, parseJwt } from 'utlis/globalFunctions';
import { socketInit } from 'utlis/socket';

import styles from './styles.module.scss';

export const GoogleAuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const projectStatusCount = useSelector(state => state.projectStatusCount);

  const [showUI, setShowUI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userObjectFromLocal, setUserObjectFromLocal] = useState(JSON.parse(localStorage.getItem('userObject')));
  const [errorMessage, setErrorMessage] = useState('');

  const writeKey = searchParams.get('writeKey');
  const userID = searchParams.get('userID');
  const returnURL = searchParams.get('returnURL');
  const pluginSource = searchParams.get('source');

  localStorage.setItem('writeKey', writeKey);

  const isEventTrackingDone = useRef(false);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: onSuccessDesigner,
    // eslint-disable-next-line no-console
    onError: resp => console.log(resp),
    select_account: true,
    hosted_domain: '*',
  });

  const loginButton = (
    <button
      className={styles['login-button-container']}
      onClick={loginViaGoogle}
    >
      <FontAwesomeIcon className={styles['google-icon']} icon={faGoogle} />
      <span className={styles['button-text']}>
        CONTINUE WITH GOOGLE
      </span>
    </button>
  );

  useEffect(
    () => {
      if (!isLoading) {
        if (userObjectFromLocal?.access) {
          if (!isEventTrackingDone.current) {
            identifyUser(userObjectFromLocal.uuid);
            trackEvent('[page] google auth plugin', { 'writeKey': writeKey, 'page name': 'figma plugin google auth' });
            isEventTrackingDone.current = true;
          }
          if (returnURL) {
            navigate(returnURL);
          }
          else {
            navigateToNextPage(projectStatusCount);
          }
        }
        else {
          setShowUI(true);

          if (!isEventTrackingDone.current) {
            trackEvent('[page] google auth plugin', { 'writeKey': writeKey, 'page name': 'figma plugin google auth' });
            isEventTrackingDone.current = true;
          }
        }
      }
    },
    [userObjectFromLocal, isLoading]
  );

  useEffect(
    (async () => {
      if (userObjectFromLocal) {
        setIsLoading(true);

        try {
          await dispatch(refreshAuthToken(userObjectFromLocal.refresh));

          dispatch(getProjectStatusCountAction())
            .finally(() => {
              setIsLoading(false);
            });
        }
        catch {
          logoutUser(dispatch, navigate, true);
          setIsLoading(false);
          setUserObjectFromLocal(null);
        }
      }
      else {
        setIsLoading(false);
      }
    }),
    []
  );

  return (
    showUI || !isLoading
      ? <PluginSignin loginButton={loginButton} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      : (
        <p className={styles['spinner-container']}>
          <Spin size="large" />
        </p>
      )
  );

  function loginViaGoogle() {
    trackEvent('[click] google auth', { 'writeKey': writeKey, 'page name': 'figma plugin google auth' });
    googleLogin();
  }

  function navigateToNextPage(projectsCount) {
    const totalProjects = projectsCount.active + projectsCount.completed;

    if (totalProjects === 0) {
      navigate(ROUTES.CREATE_NEW_PROJECT);
    }
    else
    if (pluginSource === PLUGIN_SOURCE_IDENTIFIERS.DESIGN_REVIEWER) {
      navigate(ROUTES.REVIEWER_AUTH_COMPLETE);
    }
    else if (pluginSource === PLUGIN_SOURCE_IDENTIFIERS.CHROME_EXTENSION) {
      navigate(ROUTES.EXTENSION_AUTH_COMPLETE);
    }
    else {
      navigate(ROUTES.AUTH_COMPLETE);
    }
  }

  async function onSuccessDesigner(response) {
    if (response) {
      if ((writeKey && returnURL && userID) || pluginSource === PLUGIN_SOURCE_IDENTIFIERS.CHROME_EXTENSION) {
        let result = null;

        if (userID) {
          result = await initializeSettingUser(response.code, writeKey, returnURL, userID, pluginSource);
        }
        else {
          result = await initializeSettingExtensionUser(response.code, writeKey, returnURL, pluginSource);
        }

        if (!result) {
          setErrorMessage(
            `Oh! Looks like you created a local account (non-Google authenticated)
            with that email already. Try signing in!`
          );

          return;
        }

        ReactGA.set({ userId: result?.uuid });
        identifyUser(result?.uuid);

        if (result?.user_type === null) {
          navigate('/onboarding');
        }
        else {
          navigate(returnURL);
        }
      }
      else {
        const result = await dispatch(initializeSignInUser(response.code));

        if (!result) {
          setErrorMessage(
            `Oh! Looks like you created a local account (non-Google authenticated)
            with that email already. Try signing in!`
          );

          return;
        }

        ReactGA.set({ userId: result?.data?.uuid });
        identifyUser(result?.data?.uuid);

        const projectsCount = await dispatch(getProjectStatusCountAction());

        if (result?.data?.user_type === null) {
          navigate('/onboarding');
        }
        else if (returnURL) {
          navigate(returnURL);
        }
        else {
          navigateToNextPage(projectsCount);
        }
      }
    }
    else {
      const userObject = JSON.parse(localStorage.getItem('userObject'));
      const jwtToken = parseJwt(userObject.access);

      dispatch(setSignedInUser(userObject));
      identifyUser(userObject.uuid);

      if (jwtToken.exp * 1000 < Date.now()) {
        try {
          await dispatch(refreshAuthToken(userObject.refresh));
        }
        catch (error) {
          if (error?.response?.status === 401) {
            localStorage.removeItem('authenticated');
          }
          else {
            Sentry.captureException(error);
          }
        }
      }
    }
    socketInit(localStorage.getItem('access_token'));
  }
};
