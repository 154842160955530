import { useState, Fragment } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';

import LinkForm from './components/LinkForm';

import styles from '../styles.module.scss';

export default function LinksList({ name, rules = [], required, ...props }) {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: required,
          message: 'This field is required',
        },
        ...rules,
      ]}
    >
      <LinksListField {...props} />
    </Form.Item>
  );
}

LinksList.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.array,
  required: PropTypes.bool,
};

function LinksListField({ value = [], placeholder, onChange, disabled, ListWrapComponent, ...props }) {
  const [showLinkFormId, setShowLinkFormId] = useState(value.length);

  return (
    <ListWrapComponent>
      {
        [...value, null].map((link, idx) => (
          <li
            key={`${link}-${idx}`}
            className={cn(
              styles['field-li'],
              {
                hide: (!link && showLinkFormId != value.length) || (link == null && disabled),
              }
            )}
          >
            {
              showLinkFormId != idx
                ? (
                  <>
                    <a
                      href={`${/(http|https):\/\//.test(link) ? '' : '//'}${link}`}
                      target="_blank"
                      rel="noreferrer"
                      className="break-all"
                    >
                      {link}
                    </a>
                    <Button
                      isText
                      className={cn(
                        'ml-md-lg-important text-pacificBlue',
                        {
                          hide: showLinkFormId != value.length || disabled,
                        }
                      )}
                      onClick={() => setShowLinkFormId(idx)}
                    >
                      EDIT
                    </Button>
                    <Button
                      isText
                      className={cn(
                        'ml-md-lg-important text-pacificBlue',
                        {
                          hide: showLinkFormId != value.length || disabled,
                        }
                      )}
                      onClick={() => {
                        value.splice(idx, 1);
                        onChange(value);
                        setShowLinkFormId(value.length);
                      }}
                    >
                      DELETE
                    </Button>
                  </>
                )
                : (
                  <LinkForm
                    key={`${link}-${idx}`}
                    onSave={newLink => {
                      value[idx] = newLink;
                      onChange(value);
                      setShowLinkFormId(value.length);
                    }}
                    placeholder={placeholder}
                    initialValue={link || ''}
                    onCancel={showLinkFormId != value.length
                      ? () => setShowLinkFormId(value.length)
                      : null
                    }
                    wrapperClassName="flex align-center mt-sm"

                    {...props}
                  />
                )
            }
          </li>
        ))
      }
    </ListWrapComponent>
  );
}

LinksListField.propTypes = {
  onChange: PropTypes.func.isRequired,

  placeholder: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  ListWrapComponent: PropTypes.func,
};

LinksListField.defaultProps = {
  ListWrapComponent: Fragment,
};
