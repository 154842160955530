import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { AddGoogleDocContent } from './components/AddGoogleDocContent';
import { ConnectGoogleDoc } from './components/ConnectGoogleDoc';

export const AddGoogleDocs = ({ setGoogleDocDetails }) => {
  const googleDocsToken = useSelector(state => state.userStore?.appTokens?.googleDocs);

  return (
    <div>
      {
        googleDocsToken
          ? <AddGoogleDocContent setGoogleDocDetails={setGoogleDocDetails} />
          : <ConnectGoogleDoc />
      }
    </div>
  );
};

AddGoogleDocs.propTypes = {
  setGoogleDocDetails: PropTypes.func.isRequired,
};
