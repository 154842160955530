import http from 'utlis/http';
import { successReactToastify, errorReactToastify } from 'utlis/toasts';

import StripePaymentService from 'services/stripe_payment';

import actionTypes from '../constants';
import { selectProject } from './projectActions';
import { setUserProfile } from './userActions';
import { getMilestones, initializeFeedFiles } from './FeedActions';

export const unlinkStripeAccount = () => async dispatch => {
  try {
    return StripePaymentService.unlinkStripeAccount()
      .then(() => {
        dispatch({ type: actionTypes.UNLINK_STRIPE_ACCOUNT });
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const withdrawAmount = authUser => async dispatch => {
  try {
    return StripePaymentService.withdrawMoney()
      .then(data => {
        successReactToastify(data?.message);
        dispatch(setUserProfile(authUser));
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    errorReactToastify(error?.response?.data?.detail);
  }
};

export const submitDepositRequest = (
  selectedProject,
  amount,
) => async dispatch => {
  try {
    const data = new FormData();
    data.append('amount', amount);

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/`,
      data: data,
    };

    http(config)
      .then(response => {
        successReactToastify(response?.data?.message);
      })
      .then(() => {
        dispatch(selectProject(selectedProject?.uuid));
        dispatch(initializeFeedFiles(selectedProject));
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const payDeposit = (
  selectedProject,
  pmId,
  setClientSecret,
  setStripeModal,
  setIsLoading,
) => async dispatch => {
  try {
    const url = pmId
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/pay_deposit/?pm_id=${pmId}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/pay_deposit/`;

    const config = {
      method: 'patch',
      url: url,
    };

    http(config)
      .then(response => {
        if (pmId) {
          successReactToastify(response?.data?.message);
          dispatch(initializeFeedFiles(selectedProject));
          dispatch(getMilestones(selectedProject));
        }
        else {
          setStripeModal(true);
          if (response?.data?.client_secret) {
            setClientSecret(response?.data?.client_secret);
            if (setIsLoading) {
              setIsLoading(false);
            }
          }
          else {
            if (setIsLoading) {
              setIsLoading(true);
            }
            dispatch(selectProject(selectedProject?.uuid));
            dispatch(
              dispatch(
                initializeFeedFiles(selectedProject, setIsLoading),
              ),
            );
            successReactToastify(response?.data?.message);
          }
        }
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const postPayment = selectedProject => async dispatch => {
  try {
    const saveCreditCardChoice = window.localStorage.getItem(
      'save_card_choice',
    );
    const config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/post_payment/?is_save=${saveCreditCardChoice}`,
    };

    http(config)
      .then(() => {
        successReactToastify('Payment has been successfully captured');
      })
      .then(() => {
        dispatch(selectProject(selectedProject?.uuid));
        dispatch(initializeFeedFiles(selectedProject));
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getPaymentDetails = (
  selectedProject,
  milestoneId,
  setPaymentDetails,
) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/?milestone_id=${milestoneId}`,
    };
    http(config).then(response => {
      if (response?.data) {
        setPaymentDetails(response?.data);
        window.localStorage.setItem(
          'payment_status',
          response?.data?.payment_status,
        );
        window.localStorage.setItem(
          'designer_fee',
          response?.data?.designer_fee,
        );
        window.localStorage.setItem(
          'designpro_fee',
          response?.data?.designpro_fee,
        );
        window.localStorage.setItem('stripe_fee', response?.data?.stripe_fee);
        window.localStorage.setItem('total', response?.data?.total);
      }
    });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const denyDepositRequest = selectedProject => async dispatch => {
  try {
    const config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/payment/deny_deposit_request/`,
    };

    http(config)
      .then(response => {
        successReactToastify(response?.data?.message);
        dispatch(initializeFeedFiles(selectedProject));
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const getPaymentMethods = () => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/payment_method/`,
    };

    http(config)
      .then(response => {
        dispatch({
          type: actionTypes.GET_PAYMENT_DETAILS,
          payload: response.data,
        });
      })
      .catch(error => errorReactToastify(error?.response?.data?.detail));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removePaymentMethods = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_PAYMENT_DETAILS, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};
