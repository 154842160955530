import actionTypes from '../constants';

const notesInitialState = [];

export const notesReducer = (state = notesInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTES:
      return action.payload;
    case actionTypes.EDIT_NOTE:
      return state.map(noteObj =>
        noteObj.uuid === action.payload.uuid ? action.payload : noteObj,
      );
    default:
      return state;
  }
};
