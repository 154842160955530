import axios from 'axios';

import http from 'utlis/http';

const baseURL = process.env.REACT_APP_STAGING_TASK_BASE_URL;

export const getFigmaCommentTasks = async (
  figmaFileKey,
  figmaAccessToken,
  userUUID
) => axios.post(`${baseURL}/get_figma_comments`, {
  figma_file_key: figmaFileKey,
  figma_access_token: figmaAccessToken,
  userUUID,
});

export const getFigmaCommentTasksV2 = async (
  figmaFileKey,
  figmaAccessToken,
  projectID,
  figmaFileName,
  userUUID
) => await axios.post(`${baseURL}/process_file`, {
  file_type: 1,
  project_id: projectID,
  figma_file_name: figmaFileName,
  figma_file_key: figmaFileKey,
  figma_access_token: figmaAccessToken,
  userUUID,
});

export const getTranscriptionCommentTasks = async (
  projectID,
  fileName,
  title,
  content,
  userUUID
) => axios.post(`${baseURL}/process_file`, {
  file_type: 2, // 2 is for transcription file types
  project_id: projectID,
  file_name: fileName,
  transcript_title: title,
  transcript_content: content,
  userUUID,
});

export const getNotesTasks = async (
  projectID,
  fileName,
  title,
  content,
  userUUID
) => axios.post(`${baseURL}/process_file`, {
  file_type: 4, // 4 is for notes file types
  project_id: projectID,
  file_name: fileName,
  notes_title: title,
  notes_content: content,
  userUUID,
});

export const addSlackLink = async (projectID, fileType, threadUrl, slackToken, userUUID) => {
  const data = {
    file_type: fileType,
    project_id: projectID,
    slack_access_token: slackToken,
    thread_url: threadUrl,
    userUUID,
  };

  return axios.post(`${baseURL}/process_file`, data);
};

export const getLatestTasks = async (
  figmaFileKeys,
  figmaAccessToken,
  projectID,
  userUUID
) => axios.post(`${baseURL}/fetch_latest_tasks`, {
  figma_file_keys: figmaFileKeys,
  figma_access_token: figmaAccessToken,
  project_id: projectID,
  userUUID,
}).then(response => response.data);

export const getFigmaCommentImage = async (
  figmaFileKey,
  figmaAccessToken,
  comment,
  userUUID
) => axios.post(`${baseURL}/generate_images`, {
  figma_file_key: figmaFileKey,
  figma_access_token: figmaAccessToken,
  comment,
  version: 2,
  userUUID,
});

export const createCustomTask = (
  projectID,
  taskDetails,
  userUUID
) => axios.post(`${baseURL}/custom_task`, {
  project_id: projectID,
  tasks: [taskDetails],
  userUUID,
});

export const updateTaskAPI = async (taskID, taskDetails, userUUID) => {
  const { data } = await http
    .patch(`${baseURL}/update_task/${taskID}`, {
      taskDetails,
      userUUID,
    })
    .catch(error => ({ message: error.response.data.message, failed: true }));

  return data;
};

export const updateProjectAPI = async (projectID, updatedField, userUUID) => {
  const { data } = await http
    .patch(`${baseURL}/update_project/${projectID}`, {
      updatedField,
      userUUID,
    })
    .catch(error => ({ message: error.response.data.message, failed: true }));

  return data;
};
