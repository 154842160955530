import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import cn from 'classnames';

import { ReactComponent as ImportIcon } from 'assets/import_icon.svg';

import { Button } from 'Components/Button';
import DateFromNow from 'Components/DateFromNow';

import { KANBAN_BOARD_FILE_TYPE_VALUES } from 'utlis/constants';

import { FilterMenu } from './components/FilterMenu';
import { SortMenu } from './components/SortMenu';
import { TaskSearchBar } from './components/TaskSearchBar';

import styles from './styles.module.scss';

export function KanbanBoardHeader({
  lastUpdated,
  isLoadingTasks,
  inProgressStatus,
  sortOption,
  onChangeSortOption,
  selectedFileKeys,
  onChangeSelectedFileNames,
  priorities,
  selectedAssignees,
  onChangeSelectedAssignees,
  selectedPriorities,
  onChangeSelectedPriorities,
  labels,
  selectedLabels,
  onChangeSelectedLabels,
  selectedDateRange,
  onChangeDateRange,
  setIsFiltered,
  handleShowManageFileModal,
  onChangeSearchValue,
  handleClearFilterOptions,
  handleReSyncTasks,
  taskAssignees,
}) {
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);

  const isFigmaFileAvailable = useMemo(
    () => savedFigmaFiles?.find(file => file.file_type === KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA),
    [savedFigmaFiles]
  );

  return (
    <div className={styles['kanban-board-header']}>
      <div className="flex align-center">
        <FilterMenu
          taskAssignees={taskAssignees}
          isLoadingTasks={isLoadingTasks}
          selectedFileKeys={selectedFileKeys}
          onChangeSelectedFileNames={onChangeSelectedFileNames}
          priorities={priorities}
          selectedPriorities={selectedPriorities}
          selectedAssignees={selectedAssignees}
          onChangeSelectedAssignees={onChangeSelectedAssignees}
          onChangeSelectedPriorities={onChangeSelectedPriorities}
          labels={labels}
          selectedLabels={selectedLabels}
          onChangeSelectedLabels={onChangeSelectedLabels}
          selectedDateRange={selectedDateRange}
          onChangeDateRange={onChangeDateRange}
          setIsFiltered={setIsFiltered}
          handleClearFilterOptions={handleClearFilterOptions}
        />
        <SortMenu selectedOption={sortOption} onChangeOption={onChangeSortOption} />
        <TaskSearchBar className={styles['search-bar']} onChangeSearchValue={onChangeSearchValue} />
      </div>
      <div className="flex align-center">
        {
          lastUpdated && (
            <span className={styles['last-updated']}>
              Last updated:
              {' '}
              <DateFromNow date={lastUpdated} />
            </span>
          )
        }
        {
          inProgressStatus
            ? (
              <span
                className={styles['sync-comments']}
              >
                <Spin className="flex mr-8" size="small" />
                {inProgressStatus}
              </span>
            )
            : (
              <Button
                className={cn('mr-16', styles['files-button'])}
                disabled={!isFigmaFileAvailable || isLoadingTasks}
                onClick={handleReSyncTasks}
              >
                <FontAwesomeIcon icon={faRotateRight} />
                Re-sync files
              </Button>
            )
        }
        <Button
          className={styles['files-button']}
          onClick={handleShowManageFileModal}
          disabled={inProgressStatus || isLoadingTasks}
          isInvert
        >
          <ImportIcon
            className={
              cn({
                [styles['disabled-import-icon']]: inProgressStatus || isLoadingTasks,
              })
            }
          />
          Import file
        </Button>
      </div>
    </div>
  );
}

export default KanbanBoardHeader;

KanbanBoardHeader.propTypes = {
  lastUpdated: PropTypes.string,
  isLoadingTasks: PropTypes.bool,
  inProgressStatus: PropTypes.string,
  sortOption: PropTypes.string,
  onChangeSortOption: PropTypes.func,
  selectedFileKeys: PropTypes.arrayOf(PropTypes.string),
  onChangeSelectedFileNames: PropTypes.func,
  priorities: PropTypes.arrayOf(PropTypes.string),
  selectedPriorities: PropTypes.arrayOf(PropTypes.string),
  selectedAssignees: PropTypes.array,
  onChangeSelectedAssignees: PropTypes.func,
  onChangeSelectedPriorities: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
  selectedLabels: PropTypes.arrayOf(PropTypes.string),
  onChangeSelectedLabels: PropTypes.func,
  selectedDateRange: PropTypes.string,
  onChangeDateRange: PropTypes.func,
  setIsFiltered: PropTypes.func,
  handleShowManageFileModal: PropTypes.func,
  handleReSyncTasks: PropTypes.func,
  onChangeSearchValue: PropTypes.func,
  handleClearFilterOptions: PropTypes.func,
  taskAssignees: PropTypes.arrayOf(PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    profile_photo: PropTypes.string,
  })),
};
