import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { AddThreadLink } from './AddThreadLink';
import { LogIntoSlack } from './LogIntoSlack';

export const AddSlackContent = ({ handleAddSlackThread }) => {
  const slackToken = useSelector(state => state.userStore?.appTokens?.slack);

  return (
    <div>
      {slackToken
        ? <AddThreadLink handleAddSlackThread={handleAddSlackThread} />
        : <LogIntoSlack />}
    </div>
  );
};

AddSlackContent.propTypes = {
  handleAddSlackThread: PropTypes.func.isRequired,
};
