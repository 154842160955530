import actionTypes from 'Redux/constants';

import { getUserInfo } from './userReducer';

export const projectReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_PROJECTS:
      return {
        ...action.payload,
        results: action.payload.results.map(getProjectInfo),
      };
    case actionTypes.UPDATE_PROJECTS:
      if (
        state.results[state.results.length - 1]?.uuid
          !== action.payload.results[action.payload.results.length - 1]?.uuid
      ) {
        return {
          ...action.payload,
          results: [...state.results, ...action.payload.results].map(getProjectInfo),
        };
      }

      return state;
    case actionTypes.ADD_NEW_PROJECT:
      return {
        ...state,
        results: [action.payload, ...state?.results],
      };
    case actionTypes.REMOVE_PROJECTS:
      return {};
    case actionTypes.DELETE_PROJECT:
      return state?.results.filter(
        file => file?.uuid !== action?.payload?.uuid
      );
    default:
      return state;
  }
};

export const projectStatusCountReducer = (
  state = { recent: 0, active: 0, completed: 0, myApps: 0 },
  action
) => {
  switch (action.type) {
    case actionTypes.GET_PROJECT_STATUS_COUNT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const selectedProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_PROJECT:
      return getProjectInfo({
        discovery: state.discovery,
        proposal: state.proposal,
        ...action.payload,
      });
    case actionTypes.UPDATE_SELECTED_PROJECT_DISCOVERY:
    case actionTypes.SELECTED_PROJECT_DISCOVERY:
      return getProjectInfo({
        ...state,
        discovery: getDiscoveryInfo(action.payload),
      });
    case actionTypes.UPDATE_SELECTED_PROJECT_PROPOSAL:
    case actionTypes.SELECTED_PROJECT_PROPOSAL:
      return getProjectInfo({
        ...state,
        proposal: getProposalInfo(action.payload),
      });
    case actionTypes.REMOVE_SELECTED_PROJECT:
      return {};
    default:
      return state;
  }
};

export const projectTypeMilestonesReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_PROJECT_TYPE_MILESTONES:
      return action.payload;
    case actionTypes.REMOVE_PROJECT_TYPE_MILESTONES:
      return {};
    default:
      return state;
  }
};

function getProjectInfo(projectInfo) {
  let designName = projectInfo.type
    .toLowerCase()
    .replace('design', '')
    .trim();

  designName = designName == 'ux' ? designName.toUpperCase() : designName;

  return {
    ...projectInfo,
    designName,
    owner: getUserInfo(projectInfo.owner),
    collaborators: projectInfo.collaborators.map(collaborator => ({
      ...collaborator,
      user: getUserInfo(collaborator.user),
    })),
  };
}

function getDiscoveryInfo(discoveryInfo) {
  const questionsList = discoveryInfo
    .reduce(
      (current, { questions: nextQuestionList }) => current.concat(nextQuestionList),
      []
    )
    .flat();

  return {
    uuid: discoveryInfo?.[0]?.uuid,
    questionGroupList: discoveryInfo,
    answersList: questionsList.reduce(
      (current, { uuid, answer, question_format }) => ({
        ...current,
        [uuid]: getAnswer({ question_format, answer }),
      }),
      {}
    ),
    verbalAnswersList: questionsList.reduce(
      (current, { question_type, answer, question_format }) => ({
        ...current,
        ...(
          question_type
            ? {
              [question_type]: getAnswer({ question_format, answer }),
            }
            : null
        ),
      }),
      {}
    ),
    questionsList: questionsList.reduce(
      (current, question) => ({
        ...current,
        [question.uuid]: question,
      }),
      {}
    ),
    verbalQuestionsList: questionsList.reduce(
      (current, question) => ({
        ...current,
        [question.question_type || question.uuid]: question,
      }),
      {}
    ),
  };

  function getAnswer({ question_format, answer }) {
    const res = answer?.answer || null;

    switch (question_format) {
      case 'multiselect_dropdown':
      case 'multi_file_field':
      case 'file_field':
      case 'checkbox':
        return res || [];
      case 'list':
        return res || [''];
      case 'links':
        return res || [];
      case 'colors':
        // TODO: This is a hack. We need to update the component for future use
        return res ? res.join(',') : '';
      case 'range':
        return [
          res?.[0] || '',
          res?.[1] || '',
        ];
      default:
        return res;
    }
  }
}

function getProposalInfo(proposalInfo = {}) {
  return {
    ...proposalInfo,
    proposal_json: {
      ...proposalInfo?.proposal_json,
      product_available_in: proposalInfo?.proposal_json?.product_available_in || [],
      deliverables: proposalInfo?.proposal_json?.deliverables || [],
    },
  };
}
