import actionTypes from '../constants';

export const paymentReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_DETAILS:
      return action.payload;
    case actionTypes.REMOVE_PAYMENT_DETAILS:
      return {};
    default:
      return state;
  }
};
