import PropTypes from 'prop-types';
import { Switch } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

export const ToggleSwitch = ({ isDisabled, onChange }) => (
  <div className="flex">
    <Switch
      className={cn('mr-16', styles['toggle__switch'])}
      defaultChecked
      onChange={onChange}
      disabled={isDisabled}
    />
    <span className={styles['status']}>
      Connected
    </span>
  </div>
);

ToggleSwitch.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};
