import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'antd';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import { fullPriceFormat } from 'utlis/string_format';

import { Button } from 'Components/Button';
import { CardIcon } from 'Components/CardIcon';

import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import StripePaymentService from 'services/stripe_payment';

import styles from './styles.module.scss';

export default function PaymentFormModal({
  projectUUID,
  paymentItem,
  onCancel,
  onSuccessPayment,

  ...props
}) {
  const steps = {
    viewPaymentDetails: 'View payment details',
    payment: 'Payment',
  };

  const [isSaveCard, setIsSaveCard] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('new');
  const [currentStep, setCurrentStep] = useState(steps.viewPaymentDetails);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const paymentDetails = useMemo(
    () => ([
      {
        title: 'Designer Fee',
        price: fullPriceFormat(paymentItem.amount, {
          currency: paymentItem.currency,
          minimumFractionDigits: 2,
        }),
      },
      {
        title: 'Stripe Fee',
        price: fullPriceFormat(paymentItem.stripe_fee, {
          currency: paymentItem.currency,
          minimumFractionDigits: 2,
        }),
      },
      {
        title: 'Total',
        price: fullPriceFormat(paymentItem.total, {
          currency: paymentItem.currency,
          minimumFractionDigits: 2,
        }),
      },
    ]),
    [paymentItem]
  );

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  return (
    <Modal
      title={
        <span className="text-weight-500 text-20 letter-xs">
          Payment
        </span>
      }
      className={styles.paymentFormModal}
      width="55%"
      footer={null}
      onCancel={onCancel}

      {...props}
    >
      <Row className="mt-md">
        <Col span="8">
          {
            Object.keys(steps).map((stepKey, idx) => {
              const isStepDone = idx < Object.values(steps).indexOf(currentStep);

              return (
                <div
                  key={stepKey}
                  className={cn(
                    'flex align-center invert-ml-16 py-16 text-left',
                    styles.paymentFormModal__stepButton,
                    {
                      [styles.paymentFormModal__stepButtonActive]: currentStep === steps[stepKey],
                      [styles.paymentFormModal__stepButtonDone]: isStepDone,
                    }
                  )}
                >
                  <span
                    className={cn(
                      styles.paymentFormModal__stepButton_idx,
                      'mx-16 flex flex-component-center text-xs text-weight-600'
                    )}
                  >
                    {
                      isStepDone
                        ? (
                          <FontAwesomeIcon icon={faCheck} />
                        )
                        : idx + 1
                    }
                  </span>
                  <span className="text-md text-weight-600 letter-xxs">
                    {steps[stepKey]}
                  </span>
                </div>
              );
            })
          }
        </Col>
        <Col span="16" className="pl-md">
          <div
            className={cn(
              styles.paymentFormModal__paymentDetailsList,
              {
                hide: currentStep !== steps.viewPaymentDetails,
              }
            )}
          >
            {
              paymentDetails.map(({ title, price }) => (
                <div
                  key={title}
                  className={cn(
                    styles.paymentFormModal__paymentDetailsListItem,
                    'flex space-between align-center text-md text-weight-600 letter-xxs py-sm px-sm'
                  )}
                >
                  <span className={styles.paymentFormModal__paymentDetailsItemTitle}>
                    {title}
                  </span>
                  <span className={styles.paymentFormModal__paymentDetailsItemPrice}>
                    {price}
                  </span>
                </div>
              ))
            }
          </div>
          {
            currentStep === steps.payment && (
              <div className={styles.paymentFormModal__scroller}>
                <p className="text-md text-black-opacity-0-60 letter-sm mt-zero mb-md">
                  {
                    paymentMethods.length === 0
                      ? 'Enter credit card details'
                      : 'Select Card'
                  }
                </p>
                <div className="flex flex-wrap">
                  {paymentMethods.map(method => (
                    <label
                      key={method.pm_id}
                      className={cn(
                        styles.paymentFormModal__savedCard,
                        'px-sm py-xs mr-16 mb-md flex align-center',
                        {
                          'pointer': !isPaymentInProgress,
                        }
                      )}
                    >
                      <input
                        type="radio"
                        value={method.pm_id}
                        id={'payment_method_' + method.pm_id}
                        onChange={() => setSelectedPaymentMethod(method.pm_id)}
                        name="payment_methods_radios"
                        checked={selectedPaymentMethod === method.pm_id}
                        className="pointer mr-xs"
                        disabled={isPaymentInProgress}
                      />
                      <span className="text-md text-black-opacity-0-60 letter-sm">
                        {method.card.last4}
                      </span>
                      <CardIcon brandName={method.card.brand} className="ml-xs" />
                    </label>
                  ))}
                </div>
                {
                  paymentMethods.length !== 0 && (
                    <label
                      className={cn(
                        'flex align-center mb-32 mt-12',
                        {
                          'pointer': !isPaymentInProgress,
                        }
                      )}
                    >
                      <input
                        type="radio"
                        value="new"
                        id="payment_method_new"
                        onChange={() => setSelectedPaymentMethod('new')}
                        name="payment_methods_radios"
                        className="pointer mr-xs"
                        checked={selectedPaymentMethod === 'new'}
                        disabled={isPaymentInProgress}
                      />
                      <div className="text-md text-black-opacity-0-60 letter-sm">
                        Add new card
                      </div>
                    </label>
                  )
                }
                <PaymentElement
                  onFocus={() => !isPaymentInProgress && setSelectedPaymentMethod('new')}
                  options={{
                    readOnly: isPaymentInProgress,
                  }}
                />
                {
                  (selectedPaymentMethod === 'new' || paymentMethods.length === 0) && (
                    <label
                      htmlFor="payment-modal-save-card"
                      className="flex align-center mt-sm pointer"
                    >
                      <input
                        id="payment-modal-save-card"
                        onClick={() => setIsSaveCard(currentIsSaveCard => !currentIsSaveCard)}
                        checked={isSaveCard}
                        type="checkbox"
                        className="pointer"
                      />
                      <span className="text-black-opacity-0-60 text-md letter-sm ml-sm">
                        Save card for future use
                      </span>
                    </label>
                  )
                }
              </div>
            )
          }
        </Col>
      </Row>
      <div className="flex align-center just-end mt-28">
        <Button
          onClick={() => setCurrentStep(steps.viewPaymentDetails)}
          isText
          className={cn(
            'text-lochinvar',
            {
              hide: currentStep !== steps.payment,
            }
          )}
          disabled={isPaymentInProgress}
        >
          Back
        </Button>
        <Button
          onClick={() => setCurrentStep(steps.payment)}
          className={cn(
            {
              hide: currentStep === steps.payment,
            }
          )}
          disabled={isPaymentInProgress}
        >
          Next
        </Button>
        <Button
          loading={isPaymentInProgress}
          onClick={handlePaymentProcessFinished}
          className={cn(
            'ml-32',
            {
              hide: currentStep !== steps.payment,
            }
          )}
          disabled={isPaymentInProgress}
        >
          Pay {fullPriceFormat(paymentItem.total, {
            currency: paymentItem.currency,
            minimumFractionDigits: 2,
          })}
        </Button>
      </div>
    </Modal>
  );

  async function handlePaymentProcessFinished() {
    if (!stripe || !elements) {
      return;
    }

    if (selectedPaymentMethod === 'new') {
      //Return url
      const returnUrl = `${location.origin}${location.pathname}?${qs.stringify({
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        payment_id: paymentItem.uuid,
        save_card: isSaveCard,
      })}`;

      setIsPaymentInProgress(true);

      const { error } = await stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      setIsPaymentInProgress(false);

      if (error.type === 'card_error' || error.type === 'validation_error') {
        errorReactToastify(error.message);
      }
    }
    else {
      setIsPaymentInProgress(true);

      const response = await StripePaymentService.capturePaymentWithExistingPaymentMethod(
        projectUUID,
        paymentItem.uuid,
        selectedPaymentMethod
      );

      setIsPaymentInProgress(false);

      onCancel();

      onSuccessPayment();

      if (response?.message) {
        successReactToastify(response.message);
      }
    }
  }

  async function loadPaymentMethods() {
    const { payment_methods } = await StripePaymentService.getUserPaymentMethods();

    setPaymentMethods(payment_methods);

    if (payment_methods.length) {
      setSelectedPaymentMethod(payment_methods[0].pm_id);
    }
  }
}

PaymentFormModal.propTypes = {
  projectUUID: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
  paymentItem: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccessPayment: PropTypes.func.isRequired,
};
