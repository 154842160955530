import PropTypes from 'prop-types';

import amexIcon from 'assets/card_icons/amex.svg';
import dinersIcon from 'assets/card_icons/diners.svg';
import discoverIcon from 'assets/card_icons/discover.svg';
import eloIcon from 'assets/card_icons/elo.svg';
import jcbIcon from 'assets/card_icons/jcb.svg';
import mastercardIcon from 'assets/card_icons/mastercard.svg';
import unionpayIcon from 'assets/card_icons/unionpay.svg';
import visaIcon from 'assets/card_icons/visa.svg';

export function CardIcon({ brandName, ...props }) {
  const brandsIcons = {
    amex: amexIcon,
    diners: dinersIcon,
    discover: discoverIcon,
    elo: eloIcon,
    jcb: jcbIcon,
    mastercard: mastercardIcon,
    unionpay: unionpayIcon,
    visa: visaIcon,
  };

  return brandsIcons[brandName]
    ? <img src={brandsIcons[brandName]} {...props} />
    : null;
}

CardIcon.propTypes = {
  brandName: PropTypes.string,
};
