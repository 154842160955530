import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';

import { Button } from 'Components/Button';
import { Milestone } from 'Components/Milestone';
import StepWrapper from 'Components/StepWrapper';

import ColorPaletteService from 'services/colorPalette';

import { NOTIFICATION_TYPES } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';

import { ColorPaletteCard } from './components/ColorPaletteCard';

import styles from './styles.module.scss';

export const ColorPaletteMilestone = ({ isReadonly }) => {
  const { milestoneId } = useParams();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [colorPaletteList, setColorPaletteList] = useState([]);
  const colorPaletteCount = useRef(0);

  const selectedProject = useSelector(state => state.selectedProject);
  const colorPaletteMilestone = useSelector(state => state.milestonesStore?.dictionaryId?.[milestoneId]);

  useEffect(() => {
    if (selectedProject?.uuid) {
      setIsLoading(true);
      ColorPaletteService.getColorPalettes(selectedProject?.uuid, milestoneId)
        .then(resp => {
          setColorPaletteList(resp);
          colorPaletteCount.current = resp.length;
          setIsLoading(false);
          form.resetFields();
        });
    }
  }, [selectedProject?.uuid, milestoneId]);

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          ColorPaletteService.getColorPalettes(selectedProject?.uuid, milestoneId)
            .then(resp => {
              setColorPaletteList(resp);
              colorPaletteCount.current = resp.length;
              form.resetFields();
            });
        }
      },
      [
        NOTIFICATION_TYPES.CREATE_PALETTE,
        NOTIFICATION_TYPES.EDIT_PALETTE,
        NOTIFICATION_TYPES.DELETE_PALETTE,
      ]
    ),
    [selectedProject?.uuid, milestoneId]
  );

  const editColorPalette = useDebouncedCallback(
    async (colorPaletteUUID, caption, color, index) => {
      form.setFieldValue(['colorPalette', index], {
        uuid: colorPaletteUUID,
        caption,
        color,
      });
      await form.validateFields();
      await ColorPaletteService.editColorPalette(
        colorPaletteUUID,
        selectedProject?.uuid,
        milestoneId,
        {
          caption,
          color,
        }
      );
      setColorPaletteList(currentColorPaletteList => {
        currentColorPaletteList[index] = {
          ...currentColorPaletteList[index],
          color,
          caption,
        };

        return currentColorPaletteList;
      });
    },
    500
  );

  return (
    <Milestone
      title={colorPaletteMilestone?.name}
      selectedMilestone={colorPaletteMilestone}

      withShareButton={!isReadonly}
    >
      <StepWrapper>
        <div className="flex space-between pt-40 px-56">
          <div>
            <h1 className={styles.milestone__title}>
              What are your color preferences?
            </h1>
            <p>
              What are your color preferences?
              Create a color palette and hover on the box to add colors and label with a caption.
            </p>
          </div>
          {
            !isReadonly && (
              <Button
                onClick={addColorPalette}
              >
                ADD PALETTE
              </Button>
            )
          }
        </div>
        {
          isLoading
            ? (
              <div className="flex flex-component-center px-lg py-lg">
                <Spin size="large" />
              </div>
            )
            : (
              <div className="mt-52 px-68" style={{ position: 'relative' }}>
                <Form
                  className={cn('flex flex-wrap', styles.colorPalette__listWrapper)}
                  initialValues={{ colorPalette: colorPaletteList }}
                  form={form}
                >
                  <Form.List name="colorPalette">
                    {fields => (
                      <>
                        {fields.map(field => (
                          <ColorPaletteCard
                            key={field.key}
                            index={field.key}
                            onDelete={deleteColorPalette}
                            onEdit={editColorPalette}
                            form={form}
                            isReadonly={isReadonly}
                          />
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form>
              </div>
            )
        }
      </StepWrapper>
    </Milestone>
  );

  async function addColorPalette() {
    try {
      const newColorPalette = await ColorPaletteService.addColorPalette(selectedProject?.uuid, milestoneId, {
        'caption': `Primary color ${++colorPaletteCount.current}`,
        'color': '#FFFFFF',
      });

      setColorPaletteList([...form.getFieldsValue().colorPalette, newColorPalette]);
    }
    catch (error) {
      colorPaletteCount.current--;

      throw new Error(error);
    }
    finally {
      form.resetFields();
    }
  }

  async function deleteColorPalette(colorPaletteUUID) {
    await ColorPaletteService.deleteColorPalette(colorPaletteUUID, selectedProject?.uuid, milestoneId);
    colorPaletteCount.current--;
    setColorPaletteList(
      colorPaletteList
        .filter(colorPalette => colorPalette.uuid !== colorPaletteUUID)
    );
    form.resetFields();
  }
};

ColorPaletteMilestone.propTypes = {
  isReadonly: PropTypes.bool,
};
