import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import cn from 'classnames';

import designProLogo from 'assets/designProLogo.svg';
import designProTextIcon from 'assets/designProTextIcon.svg';

import styles from './styles.module.scss';

const ForgotPassword = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  return (
    <div className={styles['parent']}>
      <div className={styles['container']}>
        <div className={styles['logo-container']}>
          <img width={26} height={26} src={designProLogo} alt="design pro logo" />
          <img className={styles['text-icon']} src={designProTextIcon} alt="design pro text icon" />
        </div>

        <form onSubmit={handleSubmit}>
          <p>
            Enter your email and we'll send you a link to reset your password.
          </p>
          <input
            type="email"
            className={styles['email-input']}
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
            required
          />
          {message && (
            <p className={styles['message']}>
              {message}
            </p>
          )}
          {error && (
            <p className={styles['error']}>
              {error}
            </p>
          )}
          <button
            type="submit"
            className={cn(
              styles['submit-button'],
              { [styles['disabled']]: sendingEmail }
            )}
            disabled={sendingEmail}
          >
            Send password reset email
          </button>
          <button type="button" className={styles['back-button']} onClick={goBack}>
            Back to log in
          </button>
        </form>
      </div>
    </div>
  );

  function goBack() {
    navigate(-1);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setMessage('');
    setSendingEmail(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent!');
    }
    catch (err) {
      setError('Failed to send password reset email.');
    }
    setSendingEmail(false);
  }
};

export default ForgotPassword;
