import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import aiIcon from 'assets/ai_icon.svg';
import searchIcon from 'assets/search_icon.svg';

import { Button } from 'Components/Button';

// eslint-disable-next-line max-len
import { AssigneeBar } from 'Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/components/TaskDetails/components/AssigneeBar';
// eslint-disable-next-line max-len
import { CollaboratorEmail } from 'Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/components/TaskDetails/components/CollaboratorEmail';

import { INVITE_STATUSES } from 'utlis/constants';

import styles from './styles.module.scss';

export function AssigneeDropdown({
  assignees,
  setAssignees,
  mappedAiAssignees,
  setMappedAiAssignees,
  allMappedAiUsers,
  projectFigmaCollaborators,
  collaboratorsOfProject,
  membersOfCurrentFile,
  isFigmaTask,
}) {
  const userDetails = useSelector(state => state.userStore);
  const selectedProject = useSelector(state => state.selectedProject);

  const emailsOfInvitedCollaborators = useMemo(() => selectedProject?.invites?.filter(
    val => val.status === INVITE_STATUSES.pending
  ).map(val => val.invitee_email), [selectedProject]);

  const [inputValue, setInputValue] = useState('');
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  // if no figma files are added then we get collaborators from project store
  const [collaborators, setCollaborators] = useState(
    projectFigmaCollaborators?.project_members
      || collaboratorsOfProject
      || []
  );
  const [invited, setInvited] = useState(
    projectFigmaCollaborators?.pending_members
      || emailsOfInvitedCollaborators
      || []
  );
  const [figmaUsers, setFigmaUsers] = useState(membersOfCurrentFile || []);

  useEffect(
    () => {
      const searchVal = inputValue.toLowerCase();

      if (Object.keys(projectFigmaCollaborators).length !== 0) {
        setCollaborators(
          projectFigmaCollaborators?.project_members.filter(
            member => (userMatchesSearch(member))
          )
        );

        setInvited(
          projectFigmaCollaborators?.pending_members.filter(
            email => email.toLowerCase().includes(searchVal)
          )
        );

        setFigmaUsers(
          membersOfCurrentFile?.filter(member => userMatchesSearch(member))
        );
      }
      else if (collaboratorsOfProject.length || emailsOfInvitedCollaborators.length) {
        setCollaborators(
          collaboratorsOfProject.filter(
            member => (userMatchesSearch(member))
          )
        );

        setInvited(
          emailsOfInvitedCollaborators.filter(
            email => email.toLowerCase().includes(searchVal)
          )
        );
      }
      const data = allMappedAiUsers();

      setMappedAiAssignees(data.filter(val => (userMatchesSearch(val))));
    },
    [
      inputValue,
      selectedProject?.collaborators,
      projectFigmaCollaborators,
      membersOfCurrentFile,
      collaboratorsOfProject,
      emailsOfInvitedCollaborators,
    ]
  );

  useEffect(
    () => {
      setCollaborators(
        projectFigmaCollaborators?.project_members
          || collaboratorsOfProject
          || []
      );
      setInvited(
        projectFigmaCollaborators?.pending_members
          || emailsOfInvitedCollaborators
          || []
      );
      setFigmaUsers(membersOfCurrentFile || []);
    },
    [
      projectFigmaCollaborators,
      selectedProject?.collaborators,
      membersOfCurrentFile,
      collaboratorsOfProject,
      emailsOfInvitedCollaborators,
    ]
  );

  return (
    <div className={styles['assignee-dropdown-container']}>
      <div className={styles['input-container']}>
        <div className={styles['search-bar-container']}>
          <img src={searchIcon} alt="Search Icon" />
          <input
            className="search-bar"
            placeholder="Search"
            value={inputValue}
            onChange={handleInputChange}
            type="text"
          />
        </div>
      </div>
      <div className={styles['assignees-container']}>
        {/* Recommended by AI */}
        {
          mappedAiAssignees.length !== 0
            && (
              <>
                <p className={styles['secondary-heading']}>
                  <img src={aiIcon} alt="ai icon" />
                  Recommended by AI
                </p>
                {mappedAiAssignees.map(({ full_name, email, image, isOwner }) => (
                  <AssigneeBar
                    key={email || full_name}
                    name={full_name}
                    email={email}
                    image={image}
                    handleUserClick={email && handleUserClick}
                    isSelected={isSelected}
                    isOwner={isOwner}
                  />
                ))}
              </>
            )
        }
        {/* Invited Teammates */}
        <span className={styles['category-title']}>
          Invited teammates
        </span>
        {
          userMatchesSearch(userDetails) && !isUserAiAssignee(userDetails.full_name, userDetails.email) && (
            <AssigneeBar
              name={userDetails.full_name}
              email={userDetails.email}
              image={userDetails.profile_photo}
              handleUserClick={handleUserClick}
              isSelected={isSelected}
              isOwner={selectedProject?.owner?.email === userDetails.email}
            />
          )
        }
        {
            selectedProject?.owner?.email !== userDetails.email
              && !isUserAiAssignee(selectedProject?.owner?.full_name, selectedProject?.owner?.email)
              && (
                <AssigneeBar
                  name={selectedProject?.owner?.full_name}
                  email={selectedProject?.owner?.email}
                  image={selectedProject?.owner?.profile_photo}
                  handleUserClick={handleUserClick}
                  isSelected={isSelected}
                  isOwner
                />
              )
        }
        {
          collaborators && collaborators.length !== 0
            && collaborators.map(({ full_name, profile_photo, email }) => (
              email !== userDetails.email
                && !isUserAiAssignee(full_name, email)
                && (
                  <AssigneeBar
                    key={email}
                    name={full_name}
                    email={email}
                    image={profile_photo}
                    handleUserClick={handleUserClick}
                    isSelected={isSelected}
                  />
                )
            ))
        }
        {
          invited && invited.length !== 0
            && invited.map(email => (
              <AssigneeBar
                key={email}
                name={''}
                email={email}
                image={''}
                handleUserClick={handleUserClick}
                isSelected={isSelected}
              />
            ))
        }
        {
          showNoResultMessage() && (
            <p className={styles['no-result']}>
              No invited member matches your search
            </p>
          )
        }
        {/* Figma File Commenters */}
        {
          isFigmaTask
            && (
              <p className={styles['secondary-heading']}>
                Figma file commenters
              </p>
            )
        }
        {
          isFigmaTask && (figmaUsers && figmaUsers.length !== 0
            ? figmaUsers.map(({ full_name, img_url, handle }) => (
              !isUserAiAssignee(full_name || handle, '') && (
                <AssigneeBar
                  key={full_name || handle}
                  name={full_name || handle}
                  email={''}
                  image={img_url}
                  handleUserClick={handleUserClick}
                  isSelected={isSelected}
                />
              )
            ))
            : (
                membersOfCurrentFile?.length !== 0 && (
                <p className={styles['no-result']}>
                  No figma commenter matches your search
                </p>
              )
            )
          )
        }
      </div>
      {
        showInviteUserModal
          ? (
            <div className={styles['invite-box']}>
              <div className={styles['invite-top']}>
                <span className={styles['heading']}>
                  Invite teammate
                </span>
                <FontAwesomeIcon
                  className={styles['close-icon']}
                  icon={faXmark}
                  onClick={() => setShowInviteUserModal(false)}
                />
              </div>
              <CollaboratorEmail fetchFigmaCollaborators />
            </div>
          )
          : (
            <div className={styles['dropdown-footer']}>
              <Button
                isText
                className={styles['invite-team']}
                onClick={() => setShowInviteUserModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Invite teammates via email
              </Button>
            </div>
          )
      }
    </div>
  );

  function showNoResultMessage() {
    return !userMatchesSearch(userDetails)
      && !invited.length
      && !collaborators.length;
  }

  function userMatchesSearch(user) {
    const searchVal = inputValue.toLowerCase();

    return user.full_name?.toLowerCase().includes(searchVal)
      || user.email?.toLowerCase().includes(searchVal)
      || user.handle?.toLowerCase().includes(searchVal);
  }

  function checkIfExists(val, name, email) {
    return (val.email && val.email === email)
      || (!val.email && val.full_name && val.full_name === name)
      || name === val
      || name.split(' ').includes(val) ;
  }

  function isSelected(name, email) {
    return assignees.find(
      val => checkIfExists(val, name, email)
    );
  }

  function isUserAiAssignee(name, email) {
    return !!mappedAiAssignees.find(val => checkIfExists(val, name, email));
  }

  function handleUserClick(details) {
    if (isSelected(details.full_name, details.email)) {
      const updated = assignees.filter(
        val => !checkIfExists(val, details.full_name, details.email)
      );

      setAssignees([...updated]);
    }
    else {
      setAssignees(prev => [...prev, details]);
    }
  }

  function handleInputChange({ target }) {
    const { value } = target;

    setInputValue(value);
  }
}

AssigneeDropdown.propTypes = {
  assignees: PropTypes.object.isRequired,
  setAssignees: PropTypes.func.isRequired,
  mappedAiAssignees: PropTypes.array.isRequired,
  setMappedAiAssignees: PropTypes.func.isRequired,
  allMappedAiUsers: PropTypes.func.isRequired,
  projectFigmaCollaborators: PropTypes.object.isRequired,
  collaboratorsOfProject: PropTypes.array.isRequired,
  membersOfCurrentFile: PropTypes.array.isRequired,
  isFigmaTask: PropTypes.bool.isRequired,
};
