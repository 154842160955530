import { Form, Tag } from 'antd';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import cn from 'classnames';

import { isColorHex } from 'utlis/validators';

import globalQuestionnaireComponentsStyles from 'Components/questionnaireComponents/styles.module.scss';

import styles from './styles.module.scss';

export default function Field({ className, required, rules = [], ...props }) {
  return (
    <Form.Item
      className={cn(globalQuestionnaireComponentsStyles['form-item'], styles['field'], 'm-zero', className)}
      {...props}
      rules={[
        { required: required },
        ...rules,
      ]}
    >
      <CustomInput
        {...props}
      />
    </Form.Item>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  rules: PropTypes.array,
  required: PropTypes.bool,
};

function CustomInput({
  value,
  initialValue,
  placeholder,
  disabled,
  prefix,
  onChange,
  onKeyUp,
  onKeyPress,
  type,
  tagMaxLength,
  ...props
}) {
  const currentValue = value || initialValue;
  const isTagInput = ['color-tags', 'tags'].includes(type);
  const isColorTagInput = ['color-tags'].includes(type);
  const tags = isTagInput
    ? currentValue?.match(/[^,]+,/g)?.map(el => el.replace(',', '')) || []
    : null;

  return (
    <>
      {
        isTagInput && tags.filter(Boolean).map(tag => (
          <Tag
            key={tag}
            closable
            onClose={() => onChange(currentValue.replace(`${tag},`, ''))}
            className={styles['tag']}
          >
            {
              isColorTagInput && isColorHex(tag) && (
                <span className={styles['color-tag']} style={{ background: tag }} />
              )
            }
            {tag}
          </Tag>
        ))
      }
      <span className={styles['input-wrapper']}>
        <span
          role="textbox"
          contentEditable={!disabled}
          className={cn(globalQuestionnaireComponentsStyles['input-text'], styles['input'])}
          onBlur={({ target: { innerText } }) => onChange(
            isTagInput
              ? `${tags.join(',')}${tags.length ? ',' : ''}${innerText}`
              : innerText
          )}
          disabled={disabled}
          data-placeholder={placeholder}
          data-prefix={prefix}
          suppressContentEditableWarning
          onKeyUp={event => {
            const { nativeEvent, target } = event;

            if (nativeEvent.key == ',' && isTagInput) {
              onChange(`${tags.join(',')}${tags.length ? ',' : ''}${target.innerText}`);

              target.innerText = '';
            }
            if (onKeyUp) {
              onKeyUp(event);
            }
          }}
          onKeyPress={event => {
            const { nativeEvent, target } = event;

            if (!/[\d,.]/.test(nativeEvent.key) && type == 'number') {
              nativeEvent.preventDefault();
              nativeEvent.stopPropagation();
            }
            if (nativeEvent.key == 'Enter') {
              nativeEvent.preventDefault();
              nativeEvent.stopPropagation();
            }
            if (
              nativeEvent.key == ','
                && isColorTagInput
                && !(isColorHex(target.innerText) || isColorHex(`#${target.innerText}`))
            ) {
              nativeEvent.preventDefault();
              nativeEvent.stopPropagation();
            }
            if (
              tagMaxLength
                && isTagInput
                && target.innerText.length >= tagMaxLength
                && nativeEvent.key != ','
            ) {
              nativeEvent.preventDefault();
              nativeEvent.stopPropagation();
            }
            if (onKeyPress) {
              onKeyPress(event);
            }
          }}
          {...props}
        >
          {
            isTagInput
              ? currentValue?.split(',')?.at(-1)
              : currentValue
          }
        </span>
        {
          isColorTagInput && (
            <SketchPicker
              className={styles['sketch-picker']}
              color={{ hex: currentValue?.split(',')?.at(-1) }}
              onChange={({ hex }) => requestAnimationFrame(() => onChange(
                `${tags.join(',')}${tags.length ? ',' : ''}${hex}`
              ))}
              presetColors={[]}
              width={300}
              disableAlpha
            />
          )
        }
      </span>
    </>
  );
}

CustomInput.propTypes = {
  value: PropTypes.any,
  initialValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  type: PropTypes.string,
  tagMaxLength: PropTypes.number,
};
