import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ReactComponent as ShareIcon } from 'assets/ShareIcon.svg';

import { Button } from 'Components/Button';
import { MilestoneIcon } from 'Components/Milestone/components/MilestoneIcon';

import { WorkspaceToolbar } from 'Pages/ProjectDetails/sub-routes/Workspace/components/WorkspaceToolbar';

import { isUserInReadOnlyMode } from 'utlis/common';
import { MILESTONES_TYPES } from 'utlis/constants';
import copyValue from 'utlis/copy_value';
import { infoReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export const Milestone = ({
  title,
  selectedMilestone,

  icon,
  children,
  className,
  /* additionalRightElement, */
  withShareButton,
  withTopics,
  hideWorkspaceToolbar,
}) => {
  const { showAllComments, showNotes, showExperts, showTopics } = useSelector(state => state.app);

  const isReadOnly = isUserInReadOnlyMode();

  const canUserSendPinComments = useMemo(
    () => [
      MILESTONES_TYPES.designConcepts,
      MILESTONES_TYPES.presentation,
      MILESTONES_TYPES.deliverFiles,
      MILESTONES_TYPES.userInterview,
      MILESTONES_TYPES.designReview,
      MILESTONES_TYPES.sketches,
    ]
      .includes(selectedMilestone?.type),
    [selectedMilestone?.type]
  );

  return (
    <div className={cn(styles['milestone-wrapper'], className)}>
      <div className={cn(styles['header-wrapper'], 'flex align-center py-16 pl-20 pr-40 space-between')}>
        <div className="flex align-center">
          {
            icon
              ? (
                <img
                  src={icon}
                  width={40}
                />
              )
              : (
                <MilestoneIcon
                  className="mr-16"
                  type={selectedMilestone?.type}
                />
              )
          }
          <p
            title={title}
            className={cn(
              styles['milestone-title'],
              'text-black text-weight-600 my-zero'
            )}
          >
            {title}
          </p>
        </div>
        <div className="flex align-center space-between">
          {/* {additionalRightElement} For now we don't need additional right element for milestons */}
          {
            withShareButton && (
              <Button
                isText
                shape="circle"
                className={cn(styles['share-button'], 'ml-sm-md-important flex flex-component-center')}
                icon={<ShareIcon width={12} height={13} />}
                onClick={() => {
                  copyValue(location.href)
                    .then(() => {
                      infoReactToastify('Link to this page has been copied');
                    });
                }}
              />
            )
          }
        </div>
      </div>
      <div
        className={cn(
          styles['workspace-content-wrapper'],
          {
            [styles['show-embedded-panel']]: showAllComments || showNotes || showExperts || showTopics,
          }
        )}
      >
        <div
          className={cn(
            styles['workspace-content'],
            'px-8 py-8',
            {
              'px-32 py-32': selectedMilestone?.type === MILESTONES_TYPES.moodboard,
            }
          )}
        >
          {children}
        </div>
        {
          !hideWorkspaceToolbar && (
            <div className={cn(styles['workspace-toolbar-wrapper'], 'flex just-end')}>
              <WorkspaceToolbar
                withComments
                withTopics={!isReadOnly && withTopics}
                withNotes={!isReadOnly}
                withExperts={!isReadOnly}
                canUserSendPinComments={canUserSendPinComments}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

Milestone.propTypes = {
  title: PropTypes.string.isRequired,
  selectedMilestone: PropTypes.object.isRequired,

  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  /* additionalRightElement: PropTypes.node, */
  withShareButton: PropTypes.bool,
  withTopics: PropTypes.bool,
  hideWorkspaceToolbar: PropTypes.bool,
};
