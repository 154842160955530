import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import atlassianIcon from 'assets/atlassian_icon.svg';
import designProLogo from 'assets/designProLogo.svg';
import plusIcon from 'assets/plus_icon.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { initializeProjects } from 'Redux/Actions/projectActions';

import { trackEvent } from 'services/eventTracking';
import JiraService from 'services/jira';

import { PROJECT_STATUS } from 'utlis/constants';

import styles from './styles.module.scss';

export const ConnectToJira = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      trackEvent('[page] connect to jira', { 'page name': 'connect to jira' });

      dispatch(initializeProjects(PROJECT_STATUS.active))
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  localStorage.setItem('lastPage', 'connectToJira');

  const icons = (
    <>
      <img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />
      <img src={plusIcon} alt="plus icon" className={styles['small-icon']} />
      <img src={atlassianIcon} alt="Figma Logo" className={styles['icon']} />
    </>
  );

  return (
    <AuthModel
      icons={icons}
      title="Connect to Atlassian account"
      description="Connect DesignPro with your Atlassian account. "
      button={{
        onClick: () => handleConnectJiraAccount(),
        text: 'CONNECT TO JIRA',
        loading: isLoading,
        disabled: isLoading,
      }}
    />
  );

  async function handleConnectJiraAccount() {
    await JiraService.connectJiraAccount()
      .then(
        connectJiraAccountURL => {
          window.open(connectJiraAccountURL, '_blank', 'noopener,noreferrer');
        }
      );
  }
};
