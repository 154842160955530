import actionTypes from 'Redux/constants';

export function setAppState(appState) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setAppState, payload: appState });
  };
}

export function setShowAllComments(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setShowAllComments, payload: value });
  };
}

export function setIsPinCommentMode(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setIsPinCommentMode, payload: value });
  };
}

export function setHidePinCommentMode(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setHidePinCommentMode, payload: value });
  };
}

export function setIsAnnotationMode(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setIsAnnotationMode, payload: value });
  };
}

export function setShowNotes(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setShowNotes, payload: value });
  };
}

export function setShowExperts(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setShowExperts, payload: value });
  };
}

export function setShowTopics(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setShowTopics, payload: value });
  };
}

export function setIsReadonly(value) {
  return dispatch => {
    dispatch({ type: actionTypes.app.setShowExperts, payload: value });
  };
}

export function showMilestoneProgressModal({ milestone, notificationType }) {
  return dispatch => {
    dispatch({
      type: actionTypes.app.showMilestoneProgressModal,
      payload: {
        /* Visibility for the milestone progress modal set to false for now.
        Setting the 'visible' property to true will enable the popup modal
        on completion of every milestone. */
        visible: false,
        milestone,
        notificationType,
      },
    });
  };
}

export function hideMilestoneProgressModal() {
  return dispatch => {
    dispatch({
      type: actionTypes.app.hideMilestoneProgressModal,
      payload: {
        visible: false,
      },
    });
  };
}
