import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import designProLogo from 'assets/designProLogo.svg';
import plusIcon from 'assets/plus_icon.svg';
import slackLogo from 'assets/slack_logo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { initializeProjects } from 'Redux/Actions/projectActions';

import { trackEvent } from 'services/eventTracking';
import SlackService from 'services/slack';

import { PROJECT_STATUS } from 'utlis/constants';

import styles from './styles.module.scss';

export const ConnectToSlack = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      trackEvent('[page] connect to slack', { 'page name': 'connect to slack' });

      dispatch(initializeProjects(PROJECT_STATUS.active))
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  localStorage.setItem('lastPage', 'connectToJira');

  const icons = (
    <>
      <img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />
      <img src={plusIcon} alt="plus icon" className={styles['small-icon']} />
      <img src={slackLogo} alt="Slack Logo" className={styles['icon']} />
    </>
  );

  return (
    <AuthModel
      icons={icons}
      title="Connect to Slack workspace"
      description="Connect DesignPro with your Slack workspace."
      button={{
        onClick: () => hanldeConnectSlackAccount(),
        text: 'CONNECT TO SLACK WORKSPACE',
        loading: isLoading,
        disabled: isLoading,
      }}
    />
  );

  async function hanldeConnectSlackAccount() {
    await SlackService.connectSlackAccount()
      .then(
        connectSlackAccountURL => {
          window.open(connectSlackAccountURL, '_blank', 'noopener,noreferrer');
        }
      );
  }
};
