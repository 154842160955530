import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SelectedImageModal from 'Components/SelectedImageModal/SelectedImageModal';

import downloadFiles from 'utlis/download_files';
import { errorReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export default function DownloadReferenceImages({ images }) {
  const [isOpen, setIsOpen] = useState(false);
  const [imageCounter, setImageCounter] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleImageClick = key => {
    setIsOpen(prevState => !prevState);
    setImageCounter(key);
  };

  const handleDownloadFiles = async () => {
    try {
      setIsDownloading(true);

      await downloadFiles(images, 'reference_images.zip');
    }
    catch {
      errorReactToastify('There were some problems when trying to download this image');
    }
    finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className={styles.downloadSection}>
      <div className={styles.downloadSection__header}>
        <span>Download reference images from client</span>
        <button
          onClick={() => handleDownloadFiles()}
          className={styles.downloadSection__downloadButton}
          disabled={isDownloading}
        >
          {
            isDownloading
              ? 'Downloading ...'
              : 'Download Images'
          }
        </button>
      </div>

      {/* Map all Images from Discovery */}
      <div className={styles.downloadSection__imagesContainer}>
        {images?.map((url, key) => (
          <button key={url} onClick={() => handleImageClick(key)}>
            <img src={url} alt="DesignImage" />
          </button>
        ))}
      </div>
      {/* Image Modal Conponent */}
      {isOpen && (
        <SelectedImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          images={images}
          imageCounter={imageCounter}
          setImageCounter={setImageCounter}
        />
      )}
    </div>
  );
}

DownloadReferenceImages.propTypes = {
  images: PropTypes.array,
};
