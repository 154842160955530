import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import cn from 'classnames';

import { fullPriceFormat, fullNumberFormat } from 'utlis/string_format';
import { isCorrectRange } from 'utlis/validators';

import { Button } from 'Components/Button';

import styles from './styles.module.scss';

export function RequestPaymentForm({
  currentUser,

  onSubmit,

  ...props
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      preserve
      onFinish={onFinish}

      {...props}
    >
      <p className="m-zero text-weight-500 text-20 letter-xs text-black-opacity-0-60">
        Request Payment
      </p>
      <div className="flex align-center mt-md-lg mb-lg">
        <Form.Item
          name="requestedAmount"
          className={cn(styles['input-wrapper'], 'mb-zero')}
          label="Amount*"
          rules={[
            {
              validator: (rule, value) => { // eslint-disable-line no-unused-vars
                if (!value) {
                  return Promise.reject('Amount is required');
                }
                if (!isCorrectRange(Number(value), 0.5, 999999.99)) {
                  return Promise.reject(`
                    Amount should not be less than ${fullPriceFormat(0.5)}
                    and it should not be greater than ${fullPriceFormat(999999.99)}
                  `);
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            className="text-md letter-sm"
            prefix={currentUser?.designer_profile?.currencySymbol}
            placeholder="0.00"
            maxLength={10}
            min={0}
            controls={false}
            formatter={fullNumberFormat}
            parser={value => Number(value.replace(/,/g, '')) || ''}
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => (
            <Button
              loading={isSubmiting}
              className="ml-32"
              type="primary"
              htmlType="submit"
              disabled={
                isSubmiting
                  || !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              SUBMIT REQUEST
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );

  async function onFinish({ requestedAmount }) {
    try {
      setIsSubmiting(true);

      if (onSubmit) {
        await onSubmit(requestedAmount);
      }

      form.resetFields();
    }
    finally {
      setIsSubmiting(false);
    }
  }
}

RequestPaymentForm.propTypes = {
  currentUser: PropTypes.object.isRequired,

  onSubmit: PropTypes.func,
};
