import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'antd';
import cn from 'classnames';

import { ReactComponent as AnnotationIcon } from 'assets/annotation_icon.svg';
import { ReactComponent as ThumbtackIcon } from 'assets/thumbtack.svg';

import { Button } from 'Components/Button';
import { AnnotationTools } from 'Components/ImageWithComments/components/AnnotationTools';
import { ReactQuill } from 'Components/ReactQuill';

import { AUTO_LINK_EVENTS_NAMES } from 'utlis/quill_auto_links';

import styles from './styles.module.scss';

export function CommentForm({
  onSubmit,

  className,
  annotationsCanvasRef,
  textAreaClassName,
  commentSuggestion,
  messagePlaceholder,
  resetFormAfterSubmit,
  messageRules,
  withTools,
  onCancel,
  submitButtonText,
  hidePinButton,
  onClickPinButton,
  onClickAnnotationButton,
  hideAnnotationButton,
  isPinCommentMode,
  isAnnotationMode,
  inputIcon,
  mentionsList,
  fileType,

  ...props
}) {
  const [isAutoLinkLoading, setIsAutoLinkLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [isValidImages, setIsValidImages] = useState(true);

  const [form] = Form.useForm();

  const reactQuillRef = useRef();

  useEffect(
    () => {
      if (reactQuillRef?.current) {
        reactQuillRef.current.editor.on(AUTO_LINK_EVENTS_NAMES.autoLinkStart, () => {
          setIsAutoLinkLoading(currentValue => currentValue + 1);
        });
        reactQuillRef.current.editor.on(AUTO_LINK_EVENTS_NAMES.autoLinkFinish, () => {
          setIsAutoLinkLoading(currentValue => Math.max(0, currentValue - 1));
        });

        return () => {
          if (reactQuillRef.current) {
            reactQuillRef.current.editor.off(AUTO_LINK_EVENTS_NAMES.autoLinkStart);
            reactQuillRef.current.editor.off(AUTO_LINK_EVENTS_NAMES.autoLinkFinish);
          }
        };
      }
    },
    [reactQuillRef?.current]
  );

  useEffect(
    () => {
      form.setFieldsValue({
        message: commentSuggestion || '',
      });
    },
    [commentSuggestion]
  );

  return (
    <Form
      className={cn(
        styles['comment-form'],
        {
          [styles['comment-form-with-tools']]: withTools,
        },
        className
      )}
      initialValues={{ message: commentSuggestion, file_links: [] }}
      form={form}
      onFinish={onSendComment}

      {...props}
    >
      {
        inputIcon
          ? (
            <span className={styles['input-icon']}>
              {inputIcon}
            </span>
          )
          : null
      }
      <Form.Item
        name="message"
        className={cn(styles['textarea-wrapper'], 'flex-grow m-zero')}
        rules={messageRules}
      >
        <ReactQuill
          ref={reactQuillRef}
          theme="snow"
          placeholder={messagePlaceholder}
          mentionsList={mentionsList}
          className={cn(
            styles['textarea'],
            textAreaClassName,
            {
              [styles['textarea-with-border']]: !withTools,
              [styles['textarea-with-icon']]: !!inputIcon,
            }
          )}
          form={form}
          showLoom
          showImageUpload={!fileType?.gDriveId}
          setIsUploadInProgress={setIsUploadInProgress}
          setIsValidImages={setIsValidImages}
        />
      </Form.Item>
      <div
        className={cn(
          'flex',
          {
            [styles['tools-box']]: withTools,
            'py-12 px-12 space-between': withTools,
            'pt-12 just-end': !withTools,
          }
        )}
      >
        {
          withTools && (
            <div className="invert-my-12 invert-ml-12 flex align-center">
              <div
                className={cn(
                  styles['pin-wrapper'],
                  'flex-grow px-8 py-8',
                  {
                    hide: hidePinButton,
                  }
                )}
              >
                <Button
                  className={cn(
                    styles['mod-button'],
                    {
                      [styles['mod-button-is-active']]: isPinCommentMode,
                    }
                  )}
                  icon={<ThumbtackIcon />}
                  isInvert={!isPinCommentMode}
                  onClick={onClickPinButton}
                />
              </div>
              <Button
                className={cn(
                  styles['mod-button'],
                  'ml-12',
                  {
                    [styles['mod-button-is-active']]: isAnnotationMode,
                    hide: hideAnnotationButton,
                  }
                )}
                icon={<AnnotationIcon />}
                isInvert={!isAnnotationMode}
                onClick={onClickAnnotationButton}
              />
              {
                isAnnotationMode && (
                  <AnnotationTools
                    canvasRef={annotationsCanvasRef}
                    isAnnotationMode={isAnnotationMode}
                  />
                )
              }
            </div>
          )
        }
        <div className="flex">
          {
            !!onCancel && (
              <Button onClick={onCancel} isInvert className="mr-sm">
                Cancel
              </Button>
            )
          }
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  isUploadInProgress
                    || isSubmitting
                    || !!isAutoLinkLoading
                    || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    || (
                      form.getFieldValue('file_links')?.length < 1
                        && (
                          form.getFieldValue('message') === null
                            || form.getFieldValue('message')?.replace(/<(.|\n)*?>/g, '').trim().length === 0
                        )
                    )
                }
                loading={(!!isAutoLinkLoading || isUploadInProgress) && isValidImages}
              >
                {
                  (isAutoLinkLoading || isUploadInProgress) && isValidImages
                    ? 'LOADING ...'
                    : submitButtonText
                }
              </Button>
            )}
          </Form.Item>
        </div>
      </div>
    </Form>
  );

  async function onSendComment(args) {
    try {
      const files = form.getFieldValue('file_links');

      setIsSubmitting(true);

      await onSubmit({ ...args, file_links: files.map(file => file.fileUrl) });

      if (resetFormAfterSubmit) {
        form.resetFields();
      }
    }
    finally {
      setIsSubmitting(false);
      setIsUploadInProgress(false);
    }
  }
}

CommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  withTools: PropTypes.bool,
  annotationsCanvasRef: PropTypes.object,
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
  commentSuggestion: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  resetFormAfterSubmit: PropTypes.bool,
  messageRules: PropTypes.array,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.node,
  hidePinButton: PropTypes.bool,
  onClickPinButton: PropTypes.func,
  isPinCommentMode: PropTypes.bool,
  isAnnotationMode: PropTypes.bool,
  onClickAnnotationButton: PropTypes.func,
  hideAnnotationButton: PropTypes.bool,
  inputIcon: PropTypes.node,
  mentionsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  fileType: PropTypes.object,
};

CommentForm.defaultProps = {
  withTools: true,
  messageRules: [],
  resetFormAfterSubmit: true,
  submitButtonText: (
    <>
      SEND
      {' '}
      <FontAwesomeIcon icon={faArrowUp} />
    </>
  ),
};
