import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 2, 'notes');

export default class NotesService {
  static async getNotes(milestoneId) {
    return (await api.getByKey('list_notes', { projectmilestone_id: milestoneId })).data;
  }

  static saveNote(milestoneId, noteUUID, newNotes, version, config) {
    const newNoteObj = {
      id: noteUUID || undefined,
      projectmilestone_id: milestoneId || undefined,
      body: newNotes,
      version: version || undefined,
      title: '',
    };

    return (
      noteUUID
        ? api.patch('update_note', newNoteObj, config)
        : api.setUrl('notes/create_note').post(newNoteObj, config)
    )
      .then(response => response.data);
  }
}
