import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function DateFromNow({
  date,
}) {
  const [, setTime] = useState(Date.now());

  useEffect(
    () => {
      const timer = setInterval(
        () => {
          setTime(Date.now());
        },
        getIntervalPeriod()
      );

      return () => {
        clearInterval(timer);
      };
    },
    []
  );

  return moment(date).fromNow();

  function getIntervalPeriod() {
    const timeDifference = Date.now() - moment(date).valueOf();

    if (timeDifference < 60 * 60e3) {
      return 60e3;
    }
    if (timeDifference < 24 * 60 * 60e3) {
      return 60 * 60e3;
    }
    if (timeDifference < 30 * 24 * 60 * 60e3) {
      return 24 * 60 * 60e3;
    }
    if (timeDifference < 365 * 30 * 24 * 60 * 60e3) {
      return 30 * 24 * 60 * 60e3;
    }
  }
}

DateFromNow.propTypes = {
  date: PropTypes.string.isRequired,
};
