import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import cn from 'classnames';

import { ReactComponent as ArrowDropDownIcon } from 'assets/arrow_drop_down_icon.svg';
import calendarIcon from 'assets/calendar_icon.svg';
import customTaskIcon from 'assets/custom_task_icon.svg';
import figJamIcon from 'assets/FigJam_logo.svg';
import figmaLogo from 'assets/figma_logo.svg';
import filterIcon from 'assets/filter_icon.svg';
import invitedIcon from 'assets/invited_icon.svg';
import jiraIcon from 'assets/jira_icon.svg';
import notesIcon from 'assets/notes_icon.svg';
import slackIcon from 'assets/slack_logo.svg';
import tagIcon from 'assets/tag_icon.svg';
import transcriptionIcon from 'assets/transcription_icon.svg';
import defaultUserAvatar from 'assets/user_avatar.svg';

import { Button } from 'Components/Button';

import {
  KANBAN_BOARD_DATE_RANGE_OPTIONS,
  KANBAN_BOARD_FILE_TYPE_VALUES,
  KANBAN_BOARD_FILTER_CATEGORIES,
} from 'utlis/constants';

import { FilterCategory } from './components/FilterCategory';

import styles from './styles.module.scss';

export function FilterMenu({
  taskAssignees,
  isLoadingTasks,
  selectedFileKeys,
  onChangeSelectedFileNames,
  priorities,
  selectedPriorities,
  onChangeSelectedPriorities,
  selectedAssignees,
  onChangeSelectedAssignees,
  labels,
  selectedLabels,
  onChangeSelectedLabels,
  selectedDateRange,
  onChangeDateRange,
  setIsFiltered,
  handleClearFilterOptions,
}) {
  const savedFigmaFiles = useSelector(state => state.kanbanBoard?.savedFigmaFiles);
  const tasks = useSelector(state => state.kanbanBoard.allTasks);

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filteredItemsLength, setFilteredItemsLength] = useState(getSelectedItemsLength());

  const categoryItems = [
    {
      title: `Source ${savedFigmaFiles.length ? `(${savedFigmaFiles.length})` : ''}`,
      items: savedFigmaFiles,
      tasksCount: countTasksByCategory(savedFigmaFiles, KANBAN_BOARD_FILTER_CATEGORIES.SOURCE),
      handleClickFunction: (...args) => handleFilterOptionClick(...args, onChangeSelectedFileNames),
      selectedItems: selectedFileKeys,
      optionPrefix: filePrefix,
    },
    {
      title: 'Priority',
      items: priorities,
      tasksCount: countTasksByCategory(priorities, KANBAN_BOARD_FILTER_CATEGORIES.PRIORITY),
      handleClickFunction: (...args) => handleFilterOptionClick(...args, onChangeSelectedPriorities),
      selectedItems: selectedPriorities,
      optionPrefix: priorityPrefix,
    },
    {
      title: `Assignee ${taskAssignees.length ? `(${taskAssignees.length})` : ''}`,
      items: taskAssignees,
      tasksCount: countTasksByCategory(taskAssignees, KANBAN_BOARD_FILTER_CATEGORIES.ASSIGNEE),
      handleClickFunction: (...args) => handleFilterOptionClick(...args, onChangeSelectedAssignees),
      selectedItems: selectedAssignees,
      optionPrefix: assigneePrefix,
    },
    {
      title: `Label ${labels.length ? `(${labels.length})` : ''}`,
      items: labels,
      tasksCount: countTasksByCategory(labels, KANBAN_BOARD_FILTER_CATEGORIES.LABEL),
      handleClickFunction: (...args) => handleFilterOptionClick(...args, onChangeSelectedLabels),
      selectedItems: selectedLabels,
      optionPrefix: () => <img src={tagIcon} alt="tag-icon" />,
    },
    {
      title: 'Date',
      items: Object.values(KANBAN_BOARD_DATE_RANGE_OPTIONS),
      tasksCount: countTasksByCategory(getDateRangeOptions(), KANBAN_BOARD_FILTER_CATEGORIES.DATE_RANGE),
      handleClickFunction: (...args) => handleSelectDateRange(...args, onChangeDateRange),
      selectedItems: selectedDateRange,
      optionPrefix: () => <img src={calendarIcon} alt="calendar-icon" />,
    },
  ];

  const fileTypeToIcon = {
    [KANBAN_BOARD_FILE_TYPE_VALUES.FIGMA]: figmaLogo,
    [KANBAN_BOARD_FILE_TYPE_VALUES.TRANSCRIPTION]: transcriptionIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.CUSTOM]: customTaskIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.NOTES]: notesIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.SLACK]: slackIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.JIRA]: jiraIcon,
    [KANBAN_BOARD_FILE_TYPE_VALUES.FigJam]: figJamIcon,
  };

  const filterButtons = (
    <>
      <Button
        isText
        onClick={handleClearFilter}
        disabled={!filteredItemsLength}
      >
        Clear all
      </Button>
      <Button onClick={handleApplyFilter}>
        Show tasks
      </Button>
    </>
  );

  const filterOptions = (
    <div
      className={cn(
        styles['filter-options']
      )}
    >
      <div className={styles['options-title']}>
        Filter by
        <FontAwesomeIcon
          className={styles['close-icon']}
          icon={faXmark}
          onClick={() => setShowFilterOptions(false)}
        />
      </div>
      <div className={styles['categories-container']}>
        {
          categoryItems.map(
            categoryItem => (
              <FilterCategory
                key={categoryItem.title}
                {...categoryItem}
              />
            )
          )
        }
      </div>
      <div className={styles['action-buttons']}>
        {filterButtons}
      </div>
    </div>
  );

  return (
    <div className={styles['filter-button-container']}>
      <Popover
        content={filterOptions}
        placement="bottomLeft"
        trigger="click"
        visible={showFilterOptions}
        onVisibleChange={setShowFilterOptions}
        showArrow={false}
        overlayStyle={{ 'paddingTop': 0 }}
        overlayClassName={styles['filter-button-container-popover']}
      >
        <Button.White
          className={cn(
            'flex align-center mr-8',
            styles['filter-button'],
            { [styles['filter-button-active']]: filteredItemsLength && !isLoadingTasks }
          )}
          onClick={() => setShowFilterOptions(!showFilterOptions)}
        >
          <img src={filterIcon} alt="filter icon" />
          {
            filteredItemsLength && !isLoadingTasks
              ? (
                <span>
                  Filter:
                  {' '}
                  {`${filteredItemsLength} selected`}
                </span>
              )
              : (
                <span>
                  Filter
                </span>
              )
          }
          <ArrowDropDownIcon
            className={cn(
              styles['arrow-drop-down-icon'],
              {
                [styles['active-arrow-drop-down-icon']]: showFilterOptions,
                [styles['filtered-arrow-drop-down-icon']]: filteredItemsLength && !isLoadingTasks,
              }
            )}
          />
        </Button.White>
      </Popover>
    </div>
  );

  function priorityPrefix(priority) {
    return (
      <span className={styles[`${priority}-priority-circle`]} />
    );
  }

  function assigneePrefix(assignee) {
    return (
      <img
        src={assignee.profile_photo || (assignee.full_name ? defaultUserAvatar : invitedIcon)}
        alt="profile picture"
        className={styles['user-image']}
      />
    );
  }

  function filePrefix(fileType) {
    return <img className="mx-4" width={16} height={16} src={fileTypeToIcon[fileType]} alt="file-icon" />;
  }

  function handleFilterOptionClick(value, handleOnChange) {
    handleOnChange(value);
  }

  function handleSelectDateRange(value, handleOnChange) {
    handleOnChange(value);
  }

  function getSelectedItemsLength() {
    return selectedFileKeys.length
      + selectedLabels.length
      + selectedPriorities.length
      + selectedAssignees.length
      + (selectedDateRange ? 1 : 0);
  }

  function handleSetSelectItemsLength() {
    const selectItemsLength = getSelectedItemsLength();

    setFilteredItemsLength(selectItemsLength);
  }

  function handleClearFilter() {
    handleClearFilterOptions();
    setFilteredItemsLength(0);
    setShowFilterOptions(false);
  }

  function handleApplyFilter() {
    setIsFiltered(true);
    handleSetSelectItemsLength();
    setShowFilterOptions(false);
  }

  function getDateRangeOptions() {
    return Object.values(KANBAN_BOARD_DATE_RANGE_OPTIONS);
  }

  function countTasksByCategory(items, filterItem) {
    return items.reduce((itemCount, item) => {
      switch (filterItem) {
        case KANBAN_BOARD_FILTER_CATEGORIES.LABEL:
          itemCount[item] = tasks.filter(task => filterTasksByLabel(task.taskDetails[filterItem], item)).length;
          break;
        case KANBAN_BOARD_FILTER_CATEGORIES.DATE_RANGE:
          itemCount[item] = tasks.filter(task => filterTasksByDateRange(task.taskDetails[filterItem], item)).length;
          break;
        case KANBAN_BOARD_FILTER_CATEGORIES.SOURCE:
          itemCount[item.figma_file_key] = tasks.filter(
            task => task.taskDetails[filterItem] === item.figma_file_key
          ).length;
          break;
        case KANBAN_BOARD_FILTER_CATEGORIES.PRIORITY:
          itemCount[item] = tasks.filter(task => task.taskDetails[filterItem].toLowerCase() === item).length;
          break;
        case KANBAN_BOARD_FILTER_CATEGORIES.ASSIGNEE:
          itemCount[item.email] = tasks.filter(
            task => {
              if (item.email === 'none') {
                return !task.taskDetails.assignees
                  || !task.taskDetails.assignees?.length
                  || task.taskDetails.assignees?.filter(val => typeof val === 'string').length;
              }

              return task.taskDetails.assignees?.map(val => val.email).includes(item.email);
            }
          ).length;
          break;
        default:
          itemCount[item] = tasks.filter(task => task.taskDetails[filterItem] === item).length;
          break;
      }

      return itemCount;
    }, {});
  }

  function filterTasksByDateRange(sourceCreatedAt, dateRange) {
    const secondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const endDate = new Date();
    const taskCreatedAt = new Date(sourceCreatedAt?.toDate());
    let startDate;

    switch (dateRange) {
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_WEEK:
        startDate = new Date(endDate.getTime() - secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_TWO_WEEKS:
        startDate = new Date(endDate.getTime() - 2 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_THREE_WEEKS:
        startDate = new Date(endDate.getTime() - 3 * secondsPerWeek);
        break;
      case KANBAN_BOARD_DATE_RANGE_OPTIONS.LAST_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 1);
        break;
    }

    return taskCreatedAt >= startDate && taskCreatedAt <= endDate;
  }

  function filterTasksByLabel(taskLabels, label) {
    return taskLabels.includes(label);
  }
}

FilterMenu.propTypes = {
  taskAssignees: PropTypes.arrayOf(PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    profile_photo: PropTypes.string,
  })),
  isLoadingTasks: PropTypes.bool.isRequired,
  selectedFileKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeSelectedFileNames: PropTypes.func.isRequired,
  priorities: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPriorities: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedAssignees: PropTypes.array.isRequired,
  onChangeSelectedAssignees: PropTypes.func.isRequired,
  onChangeSelectedPriorities: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeSelectedLabels: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.string.isRequired,
  onChangeDateRange: PropTypes.func.isRequired,
  setIsFiltered: PropTypes.func.isRequired,
  handleClearFilterOptions: PropTypes.func.isRequired,
};
