import { Row } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';

const Navigation = ({
  currentStepIndex,
  lastStepIndex,
  goToNextStep,
  goToPrevStep,

  onSubmit,
  submitText = 'SUBMIT',
  className,
  hideSubmitButton,
  disabled,
  justify = 'end',
}) => (
  <div className={cn(className)}>
    <Row justify={justify} align="middle">
      <Button
        className={cn(
          { 'visibility-hidden': currentStepIndex == 1 },
          'mr-sm',
        )}
        isInvert
        onClick={goToPrevStep}
        type="default"
        htmlType="button"
        disabled={disabled}
      >
        BACK
      </Button>
      <div className="flex align-center just-end">
        <p className="text-md text-weight-600 mr-small-md-lg my-zero">
          {currentStepIndex}/{lastStepIndex}
        </p>
        <Button
          className={cn({
            hide: currentStepIndex == lastStepIndex && hideSubmitButton,
          })}
          onClick={currentStepIndex == lastStepIndex ? onSubmit : goToNextStep}
          type="primary"
          htmlType="button"
          disabled={disabled || (currentStepIndex == lastStepIndex ? !onSubmit : !goToNextStep)}
        >
          {currentStepIndex == lastStepIndex ? submitText : 'NEXT'}
        </Button>
      </div>
    </Row>
  </div>
);

const justifyOptions = ['start', 'end', 'center', 'space-around', 'space-between', 'space-evenly'];

Navigation.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  lastStepIndex: PropTypes.number.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  className: PropTypes.string,
  hideSubmitButton: PropTypes.bool,
  disabled: PropTypes.bool,
  justify: PropTypes.oneOf(justifyOptions),
};

export default Navigation;
