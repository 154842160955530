import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image as ImageViewer } from 'antd';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import cn from 'classnames';

import copyValue from 'utlis/copy_value';
import { successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export const TaskCard = ({
  title,
  description,
  label,
  priority,
  commentCroppedImage,
  figmaCommentUrl,
}) => {
  const [imageUrl, setImageUrl] = useState();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const getImageUrl = async () => {
      const storage = getStorage();

      const url = await getDownloadURL(ref(storage, commentCroppedImage));

      setImageUrl(url);
    };

    getImageUrl();
  }, [commentCroppedImage]);

  return (
    <div className={styles['task-card']}>
      <div className="flex space-between align-start text-left">
        <div>
          <div className={styles['task-card-title']}>
            {title}
          </div>
          <div className={styles['task-card-description']}>
            {description}
          </div>
        </div>
        {priority && typeof priority === 'string' && (
          <div className={cn(styles['task-card-priority'], styles[`task-card-priority-${priority.toLowerCase()}`])}>
            {priority}
          </div>
        )}
      </div>
      <div className="flex align-center space-between w-full">
        <span className={styles['task-card-label']}>
          {label}
        </span>
        <div className="flex align-center">
          {
            figmaCommentUrl
              && (
                <Link className="mr-8" to={figmaCommentUrl} target="_blank" rel="noreferrer">
                  Figma link
                </Link>
              )
          }
          <button
            className={cn(styles['copy-button'], {
              'mr-16': imageUrl,
            })}
            onClick={handleCopyText}
            disabled={isCopied}
          >
            {
              isCopied
                ? (
                  <>
                    <FontAwesomeIcon className="mr-8" icon={faCheck} />
                    {' '}
                    Text copied
                  </>
                )
                : (
                  <>
                    <FontAwesomeIcon className="mr-8" icon={faClipboard} />
                    {' '}
                    Copy text
                  </>
                )
            }
          </button>
          {
            imageUrl
              && (
                <ImageViewer
                  src={imageUrl}
                  width={70}
                  height={70}
                />
              )
          }
        </div>
      </div>
    </div>
  );

  async function handleCopyText() {
    let textToCopy = `Title: ${title}\nDescription: ${description}\nLabel: ${label}\nPriority: ${priority}`;

    if (figmaCommentUrl) {
      textToCopy += `\nFigma link: ${figmaCommentUrl}`;
    }
    await copyValue(textToCopy);
    showSuccessCopyText();
  }

  function showSuccessCopyText() {
    setIsCopied(true);
    successReactToastify(
      'Task text copied to clipboard'
    );
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }
};

TaskCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  priority: PropTypes.string,
  commentCroppedImage: PropTypes.string,
  figmaCommentUrl: PropTypes.string,
};
