import PropTypes from 'prop-types';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import addFileIcon from 'assets/starter_screen_icon.svg';

import { Button } from 'Components/Button';

import { KANBAN_BOARD_NAME_TO_STATUS, KANBAN_BOARD_STATUS_OPTIONS } from 'utlis/constants';

import styles from './styles.module.scss';

export function StarterScreen({ openCustomTaskModal, onClickAddFigmaFileButton }) {
  return (
    <div className={cn('flex flex-column align-center just-center', styles['empty-kanban-board'])}>
      <img src={addFileIcon} alt="Add File Icon" />
      <span className={styles['sync-comments-title']}>
        To generate tasks from AI, import your Figma files, meeting transcripts or notes.
      </span>
      <span className={styles['sync-comments-description']}>
        Or, create a custom task.
      </span>
      <div
        className="flex mt-32"
        style={{ gap: '16px' }}
      >
        <Button
          className={styles['manage-files-button']}
          onClick={onClickAddFigmaFileButton}
        >
          <FontAwesomeIcon className="mr-8" icon={faGear} />
          Import files
        </Button>
        <Button
          className={styles['manage-files-button']}
          onClick={() => openCustomTaskModal(KANBAN_BOARD_NAME_TO_STATUS[KANBAN_BOARD_STATUS_OPTIONS.BACKLOG])}
        >
          Create custom task
        </Button>
      </div>
    </div>
  );
}

StarterScreen.propTypes = {
  openCustomTaskModal: PropTypes.func.isRequired,
  onClickAddFigmaFileButton: PropTypes.func.isRequired,
};
