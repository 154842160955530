import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import styles from './styles.module.scss';

export const JiraAuthComplete = () => (
  <AuthModel
    icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
    title="You're all set!"
    description="Return to Figma and start importing your Jira tasks into DesignPro."
  />
);
