import axios from 'axios';

import { getAllContainers } from './designConceptsAction';
import { setUserProfile } from './userActions';
import { successReactToastify, errorReactToastify } from '../../utlis/toasts';
import http from 'utlis/http';
import actionTypes from '../constants';

export const initializeFigmaTokens = figmaTokens => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_FIGMA_TOKENS, payload: figmaTokens });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeFigmaTokens = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_FIGMA_TOKENS, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const initializeFigmaFiles = (
  selectedProject,
  setIsLoading,
) => async dispatch => {
  let next = '';
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/files/`,
    };
    await http(config)
      .then(response => {
        next = response.data.next;
        dispatch({
          type: actionTypes.SET_FIGMA_FILES,
          payload: response?.data?.results,
        });
      })
      .then(() => {
        dispatch({
          type: actionTypes.SET_POSTS_NEXT_BATCH_URL,
          payload: next,
        });
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const fetchNextPostsBatch = (
  nextBatchURL,
  setIsLoading,
) => async dispatch => {
  const posts = [];
  let next = '';
  try {
    const config = {
      method: 'get',
      url: `${nextBatchURL}`,
    };

    await http(config)
      .then(response => {
        next = response.data.next;
        response?.data?.results?.map(obj => posts.push(obj));
      })
      .then(() =>
        dispatch({
          type: actionTypes.FETCH_NEXT_POSTS_BATCH,
          payload: posts,
        }),
      )
      .then(() =>
        dispatch({
          type: actionTypes.SET_POSTS_NEXT_BATCH_URL,
          payload: next,
        }),
      )
      .then(() => setIsLoading(false));
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removePostsNextBatchUrl = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_POSTS_NEXT_BATCH_URL, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeWorkflowSteps = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_WORKFLOW_STEPS, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeFigmaFiles = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_FIGMA_FILES, payload: [] });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const setSelectedFigmaFile = file => async dispatch => {
  try {
    dispatch({ type: actionTypes.SET_SELECTED_FIGMA_FILE, payload: file });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const removeSelectedFigmaFile = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.REMOVE_SELECTED_FIGMA_FILE, payload: {} });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const deleteFigmaFile = (
  selectedProject,
  file,
  setFilesCount,
  currentMilestone,
) => async dispatch => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/figma_file/${file?.uuid}/`,
    };

    return await http(config).then(() => {
      if (setFilesCount) {
        setFilesCount(prevState => prevState - 1);
      }
      if (
        file?.milestone_name === 'Design Concepts' ||
        file?.milestone_name === 'Moodboard'
      ) {
        dispatch(getAllContainers(currentMilestone?.id));
      }
      dispatch({ type: actionTypes.DELETE_FEED_FILE, payload: file });
      successReactToastify('Selected Figma Frame is successfully deleted.');
    });
  }
  catch (error) {
    errorReactToastify(error.response.data.detail);

    throw new Error(error);
  }
};

export const getFigmaSVGLink =
({ fileId, isNewVersion, setImageLinks, setIsLoadingFrame, fileKey, name }, payload) => async dispatch => {
  try {
    const token = window.localStorage.getItem('figma_accessToken');
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_FIGMA_BASE_URL}/v1/images/${fileKey}?ids=${fileId}&scale=1&svg_include_id=true&format=png`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    if (response) {
      const newImagesLinks = Object.values(response.data.images).filter(Boolean);

      if (!newImagesLinks.length) {
        return Promise.reject({ message: 'An empty Figma file can not be uploaded.' });
      }
      if (setImageLinks) {
        setImageLinks(prevState => [...prevState, newImagesLinks[0]]);
      }
      if (isNewVersion) {
        const figmaFile = {
          'file_key': fileKey,
          'node_ids': fileId,
          'svg_links': newImagesLinks,
          'file_name': name,
        };
        dispatch(postNewVersion(
          payload.currentMilestone,
          figmaFile, payload.authUser,
          payload.selectedProject,
          payload.selectedFigmaFile,
          null));
      }
      if (setIsLoadingFrame) {
        setIsLoadingFrame(false);
      }
      return newImagesLinks;
    }
  }
  catch (error) {
    errorReactToastify(error?.response?.data?.detail || error?.message);
    if (setIsLoadingFrame) {
      setIsLoadingFrame(false);
    }
  }
};

export const saveFigmaThumbnail = (
  authUser,
  selectedProject,
  milestone,
  data,
  setIsLoading,
) => async dispatch => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/v2/project/${selectedProject?.uuid}/figma_file/`,
      data: data,
    };

    http(config)
      .then(() => {
        successReactToastify('New Figma Frame is successfully posted.');
        setIsLoading(false);
        if (
          milestone?.name === 'Design Concepts' ||
          milestone?.name === 'Moodboard'
        ) {
          dispatch(getAllContainers(milestone?.id));
        }
      })
      .catch(error => {
        errorReactToastify(error?.response?.data?.detail);
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  }
  catch (error) {
    console.log('Error is ', error);
  }
};

export const postNewVersion = (
  milestone,
  payload,
  authUser,
  selectedProject,
  selectedFigmaFile,
  setVersionLoading,
) => async dispatch => {
  try {
    const config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/project/${selectedProject?.uuid}/figma_file/${selectedFigmaFile?.uuid}/`,
      data: payload,
    };
    http(config)
      .then(() => {
        dispatch(initializeFigmaFiles(selectedProject, authUser));
        dispatch(getAllContainers(milestone?.id));
        successReactToastify('New Figma Version is posted successfully');
        if (setVersionLoading) {
          setVersionLoading(false);
        }
      })
      .catch(error => {
        errorReactToastify(error.response.data.detail);
        if (setVersionLoading) {
          setVersionLoading(false);
        }
      });
  }
  catch (error) {
    errorReactToastify(error.response.data.detail);
  }
};

export const getFigmaTokens = (authUser, setFigmaTokens) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/user_profile/${authUser?.uuid}/`,
    };

    http(config)
      .then(response => {
        setFigmaTokens(response.data);
        dispatch(initializeFigmaTokens(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  }
  catch (error) {
    console.log(error);
  }
};

export const getFigmaConnected = (
  authUser,
  selectedProject,
  setFigmaTokens,
) => async dispatch => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/${selectedProject?.uuid}/figma_oauth`,
    };
    http(config)
      .then(response => {
        const newWindow = window.open(
          response.data.url,
          '_self',
          'noopener,noreferrer',
        );
        if (newWindow) {
          newWindow.opener = null;
        }
      })
      .then(() => dispatch(getFigmaTokens(authUser, setFigmaTokens)))
      .then(() => {
        dispatch(setUserProfile(authUser));
      })
      .catch(error => {
        console.log(error?.response?.data?.detail);
      });
  }
  catch (error) {
    console.log(error);
  }
};
