import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import cn from 'classnames';

import LogoInvision from 'Components/DesignBriefForm/components/LogoInvision';
import Checkbox from 'Components/questionnaireComponents/checkbox';
import Field from 'Components/questionnaireComponents/Field';
import FieldsList from 'Components/questionnaireComponents/FieldsList';
import LinksList from 'Components/questionnaireComponents/LinksList';
import { NumberRange } from 'Components/questionnaireComponents/NumberRange';
import Radio from 'Components/questionnaireComponents/radio';
import Select from 'Components/questionnaireComponents/Select';
import { SelectFileOrLinkModal } from 'Components/questionnaireComponents/SelectFileOrLinkModal';
import TemplateString from 'Components/template_string';

import styles from './styles.module.scss';

export default function Question({
  uuid,
  question_text,
  place_holder,
  question_format,
  question_type,
  onCancel,
  onAddOrEdit,

  is_connected_with_next,
  question_choices,
  question_choices_images,
  created_by,
  isNew,
  isDone,
  isSelected,
  ...props
}) {
  const [form] = Form.useForm();

  return (
    <p
      className={cn(
        styles['paragraph'],
        'mt-zero text-weight-normal',
        {
          [styles['connected-with-next']]: is_connected_with_next,
          [`${styles['border']} ${styles['selected-question']} flex space-between`]: (isNew && !isDone),
          [styles['edit-question']]: isSelected && (isDone || created_by),
          'mb-md-lg': !isSelected && !isNew,
          'mb-zero': isNew,
        }
      )}
    >
      {
        (question_text || question_choices || place_holder) && !(isSelected && (isNew || created_by))
          ? (
            <>
              <span className="break-all">
                <TemplateString
                  text={question_text}
                  replaceFunc={({ type, data }) => {
                    if (type == 'text' && data) {
                      const textList = data.split(/{[\w_]+}/g);
                      const keysList = data.match(/{[\w_]+}/g);

                      if (textList?.length && keysList?.length) {
                        return (
                          <>
                            {
                              textList
                                .map((el, idx) => [el, keysList[idx]])
                                .flat()
                                .filter(Boolean)
                                .map(text => {
                                  switch (text) {
                                    case '{ANSWER}':
                                      return getQuestionNode();
                                    default:
                                      return text;
                                  }
                                })
                            }
                          </>
                        );
                      }
                    }
                  }}
                />
              </span>
              {
                ['radio_button', 'multi_file_field', 'file_field', 'checkbox'].includes(question_format)
                  && !!place_holder
                  && (
                    <span className="text-md letter-xs text-starDust">
                      {' '}
                      {place_holder}
                    </span>
                  )
              }
              {' '}
              {question_text?.indexOf('{ANSWER}') == -1 && getQuestionNode()}
            </>
          )
          : getNewOrEditQuestionNode()
      }
      {
        isNew && !isDone && (
          <span className="flex ml-sm">
            <Button
              type="text"
              className="text-13 text-fuzzyWuzzyBrown text-button mr-sm-important"
              onClick={onCancel}
            >
              CANCEL
            </Button>
            <Button
              className={cn(styles['border'], styles['add-question-button'])}
              onClick={async () => {
                await form.validateFields();
                onAddOrEdit(form.getFieldsValue());
              }}
            >
              ADD
            </Button>
          </span>
        )
      }
      {
        (isDone || !isNew) && isSelected && (created_by || (isDone && isNew)) && (
          <Button
            className={cn(styles['border'], styles['add-question-button'], 'ml-md')}
            onClick={async () => {
              await form.validateFields();
              onAddOrEdit(form.getFieldsValue());
            }}
          >
            SAVE
          </Button>
        )
      }
    </p>
  );

  function getQuestionNode() {
    if (question_format == 'text_field') {
      return (
        <Field
          className="text-weight-500"
          name={uuid}
          placeholder={place_holder}

          {...props}
        />
      );
    }
    if ((question_format == 'multiselect_dropdown' || question_format == 'singleselect_dropdown')) {
      return (
        <Select
          className="text-weight-500 text-black"
          name={uuid}
          placeholder={place_holder}
          options={question_choices.map(option => ({ value: option, label: option }))}
          mode={question_format == 'multiselect_dropdown' ? 'multiple' : undefined}

          {...props}
        />
      );
    }
    if (question_format == 'radio_button' && question_type == 'product_visualize_as') {
      return (
        <LogoInvision
          name={uuid}
          className="text-md mb-zero"
          optionClassName="mt-sm-md"

          {...props}
        />
      );
    }
    if (question_format == 'multi_file_field' || question_format == 'file_field') {
      return (
        <SelectFileOrLinkModal
          name={uuid}
          className="mt-sm-md mb-zero"

          {...props}
        />
      );
    }
    if (question_format == 'range' && question_type == 'budget') {
      return (
        <NumberRange
          name={uuid}

          className="text-weight-500"
          prefix="$"
          minPlaceholder={place_holder.split('-')?.[0].trim()}
          maxPlaceholder={place_holder.split('-')?.[1].trim()}

          {...props}
        />
      );
    }
    if (question_format == 'checkbox') {
      return (
        <>
          <br />
          <Checkbox.Group
            name={uuid}
            className="mt-sm text-md"
            options={question_choices}

            {...props}
          />
        </>
      );
    }
    if (question_format == 'radio_button') {
      return (
        <>
          <br />
          <Radio.Group
            name={uuid}
            className="mt-sm text-md"
            options={
              question_choices.map((questionChoicesValue, idx) => ({
                imgUrl: question_choices_images?.[idx],
                label: questionChoicesValue,
                value: questionChoicesValue,
              }))
            }

            {...props}
          />
        </>
      );
    }
    if (question_format == 'list') {
      return (
        <>
          <br />
          <ul className="my-sm">
            <FieldsList
              name={uuid}
              placeholder={place_holder}

              {...props}
            />
          </ul>
        </>
      );
    }
    if (question_format == 'links') {
      return (
        <>
          <br />
          <LinksList
            name={uuid}
            placeholder={place_holder}
            ListWrapComponent={listWrapComponentProps => (
              <ul className="my-sm text-md" {...listWrapComponentProps} />
            )}

            {...props}
          />
        </>
      );
    }
    if (question_format === 'colors') {
      return (
        <Field
          className="text-weight-500"
          name={uuid}
          placeholder={place_holder}
          type="color-tags"

          {...props}
        />
      );
    }
  }

  function getNewOrEditQuestionNode() {
    let questionAnswerField;

    switch (question_format) {
      case 'text_field':
        questionAnswerField = (
          <Field
            className="text-weight-500"
            name="questionAnswerPlaceholder"
            placeholder="add text to help client answer the question"
            required
          />
        );
        break;
      case 'singleselect_dropdown':
        questionAnswerField = (
          <Field
            className="text-weight-500"
            name="questionAnswerChoices"
            placeholder="type drop down menu options here, press “,” to add new option"
            required
            type="tags"
            tagMaxLength={100}
          />
        );
        break;
    }

    return (
      <Form
        className={cn(styles['paragraph'], 'text-md text-black letter-xs')}
        form={form}
        initialValues={{
          questionText: isSelected ? question_text : '',
          questionAnswerPlaceholder: isSelected ? place_holder : '',
          questionAnswerChoices: isSelected && question_choices ? `${question_choices.join(',')},` : '',
        }}
      >
        <Field
          className={cn('text-weight-500', styles['new-question-text-field'])}
          name="questionText"
          placeholder="Add question statement here"
          required
        />
        {' '}
        {questionAnswerField}
      </Form>
    );
  }
}

Question.propTypes = {
  uuid: PropTypes.string.isRequired,
  question_text: PropTypes.string.isRequired,
  place_holder: PropTypes.string.isRequired,
  question_format: PropTypes.string.isRequired,
  question_type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddOrEdit: PropTypes.func.isRequired,

  is_connected_with_next: PropTypes.bool,
  question_choices: PropTypes.array,
  question_choices_images: PropTypes.array,
  isNew: PropTypes.bool,
  isDone: PropTypes.bool,
  required: PropTypes.bool,
  isSelected: PropTypes.bool,
  created_by: PropTypes.any,
};
