import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as NoQuestionImg } from 'assets/no_question_for_brief.svg';

export function NoQuestions({
  className,

  ...props
}) {
  return (
    <div
      className={cn('text-center', className)}
      {...props}
    >
      <NoQuestionImg />
      <p className="text-20 letter-xs mt-32 mb-zero">
        Click on “Add Question” to add a question in the design brief
      </p>
    </div>
  );
}

NoQuestions.propTypes = {
  className: PropTypes.string,
};
