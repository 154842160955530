import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export const LoaderCard = ({ className, showContentItems }) => (
  <div className={cn(styles.thumbnail, className)}>
    <div className={styles.thumbnail__content}>
      <div className={styles.is__loading}>
        <div className={styles.loader__image} />
        {
          showContentItems
            && (
              <div className="content">
                <div className={styles.content__1} />
                <div className={styles.content__1} />
                <div className={styles.content__1} />
              </div>
            )
        }
      </div>
    </div>
  </div>
);

LoaderCard.propTypes = {
  className: PropTypes.string,
  showContentItems: PropTypes.bool,
};
