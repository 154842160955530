import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

export const ElementNotFound = ({ className, title, subTitle, ...props }) => (
  <div
    className={cn(
      'flex flex-column flex-component-center text-center',
      styles['element-not-found'],
      className
    )}
    {...props}
  >
    <p className="text-100 mx-zero mt-zero mb-md">
      404
    </p>
    <p className="text-xxl letter-sm mx-zero mt-zero mb-sm-md">
      {title}
    </p>
    {
      !!subTitle && (
        <p className="text-20 letter-sm mx-zero mt-zero mb-md-lg">
          {subTitle}
        </p>
      )
    }
  </div>
);

ElementNotFound.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

ElementNotFound.defaultProps = {
  title: 'Oops! Something went wrong…',
};
