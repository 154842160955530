import { useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import Field from 'Components/questionnaireComponents/Field';

function NumberRangeField({ name, required, ...props }) {
  const [hasError, setHasError] = useState(false);

  return (
    <Form.Item
      name={name}
      rules={[
        {
          // eslint-disable-next-line no-unused-vars
          validator: (rule, currentValue) => {
            const newHasError = (
              (required && (!currentValue?.[0] || !currentValue?.[1]))
                || (Number(currentValue?.[0]) > Number(currentValue?.[1]))
            );

            setHasError(newHasError);

            return (
              newHasError
                ? Promise.reject()
                : Promise.resolve()
            );
          },
        },
      ]}
      noStyle
    >
      <NumberRangeInput
        validateStatus={
          hasError
            ? 'error'
            : null
        }

        {...props}
      />
    </Form.Item>
  );
}

NumberRangeField.propTypes = {
  name: PropTypes.string.isRequired,

  required: PropTypes.bool,
};

function NumberRangeInput({
  onChange,

  minPlaceholder,
  maxPlaceholder,
  value,

  ...props
}) {
  return (
    <>
      <Field
        placeholder={minPlaceholder}
        type="number"
        initialValue={value?.[0]}
        onChange={newMinValue => {
          onChange([newMinValue, value?.[1] || '']);
        }}

        {...props}
      />
      {' '}
      -
      {' '}
      <Field
        placeholder={maxPlaceholder}
        type="number"
        initialValue={value?.[1]}
        onChange={newMaxValue => {
          onChange([value?.[0] || '', newMaxValue]);
        }}

        {...props}
      />
    </>
  );
}

NumberRangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,

  minPlaceholder: PropTypes.string,
  maxPlaceholder: PropTypes.string,
  value: PropTypes.array,
};

NumberRangeInput.defaultProps = {
  minPlaceholder: 'min',
  maxPlaceholder: 'max',
};

export { NumberRangeField as NumberRange };
