import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import cn from 'classnames';

import { ReactComponent as ThumbUpIcon } from 'assets/thumb_up.svg';

import { VoteService } from 'services/vote';

import { NOTIFICATION_TYPES } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';

import styles from './styles.module.scss';

export function VotingTools({
  file,
  fileType,

  className,
  disabled,

  ...props
}) {
  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [voteValue, setVoteValue] = useState(0);

  useEffect(
    () => {
      if (file?.uuid && fileType) {
        loadVotes();
      }
    },
    [file?.uuid, fileType]
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { content } }) => {
        if (content?.object_uuid == file?.uuid) {
          loadVotes();
        }
      },
      [
        NOTIFICATION_TYPES.POST_VOTE,
      ],
    ),
    [file?.uuid, fileType]
  );

  return (
    <button
      className={cn(
        'text-white text-lg text-weight-500 letter-xl',
        styles['vote-tools-wrapper'],
        {
          pointer: !disabled,
        },
        className
      )}
      disabled={disabled || inProgress}
      onClick={changeVote}
      {...props}
    >
      {
        inProgress
          ? <Spin size="small" className="mr-sm" />
          : <ThumbUpIcon className="px-xxxs mr-sm" />
      }
      {
        isLoading
          ? <Spin size="small" />
          : voteValue
      }
    </button>
  );

  async function loadVotes() {
    try {
      setIsLoading(true);

      const { vote_count } = await VoteService.getFileVoteValue(file.uuid, fileType);

      setVoteValue(vote_count || 0);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function changeVote() {
    try {
      setInProgress(true);

      await VoteService.changeFileVoteValue(file.uuid, fileType);

      setVoteValue(currentValue => currentValue + 1);
    }
    finally {
      setInProgress(false);
    }
  }
}

VotingTools.fileTypes = VoteService.VOTE_CONTENT_TYPE_NAMES;

VotingTools.propTypes = {
  file: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  fileType: PropTypes.oneOf(Object.values(VotingTools.fileTypes)).isRequired,

  className: PropTypes.string,
  disabled: PropTypes.bool,
};
