import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress, Spin, Tooltip, Upload } from 'antd';
import cn from 'classnames';

import 'quill-mention/dist/quill.mention.min.css';
import 'react-quill/dist/quill.snow.css';

import 'quill-mention';

import { ReactComponent as AtIcon } from 'assets/at_icon.svg';
import { ReactComponent as LoomIcon } from 'assets/loom_icon.svg';
import { ReactComponent as Paperclip } from 'assets/paper_clip_vertical.svg';

import { Button } from 'Components/Button';
import { LocalImage } from 'Components/MoodboardMilestone/components/MoodboardModal/components/LocalImage';

import FilesService from 'services/files';
import { Loom } from 'services/loom';

import copyValue from 'utlis/copy_value';
import AutoLinks from 'utlis/quill_auto_links.js';
import { infoReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

ReactQuill.Quill.register('modules/autoLinks', AutoLinks);

const defaultReactQuillModules = {
  toolbar: [
    [
      'bold',
      'italic',
      'underline',
      { 'list': 'bullet' },
      { 'list': 'ordered' },
      { 'color': [] },
    ],
  ],
  autoLinks: true,
};
const defaultReactQuillFormats = [
  'bold',
  'color',
  'italic',
  'link',
  'underline',
  'list',
  'align',
  'mention',
  'autolink',
  'image',
];
/**
 * This list is required to store the mentionsList for each instance of CustomReactQuill.
 * This is due to the fact that we cannot update this list by simply changing the parameter
 * and function for the search - this causes the component to be destroyed
 * (quill-mention additional library problem)
 */
const globalMentionsList = {};

const maxFileSize = 5;

function CustomReactQuill(
  {
    className,
    mentionsButtonClassName,
    mentionsList,
    form,
    showLoom,
    showImageUpload,
    setIsUploadInProgress,
    setIsValidImages,
    ...props
  },
  ref
) {
  const reactQuillRef = useRef();
  const loomButtonRef = useRef();

  const { customReactQuillId } = useMemo(() => Math.trunc(Math.random() * Math.pow(10, 6)), []);

  const [isLoomInitialized, setIsLoomInitialized] = useState(false);
  const [imagesListForUpload, setImagesListForUpload] = useState([]);

  const { reactQuillFormats, reactQuillModules } = useMemo(
    () => mentionsList?.length
      ? {
        reactQuillFormats: defaultReactQuillFormats,
        reactQuillModules: {
          ...defaultReactQuillModules,
          mention: {
            allowedChars: /^.*$/,
            mentionDenotationChars: ['@'],
            isolateCharacter: true,
            dataAttributes: ['uuid', 'value', 'full_name', 'email', 'profile_photo', 'disabled'],
            source(searchTerm, renderList) {
              renderList(
                getMentionsList(searchTerm),
                searchTerm
              );
            },
            renderItem(item) {
              return renderToStaticMarkup(
                <div className={cn(styles['mention-dropdown-item'], 'flex align-center pl-8')}>
                  <img
                    src={item.profile_photo}
                    className={styles['avatar']}
                    width={20}
                  />
                  <p className="test-sm text-black-opacity-0-90 ml-sm my-zero">
                    {item.full_name}
                  </p>
                </div>
              );
            },
          },
        },
      }
      : {
        reactQuillFormats: defaultReactQuillFormats,
        reactQuillModules: defaultReactQuillModules,
      },
    []
  );

  useEffect(
    () => {
      globalMentionsList[customReactQuillId] = mentionsList;
    },
    [mentionsList]
  );

  useEffect(
    () => {
      if (!setIsValidImages || !setIsUploadInProgress) {
        return;
      }
      if (imagesListForUpload.length > 0) {
        const isUploadCompleted = imagesListForUpload.every(file => file.url);
        const isValidImages = imagesListForUpload.every(
          file => file.size <= maxFileSize * Math.pow(10, 6)
        );

        setIsValidImages(isValidImages);

        return setIsUploadInProgress(!isUploadCompleted);
      }
      setIsValidImages(true);
      setIsUploadInProgress(false);
    },
    [imagesListForUpload]
  );

  useEffect(
    () => {
      const initLoom = async () => {
        if (Loom.isLoomInitialized) {
          return bindLoomButtonWithLoomSDK(Loom.configureLoomButton);
        }

        const { configureButton, status: LoomStatus } = await Loom.init();

        if (configureButton && LoomStatus().success) {
          bindLoomButtonWithLoomSDK(configureButton);
        }
        else {
          initLoom();
        }
      };

      if (showLoom) {
        initLoom();
      }
    },
    [loomButtonRef.current, Loom.isLoomInitialized]
  );

  return (
    <div className={styles['custom-react-quill-wrapper']}>
      <ReactQuill
        ref={node => {
          reactQuillRef.current = node;
          ref(node);
        }}
        className={cn(
          styles['custom-react-quill'],
          {
            [styles['with-mention-button']]: mentionsList?.length,
          },
          className
        )}
        modules={reactQuillModules}
        formats={reactQuillFormats}
        {...props}
      />
      {
        showLoom
          && (
            <Tooltip
              overlayClassName="tooltip-container"
              title={
                isLoomInitialized ? 'Record with loom' : 'Loading...'
              }
              placement="bottom"
            >
              <button
                onClick={e => e.preventDefault()}
                ref={loomButtonRef}
                className={cn(styles['loom-icon-btn'])}
              >
                <LoomIcon className={styles['loom-icon']} />
              </button>
            </Tooltip>
          )
      }
      {
        showImageUpload
          && (
            <Tooltip
              overlayClassName="tooltip-container"
              title="Upload an image"
              placement="bottom"
            >
              <Upload
                className={styles['attachment-icon-btn']}
                accept="image/*"
                multiple
                beforeUpload={handleImageUpload}
                showUploadList={false}
              >
                <Paperclip className={styles['attachment-icon']} />
              </Upload>
            </Tooltip>
          )
      }
      {
        !!mentionsList?.length
          && (
            <Button
              isText
              onClick={openMentionMenu}
              className={cn(styles['mention-button'], mentionsButtonClassName)}
            >
              <AtIcon className={styles['mention-icon']} />
            </Button>
          )
      }
      {
        !!imagesListForUpload.length && showImageUpload
          && (
            <div className={cn(styles['images-list-wrapper'], 'flex flex-wrap pt-12 px-32')}>
              {
                imagesListForUpload.map(file => (
                  <div
                    className={cn(
                      styles['local-image'],
                      'flex flex-column flex-component-center mb-12',
                      {
                        [styles['loading-wrapper']]: file.isInfinityLoading || file.isProgressLoading,
                        [styles['big-image']]: file.size > maxFileSize * Math.pow(10, 6) && file.isLoaded,
                      }
                    )}
                    key={file.uid}
                  >
                    <div
                      className={cn(
                        styles['big-image-alert'],
                        {
                          hide: file.size <= maxFileSize * Math.pow(10, 6) || !file.isLoaded,
                        }
                      )}
                    >
                      Max file size
                      {' '}
                      {maxFileSize}
                      {' '}
                      MB
                    </div>
                    <div className={cn('flex just-center align-center flex-column', styles['image-container'])}>
                      <LocalImage
                        file={file}
                        className={styles['inner-local-image']}
                        onLoaded={() => updateImagesListForUpload(
                          file.uid,
                          {
                            isLoaded: true,
                          }
                        )}
                      />
                      <Button
                        isText
                        className={cn('flex just-center align-center', styles['delete-img-btn'])}
                        onClick={() => removeLocalImage(file.uid)}
                      >
                        <FontAwesomeIcon className={styles['close-icon']} icon={faXmark} />
                      </Button>
                    </div>
                    <Spin
                      className={cn(
                        styles['infinity-loading'],
                        {
                          hide: !file.isInfinityLoading,
                        }
                      )}
                    />
                    <Progress
                      className={cn(
                        styles['progress-loading'],
                        {
                          hide: !file.isProgressLoading,
                        }
                      )}
                      type="circle"
                      percent={file.progress || 0}
                      width={50}
                    />
                  </div>
                ))
              }
            </div>
          )
      }
    </div>
  );

  function openMentionMenu(event) {
    event.preventDefault();

    reactQuillRef?.current?.editor?.getModule('mention')?.openMenu?.('@');
  }

  function getMentionsList(search) {
    return globalMentionsList[customReactQuillId]
      .filter(({ full_name, email }) => (
        full_name.toLowerCase().indexOf(search.toLowerCase()) != -1
          || email.toLowerCase().indexOf(search.toLowerCase()) != -1
      ))
      .map(user => ({
        ...user,
        value: user.full_name.replace(' (you)', ''),
      }));
  }

  async function handleImageUpload(file) {
    setImagesListForUpload(currentImagesListForUpload => (
      currentImagesListForUpload
        .concat(file)
        .reduce(
          (current, next) => (
            current.find(({ uid }) => next.uid && next.uid === uid)
              ? current
              : current.concat(next)
          ),
          []
        )
    ));

    if (file.size <= maxFileSize * Math.pow(10, 6)) {
      await uploadFileToS3(file);
    }

    return false;
  }

  async function uploadFileToS3(file) {
    updateImagesListForUpload(
      file.uid,
      {
        isProgressLoading: true,
      }
    );

    const fileUrl = await FilesService.uploadFileFromLocal(
      file,
      {
        onUploadProgress: ({ progress }) => updateImagesListForUpload(
          file.uid,
          {
            progress: Math.round(progress * 100),
          }
        ),
      }
    );

    updateImagesListForUpload(
      file.uid,
      {
        url: fileUrl,
        isProgressLoading: false,
      }
    );
    const previousAddedFileLinks = form.getFieldValue('file_links');

    form.setFieldValue('file_links', [...previousAddedFileLinks, { uid: file.uid, fileUrl }]);
  }

  function removeLocalImage(removedUID) {
    setImagesListForUpload(
      currentImagesListForUpload => currentImagesListForUpload
        .filter(({ uid, id }) => (uid !== removedUID && id !== removedUID))
    );

    const currentFileLinks = form.getFieldValue('file_links');
    const updatedFileLinks = currentFileLinks.filter(file => file.uid !== removedUID);

    form.setFieldValue('file_links', updatedFileLinks);
  }

  function bindLoomButtonWithLoomSDK(configureLoomButton) {
    const sdkButton = configureLoomButton({ element: loomButtonRef.current });

    sdkButton.on('insert-click', async video => {
      const fullMessage = [];
      const previousMessageText = form.getFieldValue('message') || '';

      if (previousMessageText) {
        fullMessage.push(previousMessageText);
      }

      fullMessage.push(video.sharedUrl);
      form.setFieldsValue({
        message: fullMessage.join(' '),
      });

      await copyValue(video.sharedUrl);
      infoReactToastify('Copied Loom recording link to clipboard', { autoClose: 5000 });

      if (reactQuillRef.current) {
        const quill = reactQuillRef.current.getEditor();

        quill.setSelection(Number.MAX_VALUE, Number.MAX_VALUE);
      }
    });

    // INFO: Sometimes Loom SDK takes some time to load so added a setTimeout to indicate when the library has been initialized
    setTimeout(
      () => setIsLoomInitialized(true),
      3000
    );
  }

  function updateImagesListForUpload(fileID, newData) {
    setImagesListForUpload(currentImagesListForUpload => currentImagesListForUpload.map(image => {
      if (image.id === fileID || image.uid === fileID) {
        for (const key in newData) {
          image[key] = newData[key];
        }
      }

      return image;
    }));
  }
}

CustomReactQuill.propTypes = {
  className: PropTypes.func,
  mentionsButtonClassName: PropTypes.func,
  mentionsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    profile_photo: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  form: PropTypes.object,
  showLoom: PropTypes.bool,
  showImageUpload: PropTypes.bool,
  setIsUploadInProgress: PropTypes.func,
  setIsValidImages: PropTypes.func,
};

const CustomReactQuillWithRef = forwardRef(CustomReactQuill);

export {
  CustomReactQuillWithRef as ReactQuill,
  defaultReactQuillModules,
  defaultReactQuillFormats,
};
