import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class ColorPaletteService {
  static async getColorPalettes(projectUUID, milestoneId) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/color_palette`)
      .get()).data;
  }

  static async addColorPalette(projectUUID, milestoneId, params) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/color_palette`)
      .post(params)).data;
  }

  static async editColorPalette(colorPaletteUUID, projectUUID, milestoneId, params) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/color_palette/${colorPaletteUUID}`)
      .patch('', { uuid: colorPaletteUUID, ...params })).data;
  }

  static async deleteColorPalette(colorPaletteUUID, projectUUID, milestoneId) {
    return (await api.setUrl(`project/${projectUUID}/milestone/${milestoneId}/color_palette/${colorPaletteUUID}`)
      .delete()).data;
  }
}
