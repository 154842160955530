import PropTypes from 'prop-types';

import GoogleLogoButton from 'assets/GoogleLogoButton.png';

import Login from 'Components/Login/Login';

export const LoginButton = ({ attemptLogin: isAttemptLogin }) => (
  <Login loginButtonClassName="login__buttonContainer" attemptLogin={isAttemptLogin}>
    <>
      <img
        className="login__googleLogo"
        height={26}
        width={26}
        src={GoogleLogoButton}
        alt="Login Button"
      />
      <span
        className="login__buttonText"
      >
        Sign-in to try for free
      </span>
    </>
  </Login>
);

LoginButton.propTypes = {
  attemptLogin: PropTypes.bool,
};
