import { useMemo } from 'react';
import PropTypes from 'prop-types';

import figmaIcon from 'assets/figma_logo.svg';
import googleMeetIcon from 'assets/google_meet_icon.svg';
import googleDocsIcon from 'assets/GoogleDocsIcon.svg';
import notionIcon from 'assets/notion_icon.svg';
import slackIcon from 'assets/slack_logo.svg';
import starterScreenIcon from 'assets/starter_screen_icon_gradient.svg';
import zoomIcon from 'assets/zoom_icon.svg';

import { Button } from 'Components/Button';

import { KANBAN_BOARD_FILE_TYPE_NAMES,
  KANBAN_BOARD_NAME_TO_STATUS, KANBAN_BOARD_STATUS_OPTIONS } from 'utlis/constants';

import styles from './styles.module.scss';

export function EmptyProjectModal({ handleImportOption, openCustomTaskModal, closeModal }) {
  const sourceOptions = useMemo(
    () => [
      {
        title: 'Figma comments',
        icons: [figmaIcon],
        onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.FIGMA),
      },
      {
        title: 'Design feedback',
        icons: [googleMeetIcon, zoomIcon],
        onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.TRANSCRIPTION),
      },
      {
        title: 'Meeting notes',
        icons: [googleDocsIcon, notionIcon],
        onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.GOOGLE_DOCS),
      },
      {
        title: 'Slack message threads',
        icons: [slackIcon],
        onClick: () => handleImportOption(KANBAN_BOARD_FILE_TYPE_NAMES.SLACK),
      },
    ],
    []
  );

  return (
    <div className={styles['empty-project-modal']}>
      <img className={styles['starter-screen-img']} src={starterScreenIcon} alt="Add File Icon" />
      <div className={styles['starter-screen-content']}>
        <span className={styles['sync-comments-title']}>
          Let’s get started!
        </span>
        <span className={styles['sync-comments-description']}>
          Choose what you want to convert into tasks:
        </span>
        <div className={styles['source-options-container']}>
          {
            sourceOptions.map(({ title, icons, onClick }) => (
              <div
                key={title}
                className={styles['source-option']}
                onClick={onClick}
              >
                <div className={styles['icons-container']}>
                  {
                    icons.map(
                      (icon, index) => (
                        <img
                          width={24}
                          height={24}
                          key={index}
                          src={icon}
                          alt={title}
                          className={styles['source-option-icon']}
                        />
                      )
                    )
                  }
                </div>
                <span className={styles['source-option-title']}>
                  {title}
                </span>
              </div>
            ))
          }
        </div>
        <div className={styles['divider-container']}>
          <span className={styles['divider-text']}>
            OR
          </span>
        </div>
        <Button
          isInvert
          className={styles['create-task-button']}
          onClick={handleOpenCustomTask}
        >
          Create task manually
        </Button>
      </div>
    </div>
  );

  function handleOpenCustomTask() {
    closeModal();
    openCustomTaskModal(KANBAN_BOARD_NAME_TO_STATUS[KANBAN_BOARD_STATUS_OPTIONS.BACKLOG]);
  }
}

EmptyProjectModal.propTypes = {
  handleImportOption: PropTypes.func.isRequired,
  openCustomTaskModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
