import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ConnectApp } from 'Pages/Settings/sub-routes/ConnectedApps/components/ConnectApp';
import { ToggleSwitch } from 'Pages/Settings/sub-routes/ConnectedApps/components/ToggleSwitch';

import { unlinkSlackAccount } from 'Redux/Actions/SlackActions';

import SlackService from 'services/slack.js';

import { infoReactToastify, successReactToastify } from 'utlis/toasts';

export const SlackActions = ({ onChangeStart, onChangeStop }) => {
  const dispatch = useDispatch();

  const [createLinkToSlackInProgress, setCreateLinkToSlackInProgress] = useState(false);

  const slackToken = useSelector(state => state.userProfile?.appTokens?.slack);

  return (
    slackToken
      ? (
        <ToggleSwitch onChange={disconnectSlack} />
      )
      : (
        <ConnectApp
          title="Slack"
          onClick={handleSlackAccount}
          isDisabled={createLinkToSlackInProgress}
        />
      )
  );

  function handleSlackAccount() {
    setCreateLinkToSlackInProgress(true);
    onChangeStart();

    SlackService.getSlackOAuthLink()
      .then(link => {
        if (slackToken) {
          window.open(link, '_blank', 'noopener,noreferrer');
          setCreateLinkToSlackInProgress(false);
          onChangeStop();
        }
        else {
          infoReactToastify(`
            After a few seconds, you will be redirected to the Slack Authentication Page.
            When you finish the auth, you will return to this page automatically.
          `);
          setTimeout(
            () => {
              location.href = link;
            },
            3000
          );
        }
      })
      .finally(
        () => {
          setCreateLinkToSlackInProgress(false);
          onChangeStop();
        }
      );
  }

  function disconnectSlack() {
    onChangeStart();
    dispatch(unlinkSlackAccount())
      .then(() => {
        successReactToastify('Successfully disconnected with Slack');
      })
      .finally(
        () => {
          onChangeStop();
        }
      );
  }
};

SlackActions.propTypes = {
  onChangeStart: PropTypes.func.isRequired,
  onChangeStop: PropTypes.func.isRequired,
};
