import { useEffect, useState } from 'react';
import { Image as Img } from 'antd';
import PropTypes from 'prop-types';

import LinkPreviewService from 'services/link_preview';

const promisesList = {};

const dataKeysToDisplay = {
  Title: 'title',
  Description: 'description',
  Image: 'image',
  Icon: 'icon',
  ImageOrIcon: 'image_or_icon',
};

export function LinkPreviewData({
  link,
  dataKeyToDisplay,

  defaultIcon,

  ...props
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      let linkPreviewData = getLinkPreviewData();

      if (!linkPreviewData) {
        linkPreviewData = {};
        setLinkPreviewData({});
      }

      if (!linkPreviewData[link]) {
        setLinkPreviewData({
          ...getLinkPreviewData(),
          [link]: 'Loading ...',
        });

        promisesList[link] = LinkPreviewService.getLinkPreview(link)
          .then(data => {
            setLinkPreviewData({
              ...getLinkPreviewData(),
              [link]: data,
            });
          })
          .then(() => setIsLoading(false));
      }
      else if (promisesList[link]) {
        promisesList[link] = promisesList[link].then(() => setIsLoading(false));
      }
      else {
        setIsLoading(false);
      }
    },
    []
  );

  return isLoading
    ? 'Loading ...'
    : getDataToDisplay();

  function getLinkPreviewData() {
    return JSON.parse(sessionStorage.getItem('linkPreviewData'));
  }

  function setLinkPreviewData(data) {
    sessionStorage.setItem('linkPreviewData', JSON.stringify(data));
  }

  function getDataToDisplay() {
    const pagePreviewData = getLinkPreviewData()?.[link];

    switch (dataKeyToDisplay) {
      case dataKeysToDisplay.Title:
      case dataKeysToDisplay.Description:
        return pagePreviewData?.[dataKeyToDisplay] || '';
      case dataKeysToDisplay.Image:
        return <Img src={pagePreviewData?.['images']?.[0] || defaultIcon || ''} {...props} />;
      case dataKeysToDisplay.Icon:
        return <Img src={pagePreviewData?.['favicons']?.at(-1) || defaultIcon || ''} {...props} />;
      case dataKeysToDisplay.ImageOrIcon:
        return (
          <Img
            src={pagePreviewData?.['images']?.[0] || pagePreviewData?.['favicons']?.at(-1) || defaultIcon || ''}
            {...props}
          />
        );
      default:
        return '';
    }
  }
}

LinkPreviewData.propTypes = {
  link: PropTypes.string.isRequired,
  dataKeyToDisplay: PropTypes.oneOf(Object.values(dataKeysToDisplay)).isRequired,

  defaultIcon: PropTypes.node,
};

Object.keys(dataKeysToDisplay).forEach(dataKeyToDisplay => {
  // eslint-disable-next-line react/prop-types
  LinkPreviewData[dataKeyToDisplay] = props => ( // eslint-disable-line react/display-name, react/prop-types
    <LinkPreviewData dataKeyToDisplay={dataKeysToDisplay[dataKeyToDisplay]} {...props} />
  );
  // display-name for previos component
  LinkPreviewData[dataKeyToDisplay].displayName = `LinkPreviewData.${dataKeyToDisplay}`;
});
