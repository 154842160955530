import actionTypes from '../constants';

const feedInitialState = {};
const milestoneInitialState = {
  list: [],
  dictionaryId: {},
  currentMilestone: null,
};

export const feedReducer = (state = feedInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEED_FILES:
      return {
        ...action.payload,
        results: action.payload.results.map(feedObj => ({
          ...feedObj,
          milestone: getMilestoneInfo(feedObj.milestone),
        })),
      };
    case actionTypes.UPDATE_FEED_FILES:
      action.payload.results = [...state.results, ...action.payload.results]
        .map(feedObj => ({
          ...feedObj,
          milestone: getMilestoneInfo(feedObj.milestone),
        }));

      return action.payload;
    case actionTypes.REMOVE_FEED_FILES:
      return {};
    default:
      return state;
  }
};

export const milestonesReducer = (state = milestoneInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MILESTONES_STORE:
      return action.payload
        .map(getMilestoneInfo)
        .reduce(
          (accumulator, currentValue, idx) => {
            accumulator.list[idx] = accumulator.dictionaryId[currentValue.id] = currentValue;
            accumulator.currentMilestone = accumulator.currentMilestone
              ? (
                accumulator.currentMilestone.is_completed
                  ? currentValue
                  : accumulator.currentMilestone
              )
              : currentValue;

            return accumulator;
          },
          {
            list: [],
            dictionaryId: {},
            currentMilestone: null,
          }
        );

    case actionTypes.REMOVE_MILESTONE_STORE:
      return milestoneInitialState;

    case actionTypes.REMOVE_MILESTONE_OBJECT:
      return milestoneInitialState;

    case actionTypes.STOP_PROMPT_COPY_DESIGN_BRIEF:
      return {
        ...state,
        list: state.list.map(milestone =>
          milestone.id === action.payload.id
            ? { ...milestone, copy_information_prompt: false }
            : milestone
        ),
        dictionaryId: {
          ...state.dictionaryId,
          [action.payload.id]: {
            ...state.dictionaryId[action.payload.id],
            copy_information_prompt: false,
          },
        },
      };

    default:
      return state;
  }
};

function getMilestoneInfo(milestone) {
  return milestone; // should be saved for the future to modify the milestone if necessary
}
