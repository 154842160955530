import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { Button } from 'Components/Button';

import { updateProjectAPI } from 'services/tasks';

import { KANBAN_BOARD_ID_TO_STRING } from 'utlis/constants';

import styles from './styles.module.scss';

export function AddNewColumn() {
  const [newColTitle, setNewColTitle] = useState('');
  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [isCreatingColumn, setIsCreatingColumn] = useState(false);
  const currentProject = useSelector(state => state.kanbanBoard.currentProject);
  const userUUID = useSelector(state => state.userProfile?.uuid);
  const newColCount = useMemo(
    () => KANBAN_BOARD_ID_TO_STRING[(currentProject?.details?.columns?.length + 1) % 4 || 4] || 0,
    [currentProject]
  );

  return (
    <div
      className={cn(
        styles['add-new-column-container'],
        styles[`${newColCount}-column`]
      )}
    >
      <div className={styles['add-new-column']}>
        {
          !isAddingColumn
            ? (
              <button
                className={styles['add-new-column-text']}
                onClick={() => setIsAddingColumn(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add new
              </button>
            )
            : (
              <div className={styles['input-container']}>
                <input
                  type="text"
                  placeholder="Enter column title"
                  maxLength={32}
                  autoFocus
                  className={styles['title-input']}
                  value={newColTitle}
                  onChange={e => setNewColTitle(e.target.value)}
                />
                <div className={styles['buttons-container']}>
                  <Button
                    isText
                    className={styles['cancel-button']}
                    onClick={handleCloseInput}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!newColTitle || isCreatingColumn}
                    className={cn(
                      styles['add-button'],
                      {
                        [styles['disabled']]: !newColTitle,
                      }
                    )}
                    onClick={handleAddColumn}
                  >
                    { isCreatingColumn ? 'Saving' : 'Save'}
                  </Button>
                </div>
              </div>
            )
        }
      </div>
    </div>
  );

  async function handleAddColumn() {
    const updatedCols = [
      ...currentProject?.details?.columns || [],
      {
        name: newColTitle,
        status: currentProject?.details?.columns.length + 1,
      },
    ];

    try {
      setIsCreatingColumn(true);
      await updateProjectAPI(currentProject?.docID, { columns: updatedCols }, userUUID);
    }
    finally {
      setIsAddingColumn(false);
      setIsCreatingColumn(false);
      setNewColTitle('');
    }
  }

  function handleCloseInput() {
    setIsAddingColumn(false);
    setNewColTitle('');
  }
}
