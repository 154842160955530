import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Menu, Spin } from 'antd';
import cn from 'classnames';

import { ReactComponent as AddReactionIcon } from 'assets/add_reaction_icon.svg';
import { ReactComponent as BinIcon } from 'assets/bin_icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download_icon.svg';
import { ReactComponent as EditTextIcon } from 'assets/edit_text_icon.svg';
import { ReactComponent as TIcon } from 'assets/t_icon.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/ThreeDotsIcon.svg';

import { Button } from 'Components/Button';
import { DropdownButton, DropdownMenu } from 'Components/DropdownMenu';
import ImageFromGDrive from 'Components/ImageWithComments/components/Image';
import { Milestone } from 'Components/Milestone';

import { setUserProfile } from 'Redux/Actions/userActions';

import { ImageSearchService } from 'services/image_search';
import MoodboardService from 'services/moodboard';
import ReactionsService from 'services/reactions';

import { NOTIFICATION_TYPES } from 'utlis/constants';
import downloadFiles from 'utlis/download_files.js';
import { onSocketMessage } from 'utlis/socket';

import { AddCaptionForm } from './components/AddCaptionForm';
import { AddFirstMoodboardItem } from './components/AddFirstMoodboardItem';
import { EmojiSymbol } from './components/EmojiSymbol';
import { MoodboardModal } from './components/MoodboardModal';
import { MoodboardTextModal } from './components/MoodboardTextModal';

import styles from './styles.module.scss';

export default function MoodboardMilestone({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [moodboardItems, setMoodboardItems] = useState([]);
  const [isRemovingMoodboardItems, setIsRemovingMoodboardItems] = useState({});
  const [isDownloadingMoodboardItems, setIsDownloadingMoodboardItems] = useState({});
  const [isShowCaptionFormForMoodboardItems, setIsShowCaptionFormForMoodboardItems] = useState({});
  const [isLoadingReactionsItems, setIsLoadingReactionsItems] = useState({});
  const [showMoodboardModal, setShowMoodboardModal] = useState(false);
  const [showMoodboardTextModal, setShowMoodboardTextModal] = useState(false);
  const [selectedMoodboardTextItemForEdit, setSelectedMoodboardTextItemForEdit] = useState(null);
  const [selectedMoodboardTextItemForRead, setSelectedMoodboardTextItemForRead] = useState(null);
  const [selectedMoodboardItemForAddReaction, setSelectedMoodboardItemForAddReaction] = useState(null);

  const selectedProject = useSelector(state => state.selectedProject);
  const userProfile = useSelector(state => state.userProfile);
  const moodboardMilestone = useSelector(state => state.milestonesStore.dictionaryId[milestoneId]);

  const dispatch = useDispatch();

  const reactionListRef = useRef({});

  const { hasFiles } = useMemo(
    () => ({
      hasFiles: !!moodboardItems.length,
    }),
    [moodboardItems]
  );

  useEffect(
    () => {
      if (!isReadonly) {
        dispatch(setUserProfile(userProfile, null, true));
      }
    },
    [isReadonly]
  );

  useEffect(
    () => {
      loadMoodboardImages();
    },
    [moodboardMilestone.id]
  );

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          loadMoodboardImages();
        }
      },
      [
        NOTIFICATION_TYPES.ADD_IMAGE,
        NOTIFICATION_TYPES.DELETE_IMAGE,
        NOTIFICATION_TYPES.ADD_TEXT,
      ]
    ),
    [selectedProject.uuid, moodboardMilestone.id, milestoneId]
  );
  useEffect(
    () => onSocketMessage(
      ({
        data: {
          reaction_unique_code,
          reaction_object_uuid,
          reaction_user,
          milestone_id,
        },
      }) => {
        if (milestoneId == milestone_id) {
          localLeaveReaction(
            reaction_unique_code,
            reaction_object_uuid,
            reaction_user
          );
          scrollReactionListToBottom(reaction_object_uuid);
        }
      },
      NOTIFICATION_TYPES.REACTION_ADDED
    ),
    [selectedProject.uuid, moodboardMilestone.id, milestoneId, moodboardItems]
  );
  useEffect(
    () => onSocketMessage(
      ({
        data: {
          reaction_unique_code,
          reaction_object_uuid,
          reaction_user,
          milestone_id,
        },
      }) => {
        if (milestoneId == milestone_id) {
          localDeleteReaction(
            reaction_unique_code,
            reaction_object_uuid,
            reaction_user
          );
          scrollReactionListToBottom(reaction_object_uuid);
        }
      },
      NOTIFICATION_TYPES.REACTION_MINUSED
    ),
    [selectedProject.uuid, moodboardMilestone.id, milestoneId, moodboardItems]
  );

  useEffect(
    () => {
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    },
    []
  );

  if (isLoading) {
    return (
      <div className="flex flex-component-center px-lg py-lg">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Milestone
      title={moodboardMilestone.name}
      selectedMilestone={moodboardMilestone}

      withShareButton={!isReadonly}
    >
      {
        hasFiles && !isReadonly && (
          <div className="flex align-center just-end mb-48">
            <Button
              className="mr-12"
              isInvert
              onClick={() => setShowMoodboardTextModal(true)}
            >
              ADD TEXT
            </Button>
            <div className={styles['emoji']}>
              <Button onClick={() => setShowMoodboardModal(true)} >
                ADD IMAGE
              </Button>
            </div>
          </div>
        )
      }
      {
        hasFiles
          ? (
            <div className={cn(styles['item-list'], 'px-16 py-16 align-start')}>
              {
                moodboardItems.map(({
                  file_id,
                  uuid,
                  image_caption,
                  thumbnailUrl,
                  image_url,
                  s3_link,
                  text,
                  width,
                  height,
                  reactions,
                }) => (
                  <div
                    key={uuid}
                    className={styles['item-card']}
                    style={{
                      gridRowEnd: text
                        ? `span calc(
                          (
                            16
                              + ${image_caption && !isShowCaptionFormForMoodboardItems[uuid] ? '30' : '0'}
                              + ${isShowCaptionFormForMoodboardItems[uuid] ? '100' : '0'}
                              + ${getMoodboardTextHeight(text)}
                          ) / 10
                        )`
                        : `span calc(
                          (
                            16
                              + ${image_caption && !isShowCaptionFormForMoodboardItems[uuid] ? '30' : '0'}
                              + ${isShowCaptionFormForMoodboardItems[uuid] ? '100' : '0'}
                              + 265 / ${width} * ${height}
                          ) / 10
                        )`,
                    }}
                  >
                    <div
                      className={cn(
                        styles['image-wrapper'],
                        {
                          [styles['moodboard-text-border']]: text,
                          [styles['item-card-removing']]: isRemovingMoodboardItems[uuid],
                          [styles['item-card-downloading']]: isDownloadingMoodboardItems[uuid],
                        }
                      )}
                    >
                      {
                        text
                          ? (
                            <p
                              className={cn(styles['moodboard-text'], 'pointer')}
                              dangerouslySetInnerHTML={{ __html: text }}
                              onClick={() => setSelectedMoodboardTextItemForRead({
                                uuid: uuid,
                                text: text,
                                image_caption: image_caption,
                              })}
                            />
                          )
                          : (
                            file_id && !s3_link
                              ? (
                                <ImageFromGDrive
                                  gDriveId={file_id}
                                  className={styles['image']}
                                  useThumbnailIfNoOtherFile
                                />
                              )
                              : (
                                <img
                                  src={thumbnailUrl || s3_link || image_url}
                                  className={styles['image']}
                                />
                              )
                          )
                      }
                      <div
                        ref={el => reactionListRef.current[uuid] = el}
                        className={cn(styles['reaction-list'], 'flex flex-wrap')}
                      >
                        {
                          reactions
                            ?.filter(({ count }) => count > 0)
                            ?.map(({ reaction, users, count }) => (
                              <button
                                key={reaction}
                                className={cn(
                                  styles['reaction-button'],
                                  'px-12 mt-8',
                                  {
                                    [styles['active-reaction-button']]: users.indexOf(userProfile?.uuid) != -1,
                                  }
                                )}
                                onClick={() => {
                                  if (users.indexOf(userProfile?.uuid) != -1) {
                                    deleteReaction(
                                      reaction,
                                      uuid,
                                      ReactionsService.REACTIONS_CONTENT_TYPE_NAMES.thirdPartyImage
                                    );
                                  }
                                  else {
                                    leaveReaction(
                                      reaction,
                                      uuid,
                                      ReactionsService.REACTIONS_CONTENT_TYPE_NAMES.thirdPartyImage
                                    );
                                  }
                                }}
                              >
                                {
                                  isLoadingReactionsItems[`${uuid}-${reaction}`]
                                    ? (
                                      <Spin
                                        size="small"
                                        className={cn(
                                          'mt-xxs',
                                          styles['white-spin']
                                        )}
                                      />
                                    )
                                    : <EmojiSymbol unicode={reaction} />
                                }
                                <span className="ml-12 text-sm text-weight-500">
                                  {count}
                                </span>
                              </button>
                            ))
                        }
                      </div>
                      <DropdownMenu
                        className={styles['add-reaction-button']}
                        destroyPopupOnHide
                        visible={selectedMoodboardItemForAddReaction === uuid}
                        overlay={
                          <Menu
                            className="p-zero"
                            items={
                              [
                                {
                                  className: 'p-zero',
                                  label: (
                                    <Picker
                                      data={data}
                                      onEmojiSelect={({ unified }) => {
                                        setSelectedMoodboardItemForAddReaction(null);

                                        leaveReaction(
                                          unified,
                                          uuid,
                                          ReactionsService.REACTIONS_CONTENT_TYPE_NAMES.thirdPartyImage
                                        );
                                      }}
                                      previewPosition="none"
                                      theme="light"
                                      emojiVersion={14}
                                    />
                                  ),
                                  key: 'add-reaction-picker',
                                },
                              ]
                            }
                          />
                        }
                        trigger={['click']}
                      >
                        <button
                          className={cn(styles['reaction-button'])}
                          onClick={() => setSelectedMoodboardItemForAddReaction(
                            currentSelectedMoodboardItemForAddReaction => (
                              currentSelectedMoodboardItemForAddReaction
                                ? null
                                : uuid
                            )
                          )}
                          style={{
                            opacity: selectedMoodboardItemForAddReaction === uuid
                              ? 1
                              : undefined,
                          }}
                        >
                          <AddReactionIcon />
                        </button>
                      </DropdownMenu>
                      <DropdownMenu
                        className={styles['action-dropdown']}
                        destroyPopupOnHide
                        overlay={
                          <Menu
                            className="p-zero"
                            items={
                              [
                                {
                                  isShow: text && !isReadonly,
                                  title: 'Edit text',
                                  icon: (
                                    <EditTextIcon className="mr-sm flex flex-component-center" />
                                  ),
                                  onClick: () => setSelectedMoodboardTextItemForEdit({
                                    uuid: uuid,
                                    text: text,
                                  }),
                                },
                                {
                                  isShow: !isReadonly,
                                  title: image_caption
                                    ? 'Edit caption'
                                    : 'Add caption',
                                  icon: (
                                    <TIcon className="mr-sm flex flex-component-center" />
                                  ),
                                  onClick: () => setIsShowCaptionFormForMoodboardItemsByUUID(uuid, true),
                                },
                                {
                                  isShow: thumbnailUrl || s3_link || image_url,
                                  title: 'Export image',
                                  icon: (
                                    <DownloadIcon className="mr-sm flex flex-component-center" />
                                  ),
                                  onClick: () => downloadMoodboardImage(uuid),
                                },
                                {
                                  isShow: !isReadonly,
                                  title: 'Delete',
                                  className: 'text-fuzzyWuzzyBrown',
                                  icon: (
                                    <BinIcon className="mr-sm flex flex-component-center" />
                                  ),
                                  onClick: () => removeMoodboardImage(uuid),
                                },
                              ]
                                .filter(({ isShow }) => isShow)
                                .map(({ title, icon, ...buttonProps }) => ({
                                  className: 'p-zero',
                                  label: (
                                    <DropdownButton
                                      icon={icon}
                                      text={title}
                                      {...buttonProps}
                                    />
                                  ),
                                  key: title,
                                }))
                            }
                          />
                        }
                        trigger={['click']}
                      >
                        <button className={styles['action-dropdown-button']}>
                          <ThreeDotsIcon />
                        </button>
                      </DropdownMenu>
                    </div>
                    <p
                      className={cn(
                        'm-zero pt-8 text-sm text-weight-bold text-carbonGrey ellipsis',
                        {
                          hide: !image_caption || isShowCaptionFormForMoodboardItems[uuid],
                        }
                      )}
                      title={image_caption}
                    >
                      {image_caption}
                    </p>
                    {
                      isShowCaptionFormForMoodboardItems[uuid] && (
                        <AddCaptionForm
                          className="mt-16"
                          placeholder="Add caption..."
                          onCancel={() => setIsShowCaptionFormForMoodboardItemsByUUID(uuid, false)}
                          onSubmit={(...args) => updateMoodboardItem(uuid, ...args)}
                          initialValues={{ caption: image_caption }}
                        />
                      )
                    }
                  </div>
                ))
              }
            </div>
          )
          : (
            <AddFirstMoodboardItem
              onAddImageClick={() => setShowMoodboardModal(true)}
            />
          )
      }
      <MoodboardModal
        visible={showMoodboardModal}
        onCancel={() => setShowMoodboardModal(false)}
        onSubmit={saveMoodboardImagesList}
        onSubmitLocalFiles={saveLocalMoodboardImagesList}
      />
      <MoodboardTextModal
        title={
          selectedMoodboardTextItemForRead?.uuid
            ? selectedMoodboardTextItemForRead?.image_caption
            : null
        }
        visible={showMoodboardTextModal || selectedMoodboardTextItemForEdit || selectedMoodboardTextItemForRead}
        onCancel={closeMoodboardTextModal}
        initialValues={{
          text: selectedMoodboardTextItemForEdit?.text || selectedMoodboardTextItemForRead?.text || '',
        }}
        onSubmit={
          selectedMoodboardTextItemForEdit?.uuid
            ? text => updateMoodboardItem(selectedMoodboardTextItemForEdit?.uuid, { text })
            : saveMoodboardText
        }
        readOnly={!!selectedMoodboardTextItemForRead?.uuid}
      />
    </Milestone>
  );

  async function loadMoodboardImages() {
    setIsLoading(true);

    const moodboardElements = (
      await MoodboardService.getMoodboardImagesList(selectedProject.uuid, moodboardMilestone.id)
    ).results;
    const moodboardElementsReactions = (
      await ReactionsService.getReactions(moodboardElements.map(({ uuid }) => uuid))
    ).reduce(
      (current, next) => {
        if (!Array.isArray(current[next.object_uuid])) {
          current[next.object_uuid] = [];
        }

        const reactionObj = current[next.object_uuid].find(({ reaction }) => reaction === next.unique_code);

        if (reactionObj) {
          reactionObj.users.push(next.user);
          reactionObj.count += next.count_likes;
        }
        else {
          current[next.object_uuid].push({
            reaction: next.unique_code,
            count: next.count_likes,
            users: [next.user],
          });
        }

        return current;
      },
      {}
    );

    setMoodboardItems(
      moodboardElements
        .map(item => ({
          ...item,
          reactions: moodboardElementsReactions[item.uuid] || [],
        }))
    );

    setIsLoading(false);
  }

  async function saveMoodboardImagesList(imagesList) {
    await MoodboardService.addImagesToMoodboard(
      selectedProject.uuid,
      moodboardMilestone.id,
      imagesList
    );

    setShowMoodboardModal(false);

    loadMoodboardImages();
  }

  async function saveLocalMoodboardImagesList(imagesList) {
    const gDriveImagesListForMoodboard = imagesList.filter(({ id }) => !!id);
    const imagesListForMoodboard = imagesList
      .filter(({ uid }) => !!uid)
      .map(({ url, height, width }) => ({
        image_url: url,
        image_caption: '',
        image_source: ImageSearchService.imageSearchApiSources.local,
        height,
        width,
      }));

    await Promise.all([
      (
        gDriveImagesListForMoodboard.length
          ? (
            await MoodboardService.addGDriveImagesToMoodboard(
              selectedProject.uuid,
              moodboardMilestone.id,
              gDriveImagesListForMoodboard
            )
          )
          : Promise.resolve()
      ),
      (
        imagesListForMoodboard.length
          ? (
            await MoodboardService.addImagesToMoodboard(
              selectedProject.uuid,
              moodboardMilestone.id,
              imagesListForMoodboard
            )
          )
          : Promise.resolve()
      ),
    ]);

    setShowMoodboardModal(false);

    loadMoodboardImages();
  }

  async function saveMoodboardText(text) {
    await MoodboardService.addTextToMoodboard(
      selectedProject.uuid,
      moodboardMilestone.id,
      text
    );

    closeMoodboardTextModal();

    loadMoodboardImages();
  }

  async function removeMoodboardImage(moodboardItemUUID) {
    try {
      setIsRemovingMoodboardItemsByUUID(moodboardItemUUID, true);

      const deleteMoodboardImageFunc = moodboardItems.find(({ uuid }) => uuid === moodboardItemUUID)?.file_id
        ? MoodboardService.deleteGDriveMoodboardImage
        : MoodboardService.deleteMoodboardImage;

      await deleteMoodboardImageFunc(
        selectedProject.uuid,
        moodboardMilestone.id,
        moodboardItemUUID
      );

      setMoodboardItems(currentMoodboardItems => currentMoodboardItems.filter(({ uuid }) => uuid != moodboardItemUUID));
    }
    finally {
      setIsRemovingMoodboardItemsByUUID(moodboardItemUUID, false);
    }
  }

  async function updateMoodboardItem(moodboardItemUUID, { caption, text }) {
    const updateMoodboardImageFunc = moodboardItems.find(({ uuid }) => uuid === moodboardItemUUID)?.file_id
      ? MoodboardService.updateGDriveMoodboardItem
      : MoodboardService.updateMoodboardItem;

    const newMoodboardItem = await updateMoodboardImageFunc(
      selectedProject.uuid,
      moodboardMilestone.id,
      moodboardItemUUID,
      {
        imageCaption: caption,
        text,
      }
    );

    setMoodboardItems(currentMoodboardItems => currentMoodboardItems.map(moodboardItem => (
      moodboardItem.uuid === moodboardItemUUID
        ? {
          ...newMoodboardItem,
          reactions: moodboardItem.reactions,
        }
        : moodboardItem
    )));
    setIsShowCaptionFormForMoodboardItemsByUUID(moodboardItemUUID, false);
    closeMoodboardTextModal();
  }

  async function downloadMoodboardImage(moodboardItemUUID) {
    try {
      setIsDownloadingMoodboardItemsByUUID(moodboardItemUUID, true);

      const selectedMoodboardItem = moodboardItems.find(({ uuid }) => uuid === moodboardItemUUID);

      await downloadFiles(
        selectedMoodboardItem.s3_link || selectedMoodboardItem.image_url,
        `${selectedMoodboardItem.image_caption || 'moodboard_item'}${selectedMoodboardItem.image_format}`
      );
    }
    finally {
      setIsDownloadingMoodboardItemsByUUID(moodboardItemUUID, false);
    }
  }

  function setIsRemovingMoodboardItemsByUUID(moodboardItemUUID, newStatus) {
    setIsRemovingMoodboardItems(currentIsRemovingMoodboardItems => ({
      ...currentIsRemovingMoodboardItems,
      [moodboardItemUUID]: newStatus,
    }));
  }

  function setIsDownloadingMoodboardItemsByUUID(moodboardItemUUID, newStatus) {
    setIsDownloadingMoodboardItems(currentIsDownloadingMoodboardItems => ({
      ...currentIsDownloadingMoodboardItems,
      [moodboardItemUUID]: newStatus,
    }));
  }

  function setIsShowCaptionFormForMoodboardItemsByUUID(moodboardItemUUID, newStatus) {
    setIsShowCaptionFormForMoodboardItems(currentIsShowCaptionFormForMoodboardItems => ({
      ...currentIsShowCaptionFormForMoodboardItems,
      [moodboardItemUUID]: newStatus,
    }));
  }

  function setIsLoadingReactionsItemsByID(reactionID, newStatus) {
    setIsLoadingReactionsItems(currentIsShowCaptionFormForMoodboardItems => ({
      ...currentIsShowCaptionFormForMoodboardItems,
      [reactionID]: newStatus,
    }));
  }

  function getMoodboardTextHeight(text) {
    const div = document.createElement('div');
    const p = document.createElement('p');

    div.setAttribute('class', cn(
      styles['image-wrapper'],
      styles['moodboard-text-border'],
      styles['hide-moodboard-text']
    ));
    p.setAttribute('class', styles['moodboard-text']);

    p.innerHTML = text;

    div.appendChild(p);
    document.body.appendChild(div);

    const moodboardTextHeight = div.clientHeight;

    document.body.removeChild(div);

    return moodboardTextHeight;
  }

  function closeMoodboardTextModal() {
    setShowMoodboardTextModal(false);
    setSelectedMoodboardTextItemForEdit(null);
    setSelectedMoodboardTextItemForRead(null);
  }

  function clickOutside(event) {
    const isClickOutside = !event.composedPath().find(
      el => (
        el.tagName === 'EM-EMOJI-PICKER'
          || el.className?.indexOf && el.className.indexOf(styles['reaction-button']) != -1
      )
    );

    if (isClickOutside) {
      setSelectedMoodboardItemForAddReaction(null);
    }
  }

  function localLeaveReaction(reactionUnicode, objectsUUID, userUUID) {
    setMoodboardItems(moodboardItems.map(moodboardItem => {
      if (moodboardItem.uuid === objectsUUID) {
        const reactionObj = moodboardItem.reactions.find(({ reaction }) => reaction === reactionUnicode);

        if (reactionObj) {
          reactionObj.count += 1;
          reactionObj.users.push(userUUID);
        }
        else {
          moodboardItem.reactions.push({
            reaction: reactionUnicode,
            count: 1,
            users: [userUUID],
          });
        }
      }

      return moodboardItem;
    }));

    scrollReactionListToBottom(objectsUUID);
  }

  async function leaveReaction(reactionUnicode, objectsUUID, contentTypeName) {
    try {
      setIsLoadingReactionsItemsByID(`${objectsUUID}-${reactionUnicode}`, true);

      localLeaveReaction(reactionUnicode, objectsUUID, userProfile.uuid);

      await ReactionsService.leaveReaction(
        reactionUnicode,
        objectsUUID,
        contentTypeName,
        moodboardMilestone.id,
        selectedProject.uuid
      );
    }
    finally {
      setIsLoadingReactionsItemsByID(`${objectsUUID}-${reactionUnicode}`, false);
    }
  }

  function localDeleteReaction(reactionUnicode, objectsUUID, userUUID) {
    setMoodboardItems(moodboardItems.map(moodboardItem => {
      if (moodboardItem.uuid === objectsUUID) {
        const reactionObj = moodboardItem.reactions.find(({ reaction }) => reaction === reactionUnicode);

        reactionObj.count -= 1;
        reactionObj.users = reactionObj.users.filter(uuid => uuid !== userUUID);
      }

      return moodboardItem;
    }));

    scrollReactionListToBottom(objectsUUID);
  }

  async function deleteReaction(reactionUnicode, objectsUUID, contentTypeName) {
    try {
      setIsLoadingReactionsItemsByID(`${objectsUUID}-${reactionUnicode}`, true);

      localDeleteReaction(reactionUnicode, objectsUUID, userProfile.uuid);

      await ReactionsService.deleteReaction(
        reactionUnicode,
        objectsUUID,
        contentTypeName,
        moodboardMilestone.id,
        selectedProject.uuid
      );
    }
    finally {
      setIsLoadingReactionsItemsByID(`${objectsUUID}-${reactionUnicode}`, false);
    }
  }

  function scrollReactionListToBottom(uuid) {
    const reactionListEl = reactionListRef.current[uuid];

    if (reactionListEl) {
      reactionListEl.scrollTo(0, reactionListEl.scrollHeight);
    }
  }
}

MoodboardMilestone.propTypes = {
  isReadonly: PropTypes.bool,
};
