import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import cn from 'classnames';

import './Login.scss';

import {
  initializeSignInUser,
  refreshAuthToken,
  setSignedInUser,
} from 'Redux/Actions/userActions';

import { ROUTES } from 'routes/route.constants';

import { identifyUser } from 'services/eventTracking';

import { appLogoutFunction, parseJwt } from 'utlis/globalFunctions';
import { setProjectHistory } from 'utlis/http';
import { socketInit } from 'utlis/socket';

const Login = ({ attemptLogin, sessionHasExpired, className, loginButtonClassName, children, fromPluginAuth }) => {
  const [authStatus, setAuthStatus] = useState(sessionHasExpired ? false : null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'profile email',
    onSuccess: onSuccessDesigner,
    onError: onFailure,
    select_account: true,
    useOneTap: true,
    hosted_domain: '*',
  });

  useEffect(
    () => {
      setProjectHistory(navigate);

      if (localStorage.getItem('authenticated') && attemptLogin) {
        onSuccessDesigner();
      }
      else {
        setAuthStatus(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!sessionHasExpired) {
      const userObject = localStorage.getItem('userObject');

      if (!userObject && !fromPluginAuth) {
        navigate(ROUTES.LANDING);
      }
    }
  }, [sessionHasExpired]);

  return (
    <div className={cn('login__form', className)}>
      <div
        className={cn('login__session_expired', {
          login__session_expired_center: attemptLogin,
          hide: authStatus == null || authStatus,
          'flex align-center flex-column': sessionHasExpired,
        })}
      >
        <p
          className={cn('login__login__session_expired__header', {
            hide: !attemptLogin,
          })}
        >
          Your session has expired
        </p>
        <div className="login__session_expired__buttonsBlock">
          <div
            className={cn('login__buttonContainer', {
              hide: !attemptLogin,
            })}
          >
            <button
              className="login__buttonText"
              onClick={handleLogout}
            >
              Go To Home Page
            </button>
          </div>
          <button
            className={cn(
              loginButtonClassName,
              {
                login__hideButton: attemptLogin,
              }
            )}
            onClick={googleLogin}
          >
            {children}
          </button>
        </div>
      </div>
    </div>
  );

  async function onSuccessDesigner(response) {
    if (response) {
      const result = await dispatch(initializeSignInUser(response.code));

      ReactGA.set({ userId: result?.data?.uuid });
      identifyUser(result?.data?.uuid);

      if (result?.data?.user_type === null) {
        navigate('/onboarding');
      }
      else {
        navigate(ROUTES.DASHBOARD_PROJECT_TYPE('active'));
      }
    }
    else {
      const userObject = JSON.parse(localStorage.getItem('userObject'));
      const jwtToken = parseJwt(userObject.access);

      dispatch(setSignedInUser(userObject));
      identifyUser(userObject.uuid);

      if (jwtToken.exp * 1000 < Date.now()) {
        try {
          await dispatch(refreshAuthToken(userObject.refresh));
        }
        catch (error) {
          if (error?.response?.status == 401) {
            setAuthStatus(false);
            localStorage.removeItem('authenticated');
          }
          else {
            Sentry.captureException(error);
          }
        }
      }
    }
    socketInit(localStorage.getItem('access_token'));
  }

  function onFailure(response) {
    Sentry.captureException(response);
  }

  function handleLogout() {
    appLogoutFunction(dispatch, navigate);
  }
};

Login.defaultProps = {
  buttonText: 'Sign in',
};

Login.propTypes = {
  attemptLogin: PropTypes.bool,
  sessionHasExpired: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  loginButtonClassName: PropTypes.string,
  fromPluginAuth: PropTypes.bool,
};

export default Login;
