import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Menu } from 'antd/es';
import cn from 'classnames';

import { DropdownMenu } from 'Components/DropdownMenu';
import { MilestoneIcon } from 'Components/Milestone/components/MilestoneIcon';

import { MILESTONES_TYPES } from 'utlis/constants';
import { MILESTONE_METADATA } from 'utlis/workspace.constants';

import styles from './styles.module.scss';

export const MilestoneCard = ({ name, type, id, is_completed, onDeleteMilestone, ...otherMilestoneProps }) => {
  const { projectSlug, milestoneId } = useParams();

  return (
    <DropdownMenu
      overlayClassName={cn({
        hide: !onDeleteMilestone || [MILESTONES_TYPES.brief, MILESTONES_TYPES.uxBrief].includes(type) || is_completed,
      })}
      overlay={
        <Menu
          items={[
            {
              key: 'delete',
              label: (
                <div
                  className={styles.milestone__cardDropdownItem}
                  onClick={
                    onDeleteMilestone
                      ? () => onDeleteMilestone({ name, type, id, is_completed, ...otherMilestoneProps })
                      : null
                  }
                >
                  DELETE
                </div>
              ),
            },
          ]}
        />
      }
      trigger={['contextMenu']}
    >
      <Link
        className={cn(
          styles.workspace__sidebarCard,
          {
            [styles.active__sidebarCard]: milestoneId == id,
          },
          'flex align-center pl-16 py-12'
        )}
        to={MILESTONE_METADATA[type]?.route(projectSlug, id)}
      >
        <MilestoneIcon
          className="mr-16"
          type={type}
          size={32}
          isCompleted={is_completed}
        />
        <h3
          title={name}
          className={cn(
            styles.milestone__name,
            'ellipsis text-weight-600 text-md letter-xs m-zero'
          )}
        >
          {name}
        </h3>
      </Link>
    </DropdownMenu>
  );
};

MilestoneCard.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  is_completed: PropTypes.bool,
  onDeleteMilestone: PropTypes.func,
};
