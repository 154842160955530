import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import cn from 'classnames';

import brandPersonalityFirstViewIconUrl from 'assets/first_view_icons/brand_personality.svg';

import { Button } from 'Components/Button';
import { FirstViewBodyUI } from 'Components/FirstViewBodyUI';
import { MessageForm } from 'Components/MessageForm';
import { Milestone } from 'Components/Milestone';
import StepWrapper from 'Components/StepWrapper';

import { showMilestoneProgressModal } from 'Redux/Actions/appActions';
import { submitMilestone } from 'Redux/Actions/FeedActions';

import BrandPersonalityService from 'services/brand_personality';

import { MILESTONE_STATUSES, NOTIFICATION_TYPES } from 'utlis/constants';
import { onSocketMessage } from 'utlis/socket';
import { errorReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

export default function BrandPersonalityMilestone({ isReadonly }) {
  const { milestoneId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [brandPersonalityList, setBrandPersonalityList] = useState([]);
  const [inProgressBrandPersonalityItems, setInProgressBrandPersonalityItems] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);

  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.userProfile);
  const selectedProject = useSelector(state => state.selectedProject);
  const brandPersonalityMilestone = useSelector(state => state.milestonesStore?.dictionaryId?.[milestoneId]);

  const projectHasClient = useMemo(
    () => selectedProject.collaborators.find(
      ({ user: { permissions: { isClient } } }) => isClient
    ),
    [selectedProject]
  );

  const { isDesignerSubmitted, isCompleted } = useMemo(
    () => ({
      isDesignerSubmitted: [
        MILESTONE_STATUSES.submitted,
        MILESTONE_STATUSES.completed,
      ].includes(brandPersonalityMilestone?.milestone_status),
      isCompleted: [MILESTONE_STATUSES.completed].includes(brandPersonalityMilestone?.milestone_status),
    }),
    [brandPersonalityMilestone?.milestone_status]
  );

  useEffect(() => {
    setIsLoading(true);

    if (selectedProject.uuid && !(currentUser?.permissions?.isClient && !isDesignerSubmitted)) {
      loadBrandPersonalityList();
    }
    else {
      setIsLoading(false);
    }
  }, [selectedProject.uuid, milestoneId]);

  useEffect(
    () => onSocketMessage(
      ({ data: { milestone_id } }) => {
        if (milestoneId == milestone_id) {
          loadBrandPersonalityList();
        }
      },
      [
        NOTIFICATION_TYPES.FORM_EDIT,
        NOTIFICATION_TYPES.FORM_SUBMIT,
        NOTIFICATION_TYPES.FORM_ANSWERS_SUBMIT,
        NOTIFICATION_TYPES.MILESTONE_DONE_BY_DESIGNER,
        NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
      ]
    ),
    [selectedProject.uuid]
  );

  if (isLoading) {
    return (
      <div className="flex flex-component-center px-lg py-lg mx-xs">
        <Spin size="large" />
      </div>
    );
  }

  if (currentUser?.permissions?.isClient && !isDesignerSubmitted) {
    return (
      <Milestone
        selectedMilestone={brandPersonalityMilestone}
        title={brandPersonalityMilestone?.name}

        withShareButton={!isReadonly}
      >
        <FirstViewBodyUI
          className="mx-xxxs"
          image={brandPersonalityFirstViewIconUrl}
          text={
            <>
              The designer is working on the
              {' '}
              {brandPersonalityMilestone.name}
              .
              <br />
              We will update your view as soon as it’s available!
            </>
          }
        />
      </Milestone>
    );
  }

  return (
    <Milestone
      selectedMilestone={brandPersonalityMilestone}
      title={brandPersonalityMilestone?.name}

      withShareButton={!isReadonly}
      additionalRightElement={
        <>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !(currentUser?.permissions?.isDesigner && isDesignerSubmitted) || isCompleted,
              }
            )}
          >
            SUBMITTED FOR INPUT
          </p>
          <p
            className={cn(
              'text-13 text-weight-500 text-coolGrey m-zero',
              {
                hide: !isCompleted,
              }
            )}
          >
            ANSWERS SUBMITTED
          </p>
          <div
            className={cn(
              'flex align-center',
              {
                hide: isCompleted || (currentUser?.permissions?.isDesigner && isDesignerSubmitted) || isReadonly,
              }
            )}
          >
            <Button
              className="ml-sm"
              onClick={e => onSubmit(e)}
              disabled={
                isSubmiting
                  || !brandPersonalityList.length
              }
            >
              {
                isSubmiting
                  ? 'SUBMITING ...'
                  : (
                    currentUser?.permissions?.isClient
                      ? 'SUBMIT ANSWERS'
                      : 'SUBMIT FOR INPUT'
                  )
              }
            </Button>
          </div>
        </>
      }
    >
      <StepWrapper className="px-56 py-lg">
        <p className="text-black text-weight-300 text-20 m-zero letter-xxs">
          <span className="text-42 text-weight-500">
            What does your brand say?
          </span>
          <br />
          Select the words that best describe your brand personality, or type in a new word.
        </p>
        <MessageForm
          className={cn(
            styles['create-new-tag-form'],
            'mt-xlg',
            {
              hide: isCompleted || isReadonly,
            }
          )}
          messagePlaceholder="Add a new word"
          autoFocusOnMessage={false}
          autoFocusAfterSubmit
          textareaBackgroundColor={styles.textareaBackgroundColor}
          onSubmit={addNewBrandPersonalityItem}
          messageRules={[{
            validator: (rule, value) => (// eslint-disable-line no-unused-vars
              (value || '').split(/[,\s;.]/g).length > 1
                ? Promise.reject('You can only provide one word')
                : (
                  !!value && brandPersonalityList.find(({ word }) => word.toLowerCase() == value.toLowerCase())
                    ? Promise.reject('This word already exists')
                    : Promise.resolve()
                )
            ),
          }]}
          allowMultiLineMessage={false}
        />
        <div className="flex align-center flex-wrap mt-xlg">
          {
            brandPersonalityList.map(({ uuid, word, is_select_by_client }) => (
              <Spin
                key={uuid}
                size="small"
                wrapperClassName="mb-16 mr-16"
                spinning={!!inProgressBrandPersonalityItems[uuid]}
              >
                <div
                  className={cn(
                    styles['brand-personality-item'],
                    'flex align-center text-easternBlue text-sm text-weight-500 letter-xl uppercase',
                    {
                      pointer: currentUser?.permissions?.isClient && !isCompleted,
                      [styles['selected']]: is_select_by_client,
                    }
                  )}
                  onClick={currentUser?.permissions?.isClient && !isCompleted && !isReadonly
                    ? (() => onToggleSelectBrandPersonalityItem(uuid))
                    : null}
                >
                  {word}
                  <button
                    className={cn(
                      'text-button ml-sm-important pointer',
                      {
                        hide: currentUser?.permissions?.isClient || isCompleted || isReadonly,
                      }
                    )}
                    onClick={() => onDeleteBrandPersonalityItem(uuid)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </Spin>
            ))
          }
        </div>
      </StepWrapper>
    </Milestone>
  );

  async function loadBrandPersonalityList() {
    try {
      setBrandPersonalityList((await BrandPersonalityService.getBrandPersonalityList(
        selectedProject.uuid,
        brandPersonalityMilestone.id
      ))?.results);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function addNewBrandPersonalityItem({ message: word }) {
    try {
      const newBrandPersonalityItem = await BrandPersonalityService.addBrandPersonalityItem(
        selectedProject.uuid,
        brandPersonalityMilestone.id,
        word
      );

      setBrandPersonalityList(currentBrandPersonalityList => [...currentBrandPersonalityList, newBrandPersonalityItem]);
    }
    catch (error) {
      if (error?.response?.status === 400) {
        errorReactToastify(error?.response?.data?.detail);
      }
      else {
        throw new Error(error);
      }
    }
  }

  async function onToggleSelectBrandPersonalityItem(brandPersonalityUUID) {
    try {
      setInProgressBrandPersonalityItemsByUUID(brandPersonalityUUID, true);

      const brandPersonalityItem = brandPersonalityList.find(({ uuid }) => uuid === brandPersonalityUUID);
      const newBrandPersonalityItem = {
        ...brandPersonalityItem,
        is_select_by_client: !brandPersonalityItem.is_select_by_client,
      };

      await BrandPersonalityService.updateBrandPersonalityItem(
        selectedProject.uuid,
        brandPersonalityMilestone.id,
        brandPersonalityUUID,
        newBrandPersonalityItem
      );
      setBrandPersonalityList(currentBrandPersonalityList => currentBrandPersonalityList.map(
        item => item.uuid == brandPersonalityUUID
          ? newBrandPersonalityItem
          : item
      ));
    }
    finally {
      setInProgressBrandPersonalityItemsByUUID(brandPersonalityUUID, false);
    }
  }

  async function onDeleteBrandPersonalityItem(brandPersonalityUUID) {
    try {
      setInProgressBrandPersonalityItemsByUUID(brandPersonalityUUID, true);

      await BrandPersonalityService.deleteBrandPersonalityItem(
        selectedProject.uuid,
        brandPersonalityMilestone.id,
        brandPersonalityUUID
      );
      setBrandPersonalityList(
        currentBrandPersonalityList => currentBrandPersonalityList.filter(({ uuid }) => uuid != brandPersonalityUUID)
      );
    }
    finally {
      setInProgressBrandPersonalityItemsByUUID(brandPersonalityUUID, false);
    }
  }

  async function onSubmit() {
    if (projectHasClient) {
      try {
        setIsSubmiting(true);

        await dispatch(submitMilestone(
          currentUser,
          selectedProject,
          brandPersonalityMilestone?.id,
          brandPersonalityMilestone?.name
        ));

        dispatch(showMilestoneProgressModal({
          milestone: brandPersonalityMilestone,
          notificationType: currentUser?.permissions?.isDesigner
            ? NOTIFICATION_TYPES.FORM_SUBMIT
            : NOTIFICATION_TYPES.MILESTONE_APPROVE_BY_CLIENT,
        }));
      }
      finally {
        setIsSubmiting(false);
      }
    }
    else {
      errorReactToastify(
        'The project does not have a client. Please invite the client or wait until he accepts your invitation.'
      );
    }
  }

  function setInProgressBrandPersonalityItemsByUUID(brandPersonalityUUID, inProgressStatus) {
    setInProgressBrandPersonalityItems(currentInProgressBrandPersonalityItems => ({
      ...currentInProgressBrandPersonalityItems,
      [brandPersonalityUUID]: inProgressStatus,
    }));
  }
}

BrandPersonalityMilestone.propTypes = {
  isReadonly: PropTypes.bool,
};
