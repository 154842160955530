import { serialize as objectToFormData } from 'object-to-formdata';
import slugify from 'slugify';

import { ApiManager } from 'utlis/api_manager';
import { FILE_CONTAINER_TYPES } from 'utlis/constants';
import http from 'utlis/http';

import FigmaFile from './figma_file';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class FilesService {
  static CONTAINER_TYPES = FILE_CONTAINER_TYPES

  static async loadContainersWithFiles(milestoneId, { loadFigmaFileSize = false } = {}) {
    const data = (await api.setUrl(`project/${milestoneId}/container/get_container`).get({ limit: 1000 }))
      .data;

    if (loadFigmaFileSize) {
      await Promise.all(
        data.results.map(
          file => FigmaFile.getFigmaFileSize(file.file_key, file.node_ids[0])
            .then(filesSize => {
              file.width = filesSize.width;
              file.height = filesSize.height;
            })
        )
      );
    }

    return [{
      ...data.container,
      files: data.results.sort(({ sequence_no: a }, { sequence_no: b }) => a - b),
    }];
  }

  static deleteContainersWithFiles(milestoneId, containerUUID) {
    return api.setUrl(`project/${milestoneId}/container/${containerUUID}`).delete();
  }

  static uploadFileFromGDrive(projectUUID, containerId, milestoneId, prevFileUUID, container_type, files) {
    return api.setUrl(`project/${projectUUID}/drive_file`).post({
      container: containerId || null,
      milestone_id: milestoneId,
      prev_file: prevFileUUID || null,
      container_type: container_type,
      files: files.map(({ id, url, name, embedUrl, iconUrl, mimeType }) => ({
        file_id: id,
        file_url: url,
        file_name: name,
        thumbnail_url: embedUrl || url,
        icon_link: iconUrl,
        mime_type: mimeType,
      })),
    });
  }

  static deleteGDriveFile(projectUUID, gDriveFileUUID) {
    return api.setUrl(`project/${projectUUID}/drive_file/${gDriveFileUUID}`).delete();
  }

  static async getDownloadLinkForGDriveFile(projectUUID, gDriveFileUUID) {
    return (
      await api.setUrl(`project/${projectUUID}/drive_file/${gDriveFileUUID}/download_file`).get()
    ).data.download_link;
  }

  static async uploadFileFromLocal(file, config) {
    const { url, fields } = (await api.setUrl('project/get_presigned_post_url').get({
      key: slugify(file.name, '_'),
    }))?.data;
    const { key } = fields;

    await http.post(
      url,
      objectToFormData({
        ...fields,
        file,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: undefined,
        },
        ...config,
      }
    );

    return `${url}${key}`;
  }
}
