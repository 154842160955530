import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import cn from 'classnames';

import globalQuestionnaireComponentsStyles from 'Components/questionnaireComponents/styles.module.scss';

import styles from './styles.module.scss';

export default function SelectComponent({ className, required, disabled, ...props }) {
  return (
    <Form.Item
      className={cn(
        styles['select-wrapper'],
        'm-zero',
        className
      )}
      {...props}
      rules={[{ required: required }]}
      disabled={disabled}
    >
      <Select
        className={styles['select']}
        showArrow={!disabled}
        dropdownMatchSelectWidth={false}
        showSearch={false}
        tagRender={({ label }) => (
          <span
            className={cn(
              globalQuestionnaireComponentsStyles['input-text'],
              styles['selected-option']
            )}
            disabled={disabled}
          >
            {label}
          </span>
        )}
        disabled={disabled}
        {...props}
      />
    </Form.Item>
  );
}

SelectComponent.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
