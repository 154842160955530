import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

export function EmbeddedPanel({
  title,
  isCollapsed,
  children,
  backButtonPosition,
  className,
  contentClassName,
  customHeader,
  onOpen,
  onClose,
}) {
  return (
    <div
      className={cn(
        styles['embedded-panel-wrapper'],
        {
          [styles['show']]: !isCollapsed,
        },
        className
      )}
    >
      <div
        className={cn(
          'flex align-center px-12 py-sm',
          {
            [styles['header']]: !isCollapsed,
            'space-between': backButtonPosition == EmbeddedPanel.backButtonPositionOption.left,
          }
        )}
      >
        {
          (
            (!isCollapsed && backButtonPosition == EmbeddedPanel.backButtonPositionOption.right)
              || (
                isCollapsed && backButtonPosition == EmbeddedPanel.backButtonPositionOption.left
              )
          )
            && onClose
            && (
              <Button
                className="text-notesText p-zero"
                icon={<FontAwesomeIcon icon={faAnglesRight} />}
                type="text"
                onClick={isCollapsed ? onOpen : onClose}
              />
            )
        }
        {
          customHeader || (
            <p className="text-md text-weight-600 letter-xxs text-notesText m-zero">
              {title}
            </p>
          )
        }
        {
          (
            (!isCollapsed && backButtonPosition == EmbeddedPanel.backButtonPositionOption.left)
              || (
                isCollapsed && backButtonPosition == EmbeddedPanel.backButtonPositionOption.right
              )
          )
            && onClose
            && (
              <Button
                className="text-notesText p-zero"
                icon={<FontAwesomeIcon icon={faAnglesLeft} />}
                type="text"
                onClick={isCollapsed ? onOpen : onClose}
              />
            )
        }
      </div>
      <div className={cn(styles['content'], contentClassName)}>
        {children}
      </div>
    </div>
  );
}

EmbeddedPanel.backButtonPositionOption = {
  left: 'left',
  right: 'right',
};

EmbeddedPanel.propTypes = {
  title: PropTypes.node,
  isCollapsed: PropTypes.bool,
  children: PropTypes.node,
  backButtonPosition: PropTypes.oneOf(Object.values(EmbeddedPanel.backButtonPositionOption)),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  customHeader: PropTypes.node,
};

EmbeddedPanel.defaultProps = {
  backButtonPosition: EmbeddedPanel.backButtonPositionOption.right,
};
