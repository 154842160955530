const cbElementList = [];

export const resizeObserver = new ResizeObserver(entries => { // eslint-disable-line compat/compat
  for (const entry of entries) {
    cbElementList.forEach(({ cb, element }) => {
      if (entry.target === element) {
        cb(entry);
      }
    });
  }
});

export function addChangeElementSizeListener(element, cb) {
  const onChangeElementSizeObj = { element, cb };

  cbElementList.push(onChangeElementSizeObj);
  resizeObserver.observe(element);

  return () => {
    cbElementList.splice(cbElementList.indexOf(onChangeElementSizeObj), 1);
    resizeObserver.unobserve(element);
  };
}
