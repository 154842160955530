import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { InfiniteTable } from 'Components/InfiniteTable';

import { PriceCell } from './components/PriceCell';
import { PaymentStatusCell } from './components/PaymentStatusCell';
import { RemoveCell } from './components/RemoveCell';

import styles from './styles.module.scss';

export function PaymentHistoryTable({ paymentsList, columnsKeys, onRemovePaymentRequest, ...props }) {
  const userProfile = useSelector(state => state.userProfile);

  const columnsConfig = useMemo(() => getColumnsConfig(columnsKeys), [columnsKeys]);

  return (
    <InfiniteTable
      className={styles['payment-history-table']}
      columns={columnsConfig}
      dataSource={paymentsList}
      {...props}
    />
  );

  function getColumnsConfig(currentColumnsKeys) {
    const columns = [
      {
        title: 'Designer Fee',
        dataIndex: 'amount',
        key: 'AMOUNT',
        render: RenderCell(PriceCell),
        className: styles['amount-width'],
      },
      {
        title: 'Status',
        dataIndex: 'payment_status',
        key: 'PAYMENT_STATUS',
        render: RenderCell(PaymentStatusCell, { userProfile }),
        className: styles['payment-status-width'],
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'TOTAL',
        render: RenderCell(PriceCell),
        className: styles['total-width'],
      },
      {
        title: 'DesignPro Fee',
        dataIndex: 'platform_fee',
        key: 'PLATFORM_FEE',
        render: RenderCell(PriceCell),
        className: styles['platform-fee-width'],
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripe_fee',
        key: 'STRIPE_FEE',
        render: RenderCell(PriceCell),
        className: styles['stripe-fee-width'],
      },
      {
        title: '',
        dataIndex: 'payment_status',
        key: 'REMOVE',
        render: RenderCell(RemoveCell, { userProfile, onRemove: onRemovePaymentRequest }),
        className: cn(styles['remove-button'], styles['remove-width']),
      },
    ];

    return currentColumnsKeys.map(columnKey => columns.find(({ key }) => columnKey == key)).filter(Boolean);
  }
}

PaymentHistoryTable.columnsKeys = {
  amount: 'AMOUNT',
  stripeFee: 'STRIPE_FEE',
  platformFee: 'PLATFORM_FEE',
  paymentStatus: 'PAYMENT_STATUS',
  total: 'TOTAL',
  remove: 'REMOVE',
};

PaymentHistoryTable.propTypes = {
  paymentsList: PropTypes.arrayOf(PropTypes.shape({
    pm_id: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    stripe_fee: PropTypes.string.isRequired,
    platform_fee: PropTypes.string.isRequired,
    payment_status: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  })),
  columnsKeys: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PaymentHistoryTable.columnsKeys))),
  onRemovePaymentRequest: PropTypes.func.isRequired,
};

PaymentHistoryTable.defaultProps = {
  columnsKeys: Object.values(PaymentHistoryTable.columnsKeys),
};

function RenderCell(Component, props) {
  return (text, record, index) => ( // eslint-disable-line react/display-name
    <Component {...props} text={text} record={record} index={index} />
  );
}
