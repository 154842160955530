import { useState } from 'react';
import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { Button } from 'Components/Button';

export function RemoveCell({ userProfile, text: paymentStatus, onRemove, record: paymentRecord }) {
  const [isRemoveInProgress, setIsRemoveInProgress] = useState(false);

  return paymentStatus == 'requested' && userProfile.permissions?.isDesigner && onRemove
    ? (
      <Button
        isText
        className="text-black-opacity-0-60"
        onClick={async () => {
          try {
            setIsRemoveInProgress(!isRemoveInProgress);

            await onRemove(paymentRecord.uuid);
          }
          finally {
            setIsRemoveInProgress(false);
          }
        }}
        disabled={isRemoveInProgress}
      >
        {
          isRemoveInProgress
            ? <Spin size="small" className="invert-mx-xxxs" />
            : <FontAwesomeIcon icon={faTrash} />
        }
      </Button>
    )
    : null;
}

RemoveCell.propTypes = {
  userProfile: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
  record: PropTypes.object.isRequired,

  onRemove: PropTypes.func,
};
