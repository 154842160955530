import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

export function NameFormItem({ className, inputClassName, autofocus, ...props }) {
  const inputRef = useRef(null);

  useEffect(
    () => {
      if (autofocus && inputRef?.current) {
        inputRef.current.focus();
      }
    },
    [inputRef]
  );

  return (
    <Form.Item
      className={cn(styles['name__wrapper'], className)}
      rules={[
        {
          required: true,
          message: '*Please add name',
        },
        {
          max: 40,
          message: '*Maximum character limit reached',
        },
        () => ({
          // eslint-disable-next-line no-unused-vars
          validator(rule, value) {
            if (value.match(/.*\S.*/g)) {
              return Promise.resolve();
            } if (value !== '') {
              return Promise.reject('*At least one character is required');
            }

            return Promise.reject();
          },
        }),
      ]}
      {...props}
    >
      <Input
        ref={inputRef}
        className={cn(styles['name__input'], inputClassName)}
        {...props}
      />
    </Form.Item>
  );
}

NameFormItem.propTypes = {
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};
